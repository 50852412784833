import JWTDecode, { JwtPayload } from 'jwt-decode';

export default function parseJwt(token: string) {
  try {
    return JWTDecode<TSFixMe>(token);
  } catch (e) {
    console.log('Error is parsing token', e);
    return '';
  }
}
