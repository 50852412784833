import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import config from 'config';

Bugsnag.start({
  apiKey: config.public_bugsnag_api_key || '',
  plugins: [new BugsnagPluginReact(React)],
  autoTrackSessions: false,
  releaseStage: config.public_vercel_env,
  enabledReleaseStages: ['production', 'preview'],
});

export default Bugsnag;
