import Cookie from '@lyearn/core/utils/cookie';

function clearAllCookies() {
  Cookie.remove?.('user_id');
  Cookie.remove?.('companyId');
  Cookie.remove?.('isContentViewer');
  Cookie.remove?.('accessToken');
  Cookie.remove?.('refreshToken');
  Cookie.remove?.('lyearn_token');
  Cookie.remove?.('storeId');
}

export default clearAllCookies;
