import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import { pageview } from './utils';

const GoogleAnalyticsTriggers = ({ GA_TRACKING_ID }: { GA_TRACKING_ID: string }) => {
  const router = useRouter();

  const handleRouteChange = useCallback(
    (url: string) => {
      pageview(url, GA_TRACKING_ID);
    },
    [GA_TRACKING_ID],
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, GA_TRACKING_ID, handleRouteChange]);

  return null;
};

export default GoogleAnalyticsTriggers;
