export const getGAScript = (GA_TRACKING_ID: string) =>
  `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;

export const getTrackingScript = (GA_TRACKING_ID: string) => `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${GA_TRACKING_ID}', {
  page_path: window.location.pathname,
});
`;

/* https://developers.google.com/analytics/devguides/collection/gtagjs/pages */
export const pageview = (url: string, GA_TRACKING_ID: string): void => {
  (window as any).gtag?.('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

/* https://developers.google.com/analytics/devguides/collection/gtagjs/events */
export const event = ({ action, category, label, value }: any) => {
  (window as any).gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
