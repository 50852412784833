import React, { useMemo } from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import { createMuiTheme, ThemeProvider } from '@lyearn/ui';

const theme = createMuiTheme({
  direction: (typeof document !== 'undefined' && document && document.dir) || ('ltr' as TSFixMe),
  palette: {
    primary: {
      main: '#000',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        className: 'bg-none',
      },
    },
  },
});

const MaterialThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const cacheRtl = useMemo(() => {
    // Create rtl cache

    return typeof document !== 'undefined' && document && document.dir === 'rtl'
      ? createCache({
          key: 'muirtl',
          // @ts-ignore
          stylisPlugins: [prefixer, rtlPlugin],
          prepend: true,
        })
      : createCache({
          key: 'mui',
          stylisPlugins: [],
          prepend: true,
        });
  }, []);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
  );
};

export default MaterialThemeProvider;
