import { useUser } from 'modules/context/Contexts/User';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import checkOnBoardingCompletion from 'utils/checkOnBoardingCompletion';

const UserOnBoardingFlow = ({ children }: { children: any }) => {
  const user = useUser();
  const { site } = useInstanceConfig();

  const userData = user;
  const onBoardingComplete = checkOnBoardingCompletion(site.requiredUserFields, userData);

  if (process.browser) {
    const pathname = window.location.pathname;
    if (
      userData.id &&
      (!userData.name || !onBoardingComplete) &&
      !user.fetching &&
      pathname !== '/about-yourself' &&
      pathname !== '/verify-email' &&
      pathname !== '/terms'
    ) {
      window.location.href =
        '/about-yourself?redirectPath=' +
        encodeURIComponent(window.location.pathname + window.location.search);
    }
  }
  return children;
};

export default UserOnBoardingFlow;
