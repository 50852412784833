import parseJwt from 'helpers/parseJWT';
import _ from 'lodash';

const oldWorkspaceIds = ['5f2262589e9a66c70ce5735c'];

const isOldWorkspaceId = (accessToken?: string) => {
  let workspaceId = '';

  try {
    if (accessToken) {
      workspaceId = parseJwt(accessToken as string).workspaceId;

      return oldWorkspaceIds.includes(workspaceId);
    }
  } catch (e) {
    console.error('Error parsing workspaceId from token', e);
  }

  return false;
};

export default isOldWorkspaceId;
