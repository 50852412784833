import config from 'config';
import getSystemUserAccessToken from 'helpers/getSystemUserAccessToken';
import url from 'helpers/url';
import queryString from 'query-string';
import SiteConfig from 'types/interfaces/SiteConfig';

interface FetchSiteConfig {
  token?: string;
  configOptions?: string[];
  host?: string;
}

const fetchSiteConfig = async ({
  token,
  host,
  configOptions,
}: FetchSiteConfig = {}): Promise<SiteConfig> => {
  if (!token) {
    const newToken = getSystemUserAccessToken(host);

    token = newToken;
  }

  const includes = configOptions ? (configOptions?.length > 0 ? configOptions.join(',') : '') : '';
  const reqUrl = `${url}/store/configs`;
  const query: any = { host: host };

  if (includes) {
    query.includes = includes;
  }

  const requestUrl = queryString.stringifyUrl({ url: reqUrl, query });

  const response: any = await fetch(requestUrl, {
    headers: {
      accessToken: token!,
    },
  });

  const configResponse = await response.json();

  return configResponse.configs;
};

export default fetchSiteConfig;
