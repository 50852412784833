import React from 'react';
import Bugsnag from 'lib/Bugsnag';
import { useUser } from 'modules/context/Contexts/User';

const ErrorBoundary = Bugsnag?.getPlugin?.('react')?.createErrorBoundary(React)!;

const BugsnagWithErrorBoundary: React.FC<React.PropsWithChildren> = ({ children }) => {
  const user = useUser();
  if (user && user.id) {
    Bugsnag.setUser(user.id, user.name, user.emailId);
  }

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default BugsnagWithErrorBoundary;
