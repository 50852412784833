export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JSONObject: any;
  Map: any;
};

export type AbsoluteDateFilter = {
  endTime?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
};

export type AbsoluteDateFilterType = {
  __typename?: 'AbsoluteDateFilterType';
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

/** Edge */
export type AcademyTemplateEdge = {
  __typename?: 'AcademyTemplateEdge';
  node: AcademyTemplateType;
};

export type AcademyTemplateFilters = {
  academyTemplateId: Scalars['String'];
};

export type AcademyTemplatePayload = {
  __typename?: 'AcademyTemplatePayload';
  academyTemplate?: Maybe<AcademyTemplateType>;
};

export type AcademyTemplateType = {
  __typename?: 'AcademyTemplateType';
  collectionIds?: Maybe<Array<Scalars['String']>>;
  collections: Array<ContentCollectionGroupType>;
  contentIds?: Maybe<Array<Scalars['String']>>;
  contents: Array<ContentEntity>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  tagIds?: Maybe<Array<Scalars['String']>>;
  tags: Array<TagUnion>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type AcademyTemplatesFilters = {
  academyTemplateIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export enum AccessLevelEnum {
  Edit = 'EDIT',
  None = 'NONE',
  Revoke = 'REVOKE',
  View = 'VIEW',
}

/** Assignee Info regarding externally linked action items */
export type ActionItemAssigneeInfo = {
  __typename?: 'ActionItemAssigneeInfo';
  /** emailAddress of the assignee */
  emailAddress?: Maybe<Scalars['String']>;
};

export type ActionItemAssigneeInfoInput = {
  /** Additional Data for actionItem */
  emailAddress?: InputMaybe<Scalars['String']>;
};

export type ActionItemCountByCategoryAndStatus = {
  __typename?: 'ActionItemCountByCategoryAndStatus';
  category: StatusCategoryEnum;
  count: Scalars['Float'];
  statusGroups: Array<ActionItemStatusCount>;
};

export type ActionItemCountsByCategoryAndStatusInput = {
  assignedToIds: Array<Scalars['String']>;
};

export type ActionItemCountsByCategoryAndStatusPayload = {
  __typename?: 'ActionItemCountsByCategoryAndStatusPayload';
  actionItemCounts: Array<ActionItemCountByCategoryAndStatus>;
  openActionItemTotalCount: Scalars['Float'];
};

/** Edge */
export type ActionItemEdge = {
  __typename?: 'ActionItemEdge';
  node: ActionItemType;
};

export type ActionItemEntityFilter = {
  entityId: Scalars['String'];
  entityType: RelatedEntityTypeEnum;
  /** Meeting Type if entityType is Meeting */
  meetingType?: InputMaybe<MeetingTypeEnum>;
};

export enum ActionItemExternalEntityTypeEnum {
  JiraIssue = 'JIRA_ISSUE',
}

export type ActionItemFilters = {
  actionItemId: Scalars['String'];
};

/** Data related to linking action item to any external entity */
export type ActionItemIntegrationConfig = {
  __typename?: 'ActionItemIntegrationConfig';
  /** All the ids of configs which result to this external entity */
  creationConfigIds?: Maybe<Scalars['String']>;
  /** Defines which type of entity it is linked to */
  externalEntityType?: Maybe<ActionItemExternalEntityTypeEnum>;
  /** External content to which action time is linked */
  externalId?: Maybe<Scalars['String']>;
  /** Is there any action item created via manual sync */
  manualSync?: Maybe<Scalars['Boolean']>;
  /** provider Id, to which this actionItem is externally link */
  providerId: Scalars['String'];
};

export type ActionItemIntegrationConfigFilter = {
  creationConfigIds?: InputMaybe<Array<Scalars['String']>>;
  /** Defines which type of entity it is linked to */
  externalEntityType?: InputMaybe<ActionItemExternalEntityTypeEnum>;
  /** External content to which action time is linked */
  externalId?: InputMaybe<Scalars['String']>;
  manualSync?: InputMaybe<Scalars['Boolean']>;
  /** provider Id, to which this actionItem is externally link */
  providerId?: InputMaybe<Scalars['String']>;
};

export type ActionItemIntegrationConfigInput = {
  /** All the ids of configs which result to this external entity */
  creationConfigIds?: InputMaybe<Scalars['String']>;
  /** Defines which type of entity it is linked to */
  externalEntityType?: InputMaybe<ActionItemExternalEntityTypeEnum>;
  /** External content to which action time is linked */
  externalId?: InputMaybe<Scalars['String']>;
  /** Is there any action item created via manual sync */
  manualSync?: InputMaybe<Scalars['Boolean']>;
  /** provider Id, to which this actionItem is externally link */
  providerId: Scalars['String'];
};

/** It will resolve different entities with actionItem linkedTo */
export type ActionItemLinkedToEntityPayloadUnion =
  | KeyResultType
  | MeetingType
  | ObjectiveType
  | PeerFeedbackSchema;

/** Additional information related to the actionItem */
export type ActionItemMetadata = {
  __typename?: 'ActionItemMetadata';
  /** Assignee info from external site */
  assigneeInfo?: Maybe<ActionItemAssigneeInfo>;
  /** Name to be shown on UI */
  displayName?: Maybe<Scalars['String']>;
  /** priority info from external site */
  priorityInfo?: Maybe<ActionItemPriorityInfo>;
  /** Status to be shown on UI */
  status?: Maybe<Scalars['String']>;
  /** Status id from external site */
  statusId?: Maybe<Scalars['String']>;
};

export type ActionItemMetadataInput = {
  /** Assignee info from external site */
  assigneeInfo?: InputMaybe<ActionItemAssigneeInfoInput>;
  /** Additional Data for actionItem */
  displayName?: InputMaybe<Scalars['String']>;
  /** priority info from external site */
  priorityInfo?: InputMaybe<ActionItemPriorityInfoInput>;
  /** status from external site */
  status?: InputMaybe<Scalars['String']>;
  /** status id from external site */
  statusId?: InputMaybe<Scalars['String']>;
};

export type ActionItemPayload = {
  __typename?: 'ActionItemPayload';
  actionItem?: Maybe<ActionItemType>;
};

export enum ActionItemPriorityEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  NoPriority = 'NO_PRIORITY',
  Urgent = 'URGENT',
}

/** priority Info regarding externally linked action items */
export type ActionItemPriorityInfo = {
  __typename?: 'ActionItemPriorityInfo';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ActionItemPriorityInfoInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ActionItemStatusCount = {
  __typename?: 'ActionItemStatusCount';
  count: Scalars['Float'];
  status: Scalars['String'];
  statusInfo?: Maybe<StatusType>;
};

export enum ActionItemSubTypeEnum {
  Align = 'ALIGN',
  Discuss = 'DISCUSS',
  Inform = 'INFORM',
  Update = 'UPDATE',
}

export type ActionItemSubscriptionInput = {
  /** ids of action items */
  actionItemIds: Array<Scalars['String']>;
  /** operations to subscribe on */
  operations?: InputMaybe<Array<ActionItemSubscriptionOperationEnum>>;
};

export enum ActionItemSubscriptionOperationEnum {
  ActionItemDeleted = 'ACTION_ITEM_DELETED',
  ActionItemUpdated = 'ACTION_ITEM_UPDATED',
}

export type ActionItemSubscriptionPayload = {
  __typename?: 'ActionItemSubscriptionPayload';
  actionItem: ActionItemType;
  operation: ActionItemSubscriptionOperationEnum;
};

export type ActionItemType = {
  __typename?: 'ActionItemType';
  assignedTo?: Maybe<UserType>;
  assignedToId?: Maybe<Scalars['String']>;
  assignedToProfile?: Maybe<UserProfile>;
  attachments?: Maybe<Array<EntityMediaType>>;
  category?: Maybe<StatusCategoryEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** This resolver returns channel of a actionItem */
  discussionChannel?: Maybe<ChannelType>;
  dueDate?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  /** External Link of the actionItem */
  externalLink?: Maybe<Scalars['String']>;
  file?: Maybe<FileSchema>;
  fileId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** ActionItem Instructions in text or media */
  instruction?: Maybe<InstructionType>;
  /** Details for external entity */
  integrationConfig?: Maybe<ActionItemIntegrationConfig>;
  /** Flag to check what is sync type, Before migration, value can remain undefined */
  isAutoSynced?: Maybe<Scalars['Boolean']>;
  lastSyncedAt?: Maybe<Scalars['String']>;
  lastUpdatedAt?: Maybe<Scalars['String']>;
  linkedToEntities?: Maybe<Array<ActionItemLinkedToEntityPayloadUnion>>;
  /** Additional Details for actionItem */
  metadata?: Maybe<ActionItemMetadata>;
  parentActionItem?: Maybe<ActionItemType>;
  parentId?: Maybe<Scalars['String']>;
  priority?: Maybe<ActionItemPriorityEnum>;
  progress?: Maybe<Scalars['Float']>;
  provider?: Maybe<ProviderType>;
  providerTags: Array<EntityTagType>;
  /** All the things which are related to task */
  relatedToEntities?: Maybe<Array<Scalars['String']>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: Scalars['String'];
  statusId?: Maybe<Scalars['String']>;
  statusInfo?: Maybe<StatusType>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<Maybe<EntityTagType>>>;
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ActionItemTypeTagArgs = {
  tagId: Scalars['String'];
};

export enum ActionItemTypeEnum {
  Agenda = 'AGENDA',
  Task = 'TASK',
}

export type ActionItemsFilters = {
  actionItemIds?: InputMaybe<Array<Scalars['String']>>;
  assignedToIds?: InputMaybe<Array<Scalars['String']>>;
  assigneeActionItemStatus?: InputMaybe<AssigneeActionItemFilterEnum>;
  categories?: InputMaybe<Array<StatusCategoryEnum>>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  dueDate?: InputMaybe<DateFilter>;
  entities?: InputMaybe<Array<ActionItemEntityFilter>>;
  entityTypes?: InputMaybe<Array<RelatedEntityTypeEnum>>;
  externalIds?: InputMaybe<Array<Scalars['String']>>;
  fileIds?: InputMaybe<Array<Scalars['String']>>;
  /** Details for external entity */
  integrationConfig?: InputMaybe<ActionItemIntegrationConfigFilter>;
  isAutoSynced?: InputMaybe<Scalars['Boolean']>;
  meetingIds?: InputMaybe<Array<Scalars['String']>>;
  notInTagIds?: InputMaybe<Array<Scalars['String']>>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  priorities?: InputMaybe<Array<ActionItemPriorityEnum>>;
  relatedToEntities?: InputMaybe<Array<ActionItemEntityFilter>>;
  reporteeFilterType?: InputMaybe<ReporteeFilterTypeInput>;
  rootActionItemStatus?: InputMaybe<RootActionItemFilter>;
  status?: InputMaybe<Array<Scalars['String']>>;
  statusIds?: InputMaybe<Array<Scalars['String']>>;
  /** Get actionItems based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  /** title of actionItem */
  title?: InputMaybe<Scalars['String']>;
  unCategorized?: InputMaybe<Scalars['Boolean']>;
};

export type ActionItemsPayload = {
  __typename?: 'ActionItemsPayload';
  actionItems?: Maybe<Array<ActionItemType>>;
};

export type ActivityMetricConfig = {
  __typename?: 'ActivityMetricConfig';
  learnerProfileReportId: Scalars['String'];
};

/** Edge */
export type ActivityMetricEdge = {
  __typename?: 'ActivityMetricEdge';
  node: ActivityMetricType;
};

export enum ActivityMetricEntityTypeEnum {
  OrgRole = 'ORG_ROLE',
}

export type ActivityMetricFilters = {
  activityMetricId: Scalars['String'];
};

export type ActivityMetricInput = {
  learnerProfileReportId: Scalars['String'];
};

export type ActivityMetricPayload = {
  __typename?: 'ActivityMetricPayload';
  activityMetric?: Maybe<ActivityMetricType>;
};

export type ActivityMetricReportConfig = {
  __typename?: 'ActivityMetricReportConfig';
  learnerProfileWidgetId: Scalars['String'];
};

export type ActivityMetricType = {
  __typename?: 'ActivityMetricType';
  convertedTargetValue: Scalars['Float'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  learnerProfileActivitiesReportWidget?: Maybe<WidgetUnion>;
  metric?: Maybe<MetricSchema>;
  metricId: Scalars['String'];
  name: Scalars['String'];
  orgRole?: Maybe<OrgRoleType>;
  orgRoleId: Scalars['String'];
  reportConfig?: Maybe<ActivityMetricReportConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  targetFrequency: RelativeDateFilterUnitEnum;
  targetValue: Scalars['Float'];
  trackId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type ActivityMetricTypeConvertedTargetValueArgs = {
  filters: GetConvertedTargetActivityMetricValue;
};

export type ActivityMetricWeightConfigInput = {
  distributionType: ActivityMetricWeightDistributionTypeEnum;
};

export type ActivityMetricWeightDistributionConfig = {
  __typename?: 'ActivityMetricWeightDistributionConfig';
  distributionType: ActivityMetricWeightDistributionTypeEnum;
};

export type ActivityMetricWeightDistributionConfigInput = {
  distributionType: ActivityMetricWeightDistributionTypeEnum;
};

export enum ActivityMetricWeightDistributionTypeEnum {
  Custom = 'CUSTOM',
  Equal = 'EQUAL',
}

export type ActivityMetricsFilters = {
  activityMetricIds?: InputMaybe<Array<Scalars['String']>>;
  metricIds?: InputMaybe<Array<Scalars['String']>>;
  orgRoleIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ActivityMetricsInputType = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  metricId: Scalars['String'];
  name: Scalars['String'];
  targetFrequency: RelativeDateFilterUnitEnum;
  targetValue: Scalars['Float'];
  weight?: InputMaybe<Scalars['Float']>;
};

export type AddAuditMetadataType = {
  __typename?: 'AddAuditMetadataType';
  action: AuditActionEnum;
  auditId: Scalars['String'];
  collectionName: EntityCollectionEnum;
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  /** Content that was bookmarked by the user */
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<InstructionType>;
  /** Display string for the actual value, a string to be shown on UI */
  displayField?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  /** Actual field that is being tracked/referenced in this audit metadata of the entity */
  fieldName: Scalars['String'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  newValue: Scalars['String'];
  relatedCollectionName?: Maybe<EntityCollectionEnum>;
  valueType: Scalars['String'];
  workspaceId: Scalars['String'];
};

/** Input to add a child block to a new parent */
export type AddChildBlockInput = {
  /** child entity's id */
  childBlockId: Scalars['String'];
  /** Parent entity's id */
  parentBlockId: Scalars['String'];
  /** Parent entity's type or table */
  parentType: Scalars['String'];
};

/** Response for create block mutation */
export type AddChildBlockPayload = {
  __typename?: 'AddChildBlockPayload';
  childBlock?: Maybe<BaseBlockType>;
  parentBlock?: Maybe<BaseBlockType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddConvertedMediaInput = {
  convertedMedia: ConvertedMediaInput;
  jobId?: InputMaybe<Scalars['String']>;
  resourceId?: InputMaybe<Scalars['String']>;
};

/** add exercise submission feedback input! */
export type AddExerciseSubmissionFeedbackInput = {
  entityId: Scalars['String'];
  mediaResponse?: InputMaybe<CreateMediaInput>;
  responseType: FeedbackResponseTypeEnum;
  submissionId: Scalars['String'];
  textResponse?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

/** Add exercise submission Feedback Response */
export type AddExerciseSubmissionFeedbackPayload = {
  __typename?: 'AddExerciseSubmissionFeedbackPayload';
  channelId?: Maybe<Scalars['String']>;
  currentReviewingState?: Maybe<CurrentReviewingState>;
  errorCode?: Maybe<PerformanceErrorCode>;
  post?: Maybe<PostType>;
  postId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddExternalMappingInput = {
  referredBy: Scalars['String'];
  uniqueId: Scalars['String'];
};

/** Inputs required to add permissions for a role */
export type AddPermissionsInput = {
  /** Permissions to be added */
  permittedOn: RolePermittedOnInput;
  /** roleId of Role in which you want to add permissions */
  roleId: Scalars['String'];
};

export type AddPermissionsPayload = {
  __typename?: 'AddPermissionsPayload';
  /** Whether permission was created successfully or not */
  success: Scalars['Boolean'];
};

export type AddReactionInput = {
  /** ChannelId on which the post exists */
  channelId: Scalars['String'];
  /** Post Id on which the reaction needs to be added */
  postId: Scalars['String'];
  /** Actual reaction */
  reaction: Scalars['String'];
};

/** Add recordings of the event response */
export type AddRecordingsToClassroomEventResponse = {
  __typename?: 'AddRecordingsToClassroomEventResponse';
  success: Scalars['Boolean'];
};

export type AddRecordingsToEventInput = {
  eventId: Scalars['String'];
  recordings: Array<Scalars['String']>;
};

export type AddSkillInOrgRoleInput = {
  expectedScore: Scalars['Float'];
  skillGroupId: Scalars['String'];
  skillId: Scalars['String'];
};

export type AddSkillsToSkillGroupInput = {
  /** skillGroupId to which given skillIds should be added */
  skillGroupId: Scalars['String'];
  /** skillId to add */
  skillIds: Array<Scalars['String']>;
};

/** add submission feedback input! */
export type AddSubmissionFeedbackInput = {
  entityId: Scalars['String'];
  mediaResponse?: InputMaybe<CreateMediaInput>;
  responseType: FeedbackResponseTypeEnum;
  textResponse?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

/** Add Submission Feedback Response */
export type AddSubmissionFeedbackPayload = {
  __typename?: 'AddSubmissionFeedbackPayload';
  channelId?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Object for adding tokens for push notification for an user. */
export type AddUserPushNotificationTokenInput = {
  /** token object, which will contain information along with tokenId */
  tokens?: InputMaybe<Array<NotificationTokenInput>>;
  /** User id for which tokens are added. */
  userId: Scalars['String'];
};

export type AddUserSearchActivityInput = {
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<SearchableContentTypeEnum>;
  /** search text of the context user */
  searchText: Scalars['String'];
};

export type AddUserSearchActivityPayload = {
  __typename?: 'AddUserSearchActivityPayload';
  /** new user search activity */
  userSearchActivity?: Maybe<UserSearchActivityType>;
};

export type AddUsersInEventInput = {
  eventId: Scalars['String'];
  groupIds: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

/** Add users to event response */
export type AddUsersToClassroomEventResponse = {
  __typename?: 'AddUsersToClassroomEventResponse';
  success: Scalars['Boolean'];
};

export type AddUsersToEventScheduleInput = {
  eventScheduleId: Scalars['String'];
  eventScheduleUserRole: EventUserRoleEnum;
  userIds: Array<Scalars['String']>;
};

/** Add users to event schedule response */
export type AddUsersToEventSchedulePayload = {
  __typename?: 'AddUsersToEventSchedulePayload';
  errorCode?: Maybe<Scalars['String']>;
  existingUserIds?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Add users to event schedule response */
export type AddUsersToEventScheduleResponse = {
  __typename?: 'AddUsersToEventScheduleResponse';
  errorCode?: Maybe<Scalars['String']>;
  existingUserIds?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type AddUsersToStaffRolesInput = {
  roleIds: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

/** ReviewCycle Schema */
export type AdvanceSettingsType = {
  __typename?: 'AdvanceSettingsType';
  /** field to check for comments */
  allowFeedbackComments?: Maybe<Scalars['Boolean']>;
  /** only above or below this level of manager can do this review cycle  */
  level?: Maybe<Scalars['Float']>;
};

export enum AffiliationsTypeEnum {
  Lyearn = 'LYEARN',
  Razorpay = 'RAZORPAY',
  Stripe = 'STRIPE',
}

export type AgendaActionItemsInput = {
  assignedToName?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  title: Scalars['String'];
};

export type AgendaActionItemsInputPropsType = {
  __typename?: 'AgendaActionItemsInputPropsType';
  assignedToName?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title: Scalars['String'];
};

/** This returns paginated agenda */
export type AgendaConnection = {
  __typename?: 'AgendaConnection';
  edges?: Maybe<Array<AgendaEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Agenda Database Input */
export type AgendaDatabaseInput = {
  /** Database Id related to agenda */
  dbId: Scalars['String'];
  /** Database View for the agenda */
  viewId: Scalars['String'];
};

/** Defines database schema in Agenda */
export type AgendaDatabaseType = {
  __typename?: 'AgendaDatabaseType';
  /** Used to resolve user response of a form. Only be used via checkin */
  data?: Maybe<DatabaseDataType>;
  dbId: Scalars['String'];
  dbMetadata: DatabaseMetadataType;
  dbView: DatabaseViewType;
  viewId: Scalars['String'];
};

/** Edge */
export type AgendaEdge = {
  __typename?: 'AgendaEdge';
  node: AgendaType;
};

/** Defines filters for fetching a single agenda */
export type AgendaFilters = {
  agendaId: Scalars['String'];
};

/** Edge */
export type AgendaItemEdge = {
  __typename?: 'AgendaItemEdge';
  node: AgendaItemType;
};

export type AgendaItemEntityFilter = {
  entityId: Scalars['String'];
  entityType: AgendaItemEntityTypeEnum;
};

export type AgendaItemEntityInput = {
  entityId: Scalars['String'];
  entityType: AgendaItemEntityTypeEnum;
};

export enum AgendaItemEntityTypeEnum {
  AgendaItemTemplate = 'AGENDA_ITEM_TEMPLATE',
  MeetingInstance = 'MEETING_INSTANCE',
  Workspace = 'WORKSPACE',
}

export type AgendaItemFilters = {
  agendaItemId: Scalars['String'];
};

export type AgendaItemPayload = {
  __typename?: 'AgendaItemPayload';
  agendaItem?: Maybe<AgendaItemType>;
};

export enum AgendaItemStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export type AgendaItemSubscriptionInput = {
  /** ids of agenda items */
  agendaItemIds: Array<Scalars['String']>;
  /** operations to subscribe on */
  operations?: InputMaybe<Array<AgendaItemSubscriptionOperationEnum>>;
};

export enum AgendaItemSubscriptionOperationEnum {
  ActionItemAdded = 'ACTION_ITEM_ADDED',
  ActionItemRemoved = 'ACTION_ITEM_REMOVED',
  AgendaItemDeleted = 'AGENDA_ITEM_DELETED',
  AgendaItemUpdated = 'AGENDA_ITEM_UPDATED',
}

export type AgendaItemSubscriptionPayload = {
  __typename?: 'AgendaItemSubscriptionPayload';
  agendaItem: AgendaItemType;
  operation: AgendaItemSubscriptionOperationEnum;
};

/** Edge */
export type AgendaItemTemplateEdge = {
  __typename?: 'AgendaItemTemplateEdge';
  node: AgendaItemTemplateType;
};

export type AgendaItemTemplateFilters = {
  agendaItemTemplateId: Scalars['String'];
};

export type AgendaItemTemplatePayload = {
  __typename?: 'AgendaItemTemplatePayload';
  agendaItemTemplate?: Maybe<AgendaItemTemplateType>;
};

export enum AgendaItemTemplateStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export type AgendaItemTemplateType = {
  __typename?: 'AgendaItemTemplateType';
  agendaItemIds: Array<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  meetingsCount: Scalars['Float'];
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: AgendaItemTemplateStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type AgendaItemTemplatesFilters = {
  agendaItemTemplateIds?: InputMaybe<Array<Scalars['String']>>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AgendaItemType = {
  __typename?: 'AgendaItemType';
  actionItemsCount: Scalars['Float'];
  activityMetricIds?: Maybe<Array<Scalars['String']>>;
  attachments?: Maybe<Array<EntityMediaType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationCadenceConfig?: Maybe<CadenceConfig>;
  entity: Scalars['String'];
  id: Scalars['String'];
  /** ActionItem Instructions in text or media */
  instruction?: Maybe<InstructionType>;
  isChecked: Scalars['Boolean'];
  mindsetIds?: Maybe<Array<Scalars['String']>>;
  okrsConfig?: Maybe<OkrSelectionConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  sourceType?: Maybe<AgendaItemEntityTypeEnum>;
  status: AgendaItemStatusEnum;
  subType: ActionItemSubTypeEnum;
  tasks: Array<ActionItemType>;
  title: Scalars['String'];
  type: ActionItemTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type AgendaItemsFilters = {
  agendaItemIds?: InputMaybe<Array<Scalars['String']>>;
  entities?: InputMaybe<Array<AgendaItemEntityFilter>>;
  sourceTypes?: InputMaybe<Array<AgendaItemEntityTypeEnum>>;
};

export type AgendaItemsPayload = {
  __typename?: 'AgendaItemsPayload';
  agendaItems?: Maybe<Array<AgendaItemType>>;
};

export type AgendaOkRsInput = {
  dueDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerNames?: InputMaybe<Array<Scalars['String']>>;
  progress: Scalars['Float'];
  status: Scalars['String'];
};

export type AgendaOkRsInputPropsType = {
  __typename?: 'AgendaOKRsInputPropsType';
  dueDate?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerNames?: Maybe<Array<Scalars['String']>>;
  progress: Scalars['Float'];
  status: Scalars['String'];
};

/** Agenda Schema */
export type AgendaType = {
  __typename?: 'AgendaType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Database linked with the agenda */
  database?: Maybe<AgendaDatabaseType>;
  /** Description about agenda */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Media objects related to the agenda */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Agenda Name */
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Type of agenda */
  type: AgendaTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum AgendaTypeEnum {
  Decision = 'DECISION',
  Feedback = 'FEEDBACK',
  Review = 'REVIEW',
}

/** Defines filters for fetching multiple agendas */
export type AgendasFilters = {
  agendaIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Aggregated User Metrics Type */
export type AggregatedUserMetricsType = {
  __typename?: 'AggregatedUserMetricsType';
  /** Attendance status */
  attendance: EventUserMetricsAttendanceEnum;
  /** Attendance duration */
  attendanceDuration: Scalars['Int'];
  /** Number of connection drops during an event */
  numberOfDrops: Scalars['Int'];
};

/** Defines aggregation operation on a particular field */
export type AggregationInput = {
  aggregator: AggregationTypeEnum;
  property: Scalars['String'];
};

/** Defines array of aggregation operations */
export type AggregationSetInput = {
  set: Array<AggregationInput>;
};

/** Defines aggregation criteria for a particular field */
export type AggregationType = {
  __typename?: 'AggregationType';
  /** Query Aggregator */
  aggregator: AggregationTypeEnum;
  /** Field Id */
  property: Scalars['String'];
};

export enum AggregationTypeEnum {
  Avg = 'AVG',
  Checked = 'CHECKED',
  CountAll = 'COUNT_ALL',
  CountEmpty = 'COUNT_EMPTY',
  CountNotEmpty = 'COUNT_NOT_EMPTY',
  CountUniqueValues = 'COUNT_UNIQUE_VALUES',
  CountValues = 'COUNT_VALUES',
  DateRange = 'DATE_RANGE',
  EarliestDate = 'EARLIEST_DATE',
  LatestDate = 'LATEST_DATE',
  Max = 'MAX',
  Median = 'MEDIAN',
  Min = 'MIN',
  PercentChecked = 'PERCENT_CHECKED',
  PercentEmpty = 'PERCENT_EMPTY',
  PercentNotEmpty = 'PERCENT_NOT_EMPTY',
  PercentUnchecked = 'PERCENT_UNCHECKED',
  Range = 'RANGE',
  Unchecked = 'UNCHECKED',
}

export type AllAcademyTemplatesPayload = {
  __typename?: 'AllAcademyTemplatesPayload';
  academyTemplates: Array<AcademyTemplateType>;
};

export type AllActionItemsPayload = {
  __typename?: 'AllActionItemsPayload';
  actionItems: Array<ActionItemType>;
};

export type AllActivityMetricsPayload = {
  __typename?: 'AllActivityMetricsPayload';
  activityMetrics: Array<ActivityMetricType>;
};

export type AllCertificatesPayload = {
  __typename?: 'AllCertificatesPayload';
  certificates: Array<CertificateType>;
};

export type AllCloudMediaProxyLinksFromMediaInput = {
  medias: Array<CreateMediaInput>;
};

export type AllCloudMediaProxyLinksFromMediaPayload = {
  __typename?: 'AllCloudMediaProxyLinksFromMediaPayload';
  cloudProxyMediaLinksPayloads: Array<CloudMediaProxyLinkFromMediaPayload>;
};

export type AllConfigsPayload = {
  __typename?: 'AllConfigsPayload';
  configs: Array<ConfigType>;
};

export type AllEventsPayload = {
  __typename?: 'AllEventsPayload';
  events: Array<EventUnion>;
};

export type AllFilesPayload = {
  __typename?: 'AllFilesPayload';
  files: Array<FileSchema>;
};

export type AllJiraIssuesPayload = {
  __typename?: 'AllJiraIssuesPayload';
  jiraIssues: Array<JiraIssueType>;
};

export type AllMediasPayload = {
  __typename?: 'AllMediasPayload';
  medias: Array<MediaType>;
};

export type AllMetricsPayload = {
  __typename?: 'AllMetricsPayload';
  metrics: Array<MetricSchema>;
};

export type AllOkRsPayload = {
  __typename?: 'AllOKRsPayload';
  okrs?: Maybe<Array<OkrUnion>>;
};

export type AllPeerFeedbackTemplatesPayload = {
  __typename?: 'AllPeerFeedbackTemplatesPayload';
  peerFeedbackTemplates: Array<PeerFeedbackTemplateSchema>;
};

export type AllPeerFeedbacksPayload = {
  __typename?: 'AllPeerFeedbacksPayload';
  peerFeedbacks: Array<PeerFeedbackSchema>;
};

export type AllReportsPayload = {
  __typename?: 'AllReportsPayload';
  reports: Array<ReportType>;
};

export type AllSignedMediaUrlPayload = {
  __typename?: 'AllSignedMediaUrlPayload';
  mediaId: Scalars['String'];
  signedMediaUrl?: Maybe<Scalars['String']>;
};

export type AllSignedMediaUrlsInput = {
  signedMediaUrlsInput: Array<GetSignedMediaUrlInput>;
};

export type AllSignedMediaUrlsPayload = {
  __typename?: 'AllSignedMediaUrlsPayload';
  signedMediaUrlsPayloads: Array<AllSignedMediaUrlPayload>;
};

export type AllSkillCategoriesInput = {
  generatedBy?: InputMaybe<GeneratedByEnum>;
};

/** All Distinct Skill Categories */
export type AllSkillCategoriesPayload = {
  __typename?: 'AllSkillCategoriesPayload';
  skillCategories: Array<SkillCategoryType>;
};

export type AllTagsPayload = {
  __typename?: 'AllTagsPayload';
  tags: Array<TagUnion>;
};

/** Payload for all user groups */
export type AllUserGroupsPayload = {
  __typename?: 'AllUserGroupsPayload';
  userGroups: Array<UserGroupType>;
};

export type AllUserViewsPayload = {
  __typename?: 'AllUserViewsPayload';
  userViews: Array<UserViewSchema>;
};

export type AllWebhooksPayload = {
  __typename?: 'AllWebhooksPayload';
  webhooks: Array<WebhookType>;
};

export type AllWidgetsPayload = {
  __typename?: 'AllWidgetsPayload';
  widgets: Array<WidgetUnion>;
};

export type AllowedEntityPermissions = {
  __typename?: 'AllowedEntityPermissions';
  dependentOn: Array<Scalars['String']>;
  /** @deprecated move to entity */
  entity: AllowedPermissionsEntityType;
  key: Scalars['String'];
  label: Scalars['String'];
  permittedOn: Array<EntityPermittedOn>;
};

export enum AllowedPermissionActionsEnum {
  Approve = 'APPROVE',
  Archive = 'ARCHIVE',
  Attach = 'ATTACH',
  Checkin = 'CHECKIN',
  Create = 'CREATE',
  Edit = 'EDIT',
  Enroll = 'ENROLL',
  Hide = 'HIDE',
  Invite = 'INVITE',
  List = 'LIST',
  Manage = 'MANAGE',
  Masquerade = 'MASQUERADE',
  Pin = 'PIN',
  Publish = 'PUBLISH',
  Read = 'READ',
  Reject = 'REJECT',
  Remove = 'REMOVE',
  Send = 'SEND',
  ShareContent = 'SHARE_CONTENT',
}

export enum AllowedPermissionsEntityType {
  Academy = 'ACADEMY',
  Assignment = 'ASSIGNMENT',
  Authentication = 'AUTHENTICATION',
  Billing = 'BILLING',
  Certificate = 'CERTIFICATE',
  Community = 'COMMUNITY',
  CommunityDiscussionChannel = 'COMMUNITY_DISCUSSION_CHANNEL',
  Department = 'DEPARTMENT',
  EmailDigest = 'EMAIL_DIGEST',
  Event = 'EVENT',
  File = 'FILE',
  Group = 'GROUP',
  Homepage = 'HOMEPAGE',
  Integration = 'INTEGRATION',
  Okr = 'OKR',
  OkrCycle = 'OKR_CYCLE',
  OkrDiscussionChannel = 'OKR_DISCUSSION_CHANNEL',
  Report = 'REPORT',
  Resource = 'RESOURCE',
  ReviewCycle = 'REVIEW_CYCLE',
  Role = 'ROLE',
  SharedConnection = 'SHARED_CONNECTION',
  Skill = 'SKILL',
  SkillMatrix = 'SKILL_MATRIX',
  Subaccount = 'SUBACCOUNT',
  Tag = 'TAG',
  User = 'USER',
  Workspace = 'WORKSPACE',
}

export enum AllowedPermissionsSubjectType {
  Academy = 'ACADEMY',
  Assignment = 'ASSIGNMENT',
  Attendance = 'ATTENDANCE',
  Authentication = 'AUTHENTICATION',
  Billing = 'BILLING',
  Category = 'CATEGORY',
  Certificate = 'CERTIFICATE',
  Channel = 'CHANNEL',
  Community = 'COMMUNITY',
  CompanyObjective = 'COMPANY_OBJECTIVE',
  ContentCollection = 'CONTENT_COLLECTION',
  ContentSubworkspace = 'CONTENT_SUBWORKSPACE',
  Department = 'DEPARTMENT',
  DepartmentObjective = 'DEPARTMENT_OBJECTIVE',
  EmailDigest = 'EMAIL_DIGEST',
  Event = 'EVENT',
  EventSubworkspace = 'EVENT_SUBWORKSPACE',
  File = 'FILE',
  Group = 'GROUP',
  Homepage = 'HOMEPAGE',
  IndividualObjective = 'INDIVIDUAL_OBJECTIVE',
  Integration = 'INTEGRATION',
  Learner = 'LEARNER',
  Members = 'MEMBERS',
  Okr = 'OKR',
  OkrCycle = 'OKR_CYCLE',
  Post = 'POST',
  Reply = 'REPLY',
  Report = 'REPORT',
  Resource = 'RESOURCE',
  ReviewCycle = 'REVIEW_CYCLE',
  Role = 'ROLE',
  SharedConnection = 'SHARED_CONNECTION',
  Skill = 'SKILL',
  SkillMatrix = 'SKILL_MATRIX',
  Space = 'SPACE',
  Staff = 'STAFF',
  Subaccount = 'SUBACCOUNT',
  SubWorkspace = 'SUB_WORKSPACE',
  Tag = 'TAG',
  TagSubworkspace = 'TAG_SUBWORKSPACE',
  TeamObjective = 'TEAM_OBJECTIVE',
  User = 'USER',
  UserCollection = 'USER_COLLECTION',
}

/** Edge */
export type AnalyticsDashboardEdge = {
  __typename?: 'AnalyticsDashboardEdge';
  node: AnalyticsDashboardType;
};

export enum AnalyticsDashboardErrorCodeEnum {
  NotPresent = 'NOT_PRESENT',
}

export enum AnalyticsDashboardStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

/** AnalyticsDashboard Type */
export type AnalyticsDashboardType = {
  __typename?: 'AnalyticsDashboardType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Description of a analyticsDashboard */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Name or title of a analyticsDashboard */
  name?: Maybe<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** AnalyticsDashboard Status */
  status: AnalyticsDashboardStatusEnum;
  /** StoreId of store to which this analyticsDashboard belongs to */
  storeId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type AnalyticsDashboardsFilters = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  status: Array<AnalyticsDashboardStatusEnum>;
  storeId?: InputMaybe<Scalars['String']>;
};

export enum ApplicableOnEnum {
  Classroom = 'CLASSROOM',
  Resource = 'RESOURCE',
  Workspace = 'WORKSPACE',
}

export type ApplicationSortType = {
  __typename?: 'ApplicationSortType';
  /** Sort Order */
  order: Array<Scalars['String']>;
  /** Field Id */
  property: Scalars['String'];
};

export enum ArchivalReasonEnum {
  ContentArchived = 'CONTENT_ARCHIVED',
  SourceEventRevoked = 'SOURCE_EVENT_REVOKED',
  UserArchived = 'USER_ARCHIVED',
}

export type ArchiveAnalyticsDashboardInput = {
  analyticsId: Scalars['String'];
};

export type ArchiveAnalyticsDashboardPayload = {
  __typename?: 'ArchiveAnalyticsDashboardPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type ArchiveClassroomEventInput = {
  eventId: Scalars['String'];
};

/** Archive event response */
export type ArchiveClassroomEventPayload = {
  __typename?: 'ArchiveClassroomEventPayload';
  errorCode?: Maybe<ClassroomEventErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ArchiveClassroomInput = {
  classroomId: Scalars['String'];
};

export type ArchiveClassroomPayload = {
  __typename?: 'ArchiveClassroomPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type ArchiveContentInput = {
  entityId: Scalars['String'];
};

export type ArchiveGroupInput = {
  groupId: Scalars['String'];
};

export type ArchiveTagInput = {
  tagId: Scalars['String'];
};

export enum ArrayOperationCommandEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Set = 'SET',
}

export type ArticleAuthorInputType = {
  name: Scalars['String'];
  picture?: InputMaybe<Scalars['String']>;
};

/** Holds name and profilePicture of the author for the article */
export type ArticleAuthorType = {
  __typename?: 'ArticleAuthorType';
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
};

/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentType = {
  __typename?: 'ArticleContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  /** Author of the article. Creator of the article is the default author */
  author: ArticleAuthorType;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  creatorCommentChannel?: Maybe<ChannelType>;
  /** comment channel for creators */
  creatorCommentChannelId?: Maybe<Scalars['String']>;
  defaultGroupId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  duplicatedFrom?: Maybe<Scalars['String']>;
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** duration of content in seconds */
  duration: Scalars['Float'];
  /** Feedback Questions for the article */
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  instructors: Array<InstructorType>;
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  /** duration of content */
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  performance?: Maybe<ArticlePerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  /** Root blockId of the article */
  rootBlockId?: Maybe<Scalars['String']>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Ids of skill related to the content */
  skillIds: Array<Scalars['String']>;
  skills: Array<SkillType>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionConfigs?: Maybe<Array<VersionConfig>>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings: VisibilitySettingsType;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeRootBlockIdArgs = {
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};

/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Article resource type. It is one of the kind of resources supported in Lyearn */
export type ArticleContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export type ArticleNodeStubType = {
  __typename?: 'ArticleNodeStubType';
  content: Scalars['String'];
};

/** Article Performance Type */
export type ArticlePerformanceType = {
  __typename?: 'ArticlePerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float'];
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type ArticleResultType = {
  __typename?: 'ArticleResultType';
  nodes?: Maybe<Array<ArticleNodeStubType>>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** This is the input for AssignContent Enrollment Settings */
export type AssignContentEnrollmentSettingsInput = {
  /** tells whether the user is assigned directly or not */
  direct?: InputMaybe<Scalars['Boolean']>;
  /** tells whether the user is self enrolled or not */
  selfEnrolled?: InputMaybe<Scalars['Boolean']>;
};

/** This is the input for assigning a content! */
export type AssignContentInput = {
  dueDate?: InputMaybe<Scalars['String']>;
  enrollmentSettings?: InputMaybe<AssignContentEnrollmentSettingsInput>;
  entityIds: Array<Scalars['String']>;
  notificationSettings?: InputMaybe<AssignContentNotificationSettings>;
  userGroupIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** This is the input for AssignContent Notification Settings */
export type AssignContentNotificationSettings = {
  sendNotification?: InputMaybe<Scalars['Boolean']>;
};

/** payload for AssignContentPayload */
export type AssignContentPayload = {
  __typename?: 'AssignContentPayload';
  contents?: Maybe<Array<ContentEntity>>;
  entityIds?: Maybe<Array<Scalars['String']>>;
  errorCode?: Maybe<PerformanceErrorCode>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AssignContentUserNotification = {
  __typename?: 'AssignContentUserNotification';
  assignedAt: Scalars['String'];
  assignerId: Scalars['String'];
  assignmentId: Scalars['String'];
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  dueDate?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Input to Assign an assignment */
export type AssignHomeworkInput = {
  /**  HomeworkId, one needs to Assign */
  homeworkId: Scalars['String'];
};

export type AssignHomeworkPayload = {
  __typename?: 'AssignHomeworkPayload';
  /** Error Code regarding assign assignment */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  /** Updated Assignment document */
  homework?: Maybe<HomeworkType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AssignedToIdSetInput = {
  /** used to set assignee in action item */
  set?: InputMaybe<Scalars['String']>;
};

export enum AssigneeActionItemFilterEnum {
  AssignedItems = 'ASSIGNED_ITEMS',
  Both = 'BOTH',
  UnAssignedItems = 'UN_ASSIGNED_ITEMS',
}

/** This returns paginated Assignments */
export type AssignmentConnection = {
  __typename?: 'AssignmentConnection';
  edges?: Maybe<Array<AssignmentEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export enum AssignmentDueDateEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  NoDueDate = 'NO_DUE_DATE',
  Relative = 'RELATIVE',
}

/** Edge */
export type AssignmentEdge = {
  __typename?: 'AssignmentEdge';
  node: AssignmentType;
};

export type AssignmentImportMetadataType = {
  __typename?: 'AssignmentImportMetadataType';
  createdAssignmentsCount: Scalars['Float'];
};

export type AssignmentItemDueDateConfig = {
  __typename?: 'AssignmentItemDueDateConfig';
  /** Only present if dueDates are not set on nested sections/contents */
  dueDateConfig?: Maybe<DueDateAssignmentConfig>;
  /** The nesting level at which the dueDate is set on. Only present if dueDates are set on nested contents */
  dueDateNestingLevel?: Maybe<DueDateNestingLevelEnum>;
  itemId: Scalars['String'];
  /** Type of the item being assigned */
  itemType: AssignmentItemTypeEnum;
  /** Only present if dueDates are set on nested sections/contents */
  nestedDueDateConfig?: Maybe<Array<NestedDueDateConfig>>;
};

export type AssignmentItemDueDateConfigInput = {
  /** Only present if dueDates are not set on nested sections/contents */
  dueDateConfig?: InputMaybe<DueDateAssignmentConfigInput>;
  /** The nesting level at which the dueDate is set on. Only present if dueDates are set on nested sections/contents */
  dueDateNestingLevel?: InputMaybe<DueDateNestingLevelEnum>;
  itemId: Scalars['String'];
  /** Type of the item being assigned */
  itemType: AssignmentItemTypeEnum;
  /** Only present if dueDates are set on nested sections/contents */
  nestedDueDateConfig?: InputMaybe<Array<NestedDueDateConfigInput>>;
};

export enum AssignmentItemTypeEnum {
  Collection = 'COLLECTION',
  Content = 'CONTENT',
  Section = 'SECTION',
}

export type AssignmentPayload = {
  __typename?: 'AssignmentPayload';
  assignment?: Maybe<AssignmentType>;
};

export type AssignmentRestrictionInput = {
  roleIds?: InputMaybe<Array<Scalars['String']>>;
  type: AssignmentRestrictionTypeEnum;
};

/** Assignment restrictions for content */
export type AssignmentRestrictionType = {
  __typename?: 'AssignmentRestrictionType';
  /** role ids which can assign permissions */
  roleIds?: Maybe<Array<Scalars['String']>>;
  type: AssignmentRestrictionTypeEnum;
};

export enum AssignmentRestrictionTypeEnum {
  All = 'ALL',
  Restricted = 'RESTRICTED',
}

export enum AssignmentScheduleDateEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  NoScheduleDate = 'NO_SCHEDULE_DATE',
}

export type AssignmentStatsInput = {
  collectionIds: Array<Scalars['String']>;
  contentIds: Array<Scalars['String']>;
  userGroupIds: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

export type AssignmentStatsPayload = {
  __typename?: 'AssignmentStatsPayload';
  contentCount: Scalars['Float'];
  errorMessage?: Maybe<Scalars['String']>;
  notVisibleContentIds: Array<Scalars['String']>;
  notVisibleContents: Array<ContentEntity>;
  userCount: Scalars['Float'];
};

export enum AssignmentStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED',
}

export type AssignmentSubWorkspaceIds = {
  set: Array<Scalars['String']>;
};

export type AssignmentSubscriptionInput = {
  assignmentId: Scalars['String'];
  /** operations to subscribe on */
  operations?: InputMaybe<Array<AssignmentSubscriptionOperationEnum>>;
};

export enum AssignmentSubscriptionOperationEnum {
  AssignmentUpdated = 'ASSIGNMENT_UPDATED',
}

export type AssignmentSubscriptionPayload = {
  __typename?: 'AssignmentSubscriptionPayload';
  assignment: AssignmentType;
  operation: AssignmentSubscriptionOperationEnum;
};

/** Information regarding assignment */
export type AssignmentType = {
  __typename?: 'AssignmentType';
  assignedAt?: Maybe<Scalars['String']>;
  assigner?: Maybe<UserType>;
  assignerId: Scalars['String'];
  assignmentItemsDueDateConfig?: Maybe<Array<AssignmentItemDueDateConfig>>;
  /** number associate with assignment */
  assignmentNumber: Scalars['Float'];
  collectionContents: Array<CollectionContentType>;
  collectionIds: Array<Scalars['String']>;
  contentCount: Scalars['Float'];
  contentIds: Array<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  dueDateConfig: DueDateAssignmentConfig;
  id: Scalars['String'];
  /** Title of assignment */
  name?: Maybe<Scalars['String']>;
  notificationConfigurations?: Maybe<Array<NotificationConfigurationType>>;
  scheduleDateConfig: ScheduleDateAssignmentConfig;
  scheduledJobConfig?: Maybe<ScheduledJobAssignmentConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status of the Assignment */
  status: AssignmentStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userCount: Scalars['Float'];
  userGroupIds: Array<Scalars['String']>;
  userGroupSyncConfigs: UserGroupSyncAssignmentConfigs;
  userIds: Array<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type AssignmentsFilters = {
  assignedOn?: InputMaybe<DateFilter>;
  assignerId?: InputMaybe<Scalars['String']>;
  collectionIds?: InputMaybe<Array<Scalars['String']>>;
  contentIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateFilter>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<AssignmentStatusEnum>>;
  userGroupIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AssociationsSetInput = {
  /** To which course the question is associated */
  set: Array<QuestionAssociationInput>;
};

export type AttachActivityMetricsInput = {
  activityMetrics?: InputMaybe<Array<ActivityMetricsInputType>>;
  entityId: Scalars['String'];
  entityType?: InputMaybe<ActivityMetricEntityTypeEnum>;
  weightConfig?: InputMaybe<ActivityMetricWeightConfigInput>;
};

export type AttachActivityMetricsPayload = {
  __typename?: 'AttachActivityMetricsPayload';
  activityMetrics?: Maybe<ActivityMetricType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** It will resolve different entities which has attachments */
export type AttachmentEntityUnion =
  | ActionItemType
  | AgendaItemType
  | KeyResultType
  | MeetingType
  | MetricSchema
  | ObjectiveType;

export type AttachmentsSetInput = {
  /** Medias not in this list would be deleted and new medias in this list will be added */
  set: Array<CreateMediaInput>;
};

/** Updates attendances of all users of an event */
export type AttendanceOfEvent = {
  attendance: EventUserMetricsAttendanceEnum;
  eventId: Scalars['String'];
};

/** Updates attendance status of an user for the given event */
export type AttendanceOfUser = {
  attendance: EventUserMetricsAttendanceEnum;
  eventId: Scalars['String'];
  userId: Scalars['String'];
};

export enum AttendanceStatusEnum {
  Attended = 'ATTENDED',
  NotAttended = 'NOT_ATTENDED',
  NotMarked = 'NOT_MARKED',
  Partial = 'PARTIAL',
}

/** This is the input for attributes related to sync progress input */
export type AttributesInput = {
  conferenceBridgeId?: InputMaybe<Scalars['String']>;
  isMicOn?: InputMaybe<Scalars['Boolean']>;
  isVideoOn?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['String']>;
  progress?: InputMaybe<Scalars['Float']>;
  recordingId?: InputMaybe<Scalars['String']>;
};

export enum AuditActionEnum {
  Add = 'ADD',
  Checkin = 'CHECKIN',
  Create = 'CREATE',
  Remove = 'REMOVE',
  Set = 'SET',
  Unset = 'UNSET',
  Update = 'UPDATE',
}

export type AuditFilters = {
  auditIds?: InputMaybe<Array<Scalars['String']>>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  workspaceIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AuditMetadataConnection = {
  __typename?: 'AuditMetadataConnection';
  edges?: Maybe<Array<AuditMetadataEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type AuditMetadataEdge = {
  __typename?: 'AuditMetadataEdge';
  node: AuditMetadataUnion;
};

export type AuditMetadataFilters = {
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  workspaceIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AuditMetadataMessageInfo = {
  __typename?: 'AuditMetadataMessageInfo';
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  /** Who created this audit metadata */
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<InstructionType>;
  entityId: Scalars['String'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type AuditMetadataMessageInfoConnection = {
  __typename?: 'AuditMetadataMessageInfoConnection';
  edges?: Maybe<Array<AuditMetadataMessageInfoEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type AuditMetadataMessageInfoEdge = {
  __typename?: 'AuditMetadataMessageInfoEdge';
  node: AuditMetadataMessageInfo;
};

/** It will resolve different AuditMetadata types */
export type AuditMetadataUnion =
  | AddAuditMetadataType
  | CheckinAuditMetadataType
  | CreateAuditMetadataType
  | RemoveAuditMetadataType
  | SetAuditMetadataType
  | UnsetAuditMetadataType
  | UpdateAuditMetadataType;

export type AuditUpdateInfo = {
  __typename?: 'AuditUpdateInfo';
  displayField?: Maybe<Scalars['String']>;
  fieldName: Scalars['String'];
  value: Scalars['String'];
  valueType: Scalars['String'];
};

export type AuditUpdateInfoInput = {
  displayField?: InputMaybe<Scalars['String']>;
  fieldName: Scalars['String'];
  value: Scalars['String'];
  valueType: Scalars['String'];
};

export type AuthBackground = {
  __typename?: 'AuthBackground';
  imageName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  layout: BackgroundLayoutTypeEnum;
};

export type AuthBackgroundInput = {
  imageName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  layout?: InputMaybe<BackgroundLayoutTypeEnum>;
};

export type AuthBackgroundType = {
  __typename?: 'AuthBackgroundType';
  imageName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  layout: BackgroundLayoutTypeEnum;
};

export type AuthConfig = {
  __typename?: 'AuthConfig';
  authPageUrl?: Maybe<Scalars['String']>;
  background?: Maybe<AuthBackgroundType>;
  restrictedSignUp?: Maybe<Scalars['Boolean']>;
  signUpAllowed?: Maybe<Scalars['Boolean']>;
  spCertificate: Scalars['String'];
  strategyMethods: Array<StrategyMethod>;
  strategyMethodsListing: Array<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AuthConfigType = {
  __typename?: 'AuthConfigType';
  authPageUrl?: Maybe<Scalars['String']>;
  background?: Maybe<AuthBackground>;
  restrictedSignUp?: Maybe<Scalars['Boolean']>;
  signUpAllowed?: Maybe<Scalars['Boolean']>;
  strategyMethods: Array<StrategyMethodType>;
  strategyMethodsListing: Array<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum AutomaticMetricTypeEnum {
  CertificateGiven = 'CERTIFICATE_GIVEN',
  ContentCompletionRate = 'CONTENT_COMPLETION_RATE',
  OneOnOneParticipationRate = 'ONE_ON_ONE_PARTICIPATION_RATE',
  PeerFeedbackGiven = 'PEER_FEEDBACK_GIVEN',
  PraiseGiven = 'PRAISE_GIVEN',
  SessionCompletionRate = 'SESSION_COMPLETION_RATE',
  UserLearningTime = 'USER_LEARNING_TIME',
}

/** Available Media Formats */
export type AvailableMediaFormatType = {
  __typename?: 'AvailableMediaFormatType';
  format: Scalars['String'];
  medias: Array<FormattedResourceMediaType>;
};

export type AvailableSchemaFieldType = {
  __typename?: 'AvailableSchemaFieldType';
  iconUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Entity on which points has been awarded */
export type AwardPointsEntityInput = {
  /** Entity id on which points has been awarded */
  entityId: Scalars['String'];
  /** Type of entity on which points has been awarded */
  entityType: PointEntityTypeEnum;
};

/** Points awarded to user for some behavior in a classroom */
export type AwardPointsInput = {
  /** Behavior for which points has been awarded */
  behaviorId: Scalars['String'];
  /** Classroom id in which points has been awarded */
  classroomId: Scalars['String'];
  /** Entity on which points has been awarded. It can be optional if points are awarded for activity outside the system */
  entity?: InputMaybe<AwardPointsEntityInput>;
  /** User ids to which points has been awarded */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Response received on awardPoints mutation */
export type AwardPointsPayload = {
  __typename?: 'AwardPointsPayload';
  /** Error code for pointActivity mutation */
  errorCode?: Maybe<PointErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum BackgroundLayoutTypeEnum {
  CentreAligned = 'CENTRE_ALIGNED',
  LeftAligned = 'LEFT_ALIGNED',
  NoBackground = 'NO_BACKGROUND',
  RightAligned = 'RIGHT_ALIGNED',
}

export type BannerCardInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  props?: InputMaybe<Scalars['String']>;
  status: PageComponentStatusEnum;
  type: PageComponentTypeEnum;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  widgetProps: WidgetPropsInput;
};

export type BannerPageComponentPreviewPropsInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  pageComponentType: PageComponentTypeEnum;
  props?: InputMaybe<Scalars['String']>;
  widgetProps?: InputMaybe<WidgetPropsInput>;
  widgetType: WidgetTypeEnum;
};

export type BannerPropsInput = {
  set: Array<BannerCardInput>;
};

export type BannerPropsPreviewInput = {
  components: Array<BannerPageComponentPreviewPropsInput>;
};

/** User banner items */
export type BannerResultType = {
  __typename?: 'BannerResultType';
  content?: Maybe<ContentEntity>;
  orgRole?: Maybe<OrgRoleType>;
};

export enum BannerStatusEnum {
  AboutToStart = 'ABOUT_TO_START',
  Live = 'LIVE',
  Upcoming = 'UPCOMING',
}

export type BaseActionItemType = {
  __typename?: 'BaseActionItemType';
  attachments?: Maybe<Array<EntityMediaType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** ActionItem Instructions in text or media */
  instruction?: Maybe<InstructionType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  subType: ActionItemSubTypeEnum;
  title: Scalars['String'];
  type: ActionItemTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type BaseAuditMetadata = {
  __typename?: 'BaseAuditMetadata';
  action: AuditActionEnum;
  auditId: Scalars['String'];
  collectionName: EntityCollectionEnum;
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  /** Content that was bookmarked by the user */
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<InstructionType>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  relatedCollectionName?: Maybe<EntityCollectionEnum>;
  workspaceId: Scalars['String'];
};

/** Building element of any enclosing entity */
export type BaseBlockType = {
  __typename?: 'BaseBlockType';
  /** Attributes of the block */
  attributes: Scalars['String'];
  /** Type of the block */
  blockType: Scalars['String'];
  /** Child block ids in order of their hierarchy */
  childrenIds: Array<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** text content that is in form of json string allong with some markup/decoration */
  decoratedChildBlocks?: Maybe<Scalars['String']>;
  /** Draft version of this block */
  draftBlockId?: Maybe<Scalars['String']>;
  /** Reading duration of the block */
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  /** Is the block content optional for the learner */
  isOptional?: Maybe<Scalars['Boolean']>;
  /** Markup details of styling and comments */
  markups?: Maybe<Scalars['String']>;
  /** mediaId of media embedded in the block */
  mediaId?: Maybe<Scalars['String']>;
  /** Parent entity's id */
  parentId: Scalars['String'];
  /** Parent entity's type or table */
  parentType: Scalars['String'];
  /** Published version of this block */
  publishedBlockId?: Maybe<Scalars['String']>;
  /** Root block's id */
  rootId: Scalars['String'];
  /** Parent entity's type or table */
  rootType: Scalars['String'];
  /** Reading duration of the block */
  selfDuration?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** text content that might be present in the block */
  text?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Base Content type, all content extend this type */
export type BaseContentSchema = {
  __typename?: 'BaseContentSchema';
  applicableRoles?: Maybe<Array<RoleType>>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  duplicatedFrom?: Maybe<Scalars['String']>;
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** duration of content in seconds */
  duration: Scalars['Float'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  /** duration of content */
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Ids of skill related to the content */
  skillIds: Array<Scalars['String']>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Base Content type, all content extend this type */
export type BaseContentSchemaDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Base Content type, all content extend this type */
export type BaseContentSchemaIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Base Content type, all content extend this type */
export type BaseContentSchemaIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Base Content type, all content extend this type */
export type BaseContentSchemaSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Base Content type, all content extend this type */
export type BaseContentSchemaSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Base Content type, all content extend this type */
export type BaseContentSchemaTagArgs = {
  tagId: Scalars['String'];
};

export type BaseDisplayComponentType = {
  __typename?: 'BaseDisplayComponentType';
  type: DisplayComponentTypeEnum;
};

/** Event container content type. Refers to event entity */
export type BaseEventContentType = {
  __typename?: 'BaseEventContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the event */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  description?: Maybe<Scalars['String']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** duration of content in seconds */
  duration: Scalars['Float'];
  event?: Maybe<EventUnion>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  objective: Scalars['String'];
  performance?: Maybe<EventPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  startTime: Scalars['String'];
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Event container content type. Refers to event entity */
export type BaseEventContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Event container content type. Refers to event entity */
export type BaseEventContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Event container content type. Refers to event entity */
export type BaseEventContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Event container content type. Refers to event entity */
export type BaseEventContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Event container content type. Refers to event entity */
export type BaseEventContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Event container content type. Refers to event entity */
export type BaseEventContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export type BaseEventType = {
  __typename?: 'BaseEventType';
  /** reason for event archival */
  archivalReason?: Maybe<Scalars['String']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** count of available seats */
  availableSeatCount?: Maybe<Scalars['Float']>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  completionSettings?: Maybe<CompletionSettingsType>;
  conferenceBridgeId: Scalars['String'];
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /**
   * count of users attended the event from the current workspace
   * @deprecated no longer required
   */
  currentWorkspaceUserCount: Scalars['Float'];
  defaultGroupId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  /** user provided end time in UTC */
  endTime: Scalars['String'];
  /** session recordings */
  eventRecordings?: Maybe<Array<EventRecordingType>>;
  id: Scalars['String'];
  instructorIds: Array<Scalars['String']>;
  instructors: Array<UserType>;
  /** flag to indicate if event is filling fast */
  isFillingFast: Scalars['Boolean'];
  isRevokedAfterCompletion: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  /** flag to indicate if currentUser is an instructor */
  isUserInstructor: Scalars['Boolean'];
  isUserRegistered: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  /** event metadata */
  meta: EventMetadataType;
  name: Scalars['String'];
  objective: Scalars['String'];
  postWorkContentIds?: Maybe<Array<Scalars['String']>>;
  postWorkContents: Array<ContentEntity>;
  postwork?: Maybe<EventWorkConfig>;
  preWorkContentIds?: Maybe<Array<Scalars['String']>>;
  preWorkContents: Array<ContentEntity>;
  prework?: Maybe<EventWorkConfig>;
  /** Recording URLs of the event */
  recordings?: Maybe<Array<Scalars['String']>>;
  /** time after which registration is closed */
  registrationCloseTime?: Maybe<Scalars['String']>;
  registrationSettings?: Maybe<RegistrationSettings>;
  remainingPostWorkContents: Scalars['Float'];
  remainingPreWorkContents: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  skills: Array<SkillType>;
  /** user provided start time in UTC */
  startTime: Scalars['String'];
  status: EventStatusEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** session time zone */
  timezone: Scalars['String'];
  /** total count of users who attended the event */
  totalUsersCount: Scalars['Float'];
  type: EventTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userIds: Array<Scalars['String']>;
  /** Permissions for a user on event */
  userPermissions?: Maybe<Array<UserEventPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  users: Array<UserType>;
  usersCount: Scalars['Float'];
  versionNumber?: Maybe<Scalars['Float']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

export type BaseEventTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type BaseEventTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export type BaseEventTypeTagArgs = {
  tagId: Scalars['String'];
};

export type BaseFacetType = {
  __typename?: 'BaseFacetType';
  id: Scalars['String'];
  /** presentational properties for the facet defined as a stringified JSON */
  props?: Maybe<Scalars['String']>;
  type: FacetTypeEnum;
};

export type BaseGroupType = {
  __typename?: 'BaseGroupType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  filterGroup?: Maybe<FilterGroupV2Type>;
  id: Scalars['String'];
  metricSnapshot?: Maybe<MetricSnapshot>;
  name: Scalars['String'];
  reSyncedAt?: Maybe<Scalars['String']>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: GroupStatusEnum;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type BasePageComponentPreviewType = {
  __typename?: 'BasePageComponentPreviewType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pageComponentType: PageComponentTypeEnum;
  props?: Maybe<Scalars['String']>;
  stringifiedWidget?: Maybe<Scalars['String']>;
  widget?: Maybe<Scalars['JSON']>;
};

export type BasePageComponentType = {
  __typename?: 'BasePageComponentType';
  calculateSecondaryOrder?: Maybe<Scalars['Boolean']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** layout of the page component */
  layout?: Maybe<PageComponentLayoutTypeEnum>;
  /** name of the page component */
  name: Scalars['String'];
  primaryOrder?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  /** custom JSON properties required by the page component */
  props?: Maybe<Scalars['String']>;
  redirectPath?: Maybe<Scalars['String']>;
  secondaryOrder: Scalars['Float'];
  /** status of the page component */
  status: PageComponentStatusEnum;
  /** type of the page component */
  type: PageComponentTypeEnum;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  widget?: Maybe<WidgetUnion>;
  /** widget id */
  widgetId?: Maybe<Scalars['String']>;
};

/** Base Performance Interaction Schema */
export type BasePerformanceInteractionType = {
  __typename?: 'BasePerformanceInteractionType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entityId: Scalars['String'];
  entityType: ResourceTypeEnum;
  id: Scalars['String'];
  reviewType: Scalars['String'];
  /** reviewer for the assignment */
  reviewerId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  submissionId: Scalars['String'];
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user who submitted the assignment */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Base Performance Type */
export type BasePerformanceSchema = {
  __typename?: 'BasePerformanceSchema';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

/** Base Resource type, all resources extend this type */
export type BaseResourceSchema = {
  __typename?: 'BaseResourceSchema';
  applicableRoles?: Maybe<Array<RoleType>>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  duration: Scalars['Float'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Base Resource type, all resources extend this type */
export type BaseResourceSchemaTagArgs = {
  tagId: Scalars['String'];
};

export type BaseTagType = {
  __typename?: 'BaseTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  taggedContentCount: Scalars['Float'];
  taggedEventCount: Scalars['Float'];
  taggedOKRCount: Scalars['Float'];
  taggedTaskCount: Scalars['Float'];
  taggedUserCount: Scalars['Float'];
  type: TagTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type BaseTagTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type BaseTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export type BaseTemplateType = {
  __typename?: 'BaseTemplateType';
  categories?: Maybe<Array<TemplateCategoryType>>;
  categoryIds: Array<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: TemplateStatusEnum;
  type: TemplateTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type BaseTodoType = {
  __typename?: 'BaseTodoType';
  type: TodoTypeEnum;
};

export type BaseUserNotification = {
  __typename?: 'BaseUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type BaseUserTrainingType = {
  __typename?: 'BaseUserTrainingType';
  type: UserTrainingTypeEnum;
};

export type BaseWidgetPreviewType = {
  __typename?: 'BaseWidgetPreviewType';
  type: WidgetTypeEnum;
};

export type BaseWidgetType = {
  __typename?: 'BaseWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type BaseWorkspaceAdministrationRuleInput = {
  delaySeconds: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type BaseWorkspaceAdministrationRuleType = {
  __typename?: 'BaseWorkspaceAdministrationRuleType';
  delaySeconds: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type BatchScheduledJobType = {
  __typename?: 'BatchScheduledJobType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  scheduledJobs: Array<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type BatchedOkrDueDateReminderEventType = {
  __typename?: 'BatchedOKRDueDateReminderEventType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  dueDate: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  keyResultIds?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  objectiveIds?: Maybe<Array<Scalars['String']>>;
  okrCycleId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  timeLeft: Scalars['Float'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** This returns paginated behaviors */
export type BehaviorConnection = {
  __typename?: 'BehaviorConnection';
  edges?: Maybe<Array<BehaviorEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type BehaviorEdge = {
  __typename?: 'BehaviorEdge';
  node: BehaviorType;
};

/** applicable filters to get single behavior */
export type BehaviorFilters = {
  /** Filter behavior by behaviorId */
  id: Scalars['String'];
};

/** Behavior that can be awarded to user in a classroom */
export type BehaviorType = {
  __typename?: 'BehaviorType';
  /** ClassroomId in which behavior is applicable */
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** Icon or thumb of the behavior */
  media: EntityMediaType;
  /** Name of the behavior */
  name: Scalars['String'];
  /** Points rewarded to user for the behavior */
  points: Scalars['Float'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** applicable filters on behavior */
export type BehaviorsFilters = {
  /** filter behaviors based on behavior ids */
  behaviorIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter behavior by a classroom */
  classroomId: Scalars['String'];
};

export type BellTemplate = {
  __typename?: 'BellTemplate';
  message: Scalars['String'];
};

/** This returns paginated BlockSearchSchema */
export type BlockSearchConnections = {
  __typename?: 'BlockSearchConnections';
  edges?: Maybe<Array<BlockSearchEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type BlockSearchEdge = {
  __typename?: 'BlockSearchEdge';
  node: BlockSearchSchema;
};

/** This is the Block search result schema, used to return ES results */
export type BlockSearchSchema = {
  __typename?: 'BlockSearchSchema';
  /** Attributes of the block */
  attributes: Scalars['String'];
  /** Type of the block */
  blockType: Scalars['String'];
  /** Child block ids in order of their hierarchy */
  childrenIds: Array<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** text content that is in form of json string allong with some markup/decoration */
  decoratedChildBlocks?: Maybe<Scalars['String']>;
  /** Reading duration of the block */
  duration?: Maybe<Scalars['Float']>;
  /** Highlight info to be fetched from ES */
  highlights?: Maybe<Scalars['JSONObject']>;
  id: Scalars['String'];
  /** Markup details of styling and comments */
  markups?: Maybe<Scalars['String']>;
  /** parent content info */
  parentDetails: ParentDetails;
  /** Parent entity's id */
  parentId: Scalars['String'];
  /** Parent entity's type or table */
  parentType: Scalars['String'];
  /** Root block's id */
  rootId: Scalars['String'];
  /** Parent entity's type or table */
  rootType: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** parent entity's Status to be stored as block's status */
  status: Scalars['String'];
  /** text content that might be present in the block */
  text?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Edge */
export type BookmarkEdge = {
  __typename?: 'BookmarkEdge';
  node: BookmarkType;
};

/** Filter to get Bookmark of the user */
export type BookmarkFilter = {
  /** Bookmark id to be fetched */
  bookmarkId?: InputMaybe<Scalars['String']>;
  /** Entity id to be fetched */
  entityId?: InputMaybe<Scalars['String']>;
  /** Bookmark type to be fetched */
  entityType?: InputMaybe<ResourceTypeEnum>;
  /** UserId for whom the bookmark is to be fetched */
  userId?: InputMaybe<Scalars['String']>;
};

/** Filters for Bookmark of the user */
export type BookmarkFilters = {
  /** Bookmark ids to be fetched */
  bookmarkIds?: InputMaybe<Array<Scalars['String']>>;
  /** entityIds for which we need bookmarks */
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  /** status of content for which we need bookmarks */
  entityStatus?: InputMaybe<Array<ResourceStatusEnum>>;
  /** entityTypes for which we need bookmarks */
  entityTypes?: InputMaybe<Array<ResourceTypeEnum>>;
  /** performance status of content of user for which we need bookmarks */
  performanceStatus?: InputMaybe<Array<PerformanceStatusEnum>>;
  /** search text to get related content which is bookmarked */
  searchText?: InputMaybe<Scalars['String']>;
  /** UserIds for whom we want bookmarks */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Bookmark schema to persist bookmark info */
export type BookmarkType = {
  __typename?: 'BookmarkType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Content that was bookmarked by the user */
  entity?: Maybe<ContentEntity>;
  /** id of the content on which bookmark is created */
  entityId: Scalars['String'];
  /** Type of content that is bookmarked */
  entityType: ResourceTypeEnum;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** userId for whom the bookmark is created */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type BulkTagsUpdateMetadataInput = {
  createdTagsCount: Scalars['Float'];
  updatedTagsCount: Scalars['Float'];
};

export type BulkTagsUpdateMetadataType = {
  __typename?: 'BulkTagsUpdateMetadataType';
  createdTagsCount: Scalars['Float'];
  updatedTagsCount: Scalars['Float'];
};

/** Cadence config for creation of any entity */
export type CadenceConfig = {
  __typename?: 'CadenceConfig';
  frequency: Scalars['Float'];
};

export type CadenceConfigInput = {
  frequency: Scalars['Float'];
};

export type CalculateEventUsersAttendanceInput = {
  eventId: Scalars['String'];
};

export type CalculateEventUsersAttendancePayload = {
  __typename?: 'CalculateEventUsersAttendancePayload';
  errorCode: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum CalculationMechanismEnum {
  NoOverallGrade = 'NO_OVERALL_GRADE',
  TotalPoints = 'TOTAL_POINTS',
  WeightedByCategory = 'WEIGHTED_BY_CATEGORY',
}

export type CancelGenerativeTaskInput = {
  generativeTaskId: Scalars['String'];
};

export type CancelJobInput = {
  /** id of the job */
  jobScheduleId: Scalars['String'];
};

export type CartDetails = {
  productId: Scalars['String'];
  quantity: Scalars['Float'];
};

export enum CategoryCalculationTypeEnum {
  Percentage = 'PERCENTAGE',
  Point = 'POINT',
}

export type CategoryIdsInput = {
  /** list of all categories attached to the content */
  set?: InputMaybe<Array<Scalars['String']>>;
};

export enum CategoryStatusTypeEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

/** Assignment Category Type */
export type CategoryType = {
  __typename?: 'CategoryType';
  calculationType: CategoryCalculationTypeEnum;
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Returns the count of assignment */
  homeworkCount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: CategoryStatusTypeEnum;
  systemGenerated: Scalars['Boolean'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  weight: Scalars['Float'];
};

export type CertificateConnections = {
  __typename?: 'CertificateConnections';
  edges?: Maybe<Array<CertificateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type CertificateCreationUserNotification = {
  __typename?: 'CertificateCreationUserNotification';
  certificateId: Scalars['String'];
  contentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entityType?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  parentEntityId?: Maybe<Scalars['String']>;
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CertificateDimensions = {
  __typename?: 'CertificateDimensions';
  height: Scalars['Float'];
  width: Scalars['Float'];
};

/** Edge */
export type CertificateEdge = {
  __typename?: 'CertificateEdge';
  node: CertificateType;
};

export type CertificateFilters = {
  certificateId?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  sharedId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CertificatePayload = {
  __typename?: 'CertificatePayload';
  certificate?: Maybe<CertificateType>;
};

/** Score along with input details for certificate. */
export type CertificateScorePayload = {
  __typename?: 'CertificateScorePayload';
  entityId: Scalars['String'];
  score: Scalars['Float'];
  userId: Scalars['String'];
};

/** Settings for certificate on the entity */
export type CertificateSettings = {
  __typename?: 'CertificateSettings';
  /** allow learner to download certificate */
  allowDownload: Scalars['Boolean'];
  /** allow learner to add certificate to linkedin profile */
  allowLinkedinProfile: Scalars['Boolean'];
  /** allow learner to share certificate on social media posts */
  allowSocialSharing: Scalars['Boolean'];
  certificateTemplateId: Scalars['String'];
  minPercentage?: Maybe<Scalars['Float']>;
};

export type CertificateSettingsInput = {
  /** allow learner to download certificate */
  allowDownload: Scalars['Boolean'];
  /** allow learner to add certificate to linkedin profile */
  allowLinkedinProfile: Scalars['Boolean'];
  /** allow learner to share certificate on social media posts */
  allowSocialSharing: Scalars['Boolean'];
  certificateTemplateId: Scalars['String'];
  minPercentage?: InputMaybe<Scalars['Float']>;
};

export enum CertificateStatusEnum {
  Completed = 'COMPLETED',
  MediaPending = 'MEDIA_PENDING',
}

export type CertificateTemplateConnections = {
  __typename?: 'CertificateTemplateConnections';
  edges?: Maybe<Array<CertificateTemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type CertificateTemplateEdge = {
  __typename?: 'CertificateTemplateEdge';
  node: CertificateTemplateType;
};

export type CertificateTemplateFilters = {
  certificateTemplateId: Scalars['String'];
};

export type CertificateTemplateModelRequirement = {
  __typename?: 'CertificateTemplateModelRequirement';
  /** fields of the content on which certificate is provided */
  content?: Maybe<Array<Scalars['String']>>;
  /** fields of receiver */
  user?: Maybe<Array<Scalars['String']>>;
};

export enum CertificateTemplateStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

/** Scorecard Type */
export type CertificateTemplateType = {
  __typename?: 'CertificateTemplateType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** date format to populate dates in certificate */
  dateFormat?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dimensions: CertificateDimensions;
  id: Scalars['String'];
  modelRequirement: CertificateTemplateModelRequirement;
  name: Scalars['String'];
  /** preview of the certificate */
  preview: EntityMediaType;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: CertificateTemplateStatusEnum;
  template: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CertificateTemplatepayload = {
  __typename?: 'CertificateTemplatepayload';
  certificateTemplate?: Maybe<CertificateTemplateType>;
};

export type CertificateTemplatesFilters = {
  certificateTemplateIds?: InputMaybe<Array<Scalars['String']>>;
  createdAtTimestampRange?: InputMaybe<TimestampRangeInput>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<CertificateTemplateStatusEnum>>;
};

export type CertificateType = {
  __typename?: 'CertificateType';
  content?: Maybe<ContentEntity>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  dimensions: CertificateDimensions;
  entityId: Scalars['String'];
  entityType: PerformanceTypeEnum;
  event?: Maybe<EventUnion>;
  html: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<EntityMediaType>;
  name: Scalars['String'];
  pdf?: Maybe<EntityMediaType>;
  publicLink?: Maybe<Scalars['String']>;
  sharedId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: CertificateStatusEnum;
  template?: Maybe<CertificateTemplateType>;
  templateId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CertificatesFilters = {
  certificateIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdAtTimestampRange?: InputMaybe<TimestampRangeInput>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  entityTypes?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  templateIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ChangeDueDateInput = {
  contentId: Scalars['String'];
  /** If this parameter is not passed then due date will be changed to No_Due_Date */
  dueDate?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export enum ChannelAccessTypeEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export enum ChannelActionsEnum {
  Create = 'CREATE',
  Edit = 'EDIT',
  Hide = 'HIDE',
  Manage = 'MANAGE',
  Pin = 'PIN',
  Read = 'READ',
  Remove = 'REMOVE',
}

export enum ChannelAllowedToPostEnum {
  All = 'ALL',
  Staff = 'STAFF',
}

/** This returns paginated channel users */
export type ChannelConnection = {
  __typename?: 'ChannelConnection';
  edges?: Maybe<Array<ChannelEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ChannelEdge = {
  __typename?: 'ChannelEdge';
  node: ChannelType;
};

/** Filters applicable on channel */
export type ChannelFilters = {
  /** Filter channel based on its id */
  channelId?: InputMaybe<Scalars['String']>;
};

export enum ChannelParentEntityTypeEnum {
  Article = 'ARTICLE',
  Assignment = 'ASSIGNMENT',
  Checkin = 'CHECKIN',
  Classroom = 'CLASSROOM',
  Cohort = 'COHORT',
  Community = 'COMMUNITY',
  Course = 'COURSE',
  Event = 'EVENT',
  Exercise = 'EXERCISE',
  OkrCycle = 'OKR_CYCLE',
  PeerFeedback = 'PEER_FEEDBACK',
  Task = 'TASK',
  Workspace = 'WORKSPACE',
}

export type ChannelParticipant = {
  __typename?: 'ChannelParticipant';
  emailId?: Maybe<Scalars['String']>;
  federationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  /** participant uniqueId */
  uniqueId: Scalars['String'];
};

export type ChannelParticipantEdge = {
  __typename?: 'ChannelParticipantEdge';
  node: ChannelParticipant;
};

export type ChannelParticipantsFilters = {
  /** id of channel */
  channelId: Scalars['String'];
  /** user roles in channel */
  userRoles: Array<RoleTypesEnum>;
};

export type ChannelPostsSubscriptionInput = {
  /** channelIds to subscribe posts on */
  channelIds: Array<Scalars['String']>;
  /** operations to subscribe on */
  operations?: InputMaybe<Array<ChannelPostsSubscriptionOperation>>;
  /** parentId to filter posts */
  parentId?: InputMaybe<Scalars['String']>;
  /** filter posts on types */
  types?: InputMaybe<Array<PostTypeEnum>>;
};

export enum ChannelPostsSubscriptionOperation {
  PostCreated = 'POST_CREATED',
  PostDeleted = 'POST_DELETED',
  PostReactionUpdated = 'POST_REACTION_UPDATED',
  PostUpdated = 'POST_UPDATED',
}

export type ChannelPostsSubscriptionPayload = {
  __typename?: 'ChannelPostsSubscriptionPayload';
  /** operation which happened on the post */
  operation: ChannelPostsSubscriptionOperation;
  post: PostType;
};

export enum ChannelStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
}

export enum ChannelSubjectsEnum {
  Attachments = 'ATTACHMENTS',
  Channel = 'CHANNEL',
  Members = 'MEMBERS',
  Post = 'POST',
  Reactions = 'REACTIONS',
  Reply = 'REPLY',
}

/** Channel Type */
export type ChannelType = {
  __typename?: 'ChannelType';
  /** Channel access type */
  accessType: ChannelAccessTypeEnum;
  /** Who is allowed to post in channel */
  allowedToPost?: Maybe<ChannelAllowedToPostEnum>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** channelUser document of context user. */
  channelUser?: Maybe<ChannelUserType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** description of channel */
  description?: Maybe<Scalars['String']>;
  /** icon corresponding to the channel */
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Name of the Channel */
  name: Scalars['String'];
  /** Id of the parent entity of this channel */
  parentId: Scalars['String'];
  /** Type of the parent entity of this channel */
  parentType: ChannelParentEntityTypeEnum;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status of channel */
  status: ChannelStatusEnum;
  /** subscription topic */
  topic: Scalars['String'];
  /** Type of channel */
  type: ChannelTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** User permissions on the channel */
  userPermissions: Array<UserChannelPermissionType>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  /** Channel visibility settings */
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

export enum ChannelTypeEnum {
  ActionItem = 'ACTION_ITEM',
  Announcement = 'ANNOUNCEMENT',
  CommunityDiscussion = 'COMMUNITY_DISCUSSION',
  Discussion = 'DISCUSSION',
  OkrDiscussion = 'OKR_DISCUSSION',
  PeerFeedback = 'PEER_FEEDBACK',
  Praise = 'PRAISE',
}

/** This returns paginated channel users */
export type ChannelUserConnection = {
  __typename?: 'ChannelUserConnection';
  edges?: Maybe<Array<ChannelUserEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ChannelUserEdge = {
  __typename?: 'ChannelUserEdge';
  node: ChannelUserType;
};

/** Filters to search channel users */
export type ChannelUserSearchFilters = {
  /** ChannelId on which the users need to be searched */
  channelId: Scalars['String'];
  /** Search a user eg: @us will return users containing with string "us" in their first name and last name */
  searchText: Scalars['String'];
};

/** Has information of users in a channel */
export type ChannelUserType = {
  __typename?: 'ChannelUserType';
  /** channel id */
  channelId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** Stores the time when user last opened the corresponding channel */
  lastViewedAt: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  unreadCount?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user id */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Filters applicable on channels */
export type ChannelsFilters = {
  /** channelIds that needs to be fetched */
  channelIds?: InputMaybe<Array<Scalars['String']>>;
  /** EntityId whose channel one needs to fetch */
  parentId: Scalars['String'];
  sharedChannelFilters?: InputMaybe<SharedEntityFilters>;
  /** channel status to be fetched */
  status?: InputMaybe<Array<ChannelStatusEnum>>;
  /** Type of channel one needs to fetch */
  types: Array<ChannelTypeEnum>;
};

export type ChartExportMetadataType = {
  __typename?: 'ChartExportMetadataType';
  exportedCount: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type ChartExportPropsInput = {
  chartFilterGroup?: InputMaybe<FilterGroupInput>;
  chartId: Scalars['String'];
  projections?: InputMaybe<Array<Scalars['String']>>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type ChartExportPropsType = {
  __typename?: 'ChartExportPropsType';
  chartFilterGroup?: Maybe<FilterGroupType>;
  chartId: Scalars['String'];
  projections: Array<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export enum ChartTypeEnum {
  Bar = 'BAR',
  Column = 'COLUMN',
  Counter = 'COUNTER',
  Donut = 'DONUT',
  Line = 'LINE',
  LinearNormalized = 'LINEAR_NORMALIZED',
  MetricsList = 'METRICS_LIST',
  MetricsOverview = 'METRICS_OVERVIEW',
  PercentileProgress = 'PERCENTILE_PROGRESS',
  PillsList = 'PILLS_LIST',
  Polar = 'POLAR',
  Progress = 'PROGRESS',
  ProgressList = 'PROGRESS_LIST',
  Scatter = 'SCATTER',
  ScatterWithBuckets = 'SCATTER_WITH_BUCKETS',
  Table = 'TABLE',
  TeamProfileRedirect = 'TEAM_PROFILE_REDIRECT',
  Timeseries = 'TIMESERIES',
  UsersList = 'USERS_LIST',
}

export type ChartWidgetPropsInput = {
  chartType?: InputMaybe<ChartTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  dimensionConfigs?: InputMaybe<Array<DimensionConfigTypeInput>>;
  dimensions?: InputMaybe<Array<Scalars['String']>>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  measures?: InputMaybe<Array<MeasureTypeInput>>;
  name?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Array<Scalars['String']>>;
  sortOrder?: InputMaybe<Array<SortType>>;
  sorts?: InputMaybe<Array<SortInput>>;
  timeDimensions?: InputMaybe<Array<TimeDimensionTypeInput>>;
};

/** Chart Widget */
export type ChartWidgetType = {
  __typename?: 'ChartWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  chartType: ChartTypeEnum;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  customWidget?: Maybe<ReportingWidgetType>;
  deletedAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dimensionConfigs?: Maybe<Array<DimensionConfigType>>;
  dimensions?: Maybe<Array<Scalars['String']>>;
  entity?: Maybe<Scalars['String']>;
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  measureConfigs?: Maybe<Array<MeasureConfigType>>;
  measures?: Maybe<Array<MeasureType>>;
  name: Scalars['String'];
  properties?: Maybe<Array<Scalars['String']>>;
  /** Redirection url for chart widget type */
  redirectUrl?: Maybe<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  showGroupData?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Array<SortTypeSchema>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  timeDimensions?: Maybe<Array<TimeDimensionType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CheckContentPublishableInput = {
  contentId: Scalars['String'];
};

export type CheckContentPublishablePayload = {
  __typename?: 'CheckContentPublishablePayload';
  isPublishable: Scalars['Boolean'];
  reason?: Maybe<ContentPublishFailureReasonEnum>;
};

export type CheckinAdvanceSettingsInput = {
  /** To Allow or disallow comments on checkin */
  allowComments: Scalars['Boolean'];
};

export type CheckinAuditMetadataType = {
  __typename?: 'CheckinAuditMetadataType';
  action: AuditActionEnum;
  auditId: Scalars['String'];
  collectionName: EntityCollectionEnum;
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  /** Content that was bookmarked by the user */
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<InstructionType>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  relatedCollectionName?: Maybe<EntityCollectionEnum>;
  updates: Array<AuditUpdateInfo>;
  workspaceId: Scalars['String'];
};

/** Input to checkin Key Result */
export type CheckinKeyResultInput = {
  /** Current Value of the key result */
  actualValue?: InputMaybe<Scalars['Float']>;
  /** Id of the key result cycle */
  cycleId: Scalars['String'];
  /** Optional description related to key result checkin */
  description?: InputMaybe<Scalars['String']>;
  /** Checkin details in text or media */
  details?: InputMaybe<InstructionInput>;
  /** Id of the key result */
  keyResultId: Scalars['String'];
  /** Id of the parent objective of key result */
  objectiveId: Scalars['String'];
  /** Key Result Status */
  status: OkrStatusEnum;
};

/** Input to checkin Objective */
export type CheckinObjectiveInput = {
  /** Id of the objective cycle */
  cycleId: Scalars['String'];
  /** Optional description related to objective checkin */
  description?: InputMaybe<Scalars['String']>;
  /** Checkin details in text or media */
  details?: InputMaybe<InstructionInput>;
  /** Id of the objective */
  objectiveId: Scalars['String'];
  /** status of objective */
  status: OkrStatusEnum;
};

/** Checkin Performance Type */
export type CheckinPerformanceType = {
  __typename?: 'CheckinPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress?: Maybe<Scalars['Float']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

/** Checkin Type */
export type CheckinType = {
  __typename?: 'CheckinType';
  agendas?: Maybe<Array<AgendaType>>;
  applicableRoles?: Maybe<Array<RoleType>>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Discussion Id */
  commentChannelId?: Maybe<Scalars['String']>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']>;
  /** Description of the checkin */
  description?: Maybe<Scalars['String']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** Duration of the checkin */
  duration: Scalars['Float'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Media objects related to the checkin */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Name of the resource */
  name: Scalars['String'];
  performance?: Maybe<CheckinPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Checkin Type */
export type CheckinTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Checkin Type */
export type CheckinTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Checkin Type */
export type CheckinTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Checkin Type */
export type CheckinTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Checkin Type */
export type CheckinTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Checkin Type */
export type CheckinTypeTagArgs = {
  tagId: Scalars['String'];
};

/** Payment Create Checkout Session Mutation Payload */
export type CheckoutSessionMutationPayload = {
  __typename?: 'CheckoutSessionMutationPayload';
  paymentProvider: TransactionPaymentProviderEnum;
  success?: Maybe<Scalars['Boolean']>;
};

export type ChildJobMetaInputType = {
  completedAt?: InputMaybe<Scalars['String']>;
  jobScheduleId: Scalars['String'];
  status: JobEntityStatusEnum;
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Child job meta information */
export type ChildJobMetaType = {
  __typename?: 'ChildJobMetaType';
  completedAt?: Maybe<Scalars['String']>;
  jobScheduleId: Scalars['String'];
  status: JobEntityStatusEnum;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ChildKrStubType = {
  __typename?: 'ChildKRStubType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ChildKRsFromOkrResultType = {
  __typename?: 'ChildKRsFromOKRResultType';
  records: Array<ChildKrStubType>;
};

export type ChildObjectiveStubType = {
  __typename?: 'ChildObjectiveStubType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ChildObjectivesFromOkrResultType = {
  __typename?: 'ChildObjectivesFromOKRResultType';
  records: Array<ChildObjectiveStubType>;
};

export type ChildObjectivesSetInput = {
  /** input to update child objectives */
  set: Array<UpdateObjectiveInput>;
};

export enum ClassroomActionsEnum {
  Create = 'CREATE',
  Edit = 'EDIT',
  Manage = 'MANAGE',
  Read = 'READ',
  Remove = 'REMOVE',
}

/** This returns paginated classroom */
export type ClassroomConnections = {
  __typename?: 'ClassroomConnections';
  edges?: Maybe<Array<ClassroomEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Counts regarding different type of assignment of a classroom */
export type ClassroomContentCounts = {
  __typename?: 'ClassroomContentCounts';
  openHomeworkCount?: Maybe<Scalars['Float']>;
  toDoHomeworkCount?: Maybe<Scalars['Float']>;
};

/** Edge */
export type ClassroomEdge = {
  __typename?: 'ClassroomEdge';
  node: ClassroomType;
};

export enum ClassroomEventActionsEnum {
  Add = 'ADD',
  Allow = 'ALLOW',
  ControlOthers = 'CONTROL_OTHERS',
  Create = 'CREATE',
  Edit = 'EDIT',
  End = 'END',
  Join = 'JOIN',
  Launch = 'LAUNCH',
  Leave = 'LEAVE',
  Manage = 'MANAGE',
  Participate = 'PARTICIPATE',
  Priority = 'PRIORITY',
  Read = 'READ',
  Remove = 'REMOVE',
  Start = 'START',
  View = 'VIEW',
}

/** Edge */
export type ClassroomEventEdge = {
  __typename?: 'ClassroomEventEdge';
  node: ClassroomEventType;
};

export enum ClassroomEventErrorCodeEnum {
  EventAggregationNotScheduled = 'EVENT_AGGREGATION_NOT_SCHEDULED',
  EventMetricsNotAggregated = 'EVENT_METRICS_NOT_AGGREGATED',
  EventNotFound = 'EVENT_NOT_FOUND',
  EventNotUpdated = 'EVENT_NOT_UPDATED',
  FailedToCreateEvent = 'FAILED_TO_CREATE_EVENT',
  FailedToDeleteEvent = 'FAILED_TO_DELETE_EVENT',
}

export enum ClassroomEventMetricsAggregationStatusEnum {
  NotProcessed = 'NOT_PROCESSED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
}

export enum ClassroomEventResourceAvailabilityEnum {
  InClass = 'IN_CLASS',
  PostClass = 'POST_CLASS',
  PreClass = 'PRE_CLASS',
}

/** Event resource relation object */
export type ClassroomEventResourceType = {
  __typename?: 'ClassroomEventResourceType';
  availability: ClassroomEventResourceAvailabilityEnum;
  resourceId: Scalars['String'];
};

export enum ClassroomEventStatusEnum {
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Scheduled = 'SCHEDULED',
  Skipped = 'SKIPPED',
  Started = 'STARTED',
}

export enum ClassroomEventSubjectsEnum {
  Activity = 'ACTIVITY',
  Audio = 'AUDIO',
  Chat = 'CHAT',
  ClassroomEvent = 'CLASSROOM_EVENT',
  MemberList = 'MEMBER_LIST',
  Notepad = 'NOTEPAD',
  One = 'ONE',
  Poll = 'POLL',
  Quiz = 'QUIZ',
  RaiseHand = 'RAISE_HAND',
  Recordings = 'RECORDINGS',
  ScreenShare = 'SCREEN_SHARE',
  StudyMaterial = 'STUDY_MATERIAL',
  VideoShare = 'VIDEO_SHARE',
  Whiteboard = 'WHITEBOARD',
}

/** Event Type. This can be used in classrooms as well as individually */
export type ClassroomEventType = {
  __typename?: 'ClassroomEventType';
  /** Classroom of which the event is part of */
  classroom?: Maybe<ClassroomType>;
  classroomId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Default discussion channel ID for the event */
  defaultDiscussionChannelId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Duration of event in minutes */
  duration: Scalars['Int'];
  /** Completion timestamp of event in ISO-8601 format. It is set when the status of event is marked completed */
  eventCompletionTimestamp?: Maybe<Scalars['String']>;
  /** Id of eventSchedule that created the event */
  eventScheduleId?: Maybe<Scalars['String']>;
  /** Start timestamp of event in ISO-8601 format */
  eventStartTimestamp: Scalars['String'];
  eventType?: Maybe<ClassroomEventTypeEnum>;
  /** All users of this group have access to this event */
  groupIds: Array<Scalars['String']>;
  id: Scalars['String'];
  /** Start timestamp of metrics aggregation process */
  metricsAggregationStartTimestamp?: Maybe<Scalars['String']>;
  /** Current status of aggregation of metrics */
  metricsAggregationStatus: ClassroomEventMetricsAggregationStatusEnum;
  name: Scalars['String'];
  /** Id of postback schedule on which the postback will invoke aggregation */
  postbackScheduleId?: Maybe<Scalars['String']>;
  /** Id of the recurrenceRule that scheduled the event */
  recurrenceRuleId?: Maybe<Scalars['String']>;
  /** Holds resources that is available with this live session */
  resources?: Maybe<Array<ClassroomEventResourceType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Current status of the event */
  status: ClassroomEventStatusEnum;
  /** Type of the event */
  type: ClassroomEventTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** User Permissions on event */
  userPermissions: Array<UserClassroomEventPermissionType>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** Number of users part of the event */
  usersCount?: Maybe<Scalars['Int']>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum ClassroomEventTypeEnum {
  Exam = 'EXAM',
  Lecture = 'LECTURE',
}

/** Classroom information details decoded by invitation token */
export type ClassroomInviteDetails = {
  __typename?: 'ClassroomInviteDetails';
  /** Classroom */
  classroom: ClassroomType;
  /** Classroom Id */
  classroomId: Scalars['String'];
  /** Classroom User */
  classroomUser?: Maybe<ClassroomUserType>;
  /** Invited By User */
  invitedBy: UserType;
};

export enum ClassroomInviteErrorCode {
  DataNotFound = 'DATA_NOT_FOUND',
  IncorrectUser = 'INCORRECT_USER',
  InvalidToken = 'INVALID_TOKEN',
  UserRemovedFromClassroom = 'USER_REMOVED_FROM_CLASSROOM',
}

export type ClassroomInviteFilters = {
  /** Classroom Invitation Token */
  token: Scalars['String'];
};

/** Classroom invitation info */
export type ClassroomInvitePayload = {
  __typename?: 'ClassroomInvitePayload';
  /** Classroom information */
  classroomInviteDetails?: Maybe<ClassroomInviteDetails>;
  /** Error Code */
  errorCode?: Maybe<ClassroomInviteErrorCode>;
  /** Request successful or not */
  success: Scalars['Boolean'];
};

/** Classroom information decoded by short code */
export type ClassroomJoinDetails = {
  __typename?: 'ClassroomJoinDetails';
  /** Classroom */
  classroom: ClassroomType;
  /** Classroom Id */
  classroomId: Scalars['String'];
  /** Classroom User */
  classroomUser?: Maybe<ClassroomUserType>;
};

export enum ClassroomJoinErrorCode {
  DataNotFound = 'DATA_NOT_FOUND',
  InvalidCode = 'INVALID_CODE',
  UserRemovedFromClassroom = 'USER_REMOVED_FROM_CLASSROOM',
}

export type ClassroomJoinFilters = {
  /** Classroom Invitation code */
  code: Scalars['String'];
};

/** Classroom join info */
export type ClassroomJoinPayload = {
  __typename?: 'ClassroomJoinPayload';
  /** Classroom information */
  classroomJoinDetails?: Maybe<ClassroomJoinDetails>;
  /** Error Code */
  errorCode?: Maybe<ClassroomJoinErrorCode>;
  /** Request successful or not */
  success: Scalars['Boolean'];
};

/** This returns paginated classroomPoint */
export type ClassroomPointConnection = {
  __typename?: 'ClassroomPointConnection';
  edges?: Maybe<Array<ClassroomPointEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ClassroomPointEdge = {
  __typename?: 'ClassroomPointEdge';
  node: ClassroomPointType;
};

/** Filters on classroomPoint */
export type ClassroomPointFilters = {
  /** classrom id filter for classroomPoint */
  classroomId: Scalars['String'];
  /** filter classroomPoints based on user ids */
  userId?: InputMaybe<Scalars['String']>;
};

export enum ClassroomPointStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

/** Aggregated points of a user in classroom */
export type ClassroomPointType = {
  __typename?: 'ClassroomPointType';
  /** All user behavior in a classroom */
  behaviors: Array<UserBehaviorStatsType>;
  /** ClassroomId in which points has been rewarded */
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status of classroomUser */
  status: ClassroomPointStatusEnum;
  /** Total number of points rewarded in a classroom */
  totalPoints: Scalars['Float'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user to which points has been awarded */
  user?: Maybe<UserType>;
  /** User to which points has been rewarded */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** applicable filters on classroomPoint */
export type ClassroomPointsFilters = {
  /** classrom id filter for classroomPoint */
  classroomId?: InputMaybe<Scalars['String']>;
  status: Array<ClassroomPointStatusEnum>;
  /** filter classroomPoints based on user ids */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Current user stats for classroom points */
export type ClassroomPointsMyStatsType = {
  __typename?: 'ClassroomPointsMyStatsType';
  /** all behaviors of current user */
  myBehaviors: Array<UserBehaviorStatsType>;
  myClassroomPoint: ClassroomPointType;
  /** current user position in classroom points stats */
  myRank?: Maybe<Scalars['Float']>;
  /** current user points in classroom */
  totalPoints: Scalars['Float'];
};

/** applicable filters on behavior */
export type ClassroomPointsStatsFilters = {
  /** filter to get classroom stats */
  classroomId: Scalars['String'];
};

/** applicable filters on behavior */
export type ClassroomPointsStatsFiltersObject = {
  __typename?: 'ClassroomPointsStatsFiltersObject';
  /** filter to get classroom stats */
  classroomId: Scalars['String'];
};

/** Points stats of a classroom */
export type ClassroomPointsStatsType = {
  __typename?: 'ClassroomPointsStatsType';
  /** stats of all classroom users. Ordered by leading points user */
  allStats?: Maybe<ClassroomUserConnections>;
  /** my stats in classroom points stats */
  leaderboardExists?: Maybe<Scalars['Boolean']>;
  /** my stats in classroom points stats */
  myStats?: Maybe<ClassroomPointsMyStatsType>;
  rootArgs: ClassroomPointsStatsFiltersObject;
};

/** Points stats of a classroom */
export type ClassroomPointsStatsTypeAllStatsArgs = {
  paginationProps?: InputMaybe<PaginationProps>;
};

/** Event Resource Format */
export type ClassroomResourceInput = {
  availability: ClassroomEventResourceAvailabilityEnum;
  resourceId: Scalars['String'];
};

/** filter to get classroom roles */
export type ClassroomRolesFilters = {
  /** filter on custom role */
  classroomId?: InputMaybe<Scalars['String']>;
};

export type ClassroomSettingCategoryInput = {
  _id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  weight: Scalars['Float'];
};

/** Classroom Settings Type */
export type ClassroomSettingType = {
  __typename?: 'ClassroomSettingType';
  categories?: Maybe<Array<CategoryType>>;
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** GradeBook setting type */
  gradeBookSettings: GradeBookSettingType;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum ClassroomSettingsGradeTypeEnum {
  LetterGrade = 'LETTER_GRADE',
  Percentage = 'PERCENTAGE',
}

export enum ClassroomStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export enum ClassroomSubjectsEnum {
  AllPointActivity = 'ALL_POINT_ACTIVITY',
  Assignment = 'ASSIGNMENT',
  Attendance = 'ATTENDANCE',
  Channel = 'CHANNEL',
  Classroom = 'CLASSROOM',
  Event = 'EVENT',
  Gradebook = 'GRADEBOOK',
  Invitation = 'INVITATION',
  MyPointActivity = 'MY_POINT_ACTIVITY',
  Participants = 'PARTICIPANTS',
  PointActivity = 'POINT_ACTIVITY',
  Resource = 'RESOURCE',
}

/** Classroom Type */
export type ClassroomType = {
  __typename?: 'ClassroomType';
  /** Counts regarding different type of assignment of a classroom */
  assignmentCounts?: Maybe<ClassroomContentCounts>;
  /** classroomuser document of context user. Only be used when single user classrooms are fetched */
  classroomUser?: Maybe<ClassroomUserType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  defaultAnnouncementChannelId?: Maybe<Scalars['String']>;
  defaultGroupId?: Maybe<Scalars['String']>;
  defaultRoleId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Tentative duration of classroom in days */
  duration?: Maybe<Scalars['Int']>;
  hasHomeworks: Scalars['Boolean'];
  id: Scalars['String'];
  inviteCode?: Maybe<InvitationCodeType>;
  /** All the media corresponding to classroom */
  media: Array<DeprecatedMediaType>;
  name: Scalars['String'];
  roles?: Maybe<Array<RoleType>>;
  /** Classroom setting */
  settings?: Maybe<ClassroomSettingType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: ClassroomStatusEnum;
  /** Categorize the classroom into subjects */
  subject?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** User permissions on the classroom */
  userPermissions: Array<UserClassroomPermissionType>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  usersCount?: Maybe<Scalars['Int']>;
  versionNumber?: Maybe<Scalars['Float']>;
  /** custom watermark for classes */
  watermark?: Maybe<Scalars['String']>;
};

/** This returns paginated classroom users */
export type ClassroomUserConnections = {
  __typename?: 'ClassroomUserConnections';
  edges?: Maybe<Array<ClassroomUserEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ClassroomUserEdge = {
  __typename?: 'ClassroomUserEdge';
  node: ClassroomUserType;
};

export type ClassroomUserRoleConfig = {
  emailId: Scalars['String'];
};

export enum ClassroomUserStatusEnum {
  Active = 'ACTIVE',
  Archive = 'ARCHIVE',
}

/** Classroom users */
export type ClassroomUserType = {
  __typename?: 'ClassroomUserType';
  classroom: ClassroomType;
  /** Classroom Id */
  classroomId: Scalars['String'];
  classroomPoint?: Maybe<ClassroomPointType>;
  classroomPointsRanking?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** User onboard status in classroom */
  onboardStatus: OnboardStatusEnum;
  /** RoleId for user's role for classroom */
  roleId?: Maybe<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status of classroomUser */
  status: ClassroomUserStatusEnum;
  /** Classroom user's total points received */
  totalPoints?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** User Data of classroomUser */
  user?: Maybe<UserType>;
  /** User Id of classroom */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ClassroomUsersFilters = {
  classroomId?: InputMaybe<Scalars['String']>;
  classroomStatus: Array<ClassroomStatusEnum>;
  onboardStatus: Array<OnboardStatusEnum>;
  status: Array<ClassroomUserStatusEnum>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ClassroomsFilters = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  status: Array<ClassroomStatusEnum>;
  /** userIds to filter user classrooms. */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CloudMediaProxyLinkFromMediaPayload = {
  __typename?: 'CloudMediaProxyLinkFromMediaPayload';
  cloudProxyMediaUrl?: Maybe<Scalars['String']>;
  mediaId: Scalars['String'];
};

export enum CohortErrorCodeEnum {
  CohortCreationFailed = 'COHORT_CREATION_FAILED',
}

/** filters for cohort */
export type CohortFilters = {
  id: Scalars['String'];
};

/** Cohort Schema */
export type CohortType = {
  __typename?: 'CohortType';
  /** Announcement Channel Id of Cohort */
  announcementChannelId: Scalars['String'];
  /** This resolver returns channels of a cohort */
  channels?: Maybe<Array<ChannelType>>;
  /** Classroom Ids related to Cohort */
  classroomIds: Array<Scalars['String']>;
  /** This resolver returns classrooms for classroom ids in a cohort */
  classrooms?: Maybe<Array<ClassroomType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Default Role Id of Cohort */
  defaultRoleId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Group Id of Cohort */
  groupId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CollectionContentType = {
  __typename?: 'CollectionContentType';
  collectionId: Scalars['String'];
  contentIds: Array<Scalars['String']>;
};

export enum CommandEnum {
  AddTime = 'ADD_TIME',
  ContentAccessed = 'CONTENT_ACCESSED',
  ContentVisit = 'CONTENT_VISIT',
  HandRaise = 'HAND_RAISE',
  SetProgress = 'SET_PROGRESS',
  SetView = 'SET_VIEW',
  UpdateEventRecordingProgress = 'UPDATE_EVENT_RECORDING_PROGRESS',
  UpdateEventUserSession = 'UPDATE_EVENT_USER_SESSION',
}

/** Edge */
export type CommonProviderEdge = {
  __typename?: 'CommonProviderEdge';
  node: CommonProviderType;
};

/** Common provider model */
export type CommonProviderType = {
  __typename?: 'CommonProviderType';
  availableOn: ProviderAvailableOnEnum;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  formInputs?: Maybe<Array<FormComponentType>>;
  id: Scalars['String'];
  logoUrl: Scalars['String'];
  marketplaceContentCount: Scalars['Float'];
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** Type of the external content */
  verificationMethod?: Maybe<VerificationMethodEnum>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CommunityChannelInfoInput = {
  /** who is allowed to post in the channel */
  allowedToPost: ChannelAllowedToPostEnum;
  /** block id containing community channel */
  channelBlockId?: InputMaybe<Scalars['String']>;
  /** description of channel */
  description?: InputMaybe<Scalars['String']>;
  /** icon corresponding to the channel */
  icon?: InputMaybe<Scalars['String']>;
  /** name of channel */
  name: Scalars['String'];
  /** status of channel */
  status: ChannelStatusEnum;
  /** visibility settings of channel */
  visibilitySettings: VisibilitySettingsInput;
};

export type CommunityChannelMentionedUserNotification = {
  __typename?: 'CommunityChannelMentionedUserNotification';
  channelId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mentionedAt: Scalars['String'];
  mentionedById: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CommunityFilters = {
  /** Community Id */
  communityId: Scalars['String'];
};

/** Community defining block */
export type CommunityType = {
  __typename?: 'CommunityType';
  blockTree?: Maybe<Array<BaseBlockType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  defaultSpaceId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** String Templates for joining */
  joiningTemplates?: Maybe<Array<Scalars['String']>>;
  /** Root block Id */
  rootBlockId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CompletedLearningsWidgetPreviewType = {
  __typename?: 'CompletedLearningsWidgetPreviewType';
  /** completed contents */
  contents?: Maybe<Array<Scalars['JSON']>>;
  type: WidgetTypeEnum;
};

export type CompletedLearningsWidgetType = {
  __typename?: 'CompletedLearningsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  contents: ContentConnection;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CompletedLearningsWidgetTypeContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
};

export type CompletionSettingsInput = {
  considerRecordingProgress?: InputMaybe<Scalars['Boolean']>;
  requirePreWorkPostWork: Scalars['Boolean'];
};

/** Event metadata */
export type CompletionSettingsType = {
  __typename?: 'CompletionSettingsType';
  considerRecordingProgress?: Maybe<Scalars['Boolean']>;
  requirePreWorkPostWork: Scalars['Boolean'];
};

export type ConferenceFilters = {
  conferenceBridgeId?: InputMaybe<Scalars['String']>;
  conferenceId?: InputMaybe<Scalars['String']>;
};

/** Conference metadata */
export type ConferenceMetadataType = {
  __typename?: 'ConferenceMetadataType';
  bookedSeatRatio: Scalars['Float'];
  registeredUsersCount: Scalars['Float'];
};

export type ConferencePayload = {
  __typename?: 'ConferencePayload';
  conference?: Maybe<ConferenceType>;
  errorCode?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ConferenceSubscriptionInput = {
  conferenceBridgeId: Scalars['String'];
  /** operations to subscribe on */
  operations?: InputMaybe<Array<ConferenceSubscriptionOperationEnum>>;
};

export enum ConferenceSubscriptionOperationEnum {
  ConferenceUpdated = 'CONFERENCE_UPDATED',
}

export type ConferenceSubscriptionPayload = {
  __typename?: 'ConferenceSubscriptionPayload';
  conferenceBridgeId: Scalars['String'];
  event?: Maybe<EventUnion>;
  operation: ConferenceSubscriptionOperationEnum;
};

export type ConferenceType = {
  __typename?: 'ConferenceType';
  conferenceBridgeId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  maxSeatsAllowed?: Maybe<Scalars['Float']>;
  metadata: ConferenceMetadataType;
  registeredUsers: Array<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  source: Scalars['String'];
  targets: Array<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceIds: Array<Scalars['String']>;
};

/** Edge */
export type ConfigEdge = {
  __typename?: 'ConfigEdge';
  node: ConfigType;
};

export type ConfigFilters = {
  key: Scalars['String'];
};

export type ConfigPayload = {
  __typename?: 'ConfigPayload';
  config?: Maybe<ConfigType>;
};

/** Config site parameters */
export type ConfigSiteType = {
  __typename?: 'ConfigSiteType';
  enableAssignmentRestriction?: Maybe<Scalars['Boolean']>;
  enableDraftContent?: Maybe<Scalars['Boolean']>;
  favicon?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  isMandatoryTagValidationEnabled?: Maybe<Scalars['Boolean']>;
  /** default logo URL for the site */
  logo: Array<DeprecatedMediaType>;
  /** Application name for the workspace */
  name?: Maybe<Scalars['String']>;
  subWorkspacesEnabled?: Maybe<Scalars['Boolean']>;
};

export type ConfigType = {
  __typename?: 'ConfigType';
  authConfig?: Maybe<AuthConfigType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  difficultyWeightConfig?: Maybe<DifficultyWeightConfigType>;
  id: Scalars['String'];
  /** navConfig | authConfig | ... */
  key: Scalars['String'];
  navConfig?: Maybe<NavConfigProperties>;
  publishContentConfigProperties?: Maybe<PublishContentConfigPropertiesType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ConfigsFilters = {
  keys?: InputMaybe<Array<Scalars['String']>>;
};

/** Add product content input */
export type Content = {
  /** Id of the content. It can be id of resource, event, classroom or any other content */
  contentId?: InputMaybe<Scalars['String']>;
  /** Description of the content in listing product details */
  description?: InputMaybe<Scalars['String']>;
  /** downloadable Ids */
  downloadableIds?: InputMaybe<Array<Scalars['String']>>;
  /** Duration of the content */
  duration?: InputMaybe<Scalars['Int']>;
  /** Product media. it can be an image or video */
  media?: InputMaybe<Array<Media>>;
  /** Name or title of the content in listing product details */
  name?: InputMaybe<Scalars['String']>;
  /** previewable Ids */
  previewableIds?: InputMaybe<Array<Scalars['String']>>;
  /** Type of content the user is adding in product  */
  type?: InputMaybe<ProductContentTypeEnum>;
};

export type ContentCollectionGroupType = {
  __typename?: 'ContentCollectionGroupType';
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  contentVisibilitySettings?: Maybe<VisibilitySettingsType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  filterGroup?: Maybe<FilterGroupV2Type>;
  id: Scalars['String'];
  isSent?: Maybe<Scalars['Boolean']>;
  memberCount: Scalars['Float'];
  metricSnapshot?: Maybe<MetricSnapshot>;
  name: Scalars['String'];
  reSyncedAt?: Maybe<Scalars['String']>;
  sampleMembers: Array<ContentEntity>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  status: GroupStatusEnum;
  type: GroupTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ContentCollectionGroupTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type ContentCollectionGroupTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export type ContentCompletedUserNotification = {
  __typename?: 'ContentCompletedUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  parentEntityId?: Maybe<Scalars['String']>;
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** This returns paginated contents */
export type ContentConnection = {
  __typename?: 'ContentConnection';
  edges?: Maybe<Array<ContentEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export enum ContentCreationStrategyEnum {
  Admin = 'ADMIN',
  SelfLearning = 'SELF_LEARNING',
  System = 'SYSTEM',
}

export type ContentDueUserNotification = {
  __typename?: 'ContentDueUserNotification';
  completionTimeLeft?: Maybe<Scalars['String']>;
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  dueDate?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  timeLeft: Scalars['String'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Edge */
export type ContentEdge = {
  __typename?: 'ContentEdge';
  node: ContentEntity;
};

/** It will resolve homework or resource */
export type ContentEntity =
  | ArticleContentType
  | BaseResourceSchema
  | CheckinType
  | CourseContentType
  | ExerciseType
  | ExternalContentType
  | ExternalEventContentType
  | HomeworkType
  | LiveEventContentType
  | OfflineEventContentType
  | PathContentType
  | QuizContentType
  | ScormContentType
  | SurveyContentType
  | TaskContentType;

export type ContentFilter = {
  id: Scalars['String'];
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<ResourceStatusEnum>>;
  versionStatus?: InputMaybe<Scalars['String']>;
};

export type ContentFilters = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  /** Return all path-content whose childResources these childResourceIds */
  childResourceIds?: InputMaybe<Array<Scalars['String']>>;
  classroomId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateFilter>;
  /** Deprecated. Use createdAt instead. Deprecation Reason: This filter is of type TimestampRangeInput. Use createdAt field which has type DateFilter */
  createdAtTimestampRange?: InputMaybe<TimestampRangeInput>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  creationStrategies?: InputMaybe<Array<ContentCreationStrategyEnum>>;
  draftCategoryIds?: InputMaybe<Array<Scalars['String']>>;
  /** Return all content having the following dripTypes */
  dripTypes?: InputMaybe<Array<DripTypeEnum>>;
  /** Array of two dates between which the dueDateTimestampRange of assignment lies */
  dueDateRange?: InputMaybe<Array<Scalars['String']>>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  entityTypes?: InputMaybe<Array<QuizEntityTypeEnum>>;
  excludeReceivedContents?: InputMaybe<Scalars['Boolean']>;
  fileCategoriesFilter?: InputMaybe<FileCategoriesFilter>;
  /** filter by field and tag properties */
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  generatedBy?: InputMaybe<GeneratedByEnum>;
  /** get static or dynamic contents for given groupIds */
  groupFilter?: InputMaybe<EntityGroupFilter>;
  /** ContentIds */
  ids?: InputMaybe<Array<Scalars['String']>>;
  intent?: InputMaybe<ContentQueryIntentEnum>;
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  /** if true, will fetch those contents which are linked with external resource */
  linkedExternalResource?: InputMaybe<Scalars['Boolean']>;
  marketplaceContentIds?: InputMaybe<Array<Scalars['String']>>;
  /** title of content */
  name?: InputMaybe<Scalars['String']>;
  /** parent content Ids for surveys */
  parentContentIds?: InputMaybe<Array<Scalars['String']>>;
  /** Parent id of the content */
  parentId?: InputMaybe<Scalars['String']>;
  /** parent survey Ids for surveys */
  parentSurveyIds?: InputMaybe<Array<Scalars['String']>>;
  performanceFilters?: InputMaybe<ContentPerformanceFilters>;
  reviewerConfig?: InputMaybe<ReviewerConfigFilters>;
  /** root blockId of content */
  rootBlockIds?: InputMaybe<Array<Scalars['String']>>;
  scorecardIds?: InputMaybe<Array<Scalars['String']>>;
  shareable?: InputMaybe<Scalars['Boolean']>;
  sharedContentFilters?: InputMaybe<SharedEntityFilters>;
  status?: InputMaybe<Array<ResourceStatusEnum>>;
  /** Filter based on subtype of resource. */
  subTypes?: InputMaybe<Array<ResourceSubTypeEnum>>;
  /** get resources based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  /** tag id of content */
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  tagOptionsOutputOrder?: InputMaybe<TagOptionsOutputOrderInput>;
  types?: InputMaybe<Array<ResourceTypeEnum>>;
  /** filter and return contents by recency of usage by current user */
  userRecents?: InputMaybe<Scalars['Boolean']>;
  workspaceIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ContentLockSettings = {
  __typename?: 'ContentLockSettings';
  availableFrom: Scalars['String'];
  contentId: Scalars['String'];
  contentType: DripByEnum;
  isAvailable: Scalars['Boolean'];
};

export type ContentMajorVersionUpdateUserNotification = {
  __typename?: 'ContentMajorVersionUpdateUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ContentOverdueUserNotification = {
  __typename?: 'ContentOverdueUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  timePast: Scalars['String'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** This returns paginated Performances */
export type ContentPerformanceConnection = {
  __typename?: 'ContentPerformanceConnection';
  edges?: Maybe<Array<ContentPerformanceEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ContentPerformanceEdge = {
  __typename?: 'ContentPerformanceEdge';
  node: ContentPerformanceEntity;
};

/** It will resolve performances */
export type ContentPerformanceEntity =
  | ArticlePerformanceType
  | BasePerformanceSchema
  | CoursePerformanceType
  | EventPerformanceType
  | ExercisePerformanceType
  | ExternalContentPerformanceType
  | HomeworkPerformanceType
  | PathPerformanceType
  | QuestionPerformanceType
  | QuizPerformanceType
  | ScormPerformanceType
  | SubTaskPerformanceType
  | SurveyPerformanceType
  | TaskPerformanceType
  | TaskSectionPerformanceType;

export type ContentPerformanceFilters = {
  status: Array<PerformanceStatusFilterEnum>;
  userId: Scalars['String'];
};

/** Assignment Performance Metrics */
export type ContentPerformanceMetricsType = {
  __typename?: 'ContentPerformanceMetricsType';
  assignedCount?: Maybe<Scalars['Float']>;
  averageScore?: Maybe<Scalars['Float']>;
  awaitingCount?: Maybe<Scalars['Float']>;
  contentSubmittedCount?: Maybe<Scalars['Float']>;
  excusedCount?: Maybe<Scalars['Float']>;
  gradedCount?: Maybe<Scalars['Float']>;
  highestScore?: Maybe<Scalars['Float']>;
  lowestScore?: Maybe<Scalars['Float']>;
  missedCount?: Maybe<Scalars['Float']>;
  notStartedCount?: Maybe<Scalars['Float']>;
  returnedCount?: Maybe<Scalars['Float']>;
  submittedCount?: Maybe<Scalars['Float']>;
  toReviewCount?: Maybe<Scalars['Float']>;
};

/** Different Performance status counts */
export type ContentPerformanceStatusMetricsType = {
  __typename?: 'ContentPerformanceStatusMetricsType';
  excusedCount?: Maybe<Scalars['Float']>;
  gradedCount?: Maybe<Scalars['Float']>;
  missedCount?: Maybe<Scalars['Float']>;
  notStartedCount?: Maybe<Scalars['Float']>;
  returnedCount?: Maybe<Scalars['Float']>;
  submittedCount?: Maybe<Scalars['Float']>;
};

/** Prerequisite learning content for enclosing Content */
export type ContentPrerequisiteType = {
  __typename?: 'ContentPrerequisiteType';
  /** Entity Id of pre-requisite */
  entityId: Scalars['String'];
  /** type of pre-requisite */
  entityType: Scalars['String'];
};

/** Content Properties */
export type ContentProfile = {
  __typename?: 'ContentProfile';
  duration: Scalars['Float'];
  /** id of the Content */
  id: Scalars['String'];
  /** type of the Content */
  type: ResourceTypeEnum;
};

export enum ContentPublishFailureReasonEnum {
  MandatoryTagsNotAttached = 'MANDATORY_TAGS_NOT_ATTACHED',
}

export enum ContentQueryIntentEnum {
  Assign = 'ASSIGN',
  Read = 'READ',
}

export type ContentTodoType = {
  __typename?: 'ContentTodoType';
  content: ContentEntity;
  type: TodoTypeEnum;
};

/** Product Content Types */
export type ContentType = {
  __typename?: 'ContentType';
  /** id of content */
  contentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Description of content */
  description?: Maybe<Scalars['String']>;
  /** downloadable Ids */
  downloadableIds?: Maybe<Array<Scalars['String']>>;
  /** Duration of content in seconds */
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  /** Represents media in content  */
  media?: Maybe<Array<DeprecatedMediaType>>;
  /** Name or title of content */
  name: Scalars['String'];
  /** previewable Ids */
  previewableIds?: Maybe<Array<Scalars['String']>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Type of product content */
  type: ProductContentTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum ContentTypeEnum {
  SubTask = 'SUB_TASK',
  Task = 'TASK',
  TaskSection = 'TASK_SECTION',
}

export type ContentUserTrainingType = {
  __typename?: 'ContentUserTrainingType';
  content: ContentEntity;
  type: UserTrainingTypeEnum;
};

export enum ContentWidgetPopulationTypeEnum {
  Collection = 'COLLECTION',
  FilterGroup = 'FILTER_GROUP',
  Static = 'STATIC',
}

export enum ContentWidgetSortTypeEnum {
  Alphabetically = 'ALPHABETICALLY',
  Newest = 'NEWEST',
  Oldest = 'OLDEST',
}

export type ContentWorkspaceMappingsInput = {
  contentId: Scalars['String'];
};

export type ContentsWidgetPreviewType = {
  __typename?: 'ContentsWidgetPreviewType';
  /** contents */
  dummyContents?: Maybe<Array<Scalars['JSON']>>;
  /** contents */
  realContents?: Maybe<Array<ContentEntity>>;
  type: WidgetTypeEnum;
};

export type ContentsWidgetPropsInput = {
  collectionId?: InputMaybe<Scalars['String']>;
  populationType: ContentWidgetPopulationTypeEnum;
  sortType?: InputMaybe<ContentWidgetSortTypeEnum>;
  staticIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ContentsWidgetType = {
  __typename?: 'ContentsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  /** collection id */
  collectionId?: Maybe<Scalars['String']>;
  /** contents */
  contents: ContentConnection;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  populationType: ContentWidgetPopulationTypeEnum;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sortType: ContentWidgetSortTypeEnum;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  /** static content ids */
  staticIds?: Maybe<Array<Scalars['String']>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ContentsWidgetTypeContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  facetValueInputs?: InputMaybe<Array<FacetValueInput>>;
  filters?: InputMaybe<ContentFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortType>>;
};

export type ContinueLearningWidgetPreviewType = {
  __typename?: 'ContinueLearningWidgetPreviewType';
  /** ongoing contents */
  contents?: Maybe<Array<Scalars['JSON']>>;
  type: WidgetTypeEnum;
};

export type ContinueLearningWidgetType = {
  __typename?: 'ContinueLearningWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  contents: ContentConnection;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ContinueLearningWidgetTypeContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
};

export type ConvertMediaInput = {
  /** mimetype of the media to be converted from */
  from: Scalars['String'];
  /** purpose of the job */
  purpose?: InputMaybe<JobPurposeEnum>;
  /** mimetype of the media to be converted to */
  to: Scalars['String'];
  /** url of the media to be converted */
  url: Scalars['String'];
};

/** Response received on convert media mutation */
export type ConvertMediaPayload = {
  __typename?: 'ConvertMediaPayload';
  /** job id of the convert media job */
  jobScheduleId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Information regarding the conversion of the document */
export type ConvertedMedia = {
  __typename?: 'ConvertedMedia';
  jobId: Scalars['String'];
  jobStatus: JobStatusEnum;
  /** media type of uploaded entity */
  mimeType?: Maybe<Scalars['String']>;
};

/** Available Media Formats */
export type ConvertedMediaInput = {
  format: Scalars['String'];
  medias?: InputMaybe<Array<FormattedResourceMediaInput>>;
};

/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentType = {
  __typename?: 'CourseContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  courseSettings?: Maybe<CourseSettings>;
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  creatorCommentChannel?: Maybe<ChannelType>;
  /** comment channel for creators */
  creatorCommentChannelId: Scalars['String'];
  defaultGroupId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dripSettings: DripSettingsType;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  duplicatedFrom?: Maybe<Scalars['String']>;
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** duration of content in seconds */
  duration: Scalars['Float'];
  /** Feedback Questions for the course */
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  instructors: Array<InstructorType>;
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  /** duration of content */
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  performance?: Maybe<CoursePerformanceType>;
  /** Ids of prerequisites related to the content */
  prerequisiteIds?: Maybe<Array<Scalars['String']>>;
  prerequisites: Array<ContentEntity>;
  /** Storing media of course-preview */
  preview?: Maybe<EntityMediaType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  /** Root blockId of the course */
  rootBlockId?: Maybe<Scalars['String']>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Ids of skill related to the content */
  skillIds: Array<Scalars['String']>;
  skills: Array<SkillType>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  /** Tagline for course */
  tagline?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionConfigs?: Maybe<Array<VersionConfig>>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings: VisibilitySettingsType;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeRootBlockIdArgs = {
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};

/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Course resource type. It is one of the kind of resources supported in Lyearn */
export type CourseContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export type CourseInstructorInputType = {
  externalInstructor?: InputMaybe<ExternalContentInstructorInputType>;
  internalUserId?: InputMaybe<Scalars['String']>;
};

/** Holds details of instructor and their role for enclosing Course */
export type CourseInstructorType = {
  __typename?: 'CourseInstructorType';
  internalUserId?: Maybe<Scalars['String']>;
};

export type CourseOutlineInput = {
  sections?: InputMaybe<Array<CourseOutlineSectionInput>>;
};

export type CourseOutlineLessonInput = {
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CourseOutlineLessonStubType = {
  __typename?: 'CourseOutlineLessonStubType';
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CourseOutlineResultType = {
  __typename?: 'CourseOutlineResultType';
  description?: Maybe<Scalars['String']>;
  sections?: Maybe<Array<CourseOutlineSectionStubType>>;
  title: Scalars['String'];
};

export type CourseOutlineSectionInput = {
  lessons?: InputMaybe<Array<CourseOutlineLessonInput>>;
  title: Scalars['String'];
};

export type CourseOutlineSectionStubType = {
  __typename?: 'CourseOutlineSectionStubType';
  lessons?: Maybe<Array<CourseOutlineLessonStubType>>;
  title: Scalars['String'];
};

/** Course Performance Type */
export type CoursePerformanceType = {
  __typename?: 'CoursePerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  archivedAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** stores the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  lastScrollPosition?: Maybe<Scalars['Float']>;
  learningType?: Maybe<LearningTypeEnum>;
  lockSettings?: Maybe<Array<ContentLockSettings>>;
  masteryScore?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['String']>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float'];
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  scrollPosition?: Maybe<Scalars['Float']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type CoursePrerequisiteInputType = {
  /** Entity Id of pre-requisite */
  entityId: Scalars['String'];
  /** type of pre-requisite */
  entityType: ResourceTypeEnum;
};

/** Will contain various course settings */
export type CourseSettings = {
  __typename?: 'CourseSettings';
  /**  flag to check if review is enabled or not */
  isReviewEnabled?: Maybe<Scalars['Boolean']>;
};

export type CreateActionItemInput = {
  assignedToId?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<StatusCategoryEnum>;
  dueDate?: InputMaybe<Scalars['String']>;
  entity?: InputMaybe<RelatedEntityInput>;
  /** External Link of the actionItem */
  externalLink?: InputMaybe<Scalars['String']>;
  fileId?: InputMaybe<Scalars['String']>;
  /** instructions for the learner */
  instruction?: InputMaybe<InstructionInput>;
  /** Details for external entity */
  integrationConfig?: InputMaybe<ActionItemIntegrationConfigInput>;
  /** Details for external entity */
  metadata?: InputMaybe<ActionItemMetadataInput>;
  parentId?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<ActionItemPriorityEnum>;
  progress?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<EntityTagsInput>;
  title: Scalars['String'];
};

export type CreateActionItemsInput = {
  /** Multiple action items */
  actionItems?: InputMaybe<Array<CreateActionItemInput>>;
  /** Config for task creation */
  taskCreationConfig: TaskCreationConfig;
};

/** Agenda creation Input */
export type CreateAgendaInput = {
  /** Database related to the Agenda */
  database: AgendaDatabaseInput;
  /** Description of Agenda */
  description?: InputMaybe<Scalars['String']>;
  /** Agenda attachments */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Name of Agenda */
  name: Scalars['String'];
  /** Agenda Type */
  type: AgendaTypeEnum;
};

export type CreateAgendaItemInput = {
  creationCadenceConfig?: InputMaybe<CadenceConfigInput>;
  entity: AgendaItemEntityInput;
  /** Agenda Instructions in text or media */
  instruction?: InputMaybe<InstructionInput>;
  sourceType?: InputMaybe<AgendaItemEntityTypeEnum>;
  subType: ActionItemSubTypeEnum;
  title: Scalars['String'];
};

export type CreateAgendaItemTemplateInput = {
  name: Scalars['String'];
};

export type CreateAgendaItemsFromTemplateInput = {
  meetingInstanceId: Scalars['String'];
  templateId: Scalars['String'];
};

export type CreateAnalyticsDashboardInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<AnalyticsDashboardStatusEnum>;
  storeId: Scalars['String'];
};

/** Create AnalyticsDashboard Response */
export type CreateAnalyticsDashboardPayload = {
  __typename?: 'CreateAnalyticsDashboardPayload';
  analyticsDashboard?: Maybe<AnalyticsDashboardType>;
  errorCode?: Maybe<AnalyticsDashboardErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateArticleInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Storing media of article-cover */
  cover?: InputMaybe<CreateMediaInput>;
  name?: InputMaybe<Scalars['String']>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Create Article Response */
export type CreateArticlePayload = {
  __typename?: 'CreateArticlePayload';
  article?: Maybe<ArticleContentType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateAssignmentInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CreateAssignmentPayload = {
  __typename?: 'CreateAssignmentPayload';
  assignment: AssignmentType;
};

export type CreateAuditMetadataType = {
  __typename?: 'CreateAuditMetadataType';
  action: AuditActionEnum;
  auditId: Scalars['String'];
  collectionName: EntityCollectionEnum;
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  /** Content that was bookmarked by the user */
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<InstructionType>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  relatedCollectionName?: Maybe<EntityCollectionEnum>;
  workspaceId: Scalars['String'];
};

/** Input to create a block */
export type CreateBlockInput = {
  /** Attributes of the block */
  attributes: Scalars['String'];
  /** Type of the block */
  blockType: Scalars['String'];
  /** Child block ids in order of their hierarchy */
  childrenIds?: InputMaybe<Array<Scalars['String']>>;
  /** text content that might be present in the block */
  decoratedText?: InputMaybe<Scalars['String']>;
  /** Markup details of styling and comments */
  markups?: InputMaybe<Scalars['String']>;
  /** Parent entity's id */
  parentId?: InputMaybe<Scalars['String']>;
  /** Parent entity's type or table */
  parentType?: InputMaybe<Scalars['String']>;
  /** RootId to store the block Id of the top most block in the hierarchy */
  rootId: Scalars['String'];
  /** Root Block's type or table */
  rootType: Scalars['String'];
  /** text content that might be present in the block */
  text?: InputMaybe<Scalars['String']>;
};

/** Response for create block mutation */
export type CreateBlockPayload = {
  __typename?: 'CreateBlockPayload';
  block?: Maybe<BaseBlockType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateBookmarkInput = {
  /** Id of the content */
  entityId: Scalars['String'];
  /** Type of content to bookmark */
  entityType: ResourceTypeEnum;
};

export type CreateBookmarkPayload = {
  __typename?: 'CreateBookmarkPayload';
  /** created bookmark */
  bookmark?: Maybe<BookmarkType>;
};

export type CreateCertificateInput = {
  entityId: Scalars['String'];
  userId: Scalars['String'];
};

export enum CreateChannelErrorCodeEnum {
  ForbiddenError = 'FORBIDDEN_ERROR',
}

/** Inputs required to creat channel */
export type CreateChannelInput = {
  /** accessType of the channel */
  accessType: ChannelAccessTypeEnum;
  /** description of channel */
  description?: InputMaybe<Scalars['String']>;
  /** Name of the channel */
  name: Scalars['String'];
  /** ParentId of this channel like classroomId */
  parentId: Scalars['String'];
  /** ParentType of the channel like classroom */
  parentType: ChannelParentEntityTypeEnum;
  /** Type of the channel like announcement, discussion etc */
  type: ChannelTypeEnum;
};

export type CreateChannelPayload = {
  __typename?: 'CreateChannelPayload';
  channel?: Maybe<ChannelType>;
  errorCode?: Maybe<CreateChannelErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateChartInput = {
  chartType: ChartTypeEnum;
  description?: InputMaybe<Scalars['String']>;
  dimensionConfigs?: InputMaybe<Array<DimensionConfigTypeInput>>;
  dimensions?: InputMaybe<Array<Scalars['String']>>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  measures?: InputMaybe<Array<MeasureTypeInput>>;
  name?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Array<Scalars['String']>>;
  sortOrder?: InputMaybe<Array<SortType>>;
  timeDimensions?: InputMaybe<Array<TimeDimensionTypeInput>>;
};

export type CreateChartPayload = {
  __typename?: 'CreateChartPayload';
  chart: ChartWidgetType;
};

export type CreateChartsInput = {
  createChartsInput: Array<CreateChartInput>;
};

export type CreateChartsPayload = {
  __typename?: 'CreateChartsPayload';
  chartIds: Array<Scalars['String']>;
};

/** Checkin creation Input */
export type CreateCheckinInput = {
  /** To Allow or disallow advanceSettings */
  advanceSettings?: InputMaybe<CheckinAdvanceSettingsInput>;
  /** Agendas related to the checkin */
  agendas: Array<CreateAgendaInput>;
  /** Description about Checkin */
  description?: InputMaybe<Scalars['String']>;
  /** Duration of checkin in seconds */
  duration: Scalars['Float'];
  /** Checkin attachments */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Name of checkin */
  name: Scalars['String'];
  tags?: InputMaybe<EntityTagsInput>;
};

/** Create Checkin-Content Response */
export type CreateCheckinPayload = {
  __typename?: 'CreateCheckinPayload';
  checkin?: Maybe<CheckinType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCheckoutSessionInput = {
  cartDetails: Array<CartDetails>;
  redirectURL: Scalars['String'];
  storeId: Scalars['String'];
};

/** Resolves to Checkout Session Payload according to payment provider */
export type CreateCheckoutSessionMutationPayload =
  | InvalidCheckoutSessionMutationPayload
  | NaCheckoutSessionMutationPayload
  | RazorpayCheckoutSessionMutationPayload
  | StripeCheckoutSessionMutationPayload;

export type CreateClassroomInput = {
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  status?: InputMaybe<ClassroomStatusEnum>;
  subject?: InputMaybe<Scalars['String']>;
};

export type CreateClassroomPayload = {
  __typename?: 'CreateClassroomPayload';
  /** Created Classroom */
  classroom?: Maybe<ClassroomType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Inputs required to create a cohort */
export type CreateCohortInput = {
  /** Description of the cohort */
  description?: InputMaybe<Scalars['String']>;
  /** Name of the cohort */
  name: Scalars['String'];
};

export type CreateCohortPayload = {
  __typename?: 'CreateCohortPayload';
  /** Created Cohort */
  cohort?: Maybe<CohortType>;
  /** Error Code */
  errorCode?: Maybe<CohortErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCommentThreadInput = {
  /** Attachments of this post */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  /** ChannelId on which post to be created */
  channelId: Scalars['String'];
  /** Mentions like @user */
  mentions?: InputMaybe<Array<MentionInputType>>;
  /** Actual message of the post */
  message: Scalars['String'];
  /** Title of the post, can be used for posts like announcements */
  title?: InputMaybe<Scalars['String']>;
  /** Type of the post */
  type: PostTypeEnum;
};

export type CreateCommentThreadPayload = {
  __typename?: 'CreateCommentThreadPayload';
  errorCode?: Maybe<CreatePostErrorCodeEnum>;
  post?: Maybe<PostType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateConferenceInput = {
  maxSeatsAllowed?: InputMaybe<Scalars['Float']>;
  registeredUsers?: InputMaybe<Array<WorkspaceUserDetailsInput>>;
  source: WorkspaceEventDetailsInput;
  targets?: InputMaybe<Array<WorkspaceEventDetailsInput>>;
};

/** Creates database and corresponding metadata */
export type CreateDatabaseInput = {
  dbName: Scalars['String'];
  description: Scalars['String'];
  fields: Array<DatabaseMetaFieldsInput>;
  subDbName?: InputMaybe<Scalars['String']>;
};

/** Response to create database operation */
export type CreateDatabasePayload = {
  __typename?: 'CreateDatabasePayload';
  /** Default View of database */
  dbDefaultView?: Maybe<DatabaseViewType>;
  /** Created Database */
  dbMetadata?: Maybe<DatabaseMetadataType>;
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Defines create input for database view */
export type CreateDatabaseViewInput = {
  dbId: Scalars['String'];
  dbMetadataId?: InputMaybe<Scalars['String']>;
  format: DatabaseViewFormat;
  name: Scalars['String'];
  viewType: ViewTypeEnum;
};

/** Response to create database view operation */
export type CreateDatabaseViewPayload = {
  __typename?: 'CreateDatabaseViewPayload';
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  /** Returns created view */
  view?: Maybe<DatabaseViewType>;
};

/** input for creating a new department under a tenant */
export type CreateDepartmentInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Input props rquired for creating a department */
export type CreateDepartmentInputPropsType = {
  __typename?: 'CreateDepartmentInputPropsType';
  /** Description of the department */
  description?: Maybe<Scalars['String']>;
  /** Name of the department */
  name: Scalars['String'];
};

export type CreateDepartmentPayload = {
  __typename?: 'CreateDepartmentPayload';
  /** Created Department */
  department: DepartmentType;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateEmailDigestInput = {
  fileFormat: EmailDigestFileFormatEnum;
  name: Scalars['String'];
  recipients: Array<RecipientInfoInput>;
  reportId: Scalars['String'];
  schedule: ScheduleInfoInput;
};

export type CreateEventInput = {
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  completionSettings?: InputMaybe<CompletionSettingsInput>;
  /** Storing media of course-cover */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']>;
  endTime: Scalars['String'];
  externalEventProps?: InputMaybe<ExternalEventProps>;
  instructorIds: Array<Scalars['String']>;
  liveEventProps?: InputMaybe<LiveEventProps>;
  name: Scalars['String'];
  objective: Scalars['String'];
  offlineEventProps?: InputMaybe<OfflineEventProps>;
  postWorkContentIds?: InputMaybe<EventWorkContentsInput>;
  postwork?: InputMaybe<SetEventWorkConfigInput>;
  preWorkContentIds?: InputMaybe<EventWorkContentsInput>;
  prework?: InputMaybe<SetEventWorkConfigInput>;
  registrationSettings?: InputMaybe<RegistrationSettingsInput>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  startTime: Scalars['String'];
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']>;
  type: EventTypeEnum;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreateEventScheduleInput = {
  classroomId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<ClassroomEventTypeEnum>;
  /** All users of the groups have access to events of this event schedule */
  groupIds: Array<Scalars['String']>;
  name: Scalars['String'];
  recurrenceRuleConfiguration: EventScheduleRecurrenceRuleConfigurationInput;
  status?: InputMaybe<EventScheduleStatusEnum>;
};

/** Create eventSchedule mutation response */
export type CreateEventSchedulePayload = {
  __typename?: 'CreateEventSchedulePayload';
  errorCode?: Maybe<EventScheduleErrorCodeEnum>;
  eventSchedule?: Maybe<EventScheduleType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateExerciseInput = {
  /** Exercise Comments/ and other advance level settings */
  advanceSettings?: InputMaybe<ExerciseAdvanceSettingsInput>;
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  completionReviewRequired?: InputMaybe<Scalars['Boolean']>;
  /** Storing media of exercise-cover */
  cover?: InputMaybe<CreateMediaInput>;
  duration?: InputMaybe<Scalars['Float']>;
  exerciseQuestions?: InputMaybe<Array<ExerciseQuestionInput>>;
  /** instructions for the learner */
  instruction?: InputMaybe<ExerciseInstructionInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  objective?: InputMaybe<Scalars['String']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']>;
  reviewType?: InputMaybe<ResourceReviewTypeEnum>;
  reviewerConfig?: InputMaybe<ReviewerConfigInput>;
  /** instructions for the reviewer */
  reviewerInstruction?: InputMaybe<ExerciseInstructionInput>;
  /** scorecards */
  scorecardIds?: InputMaybe<Array<Scalars['String']>>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
};

export type CreateExercisePayload = {
  __typename?: 'CreateExercisePayload';
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  /** Created Assignment */
  exercise?: Maybe<ExerciseType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateExternalContentInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Url of the external content */
  contentUrl?: InputMaybe<Scalars['String']>;
  /** Cover image of the external content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the external content */
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the external content */
  duration?: InputMaybe<Scalars['Float']>;
  /** External content type */
  externalContentType?: InputMaybe<ExternalContentTypeEnum>;
  externalResourceInfo?: InputMaybe<ExternalResourceInfoInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  marketplaceContentId?: InputMaybe<Scalars['String']>;
  /** Title of the external content */
  name?: InputMaybe<Scalars['String']>;
  /** Objective of the external content */
  objective?: InputMaybe<Scalars['String']>;
  /** Id of the external content provider */
  providerId?: InputMaybe<Scalars['String']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  /** Method to verify completion of the external content */
  verificationMethod?: InputMaybe<VerificationMethodInputType>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Payload for create external content mutation */
export type CreateExternalContentPayload = {
  __typename?: 'CreateExternalContentPayload';
  externalContent?: Maybe<ExternalContentType>;
};

/** Feedback provided by the user */
export type CreateFeedbackInput = {
  /** Entity Id */
  entityId: Scalars['String'];
  /** Type of Enum */
  entityType: EntityTypeEnum;
  metadata?: InputMaybe<FeedbackMetadataInput>;
  questionScope?: InputMaybe<FeedbackQuestionScopeEnum>;
  /** Feedback questions and responses */
  responses: Array<FeedbackResponseInput>;
  /** User who is giving the feedback */
  userId: Scalars['String'];
};

/** Feedback Question to be answered by user */
export type CreateFeedbackQuestionInput = {
  /** Description */
  description: Scalars['String'];
  /** Questions */
  questions: Array<QuestionInput>;
  /** Type Of Question */
  type: FeedbackQuestionTypeEnum;
};

export type CreateFileInput = {
  /** files or folder */
  category: FileCategoryEnum;
  description?: InputMaybe<Scalars['String']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  /** contains type of file */
  includes?: InputMaybe<Array<FileContentTypeEnum>>;
  name: Scalars['String'];
  /** order in parent */
  orderInParent?: InputMaybe<Scalars['Float']>;
  /** category or normal file */
  parentId?: InputMaybe<Scalars['String']>;
  /** Location under which folders/file will be searched */
  path?: InputMaybe<Scalars['String']>;
  /** category or normal file */
  purpose?: InputMaybe<FilePurposeEnum>;
  visibilitySettings: VisibilitySettingsInput;
};

export type CreateGenerativeTaskInput = {
  generativeTaskContextId?: InputMaybe<Scalars['String']>;
  input: GenerativeTaskPropsInput;
  processSync?: InputMaybe<Scalars['Boolean']>;
  type: GenerativeTaskTypeEnum;
};

export type CreateGroupInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  contentVisibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  description?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  name: Scalars['String'];
  systemGenerated?: InputMaybe<Scalars['Boolean']>;
  type: GroupTypeEnum;
  userCollectionGroupProps?: InputMaybe<UserCollectionGroupPropsInput>;
};

export type CreateHomeworkInput = {
  /** Homework Comments/ submissions/ word limit and other advance level settings */
  advanceSettings: HomeworkAdvanceSettingsInput;
  /** defines the response type of Homework */
  allowedResponseTypes: Array<ResourceResponseTypeEnum>;
  /** Category of Homework */
  categoryId: Scalars['String'];
  /** Tells the Homework is connected to which classroom */
  classroomId: Scalars['String'];
  /** Deadlines, availability of Homework  */
  enrolmentSettings: HomeworkEnrolmentSettingsInput;
  /** Homework Grading configs */
  gradeSettings: HomeworkGradeSettingsInput;
  /** Homework Instructions */
  instruction?: InputMaybe<HomeworkInstructionInput>;
  name: Scalars['String'];
};

export type CreateHomeworkPayload = {
  __typename?: 'CreateHomeworkPayload';
  /** Created Homework */
  resource?: Maybe<HomeworkType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateImportJobInput = {
  childJobs?: InputMaybe<Array<ChildJobMetaInputType>>;
  customJobProcessor?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<JobInputPropsInput>;
  /** url and format of the media associated with the job */
  mediaUrls?: InputMaybe<Array<MediaUrlInput>>;
  /** purpose of the job */
  purpose?: InputMaybe<JobPurposeEnum>;
  settings?: InputMaybe<JobSettingsInput>;
};

export type CreateIndividualEventInput = {
  classroomId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  eventStartTimestamp: Scalars['String'];
  eventType: ClassroomEventTypeEnum;
  /** Add users of this groupIds */
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  resources?: InputMaybe<Array<ClassroomResourceInput>>;
  status?: InputMaybe<ClassroomEventStatusEnum>;
};

/** Create Event Response */
export type CreateIndividualEventPayload = {
  __typename?: 'CreateIndividualEventPayload';
  /** Error code */
  errorCode?: Maybe<ClassroomEventErrorCodeEnum>;
  event?: Maybe<ClassroomEventType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateJobInput = {
  /** errors that occurd during job processing */
  error?: InputMaybe<Scalars['String']>;
  /** id of the job */
  jobScheduleId: Scalars['String'];
  /** url and format of the media associated with the job */
  mediaUrls?: InputMaybe<Array<MediaUrlInput>>;
  /** purpose of the job */
  purpose?: InputMaybe<JobPurposeEnum>;
  /** status of the job */
  status: JobEntityStatusEnum;
};

export type CreateJobPayload = {
  __typename?: 'CreateJobPayload';
  /** job id of the job created */
  jobScheduleId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateKeyResultInput = {
  /** Order of okr in the parent okr child list */
  childOrder?: InputMaybe<Scalars['Float']>;
  /** Id of the OKR Cycle in which this is created */
  cycleId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  dueDateConfig: DueDateConfigInput;
  instruction?: InputMaybe<InstructionInput>;
  /** Details for linking externally */
  integrationConfig?: InputMaybe<OkrIntegrationConfigInput>;
  name: Scalars['String'];
  /** outcome for the KeyResult */
  outcome: KeyResultOutcomeInput;
  /** owners of OKR */
  ownerIds: Array<Scalars['String']>;
  /** ParentId for objective or keyResult */
  parentId?: InputMaybe<Scalars['String']>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  /** Details for progress measurement */
  progressMeasurementConfig?: InputMaybe<OkrProgressMeasurementConfigInput>;
  tags?: InputMaybe<EntityTagsInput>;
};

export type CreateMediaInput = {
  /** access class of media */
  accessClass?: InputMaybe<MediaAccessClassEnum>;
  /** Information regarding Media category(Image, video, audio, document) */
  kind: MediaKindEnum;
  /** Location of media object */
  location?: InputMaybe<MediaLocationInput>;
  /** mediaID associated with media */
  mediaId?: InputMaybe<Scalars['String']>;
  /** media type of uploaded resource */
  mimeType?: InputMaybe<Scalars['String']>;
  /** name of the media attached */
  name?: InputMaybe<Scalars['String']>;
  /** preview enabled for the media */
  previewEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Size of the attachment in kbs */
  size?: InputMaybe<Scalars['Float']>;
  /** enable maintaining relationship during bulk create */
  uniqueId?: InputMaybe<Scalars['String']>;
  /** url of the attachment stored on our end */
  url?: InputMaybe<Scalars['String']>;
  urlPreviewMetadata?: InputMaybe<UrlPreviewMetadataInput>;
};

export type CreateMeetingInput = {
  name?: InputMaybe<Scalars['String']>;
  participantIds: Array<Scalars['String']>;
  schedule: ScheduleInfoInput;
  templateId?: InputMaybe<Scalars['String']>;
  type: MeetingTypeEnum;
};

export type CreateMeetingInstanceInput = {
  meetingId: Scalars['String'];
  occurrenceType?: InputMaybe<MeetingInstanceOccurrenceTypeEnum>;
  participantIds: Array<Scalars['String']>;
  startTime: Scalars['String'];
  status: MeetingInstanceStatusEnum;
  type: MeetingInstanceTypeEnum;
};

export type CreateMetricConfigsInputPropsType = {
  __typename?: 'CreateMetricConfigsInputPropsType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  defaultTargetFrequency?: Maybe<RelativeDateFilterUnitEnum>;
  defaultTargetValue?: Maybe<Scalars['Float']>;
  defaultTargetValueUnit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String'];
  measurementUnit: KeyResultMetricEnum;
  /** Name of metric */
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  syncConfig: MetricSyncConfig;
  /** Type of metric */
  type: MetricTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CreateMetricInput = {
  /** Salesforce -> dataObject */
  dataObject?: InputMaybe<Scalars['String']>;
  defaultTargetFrequency?: InputMaybe<RelativeDateFilterUnitEnum>;
  defaultTargetValue?: InputMaybe<Scalars['Float']>;
  defaultTargetValueUnit?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  manualMetricConfig?: InputMaybe<ManualMetricConfigInput>;
  /** Metric type */
  measurementUnit: KeyResultMetricEnum;
  /** Salesforce -> filters */
  metricType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  reminders?: InputMaybe<Array<Scalars['String']>>;
  repeatSchedule?: InputMaybe<ScheduleInfoInput>;
  syncConfig: MetricSyncConfigInputType;
  /** Type of metric. By default will be considered as activity metric. */
  type: MetricTypeEnum;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateOkrCycleInput = {
  /** Due date of the cycle */
  dueDateConfig: DueDateConfigInput;
  /** display emoji or color */
  iconSetting: IconSettingsInput;
  /** Name of cycle */
  name: Scalars['String'];
  objectivePermissions: Array<OkrPermissionInput>;
  scheduleConfig?: InputMaybe<ScheduleConfigInput>;
  /** start date of the cycle */
  startDate: Scalars['String'];
  /** store who can view the cycle */
  visibilitySettings: VisibilitySettingsInput;
};

export type CreateOkrCyclePayload = {
  __typename?: 'CreateOKRCyclePayload';
  cycle?: Maybe<OkrCycle>;
};

export type CreateObjectiveInput = {
  /** category config input  */
  categoryConfig: ObjectiveCategoryConfigInput;
  childObjectives: Array<CreateObjectiveInput>;
  /** Order of okr in the parent okr child list */
  childOrder?: InputMaybe<Scalars['Float']>;
  contributeToProgress?: InputMaybe<Scalars['Boolean']>;
  /** Id of the OKR Cycle in which this is created */
  cycleId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  dueDateConfig: DueDateConfigInput;
  instruction?: InputMaybe<InstructionInput>;
  keyResults: Array<CreateKeyResultInput>;
  name: Scalars['String'];
  /** owners of OKR */
  ownerIds: Array<Scalars['String']>;
  /** ParentId for objective or keyResult */
  parentId?: InputMaybe<Scalars['String']>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  /** store who can view the objective */
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreateObjectivePayload = {
  __typename?: 'CreateObjectivePayload';
  objective?: Maybe<ObjectiveType>;
};

export type CreateOrUpdateWidgetInput = {
  chartWidgetProps?: InputMaybe<ChartWidgetPropsInput>;
  contentsWidgetProps?: InputMaybe<ContentsWidgetPropsInput>;
  externalWidgetProps?: InputMaybe<ExternalWidgetPropsInput>;
  listItemsWidgetProps?: InputMaybe<ListItemsWidgetPropsInput>;
  type: WidgetTypeEnum;
  widgetId?: InputMaybe<Scalars['String']>;
};

/** input for creating a new orgRole under a department */
export type CreateOrgRoleInput = {
  definition?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  trackId?: InputMaybe<Scalars['String']>;
};

/** Input props rquired for creating an orgROle */
export type CreateOrgRoleInputPropsType = {
  __typename?: 'CreateOrgRoleInputPropsType';
  /** DepartmentId in which the orgRole belongs */
  departmentId: Scalars['String'];
  /** Description of the orgRole */
  description?: Maybe<Scalars['String']>;
  /** Key of orgRole */
  key: Scalars['String'];
  /** Level of orgRole */
  level: Scalars['Float'];
  /** mindsets attached to orgRole */
  mindsetIds?: Maybe<Array<Scalars['String']>>;
  /** Name of the orgRole */
  name?: Maybe<Scalars['String']>;
  /** skill matrix attached to org role and skill mastery settings */
  skillMatrixConfig: SkillMatrixConfig;
  /** TrackId in which the orgRole belongs */
  trackId?: Maybe<Scalars['String']>;
};

export type CreateOrgRolePayload = {
  __typename?: 'CreateOrgRolePayload';
  /** Created OrgRole */
  orgRole?: Maybe<OrgRoleType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateOutlookCalendarEventFromMeetingInput = {
  meetingId: Scalars['String'];
  relayState?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type CreateOutlookCalendarEventFromMeetingPayload = {
  __typename?: 'CreateOutlookCalendarEventFromMeetingPayload';
  /** redirect url if user is not loginedin */
  redirectUrl?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatePathInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  /** Cover image of the path content */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']>;
  dripSettings?: InputMaybe<DripSettingsInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  objective?: InputMaybe<Scalars['String']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']>;
  sections: Array<PathSectionInput>;
  /** skill ids */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreatePathPayload = {
  __typename?: 'CreatePathPayload';
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  path?: Maybe<PathContentType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatePeerFeedbackInput = {
  /** Attachments of this feedback */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  feedbackMessage?: InputMaybe<Scalars['String']>;
  learningContentIds?: InputMaybe<Array<Scalars['String']>>;
  /** Mentions like @user */
  mentions?: InputMaybe<Array<MentionInputType>>;
  mindsetRatings?: InputMaybe<Array<PeerFeedbackMindsetRatingInputType>>;
  receiverUserId: Scalars['String'];
  skillRatings?: InputMaybe<Array<PeerFeedbackSkillRatingInputType>>;
  status: PeerFeedbackStatusEnum;
  visibleToManager?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePostAttachmentInput = {
  /** Attachments of this post */
  attachment?: InputMaybe<CreateMediaInput>;
  /** Handles preview visibility on the post */
  previewEnabled?: InputMaybe<Scalars['Boolean']>;
};

export enum CreatePostErrorCodeEnum {
  ForbiddenError = 'FORBIDDEN_ERROR',
  PostNotCreated = 'POST_NOT_CREATED',
}

export type CreatePostInput = {
  /** Attachments of this post */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  /** ChannelId on which post to be created */
  channelId: Scalars['String'];
  /** Creator of post, if empty user from context will be taken */
  createdById?: InputMaybe<Scalars['String']>;
  /** Mentions like @user */
  mentions?: InputMaybe<Array<MentionInputType>>;
  /** Actual message of the post */
  message: Scalars['String'];
  /** ParentId of post which is of type reply */
  parentId?: InputMaybe<Scalars['String']>;
  /** Title of the post, can be used for posts like announcements */
  title?: InputMaybe<Scalars['String']>;
  /** Type of the post */
  type: PostTypeEnum;
  /** [Deprecated]: Urls of the post */
  urls?: InputMaybe<Array<Scalars['String']>>;
  /** Urls of the post */
  urlsV2?: InputMaybe<Array<PostUrlInput>>;
};

export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  errorCode?: Maybe<CreatePostErrorCodeEnum>;
  post?: Maybe<PostType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatePostsInput = {
  /** Create post inputs  */
  createPostInputs: Array<CreatePostInput>;
};

export type CreatePostsPayload = {
  __typename?: 'CreatePostsPayload';
  posts?: Maybe<PostType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatePraiseInput = {
  /** Attachments of this post */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  /** Mentions in the message payload on a post like user mentions (@user) */
  mentions?: InputMaybe<Array<MentionInputType>>;
  message: Scalars['String'];
  mindsetRatings?: InputMaybe<Array<PeerFeedbackMindsetRatingInputType>>;
  receiverUserIds: Array<Scalars['String']>;
  skillRatings?: InputMaybe<Array<PeerFeedbackSkillRatingInputType>>;
  visibleToEveryone: Scalars['Boolean'];
};

export type CreatePublicApiCredentialsInput = {
  /** UserId to be associated with credentials. If not passed, SystemUser is associated */
  userId?: InputMaybe<Scalars['String']>;
  /** workspaceId of the tenant */
  workspaceId: Scalars['String'];
};

export type CreatePublicApiCredentialsPayload = {
  __typename?: 'CreatePublicAPICredentialsPayload';
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type CreateQuestionsInput = {
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']>;
  /** Questions to be created */
  questions: Array<QuestionInput>;
};

export type CreateQuestionsPayload = {
  __typename?: 'CreateQuestionsPayload';
  /** Questions created */
  createdQuestions: Array<QuestionType>;
  /** Failed Questions */
  failedQuestions: Array<QuestionPayload>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateQuizInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  /** Cover image of the content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the content */
  description?: InputMaybe<Scalars['String']>;
  /** Duration in seconds */
  duration?: InputMaybe<Scalars['Float']>;
  /** Entity on which this quiz has to attached */
  entityId?: InputMaybe<Scalars['String']>;
  /** Entity type of the entity where this quiz will be attached */
  entityType?: InputMaybe<QuizEntityTypeEnum>;
  /** Flag to force generate new */
  generateNew?: InputMaybe<Scalars['Boolean']>;
  /** Instructions for the quiz */
  instruction?: InputMaybe<ResourceInstructionInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  /** Name of the quiz */
  name?: InputMaybe<Scalars['String']>;
  /** Objective of the content */
  objective?: InputMaybe<Scalars['String']>;
  /** Passing Percentage of the quiz */
  passingPercentage?: InputMaybe<Scalars['Float']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']>;
  /** Quiz Question ids along with settings */
  questions?: InputMaybe<Array<QuizQuestionInput>>;
  /** quiz level settings */
  settings?: InputMaybe<QuizSettingsInput>;
  /** Ids of skills suitable for the content */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  /** SubType of resource, here quiz. Ex - Static, inLesson, review etc */
  subType: ResourceSubTypeEnum;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  /** Tags related to quiz */
  tags?: InputMaybe<EntityTagsInput>;
};

export type CreateQuizPayload = {
  __typename?: 'CreateQuizPayload';
  /** Flag to denote if questions are available to create quiz */
  questionsAvailable?: Maybe<Scalars['Boolean']>;
  /** Created Quiz */
  quiz?: Maybe<QuizContentType>;
};

/** Create Recurring Event by Cron Response */
export type CreateRecurringEventByCronPayload = {
  __typename?: 'CreateRecurringEventByCronPayload';
  /** Error code */
  errorCode?: Maybe<ClassroomEventErrorCodeEnum>;
  event?: Maybe<ClassroomEventType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateRecurringEventInput = {
  classroomId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  eventScheduleId: Scalars['String'];
  eventStartTimestamp: Scalars['String'];
  eventType: ClassroomEventTypeEnum;
  /** Add users of this groupIds */
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  recurrenceRuleId: Scalars['String'];
  resources?: InputMaybe<Array<ClassroomResourceInput>>;
  status?: InputMaybe<ClassroomEventStatusEnum>;
};

/** Create Recurring Event Response */
export type CreateRecurringEventPayload = {
  __typename?: 'CreateRecurringEventPayload';
  /** Error code */
  errorCode?: Maybe<ClassroomEventErrorCodeEnum>;
  event?: Maybe<ClassroomEventType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateReportInput = {
  description?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  iconSettings: IconSettingsInput;
  name: Scalars['String'];
  tags?: InputMaybe<EntityTagsInput>;
  type?: InputMaybe<ReportTypeEnum>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  /** Contains info about chart widgets and their layout */
  widgetsLayout?: InputMaybe<Array<WidgetLayoutInput>>;
};

/** Create Report Payload */
export type CreateReportPayload = {
  __typename?: 'CreateReportPayload';
  report: ReportType;
};

export type CreateResourceInput = {
  autoRename?: InputMaybe<Scalars['Boolean']>;
  category: ResourceCategoryEnum;
  classroomId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  kind: ResourceKindEnum;
  mimeType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentId: Scalars['String'];
  path: Scalars['String'];
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Create Resource Response */
export type CreateResourcePayload = {
  __typename?: 'CreateResourcePayload';
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  resource?: Maybe<ResourceType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateResourceUserInteractionInput = {
  operation: ResourceUserInteractionOperation;
  resourceId: Scalars['String'];
  resourceType?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type CreateResourceUserInteractionsInput = {
  resourceUserInteractions: Array<CreateResourceUserInteractionInput>;
};

/** ReviewCycle Schema */
export type CreateReviewCycleInput = {
  /** ReviewCycle description */
  description?: InputMaybe<Scalars['String']>;
  /** When will the review cycle end */
  endDate: Scalars['String'];
  /** Icon settings */
  iconSettings: IconSettingsInput;
  /** Types of modules to be reviewed in this review cycle */
  modulesToReview?: InputMaybe<Array<ReviewModuleTypeEnum>>;
  /** Review Cycle End Settings */
  reviewCycleEndSettings: ReviewCycleEndSettingsInput;
  /** reviewee to whom this reviewCycle is attached */
  reviewees?: InputMaybe<Array<RevieweeInput>>;
  /** TagId of the Tag (userType) that is selected as a reviewer for the reviewees in the review cycle */
  reviewerTagIds?: InputMaybe<Array<Scalars['String']>>;
  /** Types of Reviewers allowed to give the review */
  reviewerTypes: Array<ReviewTypeEnum>;
  /** When will the review cycle start */
  startDate: Scalars['String'];
  /** ReviewCycle Name or Title */
  title: Scalars['String'];
};

export type CreateRoleInput = {
  name: Scalars['String'];
  permittedOn?: InputMaybe<Array<RolePermittedOnInput>>;
};

export type CreateScorecardInput = {
  questions: Array<ScorecardQuestionInput>;
  skillId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateScorecardPayload = {
  __typename?: 'CreateScorecardPayload';
  /** Error code */
  errorCode?: Maybe<Scalars['String']>;
  scorecard?: Maybe<ScorecardType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateScormInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Storing media of article-cover */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the external content */
  duration?: InputMaybe<Scalars['Float']>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  objective?: InputMaybe<Scalars['String']>;
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']>;
  scormProperties?: InputMaybe<ScormPropertiesInput>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type CreateSelfLearningContentInput = {
  /** Url of the external content */
  contentUrl?: InputMaybe<Scalars['String']>;
  /** Cover image of the external content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the external content */
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the external content */
  duration?: InputMaybe<Scalars['Float']>;
  /** External content type */
  externalContentType?: InputMaybe<ExternalContentTypeEnum>;
  /** Title of the external content */
  name?: InputMaybe<Scalars['String']>;
  /** Objective of the external content */
  objective?: InputMaybe<Scalars['String']>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateSkillGroupInOrgRoleInput = {
  /** name of the skill group */
  skillGroupName: Scalars['String'];
};

export type CreateSkillGroupInTrackInput = {
  /** name of the skill group */
  skillGroupName: Scalars['String'];
};

export type CreateSkillInOrgRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  expectedScore: Scalars['Float'];
  iconSettings?: InputMaybe<IconSettingsInput>;
  importedFromId?: InputMaybe<Scalars['String']>;
  maxScore?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  rubrics: Array<RubricInput>;
  skillGroupId: Scalars['String'];
  type?: InputMaybe<SkillTypeEnum>;
  visibleInListing?: InputMaybe<Scalars['Boolean']>;
};

export type CreateSkillInTrackInput = {
  description?: InputMaybe<Scalars['String']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  importedFromId?: InputMaybe<Scalars['String']>;
  maxScore?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  rubrics: Array<RubricInput>;
  /** skillGroupId to which given skill should be added */
  skillGroupId: Scalars['String'];
  type?: InputMaybe<SkillTypeEnum>;
  visibleInListing?: InputMaybe<Scalars['Boolean']>;
};

/** input for creating a new Skill */
export type CreateSkillInput = {
  description?: InputMaybe<Scalars['String']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  importedFromId?: InputMaybe<Scalars['String']>;
  maxScore?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  rubrics: Array<RubricInput>;
  type?: InputMaybe<SkillTypeEnum>;
  visibleInListing?: InputMaybe<Scalars['Boolean']>;
};

/** Input props rquired for creating a skill */
export type CreateSkillInputPropsType = {
  __typename?: 'CreateSkillInputPropsType';
  /** Skill description */
  description?: Maybe<Scalars['String']>;
  iconSettings?: Maybe<IconSettings>;
  /** Skill Name or Title */
  name: Scalars['String'];
  /** Denotes the rules for scoring this skill */
  rubrics: Array<Rubric>;
  type: SkillTypeEnum;
};

export type CreateSkillMatrixInput = {
  iconSettings: IconSettingsInput;
  name: Scalars['String'];
};

/** Input props rquired for creating a skillMatrix */
export type CreateSkillMatrixInputPropsType = {
  __typename?: 'CreateSkillMatrixInputPropsType';
  iconSettings?: Maybe<IconSettings>;
  name: Scalars['String'];
  skillGroups: Array<SkillGroupType>;
};

/** Inputs required to create channel for a squad route */
export type CreateSquadChannelInput = {
  /** Name of the channel, use the squad url */
  name: Scalars['String'];
};

export type CreateStaffRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  entityPermissionKeys: Array<Scalars['String']>;
  name: Scalars['String'];
  tagColor?: InputMaybe<Scalars['String']>;
};

export type CreateSubAcademyInput = {
  academyTemplateIds?: InputMaybe<Array<Scalars['String']>>;
  clientAdminEmailId?: InputMaybe<Scalars['String']>;
  createCommunity: Scalars['Boolean'];
  department?: InputMaybe<TenantDepartmentConfigInput>;
  excludeLearnerEmailPatterns?: InputMaybe<Array<Scalars['String']>>;
  hosts: Array<Scalars['String']>;
  iconURL: Scalars['String'];
  logoURL: Scalars['String'];
  maxLearnersCount?: InputMaybe<Scalars['Float']>;
  orgRole?: InputMaybe<TenantOrgRoleConfigInput>;
  serviceAdminEmailId?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<TenantStoreConfigInput>;
  subDomain: Scalars['String'];
  tenantName: Scalars['String'];
  workspaceName: Scalars['String'];
};

export type CreateSubAcademyPayload = {
  __typename?: 'CreateSubAcademyPayload';
  workspaceId: Scalars['String'];
};

export type CreateSubWorkspacePayload = {
  __typename?: 'CreateSubWorkspacePayload';
  subWorkspace?: Maybe<PublicWorkspaceType>;
};

export type CreateSurveyInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Cover image for survey */
  cover?: InputMaybe<CreateMediaInput>;
  /** Cover offset */
  coverOffset?: InputMaybe<Scalars['Float']>;
  /** Description of the survey */
  description?: InputMaybe<Scalars['String']>;
  /** Duration in seconds */
  duration: Scalars['Float'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  /** Description of the survey */
  name: Scalars['String'];
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']>;
  /** Skill Ids */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  /** Publishing status of survey */
  status: ResourceStatusEnum;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  /** Tags related to quiz */
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings: VisibilitySettingsInput;
};

/** Create Survey Payload */
export type CreateSurveyPayload = {
  __typename?: 'CreateSurveyPayload';
  survey?: Maybe<SurveyContentType>;
};

export type CreateTagInput = {
  availableOn: Array<TagAvailableOnEnum>;
  category?: InputMaybe<TagCategoryEnum>;
  dateTagProps?: InputMaybe<DateTagPropsInput>;
  description?: InputMaybe<Scalars['String']>;
  multiSelectTagProps?: InputMaybe<MultiSelectTagPropsInput>;
  name: Scalars['String'];
  numberTagProps?: InputMaybe<NumberTagPropsInput>;
  settings: TagSettingsInput;
  singleSelectTagProps?: InputMaybe<SingleSelectTagPropsInput>;
  source?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<TagSubTypeEnum>;
  subworkspaceIds?: InputMaybe<EntitySubworkspacesInput>;
  textTagProps?: InputMaybe<TextTagPropsInput>;
  type: TagTypeEnum;
};

export type CreateTaskCreationConfigInput = {
  /** Type of config, manual or auto */
  externalEntityType: ActionItemExternalEntityTypeEnum;
  /** provider with which this sync is created */
  providerId: Scalars['String'];
  /** Searchtext, related query for the sync */
  searchText?: InputMaybe<Scalars['String']>;
  /** Type of config, manual or auto */
  taskCreationType: TaskCreationConfigTypeEnum;
};

export type CreateTaskInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Storing media of task-cover */
  cover?: InputMaybe<CreateMediaInput>;
  duration: Scalars['Float'];
  instruction?: InputMaybe<TaskInstructionInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** Letting backend API know, that we need to publish the content also */
  publish?: InputMaybe<Scalars['Boolean']>;
  subTasks?: InputMaybe<Array<SubTaskInput>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
};

export type CreateTaskPayload = {
  __typename?: 'CreateTaskPayload';
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
  task?: Maybe<TaskContentType>;
};

export type CreateTrackInput = {
  /** departmentId in which the track belongs */
  departmentId: Scalars['String'];
  /** description of the track */
  description?: InputMaybe<Scalars['String']>;
  /** name of the track */
  name: Scalars['String'];
};

/** Input props rquired for creating a track */
export type CreateTrackInputPropsType = {
  __typename?: 'CreateTrackInputPropsType';
  /** DepartmentId in which the track belongs */
  departmentId: Scalars['String'];
  /** Description of the track */
  description?: Maybe<Scalars['String']>;
  /** metric attached to track */
  metricIds?: Maybe<Array<Scalars['String']>>;
  /** mindsets attached to track */
  mindsetIds?: Maybe<Array<Scalars['String']>>;
  /** Name of the track */
  name: Scalars['String'];
  /** skillMatrix attached to track */
  skillMatrixId: Scalars['String'];
};

export type CreateTrackPayload = {
  __typename?: 'CreateTrackPayload';
  track: TrackType;
};

/** Mutation to create a user activity */
export type CreateUserActivityInput = {
  /** Category to which this activity belongs */
  activityCategory: UserActivityCategoryEnum;
  activityTimestamp: Scalars['String'];
  /** Type of activity */
  activityType: UserActivityTypeEnum;
  /** Nearest level entity on which this activity occurred */
  entityId: Scalars['String'];
  /** Entity type of the nearest level entity on which this activity occurred */
  entityType: UserActivityEntityTypeEnum;
  /** Parent entity's id of the entityId field */
  parentEntityId: Scalars['String'];
  /** Parent entity type of the entityId field */
  parentEntityType: UserActivityParentEntityTypeEnum;
  /** Root entity's id of the entity chain */
  rootEntityId: Scalars['String'];
  /** Root entity's Type of the entity chain */
  rootEntityType: UserActivityRootEntityTypeEnum;
  /** Actor for which this activity is recorded against */
  userId: Scalars['String'];
};

/** Create UserActivity Response */
export type CreateUserActivityPayload = {
  __typename?: 'CreateUserActivityPayload';
  errorCode?: Maybe<UserActivityErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
  userActivity?: Maybe<UserActivityType>;
};

export type CreateUserCollectionGroupInput = {
  classroomId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  memberIds?: InputMaybe<GroupMembersIds>;
  name: Scalars['String'];
  productId?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<UserCollectionGroupTypeEnum>;
  systemGenerated?: InputMaybe<Scalars['Boolean']>;
};

export type CreateUserInput = {
  alias?: InputMaybe<Scalars['String']>;
  emailId?: InputMaybe<Scalars['String']>;
  federationId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  hierarchyPath?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<UserPropertiesInput>;
  signupType: UserSignupTypeEnum;
  status: UserStatusEnum;
  tags?: InputMaybe<EntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type CreateUserSkillSummaryInput = {
  /** Aggregate of scores from different sources */
  overallScore: SkillScoreInput;
  /** calculated Scores */
  scores: Array<SkillScoreInput>;
  /** skill on which the summary is created */
  skillId: Scalars['String'];
  /** userId for whom the summary is created */
  userId: Scalars['String'];
};

export type CreateUserSkillSummaryPayload = {
  __typename?: 'CreateUserSkillSummaryPayload';
  success?: Maybe<Scalars['Boolean']>;
  userSkillSummary?: Maybe<UserSkillSummary>;
};

export enum CronFrequencyEnum {
  Custom = 'CUSTOM',
  Daily = 'DAILY',
  Fortnightly = 'FORTNIGHTLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY',
}

export type CurrentEventsWidgetType = {
  __typename?: 'CurrentEventsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  events: Array<EventUnion>;
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CurrentMeetingInstancesWidgetType = {
  __typename?: 'CurrentMeetingInstancesWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  meetingInstances: Array<MeetingInstanceType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CurrentMeetingsWidgetType = {
  __typename?: 'CurrentMeetingsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  meetings: Array<MeetingType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Current reviewing state of content */
export type CurrentReviewingState = {
  __typename?: 'CurrentReviewingState';
  isCurrentUserReviewing: Scalars['Boolean'];
  reviewBy: UserType;
  reviewedById: Scalars['String'];
  status: PerformanceStatusEnum;
};

export type CustomFrequency = {
  __typename?: 'CustomFrequency';
  dayOfMonth?: Maybe<Scalars['Float']>;
  daysOfWeek?: Maybe<Array<DayOfWeekEnum>>;
  frequency: CustomFrequencyEnum;
  interval: Scalars['Float'];
  monthOfYear?: Maybe<MonthOfYearEnum>;
  weekOfMonth?: Maybe<WeekOfMonthEnum>;
  weeksOfMonth?: Maybe<Array<WeekOfMonthEnum>>;
};

export enum CustomFrequencyEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type CustomFrequencyInput = {
  dayOfMonth?: InputMaybe<Scalars['Float']>;
  daysOfWeek?: InputMaybe<Array<DayOfWeekEnum>>;
  frequency: CustomFrequencyEnum;
  interval: Scalars['Float'];
  monthOfYear?: InputMaybe<MonthOfYearEnum>;
  weekOfMonth?: InputMaybe<WeekOfMonthEnum>;
  weeksOfMonth?: InputMaybe<Array<WeekOfMonthEnum>>;
};

export type CustomGradingSchemaInput = {
  relationalOperator: Scalars['String'];
  symbolGrade: Scalars['String'];
  value: Scalars['Float'];
};

/** Custom grading schema type */
export type CustomLetterGradingSchemaType = {
  __typename?: 'CustomLetterGradingSchemaType';
  relationalOperator: Scalars['String'];
  symbolGrade: Scalars['String'];
  value: Scalars['Float'];
};

export enum CustomStatusEntityTypeEnum {
  File = 'FILE',
}

/** OKR permission schema */
export type CycleOkrPermission = {
  __typename?: 'CycleOKRPermission';
  /** action to be performed */
  action: OkrActions;
  /** store who can act on subject */
  permission: VisibilitySettingsType;
  /** subject on which permission action is to be performed */
  subject: OkrPermissionSubjects;
};

export enum CycleReviewStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
}

/** CycleReviewType Schema */
export type CycleReviewType = {
  __typename?: 'CycleReviewType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** review cycle instance */
  reviewCycleId: Scalars['String'];
  /** reviewType */
  reviewType: Scalars['String'];
  /** user who is reviewing */
  reviewerId: Scalars['String'];
  /** overall score in a cycle */
  score?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: CycleReviewStatusEnum;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user who is getting reviewed */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type CycleReviewsFilters = {
  reviewCycleIds?: InputMaybe<Array<Scalars['String']>>;
  reviewType?: InputMaybe<Array<Scalars['String']>>;
  reviewerIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<CycleReviewStatusEnum>>;
  type?: InputMaybe<Array<PerformanceInteractionTypeEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Cycle Reviews */
export type CycleReviewsPayload = {
  __typename?: 'CycleReviewsPayload';
  cycleReviews: Array<CycleReviewType>;
};

export type DbViewId = {
  __typename?: 'DBViewId';
  /** Database View Type */
  type: ViewTypeEnum;
  /** Database View Id */
  viewId: Scalars['String'];
};

/** DatabaseData Edge */
export type DatabaseData = {
  __typename?: 'DatabaseData';
  node: DatabaseDataType;
};

/** This returns paginated DatabaseData */
export type DatabaseDataConnection = {
  __typename?: 'DatabaseDataConnection';
  edges?: Maybe<Array<DatabaseData>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Defines database data field filters for fetching multiple documents */
export type DatabaseDataFilters = {
  dbId: Scalars['String'];
  dbMetadataId?: InputMaybe<Scalars['String']>;
  /** Id of database document */
  documentId?: InputMaybe<Scalars['String']>;
  /** Field filters on database */
  fields?: InputMaybe<Array<DatabaseFieldFilters>>;
};

/** Database Data subscription schema  */
export type DatabaseDataSubscriptionType = {
  __typename?: 'DatabaseDataSubscriptionType';
  /** Affected Database Data */
  data?: Maybe<DatabaseDataType>;
  /** Affected Document Id */
  documentId?: Maybe<Scalars['String']>;
  /** Operation Type */
  operation: OperationEnum;
  /** Entity Type */
  type: DatabaseEntitiesEnum;
};

/** Database data */
export type DatabaseDataType = {
  __typename?: 'DatabaseDataType';
  createdBy?: Maybe<UserType>;
  /** Database data */
  data: Scalars['JSONObject'];
  id: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
};

/** Defines database data filters for fetching multiple documents */
export type DatabaseDatasFilters = {
  dbId: Scalars['String'];
  dbMetadataId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<QueryInput>;
  viewId?: InputMaybe<Scalars['String']>;
};

export enum DatabaseEntitiesEnum {
  Data = 'DATA',
  Metadata = 'METADATA',
  View = 'VIEW',
}

/** Defines filters on database fields */
export type DatabaseFieldFilters = {
  fieldId: Scalars['String'];
  value: Scalars['String'];
};

export enum DatabaseFieldTypeEnum {
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  Email = 'EMAIL',
  FilesAndMedias = 'FILES_AND_MEDIAS',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  Person = 'PERSON',
  PhoneNumber = 'PHONE_NUMBER',
  Rating = 'RATING',
  Relation = 'RELATION',
  Select = 'SELECT',
  Text = 'TEXT',
  Url = 'URL',
}

/** Defines fields of database */
export type DatabaseMetaFieldsInput = {
  fieldId: Scalars['String'];
  name: Scalars['String'];
  type: DatabaseFieldTypeEnum;
};

/** This returns paginated database metadata */
export type DatabaseMetadataConnection = {
  __typename?: 'DatabaseMetadataConnection';
  edges?: Maybe<Array<DatabaseMetadataEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type DatabaseMetadataEdge = {
  __typename?: 'DatabaseMetadataEdge';
  node: DatabaseMetadataType;
};

/** Database Metadata Fields Schema */
export type DatabaseMetadataFieldType = {
  __typename?: 'DatabaseMetadataFieldType';
  /** Defines if multiple selections are allowed */
  allowMultipleSelection?: Maybe<Scalars['Boolean']>;
  /** Collection id */
  collectionId?: Maybe<Scalars['String']>;
  /** Defines collection format */
  collectionType?: Maybe<DatabaseRelationCollectionTypeEnum>;
  /** Defines date format */
  dateFormat?: Maybe<Scalars['String']>;
  /** Field id */
  fieldId: Scalars['String'];
  /** Field Name */
  name: Scalars['String'];
  /** Defines number format */
  numberFormat?: Maybe<DatabaseNumberFormatEnum>;
  /** Select/Multi-select options */
  options?: Maybe<Array<SelectFieldOptionsType>>;
  /** Defines rating type */
  ratingType?: Maybe<DatabaseRatingTypeEnum>;
  /** Type of field */
  type: DatabaseFieldTypeEnum;
  /** Validation required on the field */
  validation?: Maybe<ValidationType>;
};

/** Defines database metadata filters for fetching a single document */
export type DatabaseMetadataFilters = {
  dbId?: InputMaybe<Scalars['String']>;
  dbMetadataId?: InputMaybe<Scalars['String']>;
};

/** Database Metadata subscription schema  */
export type DatabaseMetadataSubscriptionType = {
  __typename?: 'DatabaseMetadataSubscriptionType';
  /** Database metadata */
  dbMetadata?: Maybe<DatabaseMetadataType>;
  /** operation type */
  operation: OperationEnum;
};

/** Database Metadata Schema */
export type DatabaseMetadataType = {
  __typename?: 'DatabaseMetadataType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Default viewId of the database */
  dbDefaultViewId: Scalars['String'];
  /** Database Id */
  dbId: Scalars['String'];
  /** Database Name */
  dbName: Scalars['String'];
  /** Database Description */
  description: Scalars['String'];
  /** Database fields */
  fields: Array<DatabaseMetadataFieldType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  /** This resolver returns id, name and viewType of all the views corresponding to the metadata */
  views?: Maybe<Array<DisplayDatabaseViewType>>;
};

/** Defines database metadata filters for fetching multiple documents */
export type DatabaseMetadatasFilters = {
  dbIds?: InputMaybe<Array<Scalars['String']>>;
  dbMetadataIds?: InputMaybe<Array<Scalars['String']>>;
  /** Search text for searching database metadata */
  searchText?: InputMaybe<Scalars['String']>;
};

export enum DatabaseNumberFormatEnum {
  Dollar = 'DOLLAR',
  Euro = 'EURO',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Pound = 'POUND',
  Rupee = 'RUPEE',
  Yen = 'YEN',
}

export enum DatabaseRatingTypeEnum {
  Number = 'NUMBER',
  Star = 'STAR',
}

export enum DatabaseRelationCollectionTypeEnum {
  Agenda = 'AGENDA',
  Checkin = 'CHECKIN',
  Database = 'DATABASE',
  User = 'USER',
}

/** Database Row subscription schema  */
export type DatabaseRowSubscriptionType = {
  __typename?: 'DatabaseRowSubscriptionType';
  /** Updated Database Data */
  data?: Maybe<DatabaseDataType>;
  /** Affected Document Id */
  documentId?: Maybe<Scalars['String']>;
  /** Operation Type */
  operation: OperationEnum;
};

/** Database subscription schema  */
export type DatabaseSubscriptionType = {
  __typename?: 'DatabaseSubscriptionType';
  /** Operation Type */
  operation: OperationEnum;
  /** Entity Type */
  type: DatabaseEntitiesEnum;
};

export enum DatabaseTextFormatEnum {
  MultiLine = 'MULTI_LINE',
  SingleLine = 'SINGLE_LINE',
}

/** The database type */
export type DatabaseType = {
  __typename?: 'DatabaseType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  dbId: Scalars['String'];
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** This returns paginated database views */
export type DatabaseViewConnection = {
  __typename?: 'DatabaseViewConnection';
  edges?: Maybe<Array<DatabaseViewEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type DatabaseViewEdge = {
  __typename?: 'DatabaseViewEdge';
  node: DatabaseViewType;
};

/** Database Fields */
export type DatabaseViewFieldType = {
  __typename?: 'DatabaseViewFieldType';
  /** Defines if user is able to create custom tags */
  allowCustomOptions?: Maybe<Scalars['Boolean']>;
  /** Defines if character limit is enabled */
  charLimitEnabled?: Maybe<Scalars['Boolean']>;
  /** Name of the field */
  description?: Maybe<Scalars['String']>;
  /** Defines if description is enabled */
  descriptionEnabled?: Maybe<Scalars['Boolean']>;
  /** Short Id of the field */
  fieldId: Scalars['String'];
  /** Defines if end date is included */
  includeEndDate?: Maybe<Scalars['Boolean']>;
  /** Defines if time is included */
  includeTime?: Maybe<Scalars['Boolean']>;
  /** Name of the field */
  label?: Maybe<Scalars['String']>;
  /** Max label for rating type input */
  maxLabel?: Maybe<Scalars['String']>;
  /** Min label for rating type input */
  minLabel?: Maybe<Scalars['String']>;
  /** Placeholder for text input */
  placeholder?: Maybe<Scalars['String']>;
  /** Field Required in input */
  required: Scalars['Boolean'];
  /** Defines if user is able to create custom tags */
  textType?: Maybe<DatabaseTextFormatEnum>;
  /** Field visibility */
  visible: Scalars['Boolean'];
  /** Width of the column */
  width: Scalars['Float'];
};

/** Defines database view filters for fetching a single view */
export type DatabaseViewFilters = {
  viewId: Scalars['String'];
};

/** Defines database view fields */
export type DatabaseViewFormat = {
  fields: Array<DatabaseViewFormatFields>;
};

/** Defines database view format fields */
export type DatabaseViewFormatFields = {
  allowCustomOptions?: InputMaybe<Scalars['Boolean']>;
  /** Defines if character limit is enabled */
  charLimitEnabled?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  /** Defines if description is enabled */
  descriptionEnabled?: InputMaybe<Scalars['Boolean']>;
  fieldId: Scalars['String'];
  /** Defines if end date is included */
  includeEndDate?: InputMaybe<Scalars['Boolean']>;
  /** Defines if time is included */
  includeTime?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  /** Max label for rating type input */
  maxLabel?: InputMaybe<Scalars['String']>;
  /** Min label for rating type input */
  minLabel?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
  required: Scalars['Boolean'];
  textType?: InputMaybe<DatabaseTextFormatEnum>;
  visible: Scalars['Boolean'];
  width: Scalars['Float'];
};

/** Defines what fields are present and how fields should be displayed in a Database View */
export type DatabaseViewFormatType = {
  __typename?: 'DatabaseViewFormatType';
  /** Fields of the database view */
  fields: Array<DatabaseViewFieldType>;
};

/** Database View subscription schema  */
export type DatabaseViewSubscriptionType = {
  __typename?: 'DatabaseViewSubscriptionType';
  /** operation type */
  operation: OperationEnum;
  /** Database View */
  view?: Maybe<DatabaseViewType>;
};

/** Database View Schema */
export type DatabaseViewType = {
  __typename?: 'DatabaseViewType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Database Id */
  dbId: Scalars['String'];
  /** Database Metadata Id */
  dbMetadataId: Scalars['String'];
  /** Format of the database view */
  format: DatabaseViewFormatType;
  id: Scalars['String'];
  /** Database View Name */
  name: Scalars['String'];
  /** Query on  */
  query?: Maybe<QueryType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  /** Tells about the view type of the database */
  viewType: ViewTypeEnum;
};

/** Defines database view filters for fetching multiple views */
export type DatabaseViewsFilters = {
  dbId?: InputMaybe<Scalars['String']>;
  dbMetadataId?: InputMaybe<Scalars['String']>;
  viewIds?: InputMaybe<Array<Scalars['String']>>;
};

export type DateFilter = {
  absolute?: InputMaybe<AbsoluteDateFilter>;
  relative?: InputMaybe<RelativeDateFilter>;
  timezone: Scalars['String'];
};

export type DateFilterType = {
  __typename?: 'DateFilterType';
  absolute?: Maybe<AbsoluteDateFilterType>;
  relative?: Maybe<RelativeDateFilterType>;
  timezone: Scalars['String'];
};

/** Metadata for date fields */
export type DateMetadataInput = {
  dateFormat?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  timeFormat?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
};

export type DateTagPropsInput = {
  includeEndDate: Scalars['Boolean'];
  includeTime: Scalars['Boolean'];
};

export type DateTagType = {
  __typename?: 'DateTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** number type tag specific fields */
  includeEndDate: Scalars['Boolean'];
  includeTime: Scalars['Boolean'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  /** field resolvers */
  taggedContentCount: Scalars['Float'];
  taggedEventCount: Scalars['Float'];
  taggedOKRCount: Scalars['Float'];
  taggedTaskCount: Scalars['Float'];
  taggedUserCount: Scalars['Float'];
  type: TagTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type DateTagTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type DateTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export type DateValueInput = {
  endTime?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type DateValueType = {
  __typename?: 'DateValueType';
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

export enum DateValueTypeEnum {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC',
}

export enum DayOfWeekEnum {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

/** Defines metadata field options */
export type DbMetadataFieldOptions = {
  color: Scalars['String'];
  optionId: Scalars['String'];
  value: Scalars['String'];
};

/** Default Enrolment Settings Type */
export type DefaultEnrolmentSettingsType = {
  __typename?: 'DefaultEnrolmentSettingsType';
  /** Number of days to complete the path */
  dueDays: Scalars['Float'];
};

export enum DefaultTemplateEntityEnum {
  Department = 'DEPARTMENT',
  Metric = 'METRIC',
  OrgRole = 'ORG_ROLE',
  Skill = 'SKILL',
  SkillMatrix = 'SKILL_MATRIX',
  Track = 'TRACK',
}

/** Documents containing data for creation of default entities */
export type DefaultTemplateType = {
  __typename?: 'DefaultTemplateType';
  /** Configs required to create a department */
  createDepartmentConfig?: Maybe<CreateDepartmentInputPropsType>;
  /** Configs required to create a metric */
  createMetricConfig?: Maybe<CreateMetricConfigsInputPropsType>;
  /** Configs required to create an orgRole */
  createOrgRoleConfig?: Maybe<CreateOrgRoleInputPropsType>;
  /** Configs required to create a skill/mindset */
  createSkillConfig?: Maybe<CreateSkillInputPropsType>;
  /** Configs required to create a skillMatrix */
  createSkillMatrixConfig?: Maybe<CreateSkillMatrixInputPropsType>;
  /** Configs required to create a track */
  createTrackConfig?: Maybe<CreateTrackInputPropsType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Flows for which this template can be used */
  supportedFlows: Array<SupportedFlowsEnum>;
  trackTemplates: Array<TrackTemplatesProfile>;
  /** Entity type of template */
  type: DefaultTemplateEntityEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Filters to fetch default templates */
export type DefaultTemplatesFilters = {
  /** Returns templates with creationConfigs referencing the given departmentIds */
  departmentIds?: InputMaybe<Array<Scalars['String']>>;
  /** Flows for which the template is supported */
  supportedFlows?: InputMaybe<Array<SupportedFlowsEnum>>;
  /** ids of templates to be fetched */
  templateIds?: InputMaybe<Array<Scalars['String']>>;
  /** Entity type of template */
  types?: InputMaybe<Array<DefaultTemplateEntityEnum>>;
};

/** Default Templates Payload */
export type DefaultTemplatesPayload = {
  __typename?: 'DefaultTemplatesPayload';
  defaultTemplates: Array<DefaultTemplateType>;
};

export type DeleteActionItemInput = {
  actionItemId: Scalars['String'];
};

export type DeleteAgendaItemInput = {
  agendaItemId: Scalars['String'];
};

export type DeleteAgendaItemTemplateInput = {
  agendaItemTemplateId: Scalars['String'];
};

export type DeleteAssignmentInput = {
  assignmentId: Scalars['String'];
};

export type DeleteAssignmentPayload = {
  __typename?: 'DeleteAssignmentPayload';
  assignment: AssignmentType;
};

export type DeleteBookmarkInput = {
  /** id of the Bookmark that needs to be deleted */
  bookmarkId: Scalars['String'];
};

export type DeleteBookmarkPayload = {
  __typename?: 'DeleteBookmarkPayload';
  /** deleted bookmark */
  bookmark?: Maybe<BookmarkType>;
};

export type DeleteClassroomEventInput = {
  eventId: Scalars['String'];
};

/** Delete event response */
export type DeleteClassroomEventPayload = {
  __typename?: 'DeleteClassroomEventPayload';
  errorCode?: Maybe<ClassroomEventErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteContentInput = {
  contentId: Scalars['String'];
};

export type DeleteContentPayload = {
  __typename?: 'DeleteContentPayload';
  /** ErrorCode */
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** input for deleting a department */
export type DeleteDepartmentInput = {
  id: Scalars['String'];
};

export type DeleteEmailDigestInput = {
  emailDigestId: Scalars['String'];
};

export type DeleteEventScheduleInput = {
  eventScheduleId: Scalars['String'];
};

/** Delete eventSchedule mutation response */
export type DeleteEventSchedulePayload = {
  __typename?: 'DeleteEventSchedulePayload';
  errorCode?: Maybe<EventScheduleErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteFileInput = {
  fileId: Scalars['String'];
};

export type DeleteMeetingInput = {
  meetingId: Scalars['String'];
};

export type DeleteMeetingInstancesInput = {
  filters: MeetingInstancesFilters;
};

export type DeleteMetricInput = {
  metricId: Scalars['String'];
};

/** Delete Cycle Mutation Input */
export type DeleteOkrCycleInput = {
  /** Id of the cycle which needs to be deleted */
  cycleId: Scalars['String'];
};

/** Delete OKR Mutation Input */
export type DeleteOkrInput = {
  /** Id of the okr which needs to be deleted */
  okrId: Scalars['String'];
  /** Type of OKR to be deleted */
  type: OkrTypeEnum;
};

export type DeleteOkrPayload = {
  __typename?: 'DeleteOKRPayload';
  parentOKR?: Maybe<OkrUnion>;
  parentOKRs?: Maybe<Array<OkrUnion>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteOrgRoleInput = {
  orgRoleId: Scalars['String'];
};

export type DeletePeerFeedbackInput = {
  peerFeedbackId: Scalars['String'];
};

export enum DeletePostErrorCodeEnum {
  ForbiddenError = 'FORBIDDEN_ERROR',
  PostNotDeleted = 'POST_NOT_DELETED',
  PostNotFound = 'POST_NOT_FOUND',
}

/** Input for deleting a post */
export type DeletePostInput = {
  /** ChannelId of the post that needs to be deleted */
  channelId: Scalars['String'];
  /** PostId to be deleted */
  postId: Scalars['String'];
};

export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  errorCode?: Maybe<DeletePostErrorCodeEnum>;
  post?: Maybe<PostType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePraiseInput = {
  praiseId: Scalars['String'];
};

export type DeleteReportInput = {
  reportId: Scalars['String'];
};

export type DeleteResourceInput = {
  kind: ResourceKindEnum;
  path?: InputMaybe<Scalars['String']>;
  resourceId: Scalars['String'];
};

export type DeleteReviewCycleInput = {
  reviewCycleId: Scalars['String'];
};

export type DeleteReviewCyclePayload = {
  __typename?: 'DeleteReviewCyclePayload';
  reviewCycleId: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Job to be deleted */
export type DeleteScheduledJobInput = {
  /** ID of job to be deleted */
  scheduledJobId: Scalars['String'];
};

export type DeleteScheduledJobPayload = {
  __typename?: 'DeleteScheduledJobPayload';
  success: Scalars['Boolean'];
};

export type DeleteSkillInput = {
  skillId: Scalars['String'];
};

export type DeleteSkillMatrixInput = {
  skillMatrixId: Scalars['String'];
};

export type DeleteSkillMatrixPayload = {
  __typename?: 'DeleteSkillMatrixPayload';
  skillMatrixId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeleteSkillPayload = {
  __typename?: 'DeleteSkillPayload';
  skillId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DeleteSkillReviewInput = {
  reviewCycleId: Scalars['String'];
  reviewTypes: Array<Scalars['String']>;
  reviewerId: Scalars['String'];
  userId: Scalars['String'];
};

export type DeleteSkillReviewsInput = {
  skillIds: Array<Scalars['String']>;
};

export type DeleteStaffRoleInput = {
  roleId: Scalars['String'];
};

export type DeleteTrackInput = {
  /** track id to delete */
  trackId: Scalars['String'];
};

export type DeleteTracksInput = {
  filters: TracksFilters;
};

export type DeleteUserActivityMetricsInput = {
  userActivityMetricIds: Array<Scalars['String']>;
};

export type DeleteUserSearchActivityInput = {
  /** id of the userSearchActivity that needs to be deleted */
  userSearchActivityId: Scalars['String'];
};

export type DeleteUserViewInput = {
  userViewId: Scalars['String'];
};

export type DeleteWidgetsInput = {
  widgetIds: Array<Scalars['String']>;
};

export enum DeletedFilterEnum {
  Both = 'BOTH',
  Deleted = 'DELETED',
  NonDeleted = 'NON_DELETED',
}

/** This returns paginated Department */
export type DepartmentConnections = {
  __typename?: 'DepartmentConnections';
  edges?: Maybe<Array<DepartmentEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type DepartmentEdge = {
  __typename?: 'DepartmentEdge';
  node: DepartmentType;
};

export type DepartmentFilters = {
  id: Scalars['String'];
};

export type DepartmentOrgRoleFilter = {
  departmentId?: InputMaybe<Scalars['String']>;
  orgRoleId?: InputMaybe<Scalars['String']>;
};

export type DepartmentOrgRoleFilterType = {
  __typename?: 'DepartmentOrgRoleFilterType';
  departmentId?: Maybe<Scalars['String']>;
  orgRoleId?: Maybe<Scalars['String']>;
};

export enum DepartmentStatusEnum {
  Active = 'ACTIVE',
  Archive = 'ARCHIVE',
}

/** Payload for track templates of department templates */
export type DepartmentTemplatesProfile = {
  __typename?: 'DepartmentTemplatesProfile';
  trackTemplates: Array<TrackTemplatesProfile>;
};

/** Department in a corporate tenant */
export type DepartmentType = {
  __typename?: 'DepartmentType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  /** Description of the department */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Indicates if department is deleted */
  isDeleted: Scalars['Boolean'];
  /** Name of the department */
  name: Scalars['String'];
  orgRoleCount?: Maybe<Scalars['Float']>;
  orgRoles?: Maybe<Array<OrgRoleType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status of the department */
  status: DepartmentStatusEnum;
  trackCount?: Maybe<Scalars['Float']>;
  tracks?: Maybe<Array<TrackType>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userCount?: Maybe<Scalars['Float']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type DepartmentsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** name of the department */
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<DepartmentStatusEnum>>;
};

/** Media Types */
export type DeprecatedMediaType = {
  __typename?: 'DeprecatedMediaType';
  /** Blured initial image */
  blurHash?: Maybe<Scalars['String']>;
  resolutions: Array<DeprecatedResolutionType>;
  /** Media type */
  type: MediaTypeEnum;
  /** Media URL */
  url: Scalars['String'];
};

export enum DeprecatedResolutionEnum {
  L = 'L',
  S = 'S',
  Thumb = 'THUMB',
}

/** Deprecated Multiple Resolution Type */
export type DeprecatedResolutionType = {
  __typename?: 'DeprecatedResolutionType';
  type: DeprecatedResolutionEnum;
  url: Scalars['String'];
};

export enum DifficultyEnum {
  Easy = 'EASY',
  Hard = 'HARD',
  Medium = 'MEDIUM',
  Na = 'NA',
}

export type DifficultyWeightConfigType = {
  __typename?: 'DifficultyWeightConfigType';
  difficultyWeights: Array<DifficultyWeightType>;
};

export type DifficultyWeightType = {
  __typename?: 'DifficultyWeightType';
  difficulty: DifficultyEnum;
  weight?: Maybe<Scalars['Float']>;
};

export type DimensionConfigType = {
  __typename?: 'DimensionConfigType';
  dependentFilters?: Maybe<FilterGroupType>;
  dimension: Scalars['String'];
  dimensionDateFilter?: Maybe<Scalars['String']>;
  innerDimensionSize?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  sortOrder?: Maybe<SortTypeSchema>;
};

export type DimensionConfigTypeInput = {
  dependentFilters?: InputMaybe<FilterGroupInput>;
  dimension: Scalars['String'];
  dimensionDateFilter?: InputMaybe<Scalars['String']>;
  innerDimensionSize?: InputMaybe<Scalars['Float']>;
  size?: InputMaybe<Scalars['Float']>;
  sortOrder?: InputMaybe<SortType>;
};

export type DiscardUnpublishedChangesInput = {
  contentId: Scalars['String'];
};

/** Publish Course-Content Response */
export type DiscardUnpublishedChangesPayload = {
  __typename?: 'DiscardUnpublishedChangesPayload';
  content?: Maybe<ContentEntity>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DisconnectIntegrationDetailInput = {
  integrationProviderId: Scalars['String'];
  scope?: InputMaybe<IntegrationScopeEnum>;
  userId?: InputMaybe<Scalars['String']>;
};

/** Product discount parameters */
export type Discount = {
  /** Represents discounted amount */
  amount: Scalars['Float'];
  /** Discount Type. it can be percentage OR fixed. */
  type: DiscountTypeEnum;
};

/** Product discount parameters */
export type DiscountType = {
  __typename?: 'DiscountType';
  /** Represents discounted amount */
  amount: Scalars['Float'];
  /** Discount Type. it can be percentage OR fixed. */
  type: DiscountTypeEnum;
  /** Represents validity of the discount */
  validity?: Maybe<Scalars['String']>;
};

export enum DiscountTypeEnum {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE',
}

export enum DisplayComponentTypeEnum {
  ImageGrid = 'IMAGE_GRID',
  ListSection = 'LIST_SECTION',
  TextSection = 'TEXT_SECTION',
}

export type DisplayComponentUnion =
  | ImageGridDisplayComponentType
  | ListSectionDisplayComponentType
  | TextSectionDisplayComponentType;

/** Projected view fields used to display view */
export type DisplayDatabaseViewType = {
  __typename?: 'DisplayDatabaseViewType';
  id: Scalars['String'];
  /** Database View name */
  name: Scalars['String'];
  /** Tells about the view type of the database */
  viewType: ViewTypeEnum;
};

/** Represent pair data */
export type Distractor = {
  __typename?: 'Distractor';
  id: Scalars['String'];
  text: Scalars['String'];
};

/** Represent pair data */
export type DistractorInput = {
  id?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type DistractorsSetInput = {
  /** Array of distractors */
  set: Array<DistractorInput>;
};

/** Input for fields and corresponding data */
export type DocumentFieldsInput = {
  data: DocumentValuesInput;
  fieldId: Scalars['String'];
};

/** Data corresponding to a fieldId */
export type DocumentValuesInput = {
  metadata?: InputMaybe<DocumentValuesMetadataInput>;
  value: Scalars['String'];
};

/** Metadata for field values which require special information */
export type DocumentValuesMetadataInput = {
  /** metadata for date type field */
  d?: InputMaybe<DateMetadataInput>;
  /** metadata for media type field */
  m?: InputMaybe<Array<CreateMediaInput>>;
  /** metadata for relation type field */
  r?: InputMaybe<RelationMetadataInput>;
  /** metadata for user type field */
  u?: InputMaybe<Array<UserMetadataInput>>;
};

/** Draft Exercise Schema */
export type DraftExerciseType = {
  __typename?: 'DraftExerciseType';
  advanceSettings?: Maybe<HomeworkAdvanceSettingsType>;
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  completionReviewRequired?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Float']>;
  /** Questions for exercise. Learner has to submit answers for */
  exerciseQuestions: Array<ExerciseQuestionType>;
  /** Assignment Instructions in text or media */
  instruction?: Maybe<ExerciseInstructionType>;
  /** Draft name of the resource */
  name: Scalars['String'];
  objective?: Maybe<Scalars['String']>;
  reviewType: ResourceReviewTypeEnum;
  reviewerConfig?: Maybe<ReviewerConfig>;
  /** Reviewer Instructions in text or media */
  reviewerInstruction?: Maybe<ExerciseInstructionType>;
  /** Ids of Scorecard related to the exercise */
  scorecardIds?: Maybe<Array<Scalars['String']>>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<EntityTagType>>;
};

/** draft external content type */
export type DraftExternalContentType = {
  __typename?: 'DraftExternalContentType';
  categoryIds?: Maybe<Array<Scalars['String']>>;
  /** Draft External content URL */
  contentUrl?: Maybe<Scalars['String']>;
  cover?: Maybe<EntityMediaType>;
  /** Description of the draft external content */
  description?: Maybe<Scalars['String']>;
  /** Duration in seconds */
  duration?: Maybe<Scalars['Float']>;
  /** Type of the external content */
  externalContentType?: Maybe<ExternalContentTypeEnum>;
  marketplaceContentId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Objective of the draft external content */
  objective?: Maybe<Scalars['String']>;
  /** Id of the draft external content provider */
  providerId?: Maybe<Scalars['String']>;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Method to verify the completion of the draft external content */
  verificationMethod?: Maybe<VerificationMethodType>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

/** Draft Path Type */
export type DraftPathContentType = {
  __typename?: 'DraftPathContentType';
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  cover?: Maybe<EntityMediaType>;
  /** Stores the draft description of the path */
  description?: Maybe<Scalars['String']>;
  dripSettings: DripSettingsType;
  /** Path Draft Medias */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Draft name of the resource */
  name: Scalars['String'];
  /** Draft Objective of the path content */
  objective?: Maybe<Scalars['String']>;
  sections?: Maybe<Array<PathSectionType>>;
  /** skill ids */
  skillIds?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<EntityTagType>>;
  visibilitySettings: VisibilitySettingsType;
};

/** This is the Draft Question schema */
export type DraftQuestionType = {
  __typename?: 'DraftQuestionType';
  attachments?: Maybe<Array<EntityMediaType>>;
  correctAnswerFeedback?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  globalDistractors?: Maybe<Array<Distractor>>;
  incorrectAnswerFeedback?: Maybe<Scalars['String']>;
  mtfComponent?: Maybe<MtfComponent>;
  options?: Maybe<Array<Option>>;
  pairs?: Maybe<Array<Pair>>;
  questionText?: Maybe<Scalars['String']>;
  questionTextType?: Maybe<QuestionTextTypeEnum>;
  questionType?: Maybe<QuestionTypeEnum>;
  settings?: Maybe<QuestionSettings>;
};

/** Draft quiz Content */
export type DraftQuizContentType = {
  __typename?: 'DraftQuizContentType';
  settings?: Maybe<QuizSettings>;
};

export type DraftScormContentType = {
  __typename?: 'DraftScormContentType';
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  categoryIds?: Maybe<Array<Scalars['String']>>;
  cover?: Maybe<EntityMediaType>;
  /** Description of the external content */
  description?: Maybe<Scalars['String']>;
  /** Duration in seconds */
  duration?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  /** Objective of the scorm content */
  objective?: Maybe<Scalars['String']>;
  scormProperties: ScormProperties;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<EntityTagType>>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

export type DraftSurveyType = {
  __typename?: 'DraftSurveyType';
  /** Database Metadata Id */
  dbMetadataId: Scalars['String'];
  /** Database View Id */
  dbViewIds: Array<DbViewId>;
};

/** Draft Task Type */
export type DraftTaskContentType = {
  __typename?: 'DraftTaskContentType';
  /** duration in seconds */
  duration: Scalars['Float'];
  /** description of the task */
  instruction?: Maybe<TaskInstruction>;
  /** Draft name of the content */
  name: Scalars['String'];
  sections?: Maybe<Array<TaskSectionType>>;
};

export enum DripByEnum {
  Content = 'CONTENT',
  Lesson = 'LESSON',
  Section = 'SECTION',
}

export type DripSettingsInput = {
  dripBy?: InputMaybe<DripByEnum>;
  interval?: InputMaybe<Scalars['Float']>;
  type: DripTypeEnum;
};

/** Content drip settings */
export type DripSettingsType = {
  __typename?: 'DripSettingsType';
  dripBy?: Maybe<DripByEnum>;
  interval?: Maybe<Scalars['Float']>;
  type: DripTypeEnum;
};

export enum DripTypeEnum {
  Immediate = 'IMMEDIATE',
  Interval = 'INTERVAL',
  Sequential = 'SEQUENTIAL',
}

export type DueDateAssignmentConfig = {
  __typename?: 'DueDateAssignmentConfig';
  dueDate?: Maybe<Scalars['String']>;
  dueDateType: AssignmentDueDateEnum;
  operator?: Maybe<RelativeDateFilterOperatorEnum>;
  tagId?: Maybe<Scalars['String']>;
  unit?: Maybe<RelativeDateFilterUnitEnum>;
  value?: Maybe<Scalars['Float']>;
};

export type DueDateAssignmentConfigInput = {
  dueDate?: InputMaybe<Scalars['String']>;
  dueDateType: AssignmentDueDateEnum;
  operator?: InputMaybe<RelativeDateFilterOperatorEnum>;
  tagId?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<RelativeDateFilterUnitEnum>;
  value?: InputMaybe<Scalars['Float']>;
};

export type DueDateConfig = {
  __typename?: 'DueDateConfig';
  dueDate?: Maybe<Scalars['String']>;
  dueDateType: DueDateTypeEnum;
  operator?: Maybe<RelativeDateFilterOperatorEnum>;
  tagId?: Maybe<Scalars['String']>;
  unit?: Maybe<RelativeDateFilterUnitEnum>;
  value?: Maybe<Scalars['Float']>;
};

export type DueDateConfigInput = {
  dueDate?: InputMaybe<Scalars['String']>;
  dueDateType: DueDateTypeEnum;
  operator?: InputMaybe<RelativeDateFilterOperatorEnum>;
  tagId?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<RelativeDateFilterUnitEnum>;
  value?: InputMaybe<Scalars['Float']>;
};

export enum DueDateNestingLevelEnum {
  Contents = 'CONTENTS',
  Sections = 'SECTIONS',
}

export type DueDateSetInput = {
  /** used to set /unset duedate in action item */
  set?: InputMaybe<Scalars['String']>;
};

export enum DueDateTypeEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  NoDueDate = 'NO_DUE_DATE',
  Relative = 'RELATIVE',
}

export type DuplicateAgendaItemInput = {
  agendaItemId: Scalars['String'];
};

export type DuplicateAgendaItemTemplateInput = {
  agendaItemTemplateId: Scalars['String'];
};

export type DuplicateBlockIdMapping = {
  __typename?: 'DuplicateBlockIdMapping';
  duplicatedRootBlockId: Scalars['String'];
  rootBlockId: Scalars['String'];
};

export type DuplicateBlocksInput = {
  rootBlockIds: Scalars['String'];
};

export type DuplicateBlocksPayload = {
  __typename?: 'DuplicateBlocksPayload';
  duplicatedRootBlockIdMappings?: Maybe<DuplicateBlockIdMapping>;
};

export type DuplicateContentInput = {
  entityId: Scalars['String'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type DuplicateContentPayload = {
  __typename?: 'DuplicateContentPayload';
  entity: ContentEntity;
};

export type DuplicateEventInput = {
  eventId: Scalars['String'];
};

export type DuplicateMetricInput = {
  metricId: Scalars['String'];
};

/** input for Duplicating OKRs */
export type DuplicateOkrInput = {
  /** new parent id of okr being duplicated, if not present will be duplicated to root */
  alignToParentId?: InputMaybe<Scalars['String']>;
  /** whether to duplicate comments,attachments, note block in newly created OKRS */
  duplicateAuxiliaryData?: InputMaybe<Scalars['Boolean']>;
  /** whether to duplicate whole okr tree or not */
  duplicateOKRSubTree?: InputMaybe<Scalars['Boolean']>;
  /** id of cycle to which new okr will be duplicated to */
  duplicateToCycleId: Scalars['String'];
  /** whether to keep the same progress in newly created OKRS */
  maintainProgress?: InputMaybe<Scalars['Boolean']>;
  okrId: Scalars['String'];
  /** new parent ids under which okr will be duplicated */
  updatedParentIds?: InputMaybe<Array<Scalars['String']>>;
};

export type DuplicateOkrPayload = {
  __typename?: 'DuplicateOKRPayload';
  /** New Duplicated(Created) okr */
  duplicatedOKR?: Maybe<OkrUnion>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DuplicateReportInput = {
  reportId: Scalars['String'];
};

/** Duplicate Report Payload */
export type DuplicateReportPayload = {
  __typename?: 'DuplicateReportPayload';
  report: ReportType;
};

export type DuplicateSkillMatrixInput = {
  skillMatrixId: Scalars['String'];
};

export type DuplicateSurveyInput = {
  /** Parent Content Id */
  parentContentId?: InputMaybe<Scalars['String']>;
  /** Parent Survey Id */
  surveyId: Scalars['String'];
};

/** Duplicate Survey Payload */
export type DuplicateSurveyPayload = {
  __typename?: 'DuplicateSurveyPayload';
  survey?: Maybe<SurveyContentType>;
};

export type DuplicationAllowedOnSharingInput = {
  sharedToWorkspaceId: Scalars['String'];
};

export enum EditPostErrorCodeEnum {
  ForbiddenError = 'FORBIDDEN_ERROR',
  PostNotFound = 'POST_NOT_FOUND',
}

export type EditPostInput = {
  attachments?: InputMaybe<AttachmentsSetInput>;
  /** Post channel id */
  channelId: Scalars['String'];
  /** Flag to hide the post on the channel */
  isHidden?: InputMaybe<Scalars['Boolean']>;
  /** Flag to pin the post on the channel */
  isPinned?: InputMaybe<Scalars['Boolean']>;
  mentions?: InputMaybe<MentionsSetInput>;
  /** Actual message which needs to be edited */
  message?: InputMaybe<Scalars['String']>;
  /** PostId which needs to be edited */
  postId: Scalars['String'];
  /** Title of posts like announcement post */
  title?: InputMaybe<Scalars['String']>;
  urls?: InputMaybe<UrlsSetInput>;
  /** Urls of the post */
  urlsV2?: InputMaybe<UrlsV2SetInput>;
};

export type EditPostPayload = {
  __typename?: 'EditPostPayload';
  errorCode?: Maybe<EditPostErrorCodeEnum>;
  post?: Maybe<PostType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EditSkillGroupInOrgRoleInput = {
  /** name of the skill group */
  name: Scalars['String'];
  /** id of the skill group */
  skillGroupId: Scalars['String'];
};

export type EditSkillGroupInTrackInput = {
  /** name of the skill group */
  name: Scalars['String'];
  /** id of the skill group */
  skillGroupId: Scalars['String'];
};

export type EditWebhookInput = {
  eventTypes: Array<NotificationActionTypeEnum>;
  expiry?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
  webhookId: Scalars['String'];
};

export type EditWebhookPayload = {
  __typename?: 'EditWebhookPayload';
  /** Edit Webhook */
  webhook?: Maybe<WebhookType>;
};

/** Email Digest Edge */
export type EmailDigestEdge = {
  __typename?: 'EmailDigestEdge';
  node: EmailDigestType;
};

export enum EmailDigestFileFormatEnum {
  Csv = 'CSV',
  Pdf = 'PDF',
  Png = 'PNG',
}

export type EmailDigestPayload = {
  __typename?: 'EmailDigestPayload';
  emailDigest?: Maybe<EmailDigestType>;
};

export enum EmailDigestStatusEnum {
  Active = 'ACTIVE',
  Paused = 'PAUSED',
}

export type EmailDigestType = {
  __typename?: 'EmailDigestType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  fileFormat: EmailDigestFileFormatEnum;
  id: Scalars['String'];
  name: Scalars['String'];
  recipients: Array<RecipientInfo>;
  /** Report for digest */
  report?: Maybe<ReportType>;
  reportId: Scalars['String'];
  schedule: ScheduleInfo;
  scheduledJob: ScheduledJobConfigs;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: EmailDigestStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type EmailDigestsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  emailDigestIds?: InputMaybe<Array<Scalars['String']>>;
  reportIds?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<EmailDigestStatusEnum>>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  bccAddresses: Array<Scalars['String']>;
  ccAddresses: Array<Scalars['String']>;
  content: Scalars['String'];
  fromAddress: Scalars['String'];
  subject: Scalars['String'];
};

export type EmbeddedContentInfo = {
  __typename?: 'EmbeddedContentInfo';
  contentId: Scalars['String'];
  /** Cover image of the content */
  cover?: Maybe<EntityMediaType>;
  embeddedContentsCount: Scalars['Float'];
  name: Scalars['String'];
  type: ResourceTypeEnum;
};

export type EmbeddedContentsPayload = {
  __typename?: 'EmbeddedContentsPayload';
  embeddedContentsInfo: Array<EmbeddedContentInfo>;
};

export type EmbeddedEntitiesInfo = {
  __typename?: 'EmbeddedEntitiesInfo';
  /** Cover image of the content */
  cover?: Maybe<EntityMediaType>;
  embeddedEntitiesCount: Scalars['Float'];
  entityId: Scalars['String'];
  eventType?: Maybe<EventTypeEnum>;
  name: Scalars['String'];
  previewMemberIds?: Maybe<Array<Scalars['String']>>;
  resourceType?: Maybe<ResourceTypeEnum>;
  sampleMembers: Array<ContentEntity>;
  type: ParentEntityTypeEnum;
};

export type EmbeddedEntitiesInfoPayload = {
  __typename?: 'EmbeddedEntitiesInfoPayload';
  embeddedEntitiesInfo: Array<EmbeddedEntitiesInfo>;
};

/** Tells about the enrolment settings */
export type EnrolmentInfoType = {
  __typename?: 'EnrolmentInfoType';
  assignedAt?: Maybe<Scalars['String']>;
  assignedBy?: Maybe<UserType>;
  assignedById?: Maybe<Scalars['String']>;
  assignmentId?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['String']>;
  direct?: Maybe<Scalars['Boolean']>;
  dripSettings?: Maybe<DripSettingsType>;
  dueDate?: Maybe<Scalars['String']>;
  selfEnrolled?: Maybe<Scalars['Boolean']>;
};

/** Assignment due date settings */
export type EnrolmentSettingsType = {
  __typename?: 'EnrolmentSettingsType';
  /** Due Date for Assignment */
  dueDate: Scalars['String'];
};

/** Roles filter to get custom and system generated roles */
export type EntitiyRolesFilters = {
  /** filter on custom role */
  entityId?: InputMaybe<Scalars['String']>;
};

export type EntityAccessPayload = {
  __typename?: 'EntityAccessPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** This schema defines the association with an entity */
export type EntityAssociation = {
  __typename?: 'EntityAssociation';
  blockId?: Maybe<Scalars['String']>;
  courseId: Scalars['String'];
  lessonId: Scalars['String'];
  lessonPageId?: Maybe<Scalars['String']>;
};

/** This schema defines the association with an entity */
export type EntityAssociationInput = {
  blockId?: InputMaybe<Scalars['String']>;
  courseId: Scalars['String'];
  lessonId: Scalars['String'];
};

export type EntityCheck = {
  __typename?: 'EntityCheck';
  entity: EntityCheckEnum;
  exists: Scalars['Boolean'];
};

export enum EntityCheckEnum {
  Okr = 'OKR',
  Task = 'TASK',
}

export type EntityCheckWidgetType = {
  __typename?: 'EntityCheckWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  entitiesToCheck: Array<EntityCheckEnum>;
  /** check if entity exists or not */
  entityExists: Array<EntityCheck>;
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum EntityCollectionEnum {
  Block = 'BLOCK',
  Event = 'EVENT',
  Okr = 'OKR',
  Resource = 'RESOURCE',
  User = 'USER',
}

export type EntityGroupFilter = {
  conditionOperator?: InputMaybe<GroupConditionOperator>;
  groupAdditionMethod: GroupAdditionMethodEnum;
  groupIds: Array<Scalars['String']>;
};

export type EntityGroupFilterType = {
  __typename?: 'EntityGroupFilterType';
  groupAdditionMethod: GroupAdditionMethodEnum;
  groupIds: Array<Scalars['String']>;
};

export type EntityJiraIntegrationStatusConfig = {
  __typename?: 'EntityJiraIntegrationStatusConfig';
  jiraStatusId?: Maybe<Scalars['String']>;
  lyearnStatusId?: Maybe<Scalars['String']>;
};

export type EntityJiraProjectStatuesConfig = {
  __typename?: 'EntityJiraProjectStatuesConfig';
  projectId: Scalars['String'];
  statuses?: Maybe<Array<EntityJiraIntegrationStatusConfig>>;
};

export type EntityMediaSet = {
  __typename?: 'EntityMediaSet';
  set: Array<EntityMediaType>;
};

/** General Media Object, can be used with any entity */
export type EntityMediaType = {
  __typename?: 'EntityMediaType';
  accessClass: MediaAccessClassEnum;
  /** Tells whether it is a file or folder */
  kind: MediaKindEnum;
  location: MediaLocation;
  mediaId: Scalars['String'];
  /** media type of uploaded entity */
  mimeType?: Maybe<Scalars['String']>;
  /** name of the media stored */
  name: Scalars['String'];
  previewEnabled?: Maybe<Scalars['Boolean']>;
  /** Size of the attachment in kbs */
  size?: Maybe<Scalars['Float']>;
  uniqueId?: Maybe<Scalars['String']>;
  /** url of the attachment stored on our end */
  url?: Maybe<Scalars['String']>;
  /** preview of url */
  urlPreview?: Maybe<Scalars['Map']>;
  urlPreviewMetadata?: Maybe<UrlPreviewMetadata>;
};

export type EntityPermissionsMap = {
  __typename?: 'EntityPermissionsMap';
  entityPermissions: Array<AllowedEntityPermissions>;
  icon?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  label: Scalars['String'];
};

export type EntityPermittedOn = {
  __typename?: 'EntityPermittedOn';
  actions: Array<AllowedPermissionActionsEnum>;
  entities: Array<AllowedPermissionsEntityType>;
  subject: AllowedPermissionsSubjectType;
};

/** It will resolve groupRole or userRole */
export type EntitySharedSpace = SharedSpaceGroupRoleType | SharedSpaceUserRoleType;

export type EntitySubworkspacesInput = {
  set: Array<Scalars['String']>;
};

export type EntityTagInput = {
  dateValue?: InputMaybe<DateValueInput>;
  numberValue?: InputMaybe<Scalars['Float']>;
  numberValues?: InputMaybe<Array<Scalars['Float']>>;
  optionIdValues?: InputMaybe<Array<Scalars['String']>>;
  tagId: Scalars['String'];
  textValue?: InputMaybe<Scalars['String']>;
  textValues?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<TagTypeEnum>;
  userIdValues?: InputMaybe<Array<Scalars['String']>>;
};

export type EntityTagType = {
  __typename?: 'EntityTagType';
  dateValue?: Maybe<DateValueType>;
  isEditable?: Maybe<Scalars['Boolean']>;
  numberValue?: Maybe<Scalars['Float']>;
  optionIdValues?: Maybe<Array<Scalars['String']>>;
  options?: Maybe<Array<SelectTagOptionType>>;
  tag?: Maybe<TagUnion>;
  tagId: Scalars['String'];
  textValue?: Maybe<Scalars['String']>;
  type?: Maybe<TagTypeEnum>;
  userIdValues?: Maybe<Array<Scalars['String']>>;
  userProfiles?: Maybe<Array<UserProfile>>;
  users?: Maybe<Array<UserType>>;
};

export type EntityTagsInput = {
  set: Array<EntityTagInput>;
};

export enum EntityTypeEnum {
  Article = 'ARTICLE',
  Assignment = 'ASSIGNMENT',
  AuthToken = 'AUTH_TOKEN',
  Channel = 'CHANNEL',
  Classroom = 'CLASSROOM',
  Course = 'COURSE',
  Event = 'EVENT',
  Exercise = 'EXERCISE',
  GenerativeTask = 'GENERATIVE_TASK',
  KeyResult = 'KEY_RESULT',
  Lesson = 'LESSON',
  Objective = 'OBJECTIVE',
  Okr = 'OKR',
  Path = 'PATH',
  Performance = 'PERFORMANCE',
  Quiz = 'QUIZ',
  Resource = 'RESOURCE',
  Task = 'TASK',
  User = 'USER',
  VerificationCode = 'VERIFICATION_CODE',
}

export type EntityViewConfigInput = {
  fields: Array<UserViewFieldsInputSchema>;
};

/** This holds the information of the view of the entity */
export type EntityViewConfigSchema = {
  __typename?: 'EntityViewConfigSchema';
  fields: Array<UserViewFieldSchema>;
  updatedAt: Scalars['String'];
};

export type EntityWorkspaceMapping = {
  __typename?: 'EntityWorkspaceMapping';
  entityId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export enum ErrorCodeEnum {
  DataNotFound = 'DATA_NOT_FOUND',
  NoAllowedResponseTypes = 'NO_ALLOWED_RESPONSE_TYPES',
  NoScorecards = 'NO_SCORECARDS',
  NoTitle = 'NO_TITLE',
  RequiredClassroomConfigNotFound = 'REQUIRED_CLASSROOM_CONFIG_NOT_FOUND',
  RequiredResourceConfigNotFound = 'REQUIRED_RESOURCE_CONFIG_NOT_FOUND',
  ResourceNotFound = 'RESOURCE_NOT_FOUND',
}

/** input for approving or rejecting exercise submission */
export type EvaluateExerciseSubmissionInput = {
  entityId: Scalars['String'];
  /** set to true to feature this submission */
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  status: ExerciseSubmissionStatusEnum;
  submissionId: Scalars['String'];
  userId: Scalars['String'];
};

/** payload for exerciseSubmissionStatus */
export type EvaluateExerciseSubmissionPayload = {
  __typename?: 'EvaluateExerciseSubmissionPayload';
  currentReviewingState?: Maybe<CurrentReviewingState>;
  errorCode?: Maybe<PerformanceErrorCode>;
  success?: Maybe<Scalars['Boolean']>;
};

/** This is the input for evaluation of submission of homework! */
export type EvaluateHomeworkSubmissionInput = {
  entityId: Scalars['String'];
  score: Scalars['Float'];
  userId: Scalars['String'];
};

/** Evaluate Homework submission */
export type EvaluateHomeworkSubmissionPayload = {
  __typename?: 'EvaluateHomeworkSubmissionPayload';
  performance: HomeworkPerformanceType;
  success?: Maybe<Scalars['Boolean']>;
};

export enum EventActions {
  Add = 'ADD',
  Allow = 'ALLOW',
  Archive = 'ARCHIVE',
  ControlOthers = 'CONTROL_OTHERS',
  Create = 'CREATE',
  Edit = 'EDIT',
  End = 'END',
  Join = 'JOIN',
  Launch = 'LAUNCH',
  Leave = 'LEAVE',
  Manage = 'MANAGE',
  Participate = 'PARTICIPATE',
  Priority = 'PRIORITY',
  Read = 'READ',
  Remove = 'REMOVE',
  Start = 'START',
  View = 'VIEW',
}

export type EventArchivedUserNotification = {
  __typename?: 'EventArchivedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  eventId: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  timeLeft: Scalars['String'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type EventAttendeesFilters = {
  performancesFilters: PerformancesFilters;
  userFilters?: InputMaybe<PerformanceUsersFilters>;
};

export type EventAttendeesFiltersType = {
  __typename?: 'EventAttendeesFiltersType';
  performancesFilters: PerformancesFiltersType;
  userFilters?: Maybe<PerformanceUsersFiltersType>;
};

export type EventAttendeesFiltersV2 = {
  attendanceStatus?: InputMaybe<Array<AttendanceStatusEnum>>;
  eventId: Scalars['String'];
  isAttended?: InputMaybe<Scalars['Boolean']>;
  registeredOn?: InputMaybe<DateFilter>;
  status?: InputMaybe<Array<PerformanceStatusEnum>>;
  userSearchText?: InputMaybe<Scalars['String']>;
  userTypes?: InputMaybe<Array<EventUserTypeEnum>>;
  workspaceIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Edge */
export type EventEdge = {
  __typename?: 'EventEdge';
  node: EventUnion;
};

export type EventFilters = {
  conferenceBridgeId?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
};

export type EventLivelinessTimestamps = {
  __typename?: 'EventLivelinessTimestamps';
  endTime: Scalars['String'];
  eventId: Scalars['String'];
  startTime: Scalars['String'];
};

export type EventLocation = {
  __typename?: 'EventLocation';
  customLocation?: Maybe<Scalars['String']>;
  geoLocation?: Maybe<GeoLocation>;
  type: EventLocationTypeEnum;
};

export type EventLocationInput = {
  customLocation?: InputMaybe<Scalars['String']>;
  geoLocation?: InputMaybe<GeoLocationInput>;
  type: EventLocationTypeEnum;
};

export enum EventLocationTypeEnum {
  Custom = 'CUSTOM',
  GeoLocation = 'GEO_LOCATION',
}

/** Event metadata */
export type EventMetadataType = {
  __typename?: 'EventMetadataType';
  bookedSeatRatio: Scalars['Float'];
};

export type EventNotAttendedUserNotification = {
  __typename?: 'EventNotAttendedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  eventId: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startTime?: Maybe<Scalars['String']>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type EventPayload = {
  __typename?: 'EventPayload';
  event?: Maybe<EventUnion>;
};

export type EventPerformanceInput = {
  attendanceStatus?: InputMaybe<AttendanceStatusEnum>;
  estimatedTimeSpent?: InputMaybe<Scalars['Float']>;
  eventId: Scalars['String'];
  isAttended?: InputMaybe<Scalars['Boolean']>;
  progress?: InputMaybe<Scalars['Float']>;
  progressInfo: ProgressInfoInput;
  recordingWatchSummaries: Array<RecordingWatchSummaryInput>;
  registeredOn?: InputMaybe<Scalars['String']>;
  selfEstimatedTimeSpent?: InputMaybe<Scalars['Float']>;
  selfProgress?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PerformanceStatusEnum>;
  type?: InputMaybe<PerformanceTypeEnum>;
  userId: Scalars['String'];
  userType?: InputMaybe<EventUserTypeEnum>;
};

export type EventPerformanceType = {
  __typename?: 'EventPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  attendanceStatus?: Maybe<AttendanceStatusEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** stores the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  externalEventRegistrationInfo?: Maybe<ExternalEventRegistrationInfo>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  /** indicates if the user was ever present in the event */
  isAttended: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  postWorkProgress: Scalars['Float'];
  preWorkProgress: Scalars['Float'];
  /** percentage of total event time the user was present */
  progress: Scalars['Float'];
  progressInfo?: Maybe<ProgressInfoType>;
  progressSource?: Maybe<ProgressSourceEnum>;
  recordingWatchSummaries?: Maybe<Array<RecordingWatchSummary>>;
  /** date when the user registered on event */
  registeredOn?: Maybe<Scalars['String']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  userType: EventUserTypeEnum;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type EventPostWorkAvailableUserNotification = {
  __typename?: 'EventPostWorkAvailableUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  eventId: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  senderId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type EventRecordingAvailableUserNotification = {
  __typename?: 'EventRecordingAvailableUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  eventId: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type EventRecordingInput = {
  duration: Scalars['Float'];
  id?: InputMaybe<Scalars['String']>;
  recordingMedia: CreateMediaInput;
};

export type EventRecordingType = {
  __typename?: 'EventRecordingType';
  _id: Scalars['String'];
  duration: Scalars['Float'];
  id: Scalars['String'];
  recordingMedia: EntityMediaType;
};

export type EventRegistrationUserNotification = {
  __typename?: 'EventRegistrationUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  eventId: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  registeredById: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startTime?: Maybe<Scalars['String']>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Edge */
export type EventScheduleEdge = {
  __typename?: 'EventScheduleEdge';
  node: EventScheduleType;
};

export enum EventScheduleErrorCodeEnum {
  FailedToCreateSchedule = 'FAILED_TO_CREATE_SCHEDULE',
  FailedToDeleteSchedule = 'FAILED_TO_DELETE_SCHEDULE',
  FailedToUpdateSchedule = 'FAILED_TO_UPDATE_SCHEDULE',
  LiveSessionScheduleDoesNotExist = 'LIVE_SESSION_SCHEDULE_DOES_NOT_EXIST',
  RecurrenceRuleNotFound = 'RECURRENCE_RULE_NOT_FOUND',
}

/** Event schedule recurrence rule configuration */
export type EventScheduleRecurrenceRuleConfigurationInput = {
  /** In minutes */
  duration: Scalars['Float'];
  effectiveFrom: Scalars['String'];
  recurrenceRule: Scalars['String'];
  recurrenceUntil: Scalars['String'];
};

/** EventSchedule recurrence rule configuration object */
export type EventScheduleRecurrenceRuleConfigurationType = {
  __typename?: 'EventScheduleRecurrenceRuleConfigurationType';
  /** In minutes */
  duration: Scalars['Float'];
  effectiveFrom: Scalars['String'];
  recurrenceRule: Scalars['String'];
  recurrenceRuleId: Scalars['String'];
  recurrenceUntil: Scalars['String'];
};

export enum EventScheduleStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Suspended = 'SUSPENDED',
}

/** EventSchedule Type. This will facilitate the scheduling recurring event */
export type EventScheduleType = {
  __typename?: 'EventScheduleType';
  classroom?: Maybe<ClassroomType>;
  classroomId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  eventType: ClassroomEventTypeEnum;
  /** EventStartTimestamp for the final event to be scheduled by this eventSchedule */
  finalEventTimestamp: Scalars['String'];
  /** EventStartTimestamp for the first event to be scheduled by this eventSchedule */
  firstEventTimestamp: Scalars['String'];
  /** All users of this group have access to events of the schedule */
  groupIds: Array<Scalars['String']>;
  id: Scalars['String'];
  /** Field for internal use only, not to be used by frontend anywhere */
  lastEventScheduleProcessedTimestamp?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** EventStartTimestamp for the next event to be scheduled by this eventSchedule. For internal optimization only */
  nextEventTimestamp: Scalars['String'];
  /** Holds recurrenceRules of eventSchedule */
  recurrenceRuleConfigurations: Array<EventScheduleRecurrenceRuleConfigurationType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: EventScheduleStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  usersCount?: Maybe<Scalars['Int']>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Edge */
export type EventScheduleUserEdge = {
  __typename?: 'EventScheduleUserEdge';
  node: EventScheduleUserType;
};

/** Mutation Response */
export type EventScheduleUserMutationResponse = {
  __typename?: 'EventScheduleUserMutationResponse';
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  userIds?: Maybe<Array<Scalars['String']>>;
};

export type EventScheduleUserRoleConfig = {
  eventScheduleUserRole: EventUserRoleEnum;
  userId: Scalars['String'];
};

/** EventSchedule users */
export type EventScheduleUserType = {
  __typename?: 'EventScheduleUserType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum EventSeatStatusEnum {
  Available = 'AVAILABLE',
  Booked = 'BOOKED',
  FillingFast = 'FILLING_FAST',
}

/** self enroll course inside course micro app */
export type EventSharingSelfEnrollCourseInput = {
  courseId: Scalars['String'];
  eventId: Scalars['String'];
};

export type EventStartReminderUserNotification = {
  __typename?: 'EventStartReminderUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  eventId: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startTime?: Maybe<Scalars['String']>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  timeLeft: Scalars['String'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum EventStatusEnum {
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Revoked = 'REVOKED',
  Scheduled = 'SCHEDULED',
  Started = 'STARTED',
  Waiting = 'WAITING',
}

export enum EventSubTypeEnum {
  GroupSession = 'GROUP_SESSION',
  Webinar = 'WEBINAR',
}

export enum EventSubjects {
  Activity = 'ACTIVITY',
  Attendance = 'ATTENDANCE',
  Audio = 'AUDIO',
  Chat = 'CHAT',
  Course = 'COURSE',
  Event = 'EVENT',
  MemberList = 'MEMBER_LIST',
  Notepad = 'NOTEPAD',
  One = 'ONE',
  Poll = 'POLL',
  Quiz = 'QUIZ',
  RaiseHand = 'RAISE_HAND',
  Recordings = 'RECORDINGS',
  ScreenShare = 'SCREEN_SHARE',
  StudyMaterial = 'STUDY_MATERIAL',
  SubWorkspace = 'SUB_WORKSPACE',
  VideoShare = 'VIDEO_SHARE',
  Whiteboard = 'WHITEBOARD',
}

export type EventSubscriptionInput = {
  eventId: Scalars['String'];
  /** operations to subscribe on */
  operations?: InputMaybe<Array<EventSubscriptionOperationEnum>>;
};

export enum EventSubscriptionOperationEnum {
  EventUpdated = 'EVENT_UPDATED',
}

export type EventSubscriptionPayload = {
  __typename?: 'EventSubscriptionPayload';
  event: EventUnion;
  operation: EventSubscriptionOperationEnum;
};

export enum EventTypeEnum {
  External = 'EXTERNAL',
  Live = 'LIVE',
  Offline = 'OFFLINE',
}

/** union of all events */
export type EventUnion = ExternalEventType | LiveEventType | OfflineEventType;

export enum EventUpdationSourceEnum {
  ExternalSync = 'EXTERNAL_SYNC',
  Scheduler = 'SCHEDULER',
  User = 'USER',
}

export enum EventUserMetricsAttendanceEnum {
  Absent = 'ABSENT',
  PartiallyPresent = 'PARTIALLY_PRESENT',
  Present = 'PRESENT',
}

/** Edge */
export type EventUserMetricsEdge = {
  __typename?: 'EventUserMetricsEdge';
  node: EventUserMetricsType;
};

export enum EventUserMetricsErrorCodeEnum {
  EventNotExist = 'EVENT_NOT_EXIST',
  FailedToCreateEventUserMetrics = 'FAILED_TO_CREATE_EVENT_USER_METRICS',
  FailedToGetEventUserMetrics = 'FAILED_TO_GET_EVENT_USER_METRICS',
  FailedToMarkAttendance = 'FAILED_TO_MARK_ATTENDANCE',
  UserNotExist = 'USER_NOT_EXIST',
}

/** EventUserMetrics Type */
export type EventUserMetricsType = {
  __typename?: 'EventUserMetricsType';
  /** Represents aggregated user metrics like student's attendance status, attendance duration, number of drops */
  aggregatedUserMetrics: AggregatedUserMetricsType;
  /** Classroom ID */
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Completion time of the event */
  eventCompletionTimestamp?: Maybe<Scalars['String']>;
  /** Duration of the event */
  eventDuration: Scalars['Int'];
  /** Event ID */
  eventId: Scalars['String'];
  /** Start time of the event */
  eventStartTimestamp: Scalars['String'];
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** User ID */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum EventUserRoleEnum {
  Student = 'STUDENT',
  Teacher = 'TEACHER',
}

export type EventUserTrainingType = {
  __typename?: 'EventUserTrainingType';
  event: EventUnion;
  type: UserTrainingTypeEnum;
};

/** Event users */
export type EventUserType = {
  __typename?: 'EventUserType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Event Id for eventUser */
  eventId: Scalars['String'];
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** User Data of event user */
  user?: Maybe<UserType>;
  /** User Id for eventUser */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum EventUserTypeEnum {
  Instructor = 'INSTRUCTOR',
  Learner = 'LEARNER',
}

export type EventWorkConfig = {
  __typename?: 'EventWorkConfig';
  contents: Array<EventWorkContent>;
};

export type EventWorkConfigInput = {
  contents: Array<EventWorkContentInput>;
};

export type EventWorkContent = {
  __typename?: 'EventWorkContent';
  contentId: Scalars['String'];
  isOptional: Scalars['Boolean'];
};

export type EventWorkContentInput = {
  contentId: Scalars['String'];
  isOptional: Scalars['Boolean'];
};

export type EventWorkContentsInput = {
  set: Array<Scalars['String']>;
};

export type EventsFilters = {
  associatedUserIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  endTime?: InputMaybe<DateFilter>;
  eventIds?: InputMaybe<Array<Scalars['String']>>;
  eventSeatStatus?: InputMaybe<Array<EventSeatStatusEnum>>;
  excludeReceivedEvents?: InputMaybe<Scalars['Boolean']>;
  /** filter by field and tag properties */
  filterGroupV2?: InputMaybe<FilterGroupV2InputType>;
  instructorIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  shareable?: InputMaybe<Scalars['Boolean']>;
  sharedEventFilters?: InputMaybe<SharedEntityFilters>;
  startTime?: InputMaybe<DateFilter>;
  status?: InputMaybe<Array<EventStatusEnum>>;
  /** get resources based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<EventTypeEnum>>;
};

export type EventsWidgetType = {
  __typename?: 'EventsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  /** contents */
  events: PaginatedEventsPayload;
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type EventsWidgetTypeEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
};

/** Input for excuse students from assignment */
export type ExcuseStudentsFromAssignmentInput = {
  /** students will be excused for this assignment id */
  entityId: Scalars['String'];
  /** Id of the students who will be excused */
  userIds: Array<Scalars['String']>;
};

export type ExerciseAdvanceSettingsInput = {
  allowComments: Scalars['Boolean'];
};

export type ExerciseApprovedAndFeaturedUserNotification = {
  __typename?: 'ExerciseApprovedAndFeaturedUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  submissionId: Scalars['String'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ExerciseApprovedUserNotification = {
  __typename?: 'ExerciseApprovedUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  parentEntityId?: Maybe<Scalars['String']>;
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  reviewerType?: Maybe<ReviewerTypeEnum>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  submissionId: Scalars['String'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ExerciseInstructionInput = {
  /** Instruction Media */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Instruction Text */
  text?: InputMaybe<Scalars['String']>;
};

/** Instruction for Exercise */
export type ExerciseInstructionType = {
  __typename?: 'ExerciseInstructionType';
  /** media */
  medias: Array<EntityMediaType>;
  /** text */
  text?: Maybe<Scalars['String']>;
};

export type ExercisePerformanceType = {
  __typename?: 'ExercisePerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  currentReviewingState?: Maybe<CurrentReviewingState>;
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  featuredStatusUpdatedAt?: Maybe<Scalars['String']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  hasFeaturedSubmission?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isContextUserReviewer: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  isResubmissionAllowed?: Maybe<Scalars['Boolean']>;
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  parentEntity?: Maybe<ContentEntity>;
  parentEntityId?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Float']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** reviewer info */
  reviewInfo?: Maybe<ExerciseReviewInfo>;
  /** review result */
  reviewResults?: Maybe<Array<ExerciseReviewType>>;
  reviewStatus?: Maybe<ReviewStatusEnum>;
  reviewers: Array<UserType>;
  score?: Maybe<Scalars['Float']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  /** submission reviewer info */
  submissionReviewInfo?: Maybe<SubmissionReviewInfo>;
  /** Submissions */
  submissions?: Maybe<Array<ExerciseSubmissionType>>;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type ExercisePerformanceTypeCurrentReviewingStateArgs = {
  submissionId: Scalars['String'];
};

export type ExercisePerformanceTypeIsResubmissionAllowedArgs = {
  submissionId: Scalars['String'];
};

export type ExerciseQuestionInput = {
  /** Question id, to update the same question */
  id?: InputMaybe<Scalars['String']>;
  /** Instruction Media */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Type for response, for learner to submit */
  responseType: ResourceResponseTypeEnum;
  /** Question Text */
  text: Scalars['String'];
};

/** This is the input for submission of Exercise! */
export type ExerciseQuestionResponseInput = {
  /** User response with some attachments */
  mediaResponses?: InputMaybe<Array<CreateMediaInput>>;
  /** Id of the question for which submission is made */
  questionId: Scalars['String'];
  responseType: ResourceResponseTypeEnum;
  /** If response type is text, HTML string */
  textResponse?: InputMaybe<Scalars['String']>;
};

export type ExerciseQuestionResponseType = {
  __typename?: 'ExerciseQuestionResponseType';
  question?: Maybe<ExerciseQuestionType>;
  questionId: Scalars['String'];
  response?: Maybe<Array<EntityMediaType>>;
  responseType: ResourceResponseTypeEnum;
  /** If response type is text, HTML string */
  textResponse?: Maybe<Scalars['String']>;
};

/** Question for Exercise */
export type ExerciseQuestionType = {
  __typename?: 'ExerciseQuestionType';
  id: Scalars['String'];
  /** media for question */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Type for response, for learner to submit */
  responseType: ResourceResponseTypeEnum;
  /** text for question */
  text: Scalars['String'];
};

export type ExerciseRejectedUserNotification = {
  __typename?: 'ExerciseRejectedUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  submissionId: Scalars['String'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Data regarding the review */
export type ExerciseReviewInfo = {
  __typename?: 'ExerciseReviewInfo';
  peerReviewSubmitted?: Maybe<Scalars['Boolean']>;
  peerReviewSubmittedAt?: Maybe<Scalars['String']>;
  reviewers: Array<ReviewerInfo>;
  reviewersAssigned: Scalars['Float'];
};

export type ExerciseReviewType = {
  __typename?: 'ExerciseReviewType';
  approved: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  reviewedAt?: Maybe<Scalars['String']>;
  reviewer?: Maybe<UserType>;
  reviewerId: Scalars['String'];
  /** score */
  score?: Maybe<Scalars['Float']>;
};

export type ExerciseSubmissionScorecardScoreType = {
  __typename?: 'ExerciseSubmissionScorecardScoreType';
  score?: Maybe<Scalars['Float']>;
  scorecardId: Scalars['String'];
};

export enum ExerciseSubmissionStatusEnum {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export type ExerciseSubmissionType = {
  __typename?: 'ExerciseSubmissionType';
  allReviewersScorecardScores?: Maybe<Array<ReviewerExerciseSubmissionScorecardScoreType>>;
  /** It will be created lazily when someone gives a feedback to a submission */
  feedbackChannelId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  /** when user does partial submission, this will be false.On final submission,will be set true */
  isSubmitted?: Maybe<Scalars['Boolean']>;
  questionResponses: Array<ExerciseQuestionResponseType>;
  reviewedAt?: Maybe<Scalars['String']>;
  reviewedBy?: Maybe<UserType>;
  /** Id of the reviewer who has reviewed the submission */
  reviewedById?: Maybe<Scalars['String']>;
  reviewerScorecardScore?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  scorecardScores?: Maybe<Array<ExerciseSubmissionScorecardScoreType>>;
  submittedAt: Scalars['String'];
};

export type ExerciseSubmissionTypeReviewerScorecardScoreArgs = {
  reviewerId?: InputMaybe<Scalars['String']>;
};

export type ExerciseSubmissionTypeScorecardScoresArgs = {
  reviewerId?: InputMaybe<Scalars['String']>;
};

export type ExerciseSubmissionsForReviewerFilters = {
  reviewStatus?: InputMaybe<Array<ReviewStatusEnum>>;
  reviewedOn?: InputMaybe<DateFilter>;
  searchText?: InputMaybe<Scalars['String']>;
  submittedOn?: InputMaybe<DateFilter>;
};

export type ExerciseSubmittedUserNotification = {
  __typename?: 'ExerciseSubmittedUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  parentEntityId?: Maybe<Scalars['String']>;
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  reviewerType?: Maybe<ReviewerTypeEnum>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  submissionId: Scalars['String'];
  submittedById: Scalars['String'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Exercise Schema */
export type ExerciseType = {
  __typename?: 'ExerciseType';
  advanceSettings?: Maybe<HomeworkAdvanceSettingsType>;
  applicableRoles?: Maybe<Array<RoleType>>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** discussion channel */
  commentChannelId?: Maybe<Scalars['String']>;
  completionReviewRequired?: Maybe<Scalars['Boolean']>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']>;
  draft?: Maybe<DraftExerciseType>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  duration: Scalars['Float'];
  /** Homework Deadline and availability settings */
  enrolmentSettings?: Maybe<EnrolmentSettingsType>;
  /** Questions for exercise. Learner has to submit answers for */
  exerciseQuestions: Array<ExerciseQuestionType>;
  /** Feedback Questions for the exercise */
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  gradeSettings: ResourceGradeSettingsType;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  /** Assignment Instructions in text or media */
  instruction?: Maybe<ExerciseInstructionType>;
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  objective?: Maybe<Scalars['String']>;
  performance?: Maybe<ExercisePerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  reviewType: ResourceReviewTypeEnum;
  reviewerConfig?: Maybe<ReviewerConfig>;
  /** Reviewer Instructions in text or media */
  reviewerInstruction?: Maybe<ExerciseInstructionType>;
  reviewers: Array<UserType>;
  /** Ids of Scorecard related to the exercise */
  scorecardIds?: Maybe<Array<Scalars['String']>>;
  scorecards: Array<ScorecardType>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  skills: Array<SkillType>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Exercise Schema */
export type ExerciseTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Exercise Schema */
export type ExerciseTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Exercise Schema */
export type ExerciseTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Exercise Schema */
export type ExerciseTypeIsReviewerArgs = {
  learnerId?: InputMaybe<Scalars['String']>;
};

/** Exercise Schema */
export type ExerciseTypePerformanceArgs = {
  filters?: InputMaybe<PerformancesFilters>;
};

/** Exercise Schema */
export type ExerciseTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Exercise Schema */
export type ExerciseTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Exercise Schema */
export type ExerciseTypeTagArgs = {
  tagId: Scalars['String'];
};

export enum ExpectedTeamSizeEnum {
  FiftyToHundered = 'FIFTY_TO_HUNDERED',
  HunderedToFiveHundered = 'HUNDERED_TO_FIVE_HUNDERED',
  OneToFifty = 'ONE_TO_FIFTY',
  OverFiveHundered = 'OVER_FIVE_HUNDERED',
}

export type ExportJobCompletedUserNotification = {
  __typename?: 'ExportJobCompletedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  jobId: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ExportTagReportInput = {
  tagId: Scalars['String'];
};

export type ExportTagReportPayload = {
  __typename?: 'ExportTagReportPayload';
  url: Scalars['String'];
};

export type ExternalContentInstructorInputType = {
  designation: Scalars['String'];
  name: Scalars['String'];
  picture: Scalars['String'];
};

/** External Content Performance Type */
export type ExternalContentPerformanceType = {
  __typename?: 'ExternalContentPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** url type submission */
  externalCertificateUrl?: Maybe<Scalars['String']>;
  /** performance done on external platform */
  externalPerformance?: Maybe<ExternalPerformance>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  /** media type submission */
  mediaSubmission?: Maybe<EntityMediaType>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float'];
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  /** text submission */
  textSubmission?: Maybe<Scalars['String']>;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

/** External Content Type */
export type ExternalContentType = {
  __typename?: 'ExternalContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** External content URL */
  contentUrl?: Maybe<Scalars['String']>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']>;
  /** Description of the external content */
  description?: Maybe<Scalars['String']>;
  draft?: Maybe<DraftExternalContentType>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** Duration in seconds */
  duration: Scalars['Float'];
  /** Type of the external content */
  externalContentType?: Maybe<ExternalContentTypeEnum>;
  externalResourceInfo?: Maybe<ExternalResourceInfo>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  marketplaceContentId?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  /** Objective of the external content */
  objective?: Maybe<Scalars['String']>;
  performance?: Maybe<ExternalContentPerformanceType>;
  /** Provider of external content */
  provider?: Maybe<ProviderType>;
  /** Id of the external content provider */
  providerId?: Maybe<Scalars['String']>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']>>;
  /** Skills suitable to the externalContent */
  skills?: Maybe<Array<SkillType>>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** Method to verify the completion of the external content */
  verificationMethod?: Maybe<VerificationMethodType>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** External Content Type */
export type ExternalContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** External Content Type */
export type ExternalContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** External Content Type */
export type ExternalContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** External Content Type */
export type ExternalContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** External Content Type */
export type ExternalContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** External Content Type */
export type ExternalContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export enum ExternalContentTypeEnum {
  Article = 'ARTICLE',
  Assessment = 'ASSESSMENT',
  Book = 'BOOK',
  Course = 'COURSE',
  Podcast = 'PODCAST',
  ResearchPaper = 'RESEARCH_PAPER',
  Video = 'VIDEO',
}

/** External event container content type. Refers to event entity */
export type ExternalEventContentType = {
  __typename?: 'ExternalEventContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the event */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  description?: Maybe<Scalars['String']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** duration of content in seconds */
  duration: Scalars['Float'];
  event?: Maybe<EventUnion>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  objective: Scalars['String'];
  performance?: Maybe<EventPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  startTime: Scalars['String'];
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** External event container content type. Refers to event entity */
export type ExternalEventContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export enum ExternalEventCreationStrategyEnum {
  Form = 'FORM',
  Integration = 'INTEGRATION',
}

export type ExternalEventProps = {
  externalEventProviderInfo: ExternalEventProviderInfoInput;
  recordingSettings?: InputMaybe<RecordingSettingsInput>;
};

export type ExternalEventProviderInfoInput = {
  creationStrategy?: InputMaybe<ExternalEventCreationStrategyEnum>;
  meta: Scalars['String'];
  providerId: Scalars['String'];
};

export type ExternalEventProviderInfoType = {
  __typename?: 'ExternalEventProviderInfoType';
  /** describes whether external session was created with a form fill, or an integration */
  creationStrategy?: Maybe<ExternalEventCreationStrategyEnum>;
  meta: Scalars['String'];
  provider?: Maybe<ProviderType>;
  providerId: Scalars['String'];
};

export type ExternalEventRegistrationInfo = {
  __typename?: 'ExternalEventRegistrationInfo';
  registrantId: Scalars['String'];
};

export type ExternalEventRegistrationInfoInput = {
  registrantId: Scalars['String'];
};

export type ExternalEventType = {
  __typename?: 'ExternalEventType';
  /** actual ending time */
  actualEndTime?: Maybe<Scalars['String']>;
  /** actual start time */
  actualStartTime?: Maybe<Scalars['String']>;
  /** reason for event archival */
  archivalReason?: Maybe<Scalars['String']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** count of available seats */
  availableSeatCount?: Maybe<Scalars['Float']>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  completionSettings?: Maybe<CompletionSettingsType>;
  conferenceBridgeId: Scalars['String'];
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /**
   * count of users attended the event from the current workspace
   * @deprecated no longer required
   */
  currentWorkspaceUserCount: Scalars['Float'];
  defaultGroupId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  /** user provided end time in UTC */
  endTime: Scalars['String'];
  /** session recordings */
  eventRecordings?: Maybe<Array<EventRecordingType>>;
  externalEventProviderInfo: ExternalEventProviderInfoType;
  id: Scalars['String'];
  instructorIds: Array<Scalars['String']>;
  instructors: Array<UserType>;
  /** flag to indicate if event is filling fast */
  isFillingFast: Scalars['Boolean'];
  isRevokedAfterCompletion: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  /** flag to indicate if currentUser is an instructor */
  isUserInstructor: Scalars['Boolean'];
  isUserRegistered: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  /** event metadata */
  meta: EventMetadataType;
  name: Scalars['String'];
  objective: Scalars['String'];
  postWorkContentIds?: Maybe<Array<Scalars['String']>>;
  postWorkContents: Array<ContentEntity>;
  postwork?: Maybe<EventWorkConfig>;
  preWorkContentIds?: Maybe<Array<Scalars['String']>>;
  preWorkContents: Array<ContentEntity>;
  prework?: Maybe<EventWorkConfig>;
  recordingSettings: RecordingSettings;
  /** Recording URLs of the event */
  recordings?: Maybe<Array<Scalars['String']>>;
  /** time after which registration is closed */
  registrationCloseTime?: Maybe<Scalars['String']>;
  registrationSettings?: Maybe<RegistrationSettings>;
  remainingPostWorkContents: Scalars['Float'];
  remainingPreWorkContents: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  skills: Array<SkillType>;
  /** user provided start time in UTC */
  startTime: Scalars['String'];
  status: EventStatusEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** session time zone */
  timezone: Scalars['String'];
  toCompleteScheduledJobId: ExternalEventProviderInfoType;
  /** total count of users who attended the event */
  totalUsersCount: Scalars['Float'];
  type: EventTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userIds: Array<Scalars['String']>;
  /** Permissions for a user on event */
  userPermissions?: Maybe<Array<UserEventPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  users: Array<UserType>;
  usersCount: Scalars['Float'];
  versionNumber?: Maybe<Scalars['Float']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

export type ExternalEventTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type ExternalEventTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export type ExternalEventTypeTagArgs = {
  tagId: Scalars['String'];
};

/** Edge */
export type ExternalMappingEdge = {
  __typename?: 'ExternalMappingEdge';
  node: ExternalMappingType;
};

export type ExternalMappingFilters = {
  externalMappingId?: InputMaybe<Scalars['String']>;
  uniqueId?: InputMaybe<Scalars['String']>;
};

export type ExternalMappingPayload = {
  __typename?: 'ExternalMappingPayload';
  externalMapping?: Maybe<ExternalMappingType>;
};

export type ExternalMappingType = {
  __typename?: 'ExternalMappingType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  referredBy: Array<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** unique id of the external entity */
  uniqueId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ExternalMappingsFilters = {
  externalMappingIds?: InputMaybe<Array<Scalars['String']>>;
  uniqueIds?: InputMaybe<Array<Scalars['String']>>;
};

/** External Performance Type to store performance done on other platforms */
export type ExternalPerformance = {
  __typename?: 'ExternalPerformance';
  accuracy: Scalars['Float'];
  confidence: Scalars['Float'];
  minutesSpent: Scalars['Float'];
  progress: Scalars['Float'];
  /** stores all the scores of user */
  scores: Array<ExternalPerformanceScore>;
  /** status on other platform */
  status: Scalars['String'];
  /** Type of externalPerformance */
  type: ExternalPerformanceTypeEnum;
};

/** External Performance Type to store performance done on other platforms */
export type ExternalPerformanceInput = {
  accuracy: Scalars['Float'];
  confidence: Scalars['Float'];
  minutesSpent: Scalars['Float'];
  progress: Scalars['Float'];
  /** stores all the scores of user */
  scores: Array<ExternalPerformanceScoreInput>;
  /** status on other platform */
  status: Scalars['String'];
  /** Type of externalPerformance */
  type: ExternalPerformanceTypeEnum;
};

/** External Performance Score to store score computed on other platforms */
export type ExternalPerformanceScore = {
  __typename?: 'ExternalPerformanceScore';
  /** accuracy by which score is calculated */
  accuracy?: Maybe<Scalars['Float']>;
  /** confidence by which score is calculated */
  confidence?: Maybe<Scalars['Float']>;
  /** Language in which this performance was done */
  language?: Maybe<Scalars['String']>;
  /** minutes spent in doing this course in this language */
  minutesSpent?: Maybe<Scalars['Float']>;
  /** progress in course */
  progress?: Maybe<Scalars['Float']>;
  /** Language in which this performance was done */
  status?: Maybe<Scalars['String']>;
  videosWatched?: Maybe<Scalars['Float']>;
};

/** External Performance Score to store score computed on other platforms */
export type ExternalPerformanceScoreInput = {
  /** accuracy by which score is calculated */
  accuracy?: InputMaybe<Scalars['Float']>;
  /** confidence by which score is calculated */
  confidence?: InputMaybe<Scalars['Float']>;
  /** Language in which this performance was done */
  language?: InputMaybe<Scalars['String']>;
  /** minutes spent in doing this course in this language */
  minutesSpent?: InputMaybe<Scalars['Float']>;
  /** progress in course */
  progress?: InputMaybe<Scalars['Float']>;
  /** Language in which this performance was done */
  status?: InputMaybe<Scalars['String']>;
  videosWatched?: InputMaybe<Scalars['Float']>;
};

export enum ExternalPerformanceTypeEnum {
  SecureCodeWarrior = 'SECURE_CODE_WARRIOR',
}

/** If this resource is linked with some external resource */
export type ExternalResourceInfo = {
  __typename?: 'ExternalResourceInfo';
  /** Unique Id to identify resource  */
  resourceId: Scalars['String'];
};

/** If this resource is linked with some external resource */
export type ExternalResourceInfoInput = {
  /** Unique Id to identify resource  */
  resourceId: Scalars['String'];
};

export type ExternalWidgetPreviewType = {
  __typename?: 'ExternalWidgetPreviewType';
  link?: Maybe<LinkType>;
  /** media */
  media?: Maybe<EntityMediaType>;
  type: WidgetTypeEnum;
};

export type ExternalWidgetPropsInput = {
  /** external link props for the widget */
  linkProps?: InputMaybe<LinkPropsInput>;
  /** media for the widget */
  media?: InputMaybe<CreateMediaInput>;
};

export type ExternalWidgetType = {
  __typename?: 'ExternalWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** external link props for the widget */
  link?: Maybe<LinkType>;
  /** media for the widget */
  media?: Maybe<EntityMediaType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** This is used for fill in the blanks */
export type FtbComponent = {
  __typename?: 'FTBComponent';
  distractors?: Maybe<Array<Distractor>>;
  id: Scalars['String'];
  isBlank: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

/** This is used for fill in the blanks */
export type FtbComponentInput = {
  distractors?: InputMaybe<Array<DistractorInput>>;
  id?: InputMaybe<Scalars['String']>;
  isBlank: Scalars['Boolean'];
  text?: InputMaybe<Scalars['String']>;
};

export type FtbComponentsSetInput = {
  /** FTB component */
  set: Array<FtbComponentInput>;
};

/** Used when response type is Fill in the blank  */
export type FtbResponse = {
  __typename?: 'FTBResponse';
  blankId: Scalars['String'];
  text: Scalars['String'];
};

/** Used when response type is Fill in the blank  */
export type FtbResponseInput = {
  blankId: Scalars['String'];
  text: Scalars['String'];
};

export type FacetApplyOnType = {
  __typename?: 'FacetApplyOnType';
  field: Scalars['String'];
  model: FacetModelEnum;
};

/** configurable facets defined on this widget */
export type FacetConfig = {
  __typename?: 'FacetConfig';
  /** filter facets */
  filterFacets?: Maybe<Array<FilterFacetUnion>>;
  /** search facet */
  searchFacet?: Maybe<SearchFacetType>;
  /** sort facet */
  sortFacet?: Maybe<SortFacetType>;
};

export enum FacetModelEnum {
  Content = 'CONTENT',
}

export enum FacetTypeEnum {
  MultiSelect = 'MULTI_SELECT',
  Search = 'SEARCH',
  SingleSelect = 'SINGLE_SELECT',
  Sort = 'SORT',
}

export type FacetValueInput = {
  id: Scalars['String'];
  values: Array<Scalars['String']>;
};

/** FeatureFlagInput */
export type FeatureFlagInput = {
  name: Scalars['String'];
};

/** FeatureFlagPayload */
export type FeatureFlagPayload = {
  __typename?: 'FeatureFlagPayload';
  featureFlag?: Maybe<FeatureFlagType>;
};

/** Feature Flag Type */
export type FeatureFlagType = {
  __typename?: 'FeatureFlagType';
  name: Scalars['String'];
  /** Type of FlagValue */
  type: FlagValueTypeEnum;
  value: Scalars['String'];
};

/** FeatureFlagsInput */
export type FeatureFlagsInput = {
  names: Array<Scalars['String']>;
};

/** FeatureFlagsPayload */
export type FeatureFlagsPayload = {
  __typename?: 'FeatureFlagsPayload';
  featureFlags: Array<FeatureFlagType>;
};

export type FeatureNudge = {
  __typename?: 'FeatureNudge';
  feature: FeatureNudgeEnum;
  nudge: Scalars['Boolean'];
};

export enum FeatureNudgeEnum {
  Habit = 'HABIT',
  LearningContent = 'LEARNING_CONTENT',
  Meeting = 'MEETING',
  Okr = 'OKR',
  OneOnOne = 'ONE_ON_ONE',
  PeerFeedback = 'PEER_FEEDBACK',
  Praise = 'PRAISE',
  Session = 'SESSION',
  Task = 'TASK',
}

export type FeatureNudgeWidgetType = {
  __typename?: 'FeatureNudgeWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  featureNudgeFilters: Array<FeatureNudgeEnum>;
  /** feature nudges */
  featureNudges: Array<FeatureNudge>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type FeaturedPerformancesSubscriptionInput = {
  /** entityIds of performance */
  entityIds: Array<Scalars['String']>;
  /** operation on which to subscribe */
  operation: PerformanceSubscriptionOperationEnum;
};

export type FeaturedPerformancesSubscriptionPayload = {
  __typename?: 'FeaturedPerformancesSubscriptionPayload';
  operation: PerformanceSubscriptionOperationEnum;
  performances?: Maybe<Array<ContentPerformanceEntity>>;
};

export type FeedbackMetadataInput = {
  sessionId?: InputMaybe<Scalars['String']>;
};

export type FeedbackMetadataType = {
  __typename?: 'FeedbackMetadataType';
  sessionId?: Maybe<Scalars['String']>;
};

/** Response received on createFeedback/updateFeedback mutation */
export type FeedbackPayload = {
  __typename?: 'FeedbackPayload';
  /** Feedback document */
  feedback?: Maybe<FeedbackType>;
};

/** Filters for Feedback Questions */
export type FeedbackQuestionFilter = {
  questionIds?: InputMaybe<Array<Scalars['String']>>;
  scope: FeedbackQuestionScopeEnum;
  /** Type of Feedback Question to fetch */
  types?: InputMaybe<Array<FeedbackQuestionTypeEnum>>;
};

/** Response received on createFeedbackQuestion/updateFeedbackQuestion mutation */
export type FeedbackQuestionPayload = {
  __typename?: 'FeedbackQuestionPayload';
  /** Feedback Question document */
  feedbackQuestion?: Maybe<FeedbackQuestionType>;
};

export enum FeedbackQuestionScopeEnum {
  Global = 'GLOBAL',
  Workspace = 'WORKSPACE',
}

/** Feedback Question */
export type FeedbackQuestionType = {
  __typename?: 'FeedbackQuestionType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Description */
  description: Scalars['String'];
  id: Scalars['String'];
  /** Questions */
  questions: Array<QuestionType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Type of Question */
  type: FeedbackQuestionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum FeedbackQuestionTypeEnum {
  ArticleQuality = 'ARTICLE_QUALITY',
  AudioQuality = 'AUDIO_QUALITY',
  CourseQuality = 'COURSE_QUALITY',
  Custom = 'CUSTOM',
  ExerciseQuality = 'EXERCISE_QUALITY',
  GenerativeTaskQuality = 'GENERATIVE_TASK_QUALITY',
  LessonQuality = 'LESSON_QUALITY',
  PathQuality = 'PATH_QUALITY',
  QuizQuality = 'QUIZ_QUALITY',
  VideoQuality = 'VIDEO_QUALITY',
}

/** Feedback Questions returned by query */
export type FeedbackQuestionsPayload = {
  __typename?: 'FeedbackQuestionsPayload';
  feedbackQuestions: Array<FeedbackQuestionType>;
};

/** Feedback Response Input */
export type FeedbackResponseInput = {
  /** Id of corresponding feedback question */
  questionId: Scalars['String'];
  /** Response to the question */
  response: Scalars['String'];
};

/** Feedback Response Type */
export type FeedbackResponseType = {
  __typename?: 'FeedbackResponseType';
  /** Id of corresponding feedback question */
  questionId: Scalars['String'];
  /** Response to the question */
  response: Scalars['String'];
};

export enum FeedbackResponseTypeEnum {
  Sticker = 'STICKER',
  Text = 'TEXT',
}

/** Feedback */
export type FeedbackType = {
  __typename?: 'FeedbackType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Entity Id */
  entityId: Scalars['String'];
  /** Type of Entity */
  entityType: EntityTypeEnum;
  id: Scalars['String'];
  metadata?: Maybe<FeedbackMetadataType>;
  questionScope?: Maybe<FeedbackQuestionScopeEnum>;
  /** Response */
  responses: Array<FeedbackResponseType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** User Id */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type FieldMappingInput = {
  /** field name of lyearn schema */
  lyearnFieldName?: InputMaybe<Scalars['String']>;
  /** if permission to modify the mapping */
  selectionAvailable: Scalars['Boolean'];
  /** field name of source schema */
  sourceFieldName: Scalars['String'];
  /** if source field is mapped with lyearn tag */
  tagId?: InputMaybe<Scalars['String']>;
};

/** field mapping from source schema to lyearn schema */
export type FieldMappingType = {
  __typename?: 'FieldMappingType';
  /** field name of lyearn schema */
  lyearnFieldName?: Maybe<Scalars['String']>;
  /** if permission to modify the mapping */
  selectionAvailable: Scalars['Boolean'];
  /** field name of source schema */
  sourceFieldName: Scalars['String'];
  /** if source field is mapped with lyearn tag */
  tagId?: Maybe<Scalars['String']>;
};

export type FileAttachmentCountPayload = {
  __typename?: 'FileAttachmentCountPayload';
  attachmentCount?: Maybe<FileAttachmentCountType>;
  fileId: Scalars['String'];
};

/** Information regarding counts of entities to which this file is associated */
export type FileAttachmentCountType = {
  __typename?: 'FileAttachmentCountType';
  contentCount?: Maybe<Scalars['Float']>;
};

export type FileCategoriesFilter = {
  categoryIds: Array<Scalars['String']>;
  /** if null undefined, it will be considered false */
  includeSubtree?: InputMaybe<Scalars['Boolean']>;
};

export enum FileCategoryEnum {
  File = 'FILE',
  Folder = 'FOLDER',
}

export enum FileContentTypeEnum {
  Okr = 'OKR',
  Task = 'TASK',
}

/** Edge */
export type FileEdge = {
  __typename?: 'FileEdge';
  node: FileSchema;
};

export type FileFilters = {
  fileId: Scalars['String'];
};

export type FilePayload = {
  __typename?: 'FilePayload';
  file?: Maybe<FileSchema>;
};

export enum FilePurposeEnum {
  LibraryCategory = 'LIBRARY_CATEGORY',
  TaskCategory = 'TASK_CATEGORY',
}

export type FileSchema = {
  __typename?: 'FileSchema';
  /** files or folder */
  category: FileCategoryEnum;
  /** ordered list of children ids */
  childrenIds?: Maybe<Array<Scalars['String']>>;
  /** Count of content associated with content */
  contentCount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Filter query for system generated files */
  filterQuery?: Maybe<Scalars['String']>;
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String'];
  /** contains type of file */
  includes?: Maybe<Array<FileContentTypeEnum>>;
  jiraProjectStatusesMap?: Maybe<Array<JiraIntegrationProjectStatusesConfig>>;
  jiraTaskProjectStatusesMap?: Maybe<Array<EntityJiraProjectStatuesConfig>>;
  meta?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Location under which folders/file will be searched */
  path?: Maybe<Scalars['String']>;
  /** contains usage of file as what category, if Category it defines that it is used as category file. */
  purpose?: Maybe<FilePurposeEnum>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated?: Maybe<Scalars['Boolean']>;
  taskConfig?: Maybe<TaskConfigSchema>;
  /** Count of task inside file */
  taskCount?: Maybe<Scalars['Float']>;
  /** config for creating actionItem using externally linked entities */
  taskCreationConfigs?: Maybe<Array<OkrTaskCreationConfig>>;
  taskStatuses?: Maybe<Array<StatusType>>;
  /** config for maintaining the status of sync jobs for action items */
  taskSyncConfig?: Maybe<SyncConfig>;
  taskViewConfigs?: Maybe<Array<UserViewFieldSchema>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  viewConfig?: Maybe<EntityViewConfigSchema>;
  visibilitySettings: VisibilitySettingsType;
};

export type FileSubtreeSequenceType = {
  __typename?: 'FileSubtreeSequenceType';
  childrenFileIds: Array<Scalars['String']>;
  fileId: Scalars['String'];
};

export type FilesFilters = {
  /** fetches all files which have these ids as children */
  childrenIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  crossSubWorkspace?: InputMaybe<Scalars['Boolean']>;
  fileIds?: InputMaybe<Array<Scalars['String']>>;
  generatedByFilterType?: InputMaybe<GeneratedByEnum>;
  name?: InputMaybe<Scalars['String']>;
  /** fetches all files which are children of given parentIds */
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  /** contains usage of file as what category, if Category it defines that it is used as category file. */
  purpose?: InputMaybe<Array<FilePurposeEnum>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  systemGenerated?: InputMaybe<Scalars['Boolean']>;
  taskCreationConfigExists?: InputMaybe<Scalars['Boolean']>;
};

/** Defines filtering criteria for a particular field  */
export type FilterD1InputType = {
  /** filters sub group */
  filters?: InputMaybe<Array<FilterD2InputType>>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** property to filter on */
  property?: InputMaybe<FilterPropertyInputType>;
  /** Datatype of the "value" field */
  typeCast?: InputMaybe<TypeCastEnum>;
  /** Value to be filtered with */
  value?: InputMaybe<Scalars['String']>;
};

/** Defines filtering criteria for a particular field  */
export type FilterD1Type = {
  __typename?: 'FilterD1Type';
  /** filters sub group */
  filters: Array<FilterD2Type>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** property to filter on */
  property?: Maybe<FilterPropertyType>;
  /** Datatype of the "value" field */
  typeCast?: Maybe<TypeCastEnum>;
  /** Value to be filtered with */
  value?: Maybe<Scalars['String']>;
};

/** Defines filtering over entity */
export type FilterD2InputType = {
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** property to filter on */
  property: FilterPropertyInputType;
  /** Datatype of the "value" field */
  typeCast?: InputMaybe<TypeCastEnum>;
  /** Value to be filtered with */
  value: Scalars['String'];
};

/** Defines filtering over entity */
export type FilterD2Type = {
  __typename?: 'FilterD2Type';
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** property to filter on */
  property: FilterPropertyType;
  /** Datatype of the "value" field */
  typeCast?: Maybe<TypeCastEnum>;
  /** Value to be filtered with */
  value: Scalars['String'];
};

/** union of all filter facets */
export type FilterFacetUnion = MultiSelectFacetType | SingleSelectFacetType;

/** Defines complex  view filter */
export type FilterGroupInput = {
  filters: Array<FiltersInput>;
  operator: FilterOperatorEnum;
};

/** Defines filtering over entity */
export type FilterGroupType = {
  __typename?: 'FilterGroupType';
  /** Array of simple filter queries */
  filters: Array<FilterType>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
};

/** Defines filtering over entity */
export type FilterGroupTypeFiltersArgs = {
  input?: InputMaybe<FilterInputType>;
};

/** Defines filtering over entity */
export type FilterGroupV2InputType = {
  /** Array of simple filter queries */
  filters: Array<FilterD1InputType>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
};

/** Defines filtering over entity */
export type FilterGroupV2Type = {
  __typename?: 'FilterGroupV2Type';
  /** Array of simple filter queries */
  filters: Array<FilterD1Type>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
};

export type FilterInputType = {
  /** Will replace given meetingId in place of the string `meetingId` */
  meetingIds?: InputMaybe<Array<Scalars['String']>>;
  /** Will replace given metricIds in place of the string `metricIds` */
  metricIds?: InputMaybe<Array<Scalars['String']>>;
  /** Will replace given reviewCycleIds in place of the string `reviewCycleIds` */
  reviewCycleIds?: InputMaybe<Array<Scalars['String']>>;
  /** Will replace given userIds in place of the string `userIds` */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum FilterOperatorEnum {
  And = 'AND',
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  ContainsNot = 'CONTAINS_NOT',
  DateRange = 'DATE_RANGE',
  ElemMatch = 'ELEM_MATCH',
  EndsWith = 'ENDS_WITH',
  Equal = 'EQUAL',
  Exists = 'EXISTS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEqual = 'GREATER_THAN_EQUAL',
  In = 'IN',
  Is = 'IS',
  IsEmpty = 'IS_EMPTY',
  IsNot = 'IS_NOT',
  IsNotEmpty = 'IS_NOT_EMPTY',
  LessThan = 'LESS_THAN',
  LessThanEqual = 'LESS_THAN_EQUAL',
  NotEqual = 'NOT_EQUAL',
  NotIn = 'NOT_IN',
  Or = 'OR',
  Range = 'RANGE',
  Regex = 'REGEX',
  StartsWith = 'STARTS_WITH',
}

export type FilterPropertyInputType = {
  /** type of property */
  type: FilterPropertyTypeEnum;
  /** property identifier */
  value: Scalars['String'];
};

export type FilterPropertyType = {
  __typename?: 'FilterPropertyType';
  tag?: Maybe<TagUnion>;
  /** type of property */
  type: FilterPropertyTypeEnum;
  /** property identifier */
  value: Scalars['String'];
};

export enum FilterPropertyTypeEnum {
  Field = 'FIELD',
  Tag = 'TAG',
}

/** Defines filtering criteria for a particular field  */
export type FilterType = {
  __typename?: 'FilterType';
  /** filters sub group */
  filters: Array<SubFiltersType>;
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** Field Id */
  property?: Maybe<Scalars['String']>;
  /** Datatype of the "value" field */
  typeCast?: Maybe<TypeCastEnum>;
  /** Value to be filtered with */
  value?: Maybe<Scalars['String']>;
  /** Value to be parsed and replaced with actual value */
  valueToParse?: Maybe<Scalars['String']>;
};

/** Defines view filter for a particular field */
export type FiltersInput = {
  filters?: InputMaybe<Array<SubFiltersInput>>;
  operator: FilterOperatorEnum;
  property?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** This is the input for Assignment Evaluation */
export type FinalizeAssignmentSubmissionsInput = {
  /** It denotes, quizId for which submission/revaluation taking place */
  entityId: Scalars['String'];
  userId: Scalars['String'];
};

/** Make Submission Response */
export type FinalizeAssignmentSubmissionsPayload = {
  __typename?: 'FinalizeAssignmentSubmissionsPayload';
  performances: Array<HomeworkPerformanceType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type FlagConfigurations = {
  isFlagged: Scalars['Boolean'];
  userId: Scalars['String'];
};

export enum FlagValueTypeEnum {
  Bool = 'BOOL',
  Number = 'NUMBER',
  Object = 'OBJECT',
  String = 'STRING',
}

/** ForceCompleteContentProgressInput! */
export type ForceCompleteContentProgressInput = {
  entityIds: Array<Scalars['String']>;
  entityType?: InputMaybe<PerformanceEntityTypeEnum>;
  parentEntityId?: InputMaybe<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

/** Force complete content payload */
export type ForceCompleteContentProgressPayload = {
  __typename?: 'ForceCompleteContentProgressPayload';
  jobScheduleId: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
};

export enum FormComponentFieldTypeEnum {
  Email = 'EMAIL',
  Multiline = 'MULTILINE',
  Password = 'PASSWORD',
  Text = 'TEXT',
  Url = 'URL',
}

export type FormComponentType = {
  __typename?: 'FormComponentType';
  label: Scalars['String'];
  name: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  type: FormComponentFieldTypeEnum;
};

/** Resource Media Formats */
export type FormattedResourceMediaInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

/** Resource Media Formats */
export type FormattedResourceMediaType = {
  __typename?: 'FormattedResourceMediaType';
  name: Scalars['String'];
  url: Scalars['String'];
};

/** General Setting Parameters */
export type GeneralSettings = {
  /** Product categories */
  category: Array<Scalars['String']>;
  /** Product has certificate rewarded on completion true or false */
  certificateOnCompletion: Scalars['Boolean'];
  /** Product is best seller true or false */
  isBestSeller: Scalars['Boolean'];
  /** Product label */
  label?: InputMaybe<Scalars['String']>;
};

/** General Setting Parameters */
export type GeneralSettingsType = {
  __typename?: 'GeneralSettingsType';
  /** Product categories */
  category: Array<Scalars['String']>;
  /** Product has certificate rewarded on completion true or false */
  certificateOnCompletion: Scalars['Boolean'];
  /** Product is best seller true or false */
  isBestSeller: Scalars['Boolean'];
  /** Product Label */
  label?: Maybe<Scalars['String']>;
};

export type GenerateArticleFromTopicInput = {
  context?: InputMaybe<Scalars['String']>;
  topicName: Scalars['String'];
};

export type GenerateArticleFromTopicInputPropsType = {
  __typename?: 'GenerateArticleFromTopicInputPropsType';
  context?: Maybe<Scalars['String']>;
  topicName: Scalars['String'];
};

export type GenerateChildKRsFromOkrInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GenerateChildKRsFromOkrInputPropsType = {
  __typename?: 'GenerateChildKRsFromOKRInputPropsType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GenerateChildObjectivesFromOkrInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GenerateChildObjectivesFromOkrInputPropsType = {
  __typename?: 'GenerateChildObjectivesFromOKRInputPropsType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GenerateCourseOutlineFromTopicInput = {
  context?: InputMaybe<Scalars['String']>;
  topicName: Scalars['String'];
};

export type GenerateCourseOutlineFromTopicInputPropsType = {
  __typename?: 'GenerateCourseOutlineFromTopicInputPropsType';
  context?: Maybe<Scalars['String']>;
  topicName: Scalars['String'];
};

export type GenerateLearnerLinkInput = {
  action: GenerateLinkActionEnum;
  userId: Scalars['String'];
};

export type GenerateLearnerLinkPayload = {
  __typename?: 'GenerateLearnerLinkPayload';
  link: Scalars['String'];
};

export enum GenerateLinkActionEnum {
  ForgotPassword = 'FORGOT_PASSWORD',
}

export type GenerateMeetingInstanceSummaryInputPropsType = {
  __typename?: 'GenerateMeetingInstanceSummaryInputPropsType';
  agendaItems?: Maybe<Array<SummaryAgendaItemsInput>>;
};

export type GenerateMindsetNameAndDescriptionInput = {
  description?: InputMaybe<Scalars['String']>;
  trackName: Scalars['String'];
};

export type GenerateMindsetNameAndDescriptionInputPropsType = {
  __typename?: 'GenerateMindsetNameAndDescriptionInputPropsType';
  description?: Maybe<Scalars['String']>;
  trackName: Scalars['String'];
};

export type GenerateMindsetRubricsInput = {
  mindsetDescription: Scalars['String'];
  mindsetName: Scalars['String'];
  trackName: Scalars['String'];
};

export type GenerateMindsetRubricsInputPropsType = {
  __typename?: 'GenerateMindsetRubricsInputPropsType';
  mindsetDescription: Scalars['String'];
  mindsetName: Scalars['String'];
  trackName: Scalars['String'];
};

export type GenerateOkrTemplatesInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GenerateOkrTemplatesInputPropsType = {
  __typename?: 'GenerateOKRTemplatesInputPropsType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GenerateOptionsFromQuizQuestionInput = {
  correctOptions?: InputMaybe<Array<Scalars['String']>>;
  incorrectOptions?: InputMaybe<Array<Scalars['String']>>;
  question: Scalars['String'];
};

export type GenerateOptionsFromQuizQuestionInputPropsType = {
  __typename?: 'GenerateOptionsFromQuizQuestionInputPropsType';
  correctOptions?: Maybe<Array<Scalars['String']>>;
  incorrectOptions?: Maybe<Array<Scalars['String']>>;
  question: Scalars['String'];
};

export type GenerateQuizFromTopicInput = {
  difficultyLevel?: InputMaybe<DifficultyEnum>;
  numberOfQuestions?: InputMaybe<QuizNumberOfQuestionsEnum>;
  topicName: Scalars['String'];
};

export type GenerateQuizFromTopicInputPropsType = {
  __typename?: 'GenerateQuizFromTopicInputPropsType';
  difficultyLevel?: Maybe<DifficultyEnum>;
  numberOfQuestions?: Maybe<QuizNumberOfQuestionsEnum>;
  topicName: Scalars['String'];
};

export type GenerateReportingQueriesInput = {
  text: Scalars['String'];
};

export type GenerateReportingQueriesInputPropsType = {
  __typename?: 'GenerateReportingQueriesInputPropsType';
  text: Scalars['String'];
};

export type GenerateSkillNameAndDescriptionInput = {
  description?: InputMaybe<Scalars['String']>;
  skillGroup: Scalars['String'];
  trackName: Scalars['String'];
};

export type GenerateSkillNameAndDescriptionInputPropsType = {
  __typename?: 'GenerateSkillNameAndDescriptionInputPropsType';
  description?: Maybe<Scalars['String']>;
  skillGroup: Scalars['String'];
  trackName: Scalars['String'];
};

export type GenerateSkillRubricsInput = {
  skillDescription: Scalars['String'];
  skillGroup: Scalars['String'];
  skillName: Scalars['String'];
  trackName: Scalars['String'];
};

export type GenerateSkillRubricsInputPropsType = {
  __typename?: 'GenerateSkillRubricsInputPropsType';
  skillDescription: Scalars['String'];
  skillGroup: Scalars['String'];
  skillName: Scalars['String'];
  trackName: Scalars['String'];
};

export type GenerateSummaryAgendaItemsInput = {
  actionItems?: InputMaybe<Array<AgendaActionItemsInput>>;
  instructionText?: InputMaybe<Scalars['String']>;
  okrs?: InputMaybe<Array<AgendaOkRsInput>>;
  title: Scalars['String'];
};

export type GenerateTasksFromKrInput = {
  text: Scalars['String'];
};

export type GenerateTasksFromKrInputPropsType = {
  __typename?: 'GenerateTasksFromKRInputPropsType';
  text: Scalars['String'];
};

export type GenerateTasksFromPeerFeedbackInput = {
  text: Scalars['String'];
};

export type GenerateTasksFromPeerFeedbackInputPropsType = {
  __typename?: 'GenerateTasksFromPeerFeedbackInputPropsType';
  text: Scalars['String'];
};

export enum GeneratedByEnum {
  All = 'ALL',
  SystemGenerated = 'SYSTEM_GENERATED',
  UserGenerated = 'USER_GENERATED',
}

/** Edge */
export type GenerativeTaskEdge = {
  __typename?: 'GenerativeTaskEdge';
  node: GenerativeTaskType;
};

export enum GenerativeTaskErrorCodeEnum {
  InternalError = 'INTERNAL_ERROR',
  TokenLimitExceeded = 'TOKEN_LIMIT_EXCEEDED',
}

export type GenerativeTaskErrorType = {
  __typename?: 'GenerativeTaskErrorType';
  errorCode: GenerativeTaskErrorCodeEnum;
  message?: Maybe<Scalars['String']>;
};

export type GenerativeTaskFilters = {
  generativeTaskId: Scalars['String'];
};

export type GenerativeTaskInputPropsType = {
  __typename?: 'GenerativeTaskInputPropsType';
  articleFromTopic?: Maybe<GenerateArticleFromTopicInputPropsType>;
  childKRsFromOKR?: Maybe<GenerateChildKRsFromOkrInputPropsType>;
  childObjectivesFromOKR?: Maybe<GenerateChildObjectivesFromOkrInputPropsType>;
  courseOutlineFromTopic?: Maybe<GenerateCourseOutlineFromTopicInputPropsType>;
  mindsetNameAndDescription?: Maybe<GenerateMindsetNameAndDescriptionInputPropsType>;
  mindsetRubricsFromMindsetNameAndDescription?: Maybe<GenerateMindsetRubricsInputPropsType>;
  okrTemplates?: Maybe<GenerateOkrTemplatesInputPropsType>;
  optionsFromQuizQuestion?: Maybe<GenerateOptionsFromQuizQuestionInputPropsType>;
  quizFromTopic?: Maybe<GenerateQuizFromTopicInputPropsType>;
  reportingQueries?: Maybe<GenerateReportingQueriesInputPropsType>;
  skillNameAndDescription?: Maybe<GenerateSkillNameAndDescriptionInputPropsType>;
  skillRubricsFromSkillNameAndDescription?: Maybe<GenerateSkillRubricsInputPropsType>;
  summaryFromMeetingInstance?: Maybe<GenerateMeetingInstanceSummaryInputPropsType>;
  tasksFromKR?: Maybe<GenerateTasksFromKrInputPropsType>;
  tasksFromPeerFeedback?: Maybe<GenerateTasksFromPeerFeedbackInputPropsType>;
  transformText?: Maybe<TransformTextInputPropsType>;
};

export type GenerativeTaskPayload = {
  __typename?: 'GenerativeTaskPayload';
  generativeTask?: Maybe<GenerativeTaskType>;
};

export type GenerativeTaskPropsInput = {
  articleFromTopic?: InputMaybe<GenerateArticleFromTopicInput>;
  childKRsFromOKR?: InputMaybe<GenerateChildKRsFromOkrInput>;
  childObjectivesFromOKR?: InputMaybe<GenerateChildObjectivesFromOkrInput>;
  courseOutlineFromTopic?: InputMaybe<GenerateCourseOutlineFromTopicInput>;
  mindsetNameAndDescription?: InputMaybe<GenerateMindsetNameAndDescriptionInput>;
  mindsetRubricsFromMindsetNameAndDescription?: InputMaybe<GenerateMindsetRubricsInput>;
  okrTemplates?: InputMaybe<GenerateOkrTemplatesInput>;
  optionsFromQuizQuestion?: InputMaybe<GenerateOptionsFromQuizQuestionInput>;
  quizFromTopic?: InputMaybe<GenerateQuizFromTopicInput>;
  reportingQueries?: InputMaybe<GenerateReportingQueriesInput>;
  skillNameAndDescription?: InputMaybe<GenerateSkillNameAndDescriptionInput>;
  skillRubricsFromSkillNameAndDescription?: InputMaybe<GenerateSkillRubricsInput>;
  summaryFromMeetingInstance?: InputMaybe<MeetingInstanceSummaryInput>;
  tasksFromKR?: InputMaybe<GenerateTasksFromKrInput>;
  tasksFromPeerFeedback?: InputMaybe<GenerateTasksFromPeerFeedbackInput>;
  transformText?: InputMaybe<TransformTextInput>;
};

export type GenerativeTaskResultType = {
  __typename?: 'GenerativeTaskResultType';
  articleFromTopic?: Maybe<ArticleResultType>;
  childKRsFromOKR?: Maybe<ChildKRsFromOkrResultType>;
  childObjectivesFromOKR?: Maybe<ChildObjectivesFromOkrResultType>;
  courseOutlineFromTopic?: Maybe<CourseOutlineResultType>;
  mindsetNameAndDescription?: Maybe<SkillResultType>;
  mindsetRubricsFromMindsetNameAndDescription?: Maybe<SkillRubricsResultType>;
  okrTemplates?: Maybe<OkrTemplatesResultType>;
  optionsFromQuizQuestion?: Maybe<QuizQuestionOptionsResultType>;
  quizFromTopic?: Maybe<QuizResultType>;
  reportingQueries?: Maybe<ReportingQueriesResultType>;
  skillNameAndDescription?: Maybe<SkillResultType>;
  skillRubricsFromSkillNameAndDescription?: Maybe<SkillRubricsResultType>;
  summaryFromMeetingInstance?: Maybe<MeetingInstanceSummaryResultType>;
  tasksFromKR?: Maybe<TasksFromKrResultType>;
  tasksFromPeerFeedback?: Maybe<TasksFromPeerFeedbackResultType>;
  transformText?: Maybe<TransformTextResultType>;
};

export enum GenerativeTaskStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  ToBeProcessed = 'TO_BE_PROCESSED',
}

export type GenerativeTaskSubscriptionInput = {
  /** generativeTaskId to subscribe on */
  generativeTaskId: Scalars['String'];
};

export enum GenerativeTaskSubscriptionOperationEnum {
  GenerativeTaskUpdated = 'GENERATIVE_TASK_UPDATED',
}

export type GenerativeTaskSubscriptionPayload = {
  __typename?: 'GenerativeTaskSubscriptionPayload';
  generativeTask?: Maybe<GenerativeTaskType>;
  generativeTaskId: Scalars['String'];
  generativeTaskStatus: GenerativeTaskStatusEnum;
  operation: GenerativeTaskSubscriptionOperationEnum;
};

export type GenerativeTaskType = {
  __typename?: 'GenerativeTaskType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  error?: Maybe<GenerativeTaskErrorType>;
  generativeTaskContextId: Scalars['String'];
  id: Scalars['String'];
  input: GenerativeTaskInputPropsType;
  result: GenerativeTaskResultType;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: GenerativeTaskStatusEnum;
  type: GenerativeTaskTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum GenerativeTaskTypeEnum {
  ArticleFromTopic = 'ARTICLE_FROM_TOPIC',
  ChildKrsFromOkr = 'CHILD_KRS_FROM_OKR',
  ChildObjectivesFromOkr = 'CHILD_OBJECTIVES_FROM_OKR',
  CourseOutlineFromTopic = 'COURSE_OUTLINE_FROM_TOPIC',
  MindsetNameAndDescription = 'MINDSET_NAME_AND_DESCRIPTION',
  MindsetRubricsFromMindsetNameAndDescription = 'MINDSET_RUBRICS_FROM_MINDSET_NAME_AND_DESCRIPTION',
  OkrTemplates = 'OKR_TEMPLATES',
  OptionsFromQuizQuestion = 'OPTIONS_FROM_QUIZ_QUESTION',
  QuizFromTopic = 'QUIZ_FROM_TOPIC',
  ReportingQueries = 'REPORTING_QUERIES',
  SkillNameAndDescription = 'SKILL_NAME_AND_DESCRIPTION',
  SkillRubricsFromSkillNameAndDescription = 'SKILL_RUBRICS_FROM_SKILL_NAME_AND_DESCRIPTION',
  SummaryFromMeetingInstance = 'SUMMARY_FROM_MEETING_INSTANCE',
  TasksFromKr = 'TASKS_FROM_KR',
  TasksFromPeerFeedback = 'TASKS_FROM_PEER_FEEDBACK',
  TransformText = 'TRANSFORM_TEXT',
}

export type GenerativeTasksFilters = {
  generativeTaskIds?: InputMaybe<Array<Scalars['String']>>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  /** address line 1 */
  addressLine1?: Maybe<Scalars['String']>;
  /** address line 2 */
  addressLine2?: Maybe<Scalars['String']>;
  /**
   * custom label for longitude, latitude
   * @deprecated use addressLine 1
   */
  label?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GeoLocationInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  /** custom label for longitude, latitude */
  label?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GetAclEntriesInput = {
  /** entity type */
  entity: Scalars['String'];
  /** permission string */
  permission: Scalars['String'];
};

export type GetAclEntriesPayload = {
  __typename?: 'GetACLEntriesPayload';
  roleIds: Array<Scalars['String']>;
};

export type GetActionItemCountPayload = {
  __typename?: 'GetActionItemCountPayload';
  count: Scalars['Float'];
};

/** Applicable filters to get single block */
export type GetAllBlocksFilter = {
  /** Filter by blockIds */
  blockIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Response Object for the Batch Get Blocks */
export type GetAllBlocksPayload = {
  __typename?: 'GetAllBlocksPayload';
  /** Fetched Blocks for given filters */
  blocks?: Maybe<Array<BaseBlockType>>;
  /** FailedIds for which blocks was not fetched */
  failedIds?: Maybe<Array<Scalars['String']>>;
};

export type GetAllBookmarksPayload = {
  __typename?: 'GetAllBookmarksPayload';
  /** Fetched bookmark */
  bookmarks?: Maybe<Array<BookmarkType>>;
};

export type GetAllEventsLivelinessTimestampsInput = {
  eventIds: Array<Scalars['String']>;
};

export type GetAllEventsLivelinessTimestampsPayload = {
  __typename?: 'GetAllEventsLivelinessTimestampsPayload';
  eventLivelinessTimestamps: Array<EventLivelinessTimestamps>;
};

/** Response for Get All Question query */
export type GetAllQuestionsPayload = {
  __typename?: 'GetAllQuestionsPayload';
  questions?: Maybe<Array<QuestionType>>;
};

export type GetAllSignedUrlsInput = {
  signedUrlInputs: Array<GetSignedUrlInput>;
};

export type GetAllSignedUrlsPayload = {
  __typename?: 'GetAllSignedUrlsPayload';
  pathUrlPayloads: Array<PathUrlPayload>;
};

export type GetAllUserGroupsPayload = {
  __typename?: 'GetAllUserGroupsPayload';
  userGroups: Array<IntegrationUserGroupType>;
};

/** Get all the block upto the given level. */
export type GetBlockTreeFilters = {
  /** depth upto which the child blocks will be fetched */
  depth: Scalars['Float'];
  /** whether ref blocks should be expanded */
  followRefs?: InputMaybe<Scalars['Boolean']>;
  /** BlockId of the root block. */
  rootBlockId: Scalars['String'];
};

/** Response Object for Get Block Tree Payload */
export type GetBlockTreePayload = {
  __typename?: 'GetBlockTreePayload';
  /** Fetched Blocks for given rootId */
  blocks?: Maybe<Array<BaseBlockType>>;
};

export type GetBookmarkPayload = {
  __typename?: 'GetBookmarkPayload';
  /** Fetched bookmark */
  bookmark?: Maybe<BookmarkType>;
};

export type GetByIdFilter = {
  id: Scalars['String'];
  isEmbed?: InputMaybe<Scalars['Boolean']>;
};

export type GetCategoriesInput = {
  classroomId: Scalars['String'];
};

export type GetCertificateScoreInput = {
  entityId: Scalars['String'];
  includeExerciseScore: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type GetCertificateScoresInput = {
  requests: Array<GetCertificateScoreInput>;
};

/** Array of Score along with input details  */
export type GetCertificateScoresPayload = {
  __typename?: 'GetCertificateScoresPayload';
  scores: Array<CertificateScorePayload>;
};

export type GetChildOkRsFilters = {
  fetchAll?: InputMaybe<Scalars['Boolean']>;
  /** If no type given, then will fetch both Objective and key result which are direct children of given OKR */
  okrTypes?: InputMaybe<Array<OkrTypeEnum>>;
};

export type GetClassroomEventsFilters = {
  classroomId?: InputMaybe<Scalars['String']>;
  classroomIds?: InputMaybe<Array<Scalars['String']>>;
  eventScheduleId?: InputMaybe<Scalars['String']>;
  /** Array of two dates between which the eventStartTimestamp of event lies */
  eventStartTimestampRange?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** Array of two dates between which the metricsAggregationStartTimestamp of event lies */
  metricsAggregationStartTimestampRange?: InputMaybe<Array<Scalars['String']>>;
  metricsAggregationStatus?: InputMaybe<Array<ClassroomEventMetricsAggregationStatusEnum>>;
  recurrenceRuleId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<ClassroomEventStatusEnum>>;
  /** userIds to filter user events. */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type GetClassroomSettingInput = {
  classroomId: Scalars['String'];
};

export type GetCommunityInput = {
  /** Community Filters */
  filters: CommunityFilters;
};

export type GetCommunityPayload = {
  __typename?: 'GetCommunityPayload';
  /** Community Payload */
  community: CommunityType;
};

export type GetContentsProfileInput = {
  contentIds: Array<Scalars['String']>;
};

export type GetContentsProfilePayload = {
  __typename?: 'GetContentsProfilePayload';
  contentsProfile: Array<ContentProfile>;
};

export type GetConvertedTargetActivityMetricValue = {
  requiredFrequency: RelativeDateFilterUnitEnum;
};

export type GetEmbeddedContentsInput = {
  contentIds: Array<Scalars['String']>;
};

export type GetEmbeddedEntitiesInfoInput = {
  entities: Array<GetEmbeddedEntityInfo>;
};

export type GetEmbeddedEntityInfo = {
  entityId: Scalars['String'];
  type: ParentEntityTypeEnum;
};

export type GetEntitiesAttachedToCountInput = {
  skillId: Scalars['String'];
};

export type GetEntitiesAttachedToCountPayload = {
  __typename?: 'GetEntitiesAttachedToCountPayload';
  contentsCount: Scalars['Float'];
  skillMatricesCount: Scalars['Float'];
};

export type GetEntitiesAttachedToCountsInput = {
  skillIds: Array<Scalars['String']>;
};

export type GetEntitiesAttachedToCountsPayload = {
  __typename?: 'GetEntitiesAttachedToCountsPayload';
  skillEntitiesAttachedCounts: Array<SkillEntitiesAttachedToCount>;
};

/** filters to get users of event */
export type GetEventClassroomUsersFilters = {
  /** filter to fetch users of eventId */
  eventId: Scalars['String'];
};

export type GetEventScheduleUsersFilters = {
  eventScheduleId: Scalars['String'];
  eventScheduleUserRoles: Array<EventUserRoleEnum>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type GetEventSchedulesFilters = {
  classroomId?: InputMaybe<Scalars['String']>;
  endTimestamp?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  startTimestamp?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<EventScheduleStatusEnum>>;
};

/** Gets EventUserMetrics based on classroomId, eventIds, userIds, and eventStartTimestampRange */
export type GetEventUserMetricsFilters = {
  classroomId?: InputMaybe<Scalars['String']>;
  eventIds?: InputMaybe<Array<Scalars['String']>>;
  /** Array of two dates between which the eventStartTimestamp of event lies */
  eventStartTimestampRange?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type GetFileSubtreeSequencesInput = {
  fileIds: Array<Scalars['String']>;
};

export type GetFileSubtreeSequencesPayload = {
  __typename?: 'GetFileSubtreeSequencesPayload';
  fileSubtreeSequences: Array<FileSubtreeSequenceType>;
};

export type GetFilesAttachmentCountInput = {
  fileIds: Array<Scalars['String']>;
};

export type GetFilesAttachmentCountPayload = {
  __typename?: 'GetFilesAttachmentCountPayload';
  fileAttachmentCounts?: Maybe<Array<FileAttachmentCountPayload>>;
};

export type GetImportJobSampleCsvRowsInput = {
  purpose: JobPurposeEnum;
};

export type GetJobCsvHeadersInput = {
  purpose: JobPurposeEnum;
};

export type GetJobCsvHeadersPayload = {
  __typename?: 'GetJobCsvHeadersPayload';
  headers: Array<Scalars['String']>;
};

export type GetLatestUserReviewInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type GetLatestUserReviewPayload = {
  __typename?: 'GetLatestUserReviewPayload';
  latestUserReview?: Maybe<LatestUserReviewType>;
};

/** This returns  okrCycle if found */
export type GetOkrCyclePayload = {
  __typename?: 'GetOKRCyclePayload';
  cycle?: Maybe<OkrCycle>;
};

export type GetOkrFilters = {
  /** Returns OKRs which are deleted, non deleted or both */
  deleteStatus?: InputMaybe<DeletedFilterEnum>;
  okrId?: InputMaybe<Scalars['String']>;
};

export type GetOkRsFilters = {
  /** Returns OKRs whose ancestor is ancestorId in parent hierarchy */
  ancestorId?: InputMaybe<Scalars['String']>;
  cycleIds?: InputMaybe<Array<Scalars['String']>>;
  /** Returns OKRs which are deleted, non deleted or both */
  deleteStatus?: InputMaybe<DeletedFilterEnum>;
  dueDate?: InputMaybe<DateFilter>;
  /** filter by field and tag properties */
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  /** Returns OKRs with selected integration config */
  integrationConfig?: InputMaybe<OkrIntegrationConfigInput>;
  /** Get Team OKRs based on managers */
  managerIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  /** Returns OKRs which is not parent okr and does not have parent hierarchy */
  notInParentIdHierarchy?: InputMaybe<Scalars['String']>;
  /** Get okrs based on objective type */
  objectiveTypes?: InputMaybe<Array<ObjectiveTypeEnum>>;
  okrIds?: InputMaybe<Array<Scalars['String']>>;
  ownerIds?: InputMaybe<Array<Scalars['String']>>;
  parentId?: InputMaybe<Scalars['String']>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  /** Returns OKRs with selected progress measurement type */
  progressMeasurementTypes?: InputMaybe<Array<OkrProgressMeasurementTypeEnum>>;
  reporteeFilterType?: InputMaybe<ReporteeFilterTypeInput>;
  /** Returns Root/Top level OKRs */
  rootOKRs?: InputMaybe<Scalars['Boolean']>;
  showKeyResults?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<OkrStatusEnum>>;
  /** Get okrs based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  /** Get okrs based on tag Ids */
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  /** Returns OKRs with selected task creation config */
  taskCreationConfig?: InputMaybe<OkrTaskCreationConfigFilter>;
  /** Returns OKRs with if task creation config */
  taskCreationConfigExists?: InputMaybe<Scalars['Boolean']>;
  types?: InputMaybe<Array<OkrTypeEnum>>;
  viewType?: InputMaybe<OkrViewTypeEnum>;
};

export type GetOwnerIdsInput = {
  filters?: InputMaybe<Scalars['String']>;
};

export type GetOwnerIdsPayload = {
  __typename?: 'GetOwnerIdsPayload';
  ownerIds: Scalars['String'];
};

export type GetParentContentsInput = {
  id: Scalars['String'];
};

export type GetParentContentsPayload = {
  __typename?: 'GetParentContentsPayload';
  contents: Array<ContentEntity>;
  totalCount: Scalars['Int'];
};

export type GetPostsCountPayload = {
  __typename?: 'GetPostsCountPayload';
  count: Scalars['Float'];
};

export type GetProductFilters = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type GetProductsFilters = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  slugs?: InputMaybe<Array<Scalars['String']>>;
  status: Array<ProductStatusEnum>;
};

export type GetProviderFilters = {
  availableOn?: InputMaybe<ProviderAvailableOnEnum>;
  id?: InputMaybe<Scalars['String']>;
  integrationProviderId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  uniqueId?: InputMaybe<Scalars['String']>;
};

export type GetProvidersFilters = {
  availableOn?: InputMaybe<Array<ProviderAvailableOnEnum>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  integrationProviderIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  uniqueIds?: InputMaybe<Array<Scalars['String']>>;
};

export type GetReportPayload = {
  __typename?: 'GetReportPayload';
  report: ReportType;
};

export type GetResourceByIdFilter = {
  id: Scalars['String'];
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};

export type GetResourceUserInteractionInput = {
  resourceId: Scalars['String'];
  userId: Scalars['String'];
};

export type GetResourcesFilters = {
  /** Associate with classroom */
  classroomId?: InputMaybe<Scalars['String']>;
  /** Array of two dates between which the resource was created, [startTimeStamp, endTimeStamp] */
  createdAtTimeStampRange?: InputMaybe<Array<Scalars['String']>>;
  /** Tells whether it is a file or folder */
  kind?: InputMaybe<ResourceKindEnum>;
  /** name of the file/folder */
  name?: InputMaybe<Scalars['String']>;
  /** Associate with classroom */
  parentId?: InputMaybe<Scalars['String']>;
  /** Location under which folders/file will be searched */
  path?: InputMaybe<Scalars['String']>;
  /** Resource Types. Filter resource based on category */
  resourceCategories?: InputMaybe<Array<ResourceCategoryEnum>>;
  /** Filter resource based on resource ids */
  resourceIds?: InputMaybe<Array<Scalars['String']>>;
  /** Resource Status. Filter resources based on resource status */
  status?: InputMaybe<Array<ResourceStatusEnum>>;
};

export type GetReviewCycleInstancesPayload = {
  __typename?: 'GetReviewCycleInstancesPayload';
  /** Review Cycle Instance of current user */
  reviewCycleInstances?: Maybe<Array<ReviewCycleInstanceType>>;
};

export type GetScorecardReviewsFilters = {
  entityId?: InputMaybe<Scalars['String']>;
  /** ids of performanceInteractions */
  ids?: InputMaybe<Array<Scalars['String']>>;
  reviewCycleInstanceId?: InputMaybe<Scalars['String']>;
  reviewerId?: InputMaybe<Scalars['String']>;
  scorecardIds?: InputMaybe<Array<Scalars['String']>>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  submissionId?: InputMaybe<Scalars['String']>;
  submissionIds?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<PerformanceInteractionTypeEnum>;
  userId?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type GetSignedMediaUrlInput = {
  expiresInHours?: InputMaybe<Scalars['Float']>;
  host?: InputMaybe<Scalars['String']>;
  mediaId: Scalars['String'];
};

export type GetSignedUrlInput = {
  bucket: Scalars['String'];
  path: Scalars['String'];
};

export type GetSignedUrlPayload = {
  __typename?: 'GetSignedUrlPayload';
  url: Scalars['String'];
};

/** Total Skill reviews done by reviewer */
export type GetTotalSkillReviewDoneByReviewerPayload = {
  __typename?: 'GetTotalSkillReviewDoneByReviewerPayload';
  totalReviewsDone: Scalars['Float'];
};

/** Total Skill reviews to be done */
export type GetTotalSkillReviewsToBeDonePayload = {
  __typename?: 'GetTotalSkillReviewsToBeDonePayload';
  totalReviewsToBeDone: Scalars['Float'];
};

export type GetUrlPreviewsInput = {
  /** Urls for which one needs previews */
  urls: Array<Scalars['String']>;
};

export type GetUrlPreviewsPayload = {
  __typename?: 'GetUrlPreviewsPayload';
  previews: Array<Scalars['JSONObject']>;
};

export type GetUserClassroomPermissionPayload = {
  __typename?: 'GetUserClassroomPermissionPayload';
  /** Classroom permissions */
  permissions?: Maybe<Array<UserClassroomPermissionType>>;
};

export type GetUserEventPermissionPayload = {
  __typename?: 'GetUserEventPermissionPayload';
  /** Event permissions */
  permissions?: Maybe<Array<UserEventPermissionType>>;
};

export type GetUserGroupInput = {
  groupId: Scalars['String'];
  userId: Scalars['String'];
};

export type GetUserOkrCyclePermissionsPayload = {
  __typename?: 'GetUserOKRCyclePermissionsPayload';
  /** OKRCycle permissions */
  permissions: Array<UserOkrCyclePermissionType>;
};

export type GetUserOkrPermissionsPayload = {
  __typename?: 'GetUserOKRPermissionsPayload';
  /** Objective KeyResult Permissions */
  permissions: Array<UserOkrPermissionType>;
};

export type GetUserProductPermissionPayload = {
  __typename?: 'GetUserProductPermissionPayload';
  /** Product permissions */
  permissions?: Maybe<Array<UserProductPermissionType>>;
};

export type GetUserResourcePermissionPayload = {
  __typename?: 'GetUserResourcePermissionPayload';
  /** Resource permissions */
  permissions?: Maybe<Array<UserResourcePermissionType>>;
};

export type GetUserSkillSummaryPayload = {
  __typename?: 'GetUserSkillSummaryPayload';
  userSkillSummary?: Maybe<UserSkillSummary>;
};

export type GlobalSearchFilter = {
  contentIds?: InputMaybe<Array<Scalars['String']>>;
  contentTypes?: InputMaybe<Array<SearchableContentTypeEnum>>;
  fileCategoriesFilter?: InputMaybe<FileCategoriesFilter>;
  searchText?: InputMaybe<Scalars['String']>;
  sortFilters?: InputMaybe<GlobalSearchSortFilters>;
  status?: InputMaybe<Scalars['String']>;
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  widgetTypes?: InputMaybe<Array<SearchWidgetTypeEnum>>;
};

/** Response for Global Search Query */
export type GlobalSearchPayload = {
  __typename?: 'GlobalSearchPayload';
  contents?: Maybe<ContentConnection>;
  events?: Maybe<PaginatedEventsPayload>;
  lessons?: Maybe<BlockSearchConnections>;
};

/** Response for Global Search Query */
export type GlobalSearchPayloadContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

/** Response for Global Search Query */
export type GlobalSearchPayloadEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

/** Response for Global Search Query */
export type GlobalSearchPayloadLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type GlobalSearchSortFilters = {
  accessCountTimeRange?: InputMaybe<DateFilter>;
};

export type GlobalSearchWidgetType = {
  __typename?: 'GlobalSearchWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  contents: ContentConnection;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type GlobalSearchWidgetTypeContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GlobalSearchFilter>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type GoogleIntegrationSettingsInput = {
  archiveTerminatedUsers: Scalars['Boolean'];
  lastSyncedAt?: InputMaybe<Scalars['String']>;
  orgUnitPaths: Array<Scalars['String']>;
  syncJobId?: InputMaybe<Scalars['String']>;
  syncSchedule?: InputMaybe<ScheduleInfoInput>;
  tagMappings?: InputMaybe<Scalars['JSONObject']>;
  userGroupIds: Array<Scalars['String']>;
  usersImportType: UsersImportTypeEnum;
};

export type GoogleIntegrationSettingsType = {
  __typename?: 'GoogleIntegrationSettingsType';
  archiveTerminatedUsers: Scalars['Boolean'];
  lastSyncedAt?: Maybe<Scalars['String']>;
  orgUnitPaths: Array<Scalars['String']>;
  scheduledJob?: Maybe<ScheduledJobConfigs>;
  syncJobId?: Maybe<Scalars['String']>;
  syncSchedule?: Maybe<ScheduleInfo>;
  /** sync users job status */
  syncStatus?: Maybe<JobEntityStatusEnum>;
  tagMappings?: Maybe<Scalars['JSONObject']>;
  /** User selected group ids. Might contain stale ids. To get the latest count, use integrationGroupsCount field resolver */
  userGroupIds: Array<Scalars['String']>;
  usersImportType: UsersImportTypeEnum;
};

export type GradeBookSettingInput = {
  calculationMechanism: CalculationMechanismEnum;
  gradeType?: InputMaybe<ClassroomSettingsGradeTypeEnum>;
  /** Grading schema input type */
  gradingSchema?: InputMaybe<GradingSchemaInput>;
};

/** GradeBook settings type */
export type GradeBookSettingType = {
  __typename?: 'GradeBookSettingType';
  calculationMechanism: CalculationMechanismEnum;
  gradeType?: Maybe<ClassroomSettingsGradeTypeEnum>;
  /** Grading schema type */
  gradingSchema?: Maybe<GradingSchemaType>;
};

export enum GradeSchemaTemplateEnum {
  Alphanumeric = 'ALPHANUMERIC',
  Custom = 'CUSTOM',
  FiveLetter = 'FIVE_LETTER',
  PlusMinus = 'PLUS_MINUS',
}

export enum GradedByEnum {
  Peer = 'PEER',
  Teacher = 'TEACHER',
}

export type GradingSchemaInput = {
  /** Custom grading schema input type */
  customSchema?: InputMaybe<Array<CustomGradingSchemaInput>>;
  templateType: GradeSchemaTemplateEnum;
};

/** Grading Schema Type */
export type GradingSchemaType = {
  __typename?: 'GradingSchemaType';
  /** Custom grading schema type */
  customSchema?: Maybe<Array<CustomLetterGradingSchemaType>>;
  templateType: GradeSchemaTemplateEnum;
};

export enum GroupAdditionMethodEnum {
  All = 'ALL',
  Dynamic = 'DYNAMIC',
  Manual = 'MANUAL',
}

export enum GroupConditionOperator {
  And = 'AND',
  Or = 'OR',
}

/** This returns paginated Groups */
export type GroupConnection = {
  __typename?: 'GroupConnection';
  edges?: Maybe<Array<GroupEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type GroupEdge = {
  __typename?: 'GroupEdge';
  node: GroupUnion;
};

export type GroupFilters = {
  classroomId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<GroupStatusEnum>;
  systemGenerated?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<GroupTypeEnum>;
};

export type GroupLevelUserGroupFilter = {
  subTypes: Array<UserCollectionGroupTypeEnum>;
};

export enum GroupMemberExportFieldEnum {
  AutoSync = 'AUTO_SYNC',
  Emailid = 'EMAILID',
  GroupName = 'GROUP_NAME',
  UniqueId = 'UNIQUE_ID',
}

export type GroupMemberExportInputProps = {
  groupId: Scalars['String'];
  requiredFields: GroupMemberExportRequiredFieldsInput;
};

export type GroupMemberExportInputPropsType = {
  __typename?: 'GroupMemberExportInputPropsType';
  groupId: Scalars['String'];
  requiredFields: GroupMemberExportRequiredFieldsType;
};

export type GroupMemberExportMetadataType = {
  __typename?: 'GroupMemberExportMetadataType';
  exportedCount: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type GroupMemberExportRequiredFieldsInput = {
  fields: Array<GroupMemberExportFieldEnum>;
};

export type GroupMemberExportRequiredFieldsType = {
  __typename?: 'GroupMemberExportRequiredFieldsType';
  fields: Array<GroupMemberExportFieldEnum>;
};

export type GroupMembersIds = {
  set: Array<Scalars['String']>;
};

export type GroupPayload = {
  __typename?: 'GroupPayload';
  group: GroupUnion;
};

export type GroupPerformanceFilter = {
  entityId?: InputMaybe<Scalars['Boolean']>;
  parentEntityId?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Boolean']>;
};

/** Group Properties */
export type GroupProfile = {
  __typename?: 'GroupProfile';
  /** number of members in group */
  count: Scalars['Float'];
  /** group type */
  groupType: Scalars['String'];
  /** id of the Group */
  id: Scalars['String'];
  /** some members who need to be displayed */
  previewMemberIds: Array<Scalars['String']>;
};

export enum GroupQueryIntentEnum {
  Assign = 'ASSIGN',
  Read = 'READ',
}

export type GroupRoles = {
  __typename?: 'GroupRoles';
  groupId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
};

export type GroupRolesTypeInput = {
  groupId?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
};

export enum GroupStatusEnum {
  Archived = 'ARCHIVED',
  Published = 'PUBLISHED',
  Revoked = 'REVOKED',
}

export type GroupSubWorkspaceIds = {
  set: Array<Scalars['String']>;
};

export enum GroupTypeEnum {
  ContentCollection = 'CONTENT_COLLECTION',
  UserCollection = 'USER_COLLECTION',
}

/** union of all groups */
export type GroupUnion = ContentCollectionGroupType | UserCollectionGroupType;

export type GroupedActionItemEdge = {
  __typename?: 'GroupedActionItemEdge';
  groupByKey: Scalars['String'];
  groupedEntity: PaginatedActionItemsPayload;
};

export type GroupedActionItemsPayload = {
  __typename?: 'GroupedActionItemsPayload';
  groupedEdges: Array<GroupedActionItemEdge>;
  keys: Array<Scalars['String']>;
};

export type GroupedPayload = {
  __typename?: 'GroupedPayload';
  keys: Array<Scalars['String']>;
};

export type GroupsFilters = {
  classroomIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  crossSubWorkspace?: InputMaybe<Scalars['Boolean']>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  excludeReceivedGroups?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  intent?: InputMaybe<GroupQueryIntentEnum>;
  name?: InputMaybe<Scalars['String']>;
  /** filter groups by exact names, overrides `name` filter */
  names?: InputMaybe<Array<Scalars['String']>>;
  productIds?: InputMaybe<Array<Scalars['String']>>;
  properties?: InputMaybe<Array<Scalars['String']>>;
  shareable?: InputMaybe<Scalars['Boolean']>;
  sharedGroupFilters?: InputMaybe<SharedEntityFilters>;
  status?: InputMaybe<Array<GroupStatusEnum>>;
  subTypes?: InputMaybe<Array<UserCollectionGroupTypeEnum>>;
  systemGenerated?: InputMaybe<Scalars['Boolean']>;
  types?: InputMaybe<Array<GroupTypeEnum>>;
};

export type HabitReminderUserNotification = {
  __typename?: 'HabitReminderUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  habitId: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type HabitsWidgetType = {
  __typename?: 'HabitsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  /** scheduled habits */
  habits: PaginatedMetricsPayload;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type HabitsWidgetTypeHabitsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters: DateFilter;
  first?: InputMaybe<Scalars['Float']>;
};

export type HandleEventRecordingInput = {
  eventId: Scalars['String'];
};

/** It will resolve user homepage content */
export type Homepage =
  | HomepageBanner
  | HomepageOngoingLearnings
  | HomepageReviewCycle
  | HomepageToDoLearnings;

/** User Homepage Banner */
export type HomepageBanner = {
  __typename?: 'HomepageBanner';
  result: Array<BannerResultType>;
  type: HomepageTypeEnum;
};

/** User Homepage OngoingLearnings */
export type HomepageOngoingLearnings = {
  __typename?: 'HomepageOngoingLearnings';
  result: Array<OngoingResultType>;
  type: HomepageTypeEnum;
};

/** Review Cycle that is currently ongoing for the user */
export type HomepageReviewCycle = {
  __typename?: 'HomepageReviewCycle';
  result: Array<ReviewCycleResultType>;
  type: HomepageTypeEnum;
};

/** User Homepage HomepageToDoLearnings */
export type HomepageToDoLearnings = {
  __typename?: 'HomepageToDoLearnings';
  result: Array<ToDoLearningsType>;
  type: HomepageTypeEnum;
};

export type HomepageType = {
  result: HomepageTypeEnum;
  type: HomepageTypeEnum;
};

export enum HomepageTypeEnum {
  Banner = 'BANNER',
  OngoingLearnings = 'ONGOING_LEARNINGS',
  ReviewCycle = 'REVIEW_CYCLE',
  TodoLearnings = 'TODO_LEARNINGS',
}

export type HomeworkAdvanceSettingsInput = {
  allowComments: Scalars['Boolean'];
  allowLateCompletion: Scalars['Boolean'];
  allowLateSubmission: Scalars['Boolean'];
  allowMultipleSubmissions: Scalars['Boolean'];
  allowRetake: Scalars['Boolean'];
  allowSubmissionNote: Scalars['Boolean'];
  /** Word limit of submission if response type is text */
  wordLimit?: InputMaybe<Scalars['Float']>;
};

/** homework advanced settings */
export type HomeworkAdvanceSettingsType = {
  __typename?: 'HomeworkAdvanceSettingsType';
  allowComments?: Maybe<Scalars['Boolean']>;
  allowLateCompletion?: Maybe<Scalars['Boolean']>;
  allowLateSubmission?: Maybe<Scalars['Boolean']>;
  allowMultipleSubmissions?: Maybe<Scalars['Boolean']>;
  allowRetake?: Maybe<Scalars['Boolean']>;
  allowSubmissionNote?: Maybe<Scalars['Boolean']>;
  /** Word limit of submission if response type is text */
  wordLimit?: Maybe<Scalars['Float']>;
};

export type HomeworkEnrolmentSettingsInput = {
  /** Due Date for Homework */
  dueDate: Scalars['String'];
};

export type HomeworkGradeSettingsInput = {
  isGradable?: InputMaybe<Scalars['Boolean']>;
  maxScore: Scalars['Float'];
};

export type HomeworkInstructionInput = {
  /** Instruction Media */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Instruction Text */
  text?: InputMaybe<Scalars['String']>;
};

/** Instruction for Homework */
export type HomeworkInstructionType = {
  __typename?: 'HomeworkInstructionType';
  /** Instruction Media */
  medias: Array<EntityMediaType>;
  /** Instruction Text */
  text?: Maybe<Scalars['String']>;
};

export type HomeworkPerformanceType = {
  __typename?: 'HomeworkPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** Submission Feedback Discussion Id */
  feedbackChannelId?: Maybe<Scalars['String']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  gradedAt?: Maybe<Scalars['String']>;
  gradedById?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isFlagged?: Maybe<Scalars['Boolean']>;
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  parentEntityId?: Maybe<Scalars['String']>;
  percentile?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  returnedAt?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  /** user submissions */
  submission?: Maybe<SubmissionType>;
  submittedAt?: Maybe<Scalars['String']>;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

/** Homework Schema */
export type HomeworkType = {
  __typename?: 'HomeworkType';
  advanceSettings?: Maybe<HomeworkAdvanceSettingsType>;
  applicableRoles?: Maybe<Array<RoleType>>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** category in which homework belongs */
  categoryId?: Maybe<Scalars['String']>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  classroom: ClassroomType;
  /** This field is present if homework is created via classroom */
  classroomId: Scalars['String'];
  /** Homework Discussion Id */
  commentChannelId?: Maybe<Scalars['String']>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  /** user groups for the homework */
  deprecatedGroupIds?: Maybe<Array<Scalars['String']>>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  duration: Scalars['Float'];
  /** Homework Deadline and availability settings */
  enrolmentSettings?: Maybe<EnrolmentSettingsType>;
  feedbackStickers: Array<SubmissionFeedbackStickers>;
  gradeSettings: ResourceGradeSettingsType;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  /** Homework Instructions in text and media */
  instruction?: Maybe<HomeworkInstructionType>;
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  performance?: Maybe<HomeworkPerformanceType>;
  performanceMetrics: ContentPerformanceMetricsType;
  /** Id of Scheduled postback call */
  postbackScheduleId?: Maybe<Scalars['String']>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  submissionSettings: SubmissionSettingsType;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on resource */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Homework Schema */
export type HomeworkTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Homework Schema */
export type HomeworkTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Homework Schema */
export type HomeworkTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Homework Schema */
export type HomeworkTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Homework Schema */
export type HomeworkTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Homework Schema */
export type HomeworkTypeTagArgs = {
  tagId: Scalars['String'];
};

export enum HttpRequestTypeEnum {
  Delete = 'DELETE',
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
}

export type IconSettings = {
  __typename?: 'IconSettings';
  iconColor?: Maybe<Scalars['String']>;
  type: IconTypeEnum;
  value: Scalars['String'];
};

export type IconSettingsInput = {
  iconColor?: InputMaybe<Scalars['String']>;
  type: IconTypeEnum;
  value: Scalars['String'];
};

export type IconSettingsSetInput = {
  set?: InputMaybe<IconSettingsInput>;
};

export enum IconTypeEnum {
  Color = 'COLOR',
  Emoji = 'EMOJI',
  Icon = 'ICON',
}

export type ImageGridDisplayComponentType = {
  __typename?: 'ImageGridDisplayComponentType';
  /** heading for the section */
  heading?: Maybe<Scalars['String']>;
  /** image urls */
  imageUrls: Array<Scalars['String']>;
  type: DisplayComponentTypeEnum;
};

export type ImportContentFromMarketplaceInput = {
  marketplaceContentId: Scalars['String'];
};

export type ImportContentFromMarketplacePayload = {
  __typename?: 'ImportContentFromMarketplacePayload';
  marketplaceContent?: Maybe<MarketplaceContentType>;
  marketplaceContentId: Scalars['String'];
  /** @deprecated this is just for backward compatibility */
  success: Scalars['Boolean'];
};

export type ImportJobSampleCsvRow = {
  __typename?: 'ImportJobSampleCsvRow';
  values: Array<Scalars['String']>;
};

export type ImportJobSampleCsvRowsPayload = {
  __typename?: 'ImportJobSampleCsvRowsPayload';
  rows: Array<ImportJobSampleCsvRow>;
};

export type ImportSkillMatrixTemplateInput = {
  skillMatrixId: Scalars['String'];
};

export type ImportSkillTemplatesInput = {
  skillIds: Array<Scalars['String']>;
};

export enum IncludeChildObjectivesEnum {
  Both = 'BOTH',
  IncludeChildObjectives = 'INCLUDE_CHILD_OBJECTIVES',
  NotIncludeChildObjectives = 'NOT_INCLUDE_CHILD_OBJECTIVES',
}

export type InstructionInput = {
  /** Instruction Media */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** Instruction Text */
  text?: InputMaybe<Scalars['String']>;
};

/** Instruction for entities */
export type InstructionType = {
  __typename?: 'InstructionType';
  /** media */
  medias: Array<EntityMediaType>;
  /** text */
  text?: Maybe<Scalars['String']>;
};

export type InstructorProviderAvailabilitiesInput = {
  instructorIds: Array<Scalars['String']>;
  providerId: Scalars['String'];
};

export type InstructorProviderAvailabilitiesPayload = {
  __typename?: 'InstructorProviderAvailabilitiesPayload';
  availabilities: Array<InstructorProviderAvailability>;
};

export type InstructorProviderAvailability = {
  __typename?: 'InstructorProviderAvailability';
  instructorId: Scalars['String'];
  isAvailable: Scalars['Boolean'];
};

/** Instructor Object */
export type InstructorType = {
  __typename?: 'InstructorType';
  designation: Scalars['String'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum IntegrationAuthTypeEnum {
  BasicAuth = 'BASIC_AUTH',
  Oauth2 = 'OAUTH2',
}

export type IntegrationConfigsType = {
  __typename?: 'IntegrationConfigsType';
  connectionStrategy: IntegrationConnectionStrategyType;
};

export enum IntegrationConnectionStrategyEnum {
  Form = 'FORM',
  FormAndRedirectUrl = 'FORM_AND_REDIRECT_URL',
  RedirectUrl = 'REDIRECT_URL',
}

export type IntegrationConnectionStrategyType = {
  __typename?: 'IntegrationConnectionStrategyType';
  connectionType: IntegrationConnectionStrategyEnum;
  /** Fields to be taken as input from user for completing integration with the selected platform */
  formFields?: Maybe<Array<FormComponentType>>;
};

/** Edge */
export type IntegrationDetailEdge = {
  __typename?: 'IntegrationDetailEdge';
  node: IntegrationDetailType;
};

export type IntegrationDetailFilters = {
  connectedById?: InputMaybe<Scalars['String']>;
  integrationDetailId?: InputMaybe<Scalars['String']>;
  integrationProviderId?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<IntegrationProviderEnum>;
  scope?: InputMaybe<IntegrationScopeEnum>;
  status?: InputMaybe<IntegrationStatusEnum>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IntegrationDetailPayload = {
  __typename?: 'IntegrationDetailPayload';
  integrationDetail?: Maybe<IntegrationDetailType>;
};

export type IntegrationDetailType = {
  __typename?: 'IntegrationDetailType';
  /** Stringified user input corresponding to integration connection form fields */
  authSettings: Scalars['String'];
  connectedAt: Scalars['String'];
  /** user who connected the integration */
  connectedBy?: Maybe<UserProfile>;
  connectedById: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  existingIntegrationGroupIds: Array<Scalars['String']>;
  /**
   * Already selected user groups count according to provider latest groups data
   * @deprecated existingIntegrationGroupIds field provides updated ids hence length of that array can be used to get the updated count
   */
  existingIntegrationGroupsCount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  /** Available user groups for this integration provider */
  integrationGroups?: Maybe<Array<IntegrationUserGroupType>>;
  /** provider for this integration detail */
  integrationProvider?: Maybe<IntegrationProviderType>;
  /** Integration provider id to link to */
  integrationProviderId: Scalars['String'];
  /** Integration provider */
  provider: IntegrationProviderEnum;
  scope: IntegrationScopeEnum;
  settings?: Maybe<IntegrationSettingsType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Integration status */
  status: IntegrationStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type IntegrationDetailsFilters = {
  connectedById?: InputMaybe<Array<Scalars['String']>>;
  integrationDetailIds?: InputMaybe<Array<Scalars['String']>>;
  integrationProviderIds?: InputMaybe<Array<Scalars['String']>>;
  providers?: InputMaybe<Array<IntegrationProviderEnum>>;
  scopes?: InputMaybe<Array<IntegrationScopeEnum>>;
  status?: InputMaybe<Array<IntegrationStatusEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Edge */
export type IntegrationProviderEdge = {
  __typename?: 'IntegrationProviderEdge';
  node: IntegrationProviderType;
};

export enum IntegrationProviderEnum {
  Google = 'GOOGLE',
  Jira = 'JIRA',
  Microsoft = 'MICROSOFT',
  OutlookCalendar = 'OUTLOOK_CALENDAR',
  Salesforce = 'SALESFORCE',
  Slack = 'SLACK',
  Snowflake = 'SNOWFLAKE',
  Workday = 'WORKDAY',
  Zoom = 'ZOOM',
}

export type IntegrationProviderFilters = {
  integrationProviderId?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<IntegrationProviderEnum>;
  scopes?: InputMaybe<Array<IntegrationScopeEnum>>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type IntegrationProviderInfoType = {
  __typename?: 'IntegrationProviderInfoType';
  /** @deprecated use components */
  aboutIntegration: Scalars['String'];
  /** @deprecated use components */
  aboutProvider: Scalars['String'];
  /** layout of integration detail section */
  components: Array<DisplayComponentUnion>;
  description: Scalars['String'];
  documentationUrl: Scalars['String'];
  url: Scalars['String'];
  /** @deprecated use components */
  worksWith: Array<IntegrationWorksWithInfoType>;
};

export type IntegrationProviderPayload = {
  __typename?: 'IntegrationProviderPayload';
  integrationProvider?: Maybe<IntegrationProviderType>;
};

export enum IntegrationProviderStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  ComingSoon = 'COMING_SOON',
}

export type IntegrationProviderType = {
  __typename?: 'IntegrationProviderType';
  availableLyearnUserFields?: Maybe<Array<AvailableSchemaFieldType>>;
  availableSourceUserFields?: Maybe<Array<Scalars['String']>>;
  /** category of integration */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  defaultFieldMappings?: Maybe<Array<FieldMappingType>>;
  iconUrl: Scalars['String'];
  id: Scalars['String'];
  info?: Maybe<IntegrationProviderInfoType>;
  integrationConfigs: IntegrationConfigsType;
  /** user who connected the integration */
  integrationDetail?: Maybe<IntegrationDetailType>;
  name: Scalars['String'];
  provider: IntegrationProviderEnum;
  scopes: Array<IntegrationScopeEnum>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: IntegrationProviderStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** @deprecated use info.url */
  url: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type IntegrationProviderTypeIntegrationDetailArgs = {
  scope?: InputMaybe<IntegrationScopeEnum>;
  userId?: InputMaybe<Scalars['String']>;
};

export type IntegrationProvidersFilters = {
  integrationProviderIds?: InputMaybe<Array<Scalars['String']>>;
  providers?: InputMaybe<Array<IntegrationProviderEnum>>;
  scopes?: InputMaybe<Array<IntegrationScopeEnum>>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type IntegrationRedirectUrlInput = {
  /** stringified key value pair for form input */
  formInput?: InputMaybe<Scalars['String']>;
  integrationProviderId: Scalars['String'];
  relayState: Scalars['String'];
};

export type IntegrationRedirectUrlPayload = {
  __typename?: 'IntegrationRedirectUrlPayload';
  redirectUrl: Scalars['String'];
};

export enum IntegrationScopeEnum {
  User = 'USER',
  Workspace = 'WORKSPACE',
}

export type IntegrationSettingsInput = {
  googleSettings?: InputMaybe<GoogleIntegrationSettingsInput>;
  jiraSettings?: InputMaybe<JiraIntegrationSettingsInput>;
  microsoftSettings?: InputMaybe<MicrosoftIntegrationSettingsInput>;
  zoomSettings?: InputMaybe<ZoomIntegrationSettingsInput>;
};

export type IntegrationSettingsType = {
  __typename?: 'IntegrationSettingsType';
  googleSettings?: Maybe<GoogleIntegrationSettingsType>;
  jiraSettings?: Maybe<JiraIntegrationSettingsType>;
  microsoftSettings?: Maybe<MicrosoftIntegrationSettingsType>;
  zoomSettings?: Maybe<ZoomIntegrationSettingsType>;
};

export enum IntegrationStatusEnum {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  ReauthorizationRequired = 'REAUTHORIZATION_REQUIRED',
}

/** All Store Integrations */
export type IntegrationType = {
  __typename?: 'IntegrationType';
  /** App id of the external platform to which this product belongs */
  appId: Scalars['String'];
  /** App name of the external platform to which the content belongs */
  appName: Scalars['String'];
  /** Boolean value which dictates whether to assign to a user */
  toAssignUser: Scalars['Boolean'];
  /** Boolean value which dictates whether to create a new user */
  toCreateUser: Scalars['Boolean'];
};

export type IntegrationUserGroupType = {
  __typename?: 'IntegrationUserGroupType';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  membersCount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type IntegrationWorksWithInfoType = {
  __typename?: 'IntegrationWorksWithInfoType';
  description: Scalars['String'];
  icon: Scalars['String'];
  label: Scalars['String'];
};

/** Invalid Payment Create Checkout Session Mutation Payload */
export type InvalidCheckoutSessionMutationPayload = {
  __typename?: 'InvalidCheckoutSessionMutationPayload';
  errorCode?: Maybe<Scalars['String']>;
  paymentProvider: TransactionPaymentProviderEnum;
  success?: Maybe<Scalars['Boolean']>;
};

/** This returns paginated Invitations */
export type InvitationCodeConnection = {
  __typename?: 'InvitationCodeConnection';
  edges?: Maybe<Array<InvitationEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Gets invitationCodes corresponding to code */
export type InvitationCodeFilters = {
  code?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
};

/** The InvitationCode model */
export type InvitationCodeType = {
  __typename?: 'InvitationCodeType';
  code: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entityId: Scalars['String'];
  expireAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Gets invitationCodes corresponding to entityIds and codes */
export type InvitationCodesFilters = {
  codes?: InputMaybe<Array<Scalars['String']>>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Edge */
export type InvitationEdge = {
  __typename?: 'InvitationEdge';
  node: InvitationCodeType;
};

export type InviteUserInput = {
  emailId: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orgRoleId?: InputMaybe<Scalars['String']>;
  reportsToUserId?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<EntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']>;
  userType: UserTypeEnum;
  workspaceRoleIds?: InputMaybe<Array<Scalars['String']>>;
};

export type InviteUsersToClassroomInput = {
  classroomId: Scalars['String'];
  classroomUserRoleConfig: Array<ClassroomUserRoleConfig>;
};

export type InviteUsersToClassroomPayload = {
  __typename?: 'InviteUsersToClassroomPayload';
  failedEmailIds?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum InvocationTypeEnum {
  Grpc = 'GRPC',
  Http = 'HTTP',
}

export type IsDepartmentNameAvailableInput = {
  name: Scalars['String'];
};

export type IsDepartmentNameAvailablePayload = {
  __typename?: 'IsDepartmentNameAvailablePayload';
  isDepartmentNameAvailable: Scalars['Boolean'];
};

/** Filters applicable on userSkillReview */
export type IsPerformanceReviewCompletedInput = {
  reviewCycleId: Scalars['String'];
};

export type JwtDataType = {
  __typename?: 'JWTDataType';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  issueId?: Maybe<Scalars['String']>;
  onFailure: OnFailureActions;
  onSuccess: OnSuccessActions;
  secret: Scalars['String'];
};

export type JwtDataTypeEntity = {
  __typename?: 'JWTDataTypeEntity';
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  issueId?: Maybe<Scalars['String']>;
  onFailure: OnFailureActionsType;
  onSuccess: OnSuccessActionsType;
  secret: Scalars['String'];
};

export type JiraAssigneeInfo = {
  __typename?: 'JiraAssigneeInfo';
  displayName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
};

export type JiraIntegrationFieldMappingConfig = {
  __typename?: 'JiraIntegrationFieldMappingConfig';
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  lyearnTagId?: Maybe<Scalars['String']>;
  tagType?: Maybe<TagTypeEnum>;
};

export type JiraIntegrationPriorityConfig = {
  __typename?: 'JiraIntegrationPriorityConfig';
  id: Scalars['String'];
  lyearnPriority?: Maybe<ActionItemPriorityEnum>;
  name: Scalars['String'];
};

export type JiraIntegrationProjectStatusesConfig = {
  __typename?: 'JiraIntegrationProjectStatusesConfig';
  project?: Maybe<JiraProjectType>;
  statuses?: Maybe<Array<JiraIntegrationStatusConfig>>;
};

export type JiraIntegrationProjectStatusesConfigInput = {
  project?: InputMaybe<JiraProjectInput>;
  statuses?: InputMaybe<Array<JiraIntegrationStatusConfigInput>>;
};

export type JiraIntegrationSettingsInput = {
  projectStatusesConfigs?: InputMaybe<Array<JiraIntegrationProjectStatusesConfigInput>>;
};

export type JiraIntegrationSettingsType = {
  __typename?: 'JiraIntegrationSettingsType';
  fieldMappingConfigs?: Maybe<Array<JiraIntegrationFieldMappingConfig>>;
  priorityConfigs?: Maybe<Array<JiraIntegrationPriorityConfig>>;
  projectStatusesConfigs?: Maybe<Array<JiraIntegrationProjectStatusesConfig>>;
  registeredWebhookIds?: Maybe<Array<Scalars['String']>>;
  scheduledJobs?: Maybe<Array<ScheduledJobConfigs>>;
};

export type JiraIntegrationStatusConfig = {
  __typename?: 'JiraIntegrationStatusConfig';
  id: Scalars['String'];
  lyearnStatusId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** @deprecated use mappedLyearnStatusId to map to lyearn status */
  progress?: Maybe<Scalars['Float']>;
  statusCategory: JiraStatusCategory;
};

export type JiraIntegrationStatusConfigInput = {
  id: Scalars['String'];
  lyearnStatusId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  progress?: InputMaybe<Scalars['Float']>;
  statusCategory: JiraStatusCategoryInput;
};

/** Edge */
export type JiraIssueEdge = {
  __typename?: 'JiraIssueEdge';
  node: JiraIssueType;
};

export type JiraIssueFilters = {
  jqlQuery?: InputMaybe<Scalars['String']>;
  providerId?: InputMaybe<Scalars['String']>;
};

/** jira issue transition entity, part of jira workflow */
export type JiraIssueTransitionType = {
  __typename?: 'JiraIssueTransitionType';
  id: Scalars['String'];
  /** jira status associated with the given jira transition. issue will move to this status upon commiting the given transition */
  transitionToStatus: JiraStatusType;
};

export type JiraIssueType = {
  __typename?: 'JiraIssueType';
  assigneeInfo?: Maybe<JiraAssigneeInfo>;
  dueDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActionItemLinked: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Scalars['String']>>;
  priorityInfo?: Maybe<JiraPriorityType>;
  project?: Maybe<Scalars['String']>;
  sprintInfos?: Maybe<Array<JiraSprintInfo>>;
  status?: Maybe<Scalars['String']>;
  statusCategory?: Maybe<JiraStatusCategory>;
  statusId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type JiraIssueTypeIsActionItemLinkedArgs = {
  entityFilter?: InputMaybe<ActionItemEntityFilter>;
  fileFilter?: InputMaybe<FileFilters>;
};

export type JiraPriorityType = {
  __typename?: 'JiraPriorityType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type JiraProjectInput = {
  id: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export type JiraProjectType = {
  __typename?: 'JiraProjectType';
  id: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export type JiraSprintInfo = {
  __typename?: 'JiraSprintInfo';
  boardId?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type JiraStatusCategory = {
  __typename?: 'JiraStatusCategory';
  id: Scalars['String'];
  key: JiraStatusCategoryKeyEnum;
  name: Scalars['String'];
};

export type JiraStatusCategoryInput = {
  id: Scalars['String'];
  key: JiraStatusCategoryKeyEnum;
  name: Scalars['String'];
};

export enum JiraStatusCategoryKeyEnum {
  Done = 'DONE',
  Indeterminate = 'INDETERMINATE',
  New = 'NEW',
  Undefined = 'UNDEFINED',
}

export type JiraStatusInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  statusCategory: JiraStatusCategoryInput;
};

export type JiraStatusType = {
  __typename?: 'JiraStatusType';
  id: Scalars['String'];
  name: Scalars['String'];
  statusCategory: JiraStatusCategory;
};

export type JiraUserType = {
  __typename?: 'JiraUserType';
  accountId: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
};

export type JobByCreatorSubscriptionInput = {
  /** userId of the job creator */
  createdById: Scalars['String'];
  /** operations to subscribe on */
  operations?: InputMaybe<Array<JobSubscriptionOperationEnum>>;
  /** job purposes to subscribe on */
  purpose: Array<JobPurposeEnum>;
};

export type JobByCreatorSubscriptionPayload = {
  __typename?: 'JobByCreatorSubscriptionPayload';
  job: JobType;
  /** operation which happened on the job */
  operation: JobSubscriptionOperationEnum;
};

/** Edge */
export type JobEdge = {
  __typename?: 'JobEdge';
  node: JobType;
};

export enum JobEntityStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProcess = 'IN_PROCESS',
  NotStarted = 'NOT_STARTED',
  Parsing = 'PARSING',
  Validating = 'VALIDATING',
  Waiting = 'WAITING',
}

export type JobFilters = {
  jobScheduleId?: InputMaybe<Scalars['String']>;
};

export type JobInputPropsInput = {
  chartExportInput?: InputMaybe<ChartExportPropsInput>;
  groupMemberExportInput?: InputMaybe<GroupMemberExportInputProps>;
  learnerExportInput?: InputMaybe<LearnerExportInputPropsInput>;
  offlineEventAttendanceExportInput?: InputMaybe<OfflineEventAttendanceExportInputPropsInput>;
  offlineEventAttendanceImportInput?: InputMaybe<OfflineEventAttendanceImportInputPropsInput>;
  quizQuestionImportInput?: InputMaybe<QuizQuestionImportInputPropsInput>;
  recalibrateContentProgressInput?: InputMaybe<RecalibrateContentProgressInput>;
  reportExportInput?: InputMaybe<ReportExportPropsInput>;
  reportMediaExportInput?: InputMaybe<ReportMediaExportPropsInput>;
  skillMatrixExportInput?: InputMaybe<SkillMatrixExportInputPropsInput>;
  skillsExportInput?: InputMaybe<SkillsExportInputPropsInput>;
  surveyExportInput?: InputMaybe<SurveyExportPropsInput>;
  tagExportInput?: InputMaybe<TagExportInputProps>;
  taggedEntitiesExportInput?: InputMaybe<TaggedEntitiesExportInputPropsInput>;
  updatePathContentAvailabilityOfGivenUserIdsInput?: InputMaybe<UpdatePathContentAvailabilityOfGivenUserIdsInput>;
  zoomEventAttendanceImportInput?: InputMaybe<ZoomEventAttendanceImportInputPropsInput>;
  zoomRecordingsImportInput?: InputMaybe<ZoomRecordingsImportInputPropsInput>;
};

export type JobInputPropsType = {
  __typename?: 'JobInputPropsType';
  chartExportInput?: Maybe<ChartExportPropsType>;
  groupMemberExportInput?: Maybe<GroupMemberExportInputPropsType>;
  learnerExportInput?: Maybe<LearnerExportInputPropsType>;
  offlineEventAttendanceExportInput?: Maybe<OfflineEventAttendanceExportInputPropsType>;
  offlineEventAttendanceImportInput?: Maybe<OfflineEventAttendanceImportInputPropsType>;
  quizQuestionImportInput?: Maybe<QuizQuestionImportInputPropsType>;
  recalibrateContentProgressInput?: Maybe<RecalibrateContentProgressPropsType>;
  reportExportInput?: Maybe<ReportExportPropsType>;
  reportMediaExportInput?: Maybe<ReportMediaExportPropsType>;
  skillMatrixExportInput?: Maybe<SkillMatrixExportInputPropsType>;
  skillsExportInput?: Maybe<SkillsExportInputPropsType>;
  surveyExportInput?: Maybe<Scalars['String']>;
  tagExportInput?: Maybe<TagExportInputPropsType>;
  taggedEntitiesExportInput?: Maybe<TaggedEntitiesExportInputPropsType>;
  updateBatchUsersPathContentAvailabilityInput?: Maybe<UpdateBatchUsersPathContentAvailabilityInputPropsType>;
  zoomEventAttendanceImportInput?: Maybe<ZoomEventAttendanceImportInputPropsType>;
  zoomRecordingsImportInput?: Maybe<ZoomRecordingsImportInputPropsType>;
};

export type JobMetaInputType = {
  bulkTagsUpdateMetadata?: InputMaybe<BulkTagsUpdateMetadataInput>;
  lessonMeta?: InputMaybe<LessonMetaInputType>;
  userGroupMemberImportMetadata?: InputMaybe<UserGroupMemberImportMetadataInput>;
};

export type JobMetaType = {
  __typename?: 'JobMetaType';
  assignmentImportMetadataType?: Maybe<AssignmentImportMetadataType>;
  bulkTagsUpdateMetadata?: Maybe<BulkTagsUpdateMetadataType>;
  chartExportMetadata?: Maybe<ChartExportMetadataType>;
  groupMemberExportMetadata?: Maybe<GroupMemberExportMetadataType>;
  learnerExportMetadata?: Maybe<LearnerExportMetadataType>;
  learnerImportMetadata?: Maybe<LearnerImportMetadataType>;
  lessonMeta?: Maybe<LessonMetaType>;
  offlineEventAttendanceExportMetadataType?: Maybe<OfflineEventAttendanceExportMetadataType>;
  offlineEventAttendanceImportMetadata?: Maybe<OfflineEventAttendanceImportMetadataType>;
  quizQuestionImportMetadata?: Maybe<QuizQuestionImportMetadataType>;
  recalibrateContentProgressMetadata?: Maybe<LongLastingProcessMetaDataType>;
  reportExportMetadata?: Maybe<ReportExportMetadataType>;
  reportMediaExportMetadata?: Maybe<ReportMediaExportMetadataType>;
  scormImportMetadata?: Maybe<ScormImportMetadataType>;
  skillMatrixExportMetadata?: Maybe<SkillMatrixExportMetadataType>;
  skillMatrixImportMetadata?: Maybe<SkillMatrixImportMetadataType>;
  skillsExportMetadata?: Maybe<SkillsExportMetadataType>;
  skillsImportMetadata?: Maybe<SkillsImportMetadataType>;
  surveyExportMetadata?: Maybe<SurveyExportMetadataType>;
  tagExportMetadata?: Maybe<TagExportMetadataType>;
  taggedEntitiesExportMetadata?: Maybe<TaggedEntitiesExportMetadataType>;
  updateBatchUsersPathContentAvailabilityMetaData?: Maybe<UpdateBatchUsersPathContentAvailabilityMetaDataType>;
  userGroupMemberImportMetadata?: Maybe<UserGroupMemberImportMetadataType>;
  zoomEventAttendanceImportMetadata?: Maybe<ZoomEventAttendanceImportMetadataType>;
};

export type JobPayload = {
  __typename?: 'JobPayload';
  job?: Maybe<JobType>;
};

export enum JobPurposeEnum {
  AssignmentImport = 'ASSIGNMENT_IMPORT',
  BulkTagsUpdate = 'BULK_TAGS_UPDATE',
  ChartExport = 'CHART_EXPORT',
  GroupMemberExport = 'GROUP_MEMBER_EXPORT',
  ImportMsdocAsLesson = 'IMPORT_MSDOC_AS_LESSON',
  LearnerExport = 'LEARNER_EXPORT',
  LearnerImport = 'LEARNER_IMPORT',
  MindsetExport = 'MINDSET_EXPORT',
  MindsetImport = 'MINDSET_IMPORT',
  OfflineEventAttendanceExport = 'OFFLINE_EVENT_ATTENDANCE_EXPORT',
  OfflineEventAttendanceImport = 'OFFLINE_EVENT_ATTENDANCE_IMPORT',
  QuizQuestionImport = 'QUIZ_QUESTION_IMPORT',
  RecalibrateContentProgress = 'RECALIBRATE_CONTENT_PROGRESS',
  ReportExport = 'REPORT_EXPORT',
  ReportMediaExport = 'REPORT_MEDIA_EXPORT',
  ScormImport = 'SCORM_IMPORT',
  SkillExport = 'SKILL_EXPORT',
  SkillImport = 'SKILL_IMPORT',
  SkillMatrixExport = 'SKILL_MATRIX_EXPORT',
  SkillMatrixImport = 'SKILL_MATRIX_IMPORT',
  SurveyExport = 'SURVEY_EXPORT',
  SyncExternalUsers = 'SYNC_EXTERNAL_USERS',
  TaggedEntitiesExport = 'TAGGED_ENTITIES_EXPORT',
  TagExport = 'TAG_EXPORT',
  UpdatePathContentAvailability = 'UPDATE_PATH_CONTENT_AVAILABILITY',
  UserGroupMemberImport = 'USER_GROUP_MEMBER_IMPORT',
  ZoomEventAttendanceImport = 'ZOOM_EVENT_ATTENDANCE_IMPORT',
  ZoomRecordingsImport = 'ZOOM_RECORDINGS_IMPORT',
}

export type JobSettings = {
  __typename?: 'JobSettings';
  /** userIds who should be notified on job success or failure */
  notifyToUserIds: Array<Scalars['String']>;
};

export type JobSettingsInput = {
  /** userIds who should be notified on job success or failure */
  notifyToUserIds: Array<Scalars['String']>;
};

export enum JobStatusEnum {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
}

export type JobSubscriptionInput = {
  /** jobScheduleId to subscribe job on */
  jobScheduleId: Scalars['String'];
};

export enum JobSubscriptionOperationEnum {
  JobCreated = 'JOB_CREATED',
  JobUpdated = 'JOB_UPDATED',
}

/** Job schema */
export type JobType = {
  __typename?: 'JobType';
  childJobs?: Maybe<Array<ChildJobMetaType>>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** errors that occured during job processing */
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** input arguments for job */
  input?: Maybe<JobInputPropsType>;
  /** id of the job */
  jobScheduleId: Scalars['String'];
  /** url and format of the media associated with the job */
  mediaUrls: Array<MediaUrl>;
  meta?: Maybe<JobMetaType>;
  /** url and format of the output associated with the job */
  outputMediaUrls: Array<MediaUrl>;
  purpose?: Maybe<JobPurposeEnum>;
  retryCount?: Maybe<Scalars['Float']>;
  /** input arguments for retry job */
  retryJobInputMeta?: Maybe<Array<JobInputPropsType>>;
  settings?: Maybe<JobSettings>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedAt?: Maybe<Scalars['String']>;
  /** status of the job */
  status: JobEntityStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type JobsFilters = {
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  jobIds?: InputMaybe<Array<Scalars['String']>>;
  purpose?: InputMaybe<Array<JobPurposeEnum>>;
  status?: InputMaybe<Array<JobEntityStatusEnum>>;
};

export type JoinEventInput = {
  eventId: Scalars['String'];
};

export enum KeyResultMetricEnum {
  Currency = 'CURRENCY',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Time = 'TIME',
  Toggle = 'TOGGLE',
}

/** KeyResult outcome */
export type KeyResultOutcome = {
  __typename?: 'KeyResultOutcome';
  /** Current value mentioned */
  actualValue?: Maybe<Scalars['Float']>;
  /** Defines format */
  format?: Maybe<NumberFormatEnum>;
  /** Metric type */
  metric: KeyResultMetricEnum;
  /** Starting value mentioned */
  startValue?: Maybe<Scalars['Float']>;
  /** target value mentioned */
  targetValue?: Maybe<Scalars['Float']>;
};

export type KeyResultOutcomeInput = {
  /** Defines format */
  format?: InputMaybe<NumberFormatEnum>;
  /** Metric type */
  metric: KeyResultMetricEnum;
  /** Starting value mentioned */
  startValue?: InputMaybe<Scalars['Float']>;
  /** target value mentioned */
  targetValue?: InputMaybe<Scalars['Float']>;
};

/** Key Result Type */
export type KeyResultType = {
  __typename?: 'KeyResultType';
  /** all visibility settings of the okr */
  allVisibilitySettings: Array<VisibilitySettingsType>;
  attachments?: Maybe<Array<EntityMediaType>>;
  /** Checkin Timestamp */
  checkedInAt?: Maybe<Scalars['String']>;
  /** Total number of checkins */
  checkinCount?: Maybe<Scalars['Float']>;
  /** All checkins of the okr */
  checkins?: Maybe<Array<OkrCheckinType>>;
  /** will fetch direct child OKRs of the given OKR */
  childOKRs?: Maybe<Array<OkrUnion>>;
  /** Order of okr in the parent okr child list */
  childOrder: Scalars['Float'];
  childOrderForParent: Scalars['Float'];
  childOrders?: Maybe<Array<ParentChildOrder>>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Id of the OKR Cycle in which this is created */
  cycleId: Scalars['String'];
  /**
   * use details field with text and media
   * @deprecated use details field with text and media
   */
  description?: Maybe<Scalars['String']>;
  discussionChannel?: Maybe<ChannelType>;
  discussionChannelId?: Maybe<Scalars['String']>;
  /** Converted dueDate from dueDate config */
  dueDate: Scalars['String'];
  dueDateConfig: DueDateConfig;
  /** Returns true if okr has any child okrs */
  hasChildren: Scalars['Boolean'];
  /** path till rootNode - starting from /parent.../rootNode */
  hierarchyPath?: Maybe<Scalars['String']>;
  /** Hierarchy paths of okr */
  hierarchyPaths?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  /** OKR Instructions in text or media */
  instruction?: Maybe<InstructionType>;
  /** Details for linking externally */
  integrationConfig?: Maybe<OkrIntegrationConfig>;
  /** Indicates if okr is deleted */
  isDeleted: Scalars['Boolean'];
  isProgressAutoRolledUp?: Maybe<Scalars['Boolean']>;
  /** Name or Title of Objective or key result */
  name: Scalars['String'];
  noteBlockId?: Maybe<Scalars['String']>;
  /** Cycle of the OKR */
  okrCycle?: Maybe<OkrCycle>;
  /** owners of the okr */
  okrOwners?: Maybe<Array<UserType>>;
  /** returns count of all the direct + indirect child okrs of given okr */
  okrTreeChildrenCount: Scalars['Float'];
  /** store the result data for KR */
  outcome: KeyResultOutcome;
  /** owners of OKR */
  ownerIds: Array<Scalars['String']>;
  /** owners of the okr */
  owners?: Maybe<Array<UserProfile>>;
  /** Id of the parent */
  parentId?: Maybe<Scalars['String']>;
  /** Id of the parents */
  parentIds?: Maybe<Array<Scalars['String']>>;
  /** parent OKR of the okr */
  parentOKR?: Maybe<OkrUnion>;
  /** parent OKR of the okr */
  parentOKRs?: Maybe<Array<OkrUnion>>;
  /** Progress done */
  progress: Scalars['Float'];
  /** Details for progress measurement */
  progressMeasurementConfig?: Maybe<OkrProgressMeasurementConfig>;
  /** All the things which are related to okr */
  relatedToEntities?: Maybe<Array<Scalars['String']>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status for okr, ontrack behind etc */
  status: OkrStatusEnum;
  summary?: Maybe<OkrSummaryType>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** config for creating actionItem using externally linked entities */
  taskCreationConfigs?: Maybe<Array<OkrTaskCreationConfig>>;
  /** config for maintaining the status of sync jobs for action items */
  taskSyncConfig?: Maybe<SyncConfig>;
  taskViewConfigs?: Maybe<Array<UserViewFieldSchema>>;
  totalChildOKRCount: Scalars['Float'];
  /** Type for okr, Objective or key result */
  type: OkrTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on okr (objective or key result) */
  userPermissions?: Maybe<Array<UserOkrPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  viewConfig?: Maybe<EntityViewConfigSchema>;
  /** store who can view the objective */
  visibilitySettings: VisibilitySettingsType;
};

/** Key Result Type */
export type KeyResultTypeChildOkRsArgs = {
  filters?: InputMaybe<GetChildOkRsFilters>;
  okrTypes?: InputMaybe<Array<OkrTypeEnum>>;
};

/** Key Result Type */
export type KeyResultTypeChildOrderForParentArgs = {
  parentId: Scalars['String'];
};

/** Key Result Type */
export type KeyResultTypeOkrTreeChildrenCountArgs = {
  types?: InputMaybe<Array<OkrTypeEnum>>;
};

/** Key Result Type */
export type KeyResultTypeParentOkRsArgs = {
  fetchAll?: InputMaybe<Scalars['Boolean']>;
};

/** Key Result Type */
export type KeyResultTypeTagArgs = {
  tagId: Scalars['String'];
};

export type KeyResultsSetInput = {
  /** input to update key-result */
  set: Array<UpdateKeyResultInput>;
};

/** LatestSkillGroupReviewType Schema */
export type LatestSkillGroupReviewType = {
  __typename?: 'LatestSkillGroupReviewType';
  expectedScore: Scalars['Float'];
  reviews: Array<LatestSkillReviewType>;
  score: Scalars['Float'];
  /** user who submitted the assignment */
  skillGroupId: Scalars['String'];
};

/** LatestSkillReviewType Schema */
export type LatestSkillReviewType = {
  __typename?: 'LatestSkillReviewType';
  expectedScore: Scalars['Float'];
  score: Scalars['Float'];
  scoreDetails: Array<ScoreDetailsType>;
  /** user who submitted the assignment */
  skillId: Scalars['String'];
};

/** LatestUserReviewType Schema */
export type LatestUserReviewType = {
  __typename?: 'LatestUserReviewType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  mindsets: Array<LatestSkillReviewType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillGroups: Array<LatestSkillGroupReviewType>;
  skillMatrixId?: Maybe<Scalars['String']>;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type LeafPageComponentPreviewType = {
  __typename?: 'LeafPageComponentPreviewType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pageComponentType: PageComponentTypeEnum;
  props?: Maybe<Scalars['String']>;
  stringifiedWidget?: Maybe<Scalars['String']>;
  widget?: Maybe<Scalars['JSON']>;
  widgetPreview?: Maybe<WidgetPreviewUnion>;
};

export type LeafPageComponentType = {
  __typename?: 'LeafPageComponentType';
  calculateSecondaryOrder?: Maybe<Scalars['Boolean']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** layout of the page component */
  layout?: Maybe<PageComponentLayoutTypeEnum>;
  /** name of the page component */
  name: Scalars['String'];
  primaryOrder?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  /** custom JSON properties required by the page component */
  props?: Maybe<Scalars['String']>;
  redirectPath?: Maybe<Scalars['String']>;
  secondaryOrder: Scalars['Float'];
  /** status of the page component */
  status: PageComponentStatusEnum;
  /** type of the page component */
  type: PageComponentTypeEnum;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  widget?: Maybe<WidgetUnion>;
  /** widget id */
  widgetId?: Maybe<Scalars['String']>;
};

export enum LearnerExportFieldEnum {
  Department = 'DEPARTMENT',
  Email = 'EMAIL',
  FullName = 'FULL_NAME',
  LearnerId = 'LEARNER_ID',
  OrgRole = 'ORG_ROLE',
  ReportsTo = 'REPORTS_TO',
  UniqueId = 'UNIQUE_ID',
}

export type LearnerExportInputPropsInput = {
  filters: UsersFilters;
  requiredFields: LearnerExportRequiredFieldsInput;
};

export type LearnerExportInputPropsType = {
  __typename?: 'LearnerExportInputPropsType';
  filters: UsersFiltersType;
  requiredFields: LearnerExportRequiredFieldsType;
};

export type LearnerExportMetadataType = {
  __typename?: 'LearnerExportMetadataType';
  exportedCount: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type LearnerExportRequiredFieldsInput = {
  fields: Array<LearnerExportFieldEnum>;
  tagIds: Array<Scalars['String']>;
};

export type LearnerExportRequiredFieldsType = {
  __typename?: 'LearnerExportRequiredFieldsType';
  fields: Array<LearnerExportFieldEnum>;
  tagIds: Array<Scalars['String']>;
};

export type LearnerImportMetadataType = {
  __typename?: 'LearnerImportMetadataType';
  createdUsersCount: Scalars['Float'];
  updatedUsersCount: Scalars['Float'];
};

export type LearnersCountInput = {
  workspaceId: Scalars['String'];
};

export type LearnersCountPayload = {
  __typename?: 'LearnersCountPayload';
  count: Scalars['Float'];
};

export enum LearningTypeEnum {
  Content = 'CONTENT',
  Event = 'EVENT',
  SelfLearning = 'SELF_LEARNING',
}

export type LessonMetaInputType = {
  /** url of the html */
  htmlUrl: Scalars['String'];
  /** page blockId of newly created page block */
  pageBlockId: Scalars['String'];
  /** ref blockId of newly created page block */
  refBlockId: Scalars['String'];
};

export type LessonMetaType = {
  __typename?: 'LessonMetaType';
  /** url of the html */
  htmlUrl: Scalars['String'];
  /** page blockId of newly created page block */
  pageBlockId: Scalars['String'];
  /** ref blockId of newly created page block */
  refBlockId: Scalars['String'];
};

export type LinkPropsInput = {
  /** label for the link */
  label: Scalars['String'];
  /** external url */
  url: Scalars['String'];
};

export type LinkType = {
  __typename?: 'LinkType';
  /** label for the link */
  label: Scalars['String'];
  /** external url */
  url: Scalars['String'];
};

export type ListItemType = {
  __typename?: 'ListItemType';
  description?: Maybe<Scalars['String']>;
  /** Defines a list of actions to be performed for different userInteractions performed on the widget */
  eventActions?: Maybe<Array<WidgetInteractionEventActionType>>;
  heading: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  isChecked: Scalars['Boolean'];
  redirectPath?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ListItemsWidgetPropsInput = {
  listItemTypesToUpdate: Array<Scalars['String']>;
  widgetSubType?: InputMaybe<ListItemsWidgetSubTypeEnum>;
};

export enum ListItemsWidgetSubTypeEnum {
  PrimaryOnboardingItems = 'PRIMARY_ONBOARDING_ITEMS',
  SecondaryOnboardingItems = 'SECONDARY_ONBOARDING_ITEMS',
}

/** For Powering Data In Any Type Of List Items */
export type ListItemsWidgetType = {
  __typename?: 'ListItemsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  listItems: Array<ListItemType>;
  progress: Scalars['Float'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  subType?: Maybe<ListItemsWidgetSubTypeEnum>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ListSectionDisplayComponentType = {
  __typename?: 'ListSectionDisplayComponentType';
  /** heading for the section */
  heading?: Maybe<Scalars['String']>;
  /** image urls */
  items: Array<ListSectionItemType>;
  type: DisplayComponentTypeEnum;
};

export type ListSectionItemType = {
  __typename?: 'ListSectionItemType';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** Live Event container content type. Refers to event entity */
export type LiveEventContentType = {
  __typename?: 'LiveEventContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the event */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  description?: Maybe<Scalars['String']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** duration of content in seconds */
  duration: Scalars['Float'];
  event?: Maybe<EventUnion>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  objective: Scalars['String'];
  performance?: Maybe<EventPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  startTime: Scalars['String'];
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Live Event container content type. Refers to event entity */
export type LiveEventContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export type LiveEventProps = {
  /** Livestream provider. Can be provided while using webinar subType. */
  provider?: InputMaybe<LivestreamProviderEnum>;
  recordingSettings: RecordingSettingsInput;
  subType?: InputMaybe<EventSubTypeEnum>;
};

export type LiveEventType = {
  __typename?: 'LiveEventType';
  /** actual ending time */
  actualEndTime?: Maybe<Scalars['String']>;
  /** actual start time */
  actualStartTime?: Maybe<Scalars['String']>;
  /** reason for event archival */
  archivalReason?: Maybe<Scalars['String']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** count of available seats */
  availableSeatCount?: Maybe<Scalars['Float']>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  completionSettings?: Maybe<CompletionSettingsType>;
  conferenceBridgeId: Scalars['String'];
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /**
   * count of users attended the event from the current workspace
   * @deprecated no longer required
   */
  currentWorkspaceUserCount: Scalars['Float'];
  defaultGroupId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  discussionChannel: ChannelType;
  discussionChannelId: Scalars['String'];
  documentRootBlockId: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  /** user provided end time in UTC */
  endTime: Scalars['String'];
  /** session recordings */
  eventRecordings?: Maybe<Array<EventRecordingType>>;
  id: Scalars['String'];
  instructorIds: Array<Scalars['String']>;
  instructors: Array<UserType>;
  /** flag to indicate if event is filling fast */
  isFillingFast: Scalars['Boolean'];
  isRevokedAfterCompletion: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  /** flag to indicate if currentUser is an instructor */
  isUserInstructor: Scalars['Boolean'];
  isUserRegistered: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  /** liveStream info */
  livestreamInfo?: Maybe<LivestreamInfo>;
  /** event metadata */
  meta: EventMetadataType;
  name: Scalars['String'];
  objective: Scalars['String'];
  postWorkContentIds?: Maybe<Array<Scalars['String']>>;
  postWorkContents: Array<ContentEntity>;
  postwork?: Maybe<EventWorkConfig>;
  preWorkContentIds?: Maybe<Array<Scalars['String']>>;
  preWorkContents: Array<ContentEntity>;
  prework?: Maybe<EventWorkConfig>;
  recordingSettings: RecordingSettings;
  /** Recording URLs of the event */
  recordings?: Maybe<Array<Scalars['String']>>;
  /** time after which registration is closed */
  registrationCloseTime?: Maybe<Scalars['String']>;
  registrationSettings?: Maybe<RegistrationSettings>;
  remainingPostWorkContents: Scalars['Float'];
  remainingPreWorkContents: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  skills: Array<SkillType>;
  /** user provided start time in UTC */
  startTime: Scalars['String'];
  status: EventStatusEnum;
  subType: EventSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** session time zone */
  timezone: Scalars['String'];
  /** total count of users who attended the event */
  totalUsersCount: Scalars['Float'];
  type: EventTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userIds: Array<Scalars['String']>;
  /** Permissions for a user on event */
  userPermissions?: Maybe<Array<UserEventPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  users: Array<UserType>;
  usersCount: Scalars['Float'];
  versionNumber?: Maybe<Scalars['Float']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

export type LiveEventTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type LiveEventTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export type LiveEventTypeTagArgs = {
  tagId: Scalars['String'];
};

export type LivestreamInfo = {
  __typename?: 'LivestreamInfo';
  broadcastId: Scalars['String'];
  livestreamId: Scalars['String'];
  livestreamUrl: Scalars['String'];
  provider: LivestreamProviderEnum;
  rtmpsIngestionAddress: Scalars['String'];
};

export enum LivestreamProviderEnum {
  Ant = 'ANT',
  Youtube = 'YOUTUBE',
}

/** Load OKR Summary Input */
export type LoadOkrSummaryInput = {
  /** Id of the okr which needs to summarized */
  okrId: Scalars['String'];
  /** use a custom prompt for summary generation */
  prompt?: InputMaybe<Scalars['String']>;
};

export type LoadOkrSummaryPayload = {
  __typename?: 'LoadOKRSummaryPayload';
  /** ISO date string */
  createdAt: Scalars['String'];
  /** what service was used to generate the summary */
  generatedBy: Scalars['String'];
  /** Id of the okr which was summarized */
  okrId: Scalars['String'];
  /** the summary */
  summary: Scalars['String'];
};

/** Login Configurations for Workspace Integration */
export type LoginConfig = {
  __typename?: 'LoginConfig';
  IDPEntityId?: Maybe<Scalars['String']>;
  IDPInitiated?: Maybe<Scalars['Boolean']>;
  IDPName?: Maybe<Scalars['String']>;
  SPEntityId?: Maybe<Scalars['String']>;
  SPInitiated?: Maybe<Scalars['Boolean']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  failureRedirectionURL?: Maybe<Scalars['String']>;
  issueId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  onFailure?: Maybe<LoginFailureConfig>;
  onSuccess?: Maybe<LoginSuccessConfig>;
  secret?: Maybe<Scalars['String']>;
  successRedirectionURL?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Configurations when Login fails */
export type LoginFailureConfig = {
  __typename?: 'LoginFailureConfig';
  redirect?: Maybe<Scalars['Boolean']>;
};

/** Configurations when Login succeeds */
export type LoginSuccessConfig = {
  __typename?: 'LoginSuccessConfig';
  createUser?: Maybe<Scalars['Boolean']>;
  redirect?: Maybe<Scalars['Boolean']>;
  sendInvitationMail?: Maybe<Scalars['Boolean']>;
  updateUser?: Maybe<Scalars['Boolean']>;
};

export type LongLastingProcessMetaDataType = {
  __typename?: 'LongLastingProcessMetaDataType';
  parentProcessName: Scalars['String'];
  requestId: Scalars['String'];
};

export type LookUpActionItemValuesPayload = {
  __typename?: 'LookUpActionItemValuesPayload';
  statusIds: Array<Scalars['String']>;
  statuses?: Maybe<Array<StatusType>>;
};

export type LowSeatCountRuleInput = {
  delaySeconds: Scalars['Float'];
  lowSeatCountThreshold: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type LowSeatCountRuleType = {
  __typename?: 'LowSeatCountRuleType';
  delaySeconds: Scalars['Float'];
  lowSeatCountThreshold: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

/** This is used for match the following */
export type MtfComponent = {
  __typename?: 'MTFComponent';
  distractedRightElements?: Maybe<Array<MtfElement>>;
  leftElements: Array<MtfElement>;
  rightElements: Array<MtfElement>;
};

/** This is used for match the following */
export type MtfComponentInput = {
  leftElements: Array<MtfElementInput>;
  pairings: Array<MtfPairingInput>;
  rightElements: Array<MtfElementInput>;
};

/** Match the following element, used for single entry in match the following arrays. */
export type MtfElement = {
  __typename?: 'MTFElement';
  attachments?: Maybe<Array<EntityMediaType>>;
  id: Scalars['String'];
  text: Scalars['String'];
};

/** Element for match the following, can be right or left side of match */
export type MtfElementInput = {
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  id?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

/** Pair provides mapping for match the following type questions */
export type MtfPairing = {
  __typename?: 'MTFPairing';
  leftElementId: Scalars['String'];
  rightElementIds: Array<Scalars['String']>;
};

/** Answer for Match the following */
export type MtfPairingInput = {
  leftElementIndex: Scalars['Float'];
  rightElementIndices: Array<Scalars['Float']>;
};

/** Used when response type is match the following */
export type MtfResponse = {
  __typename?: 'MTFResponse';
  leftElementId: Scalars['String'];
  rightElementIds: Array<Scalars['String']>;
};

/** Used when response type is match the following */
export type MtfResponseInput = {
  leftElementId: Scalars['String'];
  rightElementIds: Array<Scalars['String']>;
};

/** This is the input for submission of Exercise! */
export type MakeExerciseSubmissionInput = {
  contentType?: InputMaybe<ResourceTypeEnum>;
  entityId: Scalars['String'];
  isActualSubmit: Scalars['Boolean'];
  lessonPageId?: InputMaybe<Scalars['String']>;
  parentEntityId?: InputMaybe<Scalars['String']>;
  questionResponses?: InputMaybe<Array<ExerciseQuestionResponseInput>>;
  submissionId?: InputMaybe<Scalars['String']>;
};

/** Make Exercise Submission Response */
export type MakeExerciseSubmissionPayload = {
  __typename?: 'MakeExerciseSubmissionPayload';
  errorCode?: Maybe<PerformanceErrorCode>;
  performance?: Maybe<ExercisePerformanceType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** This is the input for submission of homework! */
export type MakeHomeworkSubmissionInput = {
  entityId: Scalars['String'];
  /** User response with some attachments */
  mediaResponses?: InputMaybe<Array<CreateMediaInput>>;
  responseType: ResourceResponseTypeEnum;
  /** If response type is text, HTML string */
  textResponse?: InputMaybe<Scalars['String']>;
  /** If response type is whiteboard, Stringified JSON which will be managed by frontend */
  whiteboardResponse?: InputMaybe<Scalars['String']>;
};

/** Make Submission Response */
export type MakeSubmissionPayload = {
  __typename?: 'MakeSubmissionPayload';
  performance: HomeworkPerformanceType;
  success?: Maybe<Scalars['Boolean']>;
};

/** Manual metric column config */
export type ManualMetricColumnConfig = {
  __typename?: 'ManualMetricColumnConfig';
  columnId: Scalars['String'];
  fieldType: ManualMetricColumnFieldTypeEnum;
  isOptional: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Float'];
  type: ManualMetricColumnTypeEnum;
};

export type ManualMetricColumnConfigInput = {
  columnId?: InputMaybe<Scalars['String']>;
  fieldType: ManualMetricColumnFieldTypeEnum;
  isOptional: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Float'];
  type: ManualMetricColumnTypeEnum;
};

export enum ManualMetricColumnFieldTypeEnum {
  Date = 'DATE',
  Number = 'NUMBER',
  Text = 'TEXT',
}

export enum ManualMetricColumnTypeEnum {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
}

export type ManualMetricConfigInput = {
  columnConfigs: Array<ManualMetricColumnConfigInput>;
};

export type ManualMetricConfigType = {
  __typename?: 'ManualMetricConfigType';
  columnConfigs: Array<ManualMetricColumnConfig>;
};

/** Updates attendance status of multiple users or all of events */
export type MarkAttendancesInput = {
  attendancesOfEvents?: InputMaybe<Array<AttendanceOfEvent>>;
  attendancesOfUsers?: InputMaybe<Array<AttendanceOfUser>>;
};

/** Update attendance of users response */
export type MarkAttendancesPayload = {
  __typename?: 'MarkAttendancesPayload';
  errorCode?: Maybe<EventUserMetricsErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Apply automatic attendance for an event response */
export type MarkAutoAttendanceForEventResponse = {
  __typename?: 'MarkAutoAttendanceForEventResponse';
  errorCode?: Maybe<EventUserMetricsErrorCodeEnum>;
  success: Scalars['Boolean'];
};

export type MarkEventAttendanceInput = {
  eventId: Scalars['String'];
  userAttendances: Array<OfflineEventUserAttendanceInput>;
};

/** Deprecated: Use MarkEventAttendance instead of MarkOfflineEventAttendance */
export type MarkOfflineEventAttendanceInput = {
  eventId: Scalars['String'];
  userAttendances: Array<OfflineEventUserAttendanceInput>;
};

export type MarkOfflineEventAttendancePayload = {
  __typename?: 'MarkOfflineEventAttendancePayload';
  success: Scalars['Boolean'];
};

/** input for marking submission as feature */
export type MarkSubmissionAsFeatureInput = {
  entityId: Scalars['String'];
  isFeatured: Scalars['Boolean'];
  submissionId: Scalars['String'];
  userId: Scalars['String'];
};

/** payload for marking submission as feature */
export type MarkSubmissionAsFeaturePayload = {
  __typename?: 'MarkSubmissionAsFeaturePayload';
  /** Error code */
  errorCode?: Maybe<PerformanceErrorCode>;
  performance?: Maybe<ContentPerformanceEntity>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MarketplaceContentCategoriesFilters = {
  marketplaceContentCategoryIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

/** Edge */
export type MarketplaceContentCategoryEdge = {
  __typename?: 'MarketplaceContentCategoryEdge';
  node: MarketplaceContentCategoryType;
};

export type MarketplaceContentCategoryPayload = {
  __typename?: 'MarketplaceContentCategoryPayload';
  marketplaceContent?: Maybe<MarketplaceContentCategoryType>;
};

export type MarketplaceContentCategoryType = {
  __typename?: 'MarketplaceContentCategoryType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Edge */
export type MarketplaceContentEdge = {
  __typename?: 'MarketplaceContentEdge';
  node: MarketplaceContentType;
};

export type MarketplaceContentFilters = {
  marketplaceContentId?: InputMaybe<Scalars['String']>;
};

export type MarketplaceContentPayload = {
  __typename?: 'MarketplaceContentPayload';
  marketplaceContent?: Maybe<MarketplaceContentType>;
};

export type MarketplaceContentType = {
  __typename?: 'MarketplaceContentType';
  /** categories in which marketplace content lies */
  categories: Array<MarketplaceContentCategoryType>;
  categoryIds?: Maybe<Array<Scalars['String']>>;
  contentType: MarketplaceContentTypeEnum;
  coverUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  /** indicates if this content is already imported to the current workspace */
  isAddedToCurrentWorkspace: Scalars['Boolean'];
  name: Scalars['String'];
  objective?: Maybe<Scalars['String']>;
  /** provider for marketplace content */
  provider?: Maybe<CommonProviderType>;
  providerId: Scalars['String'];
  redirectUrl: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum MarketplaceContentTypeEnum {
  Article = 'ARTICLE',
  Assessment = 'ASSESSMENT',
  Book = 'BOOK',
  Course = 'COURSE',
  Podcast = 'PODCAST',
  ResearchPaper = 'RESEARCH_PAPER',
  Video = 'VIDEO',
}

export type MarketplaceContentsFilters = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  contentTypes?: InputMaybe<Array<MarketplaceContentTypeEnum>>;
  marketplaceContentIds?: InputMaybe<Array<Scalars['String']>>;
  providerIds?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
};

export type MarketplaceContentsWidgetType = {
  __typename?: 'MarketplaceContentsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** marketplace contents */
  marketplaceContents: PaginatedMarketplaceContentsPayload;
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  /** static content ids */
  staticIds?: Maybe<Array<Scalars['String']>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type MarketplaceContentsWidgetTypeMarketplaceContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<MarketplaceContentsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export enum MeasureAggregatorApplicableOnEnum {
  Self = 'SELF',
  Team = 'TEAM',
}

export enum MeasureAggregatorEnum {
  Avg = 'AVG',
  AvgBucket = 'AVG_BUCKET',
  Count = 'COUNT',
  CountDistinct = 'COUNT_DISTINCT',
  CumulativeSum = 'CUMULATIVE_SUM',
  Max = 'MAX',
  Min = 'MIN',
  None = 'NONE',
  Script = 'SCRIPT',
  Sum = 'SUM',
  SumBucket = 'SUM_BUCKET',
  Top = 'TOP',
}

export type MeasureConfigType = {
  __typename?: 'MeasureConfigType';
  measure: Scalars['String'];
  topHitsConfigs?: Maybe<TopHitsConfigs>;
};

export type MeasureType = {
  __typename?: 'MeasureType';
  aggregateUpto?: Maybe<Scalars['String']>;
  aggregator: MeasureAggregatorEnum;
  applicableOn?: Maybe<MeasureAggregatorApplicableOnEnum>;
  field: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type MeasureTypeInput = {
  aggregateUpto?: InputMaybe<Scalars['String']>;
  aggregator: MeasureAggregatorEnum;
  applicableOn?: InputMaybe<MeasureAggregatorApplicableOnEnum>;
  field: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

/** Media Input */
export type Media = {
  /** Media URL */
  resolutions: Array<ResolutionInputType>;
  /** Media type. type can be an image or video. */
  type: MediaTypeEnum;
  /** Media URL */
  url: Scalars['String'];
};

export enum MediaAccessClassEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export enum MediaExportFormatEnum {
  Pdf = 'PDF',
  Png = 'PNG',
}

export type MediaFilters = {
  mediaIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum MediaKindEnum {
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  File = 'FILE',
  Folder = 'FOLDER',
  Image = 'IMAGE',
  Video = 'VIDEO',
  WebLink = 'WEB_LINK',
  YoutubeLink = 'YOUTUBE_LINK',
}

/** Media Location */
export type MediaLocation = {
  __typename?: 'MediaLocation';
  bucket?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  type: MediaLocationTypeEnum;
};

export type MediaLocationInput = {
  bucket?: InputMaybe<Scalars['String']>;
  destination?: InputMaybe<Scalars['String']>;
  type: MediaLocationTypeEnum;
};

export enum MediaLocationTypeEnum {
  AwsS3 = 'AWS_S3',
  External = 'EXTERNAL',
  GcpCloudStorage = 'GCP_CLOUD_STORAGE',
}

export type MediaPayload = {
  __typename?: 'MediaPayload';
  media?: Maybe<MediaType>;
};

/** General Media Object, can be used with any entity */
export type MediaType = {
  __typename?: 'MediaType';
  accessClass: MediaAccessClassEnum;
  convertedMedias: Array<ConvertedMedia>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** Information regarding Media category(Image, video, audio, document) */
  kind: MediaKindEnum;
  location: MediaLocation;
  /** media type of uploaded entity */
  mimeType?: Maybe<Scalars['String']>;
  resolutions: Array<ResolutionType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Size of the attachment in kbs */
  size?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** url of the attachment stored on our end */
  url?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum MediaTypeEnum {
  Image = 'IMAGE',
  Video = 'VIDEO',
}

/** Media url type */
export type MediaUrl = {
  __typename?: 'MediaUrl';
  fileName?: Maybe<Scalars['String']>;
  /** media type of uploaded entity */
  format: Scalars['String'];
  mediaId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type MediaUrlInput = {
  fileName?: InputMaybe<Scalars['String']>;
  /** media type of uploaded entity */
  format: Scalars['String'];
  mediaId?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type MediasSetInput = {
  /** Medias not in this list would be deleted and new medias in this list will be added */
  set: Array<CreateMediaInput>;
};

/** input to check whether meeting exists between given participants */
export type MeetingAlreadyExistsInput = {
  participantIds?: InputMaybe<Array<Scalars['String']>>;
  type: Array<MeetingTypeEnum>;
};

export type MeetingAlreadyExistsPayload = {
  __typename?: 'MeetingAlreadyExistsPayload';
  exists: Scalars['Boolean'];
  meetingId?: Maybe<Scalars['String']>;
};

/** Edge */
export type MeetingEdge = {
  __typename?: 'MeetingEdge';
  node: MeetingType;
};

export type MeetingFilters = {
  meetingId: Scalars['String'];
};

export type MeetingInstanceCreatedUserNotification = {
  __typename?: 'MeetingInstanceCreatedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingDateTime: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  partner1Id: Scalars['String'];
  partner2Id: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  senderId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Edge */
export type MeetingInstanceEdge = {
  __typename?: 'MeetingInstanceEdge';
  node: MeetingInstanceType;
};

export type MeetingInstanceFilters = {
  meetingInstanceId: Scalars['String'];
};

export enum MeetingInstanceOccurrenceTypeEnum {
  Oneoff = 'ONEOFF',
  ViaSchedule = 'VIA_SCHEDULE',
}

export type MeetingInstancePayload = {
  __typename?: 'MeetingInstancePayload';
  meetingInstance?: Maybe<MeetingInstanceType>;
};

export type MeetingInstancePrivateNote = {
  __typename?: 'MeetingInstancePrivateNote';
  note?: Maybe<InstructionType>;
  userId: Scalars['String'];
};

export enum MeetingInstanceStatusEnum {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS',
  Late = 'LATE',
  Missed = 'MISSED',
  Scheduled = 'SCHEDULED',
}

export type MeetingInstanceSubscriptionInput = {
  /** ids of meetings instances */
  meetingInstanceIds: Array<Scalars['String']>;
  /** operations to subscribe on */
  operations?: InputMaybe<Array<MeetingInstanceSubscriptionOperationEnum>>;
};

export enum MeetingInstanceSubscriptionOperationEnum {
  AgendaItemCreated = 'AGENDA_ITEM_CREATED',
  AgendaItemDeleted = 'AGENDA_ITEM_DELETED',
  AgendaItemDuplicated = 'AGENDA_ITEM_DUPLICATED',
  MeetingInstanceUpdated = 'MEETING_INSTANCE_UPDATED',
}

export type MeetingInstanceSubscriptionPayload = {
  __typename?: 'MeetingInstanceSubscriptionPayload';
  agendaItem?: Maybe<AgendaItemType>;
  meetingInstance?: Maybe<MeetingInstanceType>;
  operation: MeetingInstanceSubscriptionOperationEnum;
};

export type MeetingInstanceSummaryInput = {
  agendaItems?: InputMaybe<Array<GenerateSummaryAgendaItemsInput>>;
};

export type MeetingInstanceSummaryResultType = {
  __typename?: 'MeetingInstanceSummaryResultType';
  text: Scalars['String'];
};

export enum MeetingInstanceSummaryStatusEnum {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Ongoing = 'ONGOING',
}

export type MeetingInstanceSummaryType = {
  __typename?: 'MeetingInstanceSummaryType';
  createdAt: Scalars['String'];
  generativeTaskId: Scalars['String'];
  status: MeetingInstanceSummaryStatusEnum;
  text?: Maybe<Scalars['String']>;
};

export type MeetingInstanceType = {
  __typename?: 'MeetingInstanceType';
  /** field resolvers */
  actionItemsCount: Scalars['Float'];
  actualEndTime?: Maybe<Scalars['String']>;
  /** timestamp when meetingInstance was actually marked IN_PROGRESS */
  actualStartTime?: Maybe<Scalars['String']>;
  agendaItemIds?: Maybe<Array<Scalars['String']>>;
  agendaItems: Array<AgendaItemType>;
  agendaItemsCount: Scalars['Float'];
  bannerStatus?: Maybe<BannerStatusEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  defaultGroupId: Scalars['String'];
  id: Scalars['String'];
  meetingId: Scalars['String'];
  occurrenceType: MeetingInstanceOccurrenceTypeEnum;
  organizerId: Scalars['String'];
  participantIds: Array<Scalars['String']>;
  participantsProfile?: Maybe<Array<UserProfile>>;
  privateNote?: Maybe<MeetingInstancePrivateNote>;
  /** deprecated: required for meetingInstances of 1on1 created before agenda feature */
  rootBlockId?: Maybe<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startTime: Scalars['String'];
  status: PublicMeetingInstanceStatusEnum;
  summary?: Maybe<MeetingInstanceSummaryType>;
  summaryRegenerationRequired: Scalars['Boolean'];
  type: MeetingInstanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum MeetingInstanceTypeEnum {
  OneOnOne = 'ONE_ON_ONE',
  Team = 'TEAM',
}

export type MeetingInstancesFilters = {
  meetingIds?: InputMaybe<Array<Scalars['String']>>;
  meetingInstanceIds?: InputMaybe<Array<Scalars['String']>>;
  occurrenceType?: InputMaybe<Array<MeetingInstanceOccurrenceTypeEnum>>;
  participantIds?: InputMaybe<Array<Scalars['String']>>;
  startTime?: InputMaybe<DateFilter>;
  status?: InputMaybe<Array<PublicMeetingInstanceStatusEnum>>;
  type?: InputMaybe<Array<MeetingInstanceTypeEnum>>;
};

export type MeetingInstancesWidgetType = {
  __typename?: 'MeetingInstancesWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** upcoming meetings instances */
  meetingInstances: PaginatedMeetingInstancesPayload;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type MeetingInstancesWidgetTypeMeetingInstancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
};

export type MeetingPayload = {
  __typename?: 'MeetingPayload';
  meeting?: Maybe<MeetingType>;
};

/** Additional information related to the meeting report */
export type MeetingReport = {
  __typename?: 'MeetingReport';
  teamInsightId?: Maybe<Scalars['String']>;
};

export type MeetingSubscriptionInput = {
  /** ids of meetings */
  meetingIds: Array<Scalars['String']>;
  /** operations to subscribe on */
  operations?: InputMaybe<Array<MeetingSubscriptionOperationEnum>>;
};

export enum MeetingSubscriptionOperationEnum {
  TemplateUpdated = 'TEMPLATE_UPDATED',
}

export type MeetingSubscriptionPayload = {
  __typename?: 'MeetingSubscriptionPayload';
  meeting: MeetingType;
  operation: MeetingSubscriptionOperationEnum;
};

export type MeetingType = {
  __typename?: 'MeetingType';
  attachments?: Maybe<Array<EntityMediaType>>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  defaultGroupId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** All okrIds to be excluded */
  excludedOkrIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  includeChildObjectives?: Maybe<Scalars['Boolean']>;
  insightsReport?: Maybe<ReportType>;
  lastOccurrenceDate?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nextInstanceAgendaItemIds?: Maybe<Array<Scalars['String']>>;
  nextOccurrenceDate: Scalars['String'];
  noteBlockId?: Maybe<Scalars['String']>;
  okrIds?: Maybe<Array<Scalars['String']>>;
  /** okrIds without children included */
  okrIdsWithoutChildren?: Maybe<Array<Scalars['String']>>;
  okrProgress: Scalars['Float'];
  oneOnOneReportee?: Maybe<Scalars['String']>;
  organizerId: Scalars['String'];
  participantIds: Array<Scalars['String']>;
  participants?: Maybe<Array<UserType>>;
  participantsCount?: Maybe<Scalars['Float']>;
  participantsProfile: Array<UserProfile>;
  report?: Maybe<MeetingReport>;
  sampleParticipantsProfile: Array<UserProfile>;
  schedule: ScheduleInfo;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: PublicMeetingStatusEnum;
  taskViewConfigs?: Maybe<Array<UserViewFieldSchema>>;
  templateId?: Maybe<Scalars['String']>;
  type: MeetingTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  viewConfig?: Maybe<EntityViewConfigSchema>;
};

export enum MeetingTypeEnum {
  OneOnOne = 'ONE_ON_ONE',
  Team = 'TEAM',
}

export type MeetingsFilters = {
  agendaItemTemplateIds?: InputMaybe<Array<Scalars['String']>>;
  childObjective?: InputMaybe<IncludeChildObjectivesEnum>;
  meetingIds?: InputMaybe<Array<Scalars['String']>>;
  nextOccurrenceDate?: InputMaybe<DateFilter>;
  okrIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<PublicMeetingStatusEnum>>;
  type?: InputMaybe<Array<MeetingTypeEnum>>;
};

export type MeetingsWidgetType = {
  __typename?: 'MeetingsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** upcoming meetings */
  meetings: PaginatedMeetingsPayload;
  returnOne: Scalars['Boolean'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type MeetingsWidgetTypeMeetingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
};

/** It will resolve user or group for mentioned entity */
export type MentionEntity = UserCollectionGroupType | UserType;

export enum MentionEntityTypeEnum {
  Content = 'CONTENT',
  Event = 'EVENT',
  Group = 'GROUP',
  User = 'USER',
}

export type MentionFilters = {
  /** search Text to search */
  searchText: Scalars['String'];
  types: Array<EntityTypeEnum>;
};

export type MentionInputType = {
  /** EntityId whoever/whichever is mentioned  */
  entityId: Scalars['String'];
  /** EntityType like user, group etc */
  entityType: MentionEntityTypeEnum;
  /** Handles preview visibility on the post */
  previewEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type MentionSearchInput = {
  /** @mentions search */
  filters: Array<Scalars['String']>;
};

export type MentionSearchPayload = {
  __typename?: 'MentionSearchPayload';
  contents?: Maybe<ContentConnection>;
  events?: Maybe<PaginatedEventsPayload>;
  users?: Maybe<UserConnection>;
};

export type MentionSearchPayloadContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ContentFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type MentionSearchPayloadEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<EventsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type MentionSearchPayloadUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<UsersFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

/** Mentions on a post */
export type MentionType = {
  __typename?: 'MentionType';
  contentMention?: Maybe<ContentEntity>;
  entityId: Scalars['String'];
  entityMention?: Maybe<MentionEntity>;
  entityType: MentionEntityTypeEnum;
  eventMention?: Maybe<EventUnion>;
  /** Handles preview visibility on the post */
  previewEnabled?: Maybe<Scalars['Boolean']>;
};

export type MentionsSetInput = {
  /** Mentions like @user (Pass mentions:[] incase of no mentions */
  set: Array<MentionInputType>;
};

export type MergeFileInput = {
  destinationFileId: Scalars['String'];
  fileIdToMerge: Scalars['String'];
};

export type MergeFilePayload = {
  __typename?: 'MergeFilePayload';
  destinationFile?: Maybe<FileSchema>;
  oldParent?: Maybe<FileSchema>;
};

/** Edge */
export type MetricEdge = {
  __typename?: 'MetricEdge';
  node: MetricSchema;
};

export type MetricFilters = {
  metricId: Scalars['String'];
};

export type MetricPayload = {
  __typename?: 'MetricPayload';
  metric?: Maybe<MetricSchema>;
};

export type MetricSchema = {
  __typename?: 'MetricSchema';
  /** returns the count of activity metrics attached to it */
  activityMetricCount?: Maybe<Scalars['Float']>;
  attachments?: Maybe<Array<EntityMediaType>>;
  automaticType?: Maybe<AutomaticMetricTypeEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Salesforce -> dataObject */
  dataObject?: Maybe<Scalars['String']>;
  defaultTargetFrequency?: Maybe<RelativeDateFilterUnitEnum>;
  defaultTargetValue?: Maybe<Scalars['Float']>;
  defaultTargetValueUnit?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Underlying chart widgets */
  entityCount?: Maybe<Scalars['Float']>;
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String'];
  integration?: Maybe<IntegrationProviderType>;
  lastSyncedAt?: Maybe<Scalars['String']>;
  /** returns the latest target value of a habit for the provided date */
  latestTargetValue?: Maybe<Scalars['Float']>;
  manualMetricConfig?: Maybe<ManualMetricConfigType>;
  /** measurementUnit, salesforce -> displayAs */
  measurementUnit: KeyResultMetricEnum;
  /** Salesforce -> filters */
  metricType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  queryConfig?: Maybe<MetricSyncQueryConfig>;
  /** Time reminders on days based on repeatSchedule */
  reminders?: Maybe<Array<Scalars['String']>>;
  /** Schedule for habit type metrics */
  repeatSchedule?: Maybe<ScheduleInfo>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Current status of metric */
  status: MetricStatusEnum;
  syncConfig: MetricSyncConfig;
  /** Type of metric */
  type: MetricTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Required to track user habits */
  userId?: Maybe<Scalars['String']>;
  /** returns the progress of a user on habit for the provided date */
  userProgress?: Maybe<Scalars['Float']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type MetricSchemaLatestTargetValueArgs = {
  filters: DateFilter;
};

export type MetricSchemaUserProgressArgs = {
  filters: DateFilter;
};

export type MetricSnapshot = {
  __typename?: 'MetricSnapshot';
  currentTime: Scalars['String'];
};

export enum MetricStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

/** Metric sync config */
export type MetricSyncConfig = {
  __typename?: 'MetricSyncConfig';
  integrationId?: Maybe<Scalars['String']>;
  type: MetricSyncTypeEnum;
};

export type MetricSyncConfigInputType = {
  integrationId?: InputMaybe<Scalars['String']>;
  type: MetricSyncTypeEnum;
};

/** Metric sync query config */
export type MetricSyncQueryConfig = {
  __typename?: 'MetricSyncQueryConfig';
  /** Metric grouped upon, mostly ownerId/userId */
  dimensions: Array<Scalars['String']>;
  /** All the conditions on which the metric will be synced */
  filterGroup: FilterGroupType;
  /** Aggregation on the field */
  measures: Array<MeasureType>;
  /** Table/Collection from which metric will be synced */
  source: Scalars['String'];
};

export enum MetricSyncTypeEnum {
  Automatic = 'AUTOMATIC',
  Integration = 'INTEGRATION',
  Manual = 'MANUAL',
}

export enum MetricTypeEnum {
  Activity = 'ACTIVITY',
  Habit = 'HABIT',
}

export type MetricsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  integrationProviderIds?: InputMaybe<Array<Scalars['String']>>;
  measureField?: InputMaybe<Scalars['String']>;
  measurementUnits?: InputMaybe<Array<KeyResultMetricEnum>>;
  metricIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  notInIds?: InputMaybe<Array<Scalars['String']>>;
  /** Metrics whose repeat schedule (falls/is valid) on this date */
  scheduledOn?: InputMaybe<DateFilter>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<MetricStatusEnum>>;
  type?: InputMaybe<MetricTypeEnum>;
  updationMethods?: InputMaybe<Array<MetricSyncTypeEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Product Metrics */
export type MetricsType = {
  __typename?: 'MetricsType';
  conversion?: Maybe<Scalars['Float']>;
  earnings?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  learners?: Maybe<Scalars['Float']>;
};

export type MicrosoftIntegrationSettingsInput = {
  fieldMappings: Array<FieldMappingInput>;
  lastSyncedAt?: InputMaybe<Scalars['String']>;
  syncJobId?: InputMaybe<Scalars['String']>;
  syncSchedule?: InputMaybe<ScheduleInfoInput>;
  userGroupIds: Array<Scalars['String']>;
  usersImportType: UsersImportTypeEnum;
};

export type MicrosoftIntegrationSettingsType = {
  __typename?: 'MicrosoftIntegrationSettingsType';
  archiveNonGroupMembers?: Maybe<Scalars['Boolean']>;
  fieldMappings: Array<FieldMappingType>;
  lastSyncedAt?: Maybe<Scalars['String']>;
  scheduledJob?: Maybe<ScheduledJobConfigs>;
  syncJobId?: Maybe<Scalars['String']>;
  syncSchedule?: Maybe<ScheduleInfo>;
  userGroupIds: Array<Scalars['String']>;
  usersImportType: UsersImportTypeEnum;
};

export type ModifyEntitiesInGroupInput = {
  entityIds: Array<Scalars['String']>;
  groupId: Scalars['String'];
};

export type ModifyEntitiesInGroupPayload = {
  __typename?: 'ModifyEntitiesInGroupPayload';
  dynamicallyPresentEntityIds: Array<Scalars['String']>;
};

export type ModifyTaskCreationConfigsInput = {
  command: OperationEnum;
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<TaskCreationEntityTypeEnum>;
  inputs: TaskCreationConfigInputProps;
  okrId?: InputMaybe<Scalars['String']>;
  providerId: Scalars['String'];
};

export type ModifyTaskCreationConfigsPayload = {
  __typename?: 'ModifyTaskCreationConfigsPayload';
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<TaskCreationEntityTypeEnum>;
  file?: Maybe<FileSchema>;
  fileId?: Maybe<Scalars['String']>;
  okr?: Maybe<OkrUnion>;
};

export enum ModifyUsersInEventCommandEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
}

export type ModifyUsersInEventInput = {
  command: ModifyUsersInEventCommandEnum;
  eventId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type ModifyUsersInGroupInput = {
  groupId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type ModifyUsersInGroupPayload = {
  __typename?: 'ModifyUsersInGroupPayload';
  dynamicallyPresentUserIds: Array<Scalars['String']>;
  dynamicallyPresentUsers?: Maybe<Array<UserType>>;
};

export enum MonthOfYearEnum {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER',
}

export type MoveFileInput = {
  fileIdToMove: Scalars['String'];
  newParentId?: InputMaybe<Scalars['String']>;
  /** order in parent */
  orderInNewParent?: InputMaybe<Scalars['Float']>;
};

export type MoveFilePayload = {
  __typename?: 'MoveFilePayload';
  newParent?: Maybe<FileSchema>;
  oldParent?: Maybe<FileSchema>;
};

export type MoveMeetingInstanceAgendaItemsInput = {
  agendaItemIds: Array<Scalars['String']>;
  sourceInstanceId: Scalars['String'];
  targetInstanceId?: InputMaybe<Scalars['String']>;
};

/** input for changing okr cycle */
export type MoveOkrInput = {
  /** whether to move whole okr tree to new cycle or not */
  moveOKRSubTrees?: InputMaybe<Scalars['Boolean']>;
  /** id of cycle to which new okr will be moved */
  moveToCycleId: Scalars['String'];
  okrId: Scalars['String'];
  /** new parent id of okr being moved. must belong to new cycle */
  updatedParentId?: InputMaybe<Scalars['String']>;
  /** new parent ids of okr being moved. must belong to new cycle */
  updatedParentIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MoveOkrPayload = {
  __typename?: 'MoveOKRPayload';
  /** moved okr after transition */
  movedOKR?: Maybe<OkrUnion>;
  /** old parent of okr being moved */
  oldParentOKR?: Maybe<OkrUnion>;
  /** old parents of okr being moved */
  oldParentOKRs?: Maybe<Array<OkrUnion>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MoveResourceInput = {
  /** current location of resource */
  fromPath: Scalars['String'];
  kind: ResourceKindEnum;
  resourceId: Scalars['String'];
  /** transferred location of resource */
  toPath: Scalars['String'];
};

export type MultiBannerPageComponentPreviewType = {
  __typename?: 'MultiBannerPageComponentPreviewType';
  components: Array<LeafPageComponentPreviewType>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pageComponentType: PageComponentTypeEnum;
  props?: Maybe<Scalars['String']>;
  stringifiedWidget?: Maybe<Scalars['String']>;
  widget?: Maybe<Scalars['JSON']>;
};

export type MultiBannerPageComponentType = {
  __typename?: 'MultiBannerPageComponentType';
  calculateSecondaryOrder?: Maybe<Scalars['Boolean']>;
  /** components of banner */
  components: Array<LeafPageComponentType>;
  /** description */
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** layout of the page component */
  layout?: Maybe<PageComponentLayoutTypeEnum>;
  /** name of the page component */
  name: Scalars['String'];
  primaryOrder?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  /** custom JSON properties required by the page component */
  props?: Maybe<Scalars['String']>;
  redirectPath?: Maybe<Scalars['String']>;
  secondaryOrder: Scalars['Float'];
  /** status of the page component */
  status: PageComponentStatusEnum;
  type: PageComponentTypeEnum;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  widget?: Maybe<WidgetUnion>;
  /** widget id */
  widgetId?: Maybe<Scalars['String']>;
};

export type MultiSelectFacetType = {
  __typename?: 'MultiSelectFacetType';
  applyOn: FacetApplyOnType;
  defaultValues?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isOptionLocalisationNeeded: Scalars['Boolean'];
  label: Scalars['String'];
  options: Array<SelectFacetOption>;
  /** presentational properties for the facet defined as a stringified JSON */
  props?: Maybe<Scalars['String']>;
  type: FacetTypeEnum;
};

export type MultiSelectTagPropsInput = {
  ignoreOptions?: InputMaybe<Scalars['Boolean']>;
  options: Array<SelectTagOptionInput>;
};

export type MultiSelectTagType = {
  __typename?: 'MultiSelectTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** If tag can have dynamic option values */
  ignoreOptions?: Maybe<Scalars['Boolean']>;
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** number type tag specific fields */
  options: Array<SelectTagOptionType>;
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  /** field resolvers */
  taggedContentCount: Scalars['Float'];
  taggedEventCount: Scalars['Float'];
  taggedOKRCount: Scalars['Float'];
  taggedTaskCount: Scalars['Float'];
  taggedUserCount: Scalars['Float'];
  type: TagTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type MultiSelectTagTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type MultiSelectTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addChildBlock: AddChildBlockPayload;
  addContentsToGroup?: Maybe<ModifyEntitiesInGroupPayload>;
  addConvertedMedia: ResourceType;
  addExerciseSubmissionFeedback: AddExerciseSubmissionFeedbackPayload;
  addPermissions: AddPermissionsPayload;
  addReaction?: Maybe<UpdateReactionPayload>;
  /** Mutation to add recordings to an event */
  addRecordingsToEvent?: Maybe<MutationResponse>;
  addSubmissionFeedback: AddSubmissionFeedbackPayload;
  addUserPushNotificationTokens: MutationResponse;
  addUserSearchActivity: AddUserSearchActivityPayload;
  addUsersInEvent?: Maybe<EventPayload>;
  addUsersToEventSchedule: AddUsersToEventSchedulePayload;
  addUsersToGroup?: Maybe<ModifyUsersInGroupPayload>;
  addUsersToStaffRoles: MutationResponse;
  archiveClassroom: MutationResponse;
  /**
   * Mutation to archive event
   * @deprecated Use updateStatusOfEvent mutation
   */
  archiveClassroomEvent?: Maybe<ArchiveClassroomEventPayload>;
  archiveGroup?: Maybe<GroupUnion>;
  archiveTag: TagUnion;
  assignContent: AssignContentPayload;
  assignHomework: AssignHomeworkPayload;
  attachActivityMetrics: AttachActivityMetricsPayload;
  awardPoints?: Maybe<AwardPointsPayload>;
  cancelGenerativeTask?: Maybe<MutationResponse>;
  cancelJob: MutationResponse;
  cancelTransaction: MutationResponse;
  changeDueDate: MutationResponse;
  checkinKeyResult?: Maybe<OkrPayload>;
  checkinObjective?: Maybe<OkrPayload>;
  convertMedia?: Maybe<ConvertMediaPayload>;
  createActionItem?: Maybe<ActionItemPayload>;
  createActionItems: ActionItemsPayload;
  createAgendaItem?: Maybe<AgendaItemType>;
  createAgendaItemTemplate?: Maybe<AgendaItemTemplateType>;
  createArticle: CreateArticlePayload;
  createAssignment: CreateAssignmentPayload;
  createBlock: CreateBlockPayload;
  createBookmark: CreateBookmarkPayload;
  createCategory: CategoryType;
  createCertificate: CertificatePayload;
  createChannel?: Maybe<CreateChannelPayload>;
  createChart?: Maybe<CreateChartPayload>;
  createCheckin: CreateCheckinPayload;
  createCheckoutSession: CreateCheckoutSessionMutationPayload;
  createClassroom?: Maybe<CreateClassroomPayload>;
  createClassroomSetting: ClassroomSettingType;
  /** Mutation corresponding to creating cohort */
  createCohort?: Maybe<CreateCohortPayload>;
  createCommentThread?: Maybe<CreateCommentThreadPayload>;
  createDatabase?: Maybe<CreateDatabasePayload>;
  createDatabaseView?: Maybe<CreateDatabaseViewPayload>;
  createDepartment: CreateDepartmentPayload;
  createEmailDigest?: Maybe<EmailDigestPayload>;
  createEvent?: Maybe<EventPayload>;
  createEventSchedule: CreateEventSchedulePayload;
  createExercise: CreateExercisePayload;
  createExternalContent: CreateExternalContentPayload;
  createFeedback: FeedbackPayload;
  createFeedbackQuestion: FeedbackQuestionPayload;
  createFile?: Maybe<FilePayload>;
  createGenerativeTask?: Maybe<GenerativeTaskPayload>;
  createGroup: GroupUnion;
  createHomework: CreateHomeworkPayload;
  createImportJob?: Maybe<JobType>;
  /** Mutation to create individual non-recurring event */
  createIndividualEvent?: Maybe<CreateIndividualEventPayload>;
  createMeeting?: Maybe<MeetingType>;
  createMeetingInstance: MeetingInstancePayload;
  createMetric?: Maybe<MetricPayload>;
  createOKRCycle: CreateOkrCyclePayload;
  createObjective: CreateObjectivePayload;
  createOrgRole: CreateOrgRolePayload;
  createOutlookCalendarEventFromMeeting: CreateOutlookCalendarEventFromMeetingPayload;
  createPath: CreatePathPayload;
  createPeerFeedback?: Maybe<PeerFeedbackPayload>;
  createPost?: Maybe<CreatePostPayload>;
  createPraise: PraisePayload;
  createPublicAPICredentials: CreatePublicApiCredentialsPayload;
  createQuestions?: Maybe<CreateQuestionsPayload>;
  createQuiz: CreateQuizPayload;
  createReport?: Maybe<CreateReportPayload>;
  createResource: CreateResourcePayload;
  createReviewCycle: ReviewCyclePayload;
  createRole: RoleType;
  createScorecard: CreateScorecardPayload;
  createScorm: ScormPayload;
  createSelfLearningContent: CreateExternalContentPayload;
  createSkill: SkillPayload;
  createSkillMatrix?: Maybe<SkillMatrixType>;
  createSquadChannel?: Maybe<CreateChannelPayload>;
  createStaffRole: RoleType;
  createSurvey: CreateSurveyPayload;
  createTag: TagUnion;
  createTask: CreateTaskPayload;
  createTrack: CreateTrackPayload;
  createUser: UserType;
  createUserCollectionGroup: UserCollectionGroupPayload;
  deleteActionItem?: Maybe<MutationResponse>;
  deleteAgendaItem: MutationResponse;
  deleteAgendaItemTemplate: MutationResponse;
  deleteAssignment: DeleteAssignmentPayload;
  deleteBookmark: DeleteBookmarkPayload;
  /** Caution! Mutation will delete the event from database */
  deleteClassroomEvent?: Maybe<DeleteClassroomEventPayload>;
  deleteContent: DeleteContentPayload;
  deleteDepartment: MutationResponse;
  deleteEmailDigest?: Maybe<MutationResponse>;
  deleteEventSchedule: DeleteEventSchedulePayload;
  deleteFile?: Maybe<MutationResponse>;
  deleteMeeting: MutationResponse;
  deleteMeetingInstances: MutationResponse;
  deleteMetric?: Maybe<MutationResponse>;
  deleteOKR: DeleteOkrPayload;
  deleteOKRCycle: MutationResponse;
  deleteOrgRole: MutationResponse;
  deletePeerFeedback: MutationResponse;
  deletePost: MutationResponse;
  deletePraise?: Maybe<MutationResponse>;
  deleteReport?: Maybe<MutationResponse>;
  deleteResource: MutationResponse;
  deleteReviewCycle: DeleteReviewCyclePayload;
  deleteSkill: DeleteSkillPayload;
  deleteSkillMatrix: DeleteSkillMatrixPayload;
  deleteSkillReview: MutationResponse;
  deleteStaffRole: MutationResponse;
  deleteTrack: MutationResponse;
  deleteTracks: MutationResponse;
  deleteUserActivityMetrics: MutationResponse;
  deleteUserSearchActivity: MutationResponse;
  deleteUserView?: Maybe<MutationResponse>;
  deleteWidgets: MutationResponse;
  discardUnpublishedChanges: DiscardUnpublishedChangesPayload;
  disconnectIntegrationDetail: IntegrationDetailPayload;
  duplicateAgendaItem?: Maybe<AgendaItemType>;
  duplicateAgendaItemTemplate?: Maybe<AgendaItemTemplateType>;
  duplicateContent: DuplicateContentPayload;
  duplicateEvent?: Maybe<EventPayload>;
  duplicateMetric?: Maybe<MetricPayload>;
  duplicateOKR?: Maybe<DuplicateOkrPayload>;
  duplicateReport?: Maybe<DuplicateReportPayload>;
  duplicateSkillMatrix?: Maybe<SkillMatrixType>;
  duplicateSurvey: DuplicateSurveyPayload;
  editPost?: Maybe<EditPostPayload>;
  editWebhook: WebhookType;
  evaluateExerciseSubmission: EvaluateExerciseSubmissionPayload;
  evaluateHomeworkSubmission: EvaluateHomeworkSubmissionPayload;
  eventSharingSelfEnrollCourse: MutationResponse;
  excuseStudentsFromAssignment: MutationResponse;
  exportTagReport: ExportTagReportPayload;
  forceCompleteContentProgress: ForceCompleteContentProgressPayload;
  generateLearnerLink: GenerateLearnerLinkPayload;
  importContentFromMarketplace: ImportContentFromMarketplacePayload;
  importSkilTemplates: MutationResponse;
  importSkillMatrixTemplate?: Maybe<SkillMatrixType>;
  inviteUser: UserType;
  inviteUsersToClassroom: InviteUsersToClassroomPayload;
  joinEvent: MutationResponse;
  loadOKRSummary?: Maybe<LoadOkrSummaryPayload>;
  makeExerciseSubmission: MakeExerciseSubmissionPayload;
  makeHomeworkSubmission: MakeSubmissionPayload;
  markAttendances: MarkAttendancesPayload;
  markEventAttendance: MutationResponse;
  /** Deprecated: Use MarkEventAttendance instead */
  markOfflineEventAttendance: MarkOfflineEventAttendancePayload;
  markSubmissionAsFeature: MarkSubmissionAsFeaturePayload;
  mergeFile?: Maybe<MergeFilePayload>;
  modifyTaskCreationConfigs?: Maybe<ModifyTaskCreationConfigsPayload>;
  modifyUsersInEvent?: Maybe<EventPayload>;
  moveFile?: Maybe<MoveFilePayload>;
  moveMeetingInstanceAgendaItems: MutationResponse;
  moveOKR?: Maybe<MoveOkrPayload>;
  moveResource: MutationResponse;
  nudgeReviewers: MutationResponse;
  /** Mutation corresponding to actions by teacher on request to onboard the classroom by learners */
  onboardClassroomUsers: OnboardClassroomUsersPayload;
  publishArticle: PublishArticlePayload;
  publishAssignment: PublishAssignmentPayload;
  publishCommunity: PublishCommunityPayload;
  publishContent: PublishContentPayload;
  publishCourse: PublishCoursePayload;
  readNotifications: MutationResponse;
  reattemptQuestions: ReattemptQuestionsPayload;
  recalibrateContentProgress: MutationResponse;
  regenerateMeetingInstanceSummary: RegenerateMeetingInstanceSummaryPayload;
  registerEvent?: Maybe<EventPayload>;
  registerWebhook: WebhookType;
  removeActionItemTag?: Maybe<ActionItemPayload>;
  removeContentTag: ContentEntity;
  removeContentsFromGroup?: Maybe<ModifyEntitiesInGroupPayload>;
  removeDatabaseData?: Maybe<MutationResponse>;
  removeEntityAccess: EntityAccessPayload;
  removeEntityFromMetric: MutationResponse;
  removeEventTag: EventPayload;
  removeMarketplaceImportedContent: RemoveMarketplaceImportedContentPayload;
  removeOKRTag?: Maybe<OkrPayload>;
  removePermissions: RemovePermissionsPayload;
  removePostAttachment?: Maybe<MutationResponse>;
  removeQuestions?: Maybe<RemoveQuestionsPayload>;
  removeReaction?: Maybe<UpdateReactionPayload>;
  removeUserPushNotificationTokens: MutationResponse;
  removeUserTag: UserType;
  /** Mutation to remove user from classroom */
  removeUsersFromClassroom: RemoveUsersFromClassroomPayload;
  /** Mutation to remove user from event */
  removeUsersFromClassroomEvent: RemoveUsersFromClassroomEventPayload;
  removeUsersFromEventSchedule: RemoveUsersFromEventSchedulePayload;
  removeUsersFromGroup?: Maybe<ModifyUsersInGroupPayload>;
  removeUsersFromStaff: MutationResponse;
  removeUsersFromStaffRoles: MutationResponse;
  removeWebhook: RemoveWebhookPayload;
  requestClassroomAccess: RequestClassroomAccessPayload;
  requestConnection: MutationResponse;
  requestPeerFeedback: MutationResponse;
  rescheduleMeetingInstance: MeetingInstancePayload;
  resetContentProgress: ResetContentProgressPayload;
  resubmitExerciseSubmission: ResubmitExerciseSubmissionPayload;
  retakeQuiz: RetakeQuizPayload;
  returnAssignmentGradeToStudents: MutationResponse;
  revertPerformancesToNotSubmitted: MutationResponse;
  reviewConnection: MutationResponse;
  rollUpDurationOfBlocks: RollUpDurationOfBlocksPayload;
  saveWorkspaceAgendaItems?: Maybe<AgendaItemsPayload>;
  sendAcademyTemplate: MutationResponse;
  sendMail: MutationResponse;
  setEntityAccess: EntityAccessPayload;
  setProductStatus: SetProductStatusPayload;
  setupAcademy: MutationResponse;
  shareItems: MutationResponse;
  shareTags: MutationResponse;
  startContent: MutationResponse;
  startQuiz: StartQuizPayload;
  startQuizQuestionTimer: StartQuizQuestionTimerPayload;
  stopItemsSharing: MutationResponse;
  submitQuiz: SubmitQuizPayload;
  submitQuizQuestion: SubmitQuizQuestionPayload;
  submitQuizWithQuestions: SubmitQuizPayload;
  submitReviews: MutationResponse;
  submitSkillReview: SubmitSkillReviewPayload;
  syncExternalPerformance: SyncExternalPerformancePayload;
  syncProgress: SyncProgressPayload;
  syncTasks?: Maybe<SyncTasksPayload>;
  syncUsers: SyncUsersPayload;
  unAssignContent: UnAssignContentPayload;
  undoClassroomInviteRejection: UndoClassroomInviteRejectionPayload;
  undoMoveMeetingInstanceAgendaItems: MutationResponse;
  unregisterEvent?: Maybe<EventPayload>;
  updateActionItem?: Maybe<ActionItemPayload>;
  updateAgendaItem?: Maybe<AgendaItemType>;
  updateAgendaItemTemplate?: Maybe<AgendaItemTemplateType>;
  updateAgendaItems?: Maybe<AgendaItemsPayload>;
  updateArticle: UpdateArticlePayload;
  updateAssignment: UpdateAssignmentPayload;
  updateCategory: CategoryType;
  updateCertificateTemplate: CertificateTemplatepayload;
  updateChart?: Maybe<UpdateChartPayload>;
  updateChildOKRsOrder?: Maybe<UpdateChildOkRsOrderPayload>;
  updateClassroom: ClassroomType;
  /** Mutation to update event */
  updateClassroomEvent?: Maybe<UpdateClassroomEventPayload>;
  updateClassroomSetting: ClassroomSettingType;
  updateContentStatus: UpdateContentStatusPayload;
  updateDatabaseMetadata?: Maybe<UpdateDatabaseMetadataPayload>;
  updateDatabaseView?: Maybe<UpdateDatabaseViewPayload>;
  updateDepartment: UpdateDepartmentPayload;
  updateEmailDigest?: Maybe<EmailDigestPayload>;
  updateEntitiesTasksRelation?: Maybe<MutationResponse>;
  updateEntityAttachments?: Maybe<AttachmentEntityUnion>;
  updateEntityCustomStatus?: Maybe<StatusesPayload>;
  updateEntityTags: UpdateEntityTagsPayload;
  updateEvent?: Maybe<EventPayload>;
  updateEventSchedule: UpdateEventSchedulePayload;
  updateEventScheduleRecurrenceRuleConfiguration: UpdateEventScheduleRecurrenceRuleConfigurationPayload;
  updateEventScheduleRoleOfUsers: UpdateEventScheduleRoleOfUsersPayload;
  updateEventScheduleStatus: UpdateEventScheduleStatusPayload;
  updateEventStatus?: Maybe<EventPayload>;
  updateEventVisibilitySettings?: Maybe<EventPayload>;
  updateExercise: UpdateExercisePayload;
  updateExternalContent: UpdateExternalContentPayload;
  updateExternalContentPerformance: UpdateExternalContentPerformancePayload;
  updateFeedback: FeedbackPayload;
  updateFeedbackQuestion: FeedbackQuestionPayload;
  updateFile?: Maybe<FilePayload>;
  updateGroup: GroupUnion;
  updateJiraPrioritiesMapping: UpdateJiraPrioritiesMappingPayload;
  updateJiraStatusesMapping: UpdateJiraStatusesMappingPayload;
  updateJiraStatusesProgressMapping: UpdateJiraStatusesProgressMappingPayload;
  updateLastResolvedNotificationTimestamp: UpdateLastResolvedNotificationTimestampPayload;
  updateMeeting?: Maybe<MeetingType>;
  updateMeetingInstanceAgendaItemsOrder: MeetingInstancePayload;
  updateMeetingInstancePrivateNotes: MeetingInstancePayload;
  updateMeetingInstanceStatus: MeetingInstancePayload;
  updateMetric?: Maybe<MetricPayload>;
  updateNotificationTemplate: UpdateNotificationTemplatePayload;
  updateOKRCycle: UpdateOkrCyclePayload;
  updateOKRDetails?: Maybe<OkrPayload>;
  updateObjective: UpdateObjectivePayload;
  updateOrgRole: UpdateOrgRolePayload;
  updatePath: UpdatePathPayload;
  updatePeerFeedback?: Maybe<PeerFeedbackPayload>;
  updatePerformanceStatus: MutationResponse;
  updatePraise?: Maybe<PraisePayload>;
  updateQuestions?: Maybe<UpdateQuestionsPayload>;
  updateQuiz: UpdateQuizPayload;
  updateReport?: Maybe<UpdateReportPayload>;
  updateResource: UpdateResourcePayload;
  updateReviewCycle: ReviewCyclePayload;
  updateScorecard: UpdateScorecardPayload;
  updateScorm: ScormPayload;
  updateSelfLearningContent: UpdateExternalContentPayload;
  updateSharedItemsDuplicationStatus: MutationResponse;
  updateSkill: SkillPayload;
  updateSkillMatrix?: Maybe<SkillMatrixType>;
  updateStaffRole: RoleType;
  /** Mutation to update status of event */
  updateStatusOfClassroomEvent?: Maybe<UpdateStatusOfClassroomEventResponse>;
  updateSurvey: UpdateSurveyPayload;
  updateSurveyPerformance: MutationResponse;
  updateTag: TagUnion;
  updateTask: UpdateTaskPayload;
  updateTrack: UpdateTrackPayload;
  updateUser: UserType;
  updateUserCollectionGroup: UserCollectionGroupPayload;
  updateUserInvite: MutationResponse;
  updateUsers: MutationResponse;
  updateUsersPerformanceFlag: UpdateUsersPerformanceFlagPayload;
  updateUsersStaffRoles: MutationResponse;
  updateWorkspace: WorkspaceType;
  upsertBlocks: UpsertBlocksPayload;
  upsertCommunity: CommunityType;
  upsertCourse: UpsertCoursePayload;
  upsertDatabaseData?: Maybe<UpsertDatabaseDataPayload>;
  upsertDatabaseFields?: Maybe<UpsertDatabaseFieldsPayload>;
  upsertHomework: UpsertHomeworkPayload;
  upsertIntegrationDetail: IntegrationDetailPayload;
  upsertOKR?: Maybe<OkrPayload>;
  upsertPage: UpsertPagePayload;
  upsertProduct: UpsertProductPayload;
  upsertScorecardReview: UpsertScorecardReviewPayload;
  upsertStatuses: MutationResponse;
  upsertUserActivityMetrics: MutationResponse;
  upsertUserView?: Maybe<UserViewPayload>;
  upsertWidgets: UpsertWidgetsPayload;
  /** Mutation corresponding to learner actions on onboard invitation to classroom */
  userOnboardClassroom: UserOnboardClassroomPayload;
  viewChannel?: Maybe<ViewChannelPayload>;
};

export type MutationAddChildBlockArgs = {
  addChildBlockInput: AddChildBlockInput;
};

export type MutationAddContentsToGroupArgs = {
  data: ModifyEntitiesInGroupInput;
};

export type MutationAddConvertedMediaArgs = {
  data: AddConvertedMediaInput;
};

export type MutationAddExerciseSubmissionFeedbackArgs = {
  data: AddExerciseSubmissionFeedbackInput;
};

export type MutationAddPermissionsArgs = {
  data: AddPermissionsInput;
};

export type MutationAddReactionArgs = {
  data: AddReactionInput;
};

export type MutationAddRecordingsToEventArgs = {
  data: AddRecordingsToEventInput;
};

export type MutationAddSubmissionFeedbackArgs = {
  data: AddSubmissionFeedbackInput;
};

export type MutationAddUserPushNotificationTokensArgs = {
  input: AddUserPushNotificationTokenInput;
};

export type MutationAddUserSearchActivityArgs = {
  input: AddUserSearchActivityInput;
};

export type MutationAddUsersInEventArgs = {
  data: AddUsersInEventInput;
};

export type MutationAddUsersToEventScheduleArgs = {
  data: AddUsersToEventScheduleInput;
};

export type MutationAddUsersToGroupArgs = {
  data: ModifyUsersInGroupInput;
};

export type MutationAddUsersToStaffRolesArgs = {
  input: AddUsersToStaffRolesInput;
};

export type MutationArchiveClassroomArgs = {
  data: ArchiveClassroomInput;
};

export type MutationArchiveClassroomEventArgs = {
  data: ArchiveClassroomEventInput;
};

export type MutationArchiveGroupArgs = {
  data: ArchiveGroupInput;
};

export type MutationArchiveTagArgs = {
  data: ArchiveTagInput;
};

export type MutationAssignContentArgs = {
  input: AssignContentInput;
};

export type MutationAssignHomeworkArgs = {
  data: AssignHomeworkInput;
};

export type MutationAttachActivityMetricsArgs = {
  data: AttachActivityMetricsInput;
};

export type MutationAwardPointsArgs = {
  input: AwardPointsInput;
};

export type MutationCancelGenerativeTaskArgs = {
  data: CancelGenerativeTaskInput;
};

export type MutationCancelJobArgs = {
  data: CancelJobInput;
};

export type MutationCancelTransactionArgs = {
  data: UpdateTransactionInput;
};

export type MutationChangeDueDateArgs = {
  data: ChangeDueDateInput;
};

export type MutationCheckinKeyResultArgs = {
  input: CheckinKeyResultInput;
};

export type MutationCheckinObjectiveArgs = {
  input: CheckinObjectiveInput;
};

export type MutationConvertMediaArgs = {
  input: ConvertMediaInput;
};

export type MutationCreateActionItemArgs = {
  data: CreateActionItemInput;
};

export type MutationCreateActionItemsArgs = {
  data: CreateActionItemsInput;
};

export type MutationCreateAgendaItemArgs = {
  data: CreateAgendaItemInput;
};

export type MutationCreateAgendaItemTemplateArgs = {
  data: CreateAgendaItemTemplateInput;
};

export type MutationCreateArticleArgs = {
  data: CreateArticleInput;
};

export type MutationCreateAssignmentArgs = {
  data: CreateAssignmentInput;
};

export type MutationCreateBlockArgs = {
  createBlockInput: CreateBlockInput;
};

export type MutationCreateBookmarkArgs = {
  input: CreateBookmarkInput;
};

export type MutationCreateCategoryArgs = {
  data: UpsertCategoryInput;
};

export type MutationCreateCertificateArgs = {
  data: CreateCertificateInput;
};

export type MutationCreateChannelArgs = {
  data: CreateChannelInput;
};

export type MutationCreateChartArgs = {
  data: CreateChartInput;
};

export type MutationCreateCheckinArgs = {
  data: CreateCheckinInput;
};

export type MutationCreateCheckoutSessionArgs = {
  data: CreateCheckoutSessionInput;
};

export type MutationCreateClassroomArgs = {
  data: CreateClassroomInput;
};

export type MutationCreateClassroomSettingArgs = {
  data: UpsertClassroomSettingInput;
};

export type MutationCreateCohortArgs = {
  data: CreateCohortInput;
};

export type MutationCreateCommentThreadArgs = {
  data: CreateCommentThreadInput;
};

export type MutationCreateDatabaseArgs = {
  input: CreateDatabaseInput;
};

export type MutationCreateDatabaseViewArgs = {
  input: CreateDatabaseViewInput;
};

export type MutationCreateDepartmentArgs = {
  data: CreateDepartmentInput;
};

export type MutationCreateEmailDigestArgs = {
  data: CreateEmailDigestInput;
};

export type MutationCreateEventArgs = {
  data: CreateEventInput;
};

export type MutationCreateEventScheduleArgs = {
  data: CreateEventScheduleInput;
};

export type MutationCreateExerciseArgs = {
  data: CreateExerciseInput;
};

export type MutationCreateExternalContentArgs = {
  input: CreateExternalContentInput;
};

export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};

export type MutationCreateFeedbackQuestionArgs = {
  input: CreateFeedbackQuestionInput;
};

export type MutationCreateFileArgs = {
  data: CreateFileInput;
};

export type MutationCreateGenerativeTaskArgs = {
  data: CreateGenerativeTaskInput;
};

export type MutationCreateGroupArgs = {
  data: CreateGroupInput;
};

export type MutationCreateHomeworkArgs = {
  data: CreateHomeworkInput;
};

export type MutationCreateImportJobArgs = {
  data: CreateImportJobInput;
};

export type MutationCreateIndividualEventArgs = {
  data: CreateIndividualEventInput;
};

export type MutationCreateMeetingArgs = {
  data: CreateMeetingInput;
};

export type MutationCreateMeetingInstanceArgs = {
  data: CreateMeetingInstanceInput;
};

export type MutationCreateMetricArgs = {
  data: CreateMetricInput;
};

export type MutationCreateOkrCycleArgs = {
  input: CreateOkrCycleInput;
};

export type MutationCreateObjectiveArgs = {
  input: CreateObjectiveInput;
};

export type MutationCreateOrgRoleArgs = {
  data: CreateOrgRoleInput;
};

export type MutationCreateOutlookCalendarEventFromMeetingArgs = {
  data: CreateOutlookCalendarEventFromMeetingInput;
};

export type MutationCreatePathArgs = {
  data: CreatePathInput;
};

export type MutationCreatePeerFeedbackArgs = {
  data: CreatePeerFeedbackInput;
};

export type MutationCreatePostArgs = {
  data: CreatePostInput;
};

export type MutationCreatePraiseArgs = {
  data: CreatePraiseInput;
};

export type MutationCreatePublicApiCredentialsArgs = {
  input: CreatePublicApiCredentialsInput;
};

export type MutationCreateQuestionsArgs = {
  data: CreateQuestionsInput;
};

export type MutationCreateQuizArgs = {
  input: CreateQuizInput;
};

export type MutationCreateReportArgs = {
  data: CreateReportInput;
};

export type MutationCreateResourceArgs = {
  data: CreateResourceInput;
};

export type MutationCreateReviewCycleArgs = {
  input: CreateReviewCycleInput;
};

export type MutationCreateRoleArgs = {
  data: CreateRoleInput;
};

export type MutationCreateScorecardArgs = {
  data: CreateScorecardInput;
};

export type MutationCreateScormArgs = {
  data: CreateScormInput;
};

export type MutationCreateSelfLearningContentArgs = {
  input: CreateSelfLearningContentInput;
};

export type MutationCreateSkillArgs = {
  input: CreateSkillInput;
};

export type MutationCreateSkillMatrixArgs = {
  data: CreateSkillMatrixInput;
};

export type MutationCreateSquadChannelArgs = {
  data: CreateSquadChannelInput;
};

export type MutationCreateStaffRoleArgs = {
  input: CreateStaffRoleInput;
};

export type MutationCreateSurveyArgs = {
  data: CreateSurveyInput;
};

export type MutationCreateTagArgs = {
  data: CreateTagInput;
};

export type MutationCreateTaskArgs = {
  data: CreateTaskInput;
};

export type MutationCreateTrackArgs = {
  data: CreateTrackInput;
};

export type MutationCreateUserArgs = {
  data: CreateUserInput;
};

export type MutationCreateUserCollectionGroupArgs = {
  data: CreateUserCollectionGroupInput;
};

export type MutationDeleteActionItemArgs = {
  data: DeleteActionItemInput;
};

export type MutationDeleteAgendaItemArgs = {
  data: DeleteAgendaItemInput;
};

export type MutationDeleteAgendaItemTemplateArgs = {
  data: DeleteAgendaItemTemplateInput;
};

export type MutationDeleteAssignmentArgs = {
  data: DeleteAssignmentInput;
};

export type MutationDeleteBookmarkArgs = {
  input: DeleteBookmarkInput;
};

export type MutationDeleteClassroomEventArgs = {
  data: DeleteClassroomEventInput;
};

export type MutationDeleteContentArgs = {
  input: DeleteContentInput;
};

export type MutationDeleteDepartmentArgs = {
  data: DeleteDepartmentInput;
};

export type MutationDeleteEmailDigestArgs = {
  data: DeleteEmailDigestInput;
};

export type MutationDeleteEventScheduleArgs = {
  data: DeleteEventScheduleInput;
};

export type MutationDeleteFileArgs = {
  data: DeleteFileInput;
};

export type MutationDeleteMeetingArgs = {
  data: DeleteMeetingInput;
};

export type MutationDeleteMeetingInstancesArgs = {
  data: DeleteMeetingInstancesInput;
};

export type MutationDeleteMetricArgs = {
  data: DeleteMetricInput;
};

export type MutationDeleteOkrArgs = {
  input: DeleteOkrInput;
};

export type MutationDeleteOkrCycleArgs = {
  input: DeleteOkrCycleInput;
};

export type MutationDeleteOrgRoleArgs = {
  data: DeleteOrgRoleInput;
};

export type MutationDeletePeerFeedbackArgs = {
  data: DeletePeerFeedbackInput;
};

export type MutationDeletePostArgs = {
  data: DeletePostInput;
};

export type MutationDeletePraiseArgs = {
  data: DeletePraiseInput;
};

export type MutationDeleteReportArgs = {
  data: DeleteReportInput;
};

export type MutationDeleteResourceArgs = {
  data: DeleteResourceInput;
};

export type MutationDeleteReviewCycleArgs = {
  input: DeleteReviewCycleInput;
};

export type MutationDeleteSkillArgs = {
  input: DeleteSkillInput;
};

export type MutationDeleteSkillMatrixArgs = {
  data: DeleteSkillMatrixInput;
};

export type MutationDeleteSkillReviewArgs = {
  data: DeleteSkillReviewInput;
};

export type MutationDeleteStaffRoleArgs = {
  input: DeleteStaffRoleInput;
};

export type MutationDeleteTrackArgs = {
  data: DeleteTrackInput;
};

export type MutationDeleteTracksArgs = {
  data: DeleteTracksInput;
};

export type MutationDeleteUserActivityMetricsArgs = {
  data: DeleteUserActivityMetricsInput;
};

export type MutationDeleteUserSearchActivityArgs = {
  input: DeleteUserSearchActivityInput;
};

export type MutationDeleteUserViewArgs = {
  data: DeleteUserViewInput;
};

export type MutationDeleteWidgetsArgs = {
  input: DeleteWidgetsInput;
};

export type MutationDiscardUnpublishedChangesArgs = {
  data: DiscardUnpublishedChangesInput;
};

export type MutationDisconnectIntegrationDetailArgs = {
  data: DisconnectIntegrationDetailInput;
};

export type MutationDuplicateAgendaItemArgs = {
  data: DuplicateAgendaItemInput;
};

export type MutationDuplicateAgendaItemTemplateArgs = {
  data: DuplicateAgendaItemTemplateInput;
};

export type MutationDuplicateContentArgs = {
  data: DuplicateContentInput;
};

export type MutationDuplicateEventArgs = {
  data: DuplicateEventInput;
};

export type MutationDuplicateMetricArgs = {
  data: DuplicateMetricInput;
};

export type MutationDuplicateOkrArgs = {
  data: DuplicateOkrInput;
};

export type MutationDuplicateReportArgs = {
  data: DuplicateReportInput;
};

export type MutationDuplicateSkillMatrixArgs = {
  data: DuplicateSkillMatrixInput;
};

export type MutationDuplicateSurveyArgs = {
  data: DuplicateSurveyInput;
};

export type MutationEditPostArgs = {
  input: EditPostInput;
};

export type MutationEditWebhookArgs = {
  data: EditWebhookInput;
};

export type MutationEvaluateExerciseSubmissionArgs = {
  data: EvaluateExerciseSubmissionInput;
};

export type MutationEvaluateHomeworkSubmissionArgs = {
  data: EvaluateHomeworkSubmissionInput;
};

export type MutationEventSharingSelfEnrollCourseArgs = {
  input: EventSharingSelfEnrollCourseInput;
};

export type MutationExcuseStudentsFromAssignmentArgs = {
  input: ExcuseStudentsFromAssignmentInput;
};

export type MutationExportTagReportArgs = {
  data: ExportTagReportInput;
};

export type MutationForceCompleteContentProgressArgs = {
  input: ForceCompleteContentProgressInput;
};

export type MutationGenerateLearnerLinkArgs = {
  data: GenerateLearnerLinkInput;
};

export type MutationImportContentFromMarketplaceArgs = {
  input: ImportContentFromMarketplaceInput;
};

export type MutationImportSkilTemplatesArgs = {
  input: ImportSkillTemplatesInput;
};

export type MutationImportSkillMatrixTemplateArgs = {
  data: ImportSkillMatrixTemplateInput;
};

export type MutationInviteUserArgs = {
  data: InviteUserInput;
};

export type MutationInviteUsersToClassroomArgs = {
  data: InviteUsersToClassroomInput;
};

export type MutationJoinEventArgs = {
  data: JoinEventInput;
};

export type MutationLoadOkrSummaryArgs = {
  data: LoadOkrSummaryInput;
};

export type MutationMakeExerciseSubmissionArgs = {
  data: MakeExerciseSubmissionInput;
};

export type MutationMakeHomeworkSubmissionArgs = {
  data: MakeHomeworkSubmissionInput;
};

export type MutationMarkAttendancesArgs = {
  data: MarkAttendancesInput;
};

export type MutationMarkEventAttendanceArgs = {
  input: MarkEventAttendanceInput;
};

export type MutationMarkOfflineEventAttendanceArgs = {
  input: MarkOfflineEventAttendanceInput;
};

export type MutationMarkSubmissionAsFeatureArgs = {
  data: MarkSubmissionAsFeatureInput;
};

export type MutationMergeFileArgs = {
  data: MergeFileInput;
};

export type MutationModifyTaskCreationConfigsArgs = {
  data: ModifyTaskCreationConfigsInput;
};

export type MutationModifyUsersInEventArgs = {
  data: ModifyUsersInEventInput;
};

export type MutationMoveFileArgs = {
  data: MoveFileInput;
};

export type MutationMoveMeetingInstanceAgendaItemsArgs = {
  data: MoveMeetingInstanceAgendaItemsInput;
};

export type MutationMoveOkrArgs = {
  data: MoveOkrInput;
};

export type MutationMoveResourceArgs = {
  data: MoveResourceInput;
};

export type MutationNudgeReviewersArgs = {
  data: NudgeReviewersInput;
};

export type MutationOnboardClassroomUsersArgs = {
  data: OnboardClassroomUsersInput;
};

export type MutationPublishArticleArgs = {
  data: PublishArticleInput;
};

export type MutationPublishAssignmentArgs = {
  data: PublishAssignmentInput;
};

export type MutationPublishCommunityArgs = {
  input: PublishCommunityInput;
};

export type MutationPublishContentArgs = {
  data: PublishContentInput;
};

export type MutationPublishCourseArgs = {
  data: PublishCourseInput;
};

export type MutationReadNotificationsArgs = {
  input: ReadNotificationsInput;
};

export type MutationReattemptQuestionsArgs = {
  input: ReattemptQuestionsInput;
};

export type MutationRecalibrateContentProgressArgs = {
  input: RecalibrateContentProgressInput;
};

export type MutationRegenerateMeetingInstanceSummaryArgs = {
  data: RegenerateMeetingInstanceSummaryInput;
};

export type MutationRegisterEventArgs = {
  data: RegisterEventInput;
};

export type MutationRegisterWebhookArgs = {
  data: RegisterWebhookInput;
};

export type MutationRemoveActionItemTagArgs = {
  data: RemoveEntityTagInput;
};

export type MutationRemoveContentTagArgs = {
  input: RemoveEntityTagInput;
};

export type MutationRemoveContentsFromGroupArgs = {
  data: ModifyEntitiesInGroupInput;
};

export type MutationRemoveDatabaseDataArgs = {
  input: RemoveDatabaseDataInput;
};

export type MutationRemoveEntityAccessArgs = {
  data: RemoveEntityAccessInput;
};

export type MutationRemoveEntityFromMetricArgs = {
  data: RemoveEntityFromMetricInput;
};

export type MutationRemoveEventTagArgs = {
  data: RemoveEntityTagInput;
};

export type MutationRemoveMarketplaceImportedContentArgs = {
  input: RemoveMarketplaceImportedContentInput;
};

export type MutationRemoveOkrTagArgs = {
  data: RemoveEntityTagInput;
};

export type MutationRemovePermissionsArgs = {
  data: RemovePermissionsInput;
};

export type MutationRemovePostAttachmentArgs = {
  data: RemovePostAttachmentsInput;
};

export type MutationRemoveQuestionsArgs = {
  data: RemoveQuestionsInput;
};

export type MutationRemoveReactionArgs = {
  data: RemoveReactionInput;
};

export type MutationRemoveUserPushNotificationTokensArgs = {
  input: RemoveUserPushNotificationTokensInput;
};

export type MutationRemoveUserTagArgs = {
  input: RemoveEntityTagInput;
};

export type MutationRemoveUsersFromClassroomArgs = {
  data: RemoveUsersFromClassroomInput;
};

export type MutationRemoveUsersFromClassroomEventArgs = {
  data: RemoveUsersFromClassroomEventInput;
};

export type MutationRemoveUsersFromEventScheduleArgs = {
  data: RemoveUsersFromEventScheduleInput;
};

export type MutationRemoveUsersFromGroupArgs = {
  data: ModifyUsersInGroupInput;
};

export type MutationRemoveUsersFromStaffArgs = {
  input: RemoveUsersFromStaffInput;
};

export type MutationRemoveUsersFromStaffRolesArgs = {
  input: RemoveUsersFromStaffRolesInput;
};

export type MutationRemoveWebhookArgs = {
  data: RemoveWebhookInput;
};

export type MutationRequestClassroomAccessArgs = {
  data: RequestClassroomAccessInput;
};

export type MutationRequestConnectionArgs = {
  input: RequestConnectionInput;
};

export type MutationRequestPeerFeedbackArgs = {
  data: RequestPeerFeedbackInput;
};

export type MutationRescheduleMeetingInstanceArgs = {
  data: RescheduleMeetingInstanceInput;
};

export type MutationResetContentProgressArgs = {
  input: ResetContentProgressInput;
};

export type MutationResubmitExerciseSubmissionArgs = {
  input: ResubmitExerciseSubmissionInput;
};

export type MutationRetakeQuizArgs = {
  input: RetakeQuizInput;
};

export type MutationReturnAssignmentGradeToStudentsArgs = {
  data: ReturnAssignmentGradeToStudentsInput;
};

export type MutationRevertPerformancesToNotSubmittedArgs = {
  data: RevertPerformancesToNotSubmittedInput;
};

export type MutationReviewConnectionArgs = {
  input: ReviewConnectionInput;
};

export type MutationRollUpDurationOfBlocksArgs = {
  input: RollUpDurationOfBlocksInput;
};

export type MutationSaveWorkspaceAgendaItemsArgs = {
  data: SaveWorkspaceAgendaItemsInput;
};

export type MutationSendAcademyTemplateArgs = {
  input: SendAcademyTemplateInput;
};

export type MutationSendMailArgs = {
  input: SendMailInput;
};

export type MutationSetEntityAccessArgs = {
  data: SetEntityAccessInput;
};

export type MutationSetProductStatusArgs = {
  data: SetProductStatusInput;
};

export type MutationSetupAcademyArgs = {
  input: SetupAcademyInput;
};

export type MutationShareItemsArgs = {
  input: ShareItemsInput;
};

export type MutationShareTagsArgs = {
  data: ShareTagsInput;
};

export type MutationStartContentArgs = {
  input: StartContentInput;
};

export type MutationStartQuizArgs = {
  input: StartQuizInput;
};

export type MutationStartQuizQuestionTimerArgs = {
  input: StartQuizQuestionTimerInput;
};

export type MutationStopItemsSharingArgs = {
  input: StopItemsSharingInput;
};

export type MutationSubmitQuizArgs = {
  input: SubmitQuizInput;
};

export type MutationSubmitQuizQuestionArgs = {
  input: SubmitQuizQuestionInput;
};

export type MutationSubmitQuizWithQuestionsArgs = {
  input: SubmitQuizWithQuestionsInput;
};

export type MutationSubmitReviewsArgs = {
  input: SubmitReviewsInput;
};

export type MutationSubmitSkillReviewArgs = {
  data: SubmitSkillReviewInput;
};

export type MutationSyncExternalPerformanceArgs = {
  input?: InputMaybe<SyncExternalPerformanceInput>;
};

export type MutationSyncProgressArgs = {
  input: Array<SyncProgressInput>;
};

export type MutationSyncTasksArgs = {
  data: SyncTasksInput;
};

export type MutationSyncUsersArgs = {
  data: SyncUsersInput;
};

export type MutationUnAssignContentArgs = {
  input: UnAssignContentInput;
};

export type MutationUndoClassroomInviteRejectionArgs = {
  data: UndoClassroomInviteRejectionInput;
};

export type MutationUndoMoveMeetingInstanceAgendaItemsArgs = {
  data: UndoMoveMeetingInstanceAgendaItemsInput;
};

export type MutationUnregisterEventArgs = {
  data: RegisterEventInput;
};

export type MutationUpdateActionItemArgs = {
  data: UpdateActionItemInput;
};

export type MutationUpdateAgendaItemArgs = {
  data: UpdateAgendaItemInput;
};

export type MutationUpdateAgendaItemTemplateArgs = {
  data: UpdateAgendaItemTemplateInput;
};

export type MutationUpdateAgendaItemsArgs = {
  data: UpdateAgendaItemsInput;
};

export type MutationUpdateArticleArgs = {
  data: UpdateArticleInput;
};

export type MutationUpdateAssignmentArgs = {
  data: UpdateAssignmentInput;
};

export type MutationUpdateCategoryArgs = {
  data: UpsertCategoryInput;
};

export type MutationUpdateCertificateTemplateArgs = {
  data: UpdateCertificateTemplateInput;
};

export type MutationUpdateChartArgs = {
  data: UpdateChartInput;
};

export type MutationUpdateChildOkRsOrderArgs = {
  data: UpdateChildOkRsOrderInput;
};

export type MutationUpdateClassroomArgs = {
  data: UpdateClassroomInput;
};

export type MutationUpdateClassroomEventArgs = {
  data: UpdateClassroomEventInput;
};

export type MutationUpdateClassroomSettingArgs = {
  data: UpsertClassroomSettingInput;
};

export type MutationUpdateContentStatusArgs = {
  data: UpdateContentStatusInput;
};

export type MutationUpdateDatabaseMetadataArgs = {
  input: UpdateDatabaseMetadataInput;
};

export type MutationUpdateDatabaseViewArgs = {
  input: UpdateDatabaseViewInput;
};

export type MutationUpdateDepartmentArgs = {
  data: UpdateDepartmentInput;
};

export type MutationUpdateEmailDigestArgs = {
  data: UpdateEmailDigestInput;
};

export type MutationUpdateEntitiesTasksRelationArgs = {
  data: UpdateEntitiesTasksRelationInput;
};

export type MutationUpdateEntityAttachmentsArgs = {
  data: UpdateEntityAttachmentsInput;
};

export type MutationUpdateEntityCustomStatusArgs = {
  data: UpdateEntityCustomStatusInput;
};

export type MutationUpdateEntityTagsArgs = {
  input: UpdateEntityTagsInput;
};

export type MutationUpdateEventArgs = {
  data: UpdateEventInput;
};

export type MutationUpdateEventScheduleArgs = {
  data: UpdateEventScheduleInput;
};

export type MutationUpdateEventScheduleRecurrenceRuleConfigurationArgs = {
  data: UpdateEventScheduleRecurrenceRuleConfigurationInput;
};

export type MutationUpdateEventScheduleRoleOfUsersArgs = {
  data: UpdateEventScheduleRoleOfUsersInput;
};

export type MutationUpdateEventScheduleStatusArgs = {
  data: UpdateEventScheduleStatusInput;
};

export type MutationUpdateEventStatusArgs = {
  data: UpdateEventStatusInput;
};

export type MutationUpdateEventVisibilitySettingsArgs = {
  data: UpdateEventVisibilitySettingsInput;
};

export type MutationUpdateExerciseArgs = {
  data: UpdateExerciseInput;
};

export type MutationUpdateExternalContentArgs = {
  input: UpdateExternalContentInput;
};

export type MutationUpdateExternalContentPerformanceArgs = {
  input: UpdateExternalContentPerformanceInput;
};

export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};

export type MutationUpdateFeedbackQuestionArgs = {
  input: UpdateFeedbackQuestionInput;
};

export type MutationUpdateFileArgs = {
  data: UpdateFileInput;
};

export type MutationUpdateGroupArgs = {
  data: UpdateGroupInput;
};

export type MutationUpdateJiraPrioritiesMappingArgs = {
  data: UpdateJiraPrioritiesMappingInput;
};

export type MutationUpdateJiraStatusesMappingArgs = {
  data: UpdateJiraStatusesMappingInput;
};

export type MutationUpdateJiraStatusesProgressMappingArgs = {
  data: UpdateJiraStatusesProgressMappingInput;
};

export type MutationUpdateMeetingArgs = {
  data: UpdateMeetingInput;
};

export type MutationUpdateMeetingInstanceAgendaItemsOrderArgs = {
  data: UpdateMeetingInstanceAgendaItemsOrderInput;
};

export type MutationUpdateMeetingInstancePrivateNotesArgs = {
  data: UpdateMeetingInstancePrivateNotesInput;
};

export type MutationUpdateMeetingInstanceStatusArgs = {
  data: UpdateMeetingInstanceStatusInput;
};

export type MutationUpdateMetricArgs = {
  data: UpdateMetricInput;
};

export type MutationUpdateNotificationTemplateArgs = {
  input: UpdateNotificationTemplateInput;
};

export type MutationUpdateOkrCycleArgs = {
  input: UpdateOkrCycleInput;
};

export type MutationUpdateOkrDetailsArgs = {
  data: UpdateOkrDetailsInput;
};

export type MutationUpdateObjectiveArgs = {
  input: UpdateObjectiveInput;
};

export type MutationUpdateOrgRoleArgs = {
  data: UpdateOrgRoleInput;
};

export type MutationUpdatePathArgs = {
  data: UpdatePathInput;
};

export type MutationUpdatePeerFeedbackArgs = {
  data: UpdatePeerFeedbackInput;
};

export type MutationUpdatePerformanceStatusArgs = {
  data: UpdatePerformanceStatusInput;
};

export type MutationUpdatePraiseArgs = {
  data: UpdatePraiseInput;
};

export type MutationUpdateQuestionsArgs = {
  data: UpdateQuestionsInput;
};

export type MutationUpdateQuizArgs = {
  data: UpdateQuizInput;
};

export type MutationUpdateReportArgs = {
  data: UpdateReportInput;
};

export type MutationUpdateResourceArgs = {
  data: UpdateResourceInput;
};

export type MutationUpdateReviewCycleArgs = {
  input: UpdateReviewCycleInput;
};

export type MutationUpdateScorecardArgs = {
  data: UpdateScorecardInput;
};

export type MutationUpdateScormArgs = {
  data: UpdateScormInput;
};

export type MutationUpdateSelfLearningContentArgs = {
  input: UpdateSelfLearningContentInput;
};

export type MutationUpdateSharedItemsDuplicationStatusArgs = {
  input: UpdateSharedItemsDuplicationStatusInput;
};

export type MutationUpdateSkillArgs = {
  input: UpdateSkillInput;
};

export type MutationUpdateSkillMatrixArgs = {
  data: UpdateSkillMatrixInput;
};

export type MutationUpdateStaffRoleArgs = {
  input: UpdateStaffRoleInput;
};

export type MutationUpdateStatusOfClassroomEventArgs = {
  data: UpdateStatusOfClassroomEventInput;
};

export type MutationUpdateSurveyArgs = {
  data: UpdateSurveyInput;
};

export type MutationUpdateSurveyPerformanceArgs = {
  input: UpdateSurveyPerformanceInput;
};

export type MutationUpdateTagArgs = {
  data: UpdateTagInput;
};

export type MutationUpdateTaskArgs = {
  data: UpdateTaskInput;
};

export type MutationUpdateTrackArgs = {
  data: UpdateTrackInput;
};

export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};

export type MutationUpdateUserCollectionGroupArgs = {
  data: UpdateUserCollectionGroupInput;
};

export type MutationUpdateUserInviteArgs = {
  data: UpdateUserInviteInput;
};

export type MutationUpdateUsersArgs = {
  data: UpdateUsersInput;
};

export type MutationUpdateUsersPerformanceFlagArgs = {
  data: UpdateUsersPerformanceFlagInput;
};

export type MutationUpdateUsersStaffRolesArgs = {
  data: UpdateUsersStaffRolesInput;
};

export type MutationUpdateWorkspaceArgs = {
  data: UpdateWorkspaceInput;
};

export type MutationUpsertBlocksArgs = {
  upsertBlocksInput: UpsertBlocksInput;
};

export type MutationUpsertCommunityArgs = {
  input: UpsertCommunityInput;
};

export type MutationUpsertCourseArgs = {
  data: UpsertCourseInput;
};

export type MutationUpsertDatabaseDataArgs = {
  input: UpsertDatabaseDataInput;
};

export type MutationUpsertDatabaseFieldsArgs = {
  input: UpsertDatabaseFieldsInput;
};

export type MutationUpsertHomeworkArgs = {
  input: UpsertHomeworkInput;
};

export type MutationUpsertIntegrationDetailArgs = {
  data: UpsertIntegrationDetailInput;
};

export type MutationUpsertOkrArgs = {
  data: UpsertOkrInput;
};

export type MutationUpsertPageArgs = {
  data: UpsertPageInput;
};

export type MutationUpsertProductArgs = {
  data: UpsertProductInput;
};

export type MutationUpsertScorecardReviewArgs = {
  data: UpsertScorecardReviewInput;
};

export type MutationUpsertStatusesArgs = {
  data: UpsertStatusesInput;
};

export type MutationUpsertUserActivityMetricsArgs = {
  data: UpsertUserActivityMetricsInput;
};

export type MutationUpsertUserViewArgs = {
  data: UpsertUserViewInput;
};

export type MutationUpsertWidgetsArgs = {
  input: UpsertWidgetsInput;
};

export type MutationUserOnboardClassroomArgs = {
  data: UserOnboardClassroomInput;
};

export type MutationViewChannelArgs = {
  data: ViewChannelInput;
};

/** Mutation Response */
export type MutationResponse = {
  __typename?: 'MutationResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type MyEventsWidgetPreviewType = {
  __typename?: 'MyEventsWidgetPreviewType';
  /** events */
  events?: Maybe<Array<Scalars['JSON']>>;
  type: WidgetTypeEnum;
};

/** Payment Create Checkout Session Mutation Payload For NA */
export type NaCheckoutSessionMutationPayload = {
  __typename?: 'NACheckoutSessionMutationPayload';
  paymentProvider: TransactionPaymentProviderEnum;
  success?: Maybe<Scalars['Boolean']>;
  transactionIds?: Maybe<Array<Scalars['String']>>;
};

export type NavConfigProperties = {
  __typename?: 'NavConfigProperties';
  children: Array<NavbarElement>;
};

export type NavbarElement = {
  __typename?: 'NavbarElement';
  children?: Maybe<Array<NavbarMenuChild>>;
  icon?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  label: Scalars['String'];
  props?: Maybe<Scalars['String']>;
  type: NavbarElementTypeEnum;
};

export enum NavbarElementTypeEnum {
  Link = 'LINK',
  Menu = 'MENU',
}

export type NavbarMenuChild = {
  __typename?: 'NavbarMenuChild';
  description: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  label: Scalars['String'];
  props?: Maybe<Scalars['String']>;
  type: NavbarElementTypeEnum;
};

export type NestedDueDateConfig = {
  __typename?: 'NestedDueDateConfig';
  dueDateConfig: DueDateAssignmentConfig;
  itemId: Scalars['String'];
  /** Type of the item being assigned */
  itemType: AssignmentItemTypeEnum;
};

export type NestedDueDateConfigInput = {
  dueDateConfig: DueDateAssignmentConfigInput;
  itemId: Scalars['String'];
  /** Type of the item being assigned */
  itemType: AssignmentItemTypeEnum;
};

export type NextInstanceAgendaItemIdsSetInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export enum NotificationActionTypeEnum {
  AssignContent = 'ASSIGN_CONTENT',
  AssignSingleContent = 'ASSIGN_SINGLE_CONTENT',
  BatchedOkrDueDateReminder = 'BATCHED_OKR_DUE_DATE_REMINDER',
  BulkAssignContent = 'BULK_ASSIGN_CONTENT',
  BulkEntityRevoked = 'BULK_ENTITY_REVOKED',
  BulkEntityShared = 'BULK_ENTITY_SHARED',
  CertificateCreation = 'CERTIFICATE_CREATION',
  ClassroomPlatformUserInvitation = 'CLASSROOM_PLATFORM_USER_INVITATION',
  ClassroomUserForgetPassword = 'CLASSROOM_USER_FORGET_PASSWORD',
  ClassroomUserInvitation = 'CLASSROOM_USER_INVITATION',
  ClassroomUserVerification = 'CLASSROOM_USER_VERIFICATION',
  CommunityChannelMentioned = 'COMMUNITY_CHANNEL_MENTIONED',
  ConnectionRequested = 'CONNECTION_REQUESTED',
  ContentCompleted = 'CONTENT_COMPLETED',
  ContentExpired = 'CONTENT_EXPIRED',
  ContentMajorVersionUpdate = 'CONTENT_MAJOR_VERSION_UPDATE',
  ContentOverdue = 'CONTENT_OVERDUE',
  ContentReviewDue = 'CONTENT_REVIEW_DUE',
  ContentSubmitted = 'CONTENT_SUBMITTED',
  ContentUserInvitation = 'CONTENT_USER_INVITATION',
  DueDateReminder = 'DUE_DATE_REMINDER',
  EnterprisePlatformUserInvitation = 'ENTERPRISE_PLATFORM_USER_INVITATION',
  EntityRevoked = 'ENTITY_REVOKED',
  EntityShared = 'ENTITY_SHARED',
  EventArchived = 'EVENT_ARCHIVED',
  EventPostWorkAvailable = 'EVENT_POST_WORK_AVAILABLE',
  EventRecordingAvailable = 'EVENT_RECORDING_AVAILABLE',
  EventRegistration = 'EVENT_REGISTRATION',
  ExerciseApproved = 'EXERCISE_APPROVED',
  ExerciseApprovedAndFeatured = 'EXERCISE_APPROVED_AND_FEATURED',
  ExerciseFeatured = 'EXERCISE_FEATURED',
  ExerciseRejected = 'EXERCISE_REJECTED',
  ExerciseSubmitted = 'EXERCISE_SUBMITTED',
  ExportJobCompleted = 'EXPORT_JOB_COMPLETED',
  HabitReminder = 'HABIT_REMINDER',
  LowQuizScore = 'LOW_QUIZ_SCORE',
  MeetingInstanceCreated = 'MEETING_INSTANCE_CREATED',
  NudgeReviewCyClEReviewers = 'NUDGE_REVIEW_CYClE_REVIEWERS',
  OkrsUpdated = 'OKRS_UPDATED',
  OkrCheckin = 'OKR_CHECKIN',
  OkrCycleCheckinReminder = 'OKR_CYCLE_CHECKIN_REMINDER',
  OkrCycleCompletionReminder = 'OKR_CYCLE_COMPLETION_REMINDER',
  OkrCycleUpdated = 'OKR_CYCLE_UPDATED',
  OkrDeleted = 'OKR_DELETED',
  OkrDueDateReminder = 'OKR_DUE_DATE_REMINDER',
  OkrOwnerChanged = 'OKR_OWNER_CHANGED',
  OneOnOneDeactivated = 'ONE_ON_ONE_DEACTIVATED',
  OneOnOneInstanceRescheduled = 'ONE_ON_ONE_INSTANCE_RESCHEDULED',
  OneOnOneMissed = 'ONE_ON_ONE_MISSED',
  OneOnOneReminder = 'ONE_ON_ONE_REMINDER',
  OneOnOneScheduleUpdate = 'ONE_ON_ONE_SCHEDULE_UPDATE',
  OneOnOneSetup = 'ONE_ON_ONE_SETUP',
  OneOnOneStart = 'ONE_ON_ONE_START',
  OneOnOneTemplateSetup = 'ONE_ON_ONE_TEMPLATE_SETUP',
  OneOnOneTemplateUpdated = 'ONE_ON_ONE_TEMPLATE_UPDATED',
  PathUpdated = 'PATH_UPDATED',
  PeerFeedbackGiven = 'PEER_FEEDBACK_GIVEN',
  PeerFeedbackRequested = 'PEER_FEEDBACK_REQUESTED',
  PraiseGiven = 'PRAISE_GIVEN',
  ResetProgress = 'RESET_PROGRESS',
  ReviewCycleStarted = 'REVIEW_CYCLE_STARTED',
  ReviewCyClEEnded = 'REVIEW_CYClE_ENDED',
  SingleEntityRevoked = 'SINGLE_ENTITY_REVOKED',
  SingleEntityShared = 'SINGLE_ENTITY_SHARED',
  SourceConnectionReviewed = 'SOURCE_CONNECTION_REVIEWED',
  StorePlatformUserInvitation = 'STORE_PLATFORM_USER_INVITATION',
  StoreUserForgetPassword = 'STORE_USER_FORGET_PASSWORD',
  StoreUserVerification = 'STORE_USER_VERIFICATION',
  SubmissionFeatured = 'SUBMISSION_FEATURED',
  SubmissionFeedbackAdded = 'SUBMISSION_FEEDBACK_ADDED',
  TeamMeetingSetup = 'TEAM_MEETING_SETUP',
  TrainingAttended = 'TRAINING_ATTENDED',
  TrainingInProgress = 'TRAINING_IN_PROGRESS',
  TrainingNotAttended = 'TRAINING_NOT_ATTENDED',
  TrainingStartReminder = 'TRAINING_START_REMINDER',
  UnAssignContent = 'UN_ASSIGN_CONTENT',
  UserMasquerading = 'USER_MASQUERADING',
  UserMention = 'USER_MENTION',
  WorkspaceCreated = 'WORKSPACE_CREATED',
  WorkspaceLearnerInvitation = 'WORKSPACE_LEARNER_INVITATION',
  WorkspaceLowSeatCount = 'WORKSPACE_LOW_SEAT_COUNT',
  WorkspaceStaffInvitation = 'WORKSPACE_STAFF_INVITATION',
  WorkspaceUpcomingAutomaticRenewal = 'WORKSPACE_UPCOMING_AUTOMATIC_RENEWAL',
  WorkspaceUpcomingClosedRenewal = 'WORKSPACE_UPCOMING_CLOSED_RENEWAL',
  WorkspaceUpcomingDeactivation = 'WORKSPACE_UPCOMING_DEACTIVATION',
  WorkspaceUpcomingRenewalOpportunity = 'WORKSPACE_UPCOMING_RENEWAL_OPPORTUNITY',
}

export type NotificationConfigurationInput = {
  /** The type of notification for which the configuration is being set */
  actionType: NotificationActionTypeEnum;
  /** Should notifications be sent to learners for the corresponding actionType */
  notifyLearners?: InputMaybe<Scalars['Boolean']>;
  /** Reminder configurations for the corresponding actionType */
  reminderConfigurations: Array<ReminderConfigurationInput>;
};

export type NotificationConfigurationType = {
  __typename?: 'NotificationConfigurationType';
  /** The type of notification for which the configuration is being set */
  actionType: NotificationActionTypeEnum;
  /** Should notifications be sent to learners for the corresponding actionType */
  notifyLearners: Scalars['Boolean'];
  /** Reminder configurations for the corresponding actionType */
  reminderConfigurations: Array<ReminderConfigurationType>;
};

export enum NotificationGroupTypeEnum {
  Assignments = 'ASSIGNMENTS',
  Content = 'CONTENT',
  Invites = 'INVITES',
  LyearnConnect = 'LYEARN_CONNECT',
  Okr = 'OKR',
  OneOnOne = 'ONE_ON_ONE',
  Others = 'OTHERS',
  PraiseAndFeedback = 'PRAISE_AND_FEEDBACK',
  Sessions = 'SESSIONS',
  SubmissionReviews = 'SUBMISSION_REVIEWS',
  SubAccount = 'SUB_ACCOUNT',
}

export type NotificationTaskRequirementAudit = {
  __typename?: 'NotificationTaskRequirementAudit';
  bell?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  push?: Maybe<Scalars['Boolean']>;
  webhook?: Maybe<Scalars['Boolean']>;
};

export type NotificationTemplate = {
  __typename?: 'NotificationTemplate';
  actionType: NotificationActionTypeEnum;
  areRemindersGloballyConfigurable?: Maybe<Scalars['Boolean']>;
  availableAs: Array<TaskTypeEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  defaultReminderType?: Maybe<ReminderTypeEnum>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<NotificationGroupTypeEnum>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isConfigurable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reminderConfigurations?: Maybe<Array<ReminderConfigurationType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  tasksRequired: NotificationTaskRequirementAudit;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Edge */
export type NotificationTemplateEdge = {
  __typename?: 'NotificationTemplateEdge';
  node: NotificationTemplate;
};

/** Get Notification Templates */
export type NotificationTemplatesFilters = {
  actionTypes?: InputMaybe<Array<NotificationActionTypeEnum>>;
  isConfigurable?: InputMaybe<Scalars['Boolean']>;
};

/** Notification Token object. */
export type NotificationTokenInput = {
  /** device Id for identifying the device. */
  deviceId: Scalars['String'];
  /** TokenId, generated by FCM or any external Messaging Service */
  tokenId: Scalars['String'];
  /** type of device, like web, android etc */
  type?: InputMaybe<NotificationTokenType>;
};

export enum NotificationTokenType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

export type NudgeReviewCycleReviewersUserNotification = {
  __typename?: 'NudgeReviewCycleReviewersUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  endDate: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  pendingReviewsCount: Scalars['Float'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  reviewCycleId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type NudgeReviewersInput = {
  reviewCycleId: Scalars['String'];
  revieweeIds?: InputMaybe<Array<Scalars['String']>>;
  reviewerIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum NumberFormatEnum {
  Dollar = 'DOLLAR',
  Euro = 'EURO',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Pound = 'POUND',
  Rupee = 'RUPEE',
  Yen = 'YEN',
}

export type NumberTagPropsInput = {
  numberType: NumberTagTypeEnum;
};

export type NumberTagType = {
  __typename?: 'NumberTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']>;
  /** common tag union fields */
  id: Scalars['String'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** number type tag specific fields */
  numberType: NumberTagTypeEnum;
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  /** field resolvers */
  taggedContentCount: Scalars['Float'];
  taggedEventCount: Scalars['Float'];
  taggedOKRCount: Scalars['Float'];
  taggedTaskCount: Scalars['Float'];
  taggedUserCount: Scalars['Float'];
  type: TagTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type NumberTagTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type NumberTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export enum NumberTagTypeEnum {
  Dollar = 'DOLLAR',
  Euro = 'EURO',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
  Pound = 'POUND',
  Rupee = 'RUPEE',
  Yen = 'YEN',
}

export enum OkrActions {
  Checkin = 'CHECKIN',
  Create = 'CREATE',
  Edit = 'EDIT',
  Read = 'READ',
  Remove = 'REMOVE',
}

/** Input for selecting bulk okrs */
export type OkrBulkSelectConfigInput = {
  excludeOkrIds?: InputMaybe<Array<Scalars['String']>>;
  filter?: InputMaybe<GetOkRsFilters>;
  includeOkrIds?: InputMaybe<Array<Scalars['String']>>;
  operation: OkrBulkSelectOperationEnum;
};

export enum OkrBulkSelectOperationEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
}

/** Checkins for any objective or Key Result */
export type OkrCheckinType = {
  __typename?: 'OKRCheckinType';
  /** Current Value of the key result */
  actualValue?: Maybe<Scalars['Float']>;
  /** Checkin Timestamp */
  checkedInAt: Scalars['String'];
  /**
   * Optional description related to checkin
   * @deprecated use details field with text and media
   */
  description?: Maybe<Scalars['String']>;
  /** Checkin details in text or media */
  details?: Maybe<InstructionType>;
  /** Progress on checkin */
  progress: Scalars['Float'];
  /** Status of Checkin */
  status: OkrStatusEnum;
};

export type OkrCheckinUserNotification = {
  __typename?: 'OKRCheckinUserNotification';
  actorId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  okrCycleId: Scalars['String'];
  okrId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Base Schema for cycle */
export type OkrCycle = {
  __typename?: 'OKRCycle';
  /** completion Date */
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  discussionChannel?: Maybe<ChannelType>;
  /** Cycle OKR Discussion ChannelId. */
  discussionChannelId?: Maybe<Scalars['String']>;
  /** Converted dueDate from dueDate config */
  dueDate: Scalars['String'];
  /** Due date of the cycle */
  dueDateConfig: DueDateConfig;
  /** display emoji or color */
  iconSetting: IconSettings;
  id: Scalars['String'];
  /** Indicates if cycle is deleted */
  isDeleted: Scalars['Boolean'];
  /** Name of cycle */
  name: Scalars['String'];
  objectivePermissions: Array<CycleOkrPermission>;
  objectivesCount?: Maybe<Scalars['Float']>;
  scheduleConfig?: Maybe<ScheduleConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** start date of the cycle */
  startDate: Scalars['String'];
  /** Status for okr, ontrack behind etc */
  status: OkrCycleStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on okr Cycle */
  userPermissions?: Maybe<Array<UserOkrCyclePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  /** store who can view the cycle */
  visibilitySettings: VisibilitySettingsType;
};

export enum OkrCycleActions {
  Create = 'CREATE',
  Edit = 'EDIT',
  Read = 'READ',
  Remove = 'REMOVE',
}

export type OkrCycleCheckinReminderUserNotification = {
  __typename?: 'OKRCycleCheckinReminderUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  okrCycleId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OkrCycleCompletionReminderUserNotification = {
  __typename?: 'OKRCycleCompletionReminderUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  okrCycleId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** This returns paginated OKR Cycles */
export type OkrCycleConnection = {
  __typename?: 'OKRCycleConnection';
  edges?: Maybe<Array<OkrCycleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge of OKR CYCLE */
export type OkrCycleEdge = {
  __typename?: 'OKRCycleEdge';
  node: OkrCycle;
};

export type OkrCycleFilters = {
  /** filter based on cycle createdAt */
  createdAt?: InputMaybe<DateFilter>;
  /** name of cycle to fetch */
  createdBy?: InputMaybe<Scalars['String']>;
  /** id of cycle to fetch */
  cycleId?: InputMaybe<Scalars['String']>;
  /** Returns Cycles which are deleted, non deleted or both */
  deleteStatus?: InputMaybe<DeletedFilterEnum>;
  /** filter based on due date */
  dueDate?: InputMaybe<DateFilter>;
  /** name of cycle to fetch */
  name?: InputMaybe<Scalars['String']>;
  /** Search to perform regex search on the cycle */
  searchText?: InputMaybe<Scalars['String']>;
  /** filter based on start date  */
  startDate?: InputMaybe<DateFilter>;
  /** Status for okr, ontrack behind etc */
  status?: InputMaybe<OkrCycleStatusEnum>;
};

export enum OkrCycleStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
}

export enum OkrCycleSubjects {
  CompanyObjective = 'COMPANY_OBJECTIVE',
  DepartmentObjective = 'DEPARTMENT_OBJECTIVE',
  IndividualObjective = 'INDIVIDUAL_OBJECTIVE',
  OkrCycle = 'OKR_CYCLE',
  TeamObjective = 'TEAM_OBJECTIVE',
}

export type OkrCycleUpdateUserNotification = {
  __typename?: 'OKRCycleUpdateUserNotification';
  actorId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  okrCycleId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OkrCyclesFilters = {
  /** filter based on cycle createdAt */
  createdAt?: InputMaybe<DateFilter>;
  /** creators of cycle */
  creators?: InputMaybe<Array<Scalars['String']>>;
  /** id of cycle to fetch */
  cycleIds?: InputMaybe<Array<Scalars['String']>>;
  /** Returns Cycles which are deleted, non deleted or both */
  deleteStatus?: InputMaybe<DeletedFilterEnum>;
  /** filter based on due date */
  dueDate?: InputMaybe<DateFilter>;
  /** name of cycle to fetch */
  names?: InputMaybe<Array<Scalars['String']>>;
  /** Search to perform regex search on the cycle */
  searchText?: InputMaybe<Scalars['String']>;
  /** filter based on start date  */
  startDate?: InputMaybe<DateFilter>;
  /** Status for okr, ontrack behind etc */
  status?: InputMaybe<Array<OkrCycleStatusEnum>>;
};

export type OkrDeletedUserNotification = {
  __typename?: 'OKRDeletedUserNotification';
  actorId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  okrCycleId: Scalars['String'];
  okrId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** OKR Edge */
export type OkrEdge = {
  __typename?: 'OKREdge';
  node: OkrUnion;
};

export type OkrGroupCount = {
  __typename?: 'OKRGroupCount';
  count: Scalars['Float'];
  status: OkrStatusEnum;
};

export type OkrInput = {
  /** Order of okr in the parent okr child list */
  childOrder?: InputMaybe<Scalars['Float']>;
  /** Id of the OKR Cycle in which this is created */
  cycleId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  dueDateConfig: DueDateConfigInput;
  instruction?: InputMaybe<InstructionInput>;
  name: Scalars['String'];
  /** owners of OKR */
  ownerIds: Array<Scalars['String']>;
  /** ParentId for objective or keyResult */
  parentId?: InputMaybe<Scalars['String']>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
};

/** Data related to integration of any key Result */
export type OkrIntegrationConfig = {
  __typename?: 'OKRIntegrationConfig';
  /** Provider used for progress tracking */
  providerId?: Maybe<Scalars['String']>;
  /** search field or jql query for jira  */
  searchText?: Maybe<Scalars['String']>;
};

export type OkrIntegrationConfigInput = {
  /** Provider used for progress tracking */
  providerId?: InputMaybe<Scalars['String']>;
  /** search field or jql query for jira  */
  searchText?: InputMaybe<Scalars['String']>;
};

export type OkrOwnerChangedUserNotification = {
  __typename?: 'OKROwnerChangedUserNotification';
  actorId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  keyResultIds?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  objectiveId?: Maybe<Scalars['String']>;
  okrCycleId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OkrPayload = {
  __typename?: 'OKRPayload';
  okr?: Maybe<OkrUnion>;
};

export type OkrPermissionInput = {
  /** action to be performed */
  action: OkrActions;
  /** store who can act on subject */
  permission: VisibilitySettingsInput;
  /** subject on which permission action is to be performed */
  subject: OkrPermissionSubjects;
};

export enum OkrPermissionSubjects {
  CompanyObjective = 'COMPANY_OBJECTIVE',
  DepartmentObjective = 'DEPARTMENT_OBJECTIVE',
  IndividualObjective = 'INDIVIDUAL_OBJECTIVE',
  KeyResult = 'KEY_RESULT',
  TeamObjective = 'TEAM_OBJECTIVE',
}

export enum OkrProgressComputationTypeEnum {
  IssueCompletion = 'ISSUE_COMPLETION',
  IssueCount = 'ISSUE_COUNT',
  StoryPointCompletion = 'STORY_POINT_COMPLETION',
  StoryPointCount = 'STORY_POINT_COUNT',
}

/** Data related to progress of any OKR */
export type OkrProgressMeasurementConfig = {
  __typename?: 'OKRProgressMeasurementConfig';
  /** computation type like issues count, etc */
  computationType?: Maybe<OkrProgressComputationTypeEnum>;
  /** type of progress measurement like manual or jira integ etc */
  progressMeasurementType: OkrProgressMeasurementTypeEnum;
};

export type OkrProgressMeasurementConfigInput = {
  /** computation type like issues count, etc */
  computationType?: InputMaybe<OkrProgressComputationTypeEnum>;
  /** type of progress measurement like manual or jira integ etc */
  progressMeasurementType: OkrProgressMeasurementTypeEnum;
};

export enum OkrProgressMeasurementTypeEnum {
  AutoSync = 'AUTO_SYNC',
  Jira = 'JIRA',
  Manual = 'MANUAL',
  Metric = 'METRIC',
  TaskRollup = 'TASK_ROLLUP',
}

export type OkrSelectionConfig = {
  __typename?: 'OKRSelectionConfig';
  excludedOkrIds?: Maybe<Array<Scalars['String']>>;
  okrIds?: Maybe<Array<Scalars['String']>>;
};

export enum OkrStatusEnum {
  AtRisk = 'AT_RISK',
  Behind = 'BEHIND',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  OnTrack = 'ON_TRACK',
}

export enum OkrSubjects {
  Okr = 'OKR',
}

/** OKR Summary */
export type OkrSummaryType = {
  __typename?: 'OKRSummaryType';
  createdAt: Scalars['String'];
  /** what service was used to generate the summary */
  generatedBy: Scalars['String'];
  /** Summary of OKR updates */
  value: Scalars['String'];
};

/** Config for auto creation of tasks in OKRs  */
export type OkrTaskCreationConfig = {
  __typename?: 'OKRTaskCreationConfig';
  actionItemsLinkedCount: Scalars['Float'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** creator of the taskCreation config */
  createdByUser?: Maybe<UserProfile>;
  /** Type of external entity to be tracked by creating action item */
  externalEntityType: ActionItemExternalEntityTypeEnum;
  externalLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Id of the OKR Cycle in which this is created */
  providerId: Scalars['String'];
  /** Optional description related to checkin */
  searchText?: Maybe<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** config for maintaining the status of sync jobs for this particular task creation config */
  syncConfig?: Maybe<SyncConfig>;
  /** Type of config, manual or auto */
  taskCreationType: TaskCreationConfigTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** creator of the taskCreation config */
  updatedByUser?: Maybe<UserProfile>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OkrTaskCreationConfigFilter = {
  /** Id of the OKR Cycle in which this is created */
  providerId?: InputMaybe<Scalars['String']>;
  /** Optional description related to checkin */
  searchText?: InputMaybe<Scalars['String']>;
  /** Type of config, manual or auto */
  taskCreationType?: InputMaybe<TaskCreationConfigTypeEnum>;
};

export type OkrTemplateKeyResultType = {
  __typename?: 'OKRTemplateKeyResultType';
  name: Scalars['String'];
};

export type OkrTemplateStubType = {
  __typename?: 'OKRTemplateStubType';
  childrenKRs?: Maybe<Array<ChildKrStubType>>;
  childrenObjectives?: Maybe<Array<ChildObjectiveStubType>>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type OkrTemplateType = {
  __typename?: 'OKRTemplateType';
  categories?: Maybe<Array<TemplateCategoryType>>;
  categoryIds: Array<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  keyResults?: Maybe<Array<OkrTemplateKeyResultType>>;
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: TemplateStatusEnum;
  type: TemplateTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OkrTemplatesResultType = {
  __typename?: 'OKRTemplatesResultType';
  records: Array<OkrTemplateStubType>;
};

/** Base Schema for any objective or Key Result */
export type OkrType = {
  __typename?: 'OKRType';
  /** all visibility settings of the okr */
  allVisibilitySettings: Array<VisibilitySettingsType>;
  attachments?: Maybe<Array<EntityMediaType>>;
  /** Checkin Timestamp */
  checkedInAt?: Maybe<Scalars['String']>;
  /** Total number of checkins */
  checkinCount?: Maybe<Scalars['Float']>;
  /** All checkins of the okr */
  checkins?: Maybe<Array<OkrCheckinType>>;
  /** will fetch direct child OKRs of the given OKR */
  childOKRs?: Maybe<Array<OkrUnion>>;
  /** Order of okr in the parent okr child list */
  childOrder: Scalars['Float'];
  childOrderForParent: Scalars['Float'];
  childOrders?: Maybe<Array<ParentChildOrder>>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Id of the OKR Cycle in which this is created */
  cycleId: Scalars['String'];
  /** @deprecated use details field with text and media */
  description?: Maybe<Scalars['String']>;
  discussionChannel?: Maybe<ChannelType>;
  discussionChannelId?: Maybe<Scalars['String']>;
  /** Converted dueDate from dueDate config */
  dueDate: Scalars['String'];
  dueDateConfig: DueDateConfig;
  /** Returns true if okr has any child okrs */
  hasChildren: Scalars['Boolean'];
  /** path till rootNode - starting from /parent.../rootNode */
  hierarchyPath?: Maybe<Scalars['String']>;
  /** Hierarchy paths of okr */
  hierarchyPaths?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  /** OKR Instructions in text or media */
  instruction?: Maybe<InstructionType>;
  /** Indicates if okr is deleted */
  isDeleted: Scalars['Boolean'];
  isProgressAutoRolledUp?: Maybe<Scalars['Boolean']>;
  /** Name or Title of Objective or key result */
  name: Scalars['String'];
  noteBlockId?: Maybe<Scalars['String']>;
  /** Cycle of the OKR */
  okrCycle?: Maybe<OkrCycle>;
  /** owners of the okr */
  okrOwners?: Maybe<Array<UserType>>;
  /** returns count of all the direct + indirect child okrs of given okr */
  okrTreeChildrenCount: Scalars['Float'];
  /** owners of OKR */
  ownerIds: Array<Scalars['String']>;
  /** owners of the okr */
  owners?: Maybe<Array<UserProfile>>;
  /** Id of the parent */
  parentId?: Maybe<Scalars['String']>;
  /** Id of the parents */
  parentIds?: Maybe<Array<Scalars['String']>>;
  /** parent OKR of the okr */
  parentOKR?: Maybe<OkrUnion>;
  /** parent OKR of the okr */
  parentOKRs?: Maybe<Array<OkrUnion>>;
  /** Progress done */
  progress: Scalars['Float'];
  /** All the things which are related to okr */
  relatedToEntities?: Maybe<Array<Scalars['String']>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status for okr, ontrack behind etc */
  status: OkrStatusEnum;
  summary?: Maybe<OkrSummaryType>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** config for creating actionItem using externally linked entities */
  taskCreationConfigs?: Maybe<Array<OkrTaskCreationConfig>>;
  /** config for maintaining the status of sync jobs for action items */
  taskSyncConfig?: Maybe<SyncConfig>;
  taskViewConfigs?: Maybe<Array<UserViewFieldSchema>>;
  totalChildOKRCount: Scalars['Float'];
  /** Type for okr, Objective or key result */
  type: OkrTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on okr (objective or key result) */
  userPermissions?: Maybe<Array<UserOkrPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  viewConfig?: Maybe<EntityViewConfigSchema>;
  /** store who can view the objective */
  visibilitySettings: VisibilitySettingsType;
};

/** Base Schema for any objective or Key Result */
export type OkrTypeChildOkRsArgs = {
  filters?: InputMaybe<GetChildOkRsFilters>;
  okrTypes?: InputMaybe<Array<OkrTypeEnum>>;
};

/** Base Schema for any objective or Key Result */
export type OkrTypeChildOrderForParentArgs = {
  parentId: Scalars['String'];
};

/** Base Schema for any objective or Key Result */
export type OkrTypeOkrTreeChildrenCountArgs = {
  types?: InputMaybe<Array<OkrTypeEnum>>;
};

/** Base Schema for any objective or Key Result */
export type OkrTypeParentOkRsArgs = {
  fetchAll?: InputMaybe<Scalars['Boolean']>;
};

/** Base Schema for any objective or Key Result */
export type OkrTypeTagArgs = {
  tagId: Scalars['String'];
};

export enum OkrTypeEnum {
  KeyResult = 'KEY_RESULT',
  Objective = 'OBJECTIVE',
}

/** It will resolve different OKR types */
export type OkrUnion = KeyResultType | ObjectiveType;

export enum OkrViewTypeEnum {
  Flat = 'FLAT',
  Nested = 'NESTED',
}

export type OkRsOrChildOkRsFilters = {
  childOKRsTypes?: InputMaybe<Array<OkrTypeEnum>>;
  okrIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OkRsUpdateUserNotification = {
  __typename?: 'OKRsUpdateUserNotification';
  actorId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  keyResultIds?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
  objectiveId?: Maybe<Scalars['String']>;
  okrCycleId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OkRsWidgetType = {
  __typename?: 'OKRsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  okrGroups: Array<OkrGroupCount>;
  okrs: PaginatedOkRsPayload;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Objective Category Config Type */
export type ObjectiveCategoryConfig = {
  __typename?: 'ObjectiveCategoryConfig';
  /** relatedId stored */
  relatedId?: Maybe<Scalars['String']>;
  /** Type of objective created */
  type: ObjectiveTypeEnum;
};

/** Objective Category Config Type */
export type ObjectiveCategoryConfigInput = {
  /** store the related id such as department ID, managerId etc */
  relatedId?: InputMaybe<Scalars['String']>;
  /** Type of objective created */
  type: ObjectiveTypeEnum;
};

export type ObjectivePermissionsSetInput = {
  /** permissions for objective */
  set: Array<OkrPermissionInput>;
};

/** Objective Type */
export type ObjectiveType = {
  __typename?: 'ObjectiveType';
  /** all visibility settings of the okr */
  allVisibilitySettings: Array<VisibilitySettingsType>;
  attachments?: Maybe<Array<EntityMediaType>>;
  /** Config to store type and id to which it is related */
  categoryConfig: ObjectiveCategoryConfig;
  /** Checkin Timestamp */
  checkedInAt?: Maybe<Scalars['String']>;
  /** Total number of checkins */
  checkinCount?: Maybe<Scalars['Float']>;
  /** All checkins of the okr */
  checkins?: Maybe<Array<OkrCheckinType>>;
  childKeyResultIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** will fetch direct child OKRs of the given OKR */
  childOKRs?: Maybe<Array<OkrUnion>>;
  /** Order of okr in the parent okr child list */
  childOrder: Scalars['Float'];
  childOrderForParent: Scalars['Float'];
  childOrders?: Maybe<Array<ParentChildOrder>>;
  completedAt?: Maybe<Scalars['String']>;
  contributeToProgress?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Id of the OKR Cycle in which this is created */
  cycleId: Scalars['String'];
  /**
   * use details field with text and media
   * @deprecated use details field with text and media
   */
  description?: Maybe<Scalars['String']>;
  discussionChannel?: Maybe<ChannelType>;
  discussionChannelId?: Maybe<Scalars['String']>;
  /** Converted dueDate from dueDate config */
  dueDate: Scalars['String'];
  dueDateConfig: DueDateConfig;
  /** Returns true if okr has any child okrs */
  hasChildren: Scalars['Boolean'];
  /** path till rootNode - starting from /parent.../rootNode */
  hierarchyPath?: Maybe<Scalars['String']>;
  /** Hierarchy paths of okr */
  hierarchyPaths?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  /** OKR Instructions in text or media */
  instruction?: Maybe<InstructionType>;
  /** Indicates if okr is deleted */
  isDeleted: Scalars['Boolean'];
  isProgressAutoRolledUp?: Maybe<Scalars['Boolean']>;
  /** Name or Title of Objective or key result */
  name: Scalars['String'];
  noteBlockId?: Maybe<Scalars['String']>;
  /** Cycle of the OKR */
  okrCycle?: Maybe<OkrCycle>;
  /** owners of the okr */
  okrOwners?: Maybe<Array<UserType>>;
  /** returns count of all the direct + indirect child okrs of given okr */
  okrTreeChildrenCount: Scalars['Float'];
  /** owners of OKR */
  ownerIds: Array<Scalars['String']>;
  /** owners of the okr */
  owners?: Maybe<Array<UserProfile>>;
  /** Id of the parent */
  parentId?: Maybe<Scalars['String']>;
  /** Id of the parents */
  parentIds?: Maybe<Array<Scalars['String']>>;
  /** parent OKR of the okr */
  parentOKR?: Maybe<OkrUnion>;
  /** parent OKR of the okr */
  parentOKRs?: Maybe<Array<OkrUnion>>;
  /** Progress done */
  progress: Scalars['Float'];
  /** All the things which are related to okr */
  relatedToEntities?: Maybe<Array<Scalars['String']>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Status for okr, ontrack behind etc */
  status: OkrStatusEnum;
  summary?: Maybe<OkrSummaryType>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** config for creating actionItem using externally linked entities */
  taskCreationConfigs?: Maybe<Array<OkrTaskCreationConfig>>;
  /** config for maintaining the status of sync jobs for action items */
  taskSyncConfig?: Maybe<SyncConfig>;
  taskViewConfigs?: Maybe<Array<UserViewFieldSchema>>;
  totalChildOKRCount: Scalars['Float'];
  /** Type for okr, Objective or key result */
  type: OkrTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on okr (objective or key result) */
  userPermissions?: Maybe<Array<UserOkrPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  viewConfig?: Maybe<EntityViewConfigSchema>;
  /** store who can view the objective */
  visibilitySettings: VisibilitySettingsType;
};

/** Objective Type */
export type ObjectiveTypeChildOkRsArgs = {
  filters?: InputMaybe<GetChildOkRsFilters>;
  okrTypes?: InputMaybe<Array<OkrTypeEnum>>;
};

/** Objective Type */
export type ObjectiveTypeChildOrderForParentArgs = {
  parentId: Scalars['String'];
};

/** Objective Type */
export type ObjectiveTypeOkrTreeChildrenCountArgs = {
  types?: InputMaybe<Array<OkrTypeEnum>>;
};

/** Objective Type */
export type ObjectiveTypeParentOkRsArgs = {
  fetchAll?: InputMaybe<Scalars['Boolean']>;
};

/** Objective Type */
export type ObjectiveTypeTagArgs = {
  tagId: Scalars['String'];
};

export enum ObjectiveTypeEnum {
  Company = 'COMPANY',
  Department = 'DEPARTMENT',
  Individual = 'INDIVIDUAL',
  Team = 'TEAM',
}

export type OfflineEventAttendanceExportInputPropsInput = {
  eventId: Scalars['String'];
  filters: EventAttendeesFilters;
};

export type OfflineEventAttendanceExportInputPropsType = {
  __typename?: 'OfflineEventAttendanceExportInputPropsType';
  eventId: Scalars['String'];
  filters: EventAttendeesFiltersType;
};

export type OfflineEventAttendanceExportMetadataType = {
  __typename?: 'OfflineEventAttendanceExportMetadataType';
  exportedCount: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type OfflineEventAttendanceImportInputPropsInput = {
  eventId: Scalars['String'];
};

export type OfflineEventAttendanceImportInputPropsType = {
  __typename?: 'OfflineEventAttendanceImportInputPropsType';
  eventId: Scalars['String'];
};

export type OfflineEventAttendanceImportMetadataType = {
  __typename?: 'OfflineEventAttendanceImportMetadataType';
  updatedUsersCount: Scalars['Float'];
};

/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentType = {
  __typename?: 'OfflineEventContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the event */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  description?: Maybe<Scalars['String']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** duration of content in seconds */
  duration: Scalars['Float'];
  event?: Maybe<EventUnion>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  objective: Scalars['String'];
  performance?: Maybe<EventPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  startTime: Scalars['String'];
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Offline Event container content type. Refers to event entity */
export type OfflineEventContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export type OfflineEventProps = {
  location: EventLocationInput;
};

export type OfflineEventType = {
  __typename?: 'OfflineEventType';
  /** reason for event archival */
  archivalReason?: Maybe<Scalars['String']>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  /** count of available seats */
  availableSeatCount?: Maybe<Scalars['Float']>;
  bannerStatus?: Maybe<BannerStatusEnum>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  completionSettings?: Maybe<CompletionSettingsType>;
  conferenceBridgeId: Scalars['String'];
  /** cover image */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /**
   * count of users attended the event from the current workspace
   * @deprecated no longer required
   */
  currentWorkspaceUserCount: Scalars['Float'];
  defaultGroupId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  /** user provided end time in UTC */
  endTime: Scalars['String'];
  /** session recordings */
  eventRecordings?: Maybe<Array<EventRecordingType>>;
  id: Scalars['String'];
  instructorIds: Array<Scalars['String']>;
  instructors: Array<UserType>;
  /** flag to indicate if event is filling fast */
  isFillingFast: Scalars['Boolean'];
  isRevokedAfterCompletion: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  /** flag to indicate if currentUser is an instructor */
  isUserInstructor: Scalars['Boolean'];
  isUserRegistered: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  location: EventLocation;
  /** event metadata */
  meta: EventMetadataType;
  name: Scalars['String'];
  objective: Scalars['String'];
  postWorkContentIds?: Maybe<Array<Scalars['String']>>;
  postWorkContents: Array<ContentEntity>;
  postwork?: Maybe<EventWorkConfig>;
  preWorkContentIds?: Maybe<Array<Scalars['String']>>;
  preWorkContents: Array<ContentEntity>;
  prework?: Maybe<EventWorkConfig>;
  /** Recording URLs of the event */
  recordings?: Maybe<Array<Scalars['String']>>;
  /** time after which registration is closed */
  registrationCloseTime?: Maybe<Scalars['String']>;
  registrationSettings?: Maybe<RegistrationSettings>;
  remainingPostWorkContents: Scalars['Float'];
  remainingPreWorkContents: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  skills: Array<SkillType>;
  /** user provided start time in UTC */
  startTime: Scalars['String'];
  status: EventStatusEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** session time zone */
  timezone: Scalars['String'];
  /** total count of users who attended the event */
  totalUsersCount: Scalars['Float'];
  type: EventTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userIds: Array<Scalars['String']>;
  /** Permissions for a user on event */
  userPermissions?: Maybe<Array<UserEventPermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  users: Array<UserType>;
  usersCount: Scalars['Float'];
  versionNumber?: Maybe<Scalars['Float']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
};

export type OfflineEventTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type OfflineEventTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export type OfflineEventTypeTagArgs = {
  tagId: Scalars['String'];
};

export type OfflineEventUserAttendanceInput = {
  isPresent: Scalars['Boolean'];
  userId: Scalars['String'];
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type OnFailureActions = {
  __typename?: 'OnFailureActions';
  redirect: Scalars['Boolean'];
  redirectionURL: Scalars['String'];
};

export type OnFailureActionsInput = {
  redirect?: InputMaybe<Scalars['Boolean']>;
  redirectionURL?: InputMaybe<Scalars['String']>;
};

export type OnFailureActionsType = {
  __typename?: 'OnFailureActionsType';
  redirect: Scalars['Boolean'];
  redirectionURL: Scalars['String'];
};

export type OnSuccessActions = {
  __typename?: 'OnSuccessActions';
  createUser: Scalars['Boolean'];
  redirect: Scalars['Boolean'];
  redirectionURL: Scalars['String'];
  sendInvitationMail: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
};

export type OnSuccessActionsInput = {
  createUser?: InputMaybe<Scalars['Boolean']>;
  redirect?: InputMaybe<Scalars['Boolean']>;
  redirectionURL?: InputMaybe<Scalars['String']>;
  sendInvitationMail?: InputMaybe<Scalars['Boolean']>;
  updateUser?: InputMaybe<Scalars['Boolean']>;
};

export type OnSuccessActionsType = {
  __typename?: 'OnSuccessActionsType';
  createUser: Scalars['Boolean'];
  redirect: Scalars['Boolean'];
  redirectionURL: Scalars['String'];
  sendInvitationMail: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
};

/** Actions by teacher on request to onboard the classroom by learners */
export type OnboardClassroomUsersInput = {
  classroomId: Scalars['String'];
  onboardStatus: OnboardingUserStatusEnum;
  userIds: Array<Scalars['String']>;
};

export type OnboardClassroomUsersPayload = {
  __typename?: 'OnboardClassroomUsersPayload';
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  userIds?: Maybe<Array<Scalars['String']>>;
};

export enum OnboardStatusEnum {
  Accepted = 'ACCEPTED',
  Invited = 'INVITED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type OnboardingContentType = {
  __typename?: 'OnboardingContentType';
  content: ContentEntity;
  orgRole?: Maybe<OrgRoleType>;
  orgRoleId: Scalars['String'];
};

export type OnboardingContentWidgetPreviewType = {
  __typename?: 'OnboardingContentWidgetPreviewType';
  /** onboarding content */
  orgRole?: Maybe<Scalars['JSON']>;
  type: WidgetTypeEnum;
};

export type OnboardingContentWidgetType = {
  __typename?: 'OnboardingContentWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  onboardingContent?: Maybe<OnboardingContentType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OnboardingInfoType = {
  __typename?: 'OnboardingInfoType';
  expectedTeamSize?: Maybe<ExpectedTeamSizeEnum>;
  lookingFor?: Maybe<Array<Scalars['String']>>;
};

export enum OnboardingUserStatusEnum {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export type OneOnOneDeactivatedUserNotification = {
  __typename?: 'OneOnOneDeactivatedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  partner1Id: Scalars['String'];
  partner2Id: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  senderId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OneOnOneInstanceRescheduledUserNotification = {
  __typename?: 'OneOnOneInstanceRescheduledUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingDateTime: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  partner1Id: Scalars['String'];
  partner2Id: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  senderId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OneOnOneMissedUserNotification = {
  __typename?: 'OneOnOneMissedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingDateTime: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  partner1Id: Scalars['String'];
  partner2Id: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OneOnOneReminderUserNotification = {
  __typename?: 'OneOnOneReminderUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingDateTime: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  partner1Id: Scalars['String'];
  partner2Id: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  senderId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OneOnOneScheduleUpdateUserNotification = {
  __typename?: 'OneOnOneScheduleUpdateUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingDateTime: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  partner1Id: Scalars['String'];
  partner2Id: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OneOnOneSetupUserNotification = {
  __typename?: 'OneOnOneSetupUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingDateTime: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  partner1Id: Scalars['String'];
  partner2Id: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OneOnOneStartUserNotification = {
  __typename?: 'OneOnOneStartUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingDateTime: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  partner1Id: Scalars['String'];
  partner2Id: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OneOnOneTemplateSetupUserNotification = {
  __typename?: 'OneOnOneTemplateSetupUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingDateTime: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  partner1Id: Scalars['String'];
  partner2Id: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OneOnOneTemplateUpdatedUserNotification = {
  __typename?: 'OneOnOneTemplateUpdatedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingDateTime: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  partner1Id: Scalars['String'];
  partner2Id: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** User ongoing learning content */
export type OngoingResultType = {
  __typename?: 'OngoingResultType';
  content?: Maybe<ContentEntity>;
};

export enum OperationEnum {
  Create = 'CREATE',
  Remove = 'REMOVE',
  Update = 'UPDATE',
}

/** Options are used for single select, multi-select or true-false type questions */
export type Option = {
  __typename?: 'Option';
  attachments?: Maybe<Array<EntityMediaType>>;
  optionId: Scalars['String'];
  optionRelativeScore?: Maybe<Scalars['Float']>;
  optionText: Scalars['String'];
};

/** Options are used for single select, multi-select or true-false type questions */
export type OptionInput = {
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  optionEvaluation: Scalars['Boolean'];
  optionId?: InputMaybe<Scalars['String']>;
  optionRelativeScore?: InputMaybe<Scalars['Float']>;
  optionText: Scalars['String'];
};

/** Response for option type questions */
export type OptionResponse = {
  __typename?: 'OptionResponse';
  optionId: Scalars['String'];
  optionText: Scalars['String'];
};

/** Used when response type is SingleSelect, MultiSelect, TrueFalse */
export type OptionResponseInput = {
  optionId: Scalars['String'];
  optionText: Scalars['String'];
};

export type OptionsSetInput = {
  /** Options to be updated */
  set: Array<OptionInput>;
};

export type OrderBy = {
  field?: InputMaybe<Scalars['String']>;
  sortType: OrderEnum;
};

export enum OrderEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum OrgRoleActionsEnum {
  Create = 'CREATE',
  Edit = 'EDIT',
  Read = 'READ',
  Remove = 'REMOVE',
}

/** This returns paginated OrgRole */
export type OrgRoleConnections = {
  __typename?: 'OrgRoleConnections';
  edges?: Maybe<Array<OrgRoleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type OrgRoleEdge = {
  __typename?: 'OrgRoleEdge';
  node: OrgRoleType;
};

/** Org Role Profile */
export type OrgRoleProfileType = {
  __typename?: 'OrgRoleProfileType';
  id: Scalars['String'];
  sampleUsers: Array<UserProfile>;
  userIds: Array<Scalars['String']>;
  usersCount: Scalars['Float'];
};

export type OrgRoleReportConfig = {
  __typename?: 'OrgRoleReportConfig';
  activityMetricConfig?: Maybe<ActivityMetricConfig>;
};

export type OrgRoleReportInput = {
  activityMetricConfig?: InputMaybe<ActivityMetricInput>;
};

export enum OrgRoleStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export enum OrgRoleSubjectsEnum {
  Orgrole = 'ORGROLE',
}

/** OrgRole Schema */
export type OrgRoleType = {
  __typename?: 'OrgRoleType';
  activityCount?: Maybe<Scalars['Float']>;
  activityMetricWeightDistributionConfig?: Maybe<ActivityMetricWeightDistributionConfig>;
  activityMetrics?: Maybe<Array<ActivityMetricType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** OrgRole definition */
  definition?: Maybe<Scalars['String']>;
  department?: Maybe<DepartmentType>;
  /** denotes orgRole belongs to which department */
  departmentId?: Maybe<Scalars['String']>;
  /** OrgRole description */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** unique identifier/abbreviation for an orgRole */
  key?: Maybe<Scalars['String']>;
  learnerProfileActivityMetricReport?: Maybe<ReportType>;
  /** level of orgRole inside a track */
  level?: Maybe<Scalars['Float']>;
  mindsetCount?: Maybe<Scalars['Float']>;
  /** denotes mindsets */
  mindsetIds?: Maybe<Array<Scalars['String']>>;
  mindsets?: Maybe<Array<SkillType>>;
  /** OrgRole Name or Title */
  name: Scalars['String'];
  onboardingPath?: Maybe<PathContentType>;
  /** onboarding path Id which needs to be taken up after joining orgRole */
  onboardingPathId?: Maybe<Scalars['String']>;
  orgRoleProfile?: Maybe<OrgRoleProfileType>;
  reportConfig?: Maybe<OrgRoleReportConfig>;
  sampleUsers: Array<UserType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillCount?: Maybe<Scalars['Float']>;
  skillGroups?: Maybe<Array<SkillGroupType>>;
  /** denotes skills */
  skillIds?: Maybe<Array<Scalars['String']>>;
  skillMatrix?: Maybe<SkillMatrixType>;
  /** skill matrix attached to org role and skill master settings */
  skillMatrixConfig?: Maybe<SkillMatrixConfig>;
  skills?: Maybe<Array<SkillType>>;
  status: OrgRoleStatusEnum;
  track?: Maybe<TrackType>;
  /** denotes orgRole belongs to which track */
  trackId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userCount?: Maybe<Scalars['Float']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type OrgRolesFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  departmentId?: InputMaybe<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['String']>>;
  hasNoParentDepartment?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  matchExactName?: InputMaybe<Scalars['Boolean']>;
  mindsetIds?: InputMaybe<Array<Scalars['String']>>;
  /** name of the orgRole */
  name?: InputMaybe<Scalars['String']>;
  searchText?: InputMaybe<Scalars['String']>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  skillMatrixIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<OrgRoleStatusEnum>>;
  trackIds?: InputMaybe<Array<Scalars['String']>>;
};

/** ReviewCycle Schema */
export type OverDueSettingsType = {
  __typename?: 'OverDueSettingsType';
  /** Max allowed over due in days */
  overdueDays?: Maybe<Scalars['Float']>;
  /** Max allowed over due in minutes */
  overdueMinutes?: Maybe<Scalars['Float']>;
};

export type OwnerIdsSetInput = {
  /** owners of OKR */
  set: Array<Scalars['String']>;
};

export type PageComponentFilters = {
  id: Scalars['String'];
  pageComponentStatus: Array<PageComponentStatusEnum>;
  pageType: PageTypeEnum;
};

export type PageComponentInput = {
  bannerProps?: InputMaybe<BannerPropsInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  props?: InputMaybe<Scalars['String']>;
  status: PageComponentStatusEnum;
  type: PageComponentTypeEnum;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  widgetProps?: InputMaybe<WidgetPropsInput>;
};

export enum PageComponentLayoutTypeEnum {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export type PageComponentPayload = {
  __typename?: 'PageComponentPayload';
  pageComponent?: Maybe<PageComponentType>;
};

export type PageComponentPreviewInput = {
  bannerProps?: InputMaybe<BannerPropsPreviewInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  pageComponentType: PageComponentTypeEnum;
  props?: InputMaybe<Scalars['String']>;
  widgetProps?: InputMaybe<WidgetPropsInput>;
  widgetType?: InputMaybe<WidgetTypeEnum>;
};

/** union of all todos */
export type PageComponentPreviewUnion =
  | LeafPageComponentPreviewType
  | MultiBannerPageComponentPreviewType;

export enum PageComponentStatusEnum {
  Hidden = 'HIDDEN',
  Published = 'PUBLISHED',
}

export type PageComponentType = {
  __typename?: 'PageComponentType';
  /** description */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** layout of the page component */
  layout?: Maybe<PageComponentLayoutTypeEnum>;
  /** name of the page component */
  name: Scalars['String'];
  primaryOrder: Scalars['Float'];
  progress?: Maybe<Scalars['Float']>;
  /** custom JSON properties required by the page component */
  props?: Maybe<Scalars['String']>;
  secondaryOrder: Scalars['Float'];
  /** status of the page component */
  status: PageComponentStatusEnum;
  /** type of the page component */
  type: PageComponentTypeEnum;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  widget?: Maybe<WidgetUnion>;
  /** widget id */
  widgetId?: Maybe<Scalars['String']>;
};

export enum PageComponentTypeEnum {
  Banner = 'BANNER',
  BannerCard = 'BANNER_CARD',
  CardGrid = 'CARD_GRID',
  Carousel = 'CAROUSEL',
  Description = 'DESCRIPTION',
  Heading = 'HEADING',
  IconDescriptionList = 'ICON_DESCRIPTION_LIST',
  IconDescriptionRows = 'ICON_DESCRIPTION_ROWS',
  MarkedDescriptionList = 'MARKED_DESCRIPTION_LIST',
  MarkedLabels = 'MARKED_LABELS',
  MultiBanner = 'MULTI_BANNER',
  OkrListing = 'OKR_LISTING',
  OnboardingProgress = 'ONBOARDING_PROGRESS',
  PaginatedCardGrid = 'PAGINATED_CARD_GRID',
  PerformanceMetrics = 'PERFORMANCE_METRICS',
  SetupCardGrid = 'SETUP_CARD_GRID',
  TaskListing = 'TASK_LISTING',
  WelcomeBanner = 'WELCOME_BANNER',
}

/** union of all page components */
export type PageComponentUnion = LeafPageComponentType | MultiBannerPageComponentType;

/** PagedInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Cursor to fetch the next page */
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type PageInput = {
  pageComponentStatus?: InputMaybe<Array<PageComponentStatusEnum>>;
  type: PageTypeEnum;
};

export type PagePayload = {
  __typename?: 'PagePayload';
  page?: Maybe<PageType__Next>;
};

export type PagePreviewInput = {
  components: Array<PageComponentPreviewInput>;
};

export type PagePreviewPayload = {
  __typename?: 'PagePreviewPayload';
  components: Array<Maybe<PageComponentPreviewUnion>>;
};

export type PageType = {
  __typename?: 'PageType';
  components: Array<PageComponentType>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type: PageTypeEnum;
};

export enum PageTypeEnum {
  AdminGettingStarted = 'ADMIN_GETTING_STARTED',
  ContextualHomepage = 'CONTEXTUAL_HOMEPAGE',
  Discover = 'DISCOVER',
  GettingStarted = 'GETTING_STARTED',
  HomePage = 'HOME_PAGE',
  HomePageConfigurable = 'HOME_PAGE_CONFIGURABLE',
  Library = 'LIBRARY',
  MarketplaceContentCatalog = 'MARKETPLACE_CONTENT_CATALOG',
}

export type PageType__Next = {
  __typename?: 'PageType__Next';
  components: Array<PageComponentUnion>;
  type: PageTypeEnum;
};

export type PaginatedAcademyTemplatesPayload = {
  __typename?: 'PaginatedAcademyTemplatesPayload';
  edges?: Maybe<Array<AcademyTemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedActionItemsPayload = {
  __typename?: 'PaginatedActionItemsPayload';
  edges?: Maybe<Array<ActionItemEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount?: Maybe<Scalars['Float']>;
};

export type PaginatedActivityMetricsPayload = {
  __typename?: 'PaginatedActivityMetricsPayload';
  edges?: Maybe<Array<ActivityMetricEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedAgendaItemTemplatesPayload = {
  __typename?: 'PaginatedAgendaItemTemplatesPayload';
  edges?: Maybe<Array<AgendaItemTemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedAgendaItemsPayload = {
  __typename?: 'PaginatedAgendaItemsPayload';
  edges?: Maybe<Array<AgendaItemEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated analyticsDashboards */
export type PaginatedAnalyticsDashboardsPayload = {
  __typename?: 'PaginatedAnalyticsDashboardsPayload';
  edges?: Maybe<Array<AnalyticsDashboardEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedBookmarkConnections = {
  __typename?: 'PaginatedBookmarkConnections';
  edges?: Maybe<Array<BookmarkEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated channel participants */
export type PaginatedChannelParticipantsPayload = {
  __typename?: 'PaginatedChannelParticipantsPayload';
  edges?: Maybe<Array<ChannelParticipantEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated Events */
export type PaginatedClassrooEventsPayload = {
  __typename?: 'PaginatedClassrooEventsPayload';
  edges?: Maybe<Array<ClassroomEventEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated Events */
export type PaginatedClassroomEvents = {
  __typename?: 'PaginatedClassroomEvents';
  edges?: Maybe<Array<ClassroomEventEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated providers */
export type PaginatedCommonProvidersPayload = {
  __typename?: 'PaginatedCommonProvidersPayload';
  edges?: Maybe<Array<CommonProviderEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedConfigsPayload = {
  __typename?: 'PaginatedConfigsPayload';
  edges?: Maybe<Array<ConfigEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedEmailDigestsPayload = {
  __typename?: 'PaginatedEmailDigestsPayload';
  edges?: Maybe<Array<EmailDigestEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated EventScheduleUsers */
export type PaginatedEventScheduleUsers = {
  __typename?: 'PaginatedEventScheduleUsers';
  edges?: Maybe<Array<EventScheduleUserEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated EventSchedules */
export type PaginatedEventSchedulesPayload = {
  __typename?: 'PaginatedEventSchedulesPayload';
  edges?: Maybe<Array<EventScheduleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated EventUserMetrics */
export type PaginatedEventUserMetrics = {
  __typename?: 'PaginatedEventUserMetrics';
  edges?: Maybe<Array<EventUserMetricsEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated EventUserMetrics */
export type PaginatedEventUserMetricsPayload = {
  __typename?: 'PaginatedEventUserMetricsPayload';
  edges?: Maybe<Array<EventUserMetricsEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedEventsPayload = {
  __typename?: 'PaginatedEventsPayload';
  edges?: Maybe<Array<EventEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedExternalMappingsPayload = {
  __typename?: 'PaginatedExternalMappingsPayload';
  edges?: Maybe<Array<ExternalMappingEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedFilesPayload = {
  __typename?: 'PaginatedFilesPayload';
  edges?: Maybe<Array<FileEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedGenerativeTasksPayload = {
  __typename?: 'PaginatedGenerativeTasksPayload';
  edges?: Maybe<Array<GenerativeTaskEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedGroupedActionItemEdge = {
  __typename?: 'PaginatedGroupedActionItemEdge';
  node: GroupedActionItemEdge;
};

export type PaginatedGroupedActionItemsPayload = {
  __typename?: 'PaginatedGroupedActionItemsPayload';
  edges: Array<PaginatedGroupedActionItemEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
  totalGroupCount: Scalars['Float'];
};

export type PaginatedIntegrationDetailsPayload = {
  __typename?: 'PaginatedIntegrationDetailsPayload';
  edges?: Maybe<Array<IntegrationDetailEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedIntegrationProvidersPayload = {
  __typename?: 'PaginatedIntegrationProvidersPayload';
  edges?: Maybe<Array<IntegrationProviderEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedJiraIssuesPayload = {
  __typename?: 'PaginatedJiraIssuesPayload';
  edges?: Maybe<Array<JiraIssueEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedJobsPayload = {
  __typename?: 'PaginatedJobsPayload';
  edges?: Maybe<Array<JobEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedMarketplaceContentCategoriesPayload = {
  __typename?: 'PaginatedMarketplaceContentCategoriesPayload';
  edges?: Maybe<Array<MarketplaceContentCategoryEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedMarketplaceContentsPayload = {
  __typename?: 'PaginatedMarketplaceContentsPayload';
  edges?: Maybe<Array<MarketplaceContentEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedMeetingInstancesPayload = {
  __typename?: 'PaginatedMeetingInstancesPayload';
  edges?: Maybe<Array<MeetingInstanceEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedMeetingsPayload = {
  __typename?: 'PaginatedMeetingsPayload';
  edges?: Maybe<Array<MeetingEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedMetricsPayload = {
  __typename?: 'PaginatedMetricsPayload';
  edges?: Maybe<Array<MetricEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated notification templates */
export type PaginatedNotificationTemplatesPayload = {
  __typename?: 'PaginatedNotificationTemplatesPayload';
  edges?: Maybe<Array<NotificationTemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedOkrCyclesPayload = {
  __typename?: 'PaginatedOKRCyclesPayload';
  edges?: Maybe<Array<OkrCycleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedOkRsPayload = {
  __typename?: 'PaginatedOKRsPayload';
  edges?: Maybe<Array<OkrEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedPeerFeedbackTemplatesPayload = {
  __typename?: 'PaginatedPeerFeedbackTemplatesPayload';
  edges?: Maybe<Array<PeerFeedbackTemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedPeerFeedbacksPayload = {
  __typename?: 'PaginatedPeerFeedbacksPayload';
  edges?: Maybe<Array<PeerFeedbackEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedPraisesPayload = {
  __typename?: 'PaginatedPraisesPayload';
  edges?: Maybe<Array<PraiseEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedReportsPayload = {
  __typename?: 'PaginatedReportsPayload';
  edges?: Maybe<Array<ReportEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated review cycles */
export type PaginatedReviewCyclesPayload = {
  __typename?: 'PaginatedReviewCyclesPayload';
  edges?: Maybe<Array<ReviewCycleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated SharedConnectionInfo */
export type PaginatedSharedConnectionInfo = {
  __typename?: 'PaginatedSharedConnectionInfo';
  edges?: Maybe<Array<SharedConnectionInfoEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedStatusesPayload = {
  __typename?: 'PaginatedStatusesPayload';
  edges?: Maybe<Array<StatusEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedTagsPayload = {
  __typename?: 'PaginatedTagsPayload';
  edges?: Maybe<Array<TagEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount?: Maybe<Scalars['Float']>;
};

export type PaginatedTemplateCategoriesPayload = {
  __typename?: 'PaginatedTemplateCategoriesPayload';
  edges?: Maybe<Array<TemplateCategoryEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedTemplatesPayload = {
  __typename?: 'PaginatedTemplatesPayload';
  edges?: Maybe<Array<TemplateEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedTracksPayload = {
  __typename?: 'PaginatedTracksPayload';
  edges?: Maybe<Array<TrackEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated transactions */
export type PaginatedTransactions = {
  __typename?: 'PaginatedTransactions';
  edges?: Maybe<Array<TransactionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated userActivities */
export type PaginatedUserActivitiesPayload = {
  __typename?: 'PaginatedUserActivitiesPayload';
  edges?: Maybe<Array<UserActivityEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedUserActivityMetricScoresPayload = {
  __typename?: 'PaginatedUserActivityMetricScoresPayload';
  edges?: Maybe<Array<UserActivityMetricScoreEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedUserActivityMetricsPayload = {
  __typename?: 'PaginatedUserActivityMetricsPayload';
  edges?: Maybe<Array<UserActivityMetricEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** This returns paginated user notifications */
export type PaginatedUserNotificationsPayload = {
  __typename?: 'PaginatedUserNotificationsPayload';
  edges?: Maybe<Array<UserNotificationPayloadEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedUserViewsPayload = {
  __typename?: 'PaginatedUserViewsPayload';
  edges?: Maybe<Array<UserViewEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type PaginatedWebhooksPayload = {
  __typename?: 'PaginatedWebhooksPayload';
  edges?: Maybe<Array<WebhookEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export enum PaginationErrorCodeEnum {
  InvalidCursor = 'INVALID_CURSOR',
}

export type PaginationProps = {
  /** This would be a cursor string */
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  /** This would be a offset number */
  offset?: InputMaybe<Scalars['Float']>;
  order?: InputMaybe<OrderEnum>;
  sortField?: InputMaybe<Scalars['String']>;
  sorts?: InputMaybe<Array<OrderBy>>;
};

/** Pair provides mapping for match the following type questions */
export type Pair = {
  __typename?: 'Pair';
  left: Scalars['String'];
  right: Scalars['String'];
};

/** Pair provides mapping for match the following type questions */
export type PairInput = {
  left: Scalars['String'];
  right: Scalars['String'];
};

/** Represent pair data */
export type PairItem = {
  __typename?: 'PairItem';
  text: Scalars['String'];
};

/** Represent pair data */
export type PairItemInput = {
  text: Scalars['String'];
};

export type ParentChildOrder = {
  __typename?: 'ParentChildOrder';
  childOrder: Scalars['Float'];
  parentId: Scalars['String'];
};

/** Parent related information for the block */
export type ParentDetails = {
  __typename?: 'ParentDetails';
  /** parent Cover */
  cover?: Maybe<EntityMediaType>;
  /** Parent Description, can have course, article etc description */
  description?: Maybe<Scalars['String']>;
  /** parent entity's id  */
  id: Scalars['String'];
  /** Lesson description for the current block */
  lessonDescription?: Maybe<Scalars['String']>;
  /** Lesson Id for the current block */
  lessonId?: Maybe<Scalars['String']>;
  /** Lesson page Id for the current block */
  lessonPageId?: Maybe<Scalars['String']>;
  /** Lesson Title for the current block */
  lessonTitle?: Maybe<Scalars['String']>;
  /** parent name, name of course, article etc. */
  name: Scalars['String'];
  /** parent preview */
  preview?: Maybe<EntityMediaType>;
  /** parent entity's Status */
  status: Scalars['String'];
  /** parent entity's type */
  subType?: Maybe<Scalars['String']>;
  /** parent Tagline */
  tagline?: Maybe<Scalars['String']>;
  /** parent entity's type */
  type: Scalars['String'];
};

export enum ParentEntityTypeEnum {
  Collection = 'COLLECTION',
  Content = 'CONTENT',
  Event = 'EVENT',
}

export type ParentIdSetInput = {
  /** parentId to be updated. If empty, OKR will be made root level */
  set?: InputMaybe<Scalars['String']>;
};

export type ParentIdsSetInput = {
  /** ParentIds for objective or keyResult */
  set?: InputMaybe<Array<Scalars['String']>>;
};

/** This is the input for saving or submitting a quiz question response */
export type PartialQuestionSubmission = {
  /** Used when Response type is Fill in the blank */
  ftbResponse?: InputMaybe<Array<FtbResponseInput>>;
  /** User when Response type is match the following */
  mtfResponse?: InputMaybe<Array<MtfResponseInput>>;
  /** Used when Response type is SingleSelect, MultiSelect, TrueFalse */
  optionResponse?: InputMaybe<Array<OptionResponseInput>>;
  /** Response QuestionId */
  questionId: Scalars['String'];
  responseType: QuestionTypeEnum;
  /** Time spent on question */
  timeSpent: Scalars['Float'];
};

/** Path Type */
export type PathContentType = {
  __typename?: 'PathContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  /** Default Enrolment Settings */
  defaultEnrolmentSettings: DefaultEnrolmentSettingsType;
  defaultGroupId?: Maybe<Scalars['String']>;
  /** Stores the description of the path */
  description?: Maybe<Scalars['String']>;
  draft?: Maybe<DraftPathContentType>;
  dripSettings: DripSettingsType;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** Stores total duration */
  duration: Scalars['Float'];
  /** Feedback Questions for the path */
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Path Medias */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Name of the resource */
  name: Scalars['String'];
  /** Objective of the path content */
  objective?: Maybe<Scalars['String']>;
  /** performance of the current user */
  performance?: Maybe<PathPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  sections?: Maybe<Array<PathSectionType>>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** skill ids */
  skillIds?: Maybe<Array<Scalars['String']>>;
  skills: Array<SkillType>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings: VisibilitySettingsType;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Path Type */
export type PathContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Path Type */
export type PathContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Path Type */
export type PathContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Path Type */
export type PathContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Path Type */
export type PathContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Path Type */
export type PathContentTypeTagArgs = {
  tagId: Scalars['String'];
};

/** User Performance on Learning Path */
export type PathPerformanceType = {
  __typename?: 'PathPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** stores the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  lockSettings: Array<ContentLockSettings>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress?: Maybe<Scalars['Float']>;
  /** resume learning */
  resume?: Maybe<ContentEntity>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** User Metrics for Path Performance */
  userMetrics?: Maybe<PathUserMetrics>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type PathSectionInput = {
  /** learning content ids */
  childResourceIds: Array<Scalars['String']>;
  name: Scalars['String'];
};

/** Path Section Type */
export type PathSectionType = {
  __typename?: 'PathSectionType';
  /** stores the child resource Ids */
  childResourceIds?: Maybe<Array<Scalars['String']>>;
  childResources?: Maybe<Array<ContentEntity>>;
  completedCount?: Maybe<Scalars['Float']>;
  /** Stores the description of the section */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Path Section Medias */
  medias?: Maybe<Array<EntityMediaType>>;
  /** Name of the section */
  name: Scalars['String'];
  /** Stores the order of opening/completion of section */
  order: Scalars['Float'];
};

export type PathUpdatedUserNotification = {
  __typename?: 'PathUpdatedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  pathId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById: Scalars['String'];
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type PathUrlPayload = {
  __typename?: 'PathUrlPayload';
  path: Scalars['String'];
  url: Scalars['String'];
};

/** User Metrics for Path Performance */
export type PathUserMetrics = {
  __typename?: 'PathUserMetrics';
  progress?: Maybe<Scalars['Float']>;
  remainingTime?: Maybe<Scalars['Float']>;
};

/** Edge */
export type PeerFeedbackEdge = {
  __typename?: 'PeerFeedbackEdge';
  node: PeerFeedbackSchema;
};

export type PeerFeedbackFilters = {
  peerFeedbackId: Scalars['String'];
};

export type PeerFeedbackGivenUserNotification = {
  __typename?: 'PeerFeedbackGivenUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  peerFeedbackGiverId: Scalars['String'];
  peerFeedbackId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type PeerFeedbackMindsetRatingInputType = {
  mindsetId: Scalars['String'];
  rating?: InputMaybe<Scalars['Float']>;
};

export type PeerFeedbackMindsetRatingType = {
  __typename?: 'PeerFeedbackMindsetRatingType';
  MindSet?: Maybe<SkillType>;
  mindsetId: Scalars['String'];
  rating?: Maybe<Scalars['Float']>;
};

export type PeerFeedbackPayload = {
  __typename?: 'PeerFeedbackPayload';
  peerFeedback?: Maybe<PeerFeedbackSchema>;
};

export type PeerFeedbackRequestedUserNotification = {
  __typename?: 'PeerFeedbackRequestedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  peerFeedbackId: Scalars['String'];
  peerFeedbackRequesterId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type PeerFeedbackSchema = {
  __typename?: 'PeerFeedbackSchema';
  actionItemsCount: Scalars['Float'];
  /** media */
  attachments: Array<EntityMediaType>;
  channelId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  feedbackMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  learningContentIds?: Maybe<Array<Scalars['String']>>;
  learningContents: Array<ContentEntity>;
  /** Mentions in the message payload on a post like user mentions (@user) */
  mentions?: Maybe<Array<MentionType>>;
  mindsetRatings?: Maybe<Array<PeerFeedbackMindsetRatingType>>;
  postsCount: Scalars['Float'];
  receiverUser?: Maybe<UserType>;
  receiverUserId: Scalars['String'];
  requestMessage?: Maybe<Scalars['String']>;
  senderUser?: Maybe<UserType>;
  senderUserId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillRatings?: Maybe<Array<PeerFeedbackSkillRatingType>>;
  status: PeerFeedbackStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  visibleToManager: Scalars['Boolean'];
};

export type PeerFeedbackSkillRatingInputType = {
  rating?: InputMaybe<Scalars['Float']>;
  skillId: Scalars['String'];
};

export type PeerFeedbackSkillRatingType = {
  __typename?: 'PeerFeedbackSkillRatingType';
  rating?: Maybe<Scalars['Float']>;
  skill?: Maybe<SkillType>;
  skillId: Scalars['String'];
};

export enum PeerFeedbackStatusEnum {
  Completed = 'COMPLETED',
  Requested = 'REQUESTED',
}

/** Edge */
export type PeerFeedbackTemplateEdge = {
  __typename?: 'PeerFeedbackTemplateEdge';
  node: PeerFeedbackTemplateSchema;
};

export type PeerFeedbackTemplateFilters = {
  peerFeedbackTemplateId: Scalars['String'];
};

export type PeerFeedbackTemplateOfTitleSchema = {
  __typename?: 'PeerFeedbackTemplateOfTitleSchema';
  text: Scalars['String'];
};

export type PeerFeedbackTemplateSchema = {
  __typename?: 'PeerFeedbackTemplateSchema';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  templates: Array<PeerFeedbackTemplateOfTitleSchema>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type PeerFeedbackTemplatesFilters = {
  peerFeedbackTemplateIds?: InputMaybe<Array<Scalars['String']>>;
};

export type PeerFeedbacksFilters = {
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  /** Returns peerFeedback which are deleted, non deleted or both */
  deleteStatus?: InputMaybe<DeletedFilterEnum>;
  peerFeedbackIds?: InputMaybe<Array<Scalars['String']>>;
  receiverUserIds?: InputMaybe<Array<Scalars['String']>>;
  senderUserIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<PeerFeedbackStatusEnum>>;
};

/** ScorecardReview Schema */
export type PeerReviewType = {
  __typename?: 'PeerReviewType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entityId: Scalars['String'];
  entityType: ResourceTypeEnum;
  evaluation: ExerciseSubmissionStatusEnum;
  id: Scalars['String'];
  /** retry info object */
  retryInfo?: Maybe<RetryInfoType>;
  reviewType: Scalars['String'];
  /** reviewer for the PeerReview */
  reviewer?: Maybe<UserType>;
  /** reviewer for the assignment */
  reviewerId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** submitted at */
  submissionDate: Scalars['String'];
  submissionId: Scalars['String'];
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user for the PeerReview */
  user?: Maybe<UserType>;
  /** user who submitted the assignment */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type PendingInvitationClassroomFilters = {
  /** onboard status filter */
  onboardStatus: Array<OnboardStatusEnum>;
  /** UserId of user for which pending invitation classrooms needs to be fetched */
  userId: Scalars['String'];
};

export enum PerformanceContentTypeEnum {
  InLessonQuiz = 'IN_LESSON_QUIZ',
  ReviewQuiz = 'REVIEW_QUIZ',
  StaticQuiz = 'STATIC_QUIZ',
}

export enum PerformanceEntityTypeEnum {
  Assignment = 'ASSIGNMENT',
  Block = 'BLOCK',
  Event = 'EVENT',
  Question = 'QUESTION',
  Resource = 'RESOURCE',
}

export enum PerformanceErrorCode {
  AllScorecardQuestionsNotAnswered = 'ALL_SCORECARD_QUESTIONS_NOT_ANSWERED',
  AlreadyReviewed = 'ALREADY_REVIEWED',
  NoPermissionToComment = 'NO_PERMISSION_TO_COMMENT',
  QuizPerformanceNotFound = 'QUIZ_PERFORMANCE_NOT_FOUND',
  QuizQuestionPerformanceNotFound = 'QUIZ_QUESTION_PERFORMANCE_NOT_FOUND',
  SubmissionNotAllowed = 'SUBMISSION_NOT_ALLOWED',
  UnableToUpdatePerformance = 'UNABLE_TO_UPDATE_PERFORMANCE',
}

export enum PerformanceEvaluationStatusEnum {
  Correct = 'CORRECT',
  InCorrect = 'IN_CORRECT',
}

export type PerformanceFilters = {
  entityId: Scalars['String'];
  status?: InputMaybe<Array<PerformanceStatusEnum>>;
  userId: Scalars['String'];
};

export enum PerformanceGradeEnum {
  AboveAverage = 'ABOVE_AVERAGE',
  Average = 'AVERAGE',
  BelowAverage = 'BELOW_AVERAGE',
  Low = 'LOW',
  Top = 'TOP',
}

/** This returns paginated resources */
export type PerformanceInteractionConnections = {
  __typename?: 'PerformanceInteractionConnections';
  edges?: Maybe<Array<PerformanceInteractionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type PerformanceInteractionEdge = {
  __typename?: 'PerformanceInteractionEdge';
  node: PerformanceInteractionEntity;
};

/** Feedback or ScorecardReview or peer review */
export type PerformanceInteractionEntity =
  | CycleReviewType
  | LatestUserReviewType
  | PeerReviewType
  | ScorecardReviewType
  | SkillGroupReviewType
  | SkillReviewType;

export enum PerformanceInteractionErrorCodeEnum {
  AlreadyReviewed = 'ALREADY_REVIEWED',
  UpsertScorecardFailed = 'UPSERT_SCORECARD_FAILED',
}

export type PerformanceInteractionFilters = {
  entityId?: InputMaybe<Scalars['String']>;
  /** ids of performanceInteractions */
  ids?: InputMaybe<Array<Scalars['String']>>;
  reviewCycleId?: InputMaybe<Scalars['String']>;
  reviewCycleIds?: InputMaybe<Array<Scalars['String']>>;
  reviewCycleInstanceId?: InputMaybe<Scalars['String']>;
  reviewerId?: InputMaybe<Scalars['String']>;
  scorecardIds?: InputMaybe<Array<Scalars['String']>>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  skillMatrixIds?: InputMaybe<Array<Scalars['String']>>;
  subType?: InputMaybe<SkillTypeEnum>;
  submissionId?: InputMaybe<Scalars['String']>;
  submissionIds?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<PerformanceInteractionTypeEnum>;
  userId?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum PerformanceInteractionTypeEnum {
  CycleReview = 'CYCLE_REVIEW',
  Feedback = 'FEEDBACK',
  LatestUserReview = 'LATEST_USER_REVIEW',
  LatestUserSkillGroupReview = 'LATEST_USER_SKILL_GROUP_REVIEW',
  LatestUserSkillReview = 'LATEST_USER_SKILL_REVIEW',
  PeerReview = 'PEER_REVIEW',
  ScorecardReview = 'SCORECARD_REVIEW',
  SkillGroupReview = 'SKILL_GROUP_REVIEW',
  SkillReview = 'SKILL_REVIEW',
}

export type PerformanceMetricsWidgetPreviewType = {
  __typename?: 'PerformanceMetricsWidgetPreviewType';
  certificatesCount: Scalars['Float'];
  completedCoursesCount: Scalars['Float'];
  inProgressCourses: Scalars['Float'];
  type: WidgetTypeEnum;
};

export type PerformanceMetricsWidgetType = {
  __typename?: 'PerformanceMetricsWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  certificatesCount: Scalars['Float'];
  completedCoursesCount: Scalars['Float'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  inProgressCourses: Scalars['Float'];
  productivityScore: Scalars['Float'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type PerformanceParentDetails = {
  __typename?: 'PerformanceParentDetails';
  contentType?: Maybe<ResourceTypeEnum>;
  lessonPageId?: Maybe<Scalars['String']>;
};

export enum PerformanceStatusEnum {
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Deassigned = 'DEASSIGNED',
  Excused = 'EXCUSED',
  Graded = 'GRADED',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED',
  NotStarted = 'NOT_STARTED',
  Overdue = 'OVERDUE',
  PeerReviewPending = 'PEER_REVIEW_PENDING',
  Rejected = 'REJECTED',
  Returned = 'RETURNED',
  Submitted = 'SUBMITTED',
}

export enum PerformanceStatusFilterEnum {
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Deassigned = 'DEASSIGNED',
  Excused = 'EXCUSED',
  Graded = 'GRADED',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED',
  NotAssigned = 'NOT_ASSIGNED',
  NotStarted = 'NOT_STARTED',
  Overdue = 'OVERDUE',
  PeerReviewPending = 'PEER_REVIEW_PENDING',
  Rejected = 'REJECTED',
  Returned = 'RETURNED',
  Submitted = 'SUBMITTED',
}

export enum PerformanceSubscriptionOperationEnum {
  FeaturedSubmissions = 'FEATURED_SUBMISSIONS',
}

export enum PerformanceTypeEnum {
  Article = 'ARTICLE',
  Block = 'BLOCK',
  Checkin = 'CHECKIN',
  Course = 'COURSE',
  Exercise = 'EXERCISE',
  ExternalContent = 'EXTERNAL_CONTENT',
  ExternalEvent = 'EXTERNAL_EVENT',
  Homework = 'HOMEWORK',
  Lesson = 'LESSON',
  LiveEvent = 'LIVE_EVENT',
  OfflineEvent = 'OFFLINE_EVENT',
  Path = 'PATH',
  Question = 'QUESTION',
  Quiz = 'QUIZ',
  Scorm = 'SCORM',
  Section = 'SECTION',
  SubTask = 'SUB_TASK',
  Survey = 'SURVEY',
  Task = 'TASK',
  TaskSection = 'TASK_SECTION',
}

export type PerformanceUserFilter = {
  searchText: Scalars['String'];
};

export type PerformanceUsersFilters = {
  searchText: Scalars['String'];
};

export type PerformanceUsersFiltersType = {
  __typename?: 'PerformanceUsersFiltersType';
  searchText: Scalars['String'];
};

export type PerformancesFilters = {
  assignmentId?: InputMaybe<Scalars['String']>;
  attendanceStatus?: InputMaybe<Array<AttendanceStatusEnum>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  classroomId?: InputMaybe<Scalars['String']>;
  contentIds?: InputMaybe<Array<Scalars['String']>>;
  contentTypes?: InputMaybe<Array<Scalars['String']>>;
  direct?: InputMaybe<Scalars['Boolean']>;
  dueDateRange?: InputMaybe<Array<Scalars['String']>>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  entityName?: InputMaybe<Scalars['String']>;
  entityTypes?: InputMaybe<Array<PerformanceEntityTypeEnum>>;
  isAttended?: InputMaybe<Scalars['Boolean']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  isSubmitted?: InputMaybe<Scalars['Boolean']>;
  parentEntityExists?: InputMaybe<Scalars['Boolean']>;
  parentEntityId?: InputMaybe<Scalars['String']>;
  registeredOn?: InputMaybe<DateFilter>;
  /** Filter based on results */
  results?: InputMaybe<Array<QuizResultEnum>>;
  reviewInfoExists?: InputMaybe<Scalars['Boolean']>;
  reviewerId?: InputMaybe<Scalars['String']>;
  reviewsAssigned?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Array<PerformanceStatusEnum>>;
  submissionIds?: InputMaybe<Array<Scalars['String']>>;
  submissionReviewInfoFilter?: InputMaybe<SubmissionReviewInfoFilter>;
  types?: InputMaybe<Array<PerformanceTypeEnum>>;
  userFilter?: InputMaybe<PerformanceUserFilter>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
  userTypes?: InputMaybe<Array<EventUserTypeEnum>>;
};

export type PerformancesFiltersType = {
  __typename?: 'PerformancesFiltersType';
  attendanceStatus?: Maybe<Array<AttendanceStatusEnum>>;
  entityIds?: Maybe<Array<Scalars['String']>>;
  isAttended?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Array<PerformanceStatusEnum>>;
  userTypes?: Maybe<Array<EventUserTypeEnum>>;
};

export type PerformancesWidgetType = {
  __typename?: 'PerformancesWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** performances */
  performances?: Maybe<Array<ContentPerformanceEntity>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum PermissionEntities {
  Channel = 'CHANNEL',
  Resource = 'RESOURCE',
}

export type PermissionInput = {
  groupId?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** Permitted Actions and Subjects on an Entity */
export type PermittedOnType = {
  __typename?: 'PermittedOnType';
  entity: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

/** applicable filters on pointActivity */
export type PointActivitiesFilters = {
  /** classrom id filter for pointActivity */
  classroomId: Scalars['String'];
  /** filter pointActivities based on user ids */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** This returns paginated PointActivity */
export type PointActivityConnection = {
  __typename?: 'PointActivityConnection';
  edges?: Maybe<Array<PointActivityEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type PointActivityEdge = {
  __typename?: 'PointActivityEdge';
  node: PointActivityType;
};

/** It will resolve entities associated with pointActivities */
export type PointActivityEntity = ClassroomEventType;

/** This represents point rewarded on an entity */
export type PointActivityType = {
  __typename?: 'PointActivityType';
  /** behaviour for awarded point */
  behavior?: Maybe<BehaviorType>;
  /** Behavior for which points been rewarded */
  behaviorId: Scalars['String'];
  /** ClassroomId in which points has been rewarded */
  classroomId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entity?: Maybe<PointActivityEntity>;
  /** Entity on which points has been rewarded */
  entityId?: Maybe<Scalars['String']>;
  /** Type of entity for which points has been rewarded */
  entityType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Number of points rewarded */
  points: Scalars['Float'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user to which points has been awarded */
  user?: Maybe<UserType>;
  /** User to which points has been rewarded */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum PointEntityTypeEnum {
  Event = 'EVENT',
}

export enum PointErrorCodeEnum {
  Forbidden = 'FORBIDDEN',
}

/** PostAttachment Edge */
export type PostAttachment = {
  __typename?: 'PostAttachment';
  node: PostAttachmentType;
};

/** This returns paginated PostAttachments */
export type PostAttachmentConnections = {
  __typename?: 'PostAttachmentConnections';
  edges?: Maybe<Array<PostAttachment>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Attachments on a post */
export type PostAttachmentType = {
  __typename?: 'PostAttachmentType';
  /** channelId of the post to which this attachment is attached */
  channelId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** Actual media of the attachment */
  media: EntityMediaType;
  /** post id to which the attachment is attached */
  postId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user id who has attached this attachment */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** workspaceId of the user who attached this attachment */
  userWorkspaceId?: Maybe<Scalars['String']>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Props to fetch PostAttachments on channels or postIds */
export type PostAttachmentsFilters = {
  /** ChannelIds */
  channelIds?: InputMaybe<Array<Scalars['String']>>;
  /** PostIds */
  postIds?: InputMaybe<Array<Scalars['String']>>;
};

/** This returns paginated Post */
export type PostConnections = {
  __typename?: 'PostConnections';
  edges?: Maybe<Array<PostEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Post Edge */
export type PostEdge = {
  __typename?: 'PostEdge';
  node: PostType;
};

/** Edge */
export type PostReactionEdge = {
  __typename?: 'PostReactionEdge';
  node: PostReactionType;
};

/** Input for getting particular reactions on postIds corresponding to the current user */
export type PostReactionFilters = {
  /** ChannelId of Post for which reactions need to be find */
  channelId: Scalars['String'];
  /** Reaction on whichever postId one needs to fetch */
  postIds?: InputMaybe<Array<Scalars['String']>>;
  /** Reaction of the current user you want to fetch */
  reactions?: InputMaybe<Array<Scalars['String']>>;
};

/** Reactions on a post */
export type PostReactionType = {
  __typename?: 'PostReactionType';
  /** channelId of the channel on which the user reacted */
  channelId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** PostId of the post to which user reacted */
  postId: Scalars['String'];
  /** Actual reaction */
  reaction: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** userId of the user who reacted */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** workspaceId of the user who reacted */
  userWorkspaceId?: Maybe<Scalars['String']>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** This returns paginated PostReaction */
export type PostReactionsConnections = {
  __typename?: 'PostReactionsConnections';
  edges?: Maybe<Array<PostReactionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Post/Message of a conversation */
export type PostType = {
  __typename?: 'PostType';
  attachmentCount?: Maybe<Scalars['Float']>;
  attachments?: Maybe<Array<PostAttachmentType>>;
  channel?: Maybe<ChannelType>;
  /** ChannelId of the channel to which post is made */
  channelId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  createdByWorkspaceId?: Maybe<Scalars['String']>;
  currentWorkspaceChannelId: Scalars['String'];
  /** Last message edited time */
  editedAt?: Maybe<Scalars['String']>;
  hiddenBy?: Maybe<UserType>;
  /** Id of User who hid the post */
  hiddenById?: Maybe<Scalars['String']>;
  /** workspaceId of user who hid the post */
  hiddenByWorkspaceId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Flag to hide the post on the channel */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** Flag to pin the post on the channel */
  isPinned?: Maybe<Scalars['Boolean']>;
  /** Mentions in the message payload on a post like user mentions (@user) */
  mentions?: Maybe<Array<MentionType>>;
  /** Message payload of the post */
  message: Scalars['String'];
  /** ParentId of the post like parentId could be a postId on which this post is acting as a reply but rootId is still the channelId */
  parentId?: Maybe<Scalars['String']>;
  pinnedBy?: Maybe<UserType>;
  /** Id of User who pinned the post */
  pinnedById?: Maybe<Scalars['String']>;
  /** workspaceId of user who pinned the post */
  pinnedByWorkspaceId?: Maybe<Scalars['String']>;
  previews?: Maybe<Array<Scalars['JSONObject']>>;
  /** Reactions of various users the post */
  reactions?: Maybe<Array<ReactionType>>;
  replyCount?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated use currentWorkspaceChannelId resolver */
  targetChannelId: Scalars['String'];
  /** Title for post used for announcement like posts */
  title?: Maybe<Scalars['String']>;
  /** Type of post */
  type: PostTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  updatedByWorkspaceId?: Maybe<Scalars['String']>;
  /**
   * [Deprecated] Urls of the post
   * @deprecated use urlsV2
   */
  urls?: Maybe<Array<Scalars['String']>>;
  /** Urls of the post */
  urlsV2?: Maybe<Array<PostUrlType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  /** workspaceId where the post is stored */
  workspaceId?: Maybe<Scalars['String']>;
};

/** Post/Message of a conversation */
export type PostTypeTargetChannelIdArgs = {
  targetChannelMappings?: InputMaybe<Array<TargetChannelMapping>>;
};

export enum PostTypeEnum {
  Post = 'POST',
  Reply = 'REPLY',
}

export type PostUrlInput = {
  /** Handles preview visibility on the post */
  previewEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Url of this post */
  url?: InputMaybe<Scalars['String']>;
};

export type PostUrlType = {
  __typename?: 'PostUrlType';
  /** Handles preview visibility on the post */
  previewEnabled?: Maybe<Scalars['Boolean']>;
  /** Url of this post */
  url?: Maybe<Scalars['String']>;
};

/** Has information of users in a post (thread) */
export type PostUserType = {
  __typename?: 'PostUserType';
  /** channel id */
  channelId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** Stores the time when user last opened the corresponding thread */
  lastViewedAt: Scalars['String'];
  /** post id */
  postId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user id */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type PostsFilters = {
  channelIds?: InputMaybe<Array<Scalars['String']>>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['String']>;
  postIds?: InputMaybe<Array<Scalars['String']>>;
  postedByIds?: InputMaybe<Array<Scalars['String']>>;
  showHiddenPosts?: InputMaybe<Scalars['Boolean']>;
  /** Type of post one needs to fetch */
  types?: InputMaybe<Array<PostTypeEnum>>;
};

/** Edge */
export type PraiseEdge = {
  __typename?: 'PraiseEdge';
  node: PraiseType;
};

export type PraiseFilters = {
  praiseId: Scalars['String'];
};

export type PraiseGivenUserNotification = {
  __typename?: 'PraiseGivenUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  postId: Scalars['String'];
  praiseGiverId: Scalars['String'];
  praiseId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type PraisePayload = {
  __typename?: 'PraisePayload';
  praise?: Maybe<PraiseType>;
};

export type PraiseType = {
  __typename?: 'PraiseType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mindsetRatings?: Maybe<Array<PeerFeedbackMindsetRatingType>>;
  post?: Maybe<PostType>;
  postId: Scalars['String'];
  /** This resolver returns channel of a praise */
  praiseChannel?: Maybe<ChannelType>;
  receiverUserIds: Array<Scalars['String']>;
  receiverUsers: Array<UserType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillRatings?: Maybe<Array<PeerFeedbackSkillRatingType>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  visibleToEveryone: Scalars['Boolean'];
};

export type PraisesFilters = {
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  praiseIds?: InputMaybe<Array<Scalars['String']>>;
  receiverUserIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Product pricing parameters */
export type PricingParameters = {
  /** Represents product coupon */
  coupons?: InputMaybe<Array<Scalars['String']>>;
  /** Represents product currency */
  currency: Scalars['String'];
  /** Represents discount in percentage OR fixed */
  discount?: InputMaybe<Discount>;
  /** Represents price of the product */
  price?: InputMaybe<Scalars['Float']>;
  /** Represents validity of the product */
  validity: Scalars['String'];
};

/** Product pricing parameters */
export type PricingType = {
  __typename?: 'PricingType';
  /** Represents product coupons */
  coupons?: Maybe<Array<Scalars['String']>>;
  /** Represents product currency */
  currency: Scalars['String'];
  /** Represents discount in percentage OR fixed */
  discount?: Maybe<DiscountType>;
  /** Represents price of the product */
  price?: Maybe<Scalars['Float']>;
  /** Represents validity of the product */
  validity: Scalars['String'];
};

export enum ProductActionsEnum {
  Create = 'CREATE',
  Edit = 'EDIT',
  Read = 'READ',
  Remove = 'REMOVE',
}

/** Product Content Types */
export type ProductCollectionContentType = {
  __typename?: 'ProductCollectionContentType';
  /** Duration of content in seconds */
  duration?: Maybe<Scalars['Int']>;
  /** Type of product content */
  type: ProductContentTypeEnum;
};

/** This returns paginated products */
export type ProductConnections = {
  __typename?: 'ProductConnections';
  edges?: Maybe<Array<ProductEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** It will resolve Corporate course or course */
export type ProductContentEntityUnion =
  | ContentType
  | CourseContentType
  | PathContentType
  | ProductCollectionContentType;

export enum ProductContentTypeEnum {
  Classroom = 'CLASSROOM',
  CorporateCourse = 'CORPORATE_COURSE',
  Course = 'COURSE',
  Event = 'EVENT',
  Path = 'PATH',
  ProductCollection = 'PRODUCT_COLLECTION',
  Resource = 'RESOURCE',
}

/** Edge */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  node: ProductType;
};

export enum ProductErrorCodeEnum {
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  UnableToUpdateProductStatus = 'UNABLE_TO_UPDATE_PRODUCT_STATUS',
  UnableToUpsertProduct = 'UNABLE_TO_UPSERT_PRODUCT',
}

export enum ProductStatusEnum {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Listed = 'LISTED',
  Unlisted = 'UNLISTED',
}

export enum ProductSubjectsEnum {
  Performance = 'PERFORMANCE',
  Product = 'PRODUCT',
  Resource = 'RESOURCE',
}

/** Product Type */
export type ProductType = {
  __typename?: 'ProductType';
  /** Represents SEO settings like pageTitle, productDescription, keywords */
  SEOSettings?: Maybe<SeoSettingsType>;
  childProducts?: Maybe<Array<ProductType>>;
  content?: Maybe<ProductContentEntityUnion>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Description of a product */
  description?: Maybe<Scalars['String']>;
  /** Represents generalSettings */
  generalSettings?: Maybe<GeneralSettingsType>;
  id: Scalars['String'];
  /** Represents media in a product */
  media: Array<DeprecatedMediaType>;
  /** Product metrics */
  metrics?: Maybe<MetricsType>;
  /** Name or title of a product */
  name?: Maybe<Scalars['String']>;
  /** Represents pricing parameters like price, currency, discount, validity, coupon */
  pricingParameters?: Maybe<PricingType>;
  /** ids of the product */
  productIds: Array<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Product Status */
  status: ProductStatusEnum;
  /** StoreId of store to which this product belongs to */
  storeId: Scalars['String'];
  /** Product Type */
  type: ProductTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** User permissions on the product */
  userPermissions: Array<UserProductPermissionType>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum ProductTypeEnum {
  Classroom = 'CLASSROOM',
  Collection = 'COLLECTION',
  Course = 'COURSE',
  Event = 'EVENT',
}

export type ProductUserFilters = {
  productId: Scalars['String'];
  userId: Scalars['String'];
};

/** Product User Type */
export type ProductUserType = {
  __typename?: 'ProductUserType';
  certificateId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  productId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  storeId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ProgressInfoInput = {
  attendanceProgress?: InputMaybe<Scalars['Float']>;
  postWorkProgress?: InputMaybe<Scalars['Float']>;
  preWorkProgress?: InputMaybe<Scalars['Float']>;
  recordingProgress?: InputMaybe<Scalars['Float']>;
};

export type ProgressInfoType = {
  __typename?: 'ProgressInfoType';
  attendanceProgress?: Maybe<Scalars['Float']>;
  postWorkProgress?: Maybe<Scalars['Float']>;
  preWorkProgress?: Maybe<Scalars['Float']>;
  recordingProgress?: Maybe<Scalars['Float']>;
};

export enum ProgressSourceEnum {
  Recording = 'RECORDING',
}

export enum ProviderAvailableOnEnum {
  Content = 'CONTENT',
  Event = 'EVENT',
  Metric = 'METRIC',
  Okr = 'OKR',
}

/** This returns paginated providers */
export type ProviderConnections = {
  __typename?: 'ProviderConnections';
  edges?: Maybe<Array<ProviderEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ProviderEdge = {
  __typename?: 'ProviderEdge';
  node: ProviderType;
};

/** The Provider model */
export type ProviderType = {
  __typename?: 'ProviderType';
  availableOn: ProviderAvailableOnEnum;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  externalEventCreationStrategies: Array<ExternalEventCreationStrategyEnum>;
  formInputs?: Maybe<Array<FormComponentType>>;
  id: Scalars['String'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  integrationProviderId?: Maybe<Scalars['String']>;
  logo: EntityMediaType;
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  uniqueId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  /** Type of the external content */
  verificationMethod?: Maybe<VerificationMethodEnum>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ProvidersWidgetType = {
  __typename?: 'ProvidersWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** marketplace contents */
  providers: PaginatedCommonProvidersPayload;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  /** static content ids */
  staticIds?: Maybe<Array<Scalars['String']>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ProvidersWidgetTypeProvidersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
};

/** Filters to view public certificate */
export type PublicCertificateFilters = {
  sharedId: Scalars['String'];
};

export type PublicCertificateType = {
  __typename?: 'PublicCertificateType';
  certificateImage?: Maybe<EntityMediaType>;
  content?: Maybe<PublicContentType>;
  event?: Maybe<PublicEventType>;
  issuedCertificate: CertificateType;
  issuedOn?: Maybe<Scalars['String']>;
  user?: Maybe<UserProfile>;
};

export type PublicContentFilter = {
  contentId: Scalars['String'];
};

export type PublicContentFilters = {
  /** ContentIds */
  contentIds?: InputMaybe<Array<Scalars['String']>>;
  createdAtTimestampRange?: InputMaybe<TimestampRangeInput>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  /** title of content */
  name?: InputMaybe<Scalars['String']>;
  /** Parent id of the content */
  parentId?: InputMaybe<Scalars['String']>;
  performanceFilters?: InputMaybe<ContentPerformanceFilters>;
  status?: InputMaybe<Array<ResourceStatusEnum>>;
  /** get user contents containing a tag that matches the given tagFilters */
  tagFilters?: InputMaybe<Array<PublicTagFilter>>;
  tagOptionsOutputOrder?: InputMaybe<TagOptionsOutputOrderInput>;
  types?: InputMaybe<Array<ResourceTypeEnum>>;
  workspaceIds?: InputMaybe<Array<Scalars['String']>>;
};

export type PublicContentPerformanceInput = {
  userId?: InputMaybe<Scalars['String']>;
};

export type PublicContentType = {
  __typename?: 'PublicContentType';
  id: Scalars['String'];
  name: Scalars['String'];
  type: ResourceTypeEnum;
};

export type PublicCreateAssignmentInput = {
  assignerId?: InputMaybe<Scalars['String']>;
  assignmentItemsDueDateConfig?: InputMaybe<Array<AssignmentItemDueDateConfigInput>>;
  contentIds: Array<Scalars['String']>;
  dueDateConfig?: InputMaybe<DueDateAssignmentConfigInput>;
  name?: InputMaybe<Scalars['String']>;
  userIds: Array<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type PublicCreateAssignmentPayload = {
  __typename?: 'PublicCreateAssignmentPayload';
  assignment: AssignmentType;
};

export type PublicCreateGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  memberIds?: InputMaybe<GroupMembersIds>;
  modifyNameIfExists?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type: GroupTypeEnum;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type PublicCreateTagInput = {
  availableOn?: InputMaybe<Array<TagAvailableOnEnum>>;
  category?: InputMaybe<TagCategoryEnum>;
  dateTagProps?: InputMaybe<DateTagPropsInput>;
  description?: InputMaybe<Scalars['String']>;
  modifyNameIfExists?: InputMaybe<Scalars['Boolean']>;
  multiSelectTagProps?: InputMaybe<MultiSelectTagPropsInput>;
  name: Scalars['String'];
  numberTagProps?: InputMaybe<NumberTagPropsInput>;
  settings?: InputMaybe<UpdateTagSettingsInput>;
  singleSelectTagProps?: InputMaybe<SingleSelectTagPropsInput>;
  source?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<TagSubTypeEnum>;
  textTagProps?: InputMaybe<TextTagPropsInput>;
  type: TagTypeEnum;
};

export type PublicCreateUserInput = {
  emailId?: InputMaybe<Scalars['String']>;
  federationId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  reportsToUniqueId?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<UserRolesTypeInput>;
  status: UserStatusEnum;
  tags?: InputMaybe<PublicEntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']>;
  workspaceIds?: InputMaybe<WorspacesInput>;
};

export type PublicCreateWorkspaceInput = {
  clientWorkspaceId?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<CreateMediaInput>;
  name: Scalars['String'];
};

export type PublicEntityTagInput = {
  dateValue?: InputMaybe<DateValueInput>;
  numberValue?: InputMaybe<Scalars['Float']>;
  optionIdValues?: InputMaybe<Array<Scalars['String']>>;
  optionTextValues?: InputMaybe<Array<Scalars['String']>>;
  tagId: Scalars['String'];
  textValue?: InputMaybe<Scalars['String']>;
  userIdValues?: InputMaybe<Array<Scalars['String']>>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type PublicEntityTagsInput = {
  set: Array<PublicEntityTagInput>;
};

export type PublicEventType = {
  __typename?: 'PublicEventType';
  id: Scalars['String'];
  name: Scalars['String'];
  type: EventTypeEnum;
};

export type PublicGroupFilters = {
  groupId?: InputMaybe<Array<Scalars['String']>>;
};

export type PublicGroupsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<GroupStatusEnum>>;
  types?: InputMaybe<Array<GroupTypeEnum>>;
};

export enum PublicMeetingInstanceStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Late = 'LATE',
  Missed = 'MISSED',
  Scheduled = 'SCHEDULED',
}

export enum PublicMeetingStatusEnum {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
}

export type PublicRemoveWorkspaceInput = {
  id: Scalars['String'];
};

export enum PublicSkillMatrixStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export type PublicTagFilter = {
  tagId: Scalars['String'];
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type PublicUpdateGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  groupId: Scalars['String'];
  memberIds?: InputMaybe<GroupMembersIds>;
  modifyNameIfExists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type PublicUpdateTagInput = {
  availableOn?: InputMaybe<Array<TagAvailableOnEnum>>;
  category?: InputMaybe<TagCategoryEnum>;
  dateTagProps?: InputMaybe<UpdateDateTagPropsInput>;
  description?: InputMaybe<Scalars['String']>;
  modifyNameIfExists?: InputMaybe<Scalars['Boolean']>;
  multiSelectTagProps?: InputMaybe<UpdateMultiSelectTagPropsInput>;
  name?: InputMaybe<Scalars['String']>;
  numberTagProps?: InputMaybe<UpdateNumberTagPropsInput>;
  settings?: InputMaybe<UpdateTagSettingsInput>;
  singleSelectTagProps?: InputMaybe<UpdateSingleSelectTagPropsInput>;
  source?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<TagSubTypeEnum>;
  tagId: Scalars['String'];
  textTagProps?: InputMaybe<UpdateTextTagPropsInput>;
};

export type PublicUpdateUserInput = {
  emailId?: InputMaybe<UserEmailIdSetInput>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  reportsToUniqueId?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<UserRolesTypeInput>;
  status?: InputMaybe<UserStatusEnum>;
  tags?: InputMaybe<PublicEntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
  workspaceIds?: InputMaybe<WorspacesInput>;
};

export type PublicWorkspaceType = {
  __typename?: 'PublicWorkspaceType';
  icon?: Maybe<EntityMediaType>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PublishArticleInput = {
  articleId: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  recalibrateConfig?: InputMaybe<RecalibrateConfigInput>;
  versionType?: InputMaybe<ResourceVersionTypeEnum>;
};

/** Publish Article Response */
export type PublishArticlePayload = {
  __typename?: 'PublishArticlePayload';
  article?: Maybe<ArticleContentType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PublishAssignmentInput = {
  assignerId?: InputMaybe<Scalars['String']>;
  assignmentId?: InputMaybe<Scalars['String']>;
  assignmentItemsDueDateConfig?: InputMaybe<Array<AssignmentItemDueDateConfigInput>>;
  collectionIds: Array<Scalars['String']>;
  contentIds: Array<Scalars['String']>;
  dueDateConfig?: InputMaybe<DueDateAssignmentConfigInput>;
  name?: InputMaybe<Scalars['String']>;
  notificationConfigurations?: InputMaybe<Array<NotificationConfigurationInput>>;
  scheduleDateConfig?: InputMaybe<ScheduleDateAssignmentConfigInput>;
  userGroupIds: Array<Scalars['String']>;
  userGroupSyncConfigs?: InputMaybe<UserGroupSyncAssignmentConfigsInput>;
  userIds: Array<Scalars['String']>;
};

export type PublishAssignmentPayload = {
  __typename?: 'PublishAssignmentPayload';
  assignment: AssignmentType;
};

export type PublishCommunityInput = {
  /** id of community */
  communityId: Scalars['String'];
};

/** Publish community Response */
export type PublishCommunityPayload = {
  __typename?: 'PublishCommunityPayload';
  /** Community Payload */
  community: CommunityType;
};

export type PublishContentConfig = {
  note?: InputMaybe<Scalars['String']>;
  recalibrateConfig?: InputMaybe<RecalibrateConfigInput>;
  versionType?: InputMaybe<ResourceVersionTypeEnum>;
};

export type PublishContentConfigPropertiesType = {
  __typename?: 'PublishContentConfigPropertiesType';
  defaultVersion?: Maybe<ResourceVersionTypeEnum>;
  recalibrateConfig?: Maybe<RecalibrateConfigType>;
};

export type PublishContentInput = {
  contentId: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  recalibrateConfig?: InputMaybe<RecalibrateConfigInput>;
  versionType?: InputMaybe<ResourceVersionTypeEnum>;
};

/** Publish Content Response */
export type PublishContentPayload = {
  __typename?: 'PublishContentPayload';
  content?: Maybe<ContentEntity>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PublishCourseInput = {
  courseId: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  recalibrateConfig?: InputMaybe<RecalibrateConfigInput>;
  versionType?: InputMaybe<ResourceVersionTypeEnum>;
};

/** Publish Course-Content Response */
export type PublishCoursePayload = {
  __typename?: 'PublishCoursePayload';
  course?: Maybe<CourseContentType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PushTemplate = {
  __typename?: 'PushTemplate';
  content: Scalars['String'];
  subject: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  academyTemplate?: Maybe<AcademyTemplateType>;
  academyTemplates: PaginatedAcademyTemplatesPayload;
  actionItem: ActionItemPayload;
  actionItems?: Maybe<PaginatedActionItemsPayload>;
  activityMetric?: Maybe<ActivityMetricType>;
  activityMetrics?: Maybe<PaginatedActivityMetricsPayload>;
  agendaItem: AgendaItemPayload;
  agendaItemTemplate: AgendaItemTemplatePayload;
  agendaItemTemplates: PaginatedAgendaItemTemplatesPayload;
  agendaItems: PaginatedAgendaItemsPayload;
  agendas: AgendaConnection;
  allBlocks: GetAllBlocksPayload;
  allConfigs: AllConfigsPayload;
  allUserGroups: AllUserGroupsPayload;
  assignment?: Maybe<AssignmentType>;
  assignmentStats: AssignmentStatsPayload;
  assignments: AssignmentConnection;
  auditMetadatas?: Maybe<AuditMetadataMessageInfoConnection>;
  baseResource?: Maybe<ResourceEntity>;
  behaviors: BehaviorConnection;
  blockTree: GetBlockTreePayload;
  bookmark?: Maybe<BookmarkType>;
  /** Fetches all categories for a classroomId */
  categories?: Maybe<Array<CategoryType>>;
  /** Fetches the category for an id if it exists */
  category?: Maybe<CategoryType>;
  certificateTemplates: CertificateTemplateConnections;
  certificates?: Maybe<CertificateConnections>;
  /** Fetches the channel for an id if it exists */
  channel?: Maybe<ChannelType>;
  /** Fetches the channel participants by given role type */
  channelParticipants?: Maybe<PaginatedChannelParticipantsPayload>;
  /** Fetches users of channel in paginated manner */
  channelUsersSearch: Array<UserType>;
  /** Fetch PaginatedChannels satisfying the filters in paginated manner */
  channels: ChannelConnection;
  /** Fetches the classroom for an id if it exists */
  classroom?: Maybe<ClassroomType>;
  /** Fetches the event for an id if it exists */
  classroomEvent?: Maybe<ClassroomEventType>;
  classroomEventUsers: UserConnection;
  classroomEvents: PaginatedClassrooEventsPayload;
  /** Fetches the classroom details from the JWT token */
  classroomInvite: ClassroomInvitePayload;
  /** Fetches the classroom details from the shortid/joinId */
  classroomJoin: ClassroomJoinPayload;
  /** Classroom points stats */
  classroomPointsStats: ClassroomPointsStatsType;
  /** Fetches the classroom settings for an id if it exists */
  classroomSetting?: Maybe<ClassroomSettingType>;
  classroomUsers: ClassroomUserConnections;
  classrooms: ClassroomConnections;
  cohort?: Maybe<CohortType>;
  commonProvider?: Maybe<CommonProviderType>;
  commonProviders: PaginatedCommonProvidersPayload;
  community?: Maybe<CommunityType>;
  config: ConfigPayload;
  configs: PaginatedConfigsPayload;
  content?: Maybe<ContentEntity>;
  contentPerformances: ContentPerformanceConnection;
  contentVersions: ResourceVersionConnections;
  contents: ContentConnection;
  /** Fetches the current workspace of user */
  currentWorkspace: WorkspaceType;
  databaseData: DatabaseDataConnection;
  /** Fetches the database metadata for an id if it exists */
  databaseMetadata?: Maybe<DatabaseMetadataType>;
  /** Fetch Paginated database metadata satisfying the filters in paginated manner */
  databaseMetadatas: DatabaseMetadataConnection;
  /** Fetches the database view for a view id if it exists */
  databaseView?: Maybe<DatabaseViewType>;
  /** Fetch database views satisfying the filters in paginated manner */
  databaseViews: DatabaseViewConnection;
  dateValueTypeEnum?: Maybe<DateValueTypeEnum>;
  /** Fetches the department for an id if it exists */
  department?: Maybe<DepartmentType>;
  departments: DepartmentConnections;
  distinctContentsCount: Scalars['Float'];
  distinctUsersCount: Scalars['Float'];
  emailDigest?: Maybe<EmailDigestType>;
  emailDigests?: Maybe<PaginatedEmailDigestsPayload>;
  embeddedContentsInfo: EmbeddedContentsPayload;
  embeddedEntitiesInfo: EmbeddedEntitiesInfoPayload;
  event?: Maybe<EventUnion>;
  eventAttendees: ContentPerformanceConnection;
  eventAttendeesV2: ContentPerformanceConnection;
  eventSchedule?: Maybe<EventScheduleType>;
  eventScheduleUsers: UserConnection;
  eventSchedules: PaginatedEventSchedulesPayload;
  events?: Maybe<PaginatedEventsPayload>;
  exerciseSubmissionsForReviewer: ContentPerformanceConnection;
  featureFlag: FeatureFlagPayload;
  featureFlags: FeatureFlagsPayload;
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  file?: Maybe<FileSchema>;
  fileAttachmentCount?: Maybe<GetFilesAttachmentCountPayload>;
  fileSubtreeSequences?: Maybe<GetFileSubtreeSequencesPayload>;
  files?: Maybe<PaginatedFilesPayload>;
  generativeTask: GenerativeTaskPayload;
  generativeTasks: PaginatedGenerativeTasksPayload;
  getAllSkillCategories: AllSkillCategoriesPayload;
  globalSearch: GlobalSearchPayload;
  group?: Maybe<GroupUnion>;
  groupedActionItems: GroupedActionItemsPayload;
  groups: GroupConnection;
  homepage: Array<Homepage>;
  importJobSampleCsvRows: ImportJobSampleCsvRowsPayload;
  instructorAvailabilities: InstructorProviderAvailabilitiesPayload;
  integrationDetail: IntegrationDetailPayload;
  integrationDetails: PaginatedIntegrationDetailsPayload;
  integrationProvider: IntegrationProviderPayload;
  integrationProviders: PaginatedIntegrationProvidersPayload;
  integrationRedirectUrl: IntegrationRedirectUrlPayload;
  invitationCode?: Maybe<InvitationCodeType>;
  invitationCodes: InvitationCodeConnection;
  /** Checks if given department name is available */
  isDepartmentNameAvailable: IsDepartmentNameAvailablePayload;
  jiraIssues: PaginatedJiraIssuesPayload;
  job?: Maybe<JobType>;
  jobCsvHeaders: GetJobCsvHeadersPayload;
  jobs: PaginatedJobsPayload;
  latestTransaction?: Maybe<TransactionType>;
  learnerEvents?: Maybe<PaginatedEventsPayload>;
  lookUpActionItemValues: LookUpActionItemValuesPayload;
  marketplaceContentCategories: PaginatedMarketplaceContentCategoriesPayload;
  marketplaceContents: PaginatedMarketplaceContentsPayload;
  meeting: MeetingPayload;
  meetingAlreadyExists: MeetingAlreadyExistsPayload;
  meetingInstance: MeetingInstancePayload;
  meetingInstances?: Maybe<PaginatedMeetingInstancesPayload>;
  meetings?: Maybe<PaginatedMeetingsPayload>;
  mentionSearch: MentionSearchPayload;
  metric?: Maybe<MetricSchema>;
  metrics?: Maybe<PaginatedMetricsPayload>;
  notificationTemplates: PaginatedNotificationTemplatesPayload;
  okr?: Maybe<OkrUnion>;
  okrCycle?: Maybe<OkrCycle>;
  okrCycles: OkrCycleConnection;
  okrs?: Maybe<PaginatedOkRsPayload>;
  orgRole?: Maybe<OrgRoleType>;
  orgRoles: OrgRoleConnections;
  page?: Maybe<PageType>;
  pageComponent: PageComponentPayload;
  pagePreview: PagePreviewPayload;
  page__next?: Maybe<PageType__Next>;
  paginatedBookmarks?: Maybe<PaginatedBookmarkConnections>;
  paginatedGroupedActionItems?: Maybe<PaginatedGroupedActionItemsPayload>;
  paginatedGroupedElasticsearchActionItems: PaginatedGroupedActionItemsPayload;
  parentContents?: Maybe<GetParentContentsPayload>;
  peerFeedback?: Maybe<PeerFeedbackSchema>;
  peerFeedbackTemplates?: Maybe<PaginatedPeerFeedbackTemplatesPayload>;
  peerFeedbacks?: Maybe<PaginatedPeerFeedbacksPayload>;
  /** Fetch classroom users with invitation pending for a particular user */
  pendingInvitations: ClassroomUserConnections;
  performanceInteractions: PerformanceInteractionConnections;
  pointActivities: PointActivityConnection;
  post?: Maybe<PostType>;
  /** Fetch PaginatedPostAttachment satisfying the filters in paginated manner */
  postAttachments: PostAttachmentConnections;
  postReactions: PostReactionsConnections;
  /** Fetch PaginatedPosts satisfying the filters in paginated manner */
  posts: PostConnections;
  praise: PraisePayload;
  praises: PaginatedPraisesPayload;
  product?: Maybe<ProductType>;
  productUser?: Maybe<ProductUserType>;
  products: ProductConnections;
  provider?: Maybe<ProviderType>;
  providers: ProviderConnections;
  publicCertificate?: Maybe<PublicCertificateType>;
  questions: QuestionConnections;
  relatedConnections: RelatedConnectionsPayload;
  report?: Maybe<ReportType>;
  reports?: Maybe<PaginatedReportsPayload>;
  resource?: Maybe<ResourceType>;
  resources: ResourceConnections;
  reviewCycle: ReviewCyclePayload;
  reviewCycleInstance?: Maybe<ReviewCycleInstanceType>;
  /** Returns all reviewees to be reviewed by the user in context for a given reviewCycle */
  reviewCycleMyReviewees?: Maybe<ReviewCycleMyRevieweesPayload>;
  reviewCycles: PaginatedReviewCyclesPayload;
  role?: Maybe<RoleType>;
  rolePermissionDescriptions?: Maybe<RolePermissionDescriptionsPayload>;
  roles: RoleConntection;
  scorecard: ScorecardType;
  scorecardReviews: ScorecardReviews;
  scorecards: ScorecardConnections;
  /** Fetch classroomUsers of classroom in paginated manner */
  searchClassroomUsers: ClassroomUserConnections;
  sharedConnection?: Maybe<SharedConnectionInfo>;
  sharedConnections: SharedConnectionsInfoPayload;
  signedUrl?: Maybe<GetSignedUrlPayload>;
  signedUrls?: Maybe<GetAllSignedUrlsPayload>;
  skill?: Maybe<SkillType>;
  skillMatrices: SkillMatrixConnection;
  skillMatrix?: Maybe<SkillMatrixType>;
  skills: SkillConnections;
  staffRoles?: Maybe<RoleConntection>;
  status: StatusPayload;
  statuses: PaginatedStatusesPayload;
  store?: Maybe<StoreType>;
  /** Fetches the sub workspaces of workspace */
  subWorkspaces?: Maybe<Array<PublicWorkspaceType>>;
  submissionToReview: SubmissionsToReviewPayload;
  surveyResponses: DatabaseDataConnection;
  tag?: Maybe<TagUnion>;
  tagErrorCodeEnum?: Maybe<TagErrorCodeEnum>;
  tagOptions: TagOptionConnection;
  tags?: Maybe<PaginatedTagsPayload>;
  tagsInfo?: Maybe<PaginatedTagsPayload>;
  template?: Maybe<TemplateUnion>;
  templateCategories: PaginatedTemplateCategoriesPayload;
  templateCategory: TemplateCategoryPayload;
  templates: PaginatedTemplatesPayload;
  track?: Maybe<TrackType>;
  tracks: PaginatedTracksPayload;
  transaction?: Maybe<TransactionType>;
  transactions: PaginatedTransactions;
  /** Fetch previews of url */
  urlPreviews: GetUrlPreviewsPayload;
  /** Fetches the channel for an id if it exists */
  user?: Maybe<UserType>;
  userActivityMetrics: PaginatedUserActivityMetricsPayload;
  /** Fetch classroomUsers of classroom in paginated manner */
  userClassrooms: ClassroomUserConnections;
  userGroups: UserGroupConnections;
  userNotifications: PaginatedUserNotificationsPayload;
  userSearchActivities?: Maybe<UserSearchActivityConnections>;
  userView?: Maybe<UserViewSchema>;
  userViews?: Maybe<PaginatedUserViewsPayload>;
  users: UserConnection;
  validateCurrentUserPassword: MutationResponse;
  webhook?: Maybe<WebhookType>;
  webhooks: PaginatedWebhooksPayload;
  widget?: Maybe<WidgetUnion>;
  workspacePermissions: Array<UserWorkspacePermissionType>;
};

export type QueryAcademyTemplateArgs = {
  filters: AcademyTemplateFilters;
};

export type QueryAcademyTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AcademyTemplatesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryActionItemArgs = {
  filters?: InputMaybe<ActionItemFilters>;
};

export type QueryActionItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ActionItemsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryActivityMetricArgs = {
  filters: GetByIdFilter;
};

export type QueryActivityMetricsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ActivityMetricsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryAgendaItemArgs = {
  filters?: InputMaybe<AgendaItemFilters>;
};

export type QueryAgendaItemTemplateArgs = {
  filters?: InputMaybe<AgendaItemTemplateFilters>;
};

export type QueryAgendaItemTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AgendaItemTemplatesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryAgendaItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AgendaItemsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryAgendasArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AgendasFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryAllBlocksArgs = {
  filters: GetAllBlocksFilter;
};

export type QueryAllConfigsArgs = {
  filters?: InputMaybe<ConfigsFilters>;
};

export type QueryAllUserGroupsArgs = {
  filters?: InputMaybe<UserGroupFilters>;
};

export type QueryAssignmentArgs = {
  filters: GetByIdFilter;
};

export type QueryAssignmentStatsArgs = {
  filters?: InputMaybe<AssignmentStatsInput>;
};

export type QueryAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AssignmentsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryAuditMetadatasArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AuditMetadataFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryBaseResourceArgs = {
  filters: GetByIdFilter;
};

export type QueryBehaviorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<BehaviorsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryBlockTreeArgs = {
  getBlockTreeInput: GetBlockTreeFilters;
};

export type QueryBookmarkArgs = {
  filters: BookmarkFilter;
};

export type QueryCategoriesArgs = {
  filters: GetCategoriesInput;
};

export type QueryCategoryArgs = {
  filters: GetByIdFilter;
};

export type QueryCertificateTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<CertificateTemplatesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryCertificatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<CertificatesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryChannelArgs = {
  filters: ChannelFilters;
};

export type QueryChannelParticipantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ChannelParticipantsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryChannelUsersSearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters: ChannelUserSearchFilters;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryChannelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ChannelsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryClassroomArgs = {
  filters: GetByIdFilter;
};

export type QueryClassroomEventArgs = {
  filters: GetByIdFilter;
};

export type QueryClassroomEventUsersArgs = {
  filters: GetEventClassroomUsersFilters;
  paginationProps?: InputMaybe<PaginationProps>;
};

export type QueryClassroomEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GetClassroomEventsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryClassroomInviteArgs = {
  filters: ClassroomInviteFilters;
};

export type QueryClassroomJoinArgs = {
  filters: ClassroomJoinFilters;
};

export type QueryClassroomPointsStatsArgs = {
  filters?: InputMaybe<ClassroomPointsStatsFilters>;
};

export type QueryClassroomSettingArgs = {
  filters: GetClassroomSettingInput;
};

export type QueryClassroomUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ClassroomUsersFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryClassroomsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ClassroomsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryCohortArgs = {
  filters: CohortFilters;
};

export type QueryCommonProviderArgs = {
  filters?: InputMaybe<GetProviderFilters>;
};

export type QueryCommonProvidersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GetProvidersFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryCommunityArgs = {
  filters: GetByIdFilter;
};

export type QueryConfigArgs = {
  filters?: InputMaybe<ConfigFilters>;
};

export type QueryConfigsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ConfigsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryContentArgs = {
  filters: GetResourceByIdFilter;
};

export type QueryContentPerformancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PerformancesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryContentVersionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ResourceVersionFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ContentFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryDatabaseDataArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DatabaseDatasFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryDatabaseMetadataArgs = {
  filters: DatabaseMetadataFilters;
};

export type QueryDatabaseMetadatasArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DatabaseMetadatasFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryDatabaseViewArgs = {
  filters: DatabaseViewFilters;
};

export type QueryDatabaseViewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DatabaseViewsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryDepartmentArgs = {
  filters: GetByIdFilter;
};

export type QueryDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DepartmentsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryDistinctContentsCountArgs = {
  contentIds?: InputMaybe<Array<Scalars['String']>>;
  groupIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryDistinctUsersCountArgs = {
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryEmailDigestArgs = {
  filters: GetByIdFilter;
};

export type QueryEmailDigestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<EmailDigestsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryEmbeddedContentsInfoArgs = {
  input: GetEmbeddedContentsInput;
};

export type QueryEmbeddedEntitiesInfoArgs = {
  input: GetEmbeddedEntitiesInfoInput;
};

export type QueryEventArgs = {
  filters: EventFilters;
};

export type QueryEventAttendeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters: EventAttendeesFilters;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryEventAttendeesV2Args = {
  after?: InputMaybe<Scalars['String']>;
  filters: EventAttendeesFiltersV2;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryEventScheduleArgs = {
  filters: GetByIdFilter;
};

export type QueryEventScheduleUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GetEventScheduleUsersFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryEventSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GetEventSchedulesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<EventsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryExerciseSubmissionsForReviewerArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ExerciseSubmissionsForReviewerFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryFeatureFlagArgs = {
  input: FeatureFlagInput;
};

export type QueryFeatureFlagsArgs = {
  input: FeatureFlagsInput;
};

export type QueryFeedbackQuestionsArgs = {
  filters: FeedbackQuestionFilter;
};

export type QueryFileArgs = {
  filters: GetByIdFilter;
};

export type QueryFileAttachmentCountArgs = {
  data: GetFilesAttachmentCountInput;
};

export type QueryFileSubtreeSequencesArgs = {
  data: GetFileSubtreeSequencesInput;
};

export type QueryFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<FilesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryGenerativeTaskArgs = {
  filters?: InputMaybe<GenerativeTaskFilters>;
};

export type QueryGenerativeTasksArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GenerativeTasksFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryGetAllSkillCategoriesArgs = {
  input: AllSkillCategoriesInput;
};

export type QueryGlobalSearchArgs = {
  filters?: InputMaybe<GlobalSearchFilter>;
};

export type QueryGroupArgs = {
  filters: GetByIdFilter;
};

export type QueryGroupedActionItemsArgs = {
  filters?: InputMaybe<ActionItemsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  groupBy?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortType>>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GroupsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryImportJobSampleCsvRowsArgs = {
  data: GetImportJobSampleCsvRowsInput;
};

export type QueryInstructorAvailabilitiesArgs = {
  data: InstructorProviderAvailabilitiesInput;
};

export type QueryIntegrationDetailArgs = {
  filters?: InputMaybe<IntegrationDetailFilters>;
};

export type QueryIntegrationDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<IntegrationDetailsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryIntegrationProviderArgs = {
  filters?: InputMaybe<IntegrationProviderFilters>;
};

export type QueryIntegrationProvidersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<IntegrationProvidersFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryIntegrationRedirectUrlArgs = {
  data?: InputMaybe<IntegrationRedirectUrlInput>;
};

export type QueryInvitationCodeArgs = {
  filters: InvitationCodeFilters;
};

export type QueryInvitationCodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<InvitationCodesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryIsDepartmentNameAvailableArgs = {
  input: IsDepartmentNameAvailableInput;
};

export type QueryJiraIssuesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<JiraIssueFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryJobArgs = {
  filters: JobFilters;
};

export type QueryJobCsvHeadersArgs = {
  data: GetJobCsvHeadersInput;
};

export type QueryJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<JobsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryLatestTransactionArgs = {
  filters: TransactionFilters;
};

export type QueryLearnerEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<EventsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryLookUpActionItemValuesArgs = {
  filters?: InputMaybe<ActionItemsFilters>;
  lookUpKey?: InputMaybe<Scalars['String']>;
};

export type QueryMarketplaceContentCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<MarketplaceContentCategoriesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryMarketplaceContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<MarketplaceContentsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryMeetingArgs = {
  filters?: InputMaybe<MeetingFilters>;
};

export type QueryMeetingAlreadyExistsArgs = {
  input: MeetingAlreadyExistsInput;
};

export type QueryMeetingInstanceArgs = {
  filters?: InputMaybe<MeetingInstanceFilters>;
};

export type QueryMeetingInstancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<MeetingInstancesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryMeetingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<MeetingsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryMentionSearchArgs = {
  filters?: InputMaybe<MentionFilters>;
};

export type QueryMetricArgs = {
  filters: GetByIdFilter;
};

export type QueryMetricsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<MetricsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryNotificationTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<NotificationTemplatesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryOkrArgs = {
  filters: GetOkrFilters;
};

export type QueryOkrCycleArgs = {
  filters: OkrCycleFilters;
};

export type QueryOkrCyclesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<OkrCyclesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryOkrsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GetOkRsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryOrgRoleArgs = {
  filters: GetByIdFilter;
};

export type QueryOrgRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<OrgRolesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryPageArgs = {
  data: PageInput;
};

export type QueryPageComponentArgs = {
  filters: PageComponentFilters;
};

export type QueryPagePreviewArgs = {
  data: PagePreviewInput;
};

export type QueryPage__NextArgs = {
  data: PageInput;
};

export type QueryPaginatedBookmarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<BookmarkFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryPaginatedGroupedActionItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ActionItemsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  firstEntity?: InputMaybe<Scalars['Float']>;
  groupBy?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortType>>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type QueryPaginatedGroupedElasticsearchActionItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  first?: InputMaybe<Scalars['Float']>;
  firstEntity?: InputMaybe<Scalars['Float']>;
  groupBy?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<SortType>>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type QueryParentContentsArgs = {
  filters: GetParentContentsInput;
};

export type QueryPeerFeedbackArgs = {
  filters: GetByIdFilter;
};

export type QueryPeerFeedbackTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PeerFeedbackTemplatesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryPeerFeedbacksArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PeerFeedbacksFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryPendingInvitationsArgs = {
  filters: PendingInvitationClassroomFilters;
  paginationProps?: InputMaybe<PaginationProps>;
};

export type QueryPerformanceInteractionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PerformanceInteractionFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryPointActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PointActivitiesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryPostArgs = {
  filters: GetByIdFilter;
};

export type QueryPostAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PostAttachmentsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryPostReactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PostReactionFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PostsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
  targetChannelMappings?: InputMaybe<Array<TargetChannelMapping>>;
};

export type QueryPraiseArgs = {
  filters?: InputMaybe<PraiseFilters>;
};

export type QueryPraisesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PraisesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryProductArgs = {
  filters?: InputMaybe<GetProductFilters>;
};

export type QueryProductUserArgs = {
  filters?: InputMaybe<ProductUserFilters>;
};

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GetProductsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryProviderArgs = {
  filters?: InputMaybe<GetProviderFilters>;
};

export type QueryProvidersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GetProvidersFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryPublicCertificateArgs = {
  filters?: InputMaybe<PublicCertificateFilters>;
};

export type QueryQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<QuestionFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryRelatedConnectionsArgs = {
  filters: RelatedConnectionsFilters;
};

export type QueryReportArgs = {
  filters: GetByIdFilter;
};

export type QueryReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ReportsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryResourceArgs = {
  filters: GetByIdFilter;
};

export type QueryResourcesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GetResourcesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryReviewCycleArgs = {
  filters?: InputMaybe<ReviewCycleFilters>;
};

export type QueryReviewCycleInstanceArgs = {
  filter: GetByIdFilter;
};

export type QueryReviewCycleMyRevieweesArgs = {
  filters?: InputMaybe<ReviewCycleMyRevieweesInput>;
};

export type QueryReviewCyclesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ReviewCyclesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryRoleArgs = {
  filters: GetByIdFilter;
};

export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<RolesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryScorecardArgs = {
  filters: GetByIdFilter;
};

export type QueryScorecardReviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<GetScorecardReviewsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryScorecardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ScorecardsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QuerySearchClassroomUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<SearchClassroomUsersFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QuerySharedConnectionArgs = {
  filters?: InputMaybe<SharedConnectionFilters>;
};

export type QuerySharedConnectionsArgs = {
  filters?: InputMaybe<SharedConnectionsFilters>;
};

export type QuerySignedUrlArgs = {
  input: GetSignedUrlInput;
};

export type QuerySignedUrlsArgs = {
  input: GetAllSignedUrlsInput;
};

export type QuerySkillArgs = {
  filters: GetByIdFilter;
};

export type QuerySkillMatricesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<SkillMatricesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QuerySkillMatrixArgs = {
  filters: SkillMatrixFilters;
};

export type QuerySkillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<SkillsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryStaffRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<RolesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryStatusArgs = {
  filters?: InputMaybe<StatusFilters>;
};

export type QueryStatusesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<StatusesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryStoreArgs = {
  filters: StoreFilters;
};

export type QuerySubmissionToReviewArgs = {
  input: SubmissionsToReviewInput;
};

export type QuerySurveyResponsesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DatabaseDatasFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
  surveyFilters?: InputMaybe<SurveyResponsesFilters>;
};

export type QueryTagArgs = {
  filters: TagFilters;
};

export type QueryTagOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TagPaginatedOptionsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TagsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryTagsInfoArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TagsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryTemplateArgs = {
  filters: TemplateFilters;
};

export type QueryTemplateCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TemplateCategoriesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryTemplateCategoryArgs = {
  filters?: InputMaybe<TemplateCategoryFilters>;
};

export type QueryTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TemplatesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryTrackArgs = {
  filters?: InputMaybe<TrackFilters>;
};

export type QueryTracksArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TracksFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryTransactionArgs = {
  filters: TransactionFilters;
};

export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TransactionsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryUrlPreviewsArgs = {
  input: GetUrlPreviewsInput;
};

export type QueryUserArgs = {
  filters: UserFilters;
};

export type QueryUserActivityMetricsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<UserActivityMetricsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryUserClassroomsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<UserClassroomsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryUserGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<UserGroupFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryUserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<UserNotificationsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryUserSearchActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<UserSearchActivitiesFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryUserViewArgs = {
  filters: GetByIdFilter;
};

export type QueryUserViewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<UserViewsFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<UsersFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryValidateCurrentUserPasswordArgs = {
  input: ValidateCurrentUserPasswordInput;
};

export type QueryWebhookArgs = {
  filters: WebhookFilters;
};

export type QueryWebhooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<WebhooksFilters>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type QueryWidgetArgs = {
  filters: WidgetFilters;
};

/** Defines  view query */
export type QueryInput = {
  aggregation?: InputMaybe<AggregationSetInput>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  sort?: InputMaybe<SortSetInput>;
};

/** Defines sorting criteria for a particular field */
export type QuerySortType = {
  __typename?: 'QuerySortType';
  /** Sort Order */
  order: OrderEnum;
  /** Field Id */
  property: Scalars['String'];
};

/** Defines queries that needs to be performed over database */
export type QueryType = {
  __typename?: 'QueryType';
  /** Provides aggregation on given Field Id */
  aggregation?: Maybe<Array<AggregationType>>;
  /** Collection of complex filter queries */
  filterGroup?: Maybe<FilterGroupType>;
  /** Provides sorting on given Field Id */
  sort?: Maybe<Array<QuerySortType>>;
};

/** This is the Question Association schema */
export type QuestionAssociation = {
  __typename?: 'QuestionAssociation';
  entityAssociation: EntityAssociation;
  type: ResourceTypeEnum;
};

/** This is the Question Association schema */
export type QuestionAssociationInput = {
  entityAssociation: EntityAssociationInput;
  type: ResourceTypeEnum;
};

export type QuestionAttachmentsSetInput = {
  /** Attachments to be updated */
  set: Array<CreateMediaInput>;
};

/** This returns paginated Questions */
export type QuestionConnections = {
  __typename?: 'QuestionConnections';
  edges?: Maybe<Array<QuestionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  node: QuestionType;
};

export type QuestionFilters = {
  /** Duplicate created from which question */
  actualQuestionIds?: InputMaybe<Array<Scalars['String']>>;
  /** Pass false when all questions are to be fetched */
  excludeAssociatedQuestions?: InputMaybe<Scalars['Boolean']>;
  /** ids of questions to fetch */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** Type of question text types one needs to fetch */
  questionTextTypes?: InputMaybe<Array<QuestionTextTypeEnum>>;
  /** Type of question one needs to fetch */
  questionTypes?: InputMaybe<Array<QuestionTypeEnum>>;
  /** Search to perform regex search on the question text and option text */
  searchText?: InputMaybe<Scalars['String']>;
  /** Status of question one needs to fetch */
  status?: InputMaybe<Array<QuestionStatusEnum>>;
  /** Sub-Types of question one needs to fetch */
  subTypes?: InputMaybe<Array<QuestionSubTypeEnum>>;
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};

/** This is the input for Question creation */
export type QuestionInput = {
  /** if provided, a duplicate question is created using actualQuestionId */
  actualQuestionId?: InputMaybe<Scalars['String']>;
  associations?: InputMaybe<Array<QuestionAssociationInput>>;
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  correctAnswerFeedback?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** Question difficulty */
  difficulty?: InputMaybe<DifficultyEnum>;
  ftbComponents?: InputMaybe<Array<FtbComponentInput>>;
  globalDistractors?: InputMaybe<Array<DistractorInput>>;
  incorrectAnswerFeedback?: InputMaybe<Scalars['String']>;
  mtfComponent?: InputMaybe<MtfComponentInput>;
  options?: InputMaybe<Array<OptionInput>>;
  pairs?: InputMaybe<Array<PairInput>>;
  questionText?: InputMaybe<Scalars['String']>;
  questionTextType?: InputMaybe<QuestionTextTypeEnum>;
  questionType?: InputMaybe<QuestionTypeEnum>;
  settings?: InputMaybe<QuestionSettingsInput>;
  /** skillIds associated with the question */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  /** Status in which the current question is */
  status?: InputMaybe<QuestionStatusEnum>;
  /** Sub type of question like multi select, single select */
  subType?: InputMaybe<QuestionSubTypeEnum>;
};

/** This is the input for Question creation */
export type QuestionPayload = {
  __typename?: 'QuestionPayload';
  /** Id of question from which this question was created */
  actualQuestionId?: Maybe<Scalars['String']>;
  associations?: Maybe<Array<QuestionAssociation>>;
  attachments?: Maybe<Array<EntityMediaType>>;
  correctAnswerFeedback?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  difficulty?: Maybe<DifficultyEnum>;
  ftbComponents?: Maybe<Array<FtbComponent>>;
  globalDistractors?: Maybe<Array<Distractor>>;
  incorrectAnswerFeedback?: Maybe<Scalars['String']>;
  mtfComponent?: Maybe<Array<MtfComponent>>;
  options?: Maybe<Array<Option>>;
  pairs?: Maybe<Array<Pair>>;
  questionText: Scalars['String'];
  questionTextType: QuestionTextTypeEnum;
  questionType: QuestionTypeEnum;
  settings?: Maybe<QuestionSettings>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  /** status of question */
  status?: Maybe<QuestionStatusEnum>;
  /** Sub type of question like multi select, single select */
  subType?: Maybe<QuestionSubTypeEnum>;
};

/** Store Question level attempts on Quiz */
export type QuestionPerformanceAttempt = {
  __typename?: 'QuestionPerformanceAttempt';
  /** tells evaluation status of the question */
  evaluationStatus?: Maybe<PerformanceEvaluationStatusEnum>;
  isSubmitted: Scalars['Boolean'];
  maxScore?: Maybe<Scalars['Float']>;
  /** For any given parent, track for which attempt the answer is given */
  parentAttemptId: Scalars['String'];
  score?: Maybe<Scalars['Float']>;
  /** tells in which state the user performance is */
  status: PerformanceStatusEnum;
  submission?: Maybe<QuestionSubmissionType>;
  timeSpent: Scalars['Float'];
  updatedAt?: Maybe<Scalars['String']>;
};

/** User Performance on Question */
export type QuestionPerformanceType = {
  __typename?: 'QuestionPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  /** History of all the selections and score calculations for a user for a question */
  attempts: Array<QuestionPerformanceAttempt>;
  completedAt?: Maybe<Scalars['String']>;
  /** This will store the content to which this question belongs to, example quizId */
  contentId: Scalars['String'];
  /** This will store the type of content to which this question belongs to, example REVIEW_QUIZ, IN_LESSON_QUIZ, STATIC etc */
  contentType: PerformanceContentTypeEnum;
  /** correct answer for match the following */
  correctFTBAnswers: Array<FtbComponent>;
  /** correct answer for match the following */
  correctMTFPairings: Array<MtfPairing>;
  /** correct options */
  correctOptions: Array<Option>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** tells evaluation status of the question */
  evaluationStatus?: Maybe<PerformanceEvaluationStatusEnum>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  isSubmitted: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  maxScore?: Maybe<Scalars['Float']>;
  /** Stores the attempt associated with max quiz score */
  maxSubmissionDetails?: Maybe<QuestionPerformanceAttempt>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** For any given parent, track for which attempt the answer is given */
  parentAttemptId: Scalars['String'];
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  /** Parent Id inside which quiz is taking place. ParentId can be of quiz if user has taken quiz directly */
  parentEntityId: Scalars['String'];
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  score?: Maybe<Scalars['Float']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  submission?: Maybe<QuestionSubmissionType>;
  timeSpent: Scalars['Float'];
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

/** Settings available on Question */
export type QuestionSettings = {
  __typename?: 'QuestionSettings';
  gradedBy?: Maybe<GradedByEnum>;
  isGradable?: Maybe<Scalars['Boolean']>;
  maxScore?: Maybe<Scalars['Float']>;
  minScore?: Maybe<Scalars['Float']>;
  retakeAllowedCount?: Maybe<Scalars['Float']>;
  shuffleOptions?: Maybe<Scalars['Boolean']>;
  /** Time limit in seconds */
  timeLimit?: Maybe<Scalars['Float']>;
};

/** Settings available on Question */
export type QuestionSettingsInput = {
  gradedBy?: InputMaybe<GradedByEnum>;
  isGradable?: InputMaybe<Scalars['Boolean']>;
  maxScore?: InputMaybe<Scalars['Float']>;
  minScore?: InputMaybe<Scalars['Float']>;
  retakeAllowedCount?: InputMaybe<Scalars['Float']>;
  /** while rendering options, should it be shuffled is controlled by this flag */
  shuffleOptions?: InputMaybe<Scalars['Boolean']>;
  /** Time limit in seconds */
  timeLimit?: InputMaybe<Scalars['Float']>;
};

export type QuestionSkillIdsSetInput = {
  /** Attachments to be updated */
  set: Array<Scalars['String']>;
};

export enum QuestionStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export enum QuestionSubTypeEnum {
  DragAndDrop = 'DRAG_AND_DROP',
  Dropdown = 'DROPDOWN',
  MultiSelect = 'MULTI_SELECT',
  SingleSelect = 'SINGLE_SELECT',
  TypeAnswer = 'TYPE_ANSWER',
}

/** Question submission */
export type QuestionSubmissionType = {
  __typename?: 'QuestionSubmissionType';
  ftbResponse?: Maybe<Array<FtbResponse>>;
  maxScore?: Maybe<Scalars['Float']>;
  mtfResponse?: Maybe<Array<MtfResponse>>;
  optionResponse?: Maybe<Array<OptionResponse>>;
  responseType: ResourceResponseTypeEnum;
  score?: Maybe<Scalars['Float']>;
  submittedAt: Scalars['String'];
};

export enum QuestionTextTypeEnum {
  Blocks = 'BLOCKS',
  Html = 'HTML',
  SimpleText = 'SIMPLE_TEXT',
  Url = 'URL',
}

/** This is the Question schema */
export type QuestionType = {
  __typename?: 'QuestionType';
  /** Id of question from which this question was created */
  actualQuestionId?: Maybe<Scalars['String']>;
  /** Association of the question */
  associations: Array<QuestionAssociation>;
  attachments?: Maybe<Array<EntityMediaType>>;
  correctAnswerFeedback?: Maybe<Scalars['String']>;
  /** correct answer for match the following */
  correctFTBAnswers: Array<FtbComponent>;
  /** correct answer for match the following */
  correctMTFPairings: Array<MtfPairing>;
  /** correct options for staff members */
  correctOptions: Array<Option>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  difficulty?: Maybe<DifficultyEnum>;
  /** correct answer for match the following */
  ftbComponents: Array<FtbComponent>;
  /** All the possible answers using global distractor for various components */
  globalDistractedOptions: Array<Distractor>;
  globalDistractors?: Maybe<Array<Distractor>>;
  id: Scalars['String'];
  incorrectAnswerFeedback?: Maybe<Scalars['String']>;
  mtfComponent?: Maybe<MtfComponent>;
  options?: Maybe<Array<Option>>;
  pairs?: Maybe<Array<Pair>>;
  questionText?: Maybe<Scalars['String']>;
  questionTextType?: Maybe<QuestionTextTypeEnum>;
  questionType: QuestionTypeEnum;
  settings?: Maybe<QuestionSettings>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  skills: Array<SkillType>;
  status: QuestionStatusEnum;
  /** Sub type of question like multi select, single select */
  subType?: Maybe<QuestionSubTypeEnum>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** This is the Question schema */
export type QuestionTypeMtfComponentArgs = {
  shuffleMTFComponents?: InputMaybe<Scalars['Boolean']>;
};

/** This is the Question schema */
export type QuestionTypeOptionsArgs = {
  shuffleOptions?: InputMaybe<Scalars['Boolean']>;
};

export enum QuestionTypeEnum {
  Audio = 'AUDIO',
  Emoji = 'EMOJI',
  Email = 'Email',
  FillInTheBlanks = 'FILL_IN_THE_BLANKS',
  File = 'File',
  Image = 'IMAGE',
  MatchTheFollowing = 'MATCH_THE_FOLLOWING',
  MultipleChoiceQuestion = 'MULTIPLE_CHOICE_QUESTION',
  MultiSelect = 'MULTI_SELECT',
  Overall = 'OVERALL',
  PictureMultipleChoiceQuestion = 'PICTURE_MULTIPLE_CHOICE_QUESTION',
  Quiz = 'QUIZ',
  SingleSelect = 'SINGLE_SELECT',
  Slider = 'SLIDER',
  Star = 'STAR',
  Text = 'TEXT',
  TrueOrFalse = 'TRUE_OR_FALSE',
  Video = 'VIDEO',
}

export type QuestionsSetInput = {
  /** ordered set of questions */
  set?: InputMaybe<Array<QuizQuestionInputType>>;
};

/** Quiz Content */
export type QuizContentType = {
  __typename?: 'QuizContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']>;
  /** Description of the content */
  description?: Maybe<Scalars['String']>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** duration in seconds */
  duration: Scalars['Float'];
  /** EntityId could be lessonId, courseId, pathId on which this quiz is attached */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: QuizEntityTypeEnum;
  /** Feedback Questions for the quiz */
  feedbackQuestions?: Maybe<Array<FeedbackQuestionType>>;
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  /** description of the task */
  instruction?: Maybe<ResourceInstruction>;
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  maxScore: Scalars['Float'];
  /** Name of the resource */
  name: Scalars['String'];
  /** Objective of the content */
  objective?: Maybe<Scalars['String']>;
  /** Passing Percentage */
  passingPercentage?: Maybe<Scalars['Float']>;
  performance?: Maybe<QuizPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Question of the quiz */
  questions: Array<QuestionType>;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  settings?: Maybe<QuizSettings>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']>>;
  /** Skills suitable to the content */
  skills?: Maybe<Array<SkillType>>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Quiz Content */
export type QuizContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Quiz Content */
export type QuizContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Quiz Content */
export type QuizContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Quiz Content */
export type QuizContentTypePerformanceArgs = {
  filters?: InputMaybe<PerformancesFilters>;
};

/** Quiz Content */
export type QuizContentTypeQuestionsArgs = {
  versionStatus?: InputMaybe<ResourceStatusEnum>;
};

/** Quiz Content */
export type QuizContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Quiz Content */
export type QuizContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Quiz Content */
export type QuizContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export enum QuizEntityTypeEnum {
  Course = 'COURSE',
  ExternalContent = 'EXTERNAL_CONTENT',
  Lesson = 'LESSON',
}

export enum QuizNumberOfQuestionsEnum {
  Fifteen = 'FIFTEEN',
  Five = 'FIVE',
  Ten = 'TEN',
  Twenty = 'TWENTY',
}

/** Store Attempt level information for any quiz x */
export type QuizPerformanceAttempt = {
  __typename?: 'QuizPerformanceAttempt';
  /** attemptId to uniquely determine attempts */
  attemptId: Scalars['String'];
  correctAnswersCount?: Maybe<Scalars['Float']>;
  isSubmitted: Scalars['Boolean'];
  maxScore?: Maybe<Scalars['Float']>;
  /** Result calculated after submission for this attempt */
  result?: Maybe<QuizResultEnum>;
  score?: Maybe<Scalars['Float']>;
  /** Percentage score */
  scorePercentage?: Maybe<Scalars['Float']>;
  /** tells in which state the user performance is */
  status: PerformanceStatusEnum;
  timeSpent: Scalars['Float'];
  updatedAt?: Maybe<Scalars['String']>;
};

/** User Performance on Quiz */
export type QuizPerformanceType = {
  __typename?: 'QuizPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  /** attemptId to uniquely determine attempts */
  attemptId: Scalars['String'];
  /** stores the latest attempt information of the user */
  attempts?: Maybe<Array<QuizPerformanceAttempt>>;
  completedAt?: Maybe<Scalars['String']>;
  correctAnswersCount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  isSubmitted: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  maxScore?: Maybe<Scalars['Float']>;
  /** stores the max attempt information of the user */
  maxSubmissionDetails?: Maybe<QuizPerformanceAttempt>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  /** Parent Id inside which quiz is taking place. ParentId can be of quiz if user has taken quiz directly */
  parentEntityId: Scalars['String'];
  progress?: Maybe<Scalars['Float']>;
  /** Result calculated after submission */
  result?: Maybe<QuizResultEnum>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  score?: Maybe<Scalars['Float']>;
  /** Percentage score */
  scorePercentage?: Maybe<Scalars['Float']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  timeSpent: Scalars['Float'];
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type QuizQuestionImportInputPropsInput = {
  quizId: Scalars['String'];
};

export type QuizQuestionImportInputPropsType = {
  __typename?: 'QuizQuestionImportInputPropsType';
  quizId: Scalars['String'];
};

export type QuizQuestionImportMetadataType = {
  __typename?: 'QuizQuestionImportMetadataType';
  createdQuestionsCount: Scalars['Float'];
};

export type QuizQuestionInput = {
  /** Question Id for any question to be added in quiz */
  questionId?: InputMaybe<Scalars['String']>;
  /** Question which will be created and added to quiz */
  questionInput?: InputMaybe<QuestionInput>;
  /** Quiz settings */
  settings?: InputMaybe<QuestionSettingsInput>;
};

export type QuizQuestionInputType = {
  questionId: Scalars['String'];
  settings?: InputMaybe<QuestionSettingsInput>;
};

export type QuizQuestionOptionStubType = {
  __typename?: 'QuizQuestionOptionStubType';
  value: Scalars['String'];
};

export type QuizQuestionOptionsResultType = {
  __typename?: 'QuizQuestionOptionsResultType';
  records: Array<QuizQuestionOptionStubType>;
};

export type QuizQuestionStubType = {
  __typename?: 'QuizQuestionStubType';
  singleChoiceQuestion?: Maybe<QuizSingleChoiceQuestionStubType>;
};

/** Quiz question with question settings */
export type QuizQuestionType = {
  __typename?: 'QuizQuestionType';
  questionId: Scalars['String'];
  settings?: Maybe<QuestionSettings>;
};

export enum QuizResultEnum {
  Fail = 'FAIL',
  Pass = 'PASS',
  ToBeEvaluated = 'TO_BE_EVALUATED',
}

export type QuizResultType = {
  __typename?: 'QuizResultType';
  records: Array<QuizQuestionStubType>;
  title: Scalars['String'];
};

export enum QuizRetakeTypeEnum {
  Everyone = 'EVERYONE',
  FailedLearners = 'FAILED_LEARNERS',
  None = 'NONE',
}

export enum QuizRevealAnswerEnum {
  AfterQuestion = 'AFTER_QUESTION',
  AfterQuiz = 'AFTER_QUIZ',
  Never = 'NEVER',
}

/** Settings available on Question */
export type QuizSettings = {
  __typename?: 'QuizSettings';
  retakeAllowedCount?: Maybe<Scalars['Float']>;
  retakeType?: Maybe<QuizRetakeTypeEnum>;
  revealAnswers?: Maybe<QuizRevealAnswerEnum>;
  syncSkillScore?: Maybe<Scalars['Boolean']>;
  /** Time limit in seconds */
  timeLimit?: Maybe<Scalars['Float']>;
  timeLimitApplicableOn?: Maybe<TimeLimitApplicableOnEnum>;
};

/** Settings available on Question */
export type QuizSettingsInput = {
  retakeAllowedCount?: InputMaybe<Scalars['Float']>;
  retakeType?: InputMaybe<QuizRetakeTypeEnum>;
  revealAnswers?: InputMaybe<QuizRevealAnswerEnum>;
  syncSkillScore?: InputMaybe<Scalars['Boolean']>;
  /** Time limit in seconds */
  timeLimit?: InputMaybe<Scalars['Float']>;
  timeLimitApplicableOn?: InputMaybe<TimeLimitApplicableOnEnum>;
};

export type QuizSingleChoiceQuestionStubType = {
  __typename?: 'QuizSingleChoiceQuestionStubType';
  /** 1-based index of the correct answer */
  answer: Scalars['Float'];
  explanation?: Maybe<Scalars['String']>;
  options: Array<Scalars['String']>;
  question: Scalars['String'];
};

export type QuizSkillIdsSetInput = {
  /** SkillIds to be updated */
  set: Array<Scalars['String']>;
};

/** Payment Create Checkout Session Mutation Payload For Razorpay */
export type RazorpayCheckoutSessionMutationPayload = {
  __typename?: 'RazorpayCheckoutSessionMutationPayload';
  amount?: Maybe<Scalars['Float']>;
  orderId?: Maybe<Scalars['String']>;
  paymentProvider: TransactionPaymentProviderEnum;
  razorpayKeyId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  transactionIds?: Maybe<Array<Scalars['String']>>;
};

export type ReSyncGroupInput = {
  groupId: Scalars['String'];
  workspaceId: Scalars['String'];
};

/** Reactions on a post */
export type ReactionType = {
  __typename?: 'ReactionType';
  count: Scalars['Float'];
  hasReacted?: Maybe<Scalars['Boolean']>;
  reaction: Scalars['String'];
  userIds: Array<Scalars['String']>;
  userWorkspaceInfos?: Maybe<Array<Scalars['String']>>;
  users?: Maybe<Array<UserType>>;
};

export type ReadNotificationsInput = {
  /** If this flag is passed as true, then ALL unread notifications of the user will be marked as read */
  readAll?: InputMaybe<Scalars['Boolean']>;
  userNotificationId?: InputMaybe<Scalars['String']>;
};

/** Input to clear current state of performance and save the attempt details in attempts array. */
export type ReattemptQuestionsInput = {
  /** Changes reference to new parent(quiz). Used when resetting the parent. */
  newParentAttemptId?: InputMaybe<Scalars['String']>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String'];
  /** QuestionIds of which performance needs to be cleared. */
  questionIds: Array<Scalars['String']>;
  /** QuizId of which performance needs to be cleared. */
  quizId: Scalars['String'];
};

/** ReattemptQuestion Response */
export type ReattemptQuestionsPayload = {
  __typename?: 'ReattemptQuestionsPayload';
  performances?: Maybe<Array<QuestionPerformanceType>>;
};

export type RecalibrateConfigInput = {
  /** Update the progress for learners who have completed content */
  recalibrateCompletedPerformances?: InputMaybe<Scalars['Boolean']>;
  /** Update the progress for learners who are inprogress */
  recalibrateInProgressPerformances?: InputMaybe<Scalars['Boolean']>;
};

export type RecalibrateConfigType = {
  __typename?: 'RecalibrateConfigType';
  /** Update the progress for learners who have completed content */
  recalibrateCompletedPerformances?: Maybe<Scalars['Boolean']>;
  /** Update the progress for learners who are inprogress */
  recalibrateInProgressPerformances?: Maybe<Scalars['Boolean']>;
};

/** RecalibrateContentProgressInput! */
export type RecalibrateContentProgressInput = {
  entityId: Scalars['String'];
  recalibrateConfig?: InputMaybe<RecalibrateConfigInput>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** RecalibrateContentProgressPropsType! */
export type RecalibrateContentProgressPropsType = {
  __typename?: 'RecalibrateContentProgressPropsType';
  entityId: Scalars['String'];
  userIds?: Maybe<Array<Scalars['String']>>;
};

export type RecipientInfo = {
  __typename?: 'RecipientInfo';
  emailId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type RecipientInfoInput = {
  emailId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** Settings for recording */
export type RecordingSettings = {
  __typename?: 'RecordingSettings';
  /** automatically record the event */
  automaticRecording: Scalars['Boolean'];
  /** share event recording with learners */
  shareRecording: Scalars['Boolean'];
};

/** Settings for recording */
export type RecordingSettingsInput = {
  /** automatically record the event */
  automaticRecording: Scalars['Boolean'];
  /** share event recording with learners */
  shareRecording: Scalars['Boolean'];
};

export type RecordingWatchSummary = {
  __typename?: 'RecordingWatchSummary';
  progress: Scalars['Float'];
  recordingId: Scalars['String'];
};

export type RecordingWatchSummaryInput = {
  progress: Scalars['Float'];
  recordingId: Scalars['String'];
};

export enum RecurringScheduledJobStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
}

export type RecurringScheduledJobType = {
  __typename?: 'RecurringScheduledJobType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  cron: Scalars['String'];
  id: Scalars['String'];
  /** Type of job grpc/http */
  invocationType: InvocationTypeEnum;
  nextExecutionAt: Scalars['String'];
  recipientService: Scalars['String'];
  rpcInput: Scalars['String'];
  rpcMethod: Scalars['String'];
  schedule?: Maybe<ScheduleInfo>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  specialConditions?: Maybe<SpecialConditions>;
  /** Execution Status Of Job */
  status: RecurringScheduledJobStatusEnum;
  timezone: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type RedirectActionsInput = {
  onFailure?: InputMaybe<OnFailureActionsInput>;
  onSuccess?: InputMaybe<OnSuccessActionsInput>;
};

export type RedirectionActions = {
  __typename?: 'RedirectionActions';
  onFailure: OnFailureActions;
  onSuccess: OnSuccessActions;
};

export type RedirectionActionsType = {
  __typename?: 'RedirectionActionsType';
  onFailure: OnFailureActionsType;
  onSuccess: OnSuccessActionsType;
};

export type RegenerateMeetingInstanceSummaryInput = {
  meetingInstanceId: Scalars['String'];
};

export type RegenerateMeetingInstanceSummaryPayload = {
  __typename?: 'RegenerateMeetingInstanceSummaryPayload';
  generativeTaskId: Scalars['String'];
};

export type RegisterEventInput = {
  eventId: Scalars['String'];
};

export type RegisterWebhookInput = {
  eventTypes: Array<NotificationActionTypeEnum>;
  expiry?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type RegisterWebhookPayload = {
  __typename?: 'RegisterWebhookPayload';
  /** Register Webhook */
  webhook?: Maybe<WebhookType>;
};

/** Settings for registration */
export type RegistrationSettings = {
  __typename?: 'RegistrationSettings';
  /** time in seconds before startTime, when event registration should be closed */
  closeRegistrationBeforeStart: Scalars['Float'];
  seatLimit?: Maybe<Scalars['Float']>;
};

/** Settings for registration */
export type RegistrationSettingsInput = {
  /** time in seconds before startTime, when event registration should be closed */
  closeRegistrationBeforeStart?: InputMaybe<Scalars['Float']>;
  seatLimit?: InputMaybe<Scalars['Float']>;
};

export type RelatedConnectionsFilters = {
  searchText?: InputMaybe<Scalars['String']>;
  workspaceIds?: InputMaybe<Array<Scalars['String']>>;
};

export type RelatedConnectionsPayload = {
  __typename?: 'RelatedConnectionsPayload';
  workspaceDetails: Array<WorkspaceDetails>;
};

export type RelatedEntityInput = {
  entityId: Scalars['String'];
  entityType: RelatedEntityTypeEnum;
};

export enum RelatedEntityTypeEnum {
  AgendaItem = 'AGENDA_ITEM',
  KeyResult = 'KEY_RESULT',
  Meeting = 'MEETING',
  MeetingInstance = 'MEETING_INSTANCE',
  Okr = 'OKR',
  PeerFeedback = 'PEER_FEEDBACK',
  TeamMeeting = 'TEAM_MEETING',
}

/** Metadata for relation fields */
export type RelationMetadataInput = {
  collectionName: EntityCollectionEnum;
  /** type of resource */
  type: PerformanceTypeEnum;
};

export type RelativeDateFilter = {
  operator: RelativeDateFilterOperatorEnum;
  unit: RelativeDateFilterUnitEnum;
  value?: InputMaybe<Scalars['Float']>;
};

export enum RelativeDateFilterOperatorEnum {
  Last = 'LAST',
  Next = 'NEXT',
  Now = 'NOW',
  Previous = 'PREVIOUS',
  This = 'THIS',
}

export type RelativeDateFilterType = {
  __typename?: 'RelativeDateFilterType';
  operator: RelativeDateFilterOperatorEnum;
  unit: RelativeDateFilterUnitEnum;
  value?: Maybe<Scalars['Float']>;
};

export enum RelativeDateFilterUnitEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

/** For scheduling reminders for a particular notification type */
export type ReminderConfigurationInput = {
  reminderType: ReminderTypeEnum;
  unit: ReminderConfigurationUnitTypeEnum;
  value: Scalars['Float'];
};

export type ReminderConfigurationType = {
  __typename?: 'ReminderConfigurationType';
  reminderType: ReminderTypeEnum;
  unit: ReminderConfigurationUnitTypeEnum;
  value: Scalars['Float'];
};

export enum ReminderConfigurationUnitTypeEnum {
  Days = 'DAYS',
  Minutes = 'MINUTES',
  Months = 'MONTHS',
}

export enum ReminderTypeEnum {
  After = 'AFTER',
  Before = 'BEFORE',
}

export type RemoveAuditMetadataType = {
  __typename?: 'RemoveAuditMetadataType';
  action: AuditActionEnum;
  auditId: Scalars['String'];
  collectionName: EntityCollectionEnum;
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  /** Content that was bookmarked by the user */
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<InstructionType>;
  /** Display string for the actual value, a string to be shown on UI */
  displayField?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  /** Actual field that is being tracked/referenced in this audit metadata of the entity */
  fieldName: Scalars['String'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  oldValue: Scalars['String'];
  relatedCollectionName?: Maybe<EntityCollectionEnum>;
  valueType: Scalars['String'];
  workspaceId: Scalars['String'];
};

/** Input to remove database document */
export type RemoveDatabaseDataInput = {
  dbId: Scalars['String'];
  documentId: Scalars['String'];
};

export type RemoveEntityAccessInput = {
  entityId: Scalars['String'];
  entityType: PermissionEntities;
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type RemoveEntityFromMetricInput = {
  entityId: Scalars['String'];
  entityType?: InputMaybe<ActivityMetricEntityTypeEnum>;
  metricId: Scalars['String'];
};

export type RemoveEntityTagInput = {
  entityId: Scalars['String'];
  tagId: Scalars['String'];
};

export type RemoveMarketplaceImportedContentInput = {
  marketplaceContentId: Scalars['String'];
};

export type RemoveMarketplaceImportedContentPayload = {
  __typename?: 'RemoveMarketplaceImportedContentPayload';
  marketplaceContent?: Maybe<MarketplaceContentType>;
  marketplaceContentId: Scalars['String'];
  /** @deprecated this is just for backward compatibility */
  success: Scalars['Boolean'];
};

/** Inputs required to remove permissions for a role */
export type RemovePermissionsInput = {
  /** Permissions to be added */
  permittedOn: RolePermittedOnInput;
  /** roleId of Role from which you want to remove permissions */
  roleId: Scalars['String'];
};

export type RemovePermissionsPayload = {
  __typename?: 'RemovePermissionsPayload';
  /** Whether permission was removed successfully or not */
  success: Scalars['Boolean'];
};

/** Input to remove postAttachments */
export type RemovePostAttachmentsInput = {
  /** Ids of the postAttachment one needs to remove */
  postAttachmentIds: Array<Scalars['String']>;
};

export type RemoveQuestionsInput = {
  /** Filters to select questions to be removed */
  filters: QuestionFilters;
};

export type RemoveQuestionsPayload = {
  __typename?: 'RemoveQuestionsPayload';
  /** Questions removed */
  removedQuestions: Array<QuestionType>;
};

export type RemoveReactionInput = {
  /** ChannelId on which the post exists */
  channelId: Scalars['String'];
  /** Post Id on which the reaction exists */
  postId: Scalars['String'];
  /** Actual reaction */
  reaction: Scalars['String'];
};

export type RemoveSkillsFromSkillGroupInput = {
  /** skillGroupId from which given skillIds should be removed */
  skillGroupId: Scalars['String'];
  /** skillId to remove */
  skillIds: Array<Scalars['String']>;
};

export type RemoveUserGroupsInput = {
  /** userId, groupId, roleId configurations to be removed */
  userGroups: Array<UserGroupConfigs>;
};

/** Object for removing tokens for push notification for an user. */
export type RemoveUserPushNotificationTokensInput = {
  /** deviceId for which the token is removed */
  deviceIds?: InputMaybe<Array<Scalars['String']>>;
  /** User id for which tokens are to be removed. */
  userId: Scalars['String'];
};

export type RemoveUsersFromClassroomEventInput = {
  eventId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

/** Remove users from event response */
export type RemoveUsersFromClassroomEventPayload = {
  __typename?: 'RemoveUsersFromClassroomEventPayload';
  errorCode?: Maybe<ClassroomEventErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Remove users from event response */
export type RemoveUsersFromClassroomEventResponse = {
  __typename?: 'RemoveUsersFromClassroomEventResponse';
  success: Scalars['Boolean'];
};

/** Removing a learner from classroom */
export type RemoveUsersFromClassroomInput = {
  classroomId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type RemoveUsersFromClassroomPayload = {
  __typename?: 'RemoveUsersFromClassroomPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type RemoveUsersFromEventScheduleInput = {
  eventScheduleId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

/** Remove users from event schedule response */
export type RemoveUsersFromEventSchedulePayload = {
  __typename?: 'RemoveUsersFromEventSchedulePayload';
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RemoveUsersFromStaffInput = {
  userIds: Array<Scalars['String']>;
};

export type RemoveUsersFromStaffRolesInput = {
  roleIds: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

export type RemoveWebhookInput = {
  webhookId: Scalars['String'];
};

export type RemoveWebhookPayload = {
  __typename?: 'RemoveWebhookPayload';
  success: Scalars['Boolean'];
};

export type RemovedStatusMappingInputType = {
  removedStatus: Scalars['String'];
  replacedWithStatus: Scalars['String'];
};

/** Edge */
export type ReportEdge = {
  __typename?: 'ReportEdge';
  node: ReportType;
};

export type ReportExportMetadataType = {
  __typename?: 'ReportExportMetadataType';
  exportedCount: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type ReportExportPropsInput = {
  chartIds?: InputMaybe<Array<Scalars['String']>>;
  emailDigestId?: InputMaybe<Scalars['String']>;
  reportFilterGroup?: InputMaybe<FilterGroupInput>;
  reportId: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type ReportExportPropsType = {
  __typename?: 'ReportExportPropsType';
  chartIds?: Maybe<Array<Scalars['String']>>;
  emailDigestId?: Maybe<Scalars['String']>;
  reportFilterGroup?: Maybe<FilterGroupType>;
  reportId: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
};

export type ReportMediaExportMetadataType = {
  __typename?: 'ReportMediaExportMetadataType';
  exportedCount: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type ReportMediaExportPropsInput = {
  baseUrlOverride?: InputMaybe<Scalars['String']>;
  emailDigestId?: InputMaybe<Scalars['String']>;
  exportFormat: MediaExportFormatEnum;
  reportId: Scalars['String'];
  urlQueryParams?: InputMaybe<Scalars['String']>;
};

export type ReportMediaExportPropsType = {
  __typename?: 'ReportMediaExportPropsType';
  baseUrlOverride?: Maybe<Scalars['String']>;
  emailDigestId?: Maybe<Scalars['String']>;
  exportFormat: MediaExportFormatEnum;
  reportId: Scalars['String'];
  urlQueryParams?: Maybe<Scalars['String']>;
};

export enum ReportStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export enum ReportSubTypeEnum {
  ActivityDetails = 'ACTIVITY_DETAILS',
  LearnerProfileActivities = 'LEARNER_PROFILE_ACTIVITIES',
  ManagerProfileOverview = 'MANAGER_PROFILE_OVERVIEW',
  MeetingInsights = 'MEETING_INSIGHTS',
  ReviewCycleMindset = 'REVIEW_CYCLE_MINDSET',
  ReviewCycleParticipation = 'REVIEW_CYCLE_PARTICIPATION',
  ReviewCycleSkill = 'REVIEW_CYCLE_SKILL',
  TeamProfileActivities = 'TEAM_PROFILE_ACTIVITIES',
  TeamProfileGoals = 'TEAM_PROFILE_GOALS',
  TeamProfileMindsets = 'TEAM_PROFILE_MINDSETS',
  TeamProfileOverview = 'TEAM_PROFILE_OVERVIEW',
  TeamProfileSkills = 'TEAM_PROFILE_SKILLS',
  TeamProfileTeam = 'TEAM_PROFILE_TEAM',
  UserProfileActivities = 'USER_PROFILE_ACTIVITIES',
  UserProfileGoals = 'USER_PROFILE_GOALS',
  UserProfileMindsets = 'USER_PROFILE_MINDSETS',
  UserProfileOverview = 'USER_PROFILE_OVERVIEW',
  UserProfileSkills = 'USER_PROFILE_SKILLS',
  UserProfileTeam = 'USER_PROFILE_TEAM',
}

/** Report schema */
export type ReportType = {
  __typename?: 'ReportType';
  /** Thumbnail image url for the standard report */
  coverImageUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  filterGroup?: Maybe<FilterGroupType>;
  iconSettings: IconSettings;
  id: Scalars['String'];
  /** if report is globle report */
  isStandardReport?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  reportStatusRelevancyScore: Scalars['Int'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: ReportStatusEnum;
  subType?: Maybe<ReportSubTypeEnum>;
  tags?: Maybe<Array<EntityTagType>>;
  type: ReportTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  visibilitySettings: VisibilitySettingsType;
  /** Underlying chart widgets */
  widgets?: Maybe<Array<ChartWidgetType>>;
  /** Contains info about chart widgets and their layout */
  widgetsLayout?: Maybe<Array<WidgetLayoutType>>;
};

export enum ReportTypeEnum {
  Custom = 'CUSTOM',
  Standard = 'STANDARD',
  System = 'SYSTEM',
}

export enum ReporteeFilterTypeEnum {
  DirectReportee = 'DIRECT_REPORTEE',
  InDirectReportee = 'IN_DIRECT_REPORTEE',
}

export type ReporteeFilterTypeInput = {
  type: ReporteeFilterTypeEnum;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type ReportingQueriesResultType = {
  __typename?: 'ReportingQueriesResultType';
  queries?: Maybe<Array<ReportingQueryStubType>>;
  suggestedQueries?: Maybe<Array<ReportingQueryStubType>>;
};

export type ReportingQueryFiltersStubType = {
  __typename?: 'ReportingQueryFiltersStubType';
  operator: Scalars['String'];
  property: Scalars['String'];
  value: Scalars['String'];
};

export type ReportingQueryMeasureStubType = {
  __typename?: 'ReportingQueryMeasureStubType';
  aggregator: Scalars['String'];
  field: Scalars['String'];
};

export type ReportingQueryStubType = {
  __typename?: 'ReportingQueryStubType';
  dimensions?: Maybe<Array<Scalars['String']>>;
  filters?: Maybe<Array<ReportingQueryFiltersStubType>>;
  measures?: Maybe<Array<ReportingQueryMeasureStubType>>;
};

export type ReportingWidgetPayloadInputType = {
  /** Will replace given reviewCycleId in place of the string `reviewCycleId` */
  reviewCycleId?: InputMaybe<Scalars['String']>;
};

export type ReportingWidgetType = {
  __typename?: 'ReportingWidgetType';
  name: Scalars['String'];
  /** Payload for reporting widget query */
  payload?: Maybe<Scalars['String']>;
  /** Payload for reporting widget query */
  payloadToParse?: Maybe<Scalars['String']>;
};

export type ReportingWidgetTypePayloadArgs = {
  input?: InputMaybe<ReportingWidgetPayloadInputType>;
};

export type ReportsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  isStandardReport?: InputMaybe<Scalars['Boolean']>;
  reportIds?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<ReportStatusEnum>>;
  subType?: InputMaybe<Array<ReportSubTypeEnum>>;
  type?: InputMaybe<Array<ReportTypeEnum>>;
};

export type ReportsToInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type RequestClassroomAccessInput = {
  classroomId: Scalars['String'];
  userId: Scalars['String'];
};

/** Request Access to Classroom Response */
export type RequestClassroomAccessPayload = {
  __typename?: 'RequestClassroomAccessPayload';
  errorCode?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  userAlreadyExists?: Maybe<Scalars['Boolean']>;
};

export type RequestConnectionInput = {
  targetWorkspaceUri: Scalars['String'];
};

export type RequestPeerFeedbackInput = {
  mindsetIds: Array<Scalars['String']>;
  requestMessage: Scalars['String'];
  requestedFromUserIds: Array<Scalars['String']>;
  skillIds: Array<Scalars['String']>;
};

export type RescheduleMeetingInstanceInput = {
  meetingInstanceId: Scalars['String'];
  startTime: Scalars['String'];
};

/** ResetContentProgressInput! */
export type ResetContentProgressInput = {
  entityIds: Array<Scalars['String']>;
  entityType?: InputMaybe<PerformanceEntityTypeEnum>;
  parentEntityId?: InputMaybe<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

/** Reset content payload */
export type ResetContentProgressPayload = {
  __typename?: 'ResetContentProgressPayload';
  jobScheduleId: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
};

export type ResetProgressUserNotification = {
  __typename?: 'ResetProgressUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  resetAt: Scalars['String'];
  resetById: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum ResolutionEnum {
  L = 'L',
  S = 'S',
  Thumb = 'THUMB',
}

export type ResolutionInputType = {
  type: ResolutionEnum;
  url: Scalars['String'];
};

/** Multiple Resolution Type */
export type ResolutionType = {
  __typename?: 'ResolutionType';
  type: ResolutionEnum;
  url: Scalars['String'];
};

export enum ResourceActions {
  Archive = 'ARCHIVE',
  Assign = 'ASSIGN',
  Consume = 'CONSUME',
  Create = 'CREATE',
  Edit = 'EDIT',
  Enroll = 'ENROLL',
  Grade = 'GRADE',
  Manage = 'MANAGE',
  Publish = 'PUBLISH',
  Read = 'READ',
  Remove = 'REMOVE',
  Return = 'RETURN',
  Share = 'SHARE',
}

export enum ResourceCategoryEnum {
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Folder = 'FOLDER',
  Image = 'IMAGE',
  Video = 'VIDEO',
  WebLink = 'WEB_LINK',
  YoutubeLink = 'YOUTUBE_LINK',
}

/** This returns paginated resources */
export type ResourceConnections = {
  __typename?: 'ResourceConnections';
  edges?: Maybe<Array<ResourceEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ResourceEdge = {
  __typename?: 'ResourceEdge';
  node: ResourceType;
};

/** It will resolve homework or resource */
export type ResourceEntity = BaseResourceSchema | HomeworkType | ResourceType;

export enum ResourceErrorCodeEnum {
  DuplicateResourceName = 'DUPLICATE_RESOURCE_NAME',
  ExerciseAllowedResponseTypesEmpty = 'EXERCISE_ALLOWED_RESPONSE_TYPES_EMPTY',
  ExerciseCreationFailed = 'EXERCISE_CREATION_FAILED',
  ExerciseNameEmpty = 'EXERCISE_NAME_EMPTY',
  ExerciseNoQuestions = 'EXERCISE_NO_QUESTIONS',
  ExerciseNoScorecards = 'EXERCISE_NO_SCORECARDS',
  ExerciseUpdateFailed = 'EXERCISE_UPDATE_FAILED',
  ExternalContentCreationFailed = 'EXTERNAL_CONTENT_CREATION_FAILED',
  ExternalContentInvalidDuration = 'EXTERNAL_CONTENT_INVALID_DURATION',
  ExternalContentInvalidProviderId = 'EXTERNAL_CONTENT_INVALID_PROVIDER_ID',
  ExternalContentNameEmpty = 'EXTERNAL_CONTENT_NAME_EMPTY',
  ExternalContentUrlEmpty = 'EXTERNAL_CONTENT_URL_EMPTY',
  HomeworkAssignmentFailed = 'HOMEWORK_ASSIGNMENT_FAILED',
  HomeworkNotFound = 'HOMEWORK_NOT_FOUND',
  InvalidData = 'INVALID_DATA',
  PathCreationFailed = 'PATH_CREATION_FAILED',
  PathCyclicDependency = 'PATH_CYCLIC_DEPENDENCY',
  PathNameEmpty = 'PATH_NAME_EMPTY',
  PathNoSections = 'PATH_NO_SECTIONS',
  PathSectionNameEmpty = 'PATH_SECTION_NAME_EMPTY',
  PathSectionNoContent = 'PATH_SECTION_NO_CONTENT',
  PathSectionOverlappingContent = 'PATH_SECTION_OVERLAPPING_CONTENT',
  QuizCreationFailed = 'QUIZ_CREATION_FAILED',
  QuizInvalidDuration = 'QUIZ_INVALID_DURATION',
  QuizInvalidPassingPercentage = 'QUIZ_INVALID_PASSING_PERCENTAGE',
  QuizNameEmpty = 'QUIZ_NAME_EMPTY',
  QuizNoQuestions = 'QUIZ_NO_QUESTIONS',
  QuizUpdationFailed = 'QUIZ_UPDATION_FAILED',
  QuizValidationFailed = 'QUIZ_VALIDATION_FAILED',
  RequiredClassroomConfigNotFound = 'REQUIRED_CLASSROOM_CONFIG_NOT_FOUND',
  ResourceNotFound = 'RESOURCE_NOT_FOUND',
  SubtaskNameEmpty = 'SUBTASK_NAME_EMPTY',
  TaskCreationFailed = 'TASK_CREATION_FAILED',
  TaskNameEmpty = 'TASK_NAME_EMPTY',
  TaskUpdateFailed = 'TASK_UPDATE_FAILED',
}

/** Resource Grading configs */
export type ResourceGradeSettingsType = {
  __typename?: 'ResourceGradeSettingsType';
  maxScore: Scalars['Float'];
};

/** Resource content description/instruction */
export type ResourceInstruction = {
  __typename?: 'ResourceInstruction';
  /** Media */
  medias: Array<EntityMediaType>;
  /** text */
  text?: Maybe<Scalars['String']>;
};

export type ResourceInstructionInput = {
  /** Media */
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** text */
  text?: InputMaybe<Scalars['String']>;
};

export enum ResourceKindEnum {
  File = 'FILE',
  Folder = 'FOLDER',
}

export enum ResourceResponseTypeEnum {
  Any = 'ANY',
  Audio = 'AUDIO',
  Email = 'EMAIL',
  File = 'FILE',
  FillInTheBlanks = 'FILL_IN_THE_BLANKS',
  Image = 'IMAGE',
  MarkAsDone = 'MARK_AS_DONE',
  MatchTheFollowing = 'MATCH_THE_FOLLOWING',
  MultipleChoiceQuestion = 'MULTIPLE_CHOICE_QUESTION',
  MultiSelect = 'MULTI_SELECT',
  Overall = 'OVERALL',
  PictureMultipleChoiceQuestion = 'PICTURE_MULTIPLE_CHOICE_QUESTION',
  Presentation = 'PRESENTATION',
  Quiz = 'QUIZ',
  SingleSelect = 'SINGLE_SELECT',
  Slider = 'SLIDER',
  Star = 'STAR',
  Text = 'TEXT',
  TrueOrFalse = 'TRUE_OR_FALSE',
  Video = 'VIDEO',
  VoiceOver = 'VOICE_OVER',
  Whiteboard = 'WHITEBOARD',
  WriteUp = 'WRITE_UP',
}

export enum ResourceReviewTypeEnum {
  MandatoryReview = 'MANDATORY_REVIEW',
  NoReview = 'NO_REVIEW',
  OptionalReview = 'OPTIONAL_REVIEW',
}

export enum ResourceStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Open = 'OPEN',
  Published = 'PUBLISHED',
  Revoked = 'REVOKED',
  ToGrade = 'TO_GRADE',
  UnpublishedChanges = 'UNPUBLISHED_CHANGES',
}

export enum ResourceSubTypeEnum {
  ExternalContent = 'EXTERNAL_CONTENT',
  InLesson = 'IN_LESSON',
  Review = 'REVIEW',
  Static = 'STATIC',
}

export enum ResourceSubjects {
  Assignment = 'ASSIGNMENT',
  Homework = 'HOMEWORK',
  Performance = 'PERFORMANCE',
  Report = 'REPORT',
  Resource = 'RESOURCE',
  Submission = 'SUBMISSION',
  SubWorkspace = 'SUB_WORKSPACE',
}

/** Resource Type. This can be used in classrooms */
export type ResourceType = {
  __typename?: 'ResourceType';
  availableMediaFormats: Array<AvailableMediaFormatType>;
  /** category of uploaded resource */
  category: ResourceCategoryEnum;
  /** Associate with classroom */
  classroomId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  entityMedia: EntityMediaType;
  /** resource extension */
  format?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Associated with convertApi, tells whether the documents is converted into images or not */
  jobId?: Maybe<Scalars['String']>;
  jobStatus?: Maybe<JobStatusEnum>;
  /** Tells whether it is a file or folder */
  kind: ResourceKindEnum;
  /** media type of uploaded resource */
  mimeType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Count of the nested folder and files inside the folder */
  nestedResourceCount: Scalars['Float'];
  /** Parent folder Id of the resource */
  parentId?: Maybe<Scalars['String']>;
  /** Location under which folders/file will be searched */
  path?: Maybe<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Size of the attachment in kbs */
  size?: Maybe<Scalars['Float']>;
  status: ResourceStatusEnum;
  type?: Maybe<ResourceTypeEnum>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** url of the attachment stored on our end */
  url?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum ResourceTypeEnum {
  Article = 'ARTICLE',
  Checkin = 'CHECKIN',
  Course = 'COURSE',
  Exercise = 'EXERCISE',
  ExternalContent = 'EXTERNAL_CONTENT',
  ExternalEventContent = 'EXTERNAL_EVENT_CONTENT',
  Homework = 'HOMEWORK',
  LiveEventContent = 'LIVE_EVENT_CONTENT',
  OfflineEventContent = 'OFFLINE_EVENT_CONTENT',
  Path = 'PATH',
  Quiz = 'QUIZ',
  Scorm = 'SCORM',
  Survey = 'SURVEY',
  Task = 'TASK',
}

export type ResourceUserInteractionFilters = {
  /** recentTimestamp range */
  recencyTimestampRange?: InputMaybe<TimestampRangeInput>;
  /** resourceIds */
  resourceIds?: InputMaybe<Array<Scalars['String']>>;
  /** user id */
  userId: Scalars['String'];
};

export enum ResourceUserInteractionOperation {
  Edit = 'EDIT',
  View = 'VIEW',
}

/** Interactions between a resource and a user */
export type ResourceUserInteractionType = {
  __typename?: 'ResourceUserInteractionType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  lastEditedAt?: Maybe<Scalars['String']>;
  lastViewedAt?: Maybe<Scalars['String']>;
  recencyReason: Scalars['String'];
  recencyTimestamp: Scalars['String'];
  /** content id */
  resourceId: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user who viewed or edited the resource */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ResourceUserInteractionsPayload = {
  __typename?: 'ResourceUserInteractionsPayload';
  resourceUserInteractions?: Maybe<Array<ResourceUserInteractionType>>;
};

/** This returns paginated ResourceVersionTypes */
export type ResourceVersionConnections = {
  __typename?: 'ResourceVersionConnections';
  edges?: Maybe<Array<ResourceVersionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ResourceVersionEdge = {
  __typename?: 'ResourceVersionEdge';
  node: ResourceVersionType;
};

export type ResourceVersionFilters = {
  contentId?: InputMaybe<Scalars['String']>;
  versionType?: InputMaybe<ResourceVersionTypeEnum>;
};

/** Contains information regarding the published version of the content */
export type ResourceVersionType = {
  __typename?: 'ResourceVersionType';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  note: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  type: ResourceVersionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum ResourceVersionTypeEnum {
  Major = 'MAJOR',
  Minor = 'MINOR',
}

/** This is the input for resubmission of Exercise! */
export type ResubmitExerciseSubmissionInput = {
  entityId: Scalars['String'];
  isActualSubmit: Scalars['Boolean'];
  parentEntityId?: InputMaybe<Scalars['String']>;
  questionResponses?: InputMaybe<Array<ExerciseQuestionResponseInput>>;
  submissionId: Scalars['String'];
};

/** Make Exercise Submission Response */
export type ResubmitExerciseSubmissionPayload = {
  __typename?: 'ResubmitExerciseSubmissionPayload';
  performance: ExercisePerformanceType;
};

/** Load OKR Summary Input */
export type ResummarizeOkrInput = {
  /** Id of the okr which needs to summarized */
  okrId: Scalars['String'];
  /** use a custom prompt for summary generation */
  prompt?: InputMaybe<Scalars['String']>;
};

export type ResummarizeOkrPayload = {
  __typename?: 'ResummarizeOKRPayload';
  /** ISO date string */
  createdAt: Scalars['String'];
  /** what service was used to generate the summary */
  generatedBy: Scalars['String'];
  /** Id of the okr which was summarized */
  okrId: Scalars['String'];
  /** the summary */
  summary: Scalars['String'];
};

/** This is the input for retaking the quiz */
export type RetakeQuizInput = {
  /** Whether quiz being retaken is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String'];
  /** quizId for which revaluation is taking place */
  quizId: Scalars['String'];
};

/** Quiz reevaluation response */
export type RetakeQuizPayload = {
  __typename?: 'RetakeQuizPayload';
  quizPerformance?: Maybe<QuizPerformanceType>;
};

/** retry info */
export type RetryInfoType = {
  __typename?: 'RetryInfoType';
  /** feedback */
  feedback?: Maybe<Scalars['String']>;
  /** submitted at */
  reviewedAt: Scalars['String'];
};

/** Input for returning graded assignment to students */
export type ReturnAssignmentGradeToStudentsInput = {
  /** Assignment Id whose grades are to be returned to the students */
  entityId: Scalars['String'];
  /** Id of the students who will be receiving their grades */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Input to undo performance reporting */
export type RevertPerformancesToNotSubmittedInput = {
  /** this assignment will be marked submitted */
  entityId: Scalars['String'];
  /** Id of the students who will have status submitted */
  userIds: Array<Scalars['String']>;
};

export type ReviewConnectionInput = {
  sharedConnectionId: Scalars['String'];
  status: TenantConnectionStatusEnum;
};

/** Edge */
export type ReviewCycleEdge = {
  __typename?: 'ReviewCycleEdge';
  node: ReviewCycleType;
};

export type ReviewCycleEndSettingsInput = {
  cycleEndType: ReviewCycleEndTypeEnum;
  endDate?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type ReviewCycleEndSettingsType = {
  __typename?: 'ReviewCycleEndSettingsType';
  cycleEndType: ReviewCycleEndTypeEnum;
  endDate?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export enum ReviewCycleEndTypeEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  Manual = 'MANUAL',
}

export type ReviewCycleEndedUserNotification = {
  __typename?: 'ReviewCycleEndedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  endDate: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  reviewCycleId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ReviewCycleFilters = {
  reviewCycleId: Scalars['String'];
};

export type ReviewCycleInstanceFilters = {
  /** ids of review cycle instances */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** reviewee to which reviewCycle instance can be attached */
  reviewees?: InputMaybe<Array<RevieweeInputType>>;
  /** Status of Review Cycle one needs to fetch */
  status?: InputMaybe<ReviewCycleStatusEnum>;
};

export type ReviewCycleInstanceTodoType = {
  __typename?: 'ReviewCycleInstanceTodoType';
  reviewCycleInstance: ReviewCycleInstanceType;
  type: TodoTypeEnum;
};

/** ReviewCycle Schema */
export type ReviewCycleInstanceType = {
  __typename?: 'ReviewCycleInstanceType';
  /** Advance settings to control certain aspects of review */
  advanceSettings?: Maybe<AdvanceSettingsType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** ReviewCycle description */
  description?: Maybe<Scalars['String']>;
  /** Time taken to complete one review cycle */
  duration?: Maybe<Scalars['Float']>;
  entity?: Maybe<ContentEntity>;
  /** CheckIn Id of the review cycle instance */
  entityId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Overdue settings to indicate allowed extension */
  overdueSettings?: Maybe<OverDueSettingsType>;
  /** Total reviews already done by the reviewer */
  pendingReviewsCount?: Maybe<Scalars['Float']>;
  /** Id of the Review Cycle from which this instance is created */
  reviewCycleId: Scalars['String'];
  /** reviewee to whom this reviewCycleInstance is attached */
  reviewees?: Maybe<Array<RevieweeType>>;
  /** Reviewer metrics */
  reviewerMetrics?: Maybe<ReviewerMetricsType>;
  /** Types of Reviewers allowed to give the review */
  reviewerTypes: Array<ReviewTypeEnum>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** When was this instance started */
  startDate: Scalars['String'];
  /** Status of this review cycle instance */
  status: Scalars['String'];
  /** ReviewCycle Name or Title */
  title: Scalars['String'];
  /** Total reviews to be done based on review cycle type */
  totalReviewsCount?: Maybe<Scalars['Float']>;
  /** Type of this review cycle instance */
  type: ReviewCycleTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Filters applicable on userSkillReview */
export type ReviewCycleMyRevieweesInput = {
  reviewCycleId: Scalars['String'];
};

/** ReviewCycleMyRevieweesPayload */
export type ReviewCycleMyRevieweesPayload = {
  __typename?: 'ReviewCycleMyRevieweesPayload';
  manager?: Maybe<Array<UserProfile>>;
  others?: Maybe<Array<UserProfile>>;
  revieweeReviewTypes?: Maybe<Array<RevieweeReviewTypes>>;
  self?: Maybe<UserProfile>;
};

/** review cycle payload */
export type ReviewCyclePayload = {
  __typename?: 'ReviewCyclePayload';
  reviewCycle: ReviewCycleType;
};

/** Filters applicable on userSkillReview */
export type ReviewCycleReportsInput = {
  reportSubTypes?: InputMaybe<Array<ReportSubTypeEnum>>;
};

/** User ongoing Review Cycle */
export type ReviewCycleResultType = {
  __typename?: 'ReviewCycleResultType';
  reviewCycle?: Maybe<ReviewCycleInstanceType>;
};

export type ReviewCycleStartedUserNotification = {
  __typename?: 'ReviewCycleStartedUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  endDate: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  reviewCycleId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startDate: Scalars['String'];
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum ReviewCycleStatusEnum {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
}

export type ReviewCycleTodoType = {
  __typename?: 'ReviewCycleTodoType';
  reviewCycle: ReviewCycleType;
  type: TodoTypeEnum;
};

/** ReviewCycle Schema */
export type ReviewCycleType = {
  __typename?: 'ReviewCycleType';
  /** Advance settings to control certain aspects of review */
  advanceSettings?: Maybe<AdvanceSettingsType>;
  /** When will the review cycle be closed */
  closesAt: Scalars['String'];
  /** What percent of the reviewCycle is complete */
  completionPercentage: Scalars['Float'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** ReviewCycle description */
  description?: Maybe<Scalars['String']>;
  /** Time taken to complete one review cycle */
  duration?: Maybe<Scalars['Float']>;
  /** When will the review cycle end */
  endDate: Scalars['String'];
  /** Icon settings */
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String'];
  /** Types of modules to be reviewed in this review cycle */
  modulesToReview?: Maybe<Array<ReviewModuleTypeEnum>>;
  /** cron expression of the review cycle occurrence */
  occurrence?: Maybe<Scalars['String']>;
  /** Overdue settings to indicate allowed extension */
  overdueSettings?: Maybe<OverDueSettingsType>;
  /** Total reviews already done by the reviewer */
  pendingReviewsCount?: Maybe<Scalars['Float']>;
  /** Tags of reviewerTagIds */
  reports?: Maybe<Array<ReportType>>;
  /** How and when to end the review cycle */
  reviewCycleEndSettings: ReviewCycleEndSettingsType;
  /** reviewee to whom this reviewCycle is attached */
  reviewees?: Maybe<Array<RevieweeType>>;
  /** Reviewer metrics */
  reviewerMetrics?: Maybe<ReviewerMetricsType>;
  /** TagId of the Tag (userType) that is selected as a reviewer for the reviewees in the review cycle */
  reviewerTagIds?: Maybe<Array<Scalars['String']>>;
  /** Tags of reviewerTagIds */
  reviewerTags?: Maybe<Array<TagUnion>>;
  /** Types of Reviewers allowed to give the review */
  reviewerTypes: Array<ReviewTypeEnum>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** When will the review cycle start */
  startDate: Scalars['String'];
  /** Status of this review cycle */
  status: ReviewCycleStatusEnum;
  /** ReviewCycle Name or Title */
  title: Scalars['String'];
  /** Total reviews to be done based on review cycle type */
  totalReviewsCount?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** ReviewCycle Schema */
export type ReviewCycleTypeReportsArgs = {
  filters?: InputMaybe<ReviewCycleReportsInput>;
};

export enum ReviewCycleTypeEnum {
  CheckIn = 'CHECK_IN',
  Skill = 'SKILL',
}

export type ReviewCyclesFilters = {
  /** Review cycle closes after */
  closesAfter?: InputMaybe<Scalars['DateTime']>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  revieweeIds?: InputMaybe<Array<Scalars['String']>>;
  /** reviewee to which reviewCycle instance can be attached */
  reviewees?: InputMaybe<Array<RevieweeInput>>;
  reviewerIds?: InputMaybe<Array<Scalars['String']>>;
  reviewers?: InputMaybe<Array<ReviewTypeEnum>>;
  /** name of the Skill */
  searchText?: InputMaybe<Scalars['String']>;
  /** Status of Review Cycle one needs to fetch */
  status?: InputMaybe<Array<ReviewCycleStatusEnum>>;
};

export type ReviewCyclesPayload = {
  __typename?: 'ReviewCyclesPayload';
  /** Review Cycle Instance of current user */
  reviewCycleInstances?: Maybe<Array<ReviewCycleType>>;
};

export type ReviewCyclesWidgetType = {
  __typename?: 'ReviewCyclesWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  reviewCycles: Array<ReviewCycleType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ReviewCyclesWidgetTypeReviewCyclesArgs = {
  first?: InputMaybe<Scalars['Float']>;
};

export enum ReviewModuleTypeEnum {
  Mindset = 'MINDSET',
  Skill = 'SKILL',
}

export enum ReviewStatusEnum {
  InProgress = 'IN_PROGRESS',
  NotRequired = 'NOT_REQUIRED',
  NotStarted = 'NOT_STARTED',
  PeerReviewPending = 'PEER_REVIEW_PENDING',
  Rejected = 'REJECTED',
  Reviewed = 'REVIEWED',
  Unassigned = 'UNASSIGNED',
}

export enum ReviewTypeEnum {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Peer = 'PEER',
  Self = 'SELF',
  Tag = 'TAG',
  User = 'USER',
}

export enum RevieweeEntityTypeEnum {
  AllLearners = 'ALL_LEARNERS',
  Department = 'DEPARTMENT',
  Group = 'GROUP',
  OrgRole = 'ORG_ROLE',
  User = 'USER',
  Workspace = 'WORKSPACE',
}

/** Reviwee Object schema  */
export type RevieweeInput = {
  /** Ids of reviewees */
  entityIds: Array<Scalars['String']>;
  /** Type of reviewee added */
  entityType: RevieweeEntityTypeEnum;
};

/** Reviwee input schema  */
export type RevieweeInputType = {
  /** Id of reviewee  */
  entityId?: InputMaybe<Scalars['String']>;
  /** Type of reviewee added */
  entityType?: InputMaybe<RevieweeEntityTypeEnum>;
};

/** A map of all distinct reviewTypes on which a reviewee is getting reviewed (by a particular reviewer) */
export type RevieweeReviewTypes = {
  __typename?: 'RevieweeReviewTypes';
  reviewTypes: Array<Scalars['String']>;
  revieweeId: Scalars['String'];
};

/** Reviwee Object schema  */
export type RevieweeType = {
  __typename?: 'RevieweeType';
  /** Ids of reviewees */
  entityIds: Array<Scalars['String']>;
  /** Type of reviewee added */
  entityType: RevieweeEntityTypeEnum;
};

export type ReviewerConfig = {
  __typename?: 'ReviewerConfig';
  anonymousReview?: Maybe<Scalars['Boolean']>;
  defaultReviewerId?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  minimumReviewsRequired?: Maybe<Scalars['Float']>;
  tagId?: Maybe<Scalars['String']>;
  type: ReviewerTypeEnum;
  userGroupIds?: Maybe<Array<Scalars['String']>>;
  userIds?: Maybe<Array<Scalars['String']>>;
};

export type ReviewerConfigFilters = {
  defaultReviewerIds?: InputMaybe<Array<Scalars['String']>>;
  fieldNames?: InputMaybe<Array<Scalars['String']>>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<ReviewerTypeEnum>>;
  userGroupIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ReviewerConfigInput = {
  anonymousReview?: InputMaybe<Scalars['Boolean']>;
  defaultReviewerId?: InputMaybe<Scalars['String']>;
  fieldName?: InputMaybe<Scalars['String']>;
  minimumReviewsRequired?: InputMaybe<Scalars['Float']>;
  tagId?: InputMaybe<Scalars['String']>;
  type: ReviewerTypeEnum;
  userGroupIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ReviewerExerciseSubmissionScorecardScoreType = {
  __typename?: 'ReviewerExerciseSubmissionScorecardScoreType';
  reviewerId: Scalars['String'];
  scorecardScores: Array<ExerciseSubmissionScorecardScoreType>;
};

/** Data regarding the reviewer and assignment  */
export type ReviewerInfo = {
  __typename?: 'ReviewerInfo';
  assignedAt: Scalars['String'];
  reviewerId: Scalars['String'];
};

/** ReviewerMetricsType */
export type ReviewerMetricsType = {
  __typename?: 'ReviewerMetricsType';
  /** Count of pending reviews to be done by reviewer */
  pendingReviewsCount: Scalars['Float'];
  /** Total reviews to be done by reviewer */
  totalReviewsCount: Scalars['Float'];
};

/** ReviewerReviewResponse Schema */
export type ReviewerResponseType = {
  __typename?: 'ReviewerResponseType';
  /** feedback text */
  feedbackText?: Maybe<Scalars['String']>;
  /** question */
  questionId: Scalars['String'];
  /** response */
  response?: Maybe<Scalars['String']>;
  /** response type */
  responseType?: Maybe<Scalars['String']>;
};

export enum ReviewerTypeEnum {
  Dynamic = 'DYNAMIC',
  Group = 'GROUP',
  Peer = 'PEER',
  Specific = 'SPECIFIC',
}

/** object for reviewers to set empty array as well */
export type ReviewersSetInput = {
  set: Array<Scalars['String']>;
};

/** This returns paginated Roles */
export type RoleConntection = {
  __typename?: 'RoleConntection';
  edges?: Maybe<Array<RoleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type RoleEdge = {
  __typename?: 'RoleEdge';
  node: RoleType;
};

export type RoleFilters = {
  roleId?: InputMaybe<Array<Scalars['String']>>;
};

export type RoleMetadata = {
  __typename?: 'RoleMetadata';
  configuredPermissions?: Maybe<Array<Scalars['String']>>;
  tagColor?: Maybe<Scalars['String']>;
};

export type RolePermissionDescriptionType = {
  __typename?: 'RolePermissionDescriptionType';
  keys: Array<Scalars['String']>;
  value: Scalars['String'];
};

export type RolePermissionDescriptionsPayload = {
  __typename?: 'RolePermissionDescriptionsPayload';
  rolePermissionDescriptions: Array<RolePermissionDescriptionType>;
};

/** Role permissions input */
export type RolePermittedOnInput = {
  entity: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

/** Role Profile */
export type RoleProfileType = {
  __typename?: 'RoleProfileType';
  id: Scalars['String'];
  sampleUsers: Array<UserType>;
  userIds: Array<Scalars['String']>;
  usersCount: Scalars['Float'];
};

export enum RoleStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

/** The Role model */
export type RoleType = {
  __typename?: 'RoleType';
  /** On which entity the role is applicable */
  applicableOn: Array<ApplicableOnEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Custom role for entity */
  entityId: Scalars['String'];
  id: Scalars['String'];
  metadata?: Maybe<RoleMetadata>;
  name: Scalars['String'];
  permittedOn: Array<PermittedOnType>;
  roleProfile: RoleProfileType;
  sampleUsers: Array<UserType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: Scalars['String'];
  /** Type of role */
  type: RoleTypesEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  usersCount: Scalars['Float'];
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum RoleTypesEnum {
  Admin = 'ADMIN',
  Assigner = 'ASSIGNER',
  Author = 'AUTHOR',
  CompanyObjective = 'COMPANY_OBJECTIVE',
  Custom = 'CUSTOM',
  DepartmentObjective = 'DEPARTMENT_OBJECTIVE',
  EditAccess = 'EDIT_ACCESS',
  EventOwner = 'EVENT_OWNER',
  FullAccess = 'FULL_ACCESS',
  Instructor = 'INSTRUCTOR',
  Learner = 'LEARNER',
  Owner = 'OWNER',
  Participant = 'PARTICIPANT',
  TeamObjective = 'TEAM_OBJECTIVE',
  ViewAccess = 'VIEW_ACCESS',
}

export type RolesFilters = {
  applicableOn?: InputMaybe<Array<ApplicableOnEnum>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  names?: InputMaybe<Array<Scalars['String']>>;
  roleIds?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<RoleStatusEnum>>;
  types?: InputMaybe<Array<RoleTypesEnum>>;
};

export type RolesProflesInput = {
  roleIds: Array<Scalars['String']>;
};

/** Input to rollUp and update all the blocks duration */
export type RollUpDurationOfBlocksInput = {
  /** Root block Id of the course */
  rootBlockId: Scalars['String'];
};

/** Updated blocks with duration */
export type RollUpDurationOfBlocksPayload = {
  __typename?: 'RollUpDurationOfBlocksPayload';
  blocks?: Maybe<Array<BaseBlockType>>;
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum RootActionItemFilter {
  Both = 'BOTH',
  NonRootActionItems = 'NON_ROOT_ACTION_ITEMS',
  RootActionItems = 'ROOT_ACTION_ITEMS',
}

/** Skill Schema */
export type Rubric = {
  __typename?: 'Rubric';
  /** Meaning while giving that score. */
  description?: Maybe<Scalars['String']>;
  /** Label corresponding to a score. */
  label: SkillRubricLabelEnum;
  /** value/score for that skill. */
  score: Scalars['Float'];
};

/** Rubric Input */
export type RubricInput = {
  /** Meaning while giving that score. */
  description?: InputMaybe<Scalars['String']>;
  /** Label orresponding to the score */
  label: SkillRubricLabelEnum;
  /** value/score for that skill. */
  score: Scalars['Float'];
};

/** Rubric Stub Generated By AI */
export type RubricStubType = {
  __typename?: 'RubricStubType';
  /** Meaning while giving that score. */
  description?: Maybe<Scalars['String']>;
  /** Label corresponding to a score. */
  label: SkillRubricLabelEnum;
  /** value/score for that skill. */
  score: Scalars['Float'];
};

export type SamlDataType = {
  __typename?: 'SAMLDataType';
  IDPEntityId: Scalars['String'];
  SPEntityId: Scalars['String'];
  SPInitiated: Scalars['Boolean'];
  idpCertificate: Scalars['String'];
  ifId?: Maybe<Scalars['String']>;
  onFailure: OnFailureActions;
  onSuccess: OnSuccessActions;
  samlAttributesToTagMapping?: Maybe<Scalars['String']>;
  samlSSOUrl?: Maybe<Scalars['String']>;
  samlType?: Maybe<SamlTypeEnum>;
  syncAttributes: SyncAttributesType;
};

export type SamlDataTypeEntity = {
  __typename?: 'SAMLDataTypeEntity';
  IDPEntityId: Scalars['String'];
  SPEntityId: Scalars['String'];
  SPInitiated: Scalars['Boolean'];
  idpCertificate: Scalars['String'];
  ifId?: Maybe<Scalars['String']>;
  onFailure: OnFailureActionsType;
  onSuccess: OnSuccessActionsType;
  samlAttributesToTagMapping?: Maybe<Scalars['String']>;
  samlSSOUrl?: Maybe<Scalars['String']>;
  samlType?: Maybe<SamlTypeEnum>;
  syncAttributes: SyncAttributesTypeEntity;
};

export enum SamlTypeEnum {
  HttpPost = 'HTTP_POST',
  HttpRedirect = 'HTTP_REDIRECT',
}

/** SEOSettings Input */
export type SeoSettings = {
  /** Represents SEO productDescription */
  productDescription: Scalars['String'];
  /** Represents SEO productTitle */
  productTitle: Scalars['String'];
  /** Represents slug in SEO  */
  slug: Scalars['String'];
};

/** SEOSettings Type */
export type SeoSettingsType = {
  __typename?: 'SEOSettingsType';
  /** Represents SEO productDescription */
  productDescription: Scalars['String'];
  /** Represents SEO productTitle */
  productTitle: Scalars['String'];
  /** Represents slug in SEO  */
  slug: Scalars['String'];
};

export type SaveWorkspaceAgendaItemsInput = {
  agendaItems: Array<UpsertAgendaItemInput>;
};

/** OKR Cycle schedule config for various reminders  */
export type ScheduleConfig = {
  __typename?: 'ScheduleConfig';
  checkinReminderSchedule?: Maybe<ScheduleInfo>;
};

export type ScheduleConfigInput = {
  checkinReminderSchedule?: InputMaybe<ScheduleInfoInput>;
};

export type ScheduleDateAssignmentConfig = {
  __typename?: 'ScheduleDateAssignmentConfig';
  operator?: Maybe<RelativeDateFilterOperatorEnum>;
  scheduleDate?: Maybe<Scalars['String']>;
  scheduleDateType: AssignmentScheduleDateEnum;
  unit?: Maybe<RelativeDateFilterUnitEnum>;
  value?: Maybe<Scalars['Float']>;
};

export type ScheduleDateAssignmentConfigInput = {
  operator?: InputMaybe<RelativeDateFilterOperatorEnum>;
  scheduleDate?: InputMaybe<Scalars['String']>;
  scheduleDateType: AssignmentScheduleDateEnum;
  unit?: InputMaybe<RelativeDateFilterUnitEnum>;
  value?: InputMaybe<Scalars['Float']>;
};

/** Grpc Job to be scheduled */
export type ScheduleGrpcJobInput = {
  /** Interval in minutes */
  interval?: InputMaybe<Scalars['Float']>;
  /** Type of job http/grpc */
  invocationType: InvocationTypeEnum;
  /** Service Name */
  recipientService: Scalars['String'];
  /** Job is repeating or not */
  recurring?: InputMaybe<Scalars['Boolean']>;
  recurringScheduledJobId: Scalars['String'];
  roleIdToAssume: Scalars['String'];
  /** Input for RPC in json format */
  rpcInput?: InputMaybe<Scalars['String']>;
  /** Method to be called in the service */
  rpcMethod: Scalars['String'];
  /** DateTime when the job needs to be executed */
  scheduledAt: Scalars['String'];
  userIdToMasquerade?: InputMaybe<Scalars['String']>;
};

/** Http Job to be scheduled */
export type ScheduleHttpJobInput = {
  /** HTTP URL to call */
  callbackUrl?: InputMaybe<Scalars['String']>;
  /** Interval in minutes */
  interval?: InputMaybe<Scalars['Float']>;
  /** Type of job http/grpc */
  invocationType: InvocationTypeEnum;
  /** HTTP Query Parameters */
  queryParams?: InputMaybe<Scalars['String']>;
  /** Job is repeating or not */
  recurring?: InputMaybe<Scalars['Boolean']>;
  recurringScheduledJobId: Scalars['String'];
  /** HTTP request body */
  requestBody?: InputMaybe<Scalars['String']>;
  /** HTTP request headers */
  requestHeaders?: InputMaybe<Scalars['String']>;
  /** HTTP request method */
  requestType: HttpRequestTypeEnum;
  roleIdToAssume: Scalars['String'];
  /** DateTime when the job needs to be executed */
  scheduledAt: Scalars['String'];
  userIdToMasquerade?: InputMaybe<Scalars['String']>;
};

export type ScheduleInfo = {
  __typename?: 'ScheduleInfo';
  MonthOfYear?: Maybe<MonthOfYearEnum>;
  custom?: Maybe<CustomFrequency>;
  dayOfMonth?: Maybe<Scalars['Float']>;
  dayOfWeek?: Maybe<DayOfWeekEnum>;
  frequency: CronFrequencyEnum;
  startDate?: Maybe<Scalars['String']>;
  time: Scalars['String'];
  timezone: Scalars['String'];
  weekOfMonth?: Maybe<WeekOfMonthEnum>;
};

export type ScheduleInfoInput = {
  MonthOfYear?: InputMaybe<MonthOfYearEnum>;
  custom?: InputMaybe<CustomFrequencyInput>;
  dayOfMonth?: InputMaybe<Scalars['Float']>;
  dayOfWeek?: InputMaybe<DayOfWeekEnum>;
  frequency: CronFrequencyEnum;
  startDate?: InputMaybe<Scalars['String']>;
  time: Scalars['String'];
  timezone: Scalars['String'];
  weekOfMonth?: InputMaybe<WeekOfMonthEnum>;
};

/** Job to be scheduled */
export type ScheduleJobInput = {
  /** Interval in minutes */
  interval?: InputMaybe<Scalars['Float']>;
  /** Type of job http/grpc */
  invocationType: InvocationTypeEnum;
  /** Job is repeating or not */
  recurring?: InputMaybe<Scalars['Boolean']>;
  recurringScheduledJobId: Scalars['String'];
  roleIdToAssume: Scalars['String'];
  /** DateTime when the job needs to be executed */
  scheduledAt: Scalars['String'];
  userIdToMasquerade?: InputMaybe<Scalars['String']>;
};

/** Schedule Job Payload */
export type ScheduleJobPayload = {
  __typename?: 'ScheduleJobPayload';
  /** Scheduled Job ID */
  scheduledJobId: Scalars['String'];
};

/** GRPC Job Schema */
export type ScheduledGrpcJobType = {
  __typename?: 'ScheduledGrpcJobType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** Interval in minutes */
  interval?: Maybe<Scalars['Float']>;
  /** Type of job grpc/http */
  invocationType: InvocationTypeEnum;
  recipientService: Scalars['String'];
  /** Job is repeating or not */
  recurring: Scalars['Boolean'];
  recurringScheduledJobId: Scalars['String'];
  roleIdToAssume: Scalars['String'];
  rpcInput: Scalars['String'];
  rpcMethod: Scalars['String'];
  /** DateTime of scheduled Job */
  scheduledAt: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Execution Status Of Job */
  status: ScheduledJobStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userIdToMasquerade: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId: Scalars['String'];
};

/** HTTP Job Schema */
export type ScheduledHttpJobType = {
  __typename?: 'ScheduledHttpJobType';
  callbackUrl: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** Interval in minutes */
  interval?: Maybe<Scalars['Float']>;
  /** Type of job grpc/http */
  invocationType: InvocationTypeEnum;
  queryParams: Scalars['String'];
  /** Job is repeating or not */
  recurring: Scalars['Boolean'];
  recurringScheduledJobId: Scalars['String'];
  requestBody: Scalars['String'];
  requestHeaders: Scalars['String'];
  requestType: HttpRequestTypeEnum;
  roleIdToAssume: Scalars['String'];
  /** DateTime of scheduled Job */
  scheduledAt: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Execution Status Of Job */
  status: ScheduledJobStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userIdToMasquerade: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId: Scalars['String'];
};

export type ScheduledJobAssignmentConfig = {
  __typename?: 'ScheduledJobAssignmentConfig';
  deactivateAutoSyncUser?: Maybe<Scalars['String']>;
  publishAssignment?: Maybe<Scalars['String']>;
};

/** Scheduled Job Schema */
export type ScheduledJobConfigs = {
  __typename?: 'ScheduledJobConfigs';
  jobId: Scalars['String'];
  purpose: Scalars['String'];
  type: Scalars['String'];
};

/** This returns paginated scheduledJob */
export type ScheduledJobConnections = {
  __typename?: 'ScheduledJobConnections';
  edges?: Maybe<Array<ScheduledJobEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ScheduledJobEdge = {
  __typename?: 'ScheduledJobEdge';
  node: ScheduledJobType;
};

export enum ScheduledJobStatusEnum {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED',
  Success = 'SUCCESS',
}

/** Scheduled Job Schema */
export type ScheduledJobType = {
  __typename?: 'ScheduledJobType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** Interval in minutes */
  interval?: Maybe<Scalars['Float']>;
  /** Type of job grpc/http */
  invocationType: InvocationTypeEnum;
  /** Job is repeating or not */
  recurring: Scalars['Boolean'];
  recurringScheduledJobId: Scalars['String'];
  roleIdToAssume: Scalars['String'];
  /** DateTime of scheduled Job */
  scheduledAt: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Execution Status Of Job */
  status: ScheduledJobStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userIdToMasquerade: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId: Scalars['String'];
};

export type ScheduledJobsFilters = {
  filterQuery?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  recurringScheduledJobId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ScheduledJobStatusEnum>;
};

/** ScoreDetailsType Schema */
export type ScoreDetailsType = {
  __typename?: 'ScoreDetailsType';
  /** user who submitted the assignment */
  reviewCycleId: Scalars['String'];
  score: Scalars['Float'];
  type: Scalars['String'];
};

/** This returns paginated score cards */
export type ScorecardConnections = {
  __typename?: 'ScorecardConnections';
  edges?: Maybe<Array<ScorecardEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ScorecardEdge = {
  __typename?: 'ScorecardEdge';
  node: ScorecardType;
};

export type ScorecardQuestionInput = {
  maxScore: Scalars['Float'];
  order: Scalars['Float'];
  text: Scalars['String'];
};

/** Question object for Scorecard */
export type ScorecardQuestionType = {
  __typename?: 'ScorecardQuestionType';
  id: Scalars['String'];
  /** maximum score */
  maxScore: Scalars['Float'];
  /** order of the scorecard question */
  order: Scalars['Float'];
  text: Scalars['String'];
};

/** Edge */
export type ScorecardReviewEdge = {
  __typename?: 'ScorecardReviewEdge';
  node: ScorecardReviewType;
};

/** ScorecardReview Schema */
export type ScorecardReviewType = {
  __typename?: 'ScorecardReviewType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entityId: Scalars['String'];
  entityType: ResourceTypeEnum;
  id: Scalars['String'];
  /** reviewer response */
  responses?: Maybe<Array<ReviewerResponseType>>;
  reviewType: Scalars['String'];
  /** reviewer for the scorecardReview */
  reviewer?: Maybe<UserType>;
  /** reviewer for the assignment */
  reviewerId: Scalars['String'];
  /** overall score of scorecard */
  score?: Maybe<Scalars['Float']>;
  /** scorecard for the scorecardReview */
  scorecard?: Maybe<ScorecardType>;
  /** scorecard */
  scorecardId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  submissionId: Scalars['String'];
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user for the scorecardReview */
  user?: Maybe<UserType>;
  /** user who submitted the assignment */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** This returns paginated resources */
export type ScorecardReviews = {
  __typename?: 'ScorecardReviews';
  edges?: Maybe<Array<ScorecardReviewEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Scorecard Type */
export type ScorecardType = {
  __typename?: 'ScorecardType';
  averageScore?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** Questions for the score card */
  questions?: Maybe<Array<ScorecardQuestionType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skill?: Maybe<SkillType>;
  /** denotes scorecard belongs to which skill */
  skillId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type ScorecardsFilters = {
  /** Filter score cards based on resource ids */
  ids?: InputMaybe<Array<Scalars['String']>>;
  /** Filter scorecards by skills */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter scorecards by title */
  title?: InputMaybe<Scalars['String']>;
};

export type ScormContentType = {
  __typename?: 'ScormContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']>;
  /** Description of the external content */
  description?: Maybe<Scalars['String']>;
  draft?: Maybe<DraftScormContentType>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** Duration in seconds */
  duration: Scalars['Float'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  /** Objective of the scorm content */
  objective?: Maybe<Scalars['String']>;
  performance?: Maybe<ScormPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  scormProperties?: Maybe<ScormProperties>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']>>;
  /** scorm skills */
  skills?: Maybe<Array<SkillType>>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings?: Maybe<VisibilitySettingsType>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

export type ScormContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

export type ScormContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

export type ScormContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

export type ScormContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type ScormContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export type ScormContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export type ScormImportMetadataType = {
  __typename?: 'ScormImportMetadataType';
  masteryScore?: Maybe<Scalars['Float']>;
  resourceHref?: Maybe<Scalars['String']>;
  schemaVersion?: Maybe<Scalars['String']>;
};

export type ScormPackageInfo = {
  __typename?: 'ScormPackageInfo';
  byteSize: Scalars['Float'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type ScormPackageInfoInput = {
  byteSize: Scalars['Float'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type ScormPayload = {
  __typename?: 'ScormPayload';
  scormContent: ScormContentType;
};

/** Course Performance Type */
export type ScormPerformanceType = {
  __typename?: 'ScormPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  archivedAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** stores the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  meta?: Maybe<Scalars['String']>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float'];
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type ScormProperties = {
  __typename?: 'ScormProperties';
  /** mastery score */
  masteryScore?: Maybe<Scalars['Float']>;
  packageInfo?: Maybe<ScormPackageInfo>;
  /** href to scorm resource */
  resourceHref: Scalars['String'];
  /** scorm schema version */
  schemaVersion: Scalars['String'];
};

export type ScormPropertiesInput = {
  masteryScore?: InputMaybe<Scalars['Float']>;
  packageInfo?: InputMaybe<ScormPackageInfoInput>;
  resourceHref: Scalars['String'];
  schemaVersion: Scalars['String'];
};

export type SearchClassroomUsersFilters = {
  /** Classroom id filter for searching classroom user */
  classroomId: Scalars['String'];
  /** Search text for searching classroom user */
  searchText: Scalars['String'];
};

export type SearchFacetType = {
  __typename?: 'SearchFacetType';
  applyOn: Array<FacetApplyOnType>;
  defaultValues?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  /** presentational properties for the facet defined as a stringified JSON */
  props?: Maybe<Scalars['String']>;
  type: FacetTypeEnum;
};

export enum SearchWidgetTypeEnum {
  BookmarkContents = 'BOOKMARK_CONTENTS',
  Contents = 'CONTENTS',
  Events = 'EVENTS',
  Lessons = 'LESSONS',
}

export enum SearchableContentTypeEnum {
  Article = 'ARTICLE',
  Block = 'BLOCK',
  Checkin = 'CHECKIN',
  Course = 'COURSE',
  Event = 'EVENT',
  Exercise = 'EXERCISE',
  External = 'EXTERNAL',
  ExternalContent = 'EXTERNAL_CONTENT',
  ExternalEventContent = 'EXTERNAL_EVENT_CONTENT',
  Homework = 'HOMEWORK',
  Live = 'LIVE',
  LiveEventContent = 'LIVE_EVENT_CONTENT',
  Offline = 'OFFLINE',
  OfflineEventContent = 'OFFLINE_EVENT_CONTENT',
  Path = 'PATH',
  Quiz = 'QUIZ',
  Resource = 'RESOURCE',
  Scorm = 'SCORM',
  Survey = 'SURVEY',
  Task = 'TASK',
}

export type SectionInfoInput = {
  /** spaces belonging to section */
  channels?: InputMaybe<Array<CommunityChannelInfoInput>>;
  /** name of section */
  name: Scalars['String'];
  /** id of section block */
  sectionBlockId?: InputMaybe<Scalars['String']>;
};

export type SelectFacetOption = {
  __typename?: 'SelectFacetOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

/** Select field options */
export type SelectFieldOptionsType = {
  __typename?: 'SelectFieldOptionsType';
  /** Color for select option */
  color: Scalars['String'];
  /** Option Id */
  optionId: Scalars['String'];
  /** Value for select option */
  value: Scalars['String'];
};

export type SelectTagOptionInput = {
  externalData?: InputMaybe<Scalars['String']>;
  /** if value is provided, then it will be used to create new option, for empty Id, it will be autoGenerated */
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type SelectTagOptionType = {
  __typename?: 'SelectTagOptionType';
  /** key:value pairs for external data, For ex : status=12:Project=SPR */
  externalData?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SendAcademyTemplateInput = {
  academyTemplateId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type SendCheckinReminderInput = {
  /** Id of cycle for which reminders has to be sent */
  okrCycleId: Scalars['String'];
  /** Id of workspace of which cycle is part */
  workspaceId: Scalars['String'];
};

export type SendMailInput = {
  subject: Scalars['String'];
  template: Scalars['String'];
  userId: Scalars['String'];
};

export type SendOkrCycleCompletionReminderInput = {
  /** Id of cycle for which reminders has to be sent */
  okrCycleId: Scalars['String'];
  /** Id of workspace of which cycle is part */
  workspaceId: Scalars['String'];
};

export type SetAuditMetadataType = {
  __typename?: 'SetAuditMetadataType';
  action: AuditActionEnum;
  auditId: Scalars['String'];
  collectionName: EntityCollectionEnum;
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  /** Content that was bookmarked by the user */
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<InstructionType>;
  /** Display string for the actual value, a string to be shown on UI */
  displayField?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  /** Actual field that is being tracked/referenced in this audit metadata of the entity */
  fieldName: Scalars['String'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  newValue: Scalars['String'];
  relatedCollectionName?: Maybe<EntityCollectionEnum>;
  valueType: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type SetEntityAccessInput = {
  entityId: Scalars['String'];
  entityType: PermissionEntities;
  permissions: Array<PermissionInput>;
};

export type SetEventWorkConfigInput = {
  set: EventWorkConfigInput;
};

export type SetPageComponentsInput = {
  set: Array<PageComponentInput>;
};

/** Input to update product status */
export type SetProductStatusInput = {
  /** Id of product whose status needs to be changed */
  id: Scalars['String'];
  /** Status of the product. Product status would change to the provided status */
  status: ProductStatusEnum;
};

/** Update Product Status Response */
export type SetProductStatusPayload = {
  __typename?: 'SetProductStatusPayload';
  errorCode?: Maybe<ProductErrorCodeEnum>;
  product?: Maybe<ProductType>;
  success: Scalars['Boolean'];
};

export type SetSamlDataInput = {
  IDPEntityId: Scalars['String'];
  SPInitiated?: InputMaybe<Scalars['Boolean']>;
  idpCertificate: Scalars['String'];
  ifId?: InputMaybe<Scalars['String']>;
  onFailure?: InputMaybe<OnFailureActionsInput>;
  onSuccess?: InputMaybe<OnSuccessActionsInput>;
  samlAttributesToTagMapping?: InputMaybe<Scalars['String']>;
  samlSSOUrl?: InputMaybe<Scalars['String']>;
  samlType?: InputMaybe<SamlTypeEnum>;
  syncAttributes: SyncAttributesInput;
};

export type SetUserPreferencesInput = {
  showMarketplaceContentCatalogBanner?: InputMaybe<Scalars['Boolean']>;
};

export type SetWorkspaceAdministrationConfigInput = {
  clientOwnerUserId?: InputMaybe<Scalars['String']>;
  excludeLearnerEmailPatterns?: InputMaybe<Array<Scalars['String']>>;
  licenseLimits?: InputMaybe<Array<WorkspaceLicenseLimitInput>>;
  licenseTagId?: InputMaybe<Scalars['String']>;
  maxLearnersCount?: InputMaybe<Scalars['Float']>;
  notificationRules?: InputMaybe<WorkspaceAdministrationRulesInput>;
  renewalDate?: InputMaybe<Scalars['String']>;
  renewalStatus?: InputMaybe<WorkspaceRenewalStatusEnum>;
  renewalType?: InputMaybe<WorkspaceRenewalTypeEnum>;
  serviceContactUserId?: InputMaybe<Scalars['String']>;
};

export type SetWorkspaceStrategyMethodInput = {
  allowUsername?: InputMaybe<Scalars['Boolean']>;
  authCodeValidatorUrl?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  icon?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  samlConfigs?: InputMaybe<Array<SetSamlDataInput>>;
  scope?: InputMaybe<Scalars['String']>;
  signInLabel?: InputMaybe<Scalars['String']>;
  signUpLabel?: InputMaybe<Scalars['String']>;
  type: SupportedStrategyMethodTypeEnum;
  webStrategyUrl?: InputMaybe<Scalars['String']>;
};

export type SetupAcademyInput = {
  departmentTemplateIds?: InputMaybe<Array<Scalars['String']>>;
  /** Details of users/staff to be invited */
  toInviteUsers: Array<InviteUserInput>;
};

export type ShareConfig = {
  __typename?: 'ShareConfig';
  allowDuplication?: Maybe<Scalars['Boolean']>;
  sharedAt: Scalars['String'];
  sharedById: Scalars['String'];
  /** content id in source workspace */
  sourceWorkspaceEntityId?: Maybe<Scalars['String']>;
  status: ShareConfigStatusEnum;
  workspaceId: Scalars['String'];
};

export enum ShareConfigStatusEnum {
  Received = 'RECEIVED',
  Revoked = 'REVOKED',
  Sent = 'SENT',
}

export type ShareContentsInput = {
  contentIds: Array<Scalars['String']>;
  sharedToWorkspaceIds: Array<Scalars['String']>;
};

export type ShareItemInput = {
  itemId: Scalars['String'];
  type: SharedItemTypeEnum;
};

export type ShareItemsInput = {
  allowDuplication?: InputMaybe<Scalars['Boolean']>;
  items: Array<ShareItemInput>;
  sharedToWorkspaceIds: Array<Scalars['String']>;
};

export type ShareTagsInput = {
  tagIds: Array<Scalars['String']>;
  targetWorkspaceIds: Array<Scalars['String']>;
};

export type ShareUserGroupsInput = {
  groupIds: Array<Scalars['String']>;
  sharedToWorkspaceIds: Array<Scalars['String']>;
};

export type SharedConnectionFilters = {
  connectionId: Scalars['String'];
};

export type SharedConnectionInfo = {
  __typename?: 'SharedConnectionInfo';
  connectedAt?: Maybe<Scalars['String']>;
  connectedBy?: Maybe<UserType>;
  connectedById?: Maybe<Scalars['String']>;
  connectionId: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  receivedItemsCount?: Maybe<Scalars['Float']>;
  requestedAt: Scalars['String'];
  sentItemsCount?: Maybe<Scalars['Float']>;
  sharedItemsCount?: Maybe<Scalars['Float']>;
  status: TenantConnectionStatusEnum;
  workspaceId: Scalars['String'];
  workspaceLogo: Scalars['String'];
  workspaceName: Scalars['String'];
  workspaceURI: Scalars['String'];
};

/** Shared Connection Info Edge */
export type SharedConnectionInfoEdge = {
  __typename?: 'SharedConnectionInfoEdge';
  node: SharedConnectionInfo;
};

export type SharedConnectionsFilters = {
  searchText?: InputMaybe<Scalars['String']>;
};

export type SharedConnectionsInfoPayload = {
  __typename?: 'SharedConnectionsInfoPayload';
  active?: Maybe<PaginatedSharedConnectionInfo>;
  pending?: Maybe<PaginatedSharedConnectionInfo>;
  sent?: Maybe<PaginatedSharedConnectionInfo>;
};

export type SharedConnectionsInfoPayloadActiveArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type SharedConnectionsInfoPayloadPendingArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type SharedConnectionsInfoPayloadSentArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<Array<SortType>>;
};

export type SharedEntityFilters = {
  sharedAt?: InputMaybe<DateFilter>;
  status?: InputMaybe<Array<ShareConfigStatusEnum>>;
  workspaceId?: InputMaybe<Scalars['String']>;
  workspaceIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum SharedItemTypeEnum {
  Collection = 'COLLECTION',
  Content = 'CONTENT',
  Session = 'SESSION',
}

/** Defines group and associated role on given entity */
export type SharedSpaceGroupRoleType = {
  __typename?: 'SharedSpaceGroupRoleType';
  group?: Maybe<UserCollectionGroupType>;
  groupId: Scalars['String'];
  role?: Maybe<RoleType>;
  /** role Id of group on entity */
  roleId?: Maybe<Scalars['String']>;
};

/** Defines user and associated role on given entity */
export type SharedSpaceUserRoleType = {
  __typename?: 'SharedSpaceUserRoleType';
  role?: Maybe<RoleType>;
  /** role Id of user on entity */
  roleId: Scalars['String'];
  user?: Maybe<UserType>;
  userId: Scalars['String'];
};

export type SignedMediaUrlPayload = {
  __typename?: 'SignedMediaUrlPayload';
  signedMediaUrl?: Maybe<Scalars['String']>;
};

export type SingleSelectFacetType = {
  __typename?: 'SingleSelectFacetType';
  applyOn: FacetApplyOnType;
  defaultValues?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isOptionLocalisationNeeded: Scalars['Boolean'];
  label: Scalars['String'];
  options: Array<SelectFacetOption>;
  /** presentational properties for the facet defined as a stringified JSON */
  props?: Maybe<Scalars['String']>;
  type: FacetTypeEnum;
};

export type SingleSelectTagPropsInput = {
  ignoreOptions?: InputMaybe<Scalars['Boolean']>;
  options: Array<SelectTagOptionInput>;
};

export type SingleSelectTagType = {
  __typename?: 'SingleSelectTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** If tag can have dynamic option values */
  ignoreOptions?: Maybe<Scalars['Boolean']>;
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** number type tag specific fields */
  options: Array<SelectTagOptionType>;
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  /** field resolvers */
  taggedContentCount: Scalars['Float'];
  taggedEventCount: Scalars['Float'];
  taggedOKRCount: Scalars['Float'];
  taggedTaskCount: Scalars['Float'];
  taggedUserCount: Scalars['Float'];
  type: TagTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type SingleSelectTagTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type SingleSelectTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Site Setting Parameters */
export type SiteSettingsType = {
  __typename?: 'SiteSettingsType';
  /** Admin user name for that workspace */
  admin?: Maybe<Scalars['String']>;
  /** Application type for the workspace */
  app?: Maybe<Scalars['String']>;
  /** default favicon URL for the site */
  favicon: Scalars['String'];
  /** default logo URL for the site */
  logo: Array<DeprecatedMediaType>;
};

export enum SkillActionsEnum {
  Create = 'CREATE',
  Edit = 'EDIT',
  Read = 'READ',
  Remove = 'REMOVE',
}

/** Category of Skill */
export type SkillCategoryType = {
  __typename?: 'SkillCategoryType';
  /** Skill Category Icon */
  icon: Scalars['String'];
  /** Skill Category Name */
  name: Scalars['String'];
};

/** This returns paginated Skill */
export type SkillConnections = {
  __typename?: 'SkillConnections';
  edges?: Maybe<Array<SkillEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type SkillEdge = {
  __typename?: 'SkillEdge';
  node: SkillType;
};

export type SkillEntitiesAttachedToCount = {
  __typename?: 'SkillEntitiesAttachedToCount';
  contentsCount: Scalars['Float'];
  skillId: Scalars['String'];
  skillMatricesCount: Scalars['Float'];
};

export type SkillFilters = {
  generatedBy?: InputMaybe<GeneratedByEnum>;
  id?: InputMaybe<Scalars['String']>;
  /** Name of the Skill */
  name?: InputMaybe<Scalars['String']>;
};

export type SkillGroupInput = {
  _id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  skillIds: Array<Scalars['String']>;
};

/** SkillReview Schema */
export type SkillGroupReviewType = {
  __typename?: 'SkillGroupReviewType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** review cycle instance */
  reviewCycleId: Scalars['String'];
  /** reviewerType */
  reviewType: Scalars['String'];
  /** user who reviewed the assignment */
  reviewerId: Scalars['String'];
  /** avg score of given skill groups */
  score: Scalars['Float'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillGroupId: Scalars['String'];
  skillMatrixId: Scalars['String'];
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user who is getting marks */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Skill Group Schema */
export type SkillGroupType = {
  __typename?: 'SkillGroupType';
  _id: Scalars['String'];
  name: Scalars['String'];
  skillIds: Array<Scalars['String']>;
  skills?: Maybe<Array<SkillType>>;
};

export type SkillGroupsInput = {
  set: Array<SkillGroupInput>;
};

export type SkillMasteryConfig = {
  __typename?: 'SkillMasteryConfig';
  expectedScore: Scalars['Float'];
  skillId: Scalars['String'];
};

export type SkillMasteryConfigInput = {
  expectedScore: Scalars['Float'];
  skillId: Scalars['String'];
};

export type SkillMatricesFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  generatedBy?: InputMaybe<GeneratedByEnum>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<PublicSkillMatrixStatusEnum>>;
};

export type SkillMatrixConfig = {
  __typename?: 'SkillMatrixConfig';
  skillMasteryConfigs: Array<SkillMasteryConfig>;
  skillMatrixId: Scalars['String'];
};

export type SkillMatrixConfigInput = {
  skillMasteryConfigs: Array<SkillMasteryConfigInput>;
  skillMatrixId: Scalars['String'];
};

export type SkillMatrixConnection = {
  __typename?: 'SkillMatrixConnection';
  edges?: Maybe<Array<SkillMatrixEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export type SkillMatrixEdge = {
  __typename?: 'SkillMatrixEdge';
  node: SkillMatrixType;
};

export enum SkillMatrixExportFieldEnum {
  Level1 = 'LEVEL1',
  Level2 = 'LEVEL2',
  Level3 = 'LEVEL3',
  Level4 = 'LEVEL4',
  Level5 = 'LEVEL5',
  SkillDescriptioin = 'SKILL_DESCRIPTIOIN',
  SkillGroupName = 'SKILL_GROUP_NAME',
  SkillName = 'SKILL_NAME',
}

export type SkillMatrixExportInputPropsInput = {
  filters: SkillMatrixFilters;
  requiredFields: SkillMatrixExportRequiredFieldsInput;
};

export type SkillMatrixExportInputPropsType = {
  __typename?: 'SkillMatrixExportInputPropsType';
  filters: SkillMatrixFiltersType;
  requiredFields: SkillMatrixExportRequiredFieldsType;
};

export type SkillMatrixExportMetadataType = {
  __typename?: 'SkillMatrixExportMetadataType';
  success: Scalars['Boolean'];
};

export type SkillMatrixExportRequiredFieldsInput = {
  fields: Array<SkillMatrixExportFieldEnum>;
};

export type SkillMatrixExportRequiredFieldsType = {
  __typename?: 'SkillMatrixExportRequiredFieldsType';
  fields: Array<SkillMatrixExportFieldEnum>;
};

export type SkillMatrixFilters = {
  id: Scalars['String'];
};

export type SkillMatrixFiltersType = {
  __typename?: 'SkillMatrixFiltersType';
  id: Scalars['String'];
};

export type SkillMatrixImportMetadataType = {
  __typename?: 'SkillMatrixImportMetadataType';
  createdSkillsCount: Scalars['Float'];
};

export type SkillMatrixPayload = {
  __typename?: 'SkillMatrixPayload';
  skillMatrix?: Maybe<SkillMatrixType>;
};

/** Skill Matrix Schema */
export type SkillMatrixType = {
  __typename?: 'SkillMatrixType';
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  iconSettings: IconSettings;
  id: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillGroups: Array<SkillGroupType>;
  skillGroupsCount: Scalars['Float'];
  skillsCount: Scalars['Float'];
  status: PublicSkillMatrixStatusEnum;
  systemGenerated: Scalars['Boolean'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Skill Payload */
export type SkillPayload = {
  __typename?: 'SkillPayload';
  skill?: Maybe<SkillType>;
};

export type SkillResultType = {
  __typename?: 'SkillResultType';
  records: Array<SkillStubType>;
};

/** Count of skill reviews submitted */
export type SkillReviewCountPayload = {
  __typename?: 'SkillReviewCountPayload';
  total: Scalars['Float'];
};

export enum SkillReviewStatusEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
}

/** SkillReview Schema */
export type SkillReviewType = {
  __typename?: 'SkillReviewType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  rationale?: Maybe<Scalars['String']>;
  /** review cycle instance */
  reviewCycleId: Scalars['String'];
  /** reviewerType */
  reviewType: Scalars['String'];
  /** user who reviewed the assignment */
  reviewerId: Scalars['String'];
  /** overall score on skill */
  score: Scalars['Float'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillGroupId?: Maybe<Scalars['String']>;
  skillId: Scalars['String'];
  skillMatrixId?: Maybe<Scalars['String']>;
  subType?: Maybe<SkillTypeEnum>;
  type: PerformanceInteractionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** user who is getting marks */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum SkillRubricLabelEnum {
  Advanced = 'Advanced',
  Competent = 'Competent',
  Expert = 'Expert',
  Intermediate = 'Intermediate',
  Novice = 'Novice',
}

export type SkillRubricsResultType = {
  __typename?: 'SkillRubricsResultType';
  records: Array<RubricStubType>;
};

/** SkillScore Schema */
export type SkillScore = {
  __typename?: 'SkillScore';
  /** average score obtained */
  average: Scalars['Float'];
  /** number of instances used to calculate the average */
  count: Scalars['Float'];
  /** percentage calculated out of 100 */
  percentage: Scalars['Float'];
  /** Source for the score */
  source: SkillScoreSourceEnum;
  /** total score achieved */
  total: Scalars['Float'];
};

export type SkillScoreInput = {
  /** average score obtained */
  average: Scalars['Float'];
  /** number of instances used to calculate the average */
  count: Scalars['Float'];
  /** percentage calculated out of 100 */
  percentage: Scalars['Float'];
  /** Source for the score */
  source: SkillScoreSourceEnum;
  /** total score that could be achieved */
  total: Scalars['Float'];
};

export enum SkillScoreSourceEnum {
  Aggregate = 'AGGREGATE',
  LearningContent = 'LEARNING_CONTENT',
  SkillReview = 'SKILL_REVIEW',
}

export type SkillScoresInput = {
  score: Scalars['Float'];
  skillId: Scalars['String'];
  skillType?: InputMaybe<SkillTypeEnum>;
};

export type SkillStubType = {
  __typename?: 'SkillStubType';
  description: Scalars['String'];
  name: Scalars['String'];
};

export enum SkillSubjectsEnum {
  Skill = 'SKILL',
}

/** Skill Schema */
export type SkillType = {
  __typename?: 'SkillType';
  attachedToEntitiesCount?: Maybe<GetEntitiesAttachedToCountPayload>;
  /** Denotes the Category of Skill */
  category?: Maybe<SkillCategoryType>;
  childSkills?: Maybe<Array<SkillType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Skill description */
  description?: Maybe<Scalars['String']>;
  iconSettings?: Maybe<IconSettings>;
  id: Scalars['String'];
  /** MaxScore Possible to achieve for this skill */
  maxScore?: Maybe<Scalars['Float']>;
  /** Skill Name or Title */
  name: Scalars['String'];
  /** denotes sub-Skill belongs to which Skill */
  parentIds?: Maybe<Array<Scalars['String']>>;
  /** Denotes the rules for scoring this skill */
  rubrics: Array<Rubric>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Denotes whether the skill is system generated or not */
  systemGenerated?: Maybe<Scalars['Boolean']>;
  type: SkillTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  /** True for all user generated skills and true for system generated skills that have been used. False for system generated skills that have not been used */
  visibleInListing?: Maybe<Scalars['Boolean']>;
};

export enum SkillTypeEnum {
  Ability = 'ABILITY',
  Behavior = 'BEHAVIOR',
}

export enum SkillsExportFieldEnum {
  Descriptioin = 'DESCRIPTIOIN',
  Level1 = 'LEVEL1',
  Level2 = 'LEVEL2',
  Level3 = 'LEVEL3',
  Level4 = 'LEVEL4',
  Level5 = 'LEVEL5',
  Name = 'NAME',
  SkillId = 'SKILL_ID',
}

export type SkillsExportInputPropsInput = {
  filters: SkillsFilters;
  requiredFields: SkillsExportRequiredFieldsInput;
};

export type SkillsExportInputPropsType = {
  __typename?: 'SkillsExportInputPropsType';
  filters: SkillsFiltersType;
  requiredFields: SkillsExportRequiredFieldsType;
};

export type SkillsExportMetadataType = {
  __typename?: 'SkillsExportMetadataType';
  exportedCount: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type SkillsExportRequiredFieldsInput = {
  fields: Array<SkillsExportFieldEnum>;
};

export type SkillsExportRequiredFieldsType = {
  __typename?: 'SkillsExportRequiredFieldsType';
  fields: Array<SkillsExportFieldEnum>;
};

export type SkillsFilters = {
  /** Skill Categories */
  categories?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  generatedBy?: InputMaybe<GeneratedByEnum>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  isParent?: InputMaybe<Scalars['Boolean']>;
  /** name of the Skill */
  name?: InputMaybe<Scalars['String']>;
  notInIds?: InputMaybe<Array<Scalars['String']>>;
  orgRoleIds?: InputMaybe<Array<Scalars['String']>>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  /** name of the Skill */
  searchText?: InputMaybe<Scalars['String']>;
  /** Returns skills present in the given skillMatrixIds */
  skillMatrixIds?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<SkillTypeEnum>>;
  visibleInListing?: InputMaybe<Scalars['Boolean']>;
};

export type SkillsFiltersType = {
  __typename?: 'SkillsFiltersType';
  /** Skill Categories */
  categories?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<DateFilterType>;
  createdByIds?: Maybe<Array<Scalars['String']>>;
  generatedBy?: Maybe<GeneratedByEnum>;
  ids?: Maybe<Array<Scalars['String']>>;
  isParent?: Maybe<Scalars['Boolean']>;
  /** name of the Skill */
  name?: Maybe<Scalars['String']>;
  orgRoleIds?: Maybe<Array<Scalars['String']>>;
  parentIds?: Maybe<Array<Scalars['String']>>;
  /** name of the Skill */
  searchText?: Maybe<Scalars['String']>;
  /** Returns skills present in the given skillMatrixIds */
  skillMatrixIds?: Maybe<Array<Scalars['String']>>;
  types?: Maybe<Array<SkillTypeEnum>>;
  /** True for all user generated skills. True for all system generated skills that have been imported. False for system generated skills that have not been imported */
  visibleInListing?: Maybe<Scalars['Boolean']>;
};

export type SkillsImportMetadataType = {
  __typename?: 'SkillsImportMetadataType';
  createdSkillsCount: Scalars['Float'];
};

export type SortFacetType = {
  __typename?: 'SortFacetType';
  defaultValues?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isOptionLocalisationNeeded: Scalars['Boolean'];
  model: FacetModelEnum;
  options: Array<SelectFacetOption>;
  /** presentational properties for the facet defined as a stringified JSON */
  props?: Maybe<Scalars['String']>;
  type: FacetTypeEnum;
};

/** Defines sort order of a particular field */
export type SortInput = {
  order: OrderEnum;
  property: Scalars['String'];
};

/** Defines array of sort operations */
export type SortSetInput = {
  set: Array<SortInput>;
};

export type SortType = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderEnum>;
};

export type SortTypeSchema = {
  __typename?: 'SortTypeSchema';
  /** Sort Field */
  field?: Maybe<Scalars['String']>;
  /** Sort Order */
  order?: Maybe<OrderEnum>;
};

export type SpecialConditions = {
  __typename?: 'SpecialConditions';
  firstWeekly?: Maybe<Scalars['Boolean']>;
};

export enum StartContentEntityTypeEnum {
  Question = 'QUESTION',
  Quiz = 'QUIZ',
}

/** Input to mark a content as started */
export type StartContentInput = {
  /** entityId of the entity that needs to be marked as started */
  entityId: Scalars['String'];
  /** entityType of the entity that needs to be marked as started */
  entityType: StartContentEntityTypeEnum;
  metadata?: InputMaybe<StartContentMetadataInput>;
};

export type StartContentMetadataInput = {
  /** required for embed contents */
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  /** parentEntityId is required for entityType: quiz, questions */
  parentEntityId?: InputMaybe<Scalars['String']>;
  /** quizId required for entityType: questions */
  quizId?: InputMaybe<Scalars['String']>;
};

/** This is the input for starting the quiz */
export type StartQuizInput = {
  /** Whether quiz being retaken is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String'];
  /** quizId to be started */
  quizId: Scalars['String'];
};

/** Quiz start response */
export type StartQuizPayload = {
  __typename?: 'StartQuizPayload';
  /** Error code for mutations */
  errorCode?: Maybe<PerformanceErrorCode>;
  questionPerformances?: Maybe<Array<QuestionPerformanceType>>;
  quizPerformance?: Maybe<QuizPerformanceType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** This is the input for starting the quiz question timer */
export type StartQuizQuestionTimerInput = {
  /** Whether quiz being retaken is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String'];
  /** QuestionId for which timer is being started */
  questionId: Scalars['String'];
  /** quizId the question is part of */
  quizId: Scalars['String'];
};

/** Quiz question start response */
export type StartQuizQuestionTimerPayload = {
  __typename?: 'StartQuizQuestionTimerPayload';
  /** Error code for mutations */
  errorCode?: Maybe<PerformanceErrorCode>;
  questionPerformance?: Maybe<QuestionPerformanceType>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum StatusApplicableOnEnum {
  Task = 'TASK',
}

export enum StatusCategoryEnum {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
}

export enum StatusConfigTypeEnum {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
}

/** Edge */
export type StatusEdge = {
  __typename?: 'StatusEdge';
  node: StatusType;
};

export type StatusFilters = {
  statusId: Scalars['String'];
};

export type StatusPayload = {
  __typename?: 'StatusPayload';
  status?: Maybe<StatusType>;
};

export type StatusType = {
  __typename?: 'StatusType';
  applicableOn: StatusApplicableOnEnum;
  category: StatusCategoryEnum;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** id of the default status from which current status is created */
  defaultStatusId?: Maybe<Scalars['String']>;
  /** Status on which EntityId to be applicable  */
  entityId?: Maybe<Scalars['String']>;
  /** Status on which EntityId to be applicable  */
  entityType?: Maybe<CustomStatusEntityTypeEnum>;
  icon?: Maybe<IconSettings>;
  id: Scalars['String'];
  name: Scalars['String'];
  /** Stores the order of status */
  order?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  systemGenerated: Scalars['Boolean'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type StatusesFilters = {
  applicableOn?: InputMaybe<Array<StatusApplicableOnEnum>>;
  categories?: InputMaybe<Array<StatusCategoryEnum>>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  /** exclude entity level custom statuses from result */
  excludeEntityStatuses?: InputMaybe<Scalars['Boolean']>;
  generatedBy?: InputMaybe<GeneratedByEnum>;
  /** exact name match */
  names?: InputMaybe<Array<Scalars['String']>>;
  statusIds?: InputMaybe<Array<Scalars['String']>>;
};

export type StatusesPayload = {
  __typename?: 'StatusesPayload';
  statuses?: Maybe<Array<StatusType>>;
};

export type StopContentsSharingInput = {
  contentIds: Array<Scalars['String']>;
  sharedToWorkspaceIds: Array<Scalars['String']>;
};

export type StopItemsSharingInput = {
  items: Array<ShareItemInput>;
  sharedToWorkspaceIds: Array<Scalars['String']>;
};

export type StoreFilters = {
  host?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

/** The Store Model */
export type StoreType = {
  __typename?: 'StoreType';
  configs?: Maybe<Scalars['JSONObject']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  hosts?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  integrations: Array<IntegrationType>;
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId: Scalars['String'];
};

export type StrategyMethod = {
  __typename?: 'StrategyMethod';
  allowUsername?: Maybe<Scalars['Boolean']>;
  authCodeValidatorUrl?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  jwtConfigs?: Maybe<Array<JwtDataType>>;
  meta?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  samlConfigs?: Maybe<Array<SamlDataType>>;
  scope?: Maybe<Scalars['String']>;
  signInLabel: Scalars['String'];
  signUpLabel?: Maybe<Scalars['String']>;
  type: SupportedStrategyMethodTypeEnum;
  webStrategyUrl: Scalars['String'];
};

export type StrategyMethodType = {
  __typename?: 'StrategyMethodType';
  allowUsername?: Maybe<Scalars['Boolean']>;
  authCodeValidatorUrl?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  jwtConfigs?: Maybe<Array<JwtDataTypeEntity>>;
  meta?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  samlConfigs?: Maybe<Array<SamlDataTypeEntity>>;
  scope?: Maybe<Scalars['String']>;
  signInLabel: Scalars['String'];
  signUpLabel?: Maybe<Scalars['String']>;
  type: SupportedStrategyMethodTypeEnum;
  webStrategyUrl: Scalars['String'];
};

export type StreamEventFilters = {
  eventId?: InputMaybe<Scalars['String']>;
};

/** Payment Create Checkout Session Mutation Payload For Stripe */
export type StripeCheckoutSessionMutationPayload = {
  __typename?: 'StripeCheckoutSessionMutationPayload';
  paymentProvider: TransactionPaymentProviderEnum;
  sessionId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  transactionIds?: Maybe<Array<Scalars['String']>>;
};

/** Defines view sub filters for a particular field */
export type SubFiltersInput = {
  operator: FilterOperatorEnum;
  property: Scalars['String'];
  value: Scalars['String'];
};

/** Defines filtering over entity */
export type SubFiltersType = {
  __typename?: 'SubFiltersType';
  /** Logical operation to be performed between filters */
  operator: FilterOperatorEnum;
  /** Field Id */
  property: Scalars['String'];
  /** Datatype of the "value" field */
  typeCast?: Maybe<TypeCastEnum>;
  /** Value to be filtered with */
  value: Scalars['String'];
};

export type SubTaskInput = {
  instruction?: InputMaybe<TaskInstructionInput>;
  name: Scalars['String'];
};

/** SubTask Performance Type */
export type SubTaskPerformanceType = {
  __typename?: 'SubTaskPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress?: Maybe<Scalars['Float']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

/** Sub-Task Type */
export type SubTaskType = {
  __typename?: 'SubTaskType';
  id: Scalars['String'];
  /** Stores the instruction of the sub task */
  instruction?: Maybe<TaskInstruction>;
  /** Name of the sub task */
  name: Scalars['String'];
  performance?: Maybe<SubTaskPerformanceType>;
  type: ContentTypeEnum;
};

export type SubmissionData = {
  __typename?: 'SubmissionData';
  exerciseId: Scalars['String'];
  isReviewComplete: Scalars['Boolean'];
  submissionId: Scalars['String'];
  user?: Maybe<UserType>;
  userId: Scalars['String'];
};

export type SubmissionFeaturedUserNotification = {
  __typename?: 'SubmissionFeaturedUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  parentEntityId: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  reviewerId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  submissionId: Scalars['String'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type SubmissionFeedbackAddedUserNotification = {
  __typename?: 'SubmissionFeedbackAddedUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  reviewerId: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  submissionId: Scalars['String'];
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** SubmissionFeedbackStickers Response */
export type SubmissionFeedbackStickers = {
  __typename?: 'SubmissionFeedbackStickers';
  /** Name of stickers */
  name: Scalars['String'];
  /** url of stickers */
  url: Scalars['String'];
};

/** Data regarding the reviewers */
export type SubmissionReviewInfo = {
  __typename?: 'SubmissionReviewInfo';
  defaultReviewerId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  isEmpty?: Maybe<Scalars['Boolean']>;
  reviewerIds?: Maybe<Array<Scalars['String']>>;
  tagId?: Maybe<Scalars['String']>;
  type: SubmissionReviewInfoTypeEnum;
};

export type SubmissionReviewInfoFilter = {
  defaultReviewerId?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
  reviewerIds?: InputMaybe<Array<Scalars['String']>>;
  tagId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SubmissionReviewInfoTypeEnum>;
};

export enum SubmissionReviewInfoTypeEnum {
  Group = 'GROUP',
  Manager = 'MANAGER',
  Specific = 'SPECIFIC',
  Tag = 'TAG',
}

/** Input regarding whom the review was done. */
export type SubmissionRevieweeInfo = {
  /** the person who was reviewed in the submission */
  revieweeId: Scalars['String'];
  /** Submission Id of the person who was reviewed */
  submissionId: Scalars['String'];
};

/** Submission settings of task! */
export type SubmissionSettingsType = {
  __typename?: 'SubmissionSettingsType';
  allowedResponseTypes: Array<ResourceResponseTypeEnum>;
};

/** User Response to the resource */
export type SubmissionType = {
  __typename?: 'SubmissionType';
  mediaResponses?: Maybe<Array<EntityMediaType>>;
  responseType: ResourceResponseTypeEnum;
  retakeCount?: Maybe<Scalars['Float']>;
  /** If response type is text, HTML string */
  textResponse?: Maybe<Scalars['String']>;
  /** If response type is whiteboard, Stringified JSON which will be managed by frontend */
  whiteboardResponse?: Maybe<Scalars['String']>;
};

export enum SubmissionTypeEnum {
  Media = 'MEDIA',
  Text = 'TEXT',
}

/** input to fetch which submissions are to be reviewed by the peer. */
export type SubmissionsToReviewInput = {
  entityId: Scalars['String'];
  parentEntityId?: InputMaybe<Scalars['String']>;
};

export type SubmissionsToReviewPayload = {
  __typename?: 'SubmissionsToReviewPayload';
  submissions: Array<SubmissionData>;
};

export type SubmissionsToReviewWidgetType = {
  __typename?: 'SubmissionsToReviewWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  /** submissionsToReview */
  submissionsToReview: ContentPerformanceConnection;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type SubmissionsToReviewWidgetTypeSubmissionsToReviewArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
};

/** This is the input for submitting the entire quiz */
export type SubmitQuizInput = {
  /** Whether quiz being submitted is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String'];
  /** quizId for which submission/revaluation taking place */
  quizId: Scalars['String'];
};

/** Quiz Submission Response */
export type SubmitQuizPayload = {
  __typename?: 'SubmitQuizPayload';
  /** Error code for mutations */
  errorCode?: Maybe<PerformanceErrorCode>;
  performance?: Maybe<QuizPerformanceType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** This is the input for saving or submitting a quiz question response */
export type SubmitQuizQuestionInput = {
  /** Pass true when need to submit response. Pass false when need to save response */
  actualSubmit: Scalars['Boolean'];
  /** Used when Response type is Fill in the blank */
  ftbResponse?: InputMaybe<Array<FtbResponseInput>>;
  /** Whether quiz question being submitted is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  /** User when Response type is match the following */
  mtfResponse?: InputMaybe<Array<MtfResponseInput>>;
  /** Used when Response type is SingleSelect, MultiSelect, TrueFalse */
  optionResponse?: InputMaybe<Array<OptionResponseInput>>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String'];
  /** Response QuestionId */
  questionId: Scalars['String'];
  /** QuizId of which response needs to be saved */
  quizId: Scalars['String'];
  responseType: QuestionTypeEnum;
  /** Time spent on question */
  timeSpent: Scalars['Float'];
};

/** Quiz Submission Response */
export type SubmitQuizQuestionPayload = {
  __typename?: 'SubmitQuizQuestionPayload';
  /** Error code for mutations */
  errorCode?: Maybe<PerformanceErrorCode>;
  performance?: Maybe<QuestionPerformanceType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** This is the input for submitting the entire quiz */
export type SubmitQuizWithQuestionsInput = {
  /** Whether quiz being submitted is embedded or not */
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  /** Parent EntityId of which the quiz is part of */
  parentEntityId: Scalars['String'];
  /** all question submissions */
  questionSubmissions: Array<PartialQuestionSubmission>;
  /** quizId for which submission/revaluation taking place */
  quizId: Scalars['String'];
};

/** Input to submit exercise submission review */
export type SubmitReviewsInput = {
  /** exercise for which submissions were reviewed */
  entityId: Scalars['String'];
  /** course for which submissions were reviewed */
  parentEntityId?: InputMaybe<Scalars['String']>;
  /** reviewee info for which person, which submissionId the review was done */
  submissionRevieweeInfos: Array<SubmissionRevieweeInfo>;
};

export type SubmitSkillReviewInput = {
  orgRoleId: Scalars['String'];
  reviewCycleId: Scalars['String'];
  reviewTypes: Array<Scalars['String']>;
  skillScores: Array<SkillScoresInput>;
  status?: InputMaybe<SkillReviewStatusEnum>;
  userId: Scalars['String'];
};

export type SubmitSkillReviewPayload = {
  __typename?: 'SubmitSkillReviewPayload';
  /** Error code */
  errorCode?: Maybe<PerformanceInteractionErrorCodeEnum>;
  skillReviews?: Maybe<Array<SkillReviewType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  actionItems: ActionItemSubscriptionPayload;
  agendaItems: AgendaItemSubscriptionPayload;
  assignments: AssignmentSubscriptionPayload;
  channelPosts: ChannelPostsSubscriptionPayload;
  conferences: ConferenceSubscriptionPayload;
  database: DatabaseDataSubscriptionType;
  databaseMetadata: DatabaseMetadataSubscriptionType;
  databaseRow: DatabaseRowSubscriptionType;
  databaseView: DatabaseViewSubscriptionType;
  events: EventSubscriptionPayload;
  featuredSubmissions: FeaturedPerformancesSubscriptionPayload;
  generativeTask: GenerativeTaskSubscriptionPayload;
  job: JobType;
  jobByCreator: JobByCreatorSubscriptionPayload;
  meetingInstances: MeetingInstanceSubscriptionPayload;
  meetings: MeetingSubscriptionPayload;
  newPost: PostType;
  syncPerformance: ContentPerformanceEntity;
  userBellNotification: UserBellNotificationsSubscriptionPayload;
  users: UserSubscriptionPayload;
};

export type SubscriptionActionItemsArgs = {
  data: ActionItemSubscriptionInput;
};

export type SubscriptionAgendaItemsArgs = {
  data: AgendaItemSubscriptionInput;
};

export type SubscriptionAssignmentsArgs = {
  data: AssignmentSubscriptionInput;
};

export type SubscriptionChannelPostsArgs = {
  data: ChannelPostsSubscriptionInput;
};

export type SubscriptionConferencesArgs = {
  data: ConferenceSubscriptionInput;
};

export type SubscriptionDatabaseArgs = {
  dbId: Scalars['String'];
};

export type SubscriptionDatabaseMetadataArgs = {
  dbId: Scalars['String'];
};

export type SubscriptionDatabaseRowArgs = {
  documentId: Scalars['String'];
};

export type SubscriptionDatabaseViewArgs = {
  viewId: Scalars['String'];
};

export type SubscriptionEventsArgs = {
  data: EventSubscriptionInput;
};

export type SubscriptionFeaturedSubmissionsArgs = {
  data: FeaturedPerformancesSubscriptionInput;
};

export type SubscriptionGenerativeTaskArgs = {
  data: GenerativeTaskSubscriptionInput;
};

export type SubscriptionJobArgs = {
  data: JobSubscriptionInput;
};

export type SubscriptionJobByCreatorArgs = {
  data: JobByCreatorSubscriptionInput;
};

export type SubscriptionMeetingInstancesArgs = {
  data: MeetingInstanceSubscriptionInput;
};

export type SubscriptionMeetingsArgs = {
  data: MeetingSubscriptionInput;
};

export type SubscriptionNewPostArgs = {
  channelId: Scalars['String'];
};

export type SubscriptionSyncPerformanceArgs = {
  data: UserEntityPerformanceSubscriptionInput;
};

export type SubscriptionUserBellNotificationArgs = {
  data: UserBellNotificationSubscriptionInput;
};

export type SubscriptionUsersArgs = {
  data: UserSubscriptionInput;
};

export type SummaryActionItemsInput = {
  __typename?: 'SummaryActionItemsInput';
  assignedToName?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title: Scalars['String'];
};

export type SummaryAgendaItemsInput = {
  __typename?: 'SummaryAgendaItemsInput';
  actionItems: Array<AgendaActionItemsInputPropsType>;
  instructionText?: Maybe<Scalars['String']>;
  okrs: Array<AgendaOkRsInputPropsType>;
  title: Scalars['String'];
};

export enum SupportedFlowsEnum {
  AcademySetup = 'ACADEMY_SETUP',
}

export enum SupportedStrategyMethodTypeEnum {
  Credential = 'CREDENTIAL',
  Oauth = 'OAUTH',
  Sso = 'SSO',
}

/** Survey Type */
export type SurveyContentType = {
  __typename?: 'SurveyContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  /** Cover offset */
  coverOffset?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  databaseMetadata?: Maybe<DatabaseMetadataType>;
  databaseViews?: Maybe<Array<DatabaseViewType>>;
  /** Database Metadata Id */
  dbMetadataId: Scalars['String'];
  /** Database View Id */
  dbViewIds: Array<DbViewId>;
  defaultGroupId?: Maybe<Scalars['String']>;
  /** Description of the survey */
  description?: Maybe<Scalars['String']>;
  draft?: Maybe<DraftSurveyType>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** Duration in seconds */
  duration: Scalars['Float'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  /** Parent Content Id */
  parentContentId?: Maybe<Scalars['String']>;
  /** Parent Content Type */
  parentContentType?: Maybe<ResourceTypeEnum>;
  /** Parent Survey Id */
  parentSurveyId: Scalars['String'];
  performance?: Maybe<SurveyPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Skill Ids */
  skillIds?: Maybe<Array<Scalars['String']>>;
  skills?: Maybe<Array<SkillType>>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  visibilitySettings: VisibilitySettingsType;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Survey Type */
export type SurveyContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Survey Type */
export type SurveyContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Survey Type */
export type SurveyContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Survey Type */
export type SurveyContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Survey Type */
export type SurveyContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Survey Type */
export type SurveyContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export type SurveyExportMetadataType = {
  __typename?: 'SurveyExportMetadataType';
  exportedCount: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type SurveyExportPropsInput = {
  surveyFilters: SurveyResponsesFilters;
};

/** Survey Performance Type */
export type SurveyPerformanceType = {
  __typename?: 'SurveyPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  databaseData?: Maybe<DatabaseDataType>;
  /** document Id containing responses */
  documentId?: Maybe<Scalars['String']>;
  /** stores regarding the enrolment information of the user */
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress: Scalars['Float'];
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

export type SurveyResponsesFilters = {
  dataFilters: DatabaseDatasFilters;
  projections?: InputMaybe<Array<Scalars['String']>>;
  surveyId: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type SyncAttributesInput = {
  name: Scalars['Boolean'];
};

export type SyncAttributesType = {
  __typename?: 'SyncAttributesType';
  name: Scalars['Boolean'];
};

export type SyncAttributesTypeEntity = {
  __typename?: 'SyncAttributesTypeEntity';
  name: Scalars['Boolean'];
};

/** sync Config to track any kind of sync tasks in entities  */
export type SyncConfig = {
  __typename?: 'SyncConfig';
  /** Last jobSchedule id which was triggered to sync */
  lastJobScheduleId?: Maybe<Scalars['String']>;
  /** timestamp for the latest sync */
  lastSyncedAt: Scalars['String'];
  /** user who triggered the sync */
  lastSyncedById: Scalars['String'];
  /** current status of sync */
  syncStatus: SyncStatusEnum;
};

export type SyncConfigInput = {
  /** Last jobSchedule id which was triggered to sync */
  lastJobScheduleId?: InputMaybe<Scalars['String']>;
  /** timestamp for the latest sync */
  lastSyncedAt: Scalars['String'];
  /** user who triggered the sync */
  lastSyncedById: Scalars['String'];
  /** current status of sync */
  syncStatus: SyncStatusEnum;
};

/** This is the input for resource properties */
export type SyncContentProgressTargetInput = {
  blockId?: InputMaybe<Scalars['String']>;
  blockType?: InputMaybe<Scalars['String']>;
  contentId: Scalars['String'];
  contentType: ResourceTypeEnum;
  lessonId?: InputMaybe<Scalars['String']>;
  lessonPageId?: InputMaybe<Scalars['String']>;
};

/** input to sync individual users external performance */
export type SyncExternalPerformanceInput = {
  /** Unique Id to identify resource  */
  entityId: Scalars['String'];
  /** userId for whom external performance needs to be synced */
  userId: Scalars['String'];
};

/** response */
export type SyncExternalPerformancePayload = {
  __typename?: 'SyncExternalPerformancePayload';
  success: Scalars['Boolean'];
};

/** This is the input for sync progress on content */
export type SyncProgressInput = {
  attributes?: InputMaybe<AttributesInput>;
  clientTimestamp: Scalars['String'];
  command: CommandEnum;
  /** id of the content viewed by the user */
  entityId?: InputMaybe<Scalars['String']>;
  /** parentEntityId from which the content viewed by the user */
  parentEntityId?: InputMaybe<Scalars['String']>;
  /** Additional resume information for contents like course */
  resume?: InputMaybe<Scalars['JSONObject']>;
  target?: InputMaybe<SyncContentProgressTargetInput>;
};

/** Make Exercise Submission Response */
export type SyncProgressPayload = {
  __typename?: 'SyncProgressPayload';
  errorCode?: Maybe<PerformanceErrorCode>;
  performances?: Maybe<Array<CoursePerformanceType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum SyncStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
}

export type SyncTasksInput = {
  /** entity for which we need to sync all the action items */
  entityId: Scalars['String'];
  /** entity type which we need to sync all the action items */
  entityType: TaskCreationEntityTypeEnum;
};

/** Sync Action item payload */
export type SyncTasksPayload = {
  __typename?: 'SyncTasksPayload';
  jobScheduleId: Scalars['String'];
};

export type SyncUsersInput = {
  integrationProviderId: Scalars['String'];
};

export type SyncUsersPayload = {
  __typename?: 'SyncUsersPayload';
  jobScheduleId: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
};

export enum SyncUsersToRolesActionEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Replace = 'REPLACE',
}

export type SyncUsersToRolesInput = {
  action: SyncUsersToRolesActionEnum;
  roleIds: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

export enum TagAvailableOnEnum {
  Content = 'CONTENT',
  Event = 'EVENT',
  Okr = 'OKR',
  Task = 'TASK',
  User = 'USER',
}

export enum TagCategoryEnum {
  Field = 'FIELD',
  Label = 'LABEL',
}

export type TagControlConfigInput = {
  controls: Array<TagControlTypeInput>;
};

/** This will contain the controlling entity info and rules for control tags */
export type TagControlConfigType = {
  __typename?: 'TagControlConfigType';
  controls: Array<TagControlType>;
};

export type TagControlEntityConfig = {
  __typename?: 'TagControlEntityConfig';
  type: TagControlSourcePropertyTypeEnum;
  value: Scalars['String'];
};

export type TagControlEntityConfigInput = {
  type: TagControlSourcePropertyTypeEnum;
  value: Scalars['String'];
};

export enum TagControlOperatorEnum {
  In = 'IN',
}

export type TagControlRuleConstraint = {
  __typename?: 'TagControlRuleConstraint';
  operator: TagControlOperatorEnum;
  /** values for filtering or filtered values */
  values: Array<Scalars['String']>;
};

export type TagControlRuleConstraintInput = {
  operator: TagControlOperatorEnum;
  values: Array<Scalars['String']>;
};

/** specific rule for a controlling tag */
export type TagControlRuleType = {
  __typename?: 'TagControlRuleType';
  /** contains the controlling or filtering condition values. */
  source: TagControlRuleConstraint;
  /** contains the values after the controlling tag is applied, subset of actual values. */
  target: TagControlRuleConstraint;
};

export type TagControlRuleTypeInput = {
  source: TagControlRuleConstraintInput;
  target: TagControlRuleConstraintInput;
};

export enum TagControlSourcePropertyTypeEnum {
  Tag = 'TAG',
}

export type TagControlType = {
  __typename?: 'TagControlType';
  /** This will contain the controlling entity info, type will be tag, user etc, and value will be the corresponding id */
  controllingEntity: TagControlEntityConfig;
  /** This will contain the rules for control tags */
  rules: Array<TagControlRuleType>;
};

export type TagControlTypeInput = {
  controllingEntity: TagControlEntityConfigInput;
  rules: Array<TagControlRuleTypeInput>;
};

export type TagDimesionKeyMapping = {
  __typename?: 'TagDimesionKeyMapping';
  availableOn: TagAvailableOnEnum;
  dimensionKey: Scalars['String'];
};

/** Edge */
export type TagEdge = {
  __typename?: 'TagEdge';
  node: TagUnion;
};

export enum TagErrorCodeEnum {
  DatePropsNotFound = 'DATE_PROPS_NOT_FOUND',
  InvalidOptionId = 'INVALID_OPTION_ID',
  MultiSelectPropsNotFound = 'MULTI_SELECT_PROPS_NOT_FOUND',
  NoOptionsFound = 'NO_OPTIONS_FOUND',
  NoOptionName = 'NO_OPTION_NAME',
  NoTagName = 'NO_TAG_NAME',
  NumberPropsNotFound = 'NUMBER_PROPS_NOT_FOUND',
  SingleSelectPropsNotFound = 'SINGLE_SELECT_PROPS_NOT_FOUND',
  TagNameExists = 'TAG_NAME_EXISTS',
  TextPropsNotFound = 'TEXT_PROPS_NOT_FOUND',
  UserPropsNotFound = 'USER_PROPS_NOT_FOUND',
}

export enum TagExportFieldEnum {
  AvailableOn = 'AVAILABLE_ON',
  Description = 'DESCRIPTION',
  IncludeEndDate = 'INCLUDE_END_DATE',
  IncludeEndTime = 'INCLUDE_END_TIME',
  Name = 'NAME',
  NumberType = 'NUMBER_TYPE',
  Options = 'OPTIONS',
  RequiredTag = 'REQUIRED_TAG',
  TagId = 'TAG_ID',
  TextType = 'TEXT_TYPE',
  Type = 'TYPE',
  VisibleToLearners = 'VISIBLE_TO_LEARNERS',
}

export type TagExportInputProps = {
  filters: TagsFilters;
  requiredFields: TagExportRequiredFieldsInput;
};

export type TagExportInputPropsType = {
  __typename?: 'TagExportInputPropsType';
  filters: TagsFiltersType;
  requiredFields: TagExportRequiredFieldsType;
};

export type TagExportMetadataType = {
  __typename?: 'TagExportMetadataType';
  exportedCount: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type TagExportRequiredFieldsInput = {
  fields: Array<TagExportFieldEnum>;
};

export type TagExportRequiredFieldsType = {
  __typename?: 'TagExportRequiredFieldsType';
  fields: Array<TagExportFieldEnum>;
};

export type TagFilters = {
  name?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
};

/** This returns paginated TagOptions */
export type TagOptionConnection = {
  __typename?: 'TagOptionConnection';
  edges?: Maybe<Array<TagOptionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type TagOptionEdge = {
  __typename?: 'TagOptionEdge';
  node: SelectTagOptionType;
};

export type TagOptionsFilters = {
  parentId?: InputMaybe<Scalars['String']>;
};

export type TagOptionsOutputOrderInput = {
  order: Array<Scalars['String']>;
  tagId: Scalars['String'];
};

export type TagPaginatedOptionsFilters = {
  parentId?: InputMaybe<Scalars['String']>;
  tagId: Scalars['String'];
  valueIds?: InputMaybe<Array<Scalars['String']>>;
  valueText?: InputMaybe<Scalars['String']>;
};

export type TagPayload = {
  __typename?: 'TagPayload';
  tag?: Maybe<TagUnion>;
};

export type TagSettingsInput = {
  availableInLibrary: Scalars['Boolean'];
  controlConfig?: InputMaybe<TagControlConfigInput>;
  disableAttaching?: InputMaybe<Scalars['Boolean']>;
  disableEditing?: InputMaybe<Scalars['Boolean']>;
  disableFiltering?: InputMaybe<Scalars['Boolean']>;
  disableGrouping?: InputMaybe<Scalars['Boolean']>;
  disableResetting?: InputMaybe<Scalars['Boolean']>;
  enableCrossWorkspaceSharing?: InputMaybe<Scalars['Boolean']>;
  iconSettings: IconSettingsInput;
  requiredTag: Scalars['Boolean'];
};

export type TagSettingsType = {
  __typename?: 'TagSettingsType';
  availableInLibrary: Scalars['Boolean'];
  controlConfig?: Maybe<TagControlConfigType>;
  /** If true, this tag will not be attachable from UI, synced tags. external tags will be attached by services internally only. */
  disableAttaching?: Maybe<Scalars['Boolean']>;
  /** If true, options for any select type of tag will be disabled */
  disableEditing?: Maybe<Scalars['Boolean']>;
  /** If true, options for any select type of tag will be disabled */
  disableFiltering?: Maybe<Scalars['Boolean']>;
  /** If true, this tag will not be shown in groupby from UI, synced tags. external tags will be attached by services internally only. */
  disableGrouping?: Maybe<Scalars['Boolean']>;
  /** If true, this tag cannot be set to any empty value or be unassigned etc. */
  disableResetting?: Maybe<Scalars['Boolean']>;
  enableCrossWorkspaceSharing?: Maybe<Scalars['Boolean']>;
  iconSettings: IconSettings;
  requiredTag: Scalars['Boolean'];
};

export enum TagStatusEnum {
  Archived = 'ARCHIVED',
  Published = 'PUBLISHED',
}

export enum TagSubTypeEnum {
  MultiSelect = 'MULTI_SELECT',
  SingleSelect = 'SINGLE_SELECT',
}

export enum TagTypeEnum {
  Date = 'DATE',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  SingleSelect = 'SINGLE_SELECT',
  Text = 'TEXT',
  User = 'USER',
}

/** union of all tags */
export type TagUnion =
  | DateTagType
  | MultiSelectTagType
  | NumberTagType
  | SingleSelectTagType
  | TextTagType
  | UserTagType;

export type TaggedEntitiesExportInputPropsInput = {
  requiredEntities: Array<TagAvailableOnEnum>;
  tagId: Scalars['String'];
};

export type TaggedEntitiesExportInputPropsType = {
  __typename?: 'TaggedEntitiesExportInputPropsType';
  requiredEntities: Array<TagAvailableOnEnum>;
  tagId: Scalars['String'];
};

export type TaggedEntitiesExportMetadataType = {
  __typename?: 'TaggedEntitiesExportMetadataType';
  exportedCount: Scalars['Float'];
  totalCount: Scalars['Float'];
};

export type TaggedEntityCountInput = {
  tagId: Scalars['String'];
};

export type TaggedEntityCountPayload = {
  __typename?: 'TaggedEntityCountPayload';
  count: Scalars['Float'];
};

export type TagsFilters = {
  availableInLibrary?: InputMaybe<Array<Scalars['Boolean']>>;
  availableOn?: InputMaybe<Array<TagAvailableOnEnum>>;
  category?: InputMaybe<Array<TagCategoryEnum>>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  crossSubWorkspace?: InputMaybe<Scalars['Boolean']>;
  /** If true, this tag will not be attachable from UI, synced tags. external tags will be attached by services internally only. */
  disableAttaching?: InputMaybe<Array<Scalars['Boolean']>>;
  disableEditing?: InputMaybe<Array<Scalars['Boolean']>>;
  disableFiltering?: InputMaybe<Array<Scalars['Boolean']>>;
  disableGrouping?: InputMaybe<Array<Scalars['Boolean']>>;
  disableResetting?: InputMaybe<Array<Scalars['Boolean']>>;
  excludeReceivedTags?: InputMaybe<Scalars['Boolean']>;
  /** This field uses regex in name to fetch tags */
  name?: InputMaybe<Scalars['String']>;
  requiredTag?: InputMaybe<Scalars['Boolean']>;
  sharedTagFilters?: InputMaybe<SharedEntityFilters>;
  source?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<TagStatusEnum>>;
  subTypes?: InputMaybe<Array<TagSubTypeEnum>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  /** This field is used to match exact names of the tag */
  tagNames?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<Array<TagTypeEnum>>;
};

export type TagsFiltersType = {
  __typename?: 'TagsFiltersType';
  availableInLibrary?: Maybe<Array<Scalars['Boolean']>>;
  availableOn?: Maybe<Array<TagAvailableOnEnum>>;
  category?: Maybe<Array<TagCategoryEnum>>;
  createdByIds?: Maybe<Array<Scalars['String']>>;
  /** If true, this tag will not be attachable from UI, synced tags. external tags will be attached by services internally only. */
  disableAttaching?: Maybe<Array<Scalars['Boolean']>>;
  disableOptionsEdit?: Maybe<Array<Scalars['Boolean']>>;
  requiredTag?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Array<TagStatusEnum>>;
  tagIds?: Maybe<Array<Scalars['String']>>;
  /** This field is used to match exact names of the tag */
  tagNames?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Array<TagTypeEnum>>;
};

export type TargetChannelMapping = {
  sourceChannelId: Scalars['String'];
  targetChannelId: Scalars['String'];
};

export enum TaskAvailableOnEnum {
  AgendaItem = 'AGENDA_ITEM',
  KeyResult = 'KEY_RESULT',
  Meeting = 'MEETING',
  MeetingInstance = 'MEETING_INSTANCE',
  Okr = 'OKR',
  PeerFeedback = 'PEER_FEEDBACK',
}

/** All the configs related to task stored */
export type TaskConfigInputSchema = {
  statusConfig: TaskStatusConfigInputSchema;
};

/** All the configs related to task stored */
export type TaskConfigSchema = {
  __typename?: 'TaskConfigSchema';
  statusConfig: TaskStatusConfigSchema;
};

/** Task Type */
export type TaskContentType = {
  __typename?: 'TaskContentType';
  applicableRoles?: Maybe<Array<RoleType>>;
  assignmentRestriction?: Maybe<AssignmentRestrictionType>;
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  bookmark?: Maybe<BookmarkType>;
  /** list of all categories attached to the content */
  categoryIds?: Maybe<Array<Scalars['String']>>;
  certificateSettings?: Maybe<CertificateSettings>;
  certificateTemplate?: Maybe<CertificateTemplateType>;
  /** Storing media of cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  creationStrategy?: Maybe<ContentCreationStrategyEnum>;
  defaultGroupId?: Maybe<Scalars['String']>;
  draft?: Maybe<DraftTaskContentType>;
  /** Field resolver to check whether a content can be duplicated or not, on three dots of content listing page */
  duplicatable: Scalars['Boolean'];
  /** Field resolver to show whether the shared content, duplication is toggled on or off */
  duplicationAllowedOnSharing: Scalars['Boolean'];
  /** duration in seconds */
  duration: Scalars['Float'];
  /** list of all content groups where this content is added */
  groupIds?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  /** description of the task */
  instruction?: Maybe<TaskInstruction>;
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isReviewer: Scalars['Boolean'];
  isSent?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  /** Name of the resource */
  name: Scalars['String'];
  performance?: Maybe<TaskPerformanceType>;
  publishable: CheckContentPublishablePayload;
  /** Relevancy score is automatically given to all resources based on its status so that resources can be sorted according to status */
  relevancyScore: Scalars['Float'];
  sections?: Maybe<Array<TaskSectionType>>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  shareable: Scalars['Boolean'];
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  status: ResourceStatusEnum;
  /** Tells the sub-type of resource, for eg Static or review for quiz */
  subType: ResourceSubTypeEnum;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  systemGenerated: Scalars['Boolean'];
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  /** Tells the type of resource */
  type: ResourceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Permissions for a user on course */
  userPermissions?: Maybe<Array<UserResourcePermissionType>>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  versionStatus?: Maybe<ResourceStatusEnum>;
  workspaceMappings: Array<EntityWorkspaceMapping>;
};

/** Task Type */
export type TaskContentTypeDuplicationAllowedOnSharingArgs = {
  filters?: InputMaybe<DuplicationAllowedOnSharingInput>;
};

/** Task Type */
export type TaskContentTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** Task Type */
export type TaskContentTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** Task Type */
export type TaskContentTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

/** Task Type */
export type TaskContentTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** Task Type */
export type TaskContentTypeTagArgs = {
  tagId: Scalars['String'];
};

export type TaskCreationConfig = {
  /** Defines how the task is to be created */
  taskCreationType?: InputMaybe<TaskCreationTypeEnum>;
};

export type TaskCreationConfigInputProps = {
  createTaskCreationConfigInputs?: InputMaybe<Array<CreateTaskCreationConfigInput>>;
  removeTaskCreationConfigInputs?: InputMaybe<Array<Scalars['String']>>;
  updateTaskCreationConfigInputs?: InputMaybe<Array<UpdateTaskCreationConfigInput>>;
};

export enum TaskCreationConfigTypeEnum {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}

export enum TaskCreationEntityTypeEnum {
  File = 'FILE',
  Okr = 'OKR',
  Task = 'TASK',
}

export enum TaskCreationTypeEnum {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}

export type TaskGroupCount = {
  __typename?: 'TaskGroupCount';
  count: Scalars['Float'];
  status: Scalars['String'];
};

/** Task content description */
export type TaskInstruction = {
  __typename?: 'TaskInstruction';
  /** Media */
  medias: Array<EntityMediaType>;
  /** text */
  text?: Maybe<Scalars['String']>;
};

export type TaskInstructionInput = {
  medias?: InputMaybe<Array<CreateMediaInput>>;
  /** text */
  text?: InputMaybe<Scalars['String']>;
};

/** Task Performance Type */
export type TaskPerformanceType = {
  __typename?: 'TaskPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  /** stores all the assignments enrolment information of the user */
  allEnrolmentInfo?: Maybe<Array<EnrolmentInfoType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  enrolmentInfo?: Maybe<EnrolmentInfoType>;
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress?: Maybe<Scalars['Float']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

/** Section Performance Type */
export type TaskSectionPerformanceType = {
  __typename?: 'TaskSectionPerformanceType';
  actionMeta?: Maybe<Array<UserActionMetaType>>;
  archivalReason?: Maybe<ArchivalReasonEnum>;
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entity?: Maybe<ContentEntity>;
  /** EntityId could be sectionId, taskId etc */
  entityId: Scalars['String'];
  /** EntityType of the entityId */
  entityType: PerformanceEntityTypeEnum;
  /** Estimated Time spent by a user based on completed content's duration */
  estimatedTimeSpent?: Maybe<Scalars['Float']>;
  forceComplete?: Maybe<Scalars['Boolean']>;
  gradeEnum?: Maybe<PerformanceGradeEnum>;
  id: Scalars['String'];
  isOfCurrentWorkspace: Scalars['Boolean'];
  issuedCertificate?: Maybe<CertificateType>;
  learningType?: Maybe<LearningTypeEnum>;
  olderStatus?: Maybe<PerformanceStatusEnum>;
  /** Parent Info */
  parentDetails?: Maybe<PerformanceParentDetails>;
  progress?: Maybe<Scalars['Float']>;
  /** Additional resume information for contents like course */
  resume?: Maybe<Scalars['JSONObject']>;
  /** Estimated Time spent by a user on the content itself. Excluding the contents embedded in the content */
  selfEstimatedTimeSpent?: Maybe<Scalars['Float']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startedOn?: Maybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  timeSpent?: Maybe<Scalars['Float']>;
  type: PerformanceTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  /** id of the user who is assigned the task */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspaceId?: Maybe<Scalars['String']>;
};

/** Task Section Type */
export type TaskSectionType = {
  __typename?: 'TaskSectionType';
  completedCount?: Maybe<Scalars['Float']>;
  /** Stores the description of the section */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Name of the section */
  name?: Maybe<Scalars['String']>;
  subTasks?: Maybe<Array<SubTaskType>>;
  type: ContentTypeEnum;
};

/** Input for selecting tasks */
export type TaskSelectionConfigInput = {
  excludeTaskIds?: InputMaybe<Array<Scalars['String']>>;
  filter?: InputMaybe<ActionItemsFilters>;
  includeTaskIds?: InputMaybe<Array<Scalars['String']>>;
  operation: TaskSelectionOperationEnum;
};

export enum TaskSelectionOperationEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
}

/** Information regarding task status config */
export type TaskStatusConfigInputSchema = {
  statusIds: Array<Scalars['String']>;
  type: StatusConfigTypeEnum;
};

/** Information regarding task status config */
export type TaskStatusConfigSchema = {
  __typename?: 'TaskStatusConfigSchema';
  statusIds: Array<Scalars['String']>;
  type: StatusConfigTypeEnum;
};

export type TaskStubType = {
  __typename?: 'TaskStubType';
  name: Scalars['String'];
};

export enum TaskTypeEnum {
  Bell = 'BELL',
  Email = 'EMAIL',
  Push = 'PUSH',
  Slack = 'SLACK',
  Sms = 'SMS',
  Webhook = 'WEBHOOK',
}

export type TasksFromKrResultType = {
  __typename?: 'TasksFromKRResultType';
  records: Array<TaskStubType>;
};

export type TasksFromPeerFeedbackResultType = {
  __typename?: 'TasksFromPeerFeedbackResultType';
  records: Array<TaskStubType>;
};

/** For toggling email and bell notification on/off for a given actionType */
export type TasksRequiredInput = {
  bell: Scalars['Boolean'];
  email: Scalars['Boolean'];
};

export type TasksWidgetType = {
  __typename?: 'TasksWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  taskGroups: ActionItemCountsByCategoryAndStatusPayload;
  tasks: PaginatedActionItemsPayload;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type TasksWidgetTypeTasksArgs = {
  first?: InputMaybe<Scalars['Float']>;
};

export type TeamMeetingSetupUserNotification = {
  __typename?: 'TeamMeetingSetupUserNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  meetingDateTime: Scalars['String'];
  meetingId: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type TemplateCategoriesFilters = {
  availableOnTemplateType?: InputMaybe<Array<TemplateTypeEnum>>;
  featured?: InputMaybe<Scalars['Boolean']>;
  searchText?: InputMaybe<Scalars['String']>;
  templateCategoryIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Edge */
export type TemplateCategoryEdge = {
  __typename?: 'TemplateCategoryEdge';
  node: TemplateCategoryType;
};

export type TemplateCategoryFilters = {
  templateCategoryId: Scalars['String'];
};

export type TemplateCategoryPayload = {
  __typename?: 'TemplateCategoryPayload';
  templateCategory?: Maybe<TemplateCategoryType>;
};

export enum TemplateCategoryStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export type TemplateCategoryType = {
  __typename?: 'TemplateCategoryType';
  availableOnTemplateType: TemplateTypeEnum;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  featured: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: TemplateCategoryStatusEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Edge */
export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  node: TemplateUnion;
};

export type TemplateFilters = {
  templateId: Scalars['String'];
};

export type TemplateIdSetInput = {
  /** used to set template Id in meeting */
  set?: InputMaybe<Scalars['String']>;
};

export type TemplatePayload = {
  __typename?: 'TemplatePayload';
  template?: Maybe<TemplateUnion>;
};

export enum TemplateStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export enum TemplateTypeEnum {
  Okr = 'OKR',
}

/** union of all templates */
export type TemplateUnion = OkrTemplateType;

export type TemplatesFilters = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<TemplateStatusEnum>>;
  templateIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum TenantConnectionStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED',
}

export type TenantDepartmentConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Fetch TenantInfo input */
export type TenantInfoInput = {
  /** Fetch tenant info based on workspace id */
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type TenantOrgRoleConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type TenantStoreConfigInput = {
  hosts: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type TerminateUnusedExternalEventInput = {
  eventId: Scalars['String'];
};

export type TextSectionDisplayComponentType = {
  __typename?: 'TextSectionDisplayComponentType';
  /** heading for the section */
  heading?: Maybe<Scalars['String']>;
  /** rich text */
  text: Scalars['String'];
  type: DisplayComponentTypeEnum;
};

export type TextTagPropsInput = {
  textType: TextTagTypeEnum;
};

export type TextTagType = {
  __typename?: 'TextTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  /** field resolvers */
  taggedContentCount: Scalars['Float'];
  taggedEventCount: Scalars['Float'];
  taggedOKRCount: Scalars['Float'];
  taggedTaskCount: Scalars['Float'];
  taggedUserCount: Scalars['Float'];
  /** number type tag specific fields */
  textType: TextTagTypeEnum;
  type: TagTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type TextTagTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type TextTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

export enum TextTagTypeEnum {
  Long = 'LONG',
  Short = 'SHORT',
}

export enum TextToneEnum {
  Appreciative = 'APPRECIATIVE',
  Casual = 'CASUAL',
  Confident = 'CONFIDENT',
  Friendly = 'FRIENDLY',
  Informal = 'INFORMAL',
  Joyful = 'JOYFUL',
  Optimistic = 'OPTIMISTIC',
  Professional = 'PROFESSIONAL',
}

export enum TextTransformationEnum {
  ChangeTone = 'CHANGE_TONE',
  Contract = 'CONTRACT',
  Expand = 'EXPAND',
  Generate = 'GENERATE',
  Rephrase = 'REPHRASE',
}

export enum TextUseCaseEnum {
  ContentDescription = 'CONTENT_DESCRIPTION',
  EventDescription = 'EVENT_DESCRIPTION',
  OkrDescription = 'OKR_DESCRIPTION',
  QuizQuestion = 'QUIZ_QUESTION',
  UserFeedback = 'USER_FEEDBACK',
  UserFeedbackRequest = 'USER_FEEDBACK_REQUEST',
  UserPraise = 'USER_PRAISE',
}

export enum TimeDimensionGranularityEnum {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Second = 'SECOND',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type TimeDimensionType = {
  __typename?: 'TimeDimensionType';
  dateRange?: Maybe<Array<Scalars['String']>>;
  dimension: Scalars['String'];
  granularity: TimeDimensionGranularityEnum;
};

export type TimeDimensionTypeInput = {
  dateRange?: InputMaybe<Scalars['String']>;
  dimension: Scalars['String'];
  granularity: TimeDimensionGranularityEnum;
};

export enum TimeLimitApplicableOnEnum {
  Question = 'QUESTION',
  Quiz = 'QUIZ',
}

export type TimestampRangeInput = {
  endTime?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
};

export type TimestampRangeType = {
  __typename?: 'TimestampRangeType';
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

/** To Do learnings payloads  */
export type ToDoLearningsType = {
  __typename?: 'ToDoLearningsType';
  content?: Maybe<ContentEntity>;
};

export enum TodoTypeEnum {
  Content = 'CONTENT',
  ReviewCycle = 'REVIEW_CYCLE',
}

/** union of all todos */
export type TodoUnion = ContentTodoType | ReviewCycleInstanceTodoType | ReviewCycleTodoType;

export type TodosWidgetPreviewType = {
  __typename?: 'TodosWidgetPreviewType';
  /** todos */
  todos?: Maybe<Array<Scalars['JSON']>>;
  type: WidgetTypeEnum;
};

export type TodosWidgetType = {
  __typename?: 'TodosWidgetType';
  applicationSorts?: Maybe<Array<ApplicationSortType>>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  facetConfig?: Maybe<FacetConfig>;
  filterGroup?: Maybe<FilterGroupType>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** @deprecated Uses old type for sort */
  sorts?: Maybe<Array<QuerySortType>>;
  todoContents: Array<TodoUnion>;
  todos: Array<TodoUnion>;
  type: WidgetTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type TodosWidgetTypeTodoContentsArgs = {
  first?: InputMaybe<Scalars['Float']>;
};

export type TodosWidgetTypeTodosArgs = {
  first?: InputMaybe<Scalars['Float']>;
};

export type TopHitsConfigs = {
  __typename?: 'TopHitsConfigs';
  size?: Maybe<Scalars['Float']>;
  sorts?: Maybe<Array<SortTypeSchema>>;
};

/** Edge */
export type TrackEdge = {
  __typename?: 'TrackEdge';
  node: TrackType;
};

export type TrackFilters = {
  trackId: Scalars['String'];
};

export type TrackPayload = {
  __typename?: 'TrackPayload';
  track?: Maybe<TrackType>;
};

/** Payload of childTrackTemplate with child orgRole templates per track */
export type TrackTemplatesProfile = {
  __typename?: 'TrackTemplatesProfile';
  /** Configs required to create a department */
  createDepartmentConfig?: Maybe<CreateDepartmentInputPropsType>;
  /** Configs required to create a metric */
  createMetricConfig?: Maybe<CreateMetricConfigsInputPropsType>;
  /** Configs required to create an orgRole */
  createOrgRoleConfig?: Maybe<CreateOrgRoleInputPropsType>;
  /** Configs required to create a skill/mindset */
  createSkillConfig?: Maybe<CreateSkillInputPropsType>;
  /** Configs required to create a skillMatrix */
  createSkillMatrixConfig?: Maybe<CreateSkillMatrixInputPropsType>;
  /** Configs required to create a track */
  createTrackConfig?: Maybe<CreateTrackInputPropsType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  orgRoleTemplates?: Maybe<Array<DefaultTemplateType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Flows for which this template can be used */
  supportedFlows: Array<SupportedFlowsEnum>;
  trackTemplates: Array<TrackTemplatesProfile>;
  /** Entity type of template */
  type: DefaultTemplateEntityEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type TrackType = {
  __typename?: 'TrackType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  department?: Maybe<DepartmentType>;
  /** departmentId in which the track belongs */
  departmentId: Scalars['String'];
  /** description of the track */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** if track is deleted or not */
  isDeleted: Scalars['Boolean'];
  /** metric attached to track */
  metricIds?: Maybe<Array<Scalars['String']>>;
  metrics?: Maybe<Array<MetricSchema>>;
  /** mindsets attached to track */
  mindsetIds?: Maybe<Array<Scalars['String']>>;
  mindsets?: Maybe<Array<SkillType>>;
  /** name of the track */
  name: Scalars['String'];
  orgRoles?: Maybe<Array<OrgRoleType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  skillMatrix?: Maybe<SkillMatrixType>;
  /** skillMatrix attached to track */
  skillMatrixId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userCount: Scalars['Float'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type TracksFilters = {
  departmentIds?: InputMaybe<Array<Scalars['String']>>;
  skillMatrixIds?: InputMaybe<Array<Scalars['String']>>;
  trackIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Edge */
export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  node: TransactionType;
};

export type TransactionFilters = {
  _id?: InputMaybe<Scalars['String']>;
  paymentIntentId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  status: Array<TransactionPaymentStatusEnum>;
  userId?: InputMaybe<Scalars['String']>;
};

export enum TransactionPaymentModeEnum {
  Bank = 'BANK',
  Card = 'CARD',
  Emi = 'EMI',
  Na = 'NA',
  Upi = 'UPI',
  Wallet = 'WALLET',
}

export enum TransactionPaymentProviderEnum {
  Invalid = 'INVALID',
  Na = 'NA',
  Razorpay = 'RAZORPAY',
  Stripe = 'STRIPE',
}

export enum TransactionPaymentStatusEnum {
  Cancel = 'CANCEL',
  Failure = 'FAILURE',
  Init = 'INIT',
  Success = 'SUCCESS',
}

export enum TransactionPaymentTypeEnum {
  OneTime = 'ONE_TIME',
  Periodic = 'PERIODIC',
}

/** The Transaction Model */
export type TransactionType = {
  __typename?: 'TransactionType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  currency: Scalars['String'];
  id: Scalars['String'];
  orderId: Scalars['String'];
  paymentIntentId: Scalars['String'];
  paymentMode: TransactionPaymentModeEnum;
  paymentProvider: TransactionPaymentProviderEnum;
  paymentType: TransactionPaymentTypeEnum;
  productId: Scalars['String'];
  productPrice: Scalars['Float'];
  quantity: Scalars['Float'];
  reasonType: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: TransactionPaymentStatusEnum;
  storeId: Scalars['String'];
  totalAmount: Scalars['Float'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type TransactionTypeInput = {
  _id?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  paymentIntentId?: InputMaybe<Scalars['String']>;
  paymentMode?: InputMaybe<TransactionPaymentModeEnum>;
  paymentProvider?: InputMaybe<TransactionPaymentProviderEnum>;
  paymentType?: InputMaybe<TransactionPaymentTypeEnum>;
  productId?: InputMaybe<Scalars['String']>;
  productPrice?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  reasonType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TransactionPaymentStatusEnum>;
  storeId?: InputMaybe<Scalars['String']>;
  totalAmount?: InputMaybe<Scalars['Float']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type TransactionsFilters = {
  productIds?: InputMaybe<Array<Scalars['String']>>;
  status: Array<TransactionPaymentStatusEnum>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type TransformTextInput = {
  text: Scalars['String'];
  tone?: InputMaybe<TextToneEnum>;
  transformation: TextTransformationEnum;
  useCase?: InputMaybe<TextUseCaseEnum>;
};

export type TransformTextInputPropsType = {
  __typename?: 'TransformTextInputPropsType';
  text: Scalars['String'];
  tone?: Maybe<Scalars['String']>;
  transformation: TextTransformationEnum;
  useCase?: Maybe<TextUseCaseEnum>;
};

export type TransformTextResultType = {
  __typename?: 'TransformTextResultType';
  text: Scalars['String'];
};

export enum TypeCastEnum {
  ArrayObjectId = 'ARRAY_OBJECT_ID',
  ArrayString = 'ARRAY_STRING',
  Boolean = 'BOOLEAN',
  Number = 'NUMBER',
  ObjectId = 'OBJECT_ID',
  String = 'STRING',
}

/** URL Preview Metadata */
export type UrlPreviewImageMetadata = {
  __typename?: 'URLPreviewImageMetadata';
  height?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type UrlPreviewImageMetadataInput = {
  height?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Float']>;
};

/** URL Preview Metadata */
export type UrlPreviewMetadata = {
  __typename?: 'URLPreviewMetadata';
  description?: Maybe<Scalars['String']>;
  domain: Scalars['String'];
  icon?: Maybe<UrlPreviewImageMetadata>;
  image?: Maybe<UrlPreviewImageMetadata>;
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type UrlPreviewMetadataInput = {
  description?: InputMaybe<Scalars['String']>;
  domain: Scalars['String'];
  icon?: InputMaybe<UrlPreviewImageMetadataInput>;
  image?: InputMaybe<UrlPreviewImageMetadataInput>;
  title?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

/** UnAssignContentInput! */
export type UnAssignContentInput = {
  entityId: Scalars['String'];
  userId: Scalars['String'];
};

/** unAssignContent content payload */
export type UnAssignContentPayload = {
  __typename?: 'UnAssignContentPayload';
  /** ContentIds of parent content */
  contentIds?: Maybe<Array<Scalars['String']>>;
  contents?: Maybe<Array<ContentEntity>>;
  errorCode?: Maybe<Scalars['String']>;
  jobScheduleId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UnAssignContentUserNotification = {
  __typename?: 'UnAssignContentUserNotification';
  contentId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  unAssignedById: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type UnarchiveTagInput = {
  /** The name provided over here will replace the name of the tag being unarchived. It can be provided if the the current name of the tag being unarchived matches with an already published tag */
  name?: InputMaybe<Scalars['String']>;
  /** Id of the Tag that you want to unarchive */
  tagId: Scalars['String'];
};

/** Changing user onboard status from declined and reinviting the user to classroom */
export type UndoClassroomInviteRejectionInput = {
  classroomId: Scalars['String'];
  userId: Scalars['String'];
};

/** Change Onboarding Status Mutation Response */
export type UndoClassroomInviteRejectionPayload = {
  __typename?: 'UndoClassroomInviteRejectionPayload';
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UndoMoveMeetingInstanceAgendaItemsInput = {
  agendaItemId: Scalars['String'];
  targetInstanceId: Scalars['String'];
};

/** A filter to get user based on any uniqueId */
export type UniqueIdFilter = {
  /** Filter user based on users emailId */
  emailId?: InputMaybe<Scalars['String']>;
  /** Filter user based on users federationId */
  federationId?: InputMaybe<Scalars['String']>;
  /** Filter based on any uniqueId of user */
  id?: InputMaybe<Scalars['String']>;
};

/** A filter to get user based on any uniqueId */
export type UniqueIdsFilter = {
  /** Filter user based on users emailId */
  emailIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter user based on users federationId */
  federationIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter based on any uniqueId of user */
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type UnpublishContentInput = {
  blockId?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['String']>;
  contentType: ResourceTypeEnum;
};

/** UnPublish Content Response */
export type UnpublishContentPayload = {
  __typename?: 'UnpublishContentPayload';
  content?: Maybe<ContentEntity>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UnsetAuditMetadataType = {
  __typename?: 'UnsetAuditMetadataType';
  action: AuditActionEnum;
  auditId: Scalars['String'];
  collectionName: EntityCollectionEnum;
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  /** Content that was bookmarked by the user */
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<InstructionType>;
  /** Display string for the actual value, a string to be shown on UI */
  displayField?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  /** Actual field that is being tracked/referenced in this audit metadata of the entity */
  fieldName: Scalars['String'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  oldValue: Scalars['String'];
  relatedCollectionName?: Maybe<EntityCollectionEnum>;
  valueType: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type UnsetWorkspaceStrategyMethodInput = {
  provider: Scalars['String'];
};

export type UpcomingAutomaticRenewalRuleInput = {
  daysBeforeRenewalDate: Scalars['Float'];
  delaySeconds: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingAutomaticRenewalRuleType = {
  __typename?: 'UpcomingAutomaticRenewalRuleType';
  daysBeforeRenewalDate: Scalars['Float'];
  delaySeconds: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingClosedRenewalRuleInput = {
  daysBeforeRenewalDate: Scalars['Float'];
  delaySeconds: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingClosedRenewalRuleType = {
  __typename?: 'UpcomingClosedRenewalRuleType';
  daysBeforeRenewalDate: Scalars['Float'];
  delaySeconds: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingDeactivationRuleInput = {
  daysAfterRenewalDate: Scalars['Float'];
  delaySeconds: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingDeactivationRuleType = {
  __typename?: 'UpcomingDeactivationRuleType';
  daysAfterRenewalDate: Scalars['Float'];
  delaySeconds: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingEventsWidgetPreviewType = {
  __typename?: 'UpcomingEventsWidgetPreviewType';
  /** events */
  events?: Maybe<Array<Scalars['JSON']>>;
  type: WidgetTypeEnum;
};

export type UpcomingRenewalOpportunityRuleInput = {
  daysBeforeRenewalDate: Scalars['Float'];
  delaySeconds: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpcomingRenewalOpportunityRuleType = {
  __typename?: 'UpcomingRenewalOpportunityRuleType';
  daysBeforeRenewalDate: Scalars['Float'];
  delaySeconds: Scalars['Float'];
  status: WorkspaceAdministrationRuleStatusEnum;
  type: WorkspaceAdministrationRuleTypeEnum;
};

export type UpdateActionItemInput = {
  actionItemId: Scalars['String'];
  assignedToId?: InputMaybe<Scalars['String']>;
  /** if set is undefined, will unset the assigned to id field */
  assignedToIdSetInput?: InputMaybe<AssignedToIdSetInput>;
  category?: InputMaybe<StatusCategoryEnum>;
  dueDate?: InputMaybe<Scalars['String']>;
  /** if set is undefined, will unset the duedate field */
  dueDateSetInput?: InputMaybe<DueDateSetInput>;
  /** External Link of the actionItem */
  externalLink?: InputMaybe<Scalars['String']>;
  fileId?: InputMaybe<Scalars['String']>;
  /** instructions for the learner */
  instruction?: InputMaybe<InstructionInput>;
  /** Details for external entity */
  integrationConfig?: InputMaybe<ActionItemIntegrationConfigInput>;
  /** Details for any additional data related to action item  */
  metadata?: InputMaybe<ActionItemMetadataInput>;
  parentId?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<ActionItemPriorityEnum>;
  progress?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<EntityTagsInput>;
  title?: InputMaybe<Scalars['String']>;
  updateAtExternalSource?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateActionItemRelatedToEntitiesInput = {
  add?: InputMaybe<Array<RelatedEntityInput>>;
  pull?: InputMaybe<Array<RelatedEntityInput>>;
  set?: InputMaybe<Array<RelatedEntityInput>>;
  setEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateActivityMetricSelectionConfig = {
  add?: InputMaybe<Array<Scalars['String']>>;
  pull?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateAgendaItemInput = {
  activityMetricIds?: InputMaybe<UpdateActivityMetricSelectionConfig>;
  agendaItemId: Scalars['String'];
  creationCadenceConfig?: InputMaybe<UpdateCadenceConfigInput>;
  entity?: InputMaybe<Scalars['String']>;
  excludedOkrIds?: InputMaybe<UpdateOkrSelectionConfig>;
  /** Agenda Instructions in text or media */
  instruction?: InputMaybe<InstructionInput>;
  isChecked?: InputMaybe<Scalars['Boolean']>;
  mindsetIds?: InputMaybe<UpdateMindsetSelectionConfig>;
  okrBulkSelectConfig?: InputMaybe<OkrBulkSelectConfigInput>;
  okrIds?: InputMaybe<UpdateOkrSelectionConfig>;
  skillGroupSelectionConfig?: InputMaybe<UpdateSkillGroupSelectionConfig>;
  skillIds?: InputMaybe<UpdateSkillSelectionConfig>;
  subType?: InputMaybe<ActionItemSubTypeEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateAgendaItemTemplateInput = {
  agendaItemIds?: InputMaybe<Array<Scalars['String']>>;
  agendaItemTemplateId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateAgendaItemsInput = {
  agendaItems: Array<UpdateAgendaItemInput>;
};

export type UpdateAnalyticsDashboardInput = {
  analyticsId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AnalyticsDashboardStatusEnum>;
};

/** Update AnalyticsDashboard Status Response */
export type UpdateAnalyticsDashboardStatusPayload = {
  __typename?: 'UpdateAnalyticsDashboardStatusPayload';
  analyticsDashboard?: Maybe<AnalyticsDashboardType>;
  errorCode?: Maybe<AnalyticsDashboardErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateArticleInput = {
  articleId?: InputMaybe<Scalars['String']>;
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** author of the article */
  author?: InputMaybe<ArticleAuthorInputType>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Storing media of article-cover */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']>;
  /** duration of article */
  duration?: InputMaybe<Scalars['Float']>;
  /** external and internal instructors of article */
  instructors?: InputMaybe<Array<CourseInstructorInputType>>;
  name?: InputMaybe<Scalars['String']>;
  /** Ids of skill related to the article */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Update Article Response */
export type UpdateArticlePayload = {
  __typename?: 'UpdateArticlePayload';
  article?: Maybe<ArticleContentType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateAssignmentInput = {
  assignerId?: InputMaybe<Scalars['String']>;
  assignmentId: Scalars['String'];
  assignmentItemsDueDateConfig?: InputMaybe<Array<AssignmentItemDueDateConfigInput>>;
  collectionIds: Array<Scalars['String']>;
  contentIds: Array<Scalars['String']>;
  dueDateConfig?: InputMaybe<DueDateAssignmentConfigInput>;
  name?: InputMaybe<Scalars['String']>;
  notificationConfigurations?: InputMaybe<Array<NotificationConfigurationInput>>;
  scheduleDateConfig?: InputMaybe<ScheduleDateAssignmentConfigInput>;
  userGroupIds: Array<Scalars['String']>;
  userGroupSyncConfigs?: InputMaybe<UserGroupSyncAssignmentConfigsInput>;
  userIds: Array<Scalars['String']>;
};

export type UpdateAssignmentPayload = {
  __typename?: 'UpdateAssignmentPayload';
  assignment: AssignmentType;
};

export type UpdateAuditMetadataType = {
  __typename?: 'UpdateAuditMetadataType';
  action: AuditActionEnum;
  auditId: Scalars['String'];
  collectionName: EntityCollectionEnum;
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  /** Content that was bookmarked by the user */
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<InstructionType>;
  /** Display string for the actual value, a string to be shown on UI */
  displayField?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  /** Actual field that is being tracked/referenced in this audit metadata of the entity */
  fieldName: Scalars['String'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  newValue: Scalars['String'];
  oldValue: Scalars['String'];
  relatedCollectionName?: Maybe<EntityCollectionEnum>;
  valueType: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type UpdateBatchUsersPathContentAvailabilityInputPropsType = {
  __typename?: 'UpdateBatchUsersPathContentAvailabilityInputPropsType';
  pathId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type UpdateBatchUsersPathContentAvailabilityMetaDataType = {
  __typename?: 'UpdateBatchUsersPathContentAvailabilityMetaDataType';
  success: Scalars['Boolean'];
};

/** Input to update the block */
export type UpdateBlockInput = {
  /** Attributes of the block */
  attributes?: InputMaybe<Scalars['String']>;
  /** Type of the block */
  blockType?: InputMaybe<Scalars['String']>;
  /** Child block ids in order of their hierarchy */
  childrenIds?: InputMaybe<Array<Scalars['String']>>;
  /** child content that might be present in the block */
  decoratedChildBlocks?: InputMaybe<Scalars['String']>;
  /** BlockId */
  id?: InputMaybe<Scalars['String']>;
  /** Markup details of styling and comments */
  markups?: InputMaybe<Scalars['String']>;
  /** Parent entity's id */
  parentId?: InputMaybe<Scalars['String']>;
  /** Parent entity's type or table */
  parentType?: InputMaybe<Scalars['String']>;
  /** RootId to store the block Id of the top most block in the hierarchy */
  rootId?: InputMaybe<Scalars['String']>;
  /** Root Block's type or table */
  rootType?: InputMaybe<Scalars['String']>;
  /** text content that might be present in the block */
  text?: InputMaybe<Scalars['String']>;
};

/** Returns list of blocks which were affected */
export type UpdateBlockPayload = {
  __typename?: 'UpdateBlockPayload';
  block?: Maybe<BaseBlockType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCadenceConfigInput = {
  set: CadenceConfigInput;
};

export type UpdateCertificateTemplateInput = {
  certificateTemplateId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CertificateTemplateStatusEnum>;
};

export type UpdateChartInput = {
  chartId: Scalars['String'];
  chartType?: InputMaybe<ChartTypeEnum>;
  description?: InputMaybe<Scalars['String']>;
  dimensionConfigs?: InputMaybe<Array<DimensionConfigTypeInput>>;
  dimensions?: InputMaybe<Array<Scalars['String']>>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  measures?: InputMaybe<Array<MeasureTypeInput>>;
  name?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Array<Scalars['String']>>;
  sortOrder?: InputMaybe<Array<SortType>>;
  versionNumber?: InputMaybe<Scalars['Float']>;
};

export type UpdateChartPayload = {
  __typename?: 'UpdateChartPayload';
  chart: ChartWidgetType;
};

export type UpdateChildOkRsOrderInput = {
  /** ParentId of all okrs, for which we are trying to update the order */
  parentOKRId?: InputMaybe<Scalars['String']>;
  updatedChildOKROrders: Array<UpdatedChildOkrOrder>;
};

export type UpdateChildOkRsOrderPayload = {
  __typename?: 'UpdateChildOKRsOrderPayload';
  parentOKR?: Maybe<OkrUnion>;
  success?: Maybe<Scalars['Boolean']>;
};

/** childrenIds to update */
export type UpdateChildrenIdsInput = {
  operation: Array<ArrayOperationCommandEnum>;
  position?: InputMaybe<Scalars['Float']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateClassroomEventInput = {
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  eventId: Scalars['String'];
  eventStartTimestamp?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recordings?: InputMaybe<Array<Scalars['String']>>;
};

/** Update Event by Cron Response */
export type UpdateClassroomEventPayload = {
  __typename?: 'UpdateClassroomEventPayload';
  /** Error code */
  errorCode?: Maybe<ClassroomEventErrorCodeEnum>;
  event?: Maybe<ClassroomEventType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateClassroomInput = {
  classroomId: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ClassroomStatusEnum>;
  subject?: InputMaybe<Scalars['String']>;
};

export type UpdateClassroomPayload = {
  __typename?: 'UpdateClassroomPayload';
  /** Updated Classroom */
  classroom?: Maybe<ClassroomType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateContentStatusInput = {
  id: Scalars['String'];
  status: ResourceStatusEnum;
};

export type UpdateContentStatusPayload = {
  __typename?: 'UpdateContentStatusPayload';
  content?: Maybe<ContentEntity>;
  entityId: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCurrentUserPasswordInput = {
  currentPassword: Scalars['String'];
  logOutFromOtherDevices: Scalars['Boolean'];
  newPassword: Scalars['String'];
};

/** Updates database metadata */
export type UpdateDatabaseMetadataInput = {
  dbDefaultViewId?: InputMaybe<Scalars['String']>;
  dbId: Scalars['String'];
  dbMetadataId?: InputMaybe<Scalars['String']>;
  dbName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

/** Response for updating database metadata */
export type UpdateDatabaseMetadataPayload = {
  __typename?: 'UpdateDatabaseMetadataPayload';
  /** Updated Database */
  dbMetadata?: Maybe<DatabaseMetadataType>;
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Defines upsert input for database view */
export type UpdateDatabaseViewInput = {
  name?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<QueryInput>;
  viewId: Scalars['String'];
  viewType?: InputMaybe<ViewTypeEnum>;
};

/** Response to upsert database view operation */
export type UpdateDatabaseViewPayload = {
  __typename?: 'UpdateDatabaseViewPayload';
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  /** Returns upserted View */
  view?: Maybe<DatabaseViewType>;
};

export type UpdateDateTagPropsInput = {
  includeEndDate: Scalars['Boolean'];
  includeTime: Scalars['Boolean'];
};

/** Updates metadata fields */
export type UpdateDbMetadataFields = {
  allowMultipleSelection?: InputMaybe<Scalars['Boolean']>;
  collectionId?: InputMaybe<Scalars['String']>;
  collectionType?: InputMaybe<DatabaseRelationCollectionTypeEnum>;
  dateFormat?: InputMaybe<Scalars['String']>;
  fieldId: Scalars['String'];
  includeEndDate?: InputMaybe<Scalars['Boolean']>;
  includeTime?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  numberFormat?: InputMaybe<DatabaseNumberFormatEnum>;
  options?: InputMaybe<Array<DbMetadataFieldOptions>>;
  ratingType?: InputMaybe<DatabaseRatingTypeEnum>;
  type: DatabaseFieldTypeEnum;
  validation?: InputMaybe<ValidationInput>;
};

/** input for updating a department */
export type UpdateDepartmentInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDepartmentPayload = {
  __typename?: 'UpdateDepartmentPayload';
  /** Updated Department */
  department: DepartmentType;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateEmailDigestInput = {
  emailDigestId: Scalars['String'];
  fileFormat?: InputMaybe<EmailDigestFileFormatEnum>;
  name?: InputMaybe<Scalars['String']>;
  recipients?: InputMaybe<Array<RecipientInfoInput>>;
  reportId?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<ScheduleInfoInput>;
  status?: InputMaybe<EmailDigestStatusEnum>;
};

export type UpdateEntitiesTasksRelationInput = {
  /** array of updated entity tasks relations */
  entityTasksRelations: Array<UpdateEntityTasksRelationInput>;
};

export enum UpdateEntityAttachmentsCommandEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Set = 'SET',
  Update = 'UPDATE',
}

export enum UpdateEntityAttachmentsEntityTypeEnum {
  Agenda = 'AGENDA',
  Meeting = 'MEETING',
  Metric = 'METRIC',
  Okr = 'OKR',
  Task = 'TASK',
}

export type UpdateEntityAttachmentsInput = {
  command: UpdateEntityAttachmentsCommandEnum;
  entityId: Scalars['String'];
  entityType: UpdateEntityAttachmentsEntityTypeEnum;
  toRemoveAttachmentIds?: InputMaybe<Array<Scalars['String']>>;
  toUpsertAttachments?: InputMaybe<Array<CreateMediaInput>>;
};

export type UpdateEntityCustomStatusInput = {
  configType: StatusConfigTypeEnum;
  entityId: Scalars['String'];
  entityType: CustomStatusEntityTypeEnum;
  removedStatusMapping: Array<RemovedStatusMappingInputType>;
  statuses: Array<UpsertStatusInput>;
};

export type UpdateEntityJiraProjectStatuesConfig = {
  projectId: Scalars['String'];
  statuses: Array<UpdateJiraStatusMappingInput>;
};

export enum UpdateEntityTagCommandEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Set = 'SET',
  Update = 'UPDATE',
}

export type UpdateEntityTagOperation = {
  /** operation to performa on entity tags i.e. remove, set, upsert */
  command: UpdateEntityTagCommandEnum;
  /** entity tags which will be set, added or updated in entity */
  entityTags?: InputMaybe<Array<EntityTagInput>>;
  /** tag ids to remove */
  toRemoveTagIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum UpdateEntityTagsEntityTypeEnum {
  Content = 'CONTENT',
  Event = 'EVENT',
  Okr = 'OKR',
  Task = 'TASK',
  User = 'USER',
}

export type UpdateEntityTagsInput = {
  /** id of entity for which to update tags */
  entityId: Scalars['String'];
  /** type of entity */
  entityType: UpdateEntityTagsEntityTypeEnum;
  /** operation to perform on entity tags */
  operations: Array<UpdateEntityTagOperation>;
};

/** update entity tags payload */
export type UpdateEntityTagsPayload = {
  __typename?: 'UpdateEntityTagsPayload';
  entityTags?: Maybe<Array<EntityTagType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateEntityTasksRelationInput = {
  /** id of entity for which to update related tasks */
  entityId: Scalars['String'];
  /** type of entity */
  entityType: TaskAvailableOnEnum;
  /** task selection configs for entity */
  taskSelectionConfig: TaskSelectionConfigInput;
};

export type UpdateEventInput = {
  actualEndTime?: InputMaybe<Scalars['String']>;
  actualStartTime?: InputMaybe<Scalars['String']>;
  /** if not provided, existing certificateSettings will be removed */
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  completionSettings?: InputMaybe<CompletionSettingsInput>;
  /** if not provided, existing cover will be removed */
  cover?: InputMaybe<CreateMediaInput>;
  /** if not provided, existing description will be removed */
  description?: InputMaybe<Scalars['String']>;
  /** duration of event in seconds,used to derive endTime if endTime is not provided */
  duration?: InputMaybe<Scalars['Float']>;
  endTime?: InputMaybe<Scalars['String']>;
  eventId: Scalars['String'];
  eventRecordings?: InputMaybe<UpdateEventRecordingsInput>;
  externalEventProps?: InputMaybe<UpdateExternalEventPropsInput>;
  /** if not provided, will return error */
  instructorIds?: InputMaybe<Array<Scalars['String']>>;
  liveEventProps?: InputMaybe<UpdateLiveEventPropsInput>;
  name?: InputMaybe<Scalars['String']>;
  objective?: InputMaybe<Scalars['String']>;
  offlineEventProps?: InputMaybe<UpdateOfflineEventPropsInput>;
  postWorkContentIds?: InputMaybe<EventWorkContentsInput>;
  postwork?: InputMaybe<SetEventWorkConfigInput>;
  preWorkContentIds?: InputMaybe<EventWorkContentsInput>;
  prework?: InputMaybe<SetEventWorkConfigInput>;
  recordings?: InputMaybe<UpdateRecordingsInput>;
  /** if not provided, existing registrationSettings will be removed */
  registrationSettings?: InputMaybe<RegistrationSettingsInput>;
  /** if not provided, existing skillIds will be removed */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  startTime?: InputMaybe<Scalars['String']>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']>;
  updationSource?: InputMaybe<EventUpdationSourceEnum>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateEventRecordingsInput = {
  command: UpdateRecordingsCommandTypeEnum;
  eventRecordings: Array<EventRecordingInput>;
};

export type UpdateEventScheduleInput = {
  description?: InputMaybe<Scalars['String']>;
  eventScheduleId: Scalars['String'];
  /** Not to be updated by frontend */
  finalEventTimestamp?: InputMaybe<Scalars['String']>;
  /** Not to be updated by frontend */
  firstEventTimestamp?: InputMaybe<Scalars['String']>;
  /** Not to be updated by frontend */
  lastEventScheduleProcessedTimestamp?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Not to be updated by frontend */
  nextEventTimestamp?: InputMaybe<Scalars['String']>;
};

/** Update eventSchedule mutation response */
export type UpdateEventSchedulePayload = {
  __typename?: 'UpdateEventSchedulePayload';
  errorCode?: Maybe<EventScheduleErrorCodeEnum>;
  eventSchedule?: Maybe<EventScheduleType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateEventScheduleRecurrenceRuleConfigurationInput = {
  eventScheduleId: Scalars['String'];
  recurrenceRuleConfiguration: EventScheduleRecurrenceRuleConfigurationInput;
};

/** Update EventSchedule recurrenceRuleConfiguration mutation response */
export type UpdateEventScheduleRecurrenceRuleConfigurationPayload = {
  __typename?: 'UpdateEventScheduleRecurrenceRuleConfigurationPayload';
  errorCode?: Maybe<EventScheduleErrorCodeEnum>;
  eventSchedule?: Maybe<EventScheduleType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateEventScheduleRoleOfUsersInput = {
  eventScheduleId: Scalars['String'];
  eventScheduleUserRoleConfig: Array<EventScheduleUserRoleConfig>;
};

/** Update role of users to event schedule response */
export type UpdateEventScheduleRoleOfUsersPayload = {
  __typename?: 'UpdateEventScheduleRoleOfUsersPayload';
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  userIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateEventScheduleStatusInput = {
  eventScheduleId: Scalars['String'];
  status: EventScheduleStatusEnum;
};

/** Update eventSchedule Status mutation response */
export type UpdateEventScheduleStatusPayload = {
  __typename?: 'UpdateEventScheduleStatusPayload';
  errorCode?: Maybe<EventScheduleErrorCodeEnum>;
  eventSchedule?: Maybe<EventScheduleType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateEventStatusInput = {
  /** reason for event archival */
  archivalReason?: InputMaybe<Scalars['String']>;
  eventId: Scalars['String'];
  status: EventStatusEnum;
  updationSource?: InputMaybe<EventUpdationSourceEnum>;
};

export type UpdateEventUserSessionInput = {
  eventId: Scalars['String'];
};

export type UpdateEventUserSessionPayload = {
  __typename?: 'UpdateEventUserSessionPayload';
  success: Scalars['Boolean'];
};

export type UpdateEventVisibilitySettingsInput = {
  eventId: Scalars['String'];
  visibilitySettings: VisibilitySettingsInput;
};

export type UpdateExerciseInput = {
  /** Exercise Comments/ and other advance level settings */
  advanceSettings?: InputMaybe<ExerciseAdvanceSettingsInput>;
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  completionReviewRequired?: InputMaybe<Scalars['Boolean']>;
  /** Storing media of exercise-cover */
  cover?: InputMaybe<CreateMediaInput>;
  duration?: InputMaybe<Scalars['Float']>;
  exerciseQuestions?: InputMaybe<Array<ExerciseQuestionInput>>;
  /** instructions for the learner */
  instruction?: InputMaybe<ExerciseInstructionInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  objective?: InputMaybe<Scalars['String']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  resourceId: Scalars['String'];
  reviewType?: InputMaybe<ResourceReviewTypeEnum>;
  reviewerConfig?: InputMaybe<ReviewerConfigInput>;
  /** instructions for the reviewer */
  reviewerInstruction?: InputMaybe<ExerciseInstructionInput>;
  /** scorecards */
  scorecardIds?: InputMaybe<Array<Scalars['String']>>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
};

export type UpdateExercisePayload = {
  __typename?: 'UpdateExercisePayload';
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  /** Updated Homework */
  exercise?: Maybe<ExerciseType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateExternalContentInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Url of the external content */
  contentUrl?: InputMaybe<Scalars['String']>;
  /** Cover image of the external content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the external content */
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the external content */
  duration?: InputMaybe<Scalars['Float']>;
  /** External content type */
  externalContentType?: InputMaybe<ExternalContentTypeEnum>;
  externalResourceInfo?: InputMaybe<ExternalResourceInfoInput>;
  /** id of the external content to be updated */
  id: Scalars['String'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  /** Title of the external content */
  name?: InputMaybe<Scalars['String']>;
  /** Objective of the external content */
  objective?: InputMaybe<Scalars['String']>;
  /** Id of the external content provider */
  providerId?: InputMaybe<Scalars['String']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  /** Self Duration of the external content */
  selfDuration?: InputMaybe<Scalars['Float']>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  /** Method to verify completion of the external content */
  verificationMethod?: InputMaybe<VerificationMethodInputType>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Payload for updated external content mutation */
export type UpdateExternalContentPayload = {
  __typename?: 'UpdateExternalContentPayload';
  externalContent?: Maybe<ExternalContentType>;
};

/** Input for updating externalContentPerformance */
export type UpdateExternalContentPerformanceInput = {
  /** external url of the certificate */
  externalCertificateUrl?: InputMaybe<Scalars['String']>;
  /** Id of the external content whose performance to be updated */
  externalContentId: Scalars['String'];
  /** performance done on external platform */
  externalPerformance?: InputMaybe<ExternalPerformanceInput>;
  /** media submission */
  mediaSubmission?: InputMaybe<CreateMediaInput>;
  /** progress for external content */
  progress?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PerformanceStatusEnum>;
  /** text submission */
  textSubmission?: InputMaybe<Scalars['String']>;
  /** Id of the user whose performance to be updated */
  userId: Scalars['String'];
};

/** Updated external content */
export type UpdateExternalContentPerformancePayload = {
  __typename?: 'UpdateExternalContentPerformancePayload';
  externalContentPerformance?: Maybe<ExternalContentPerformanceType>;
};

export type UpdateExternalEventPropsInput = {
  externalEventProviderInfo?: InputMaybe<ExternalEventProviderInfoInput>;
  recordingSettings?: InputMaybe<RecordingSettingsInput>;
};

/** Feedback provided by the user */
export type UpdateFeedbackInput = {
  /** Entity Id */
  entityId?: InputMaybe<Scalars['String']>;
  /** Type of Enum */
  entityType?: InputMaybe<EntityTypeEnum>;
  /** FeedbackId for the feedback to be updated */
  feedbackId: Scalars['String'];
  metadata?: InputMaybe<FeedbackMetadataInput>;
  questionScope?: InputMaybe<FeedbackQuestionScopeEnum>;
  /** User feedback response to be updated */
  responses?: InputMaybe<Array<FeedbackResponseInput>>;
};

/** Feedback Question to be answered by user */
export type UpdateFeedbackQuestionInput = {
  /** Description */
  description?: InputMaybe<Scalars['String']>;
  /** FeedbackQuestionId for the feedbackQuestion to be updated */
  feedbackQuestionId: Scalars['String'];
  /** Questions */
  questions?: InputMaybe<Array<QuestionInput>>;
  /** Type Of Question */
  type?: InputMaybe<FeedbackQuestionTypeEnum>;
};

export type UpdateFileInput = {
  /** files or folder */
  category?: InputMaybe<FileCategoryEnum>;
  children?: InputMaybe<UpdateChildrenIdsInput>;
  description?: InputMaybe<Scalars['String']>;
  fileId: Scalars['String'];
  iconSettings?: InputMaybe<IconSettingsInput>;
  /** contains type of file */
  includes?: InputMaybe<Array<FileContentTypeEnum>>;
  jiraTaskProjectStatusesMap?: InputMaybe<Array<UpdateEntityJiraProjectStatuesConfig>>;
  name?: InputMaybe<Scalars['String']>;
  /** Location under which folders/file will be searched */
  path?: InputMaybe<Scalars['String']>;
  taskConfig?: InputMaybe<TaskConfigInputSchema>;
  taskSyncConfig?: InputMaybe<SyncConfigInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateGroupInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  contentVisibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  description?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  groupId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateGroupsOfUserInput = {
  add: Array<Scalars['String']>;
  remove: Array<Scalars['String']>;
};

export type UpdateJiraFieldsInput = {
  assigneeEmailAddress: Scalars['String'];
  dueDate: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateJiraPrioritiesMappingInput = {
  priorityMapping: Array<UpdateJiraPriorityMappingInput>;
};

export type UpdateJiraPrioritiesMappingPayload = {
  __typename?: 'UpdateJiraPrioritiesMappingPayload';
  priorityConfigs?: Maybe<Array<JiraIntegrationPriorityConfig>>;
};

export type UpdateJiraPriorityMappingInput = {
  jiraPriorityId: Scalars['String'];
  lyearnPriority: ActionItemPriorityEnum;
};

export type UpdateJiraStatusMappingInput = {
  jiraStatusId: Scalars['String'];
  lyearnStatusId: Scalars['String'];
};

export type UpdateJiraStatusProgressInput = {
  jiraStatusId: Scalars['String'];
  progress?: InputMaybe<Scalars['Float']>;
};

export type UpdateJiraStatusesMappingInput = {
  statusMap: Array<UpdateJiraStatusMappingInput>;
};

export type UpdateJiraStatusesMappingPayload = {
  __typename?: 'UpdateJiraStatusesMappingPayload';
  projectStatusesConfigs: Array<JiraIntegrationProjectStatusesConfig>;
};

export type UpdateJiraStatusesProgressMappingInput = {
  statusProgressMap: Array<UpdateJiraStatusProgressInput>;
};

export type UpdateJiraStatusesProgressMappingPayload = {
  __typename?: 'UpdateJiraStatusesProgressMappingPayload';
  projectStatusesConfigs: Array<JiraIntegrationProjectStatusesConfig>;
};

export type UpdateJobInput = {
  completedAt?: InputMaybe<Scalars['String']>;
  /** status of the job */
  error?: InputMaybe<Scalars['String']>;
  /** id of the job */
  jobScheduleId?: InputMaybe<Scalars['String']>;
  /** url and format of the media associated with the job */
  mediaUrls?: InputMaybe<Array<MediaUrlInput>>;
  meta?: InputMaybe<JobMetaInputType>;
  /** url and format of the media associated with the job */
  outputMediaUrls?: InputMaybe<Array<MediaUrlInput>>;
  /** status of the job */
  status?: InputMaybe<JobEntityStatusEnum>;
};

export type UpdateKeyResultInput = {
  /** Order of okr in parent okr child list */
  childOrder?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  dueDateConfig?: InputMaybe<DueDateConfigInput>;
  instruction?: InputMaybe<InstructionInput>;
  /** Details for linking externally */
  integrationConfig?: InputMaybe<OkrIntegrationConfigInput>;
  /** If id is provided, it will update keyResult, else will create a new one */
  keyResultId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** outcome for the KeyResult */
  outcome?: InputMaybe<KeyResultOutcomeInput>;
  /** owners of OKR */
  ownerIds?: InputMaybe<OwnerIdsSetInput>;
  /** if empty, means that no update is to be done */
  parentId?: InputMaybe<ParentIdSetInput>;
  parentIds?: InputMaybe<ParentIdsSetInput>;
  /** Details for progress measurement */
  progressMeasurementConfig?: InputMaybe<OkrProgressMeasurementConfigInput>;
  tags?: InputMaybe<EntityTagsInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
};

export type UpdateLastResolvedNotificationTimestampPayload = {
  __typename?: 'UpdateLastResolvedNotificationTimestampPayload';
  lastResolvedNotificationTimestamp?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateLiveEventPropsInput = {
  /** should be provided while updating subType to webinar */
  provider?: InputMaybe<LivestreamProviderEnum>;
  recordingSettings?: InputMaybe<RecordingSettingsInput>;
  subType?: InputMaybe<EventSubTypeEnum>;
};

export type UpdateMeetingInput = {
  description?: InputMaybe<Scalars['String']>;
  excludedOkrIds?: InputMaybe<UpdateMeetingOkrIdsInput>;
  includeChildObjectives?: InputMaybe<Scalars['Boolean']>;
  meetingId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  noteBlockId?: InputMaybe<Scalars['String']>;
  okrBulkSelectConfig?: InputMaybe<OkrBulkSelectConfigInput>;
  okrIds?: InputMaybe<UpdateMeetingOkrIdsInput>;
  okrIdsWithoutChildren?: InputMaybe<UpdateMeetingOkrIdsInput>;
  participantIds?: InputMaybe<Array<Scalars['String']>>;
  schedule?: InputMaybe<ScheduleInfoInput>;
  status?: InputMaybe<PublicMeetingStatusEnum>;
  /** if set is undefined, will unset the assigned to id field */
  templateId?: InputMaybe<TemplateIdSetInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
};

export type UpdateMeetingInstanceAgendaItemsOrderInput = {
  agendaItemIds: Array<Scalars['String']>;
  meetingInstanceId: Scalars['String'];
};

export type UpdateMeetingInstancePrivateNotesInput = {
  meetingInstanceId: Scalars['String'];
  note: InstructionInput;
};

export type UpdateMeetingInstanceStatusInput = {
  meetingInstanceId: Scalars['String'];
  status: PublicMeetingInstanceStatusEnum;
};

export type UpdateMeetingOkrIdsInput = {
  add?: InputMaybe<Array<Scalars['String']>>;
  pull?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
  setEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateMetricInput = {
  /** Salesforce -> dataObject */
  dataObject?: InputMaybe<Scalars['String']>;
  defaultTargetFrequency?: InputMaybe<RelativeDateFilterUnitEnum>;
  defaultTargetValue?: InputMaybe<Scalars['Float']>;
  defaultTargetValueUnit?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  lastSyncedAt?: InputMaybe<Scalars['String']>;
  manualMetricConfig?: InputMaybe<ManualMetricConfigInput>;
  /** Metric type */
  measurementUnit?: InputMaybe<KeyResultMetricEnum>;
  metricId: Scalars['String'];
  /** Salesforce -> filters */
  metricType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  reminders?: InputMaybe<UpdateMetricRemindersInput>;
  repeatSchedule?: InputMaybe<ScheduleInfoInput>;
  status?: InputMaybe<MetricStatusEnum>;
  syncConfig?: InputMaybe<MetricSyncConfigInputType>;
};

export type UpdateMetricRemindersInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateMindsetSelectionConfig = {
  add?: InputMaybe<Array<Scalars['String']>>;
  pull?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateMultiSelectTagPropsInput = {
  ignoreOptions?: InputMaybe<Scalars['Boolean']>;
  options: Array<UpdateSelectTagOptionInput>;
};

/** Input for Updating Notification Template For Customizing Notifications */
export type UpdateNotificationTemplateInput = {
  actionType?: InputMaybe<NotificationActionTypeEnum>;
  notificationTemplateId: Scalars['String'];
  reminderConfigurations?: InputMaybe<Array<ReminderConfigurationInput>>;
  tasksRequired?: InputMaybe<TasksRequiredInput>;
};

/** Update Notification Template Response */
export type UpdateNotificationTemplatePayload = {
  __typename?: 'UpdateNotificationTemplatePayload';
  notificationTemplate?: Maybe<NotificationTemplate>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateNumberTagPropsInput = {
  numberType: NumberTagTypeEnum;
};

export type UpdateOkrCycleInput = {
  /** id of cycle */
  cycleId: Scalars['String'];
  /** Due date of the cycle */
  dueDateConfig?: InputMaybe<DueDateConfigInput>;
  /** display emoji or color */
  iconSetting?: InputMaybe<IconSettingsInput>;
  /** Name of cycle */
  name?: InputMaybe<Scalars['String']>;
  objectivePermissions?: InputMaybe<ObjectivePermissionsSetInput>;
  scheduleConfig?: InputMaybe<ScheduleConfigInput>;
  /** start date of the cycle */
  startDate?: InputMaybe<Scalars['String']>;
  /** Status for okr, ontrack behind etc */
  status?: InputMaybe<OkrCycleStatusEnum>;
  /** store who can view the cycle */
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateOkrCyclePayload = {
  __typename?: 'UpdateOKRCyclePayload';
  cycle?: Maybe<OkrCycle>;
};

export type UpdateOkrDetailsInput = {
  description?: InputMaybe<Scalars['String']>;
  dueDateConfig?: InputMaybe<DueDateConfigInput>;
  instruction?: InputMaybe<InstructionInput>;
  name?: InputMaybe<Scalars['String']>;
  okrId: Scalars['String'];
  /** owners of OKR */
  ownerIds?: InputMaybe<OwnerIdsSetInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
};

export type UpdateOkrInput = {
  /** Order of okr in parent okr child list */
  childOrder?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  dueDateConfig?: InputMaybe<DueDateConfigInput>;
  instruction?: InputMaybe<InstructionInput>;
  name?: InputMaybe<Scalars['String']>;
  /** owners of OKR */
  ownerIds?: InputMaybe<OwnerIdsSetInput>;
  /** if empty, means that no update is to be done */
  parentId?: InputMaybe<ParentIdSetInput>;
  parentIds?: InputMaybe<ParentIdsSetInput>;
  tags?: InputMaybe<EntityTagsInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
};

/** Input to update KeyResult or Objective progress */
export type UpdateOkrProgressInput = {
  /** Current Value of the key result */
  actualValue?: InputMaybe<Scalars['Float']>;
  /** Id of the OKR */
  okrId: Scalars['String'];
  /** Direct progress value for OKR, will add current value based on Progress number */
  progress?: InputMaybe<Scalars['Float']>;
};

export type UpdateOkrSelectionConfig = {
  add?: InputMaybe<Array<Scalars['String']>>;
  pull?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateObjectiveInput = {
  /** category config input */
  categoryConfig?: InputMaybe<ObjectiveCategoryConfigInput>;
  childObjectives?: InputMaybe<ChildObjectivesSetInput>;
  /** Order of okr in parent okr child list */
  childOrder?: InputMaybe<Scalars['Float']>;
  contributeToProgress?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  dueDateConfig?: InputMaybe<DueDateConfigInput>;
  instruction?: InputMaybe<InstructionInput>;
  keyResults?: InputMaybe<KeyResultsSetInput>;
  name?: InputMaybe<Scalars['String']>;
  /** ID of objective to be updated */
  objectiveId?: InputMaybe<Scalars['String']>;
  /** owners of OKR */
  ownerIds?: InputMaybe<OwnerIdsSetInput>;
  /** if empty, means that no update is to be done */
  parentId?: InputMaybe<ParentIdSetInput>;
  parentIds?: InputMaybe<ParentIdsSetInput>;
  tags?: InputMaybe<EntityTagsInput>;
  viewConfig?: InputMaybe<EntityViewConfigInput>;
  /** store who can view the objective */
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateObjectivePayload = {
  __typename?: 'UpdateObjectivePayload';
  objective?: Maybe<ObjectiveType>;
};

export type UpdateOfflineEventPropsInput = {
  location?: InputMaybe<EventLocationInput>;
};

export type UpdateOrgRoleActivityMetricsInput = {
  add?: InputMaybe<Array<ActivityMetricsInputType>>;
  edit?: InputMaybe<Array<ActivityMetricsInputType>>;
  remove?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<ActivityMetricsInputType>>;
  /** Metrics to be created and added to orgRole with default target and frequency */
  toCreateMetrics?: InputMaybe<Array<CreateMetricInput>>;
};

export type UpdateOrgRoleDepartmentInput = {
  set?: InputMaybe<Scalars['String']>;
};

/** input for creating a new orgRole under a department */
export type UpdateOrgRoleInput = {
  activityMetricWeightDistributionConfig?: InputMaybe<ActivityMetricWeightDistributionConfigInput>;
  activityMetrics?: InputMaybe<UpdateOrgRoleActivityMetricsInput>;
  definition?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<UpdateOrgRoleDepartmentInput>;
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Float']>;
  mindsetIds?: InputMaybe<UpdateOrgRoleMindsetInput>;
  name?: InputMaybe<Scalars['String']>;
  orgRoleId: Scalars['String'];
  orgRoleReportConfig?: InputMaybe<OrgRoleReportInput>;
  /** input for creating or editing skillGroups inside an orgRole */
  skillGroups?: InputMaybe<UpdateOrgRoleSkillGroupsInput>;
  skillMasteries?: InputMaybe<UpdateOrgRoleSkillMasteriesInput>;
  skillMatrixConfig?: InputMaybe<UpdateSkillMatrixConfigInput>;
  status?: InputMaybe<OrgRoleStatusEnum>;
};

export type UpdateOrgRoleMindsetInput = {
  add?: InputMaybe<Array<Scalars['String']>>;
  create?: InputMaybe<Array<CreateSkillInput>>;
  remove?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateOrgRolePayload = {
  __typename?: 'UpdateOrgRolePayload';
  createdActivityMetrics?: Maybe<Array<ActivityMetricType>>;
  createdMindsets?: Maybe<Array<SkillType>>;
  createdSkillGroups?: Maybe<Array<SkillGroupType>>;
  createdSkills?: Maybe<Array<SkillType>>;
  /** Updated OrgRole */
  orgRole?: Maybe<OrgRoleType>;
};

export type UpdateOrgRoleSkillGroupsInput = {
  /** input for creating skillGroup from inside an org role */
  toCreateSkillGroups?: InputMaybe<Array<CreateSkillGroupInOrgRoleInput>>;
  /** input for editing skillGroup name from inside an org role */
  toEditSkillGroups?: InputMaybe<Array<EditSkillGroupInOrgRoleInput>>;
};

export type UpdateOrgRoleSkillMasteriesInput = {
  add?: InputMaybe<Array<AddSkillInOrgRoleInput>>;
  create?: InputMaybe<Array<CreateSkillInOrgRoleInput>>;
  edit?: InputMaybe<Array<SkillMasteryConfigInput>>;
  remove?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<SkillMasteryConfigInput>>;
};

/** UpdatePathContentAvailabilityOfGivenUserIdsInput! */
export type UpdatePathContentAvailabilityOfGivenUserIdsInput = {
  pathId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type UpdatePathInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  /** Cover image of the path content */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']>;
  dripSettings?: InputMaybe<DripSettingsInput>;
  id: Scalars['String'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Should notifications be sent to learners when the path is being updated */
  notifyLearners?: InputMaybe<Scalars['Boolean']>;
  objective?: InputMaybe<Scalars['String']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  /** sections not provided will be deleted */
  sections: Array<UpdatePathSectionInput>;
  /** skill ids not provided will be deleted */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdatePathPayload = {
  __typename?: 'UpdatePathPayload';
  path?: Maybe<PathContentType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdatePathSectionInput = {
  /** learning content ids */
  childResourceIds: Array<Scalars['String']>;
  /** id given means that section already exists */
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdatePeerFeedbackInput = {
  /** Attachments of this post */
  attachments?: InputMaybe<AttachmentsSetInput>;
  feedbackMessage?: InputMaybe<Scalars['String']>;
  learningContentIds?: InputMaybe<Array<Scalars['String']>>;
  mentions?: InputMaybe<MentionsSetInput>;
  mindsetRatings?: InputMaybe<Array<PeerFeedbackMindsetRatingInputType>>;
  peerFeedbackId: Scalars['String'];
  skillRatings?: InputMaybe<Array<PeerFeedbackSkillRatingInputType>>;
  status?: InputMaybe<PeerFeedbackStatusEnum>;
  visibleToManager?: InputMaybe<Scalars['Boolean']>;
};

/** Input for updating the status of performance */
export type UpdatePerformanceStatusInput = {
  /** Entity (like task, subtask) Id whose performance status are to be update */
  entityId?: InputMaybe<Scalars['String']>;
  status: PerformanceStatusEnum;
  type?: InputMaybe<PerformanceTypeEnum>;
  /** Id of the students whose performance status are to be update */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdatePraiseInput = {
  /** Attachments of this post */
  attachments?: InputMaybe<AttachmentsSetInput>;
  /** Mentions in the message payload on a post like user mentions (@user) */
  mentions?: InputMaybe<MentionsSetInput>;
  message?: InputMaybe<Scalars['String']>;
  mindsetRatings?: InputMaybe<Array<PeerFeedbackMindsetRatingInputType>>;
  postId: Scalars['String'];
  praiseId: Scalars['String'];
  receiverUserIds?: InputMaybe<Array<Scalars['String']>>;
  skillRatings?: InputMaybe<Array<PeerFeedbackSkillRatingInputType>>;
  visibleToEveryone?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateQuestionEntity = {
  associations?: InputMaybe<AssociationsSetInput>;
  attachments?: InputMaybe<QuestionAttachmentsSetInput>;
  correctAnswerFeedback?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  difficulty?: InputMaybe<DifficultyEnum>;
  ftbComponents?: InputMaybe<FtbComponentsSetInput>;
  globalDistractors?: InputMaybe<DistractorsSetInput>;
  id: Scalars['String'];
  incorrectAnswerFeedback?: InputMaybe<Scalars['String']>;
  mtfComponent?: InputMaybe<MtfComponentInput>;
  options?: InputMaybe<OptionsSetInput>;
  questionText?: InputMaybe<Scalars['String']>;
  questionTextType?: InputMaybe<QuestionTextTypeEnum>;
  questionType?: InputMaybe<QuestionTypeEnum>;
  settings?: InputMaybe<QuestionSettingsInput>;
  skillIds?: InputMaybe<QuestionSkillIdsSetInput>;
  status?: InputMaybe<QuestionStatusEnum>;
  /** Sub type of question like multi select, single select */
  subType?: InputMaybe<QuestionSubTypeEnum>;
};

export type UpdateQuestionsInput = {
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  updateQuestionEntities?: InputMaybe<Array<UpdateQuestionEntity>>;
};

export type UpdateQuestionsPayload = {
  __typename?: 'UpdateQuestionsPayload';
  /** Failed Questions */
  failedQuestions: Array<QuestionType>;
  success?: Maybe<Scalars['Boolean']>;
  /** Questions created */
  updatedQuestions: Array<QuestionType>;
};

export type UpdateQuizInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  /** Cover image of the content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the content */
  description?: InputMaybe<Scalars['String']>;
  /** Duration in seconds */
  duration?: InputMaybe<Scalars['Float']>;
  /** Entity on which this quiz has to attached */
  entityId?: InputMaybe<Scalars['String']>;
  /** Entity type of the entity where this quiz will be attached */
  entityType?: InputMaybe<QuizEntityTypeEnum>;
  /** Quiz id to be updated */
  id: Scalars['String'];
  /** Instructions for the quiz */
  instruction?: InputMaybe<ResourceInstructionInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  /** Name of the quiz */
  name?: InputMaybe<Scalars['String']>;
  /** Objective of the content */
  objective?: InputMaybe<Scalars['String']>;
  /** Passing Percentage of the quiz */
  passingPercentage?: InputMaybe<Scalars['Float']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  /** Quiz Question ids along with settings */
  questions?: InputMaybe<QuestionsSetInput>;
  /** quiz level settings */
  settings?: InputMaybe<QuizSettingsInput>;
  /** Ids of skills suitable for the content */
  skillIds?: InputMaybe<QuizSkillIdsSetInput>;
  /** status of quiz */
  status?: InputMaybe<ResourceStatusEnum>;
  /** SubType of resource, here quiz. Ex - Static, inLesson, review etc */
  subType?: InputMaybe<ResourceSubTypeEnum>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  /** Tags related to quiz */
  tags?: InputMaybe<EntityTagsInput>;
};

export type UpdateQuizPayload = {
  __typename?: 'UpdateQuizPayload';
  /** Updated Quiz */
  quiz?: Maybe<QuizContentType>;
};

/** This returns post along with success when a reaction is added or removed */
export type UpdateReactionPayload = {
  __typename?: 'UpdateReactionPayload';
  post?: Maybe<PostType>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum UpdateRecordingsCommandTypeEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Set = 'SET',
}

export type UpdateRecordingsInput = {
  command: UpdateRecordingsCommandTypeEnum;
  recordings: Array<Scalars['String']>;
};

export type UpdateReportInput = {
  description?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroupInput>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  name?: InputMaybe<Scalars['String']>;
  reportId: Scalars['String'];
  status?: InputMaybe<ReportStatusEnum>;
  tags?: InputMaybe<EntityTagsInput>;
  versionNumber?: InputMaybe<Scalars['Float']>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
  widgetsLayout?: InputMaybe<WidgetLayoutSetInput>;
};

/** Update Report Payload */
export type UpdateReportPayload = {
  __typename?: 'UpdateReportPayload';
  report: ReportType;
};

export type UpdateResourceInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Parent folder Id of the resource */
  parentId?: InputMaybe<Scalars['String']>;
  resourceId: Scalars['String'];
  status?: InputMaybe<ResourceStatusEnum>;
};

/** Update Resource Response */
export type UpdateResourcePayload = {
  __typename?: 'UpdateResourcePayload';
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  resource?: Maybe<ResourceType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** ReviewCycle Schema */
export type UpdateReviewCycleInput = {
  /** ReviewCycle description */
  description?: InputMaybe<Scalars['String']>;
  /** When will the review cycle end */
  endDate?: InputMaybe<Scalars['String']>;
  /** Icon settings */
  iconSettings?: InputMaybe<IconSettingsInput>;
  /** Id of the review cycle */
  id?: InputMaybe<Scalars['String']>;
  /** Review Cycle End Settings */
  reviewCycleEndSettings?: InputMaybe<ReviewCycleEndSettingsInput>;
  /** reviewee to whom this reviewCycle is attached */
  reviewees?: InputMaybe<Array<RevieweeInput>>;
  /** Types of Reviewers allowed to give the review */
  reviewerTypes?: InputMaybe<Array<ReviewTypeEnum>>;
  /** When will the review cycle start */
  startDate?: InputMaybe<Scalars['String']>;
  /** Review Cycle Status */
  status?: InputMaybe<ReviewCycleStatusEnum>;
  /** ReviewCycle Name or Title */
  title?: InputMaybe<Scalars['String']>;
};

/** input for approving or rejecting exercise submission */
export type UpdateReviewersInput = {
  defaultReviewerId?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  managerId?: InputMaybe<Scalars['String']>;
  reviewers?: InputMaybe<ReviewersSetInput>;
  specificReviewerId?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
  type: UpdateReviewersInputTypeEnum;
  userId?: InputMaybe<Scalars['String']>;
};

export enum UpdateReviewersInputTypeEnum {
  ExerciseDefaultReviewerChanged = 'EXERCISE_DEFAULT_REVIEWER_CHANGED',
  ExerciseGroupChanged = 'EXERCISE_GROUP_CHANGED',
  ExerciseManagerChanged = 'EXERCISE_MANAGER_CHANGED',
  ExerciseSpecificReviewerChanged = 'EXERCISE_SPECIFIC_REVIEWER_CHANGED',
  ExerciseTagChanged = 'EXERCISE_TAG_CHANGED',
  GroupMembersUpdated = 'GROUP_MEMBERS_UPDATED',
  TagMembersUpdated = 'TAG_MEMBERS_UPDATED',
  UserManagerUpdated = 'USER_MANAGER_UPDATED',
}

export type UpdateSamlDataInput = {
  provider: Scalars['String'];
  set: Array<SetSamlDataInput>;
};

export type UpdateScorecardInput = {
  questions?: InputMaybe<Array<ScorecardQuestionInput>>;
  scorecardId: Scalars['String'];
  skillId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateScorecardPayload = {
  __typename?: 'UpdateScorecardPayload';
  /** Error code */
  errorCode?: Maybe<Scalars['String']>;
  scorecard?: Maybe<ScorecardType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateScormInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  contentId: Scalars['String'];
  /** Storing media of cover */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the scorm content */
  duration?: InputMaybe<Scalars['Float']>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  objective?: InputMaybe<Scalars['String']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  scormProperties?: InputMaybe<ScormPropertiesInput>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

export type UpdateSelectTagOptionInput = {
  externalData?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdateSelfLearningContentInput = {
  /** content id */
  contentId: Scalars['String'];
  /** Url of the external content */
  contentUrl?: InputMaybe<Scalars['String']>;
  /** Cover image of the external content */
  cover?: InputMaybe<CreateMediaInput>;
  /** Description of the external content */
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the external content */
  duration?: InputMaybe<Scalars['Float']>;
  /** External content type */
  externalContentType?: InputMaybe<ExternalContentTypeEnum>;
  /** Title of the external content */
  name?: InputMaybe<Scalars['String']>;
  /** Objective of the external content */
  objective?: InputMaybe<Scalars['String']>;
  /** Ids of skills suitable for the external content */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateSharedItemsDuplicationStatusInput = {
  allowDuplication: Scalars['Boolean'];
  items: Array<ShareItemInput>;
  sharedToWorkspaceId: Scalars['String'];
};

export type UpdateSingleSelectTagPropsInput = {
  ignoreOptions?: InputMaybe<Scalars['Boolean']>;
  options: Array<UpdateSelectTagOptionInput>;
};

export type UpdateSkillGroupSelectionConfig = {
  add?: InputMaybe<Array<Scalars['String']>>;
  userId: Scalars['String'];
};

/** Input for updating an existing skill */
export type UpdateSkillInput = {
  description?: InputMaybe<Scalars['String']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  name?: InputMaybe<Scalars['String']>;
  rubrics?: InputMaybe<Array<RubricInput>>;
  skillId: Scalars['String'];
};

export type UpdateSkillMatrixConfigInput = {
  set?: InputMaybe<SkillMatrixConfigInput>;
};

export type UpdateSkillMatrixInput = {
  iconSettings?: InputMaybe<IconSettingsInput>;
  name?: InputMaybe<Scalars['String']>;
  skillGroups?: InputMaybe<SkillGroupsInput>;
  skillMatrixId: Scalars['String'];
  status?: InputMaybe<PublicSkillMatrixStatusEnum>;
};

export type UpdateSkillMatrixPayload = {
  __typename?: 'UpdateSkillMatrixPayload';
  createdSkills?: Maybe<Array<SkillType>>;
  skillMatrix?: Maybe<SkillMatrixType>;
};

export type UpdateSkillSelectionConfig = {
  add?: InputMaybe<Array<Scalars['String']>>;
  pull?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateStaffRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  entityPermissionKeys: Array<Scalars['String']>;
  name: Scalars['String'];
  roleId: Scalars['String'];
  tagColor?: InputMaybe<Scalars['String']>;
};

export type UpdateStatusOfClassroomEventInput = {
  eventId: Scalars['String'];
  status: ClassroomEventStatusEnum;
};

/** Update Event Status response */
export type UpdateStatusOfClassroomEventPayload = {
  __typename?: 'UpdateStatusOfClassroomEventPayload';
  errorCode?: Maybe<ClassroomEventErrorCodeEnum>;
  event?: Maybe<ClassroomEventType>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Update status of event response */
export type UpdateStatusOfClassroomEventResponse = {
  __typename?: 'UpdateStatusOfClassroomEventResponse';
  errorCode?: Maybe<ClassroomEventErrorCodeEnum>;
  event?: Maybe<ClassroomEventType>;
  success: Scalars['Boolean'];
};

export type UpdateSubTaskInput = {
  id?: InputMaybe<Scalars['String']>;
  instruction?: InputMaybe<TaskInstructionInput>;
  name: Scalars['String'];
};

export type UpdateSurveyInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Cover image for survey */
  cover?: InputMaybe<CreateMediaInput>;
  /** Cover offset */
  coverOffset?: InputMaybe<Scalars['Float']>;
  dbFields?: InputMaybe<UpsertDatabaseFieldsInput>;
  /** Description of the survey */
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  /** Survey Id */
  id: Scalars['String'];
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  /** Description of the survey */
  name?: InputMaybe<Scalars['String']>;
  publishConfig?: InputMaybe<PublishContentConfig>;
  selfDuration?: InputMaybe<Scalars['Float']>;
  /** Skill Ids */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  /** Publishing status of survey */
  status?: InputMaybe<ResourceStatusEnum>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  /** Tags related to quiz */
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Update Survey Payload */
export type UpdateSurveyPayload = {
  __typename?: 'UpdateSurveyPayload';
  survey?: Maybe<SurveyContentType>;
};

/** Input for updating externalContentPerformance */
export type UpdateSurveyPerformanceInput = {
  /** Database data input */
  databaseDataInput?: InputMaybe<UpsertDatabaseDataInput>;
  /** Is survey embedded inside course */
  isEmbed?: InputMaybe<Scalars['Boolean']>;
  /** survey performance status */
  status?: InputMaybe<PerformanceStatusEnum>;
  /** Id of the survey whose performance to be updated */
  surveyId: Scalars['String'];
  /** Id of the user whose performance to be updated */
  userId: Scalars['String'];
};

export type UpdateTagInput = {
  availableOn?: InputMaybe<Array<TagAvailableOnEnum>>;
  category?: InputMaybe<TagCategoryEnum>;
  dateTagProps?: InputMaybe<UpdateDateTagPropsInput>;
  description?: InputMaybe<Scalars['String']>;
  multiSelectTagProps?: InputMaybe<UpdateMultiSelectTagPropsInput>;
  name?: InputMaybe<Scalars['String']>;
  numberTagProps?: InputMaybe<UpdateNumberTagPropsInput>;
  settings?: InputMaybe<UpdateTagSettingsInput>;
  singleSelectTagProps?: InputMaybe<UpdateSingleSelectTagPropsInput>;
  source?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<TagSubTypeEnum>;
  subworkspaceIds?: InputMaybe<EntitySubworkspacesInput>;
  tagId: Scalars['String'];
  textTagProps?: InputMaybe<UpdateTextTagPropsInput>;
  type?: InputMaybe<TagTypeEnum>;
};

export type UpdateTagSettingsInput = {
  availableInLibrary?: InputMaybe<Scalars['Boolean']>;
  controlConfig?: InputMaybe<TagControlConfigInput>;
  disableAttaching?: InputMaybe<Scalars['Boolean']>;
  disableEditing?: InputMaybe<Scalars['Boolean']>;
  disableFiltering?: InputMaybe<Scalars['Boolean']>;
  disableGrouping?: InputMaybe<Scalars['Boolean']>;
  disableResetting?: InputMaybe<Scalars['Boolean']>;
  enableCrossWorkspaceSharing?: InputMaybe<Scalars['Boolean']>;
  iconSettings?: InputMaybe<IconSettingsInput>;
  requiredTag?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateTaskCreationConfigInput = {
  /** Type of config, manual or auto */
  externalEntityType?: InputMaybe<ActionItemExternalEntityTypeEnum>;
  /** provider with which this sync is created */
  providerId?: InputMaybe<Scalars['String']>;
  /** Searchtext, related query for the sync */
  searchText?: InputMaybe<Scalars['String']>;
  /** Id of the config to update */
  taskCreationConfigId: Scalars['String'];
  /** Type of config, manual or auto */
  taskCreationType?: InputMaybe<TaskCreationConfigTypeEnum>;
};

export type UpdateTaskInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  /** Storing media of task-cover */
  cover?: InputMaybe<CreateMediaInput>;
  duration: Scalars['Float'];
  instruction?: InputMaybe<TaskInstructionInput>;
  maintainDraftState?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  publishConfig?: InputMaybe<PublishContentConfig>;
  subTasks?: InputMaybe<Array<UpdateSubTaskInput>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<EntityTagsInput>;
  taskId: Scalars['String'];
};

export type UpdateTaskPayload = {
  __typename?: 'UpdateTaskPayload';
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
  task?: Maybe<TaskContentType>;
};

export type UpdateTextTagPropsInput = {
  textType: TextTagTypeEnum;
};

export type UpdateTrackInput = {
  /** description of the track */
  description?: InputMaybe<Scalars['String']>;
  /** input for adding, removing or creating metrics inside a track */
  metrics?: InputMaybe<UpdateTrackMetricsInput>;
  /** input for adding, removing or creating mindsets inside a track */
  mindsets?: InputMaybe<UpdateTrackMindsetsInput>;
  /** name of the track */
  name?: InputMaybe<Scalars['String']>;
  /** input for removing, creating or editing skillGroups inside a track */
  skillGroups?: InputMaybe<UpdateTrackSkillGroupsInput>;
  /** input for adding, removing or creating skills inside a track */
  skills?: InputMaybe<UpdateTrackSkillsInput>;
  /** Id of the track */
  trackId: Scalars['String'];
};

export type UpdateTrackMetricsInput = {
  /** ids of activities to add in track */
  add?: InputMaybe<Array<Scalars['String']>>;
  /** ids of activities to remove from track */
  remove?: InputMaybe<Array<Scalars['String']>>;
  /** activity metrics to be created and added to track */
  toCreateMetrics?: InputMaybe<Array<CreateMetricInput>>;
};

export type UpdateTrackMindsetsInput = {
  /** ids of mindsets to add in track */
  add?: InputMaybe<Array<Scalars['String']>>;
  /** ids mindsets to remove from track */
  remove?: InputMaybe<Array<Scalars['String']>>;
  /** mindsets to be created and added to track */
  toCreateMindsets?: InputMaybe<Array<CreateSkillInput>>;
};

export type UpdateTrackPayload = {
  __typename?: 'UpdateTrackPayload';
  createdMetrics?: Maybe<Array<MetricSchema>>;
  createdMindsets?: Maybe<Array<SkillType>>;
  createdSkillGroups?: Maybe<Array<SkillGroupType>>;
  createdSkills?: Maybe<Array<SkillType>>;
  success?: Maybe<Scalars['Boolean']>;
  track?: Maybe<TrackType>;
};

export type UpdateTrackSkillGroupsInput = {
  /** skillIds to be removed from track along with corresponding skillGroupIds */
  remove?: InputMaybe<Array<Scalars['String']>>;
  /** input for creating skillGroup from inside a track */
  toCreateSkillGroups?: InputMaybe<Array<CreateSkillGroupInTrackInput>>;
  /** input for editing skillGroup name from inside a track */
  toEditSkillGroups?: InputMaybe<Array<EditSkillGroupInTrackInput>>;
};

export type UpdateTrackSkillsInput = {
  /** skillIds to be added in track along with corresponding skillGroupIds */
  add?: InputMaybe<Array<AddSkillsToSkillGroupInput>>;
  /** skillIds to be removed from track along with corresponding skillGroupIds */
  remove?: InputMaybe<Array<RemoveSkillsFromSkillGroupInput>>;
  /** input for creating skill from inside a track. The created skill will be attached to track and added to skillBank */
  toCreateSkills?: InputMaybe<Array<CreateSkillInTrackInput>>;
};

export type UpdateTransactionInput = {
  _id?: InputMaybe<Scalars['String']>;
  paymentIntentId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  status: Array<TransactionPaymentStatusEnum>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateUserCollectionGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  groupId: Scalars['String'];
  memberIds?: InputMaybe<GroupMembersIds>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateUserGroupRoleInput = {
  groupId: Scalars['String'];
  roleId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type UpdateUserInput = {
  alias?: InputMaybe<Scalars['String']>;
  /** Cover image of the user */
  cover?: InputMaybe<CreateMediaInput>;
  emailId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  groupIds?: InputMaybe<UpdateGroupsOfUserInput>;
  /** User hierarchy updated at */
  hierarchyPathUpdatedAt?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastActiveAt?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orgRoleId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<UpdateUserPreferencesInput>;
  profilePicture?: InputMaybe<Scalars['String']>;
  reportsTo?: InputMaybe<ReportsToInput>;
  reportsToUserId?: InputMaybe<Scalars['String']>;
  /** users social accounts info */
  socials?: InputMaybe<UpdateUserSocialAccountsInput>;
  status?: InputMaybe<UserStatusEnum>;
  tags?: InputMaybe<EntityTagsInput>;
  timezone?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export enum UpdateUserInviteAction {
  Cancel = 'CANCEL',
  Resend = 'RESEND',
}

export type UpdateUserInviteInput = {
  action: UpdateUserInviteAction;
  userId: Scalars['String'];
};

export type UpdateUserPreferencesInput = {
  set?: InputMaybe<SetUserPreferencesInput>;
};

export type UpdateUserSkillSummaryInput = {
  /** Aggregate of scores from different sources */
  overallScore?: InputMaybe<SkillScoreInput>;
  /** calculated Scores */
  scores?: InputMaybe<Array<SkillScoreInput>>;
  /** skill on which the summary is created */
  skillId: Scalars['String'];
  /** userId for whom the summary is created */
  userId: Scalars['String'];
};

export type UpdateUserSkillSummaryPayload = {
  __typename?: 'UpdateUserSkillSummaryPayload';
  success?: Maybe<Scalars['Boolean']>;
  userSkillSummary?: Maybe<UserSkillSummary>;
};

export type UpdateUserSocialAccountsInput = {
  set: Array<UserSocialAccountInfoInput>;
};

export type UpdateUserType = {
  hierarchyPath?: InputMaybe<Scalars['String']>;
  hierarchyPathUpdatedAt?: InputMaybe<Scalars['String']>;
  lastActiveAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<UserPropertiesInput>;
  status?: InputMaybe<UserStatusEnum>;
  tagIdsToRemove?: InputMaybe<Array<Scalars['String']>>;
  tagsToSetAddRemove?: InputMaybe<EntityTagsInput>;
  userId: Scalars['String'];
};

export type UpdateUsersInput = {
  users: Array<UpdateUserType>;
};

/** This is the input for update users performance flag */
export type UpdateUsersPerformanceFlagInput = {
  entityId: Scalars['String'];
  flagConfigurations: Array<FlagConfigurations>;
};

/** Update Users Performance Flag Response */
export type UpdateUsersPerformanceFlagPayload = {
  __typename?: 'UpdateUsersPerformanceFlagPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateUsersStaffRolesInput = {
  roleIds: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
};

export type UpdateWorkspaceAdministrationConfigInput = {
  set: SetWorkspaceAdministrationConfigInput;
};

export type UpdateWorkspaceAuthConfigInput = {
  authPageUrl?: InputMaybe<Scalars['String']>;
  background?: InputMaybe<AuthBackgroundInput>;
  restrictedSignUp?: InputMaybe<Scalars['Boolean']>;
  signUpAllowed?: InputMaybe<Scalars['Boolean']>;
  strategyMethods?: InputMaybe<UpdateWorkspaceStrategyMethodsInput>;
  strategyMethodsListing?: InputMaybe<UpdateWorkspaceStrategyMethodsListingInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkspaceInput = {
  administrationConfig?: InputMaybe<UpdateWorkspaceAdministrationConfigInput>;
  authConfig?: InputMaybe<UpdateWorkspaceAuthConfigInput>;
  samlData?: InputMaybe<UpdateSamlDataInput>;
  siteConfig?: InputMaybe<UpdateWorkspaceSiteConfigInput>;
  subDomain?: InputMaybe<Scalars['String']>;
  whitelistedSignUpDomains?: InputMaybe<Array<Scalars['String']>>;
  workspaceId: Scalars['String'];
};

export type UpdateWorkspaceSiteConfigInput = {
  favicon?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Media>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkspaceStrategyMethodsInput = {
  set: Array<SetWorkspaceStrategyMethodInput>;
  unset: Array<UnsetWorkspaceStrategyMethodInput>;
};

export type UpdateWorkspaceStrategyMethodsListingInput = {
  set: Array<Scalars['String']>;
};

export type UpdatedChildOkrOrder = {
  childOrder: Scalars['Float'];
  okrId: Scalars['String'];
};

export type UpsertAgendaItemInput = {
  agendaItemId?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<MediasSetInput>;
  creationCadenceConfig?: InputMaybe<UpdateCadenceConfigInput>;
  entity: AgendaItemEntityInput;
  /** Agenda Instructions in text or media */
  instruction?: InputMaybe<InstructionInput>;
  isChecked?: InputMaybe<Scalars['Boolean']>;
  subType: ActionItemSubTypeEnum;
  title?: InputMaybe<Scalars['String']>;
};

/** Complete Block object to persist */
export type UpsertBlockInput = {
  /** Attributes of the block */
  attributes: Scalars['String'];
  /** Type of the block */
  blockType?: InputMaybe<Scalars['String']>;
  /** Child block ids in order of their position. If provided empty, it would replace all the children */
  childrenIds?: InputMaybe<Array<Scalars['String']>>;
  /** All the inline blocks which cannot be further divided */
  decoratedChildBlocks?: InputMaybe<Scalars['String']>;
  /** Duration for block */
  duration?: InputMaybe<Scalars['Float']>;
  /** Block's id */
  id: Scalars['String'];
  /** Is the block content optional for the learner */
  isOptional?: InputMaybe<Scalars['Boolean']>;
  /** Markup details of styling and comments */
  markups?: InputMaybe<Scalars['String']>;
  /** Parent entity's id */
  parentId: Scalars['String'];
  /** Parent entity's type or table */
  parentType: Scalars['String'];
  /** RootId to store the block Id of the top most block in the hierarchy */
  rootId: Scalars['String'];
  /** Root Block's type or table */
  rootType: Scalars['String'];
  /** Text content that might be present in the block */
  text?: InputMaybe<Scalars['String']>;
};

/** Response object for failed upsert requests */
export type UpsertBlockPayload = {
  __typename?: 'UpsertBlockPayload';
  /** Attributes of the block */
  attributes: Scalars['String'];
  /** Type of the block */
  blockType?: Maybe<Scalars['String']>;
  /** Child block ids in order of their position. If provided empty, it would replace all the children */
  childrenIds?: Maybe<Array<Scalars['String']>>;
  /** All the inline blocks which cannot be further divided */
  decoratedChildBlocks?: Maybe<Scalars['String']>;
  /** Reading duration of block */
  duration?: Maybe<Scalars['Float']>;
  /** Block's id */
  id: Scalars['String'];
  /** Markup details of styling and comments */
  markups?: Maybe<Scalars['String']>;
  /** Parent entity's id */
  parentId: Scalars['String'];
  /** Parent entity's type or table */
  parentType: Scalars['String'];
  /** RootId to store the block Id of the top most block in the hierarchy */
  rootId: Scalars['String'];
  /** Root Block's type or table */
  rootType: Scalars['String'];
  /** Text content that might be present in the block */
  text?: Maybe<Scalars['String']>;
};

/** Input to update the block */
export type UpsertBlocksInput = {
  /** Batch create/update blocks. */
  blocks?: InputMaybe<Array<UpsertBlockInput>>;
};

/** Returns list of blocks which were affected */
export type UpsertBlocksPayload = {
  __typename?: 'UpsertBlocksPayload';
  blocks?: Maybe<Array<UpsertBlockPayload>>;
  /** list of failed blocks. */
  failedBlocks?: Maybe<Array<UpsertBlockPayload>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpsertCategoryInput = {
  _id?: InputMaybe<Scalars['String']>;
  calculationType: CategoryCalculationTypeEnum;
  classroomId: Scalars['String'];
  name: Scalars['String'];
  status: CategoryStatusTypeEnum;
  systemGenerated?: InputMaybe<Scalars['Boolean']>;
  weight: Scalars['Float'];
};

export type UpsertClassroomSettingInput = {
  /** Categories associated to the gradeBook settings */
  categories?: InputMaybe<Array<ClassroomSettingCategoryInput>>;
  classroomId: Scalars['String'];
  /** GradeBook setting input type */
  gradeBookSettings: GradeBookSettingInput;
};

export type UpsertCommunityInput = {
  /** id of community to update */
  communityId: Scalars['String'];
  /** sections of community */
  sections: Array<SectionInfoInput>;
};

export type UpsertCommunityPayload = {
  __typename?: 'UpsertCommunityPayload';
  /** Community Payload */
  community: CommunityType;
};

export type UpsertCourseInput = {
  assignmentRestriction?: InputMaybe<AssignmentRestrictionInput>;
  /** list of all categories attached to the content */
  categoryIds?: InputMaybe<CategoryIdsInput>;
  certificateSettings?: InputMaybe<CertificateSettingsInput>;
  courseId?: InputMaybe<Scalars['String']>;
  /** Storing media of course-cover */
  cover?: InputMaybe<CreateMediaInput>;
  description?: InputMaybe<Scalars['String']>;
  dripSettings?: InputMaybe<DripSettingsInput>;
  /** duration of content */
  duration?: InputMaybe<Scalars['Float']>;
  /** external and internal instructors of course */
  instructors?: InputMaybe<Array<CourseInstructorInputType>>;
  /** level of course */
  level?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** outline of course containing sections with lessions */
  outline?: InputMaybe<CourseOutlineInput>;
  /** Ids of prerequisites related to the content */
  prerequisiteIds?: InputMaybe<Array<Scalars['String']>>;
  /** Storing media of course-preview */
  preview?: InputMaybe<CreateMediaInput>;
  /** self duration of content */
  selfDuration?: InputMaybe<Scalars['Float']>;
  /** Ids of skill related to the course */
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  subWorkspaceIds?: InputMaybe<Array<Scalars['String']>>;
  /** Tagline for course */
  tagline?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<EntityTagsInput>;
  visibilitySettings?: InputMaybe<VisibilitySettingsInput>;
};

/** Upsert Course-Content Response */
export type UpsertCoursePayload = {
  __typename?: 'UpsertCoursePayload';
  course?: Maybe<CourseContentType>;
  /** Error code */
  errorCode?: Maybe<ResourceErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input for updating/creating a document inside a database */
export type UpsertDatabaseDataInput = {
  dbId: Scalars['String'];
  documentId?: InputMaybe<Scalars['String']>;
  fields: Array<DocumentFieldsInput>;
};

export type UpsertDatabaseDataPayload = {
  __typename?: 'UpsertDatabaseDataPayload';
  /** Document Id */
  documentId?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input for updating/creating document fields inside a database */
export type UpsertDatabaseFieldsInput = {
  upsertDatabaseMetadataFields?: InputMaybe<UpsertDatabaseMetadataFieldsInput>;
  upsertDatabaseViewFields?: InputMaybe<UpsertDatabaseViewFieldsInput>;
};

export type UpsertDatabaseFieldsPayload = {
  __typename?: 'UpsertDatabaseFieldsPayload';
  /** Updated Database */
  dbMetadata?: Maybe<DatabaseMetadataType>;
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  /** Returns upserted View */
  view?: Maybe<DatabaseViewType>;
};

/** Defines fields to be added in database metadata */
export type UpsertDatabaseMetadataFieldsInput = {
  dbId?: InputMaybe<Scalars['String']>;
  dbMetadataId?: InputMaybe<Scalars['String']>;
  fields: Array<UpdateDbMetadataFields>;
};

/**  Defines fields to be added in database view */
export type UpsertDatabaseViewFieldsInput = {
  fields: Array<DatabaseViewFormatFields>;
  viewId: Scalars['String'];
};

export type UpsertEventPerformancesInput = {
  performances: Array<EventPerformanceInput>;
};

/** Input to update an Homework */
export type UpsertHomeworkInput = {
  advanceSettings: HomeworkAdvanceSettingsInput;
  /** defines the response type of Homework */
  allowedResponseTypes: Array<ResourceResponseTypeEnum>;
  categoryId: Scalars['String'];
  /** Tells the Homework is connected to which classroom */
  classroomId: Scalars['String'];
  enrolmentSettings: HomeworkEnrolmentSettingsInput;
  gradeSettings: HomeworkGradeSettingsInput;
  instruction?: InputMaybe<HomeworkInstructionInput>;
  name: Scalars['String'];
  resourceId?: InputMaybe<Scalars['String']>;
};

export type UpsertHomeworkPayload = {
  __typename?: 'UpsertHomeworkPayload';
  errorCode?: Maybe<ErrorCodeEnum>;
  resource?: Maybe<HomeworkType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpsertIntegrationDetailInput = {
  authSettings?: InputMaybe<Scalars['String']>;
  connectedAt?: InputMaybe<Scalars['String']>;
  connectedById?: InputMaybe<Scalars['String']>;
  integrationProviderId: Scalars['String'];
  scope?: InputMaybe<IntegrationScopeEnum>;
  settings?: InputMaybe<IntegrationSettingsInput>;
  status?: InputMaybe<IntegrationStatusEnum>;
  userId?: InputMaybe<Scalars['String']>;
};

export enum UpsertOkrCommandEnum {
  Create = 'CREATE',
  Update = 'UPDATE',
}

export type UpsertOkrConfigInputProps = {
  createKeyResultInput?: InputMaybe<CreateKeyResultInput>;
  updateKeyResultInput?: InputMaybe<UpdateKeyResultInput>;
};

export type UpsertOkrInput = {
  command: UpsertOkrCommandEnum;
  inputs: UpsertOkrConfigInputProps;
};

export type UpsertPageInput = {
  components: SetPageComponentsInput;
  type: PageTypeEnum;
};

export type UpsertPagePayload = {
  __typename?: 'UpsertPagePayload';
  page: PageType__Next;
};

/** Product creation/updation input */
export type UpsertProductInput = {
  /** Represents SEO settings like pageTitle, productDescription, keywords */
  SEOSettings?: InputMaybe<SeoSettings>;
  /** Represents content in a product */
  content?: InputMaybe<Content>;
  /** Description of a product */
  description?: InputMaybe<Scalars['String']>;
  /** Represents generalSettings */
  generalSettings?: InputMaybe<GeneralSettings>;
  /** ProductId of the product to publish */
  id?: InputMaybe<Scalars['String']>;
  /** Represents media in a product */
  media: Array<Media>;
  /** Name or title of a product */
  name?: InputMaybe<Scalars['String']>;
  /** Represents pricing parameters like price, curreny, discount, validity, coupon */
  pricingParameters?: InputMaybe<PricingParameters>;
  /** ids of the product. Represents which products are added inside the collection */
  productIds?: InputMaybe<Array<Scalars['String']>>;
  /** Product Status */
  status: ProductStatusEnum;
  /** StoreId of store to which this product belongs to */
  storeId: Scalars['String'];
  /** Product Type */
  type: ProductTypeEnum;
};

/** Upsert Product Response */
export type UpsertProductPayload = {
  __typename?: 'UpsertProductPayload';
  errorCode?: Maybe<ProductErrorCodeEnum>;
  product?: Maybe<ProductType>;
  success: Scalars['Boolean'];
};

export type UpsertResourceUserInteractionInput = {
  operation: ResourceUserInteractionOperation;
  resourceId: Scalars['String'];
  resourceType?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type UpsertResourceUserInteractionPayload = {
  __typename?: 'UpsertResourceUserInteractionPayload';
  resourceUserInteraction?: Maybe<ResourceUserInteractionType>;
};

export type UpsertScorecardReviewInput = {
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  feedbackText?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
  response?: InputMaybe<Scalars['Float']>;
  scorecardId: Scalars['String'];
  submissionId: Scalars['String'];
  userId: Scalars['String'];
};

export type UpsertScorecardReviewPayload = {
  __typename?: 'UpsertScorecardReviewPayload';
  currentReviewingState?: Maybe<CurrentReviewingState>;
  /** Error code */
  errorCode?: Maybe<PerformanceInteractionErrorCodeEnum>;
  scorecardReview?: Maybe<ScorecardReviewType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpsertStatusInput = {
  applicableOn?: InputMaybe<StatusApplicableOnEnum>;
  category?: InputMaybe<StatusCategoryEnum>;
  /** id of the default status from which current status is created */
  defaultStatusId?: InputMaybe<Scalars['String']>;
  /** Status on which EntityId to be applicable  */
  entityId?: InputMaybe<Scalars['String']>;
  /** Status on which EntityId to be applicable  */
  entityType?: InputMaybe<CustomStatusEntityTypeEnum>;
  icon?: InputMaybe<IconSettingsSetInput>;
  name?: InputMaybe<Scalars['String']>;
  /** Stores the order of status */
  order?: InputMaybe<Scalars['Float']>;
  progress?: InputMaybe<Scalars['Float']>;
  statusId?: InputMaybe<Scalars['String']>;
};

export type UpsertStatusesInput = {
  statuses: Array<UpsertStatusInput>;
};

export type UpsertUserActivityMetricInput = {
  activityId?: InputMaybe<Scalars['String']>;
  actualValue: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['String']>;
  createdById?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  metricId: Scalars['String'];
  metricMeta?: InputMaybe<Array<UserActivityMetricParentDocMetaInput>>;
  notes?: InputMaybe<Scalars['String']>;
  parentDocumentConfig?: InputMaybe<UserActivityMetricParentDocInputConfig>;
  startTime?: InputMaybe<Scalars['String']>;
  targetValue: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedById?: InputMaybe<Scalars['String']>;
  userActivityMetricId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
  weight?: InputMaybe<Scalars['Float']>;
};

export type UpsertUserActivityMetricsInput = {
  upsertUserActivityMetrics: Array<UpsertUserActivityMetricInput>;
};

export type UpsertUserGroupsInput = {
  /** userId, groupId, roleId configurations to be upserted */
  userGroups: Array<UserGroupRoleConfigs>;
};

export type UpsertUserViewInput = {
  applyToAll: Scalars['Boolean'];
  entityId: Scalars['String'];
  entityType: UserViewEntityTypeEnum;
  fields: Array<UserViewFieldsInputSchema>;
  userViewId?: InputMaybe<Scalars['String']>;
  viewType: UserViewTypeEnum;
};

export type UpsertWidgetsInput = {
  createOrUpdateWidgetsInput: Array<CreateOrUpdateWidgetInput>;
};

export type UpsertWidgetsPayload = {
  __typename?: 'UpsertWidgetsPayload';
  success?: Maybe<Scalars['Boolean']>;
  widgetIds: Array<Scalars['String']>;
};

export type UrlsSetInput = {
  /** Urls to be set */
  set: Array<Scalars['String']>;
};

export type UrlsV2SetInput = {
  /** Urls to be set */
  set: Array<PostUrlInput>;
};

export enum UserActionEnum {
  ForceComplete = 'FORCE_COMPLETE',
  ResetProgress = 'RESET_PROGRESS',
}

/** Base Performance Type */
export type UserActionMetaType = {
  __typename?: 'UserActionMetaType';
  action: UserActionEnum;
  meta?: Maybe<Scalars['JSON']>;
  timestamp: Scalars['String'];
  /** Id of the user who performed the action */
  userId: Scalars['String'];
};

export type UserActivitiesFilters = {
  activityTimestampRange?: InputMaybe<Array<Scalars['String']>>;
  activityTypes?: InputMaybe<Array<UserActivityTypeEnum>>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum UserActivityCategoryEnum {
  InSession = 'IN_SESSION',
}

/** Edge */
export type UserActivityEdge = {
  __typename?: 'UserActivityEdge';
  node: UserActivityType;
};

export enum UserActivityEntityTypeEnum {
  Classroom = 'CLASSROOM',
  Event = 'EVENT',
  Resource = 'RESOURCE',
}

export enum UserActivityErrorCodeEnum {
  AlreadyExists = 'ALREADY_EXISTS',
}

/** Edge */
export type UserActivityMetricEdge = {
  __typename?: 'UserActivityMetricEdge';
  node: UserActivityMetricType;
};

export type UserActivityMetricFilters = {
  userActivityMetricId: Scalars['String'];
};

export type UserActivityMetricParentDocConfig = {
  __typename?: 'UserActivityMetricParentDocConfig';
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<AutomaticMetricTypeEnum>;
  meta?: Maybe<Array<UserActivityMetricParentDocMetaType>>;
};

export type UserActivityMetricParentDocInputConfig = {
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<AutomaticMetricTypeEnum>;
  meta?: InputMaybe<Array<UserActivityMetricParentDocMetaInput>>;
};

export type UserActivityMetricParentDocMetaInput = {
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type UserActivityMetricParentDocMetaType = {
  __typename?: 'UserActivityMetricParentDocMetaType';
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type UserActivityMetricPayload = {
  __typename?: 'UserActivityMetricPayload';
  userActivityMetric?: Maybe<UserActivityMetricType>;
};

/** Edge */
export type UserActivityMetricScoreEdge = {
  __typename?: 'UserActivityMetricScoreEdge';
  node: UserActivityMetricScoreSchema;
};

export type UserActivityMetricScoreFilters = {
  userActivityMetricScoreId: Scalars['String'];
};

export type UserActivityMetricScorePayload = {
  __typename?: 'UserActivityMetricScorePayload';
  userActivityMetricScore?: Maybe<UserActivityMetricScoreSchema>;
};

export type UserActivityMetricScoreSchema = {
  __typename?: 'UserActivityMetricScoreSchema';
  activityMetricScore: Scalars['Float'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  orgRoleId?: Maybe<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type UserActivityMetricScoresFilters = {
  createdAt?: InputMaybe<DateFilter>;
  userActivityMetricScoreIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UserActivityMetricType = {
  __typename?: 'UserActivityMetricType';
  activityId?: Maybe<Scalars['String']>;
  actualValue: Scalars['Float'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  metricId: Scalars['String'];
  metricMeta?: Maybe<Array<UserActivityMetricParentDocMetaType>>;
  /** Log progress notes for Habit type metrics */
  notes?: Maybe<Scalars['String']>;
  parentDocumentConfig?: Maybe<UserActivityMetricParentDocConfig>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  startTime?: Maybe<Scalars['String']>;
  targetValue: Scalars['Float'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type UserActivityMetricsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  endTime?: InputMaybe<DateFilter>;
  endTimeExists?: InputMaybe<Scalars['Boolean']>;
  metricIds?: InputMaybe<Array<Scalars['String']>>;
  startTime?: InputMaybe<DateFilter>;
  userActivityMetricIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum UserActivityParentEntityTypeEnum {
  Classroom = 'CLASSROOM',
  Event = 'EVENT',
}

export enum UserActivityRootEntityTypeEnum {
  Classroom = 'CLASSROOM',
  Event = 'EVENT',
}

/** User's activity tracking document type */
export type UserActivityType = {
  __typename?: 'UserActivityType';
  /** Category to which this activity belongs */
  activityCategory: UserActivityCategoryEnum;
  /** Type of activity */
  activityType: UserActivityTypeEnum;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  /** Nearest level entity on which this activity occurred */
  entityId: Scalars['String'];
  /** Entity type of the nearest level entity on which this activity occurred */
  entityType: Scalars['String'];
  id: Scalars['String'];
  /** Parent entity's id of the entityId field */
  parentEntityId: Scalars['String'];
  /** Parent entity type of the entityId field */
  parentEntityType: Scalars['String'];
  /** Root entity's id of the entity chain */
  rootEntityId: Scalars['String'];
  /** Root entity's Type of the entity chain */
  rootEntityType: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** Actor for which this activity is recorded against */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum UserActivityTypeEnum {
  EndSession = 'END_SESSION',
  JoinSession = 'JOIN_SESSION',
  LeftSession = 'LEFT_SESSION',
  StartSession = 'START_SESSION',
}

/** User Affiliations */
export type UserAffiliations = {
  __typename?: 'UserAffiliations';
  customerId?: Maybe<Scalars['String']>;
  type: AffiliationsTypeEnum;
  userId?: Maybe<Scalars['String']>;
};

/** Aggregated points of a user in classroom */
export type UserBehaviorStatsType = {
  __typename?: 'UserBehaviorStatsType';
  /** Behavior Id of the behavior for which points been awarded in a classroom  */
  behaviorId: Scalars['String'];
  /** Total number of times points has been awarded for the behavior */
  count: Scalars['Float'];
};

/** Latest time at which a user opened the notification bell icon */
export type UserBellNotification = {
  __typename?: 'UserBellNotification';
  lastResolvedNotificationTimestamp?: Maybe<Scalars['String']>;
};

export type UserBellNotificationSubscriptionInput = {
  /** operations to subscribe on */
  operations: Array<UserSubscriptionOperationEnum>;
  userTimezone?: InputMaybe<Scalars['String']>;
};

export type UserBellNotificationsSubscriptionPayload = {
  __typename?: 'UserBellNotificationsSubscriptionPayload';
  eventTriggeredAt: Scalars['String'];
  lastResolvedTimeStamp?: Maybe<Scalars['String']>;
  operation: UserSubscriptionOperationEnum;
  type: UserBellNotificationsSubscriptionPayloadTypeEnum;
  unreadNotificationsCount: Scalars['Float'];
  unresolvedNotificationsCount: Scalars['Float'];
  userNotification?: Maybe<BaseUserNotification>;
};

export enum UserBellNotificationsSubscriptionPayloadTypeEnum {
  AllNotificationsRead = 'ALL_NOTIFICATIONS_READ',
  BellNotificationAdded = 'BELL_NOTIFICATION_ADDED',
  BellNotificationUpdated = 'BELL_NOTIFICATION_UPDATED',
  LastResolvedUpdated = 'LAST_RESOLVED_UPDATED',
}

/** User Permission */
export type UserChannelPermissionType = {
  __typename?: 'UserChannelPermissionType';
  /** Action type */
  action: ChannelActionsEnum;
  /** Subject type */
  subject: ChannelSubjectsEnum;
};

/** User Permission */
export type UserClassroomEventPermissionType = {
  __typename?: 'UserClassroomEventPermissionType';
  /** Action type */
  action: ClassroomEventActionsEnum;
  /** Subject type */
  subject: ClassroomEventSubjectsEnum;
};

/** User Permission */
export type UserClassroomPermissionType = {
  __typename?: 'UserClassroomPermissionType';
  /** Action type */
  action: ClassroomActionsEnum;
  /** Subject type */
  subject: ClassroomSubjectsEnum;
};

export type UserClassroomsFilters = {
  classroomStatus: Array<ClassroomStatusEnum>;
  onboardStatus: Array<OnboardStatusEnum>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UserCollectionGroupPayload = {
  __typename?: 'UserCollectionGroupPayload';
  group: UserCollectionGroupType;
};

export type UserCollectionGroupPropsInput = {
  classroomId?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<UserCollectionGroupTypeEnum>;
};

export type UserCollectionGroupType = {
  __typename?: 'UserCollectionGroupType';
  classroomId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  filterGroup?: Maybe<FilterGroupV2Type>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  metricSnapshot?: Maybe<MetricSnapshot>;
  name: Scalars['String'];
  productId?: Maybe<Scalars['String']>;
  reSyncedAt?: Maybe<Scalars['String']>;
  sampleUsers: Array<UserType>;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: GroupStatusEnum;
  subType: UserCollectionGroupTypeEnum;
  type: GroupTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  usersCount: Scalars['Float'];
  versionNumber?: Maybe<Scalars['Float']>;
};

export type UserCollectionGroupTypeIsAutoSyncArgs = {
  userId: Scalars['String'];
};

export enum UserCollectionGroupTypeEnum {
  Admin = 'ADMIN',
  Author = 'AUTHOR',
  Custom = 'CUSTOM',
  Learner = 'LEARNER',
  LyearnUser = 'LYEARN_USER',
  Staff = 'STAFF',
}

/** This returns paginated users */
export type UserConnection = {
  __typename?: 'UserConnection';
  edges?: Maybe<Array<UserEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Filters applicable on user cycle review */
export type UserCycleReviewFilters = {
  reviewCycleId: Scalars['String'];
};

/** UserCycleReviewPayload */
export type UserCycleReviewPayload = {
  __typename?: 'UserCycleReviewPayload';
  exists: Scalars['Boolean'];
};

/** Edge */
export type UserEdge = {
  __typename?: 'UserEdge';
  node: UserType;
};

export type UserEmailIdSetInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type UserEntityPerformanceSubscriptionInput = {
  /** entityIds of performance */
  entityIds: Array<Scalars['String']>;
  /** userId of performance */
  userId: Scalars['String'];
};

/** User Event Permission */
export type UserEventPermissionType = {
  __typename?: 'UserEventPermissionType';
  /** Action type */
  action: EventActions;
  /** Subject type */
  subject: EventSubjects;
};

/** Filters applicable on user */
export type UserFilters = {
  crossSubWorkspace?: InputMaybe<Scalars['Boolean']>;
  /** Filter user based on its email id */
  emailId?: InputMaybe<Scalars['String']>;
  /** Filter user based on its federation id */
  federationId?: InputMaybe<Scalars['String']>;
  /** Filter user based on any of the users unique id */
  uniqueId?: InputMaybe<UniqueIdFilter>;
  /** Filter user based on its id */
  userId?: InputMaybe<Scalars['String']>;
};

export type UserGroupConfigs = {
  /** groupId of the userGroup */
  groupId: Scalars['String'];
  /** userId of the userGroup */
  userId: Scalars['String'];
};

export type UserGroupConnections = {
  __typename?: 'UserGroupConnections';
  edges?: Maybe<Array<UserGroupEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type UserGroupEdge = {
  __typename?: 'UserGroupEdge';
  node: UserGroupType;
};

export type UserGroupFilters = {
  /** filter on properties of groups, incase groupIds are also provided, an intersection will be taken */
  groupFilters?: InputMaybe<GroupLevelUserGroupFilter>;
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  /** filter on properties of users, incase userIds are also provided, an intersection will be taken */
  userFilters?: InputMaybe<UserLevelUserGroupFilter>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UserGroupMemberImportMetadataInput = {
  createdGroupsCount: Scalars['Float'];
};

export type UserGroupMemberImportMetadataType = {
  __typename?: 'UserGroupMemberImportMetadataType';
  createdGroupsCount: Scalars['Float'];
  updatedGroupsCount: Scalars['Float'];
};

export type UserGroupPayload = {
  __typename?: 'UserGroupPayload';
  userGroup?: Maybe<UserGroupType>;
};

export type UserGroupRoleConfigs = {
  /** groupId of userGroup */
  groupId: Scalars['String'];
  /** optional roleId if needed */
  roleId?: InputMaybe<Scalars['String']>;
  /** userId of userGroup */
  userId: Scalars['String'];
};

/** Filters applicable on userGroupRoles Field Resolver of user */
export type UserGroupRolesFilters = {
  /** GroupIds for which user role required */
  groupIds: Array<Scalars['String']>;
};

/** UserGroupRolesPayload */
export type UserGroupRolesPayload = {
  __typename?: 'UserGroupRolesPayload';
  accessLevel: AccessLevelEnum;
  groupId: Scalars['String'];
  roleId?: Maybe<Scalars['String']>;
};

export type UserGroupSyncAssignmentConfigs = {
  __typename?: 'UserGroupSyncAssignmentConfigs';
  autoAssignGroupUsers: Scalars['Boolean'];
  deactivateFrom?: Maybe<Scalars['String']>;
};

export type UserGroupSyncAssignmentConfigsInput = {
  autoAssignGroupUsers: Scalars['Boolean'];
  deactivateFrom?: InputMaybe<Scalars['String']>;
};

/** UserGroup Schema */
export type UserGroupType = {
  __typename?: 'UserGroupType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  group?: Maybe<UserCollectionGroupType>;
  groupId: Scalars['String'];
  id: Scalars['String'];
  roleId?: Maybe<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** The User model */
export type UserInfoType = {
  __typename?: 'UserInfoType';
  alias?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  emailId?: Maybe<Scalars['String']>;
  /** @deprecated Use name field */
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** @deprecated Use name field */
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** This returns the learning stats of user trainings */
export type UserLearningStats = {
  __typename?: 'UserLearningStats';
  certificatesEarnedCount: Scalars['Float'];
  completedTrainingsCount: Scalars['Float'];
  inProgressTrainingsCount: Scalars['Float'];
  notStartedTrainingsCount: Scalars['Float'];
};

export type UserLearningStatsInput = {
  /** emailId of the user for which learning stats are required */
  emailId?: InputMaybe<Scalars['String']>;
  /** Type of content-performances for which learning stats are required */
  types?: InputMaybe<Array<PerformanceTypeEnum>>;
  /** filter trainings of user */
  userFilter?: InputMaybe<UserFilters>;
  /** id of the user for which learning stats are required */
  userId?: InputMaybe<Scalars['String']>;
};

export type UserLevelUserGroupFilter = {
  searchText: Scalars['String'];
};

export type UserLibraryContentsFilters = {
  status: Array<ResourceStatusEnum>;
  /** get user trainings of contents containing a tag that matches the given tagFilters */
  tagFilters?: InputMaybe<Array<PublicTagFilter>>;
  /** If this filter is provided, only the selected resourceTypes will be returned, else all resourceTypes will be returned */
  types?: InputMaybe<Array<ResourceTypeEnum>>;
  /** filter library of user */
  userFilter?: InputMaybe<UserFilters>;
  /** id of the user whose trainings need to be fetched */
  userId?: InputMaybe<Scalars['String']>;
};

export type UserMasqueradingNotification = {
  __typename?: 'UserMasqueradingNotification';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type UserMentionUserNotification = {
  __typename?: 'UserMentionUserNotification';
  channelId: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mentionedAt: Scalars['String'];
  mentionedById: Scalars['String'];
  message: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parentType?: Maybe<NotificationActionTypeEnum>;
  quotedMessage?: Maybe<Scalars['String']>;
  readAt: Scalars['String'];
  redirectPath: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  status: UserNotificationStatusEnum;
  streamEventId?: Maybe<Scalars['String']>;
  type: NotificationActionTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userProfileIcon?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** Metadata for user fields */
export type UserMetadataInput = {
  emailId?: InputMaybe<Scalars['String']>;
  federationId: Scalars['String'];
  /** Firstname (space) Lastname of the user */
  name: Scalars['String'];
  userId: Scalars['String'];
};

/** UserNotificationsPayloadEdge */
export type UserNotificationPayloadEdge = {
  __typename?: 'UserNotificationPayloadEdge';
  node: BaseUserNotification;
};

export enum UserNotificationStatusEnum {
  Read = 'READ',
  Unread = 'UNREAD',
}

export type UserNotificationsFilters = {
  types?: InputMaybe<Array<NotificationActionTypeEnum>>;
  userTimezone?: InputMaybe<Scalars['String']>;
};

/** User OKR Cycle Permission */
export type UserOkrCyclePermissionType = {
  __typename?: 'UserOKRCyclePermissionType';
  /** Action type */
  action: OkrCycleActions;
  /** Subject type */
  subject: OkrCycleSubjects;
};

/** User Objective KeyResult Permission */
export type UserOkrPermissionType = {
  __typename?: 'UserOKRPermissionType';
  /** OKR Action type */
  action: OkrActions;
  /** OKR Subject type */
  subject: OkrSubjects;
};

/** Learner actions on onboard invitation to classroom */
export type UserOnboardClassroomInput = {
  classroomId: Scalars['String'];
  onboardStatus: OnboardingUserStatusEnum;
  userId: Scalars['String'];
};

export type UserOnboardClassroomPayload = {
  __typename?: 'UserOnboardClassroomPayload';
  errorCode?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  userIds?: Maybe<Array<Scalars['String']>>;
};

/** User Permission */
export type UserOrgRolePermissionType = {
  __typename?: 'UserOrgRolePermissionType';
  /** Action type */
  action: OrgRoleActionsEnum;
  /** Subject type */
  subject: OrgRoleSubjectsEnum;
};

export type UserPreferencesType = {
  __typename?: 'UserPreferencesType';
  showMarketplaceContentCatalogBanner?: Maybe<Scalars['Boolean']>;
};

/** User Product Permission */
export type UserProductPermissionType = {
  __typename?: 'UserProductPermissionType';
  /** Action type */
  action: ProductActionsEnum;
  /** Subject type */
  subject: ProductSubjectsEnum;
};

/** User Properties */
export type UserProfile = {
  __typename?: 'UserProfile';
  alias?: Maybe<Scalars['String']>;
  /** email id of the user */
  emailId?: Maybe<Scalars['String']>;
  /** federation id of the user */
  federationId?: Maybe<Scalars['String']>;
  /**
   * first name of the user
   * @deprecated Use name field
   */
  firstName?: Maybe<Scalars['String']>;
  /** id of the user */
  id: Scalars['String'];
  /** is user learner: true or false */
  isLearner: Scalars['Boolean'];
  isPerformanceReviewCompleted?: Maybe<Scalars['Boolean']>;
  /** is user staff: true or false */
  isStaff: Scalars['Boolean'];
  /**
   * last name of the user
   * @deprecated Use name field
   */
  lastName?: Maybe<Scalars['String']>;
  /** Full name of the user */
  name?: Maybe<Scalars['String']>;
  /** url of the profile picture of the user */
  profilePicture?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
};

/** User Properties */
export type UserProfileIsPerformanceReviewCompletedArgs = {
  filters?: InputMaybe<IsPerformanceReviewCompletedInput>;
};

/** User Properties */
export type UserPropertiesInput = {
  departmentId?: InputMaybe<Scalars['String']>;
  orgRoleId?: InputMaybe<Scalars['String']>;
  syncedFromIntegrationProviderId?: InputMaybe<Scalars['String']>;
  trackId?: InputMaybe<Scalars['String']>;
};

/** User Properties */
export type UserPropertiesType = {
  __typename?: 'UserPropertiesType';
  departmentId?: Maybe<Scalars['String']>;
  orgRoleId?: Maybe<Scalars['String']>;
  /** Integration provider id from where the user was synced in Lyearn */
  syncedFromIntegrationProviderId?: Maybe<Scalars['String']>;
  syncedFromProvider?: Maybe<IntegrationProviderType>;
  /** trackId of orgRole */
  trackId?: Maybe<Scalars['String']>;
};

/** User Resource Permission */
export type UserResourcePermissionType = {
  __typename?: 'UserResourcePermissionType';
  /** Action type */
  action: ResourceActions;
  /** Subject type */
  subject: ResourceSubjects;
};

export type UserRolePayload = {
  __typename?: 'UserRolePayload';
  roleId: Scalars['String'];
  user: UserInfoType;
};

export type UserRolesTypeInput = {
  set: Array<WorkspaceRoleTypeEnum>;
};

/** Filters for userSearchActivities of the context user */
export type UserSearchActivitiesFilters = {
  /** searchText to give relevant results */
  searchText?: InputMaybe<Scalars['String']>;
};

export type UserSearchActivityConnections = {
  __typename?: 'UserSearchActivityConnections';
  edges?: Maybe<Array<UserSearchActivityEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type UserSearchActivityEdge = {
  __typename?: 'UserSearchActivityEdge';
  node: UserSearchActivityType;
};

/** The User model */
export type UserSearchActivityType = {
  __typename?: 'UserSearchActivityType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** search text input of the user */
  searchText: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export enum UserSignupTypeEnum {
  Apple = 'APPLE',
  EmailPassword = 'EMAIL_PASSWORD',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Linkedin = 'LINKEDIN',
  PublicApi = 'PUBLIC_API',
  Saml = 'SAML',
  Sso = 'SSO',
}

/** User Permission */
export type UserSkillPermissionType = {
  __typename?: 'UserSkillPermissionType';
  /** Action type */
  action: SkillActionsEnum;
  /** Subject type */
  subject: SkillSubjectsEnum;
};

/** UserSkill Summary Schema */
export type UserSkillSummary = {
  __typename?: 'UserSkillSummary';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  id: Scalars['String'];
  /** Aggregate of scores from different sources */
  overallScore: SkillScore;
  /** Array of scores from different sources */
  scores?: Maybe<Array<SkillScore>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  /** Skill Id for which the summary is created */
  skillId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** UserId for whom this summary is created. */
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type UserSkillSummaryFilters = {
  /** SkillId of which the summary is required */
  skillId?: InputMaybe<Scalars['String']>;
  /** UserId whose skill summary needs to be fetched */
  userId?: InputMaybe<Scalars['String']>;
};

/** Filters applicable on userSkillReview */
export type UserSkillsReviewFilters = {
  reviewCycleId: Scalars['String'];
  reviewerId: Scalars['String'];
};

/** UserSkillsReviewPayload */
export type UserSkillsReviewPayload = {
  __typename?: 'UserSkillsReviewPayload';
  completed: Scalars['Boolean'];
};

export type UserSocialAccountInfo = {
  __typename?: 'UserSocialAccountInfo';
  link: Scalars['String'];
  type: UserSocialAccountTypeEnum;
};

export type UserSocialAccountInfoInput = {
  link: Scalars['String'];
  type: UserSocialAccountTypeEnum;
};

export enum UserSocialAccountTypeEnum {
  Github = 'GITHUB',
  Linkedin = 'LINKEDIN',
  Skype = 'SKYPE',
  Slack = 'SLACK',
}

export enum UserStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  EmailNotVerified = 'EMAIL_NOT_VERIFIED',
  Invited = 'INVITED',
}

export type UserSubscriptionInput = {
  /** operations to subscribe on */
  operations: Array<UserSubscriptionOperationEnum>;
};

export enum UserSubscriptionOperationEnum {
  UserArchived = 'USER_ARCHIVED',
  UserBellNotification = 'USER_BELL_NOTIFICATION',
  UserPasswordUpdateLogout = 'USER_PASSWORD_UPDATE_LOGOUT',
}

export type UserSubscriptionPayload = {
  __typename?: 'UserSubscriptionPayload';
  operation: UserSubscriptionOperationEnum;
  user: UserType;
};

export type UserTagType = {
  __typename?: 'UserTagType';
  availableInWorkspaces?: Maybe<Array<WorkspaceDetails>>;
  availableOn: Array<TagAvailableOnEnum>;
  category?: Maybe<TagCategoryEnum>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** dimension key for tag based reports */
  dimensionKeyMappings: Array<TagDimesionKeyMapping>;
  /** Field or property of external source to which the values of this tag is linked to. i.e. "status", "sprint" etc */
  externalLinkedEntityType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integrationProvider?: Maybe<IntegrationProviderType>;
  isExternal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  settings: TagSettingsType;
  shareConfigs?: Maybe<Array<ShareConfig>>;
  sharedAt?: Maybe<Scalars['String']>;
  sharedByWorkspace?: Maybe<WorkspaceDetails>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  sharedStatus?: Maybe<ShareConfigStatusEnum>;
  /** Format of string - entity/entityId , for ex - integrationDetail/integrationProviderId */
  source?: Maybe<Scalars['String']>;
  status: TagStatusEnum;
  /** Sub type of tag like UserType with single select or multi select  */
  subType?: Maybe<TagSubTypeEnum>;
  subWorkspaces?: Maybe<Array<Scalars['String']>>;
  /** field resolvers */
  taggedContentCount: Scalars['Float'];
  taggedEventCount: Scalars['Float'];
  taggedOKRCount: Scalars['Float'];
  taggedTaskCount: Scalars['Float'];
  taggedUserCount: Scalars['Float'];
  type: TagTypeEnum;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type UserTagTypeSharedAtArgs = {
  workspaceId: Scalars['String'];
};

export type UserTagTypeSharedStatusArgs = {
  workspaceId: Scalars['String'];
};

/** It will resolve exercise, homework, task, checkin, course, path, external content, event */
export type UserTraining = ContentUserTrainingType | EventUserTrainingType;

export enum UserTrainingTypeEnum {
  Content = 'CONTENT',
  Event = 'EVENT',
}

/** This returns userTrainings */
export type UserTrainings = {
  __typename?: 'UserTrainings';
  userTrainings: Array<UserTraining>;
};

/** This returns paginated userTrainings */
export type UserTrainingsConnection = {
  __typename?: 'UserTrainingsConnection';
  edges?: Maybe<Array<UserTrainingsEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type UserTrainingsEdge = {
  __typename?: 'UserTrainingsEdge';
  node: UserTraining;
};

export type UserTrainingsFilters = {
  /** emailId of the user for which trainings are required */
  emailId?: InputMaybe<Scalars['String']>;
  /** search by content name or event name */
  name?: InputMaybe<Scalars['String']>;
  status: Array<PerformanceStatusEnum>;
  /** get user trainings of contents containing a tag that matches the given tagFilters */
  tagFilters?: InputMaybe<Array<PublicTagFilter>>;
  /** If this filter is provided, only the selected performanceTypes will be returned, else all performanceTypes will be returned */
  types?: InputMaybe<Array<PerformanceTypeEnum>>;
  /** filter trainings of user */
  userFilter?: InputMaybe<UserFilters>;
  /** id of the user for which user trainings are required */
  userId?: InputMaybe<Scalars['String']>;
};

/** The User model */
export type UserType = {
  __typename?: 'UserType';
  affiliations?: Maybe<Array<UserAffiliations>>;
  alias?: Maybe<Scalars['String']>;
  /** Storing media of user cover */
  cover?: Maybe<EntityMediaType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  currentSubWorkspace?: Maybe<Scalars['String']>;
  cycleReview?: Maybe<UserCycleReviewPayload>;
  deletedAt?: Maybe<Scalars['String']>;
  department?: Maybe<DepartmentType>;
  emailId?: Maybe<Scalars['String']>;
  federationId?: Maybe<Scalars['String']>;
  /** @deprecated Use the field name */
  firstName?: Maybe<Scalars['String']>;
  /** User hierarchy */
  hierarchyPath?: Maybe<Scalars['String']>;
  /** User hierarchy updated at */
  hierarchyPathUpdatedAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAutoSync: Scalars['Boolean'];
  isInGroup: Scalars['Boolean'];
  isInStaffRole: Scalars['Boolean'];
  isLearner: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  lastActiveAt?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
  /** @deprecated Use the field name */
  lastName?: Maybe<Scalars['String']>;
  manager?: Maybe<UserType>;
  managerId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orgRole?: Maybe<OrgRoleType>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** user preferences */
  preferences?: Maybe<UserPreferencesType>;
  profilePicture?: Maybe<Scalars['String']>;
  /** profile picture media */
  profilePictureMedia?: Maybe<EntityMediaType>;
  properties?: Maybe<UserPropertiesType>;
  reportees?: Maybe<Array<UserType>>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  signupType: UserSignupTypeEnum;
  skillReview?: Maybe<UserSkillsReviewPayload>;
  /** user social accounts info */
  socials?: Maybe<Array<UserSocialAccountInfo>>;
  staffRoleIds?: Maybe<Array<Scalars['String']>>;
  staffRoles?: Maybe<Array<RoleType>>;
  status: UserStatusEnum;
  tag?: Maybe<EntityTagType>;
  tags?: Maybe<Array<EntityTagType>>;
  timezone?: Maybe<Scalars['String']>;
  uniqueId: Scalars['String'];
  unreadNotificationsCount: Scalars['Float'];
  unresolvedNotificationsCount: Scalars['Float'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userBellNotification?: Maybe<UserBellNotification>;
  userGroups: Array<UserCollectionGroupType>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  workspacePermissions: Array<UserWorkspacePermissionType>;
};

/** The User model */
export type UserTypeCycleReviewArgs = {
  filters?: InputMaybe<UserCycleReviewFilters>;
};

/** The User model */
export type UserTypeIsAutoSyncArgs = {
  groupId: Scalars['String'];
};

/** The User model */
export type UserTypeIsInGroupArgs = {
  groupId: Scalars['String'];
};

/** The User model */
export type UserTypeIsInStaffRoleArgs = {
  roleId: Scalars['String'];
};

/** The User model */
export type UserTypeSkillReviewArgs = {
  filters?: InputMaybe<UserSkillsReviewFilters>;
};

/** The User model */
export type UserTypeTagArgs = {
  tagId: Scalars['String'];
};

export enum UserTypeEnum {
  Learner = 'LEARNER',
  Staff = 'STAFF',
}

/** Edge */
export type UserViewEdge = {
  __typename?: 'UserViewEdge';
  node: UserViewSchema;
};

export enum UserViewEntityTypeEnum {
  File = 'FILE',
  Meeting = 'MEETING',
  Okr = 'OKR',
}

/** It will resolve different entities with user view is linked */
export type UserViewEntityUnion = FileSchema | KeyResultType | MeetingType | ObjectiveType;

export enum UserViewFieldEntityTypeEnum {
  Tag = 'TAG',
}

export type UserViewFieldSchema = {
  __typename?: 'UserViewFieldSchema';
  /** description of the field */
  description?: Maybe<Scalars['String']>;
  /** Is field editable */
  editable: Scalars['Boolean'];
  entity?: Maybe<TagUnion>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<UserViewFieldEntityTypeEnum>;
  /** IconSettings of the field */
  iconSettings?: Maybe<IconSettings>;
  /** label/shortId of the field */
  label: Scalars['String'];
  /** Name of the field */
  name: Scalars['String'];
  /** Order of the field */
  order: Scalars['Float'];
  /** Field Resolvers */
  provider?: Maybe<IntegrationProviderEnum>;
  tagEntitySettings?: Maybe<TagSettingsType>;
  /** Field visibility */
  visible: Scalars['Boolean'];
  /** Width of the field */
  width: Scalars['Float'];
};

export type UserViewFieldsInputSchema = {
  /** description of the field */
  description?: InputMaybe<Scalars['String']>;
  /** Is field editable */
  editable: Scalars['Boolean'];
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<UserViewFieldEntityTypeEnum>;
  /** IconSettings of the field */
  iconSettings?: InputMaybe<IconSettingsInput>;
  /** label/shortId of the field */
  label: Scalars['String'];
  /** Name of the field */
  name: Scalars['String'];
  /** Order of the field */
  order: Scalars['Float'];
  /** Field visibility */
  visible: Scalars['Boolean'];
  /** Width of the field */
  width: Scalars['Float'];
};

export type UserViewFilters = {
  userViewId: Scalars['String'];
};

export type UserViewPayload = {
  __typename?: 'UserViewPayload';
  userView?: Maybe<UserViewSchema>;
  viewEntity?: Maybe<UserViewEntityUnion>;
};

export type UserViewSchema = {
  __typename?: 'UserViewSchema';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  entityId: Scalars['String'];
  entityType: UserViewEntityTypeEnum;
  fields: Array<UserViewFieldSchema>;
  id: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
  viewType: UserViewTypeEnum;
};

export enum UserViewTypeEnum {
  Task = 'TASK',
}

export type UserViewsFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  entityTypes?: InputMaybe<Array<UserViewEntityTypeEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
  userViewIds?: InputMaybe<Array<Scalars['String']>>;
  viewTypes?: InputMaybe<Array<UserViewTypeEnum>>;
};

/** User Worskapce Permissions */
export type UserWorkspacePermissionType = {
  __typename?: 'UserWorkspacePermissionType';
  /** Action type */
  action: WorkspaceActions;
  /** Subject type */
  subject: WorkspaceSubjects;
};

export type UsersFilters = {
  createdAt?: InputMaybe<DateFilter>;
  createdAtTimestampRange?: InputMaybe<TimestampRangeInput>;
  crossSubWorkspace?: InputMaybe<Scalars['Boolean']>;
  departmentOrgRole?: InputMaybe<DepartmentOrgRoleFilter>;
  departmentOrgRoles?: InputMaybe<Array<DepartmentOrgRoleFilter>>;
  emailIds?: InputMaybe<Array<Scalars['String']>>;
  federationIds?: InputMaybe<Array<Scalars['String']>>;
  /** filter by field and tag properties */
  filterGroup?: InputMaybe<FilterGroupV2InputType>;
  /** get static or dynamic users for given groupIds */
  groupFilter?: InputMaybe<EntityGroupFilter>;
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  /** user hierarchy under which user will be searched */
  hierarchyPath?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  lastLogin?: InputMaybe<DateFilter>;
  /** get users which are not having emailIds */
  notInEmailIds?: InputMaybe<Array<Scalars['String']>>;
  orgRoleIds?: InputMaybe<Array<Scalars['String']>>;
  /** immediate parent of the user */
  parentId?: InputMaybe<Scalars['String']>;
  /** immediate parent of the user */
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  searchText?: InputMaybe<Scalars['String']>;
  staffRoleIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Array<UserStatusEnum>>;
  /** get users based on tags */
  tagFilters?: InputMaybe<Array<EntityTagInput>>;
  /** to be used with tagFilters input, current support for AND and OR */
  tagFiltersOperation?: InputMaybe<FilterOperatorEnum>;
  /** tag id of the user */
  tagIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter to fetch users based on either of the given uniqueIds */
  uniqueIds?: InputMaybe<UniqueIdsFilter>;
  updatedAt?: InputMaybe<DateFilter>;
  userTypes?: InputMaybe<Array<UserTypeEnum>>;
  workspaceRoleIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UsersFiltersType = {
  __typename?: 'UsersFiltersType';
  createdAt?: Maybe<DateFilterType>;
  createdAtTimestampRange?: Maybe<TimestampRangeType>;
  departmentOrgRole?: Maybe<DepartmentOrgRoleFilterType>;
  departmentOrgRoles?: Maybe<Array<DepartmentOrgRoleFilterType>>;
  emailIds?: Maybe<Array<Scalars['String']>>;
  federationIds?: Maybe<Array<Scalars['String']>>;
  /** get static or dynamic users for given groupIds */
  groupFilter?: Maybe<EntityGroupFilterType>;
  groupIds?: Maybe<Array<Scalars['String']>>;
  /** user hierarchy under which user will be searched */
  hierarchyPath?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['String']>>;
  orgRoleIds?: Maybe<Array<Scalars['String']>>;
  parentId?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  status?: Maybe<Array<UserStatusEnum>>;
  /** get users based on tags */
  tagFilters?: Maybe<Array<EntityTagType>>;
  /** tag id of the user */
  tagIds?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<DateFilterType>;
  userTypes?: Maybe<Array<UserTypeEnum>>;
  workspaceRoleIds?: Maybe<Array<Scalars['String']>>;
};

export enum UsersImportTypeEnum {
  AllUsers = 'ALL_USERS',
  SpecificGroups = 'SPECIFIC_GROUPS',
}

export type ValidateCurrentUserPasswordInput = {
  password: Scalars['String'];
};

/** Validation Input */
export type ValidationInput = {
  /** Maximum Length */
  maxLength?: InputMaybe<Scalars['Float']>;
  /** Maximum Selections */
  maxSelections?: InputMaybe<Scalars['Float']>;
  /** Maximum value */
  maxValue?: InputMaybe<Scalars['Float']>;
  /** Minimum Selections */
  minSelections?: InputMaybe<Scalars['Float']>;
  /** Minimum value */
  minValue?: InputMaybe<Scalars['Float']>;
};

/** Validations on Database fields */
export type ValidationType = {
  __typename?: 'ValidationType';
  /** Maximum Length */
  maxLength?: Maybe<Scalars['Float']>;
  /** Maximum Selections */
  maxSelections?: Maybe<Scalars['Float']>;
  /** Maximum value */
  maxValue?: Maybe<Scalars['Float']>;
  /** Minimum Selections */
  minSelections?: Maybe<Scalars['Float']>;
  /** Minimum value */
  minValue?: Maybe<Scalars['Float']>;
};

export enum VerificationMethodEnum {
  AnswerQuestions = 'ANSWER_QUESTIONS',
  AttachCertificate = 'ATTACH_CERTIFICATE',
  AutomaticSync = 'AUTOMATIC_SYNC',
  MakeSubmission = 'MAKE_SUBMISSION',
  NoVerification = 'NO_VERIFICATION',
}

export type VerificationMethodInputType = {
  /** Extra instruction for verification method */
  instruction?: InputMaybe<Scalars['String']>;
  /** Method to verify the completion of the external content */
  methodType: VerificationMethodEnum;
  /** Minimum required score percentage for Answer Questions verification method */
  minScorePercent?: InputMaybe<Scalars['Float']>;
  /** Question Ids for Answer Questions verification method */
  questionIds?: InputMaybe<Array<Scalars['String']>>;
  /** Submission type for Make a Submission verification method */
  submissionType?: InputMaybe<SubmissionTypeEnum>;
};

/** Properties of verification methods */
export type VerificationMethodType = {
  __typename?: 'VerificationMethodType';
  /** Extra instruction for Make a Submission verification method */
  instruction?: Maybe<Scalars['String']>;
  /** Method to verify the completion of the external content */
  methodType: VerificationMethodEnum;
  /** Minimum required score percentage for Answer Questions verification method */
  minScorePercent?: Maybe<Scalars['Float']>;
  /** Question Ids for Answer Questions verification method */
  questionIds?: Maybe<Array<Scalars['String']>>;
  /** Submission type for Make a Submission verification method */
  submissionType?: Maybe<SubmissionTypeEnum>;
};

/** Contains information regarding the version of the content */
export type VersionConfig = {
  __typename?: 'VersionConfig';
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rootBlockId: Scalars['String'];
  status: ResourceStatusEnum;
};

/** Input for updating the timestamp of user's last visit at the channel */
export type ViewChannelInput = {
  /** ChannelId on which lastViewedAt needs to be updated */
  channelId: Scalars['String'];
  /** Timestamp to be updated to. Takes request timestamp as default if not provided */
  lastViewedAt?: InputMaybe<Scalars['String']>;
};

export type ViewChannelPayload = {
  __typename?: 'ViewChannelPayload';
  channelUser?: Maybe<ChannelUserType>;
  errorCode?: Maybe<ViewChannelUserErrorCodeEnum>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum ViewChannelUserErrorCodeEnum {
  ChannelUserNotFound = 'CHANNEL_USER_NOT_FOUND',
  ForbiddenError = 'FORBIDDEN_ERROR',
}

export enum ViewTypeEnum {
  DetailView = 'DETAIL_VIEW',
  FormView = 'FORM_VIEW',
  TableView = 'TABLE_VIEW',
}

export enum VisibilitySettingsAccessTypeEnum {
  None = 'NONE',
  Open = 'OPEN',
  Restricted = 'RESTRICTED',
}

/** It will resolve different entities with visibility settings inheritedFrom */
export type VisibilitySettingsInheritedFromEntityUnion = KeyResultType | ObjectiveType;

export type VisibilitySettingsInput = {
  /** visibility setting access type */
  accessType: VisibilitySettingsAccessTypeEnum;
  /** groupIds entity is visible to */
  groupIds?: InputMaybe<Array<Scalars['String']>>;
  /** parent info in case visibility settings are inherited from parent */
  inheritedFrom?: InputMaybe<VisibilitySettingsParentEntityInput>;
  /** restriction configs for restricted visibility */
  restrictionConfigs?: InputMaybe<Array<VisibilitySettingsRestrictionInput>>;
};

export type VisibilitySettingsParentEntityInput = {
  /** parent entity id */
  entityId: Scalars['String'];
  /** parent entity type */
  entityType: VisibilitySettingsParentEntityTypeEnum;
};

/** parent entity info in case visibility settings are inherited */
export type VisibilitySettingsParentEntityType = {
  __typename?: 'VisibilitySettingsParentEntityType';
  /** parent entity id */
  entityId: Scalars['String'];
  /** parent entity type */
  entityType: VisibilitySettingsParentEntityTypeEnum;
};

export enum VisibilitySettingsParentEntityTypeEnum {
  Okr = 'OKR',
  OkrCycle = 'OKR_CYCLE',
}

/** restriction config input for restricted visibility settings access type */
export type VisibilitySettingsRestrictionInput = {
  /** id of user which will be used for contexual visibility settings */
  contextualUserId?: InputMaybe<Scalars['String']>;
  /** entity ids(userIds, groupIds, etc...) for which entity is visible */
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  /** parent entity id */
  type: VisibilitySettingsRestrictionTypeEnum;
};

/** restriction config in case of restricted visibility settings access type */
export type VisibilitySettingsRestrictionType = {
  __typename?: 'VisibilitySettingsRestrictionType';
  /** id of user which will be used for contexual visibility settings */
  contextualUserId?: Maybe<Scalars['String']>;
  /** entity ids(userIds, groupIds, etc...) for which entity is visible */
  entityIds?: Maybe<Array<Scalars['String']>>;
  /** parent entity id */
  type: VisibilitySettingsRestrictionTypeEnum;
};

export enum VisibilitySettingsRestrictionTypeEnum {
  Department = 'DEPARTMENT',
  Groups = 'GROUPS',
  Management = 'MANAGEMENT',
  Manager = 'MANAGER',
  Reportees = 'REPORTEES',
  Self = 'SELF',
  Team = 'TEAM',
  Users = 'USERS',
}

/** Visibility settings for entities */
export type VisibilitySettingsType = {
  __typename?: 'VisibilitySettingsType';
  /** visibility setting access type */
  accessType: VisibilitySettingsAccessTypeEnum;
  /** groupIds entity is visible to */
  groupIds?: Maybe<Array<Scalars['String']>>;
  /** parent info in case visibility settings are inherited from parent */
  inheritedFrom?: Maybe<VisibilitySettingsParentEntityType>;
  /** Field Resolvers */
  inheritedFromEntity?: Maybe<VisibilitySettingsInheritedFromEntityUnion>;
  /** restriction configs for restricted visibility */
  restrictionConfigs?: Maybe<Array<VisibilitySettingsRestrictionType>>;
};

/** Edge */
export type WebhookEdge = {
  __typename?: 'WebhookEdge';
  node: WebhookType;
};

export type WebhookFilters = {
  webhookId: Scalars['String'];
};

export type WebhookPayload = {
  __typename?: 'WebhookPayload';
  webhook?: Maybe<WebhookType>;
};

export type WebhookType = {
  __typename?: 'WebhookType';
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  eventTypes: Array<NotificationActionTypeEnum>;
  expireAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  secretKey: Scalars['String'];
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

export type WebhooksFilters = {
  eventTypes?: InputMaybe<Array<NotificationActionTypeEnum>>;
  webhookIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum WeekOfMonthEnum {
  First = 'FIRST',
  Fourth = 'FOURTH',
  Last = 'LAST',
  Second = 'SECOND',
  Third = 'THIRD',
}

export type WidgetConnections = {
  __typename?: 'WidgetConnections';
  edges?: Maybe<Array<WidgetEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type WidgetEdge = {
  __typename?: 'WidgetEdge';
  node: WidgetUnion;
};

export type WidgetFilters = {
  isStandardWidget?: InputMaybe<Scalars['Boolean']>;
  widgetId?: InputMaybe<Scalars['String']>;
};

export type WidgetInteractionActionType = {
  __typename?: 'WidgetInteractionActionType';
  /** Relevant data to perform given action */
  props?: Maybe<Scalars['String']>;
  /** ENUM describing the action that should be performed for given user-interaction */
  type: WidgetInteractionActionTypeEnum;
};

export enum WidgetInteractionActionTypeEnum {
  Mutation = 'MUTATION',
  Redirect = 'REDIRECT',
}

export type WidgetInteractionEventActionType = {
  __typename?: 'WidgetInteractionEventActionType';
  /** List of actions to be performaned against given event */
  actions: Array<WidgetInteractionActionType>;
  /** ENUM for identifying type of user-interaction performed */
  event: WidgetInteractionEventTypeEnum;
};

export enum WidgetInteractionEventTypeEnum {
  OnClick = 'ON_CLICK',
}

export type WidgetLayoutInput = {
  position: WidgetPositionInput;
  widgetId: Scalars['String'];
};

export type WidgetLayoutSetInput = {
  set: Array<WidgetLayoutInput>;
};

/** Widget Layout */
export type WidgetLayoutType = {
  __typename?: 'WidgetLayoutType';
  position: WidgetPositionType;
  widgetId: Scalars['String'];
};

export type WidgetPayload = {
  __typename?: 'WidgetPayload';
  widget?: Maybe<WidgetUnion>;
};

/** Widget Position Input */
export type WidgetPositionInput = {
  h: Scalars['Float'];
  w: Scalars['Float'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

/** Widget Position */
export type WidgetPositionType = {
  __typename?: 'WidgetPositionType';
  h: Scalars['Float'];
  w: Scalars['Float'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

/** union of all widget previews */
export type WidgetPreviewUnion =
  | CompletedLearningsWidgetPreviewType
  | ContentsWidgetPreviewType
  | ContinueLearningWidgetPreviewType
  | ExternalWidgetPreviewType
  | MyEventsWidgetPreviewType
  | OnboardingContentWidgetPreviewType
  | PerformanceMetricsWidgetPreviewType
  | TodosWidgetPreviewType
  | UpcomingEventsWidgetPreviewType;

export type WidgetPropsInput = {
  chartWidgetProps?: InputMaybe<ChartWidgetPropsInput>;
  contentsWidgetProps?: InputMaybe<ContentsWidgetPropsInput>;
  externalWidgetProps?: InputMaybe<ExternalWidgetPropsInput>;
  listItemsWidgetProps?: InputMaybe<ListItemsWidgetPropsInput>;
  type: WidgetTypeEnum;
};

export enum WidgetTypeEnum {
  CompletedLearnings = 'COMPLETED_LEARNINGS',
  Contents = 'CONTENTS',
  ContinueLearning = 'CONTINUE_LEARNING',
  CurrentEvents = 'CURRENT_EVENTS',
  CurrentMeetings = 'CURRENT_MEETINGS',
  CurrentMeetingInstances = 'CURRENT_MEETING_INSTANCES',
  EntityCheck = 'ENTITY_CHECK',
  Events = 'EVENTS',
  External = 'EXTERNAL',
  FeatureNudge = 'FEATURE_NUDGE',
  GlobalSearch = 'GLOBAL_SEARCH',
  Habits = 'HABITS',
  ListItems = 'LIST_ITEMS',
  MarketplaceContents = 'MARKETPLACE_CONTENTS',
  Meetings = 'MEETINGS',
  MeetingInstances = 'MEETING_INSTANCES',
  MyEvents = 'MY_EVENTS',
  Okrs = 'OKRS',
  OnboardingContent = 'ONBOARDING_CONTENT',
  Performances = 'PERFORMANCES',
  PerformanceMetrics = 'PERFORMANCE_METRICS',
  Providers = 'PROVIDERS',
  Report = 'REPORT',
  ReviewCycles = 'REVIEW_CYCLES',
  SubmissionsToReview = 'SUBMISSIONS_TO_REVIEW',
  Tasks = 'TASKS',
  Todos = 'TODOS',
  UpcomingEvents = 'UPCOMING_EVENTS',
}

/** union of all widgets */
export type WidgetUnion =
  | ChartWidgetType
  | CompletedLearningsWidgetType
  | ContentsWidgetType
  | ContinueLearningWidgetType
  | CurrentEventsWidgetType
  | CurrentMeetingInstancesWidgetType
  | CurrentMeetingsWidgetType
  | EntityCheckWidgetType
  | EventsWidgetType
  | ExternalWidgetType
  | FeatureNudgeWidgetType
  | GlobalSearchWidgetType
  | HabitsWidgetType
  | ListItemsWidgetType
  | MarketplaceContentsWidgetType
  | MeetingInstancesWidgetType
  | MeetingsWidgetType
  | OkRsWidgetType
  | OnboardingContentWidgetType
  | PerformanceMetricsWidgetType
  | PerformancesWidgetType
  | ProvidersWidgetType
  | ReviewCyclesWidgetType
  | SubmissionsToReviewWidgetType
  | TasksWidgetType
  | TodosWidgetType;

export type WidgetsFilters = {
  isStandardWidget?: InputMaybe<Scalars['Boolean']>;
  widgetIds: Array<Scalars['String']>;
};

export enum WorkspaceActions {
  Approve = 'APPROVE',
  Archive = 'ARCHIVE',
  Attach = 'ATTACH',
  Checkin = 'CHECKIN',
  Create = 'CREATE',
  Edit = 'EDIT',
  Enroll = 'ENROLL',
  Hide = 'HIDE',
  Invite = 'INVITE',
  List = 'LIST',
  Manage = 'MANAGE',
  Masquerade = 'MASQUERADE',
  Pin = 'PIN',
  Publish = 'PUBLISH',
  Read = 'READ',
  Reject = 'REJECT',
  Remove = 'REMOVE',
  Send = 'SEND',
  ShareContent = 'SHARE_CONTENT',
}

export type WorkspaceAdministrationConfigType = {
  __typename?: 'WorkspaceAdministrationConfigType';
  clientOwner?: Maybe<UserType>;
  clientOwnerUserId?: Maybe<Scalars['String']>;
  excludeLearnerEmailPatterns?: Maybe<Array<Scalars['String']>>;
  licenseLimits?: Maybe<Array<WorkspaceLicenseLimit>>;
  licenseTagId?: Maybe<Scalars['String']>;
  maxLearnersCount?: Maybe<Scalars['Float']>;
  notificationRules?: Maybe<WorkspaceAdministrationRulesType>;
  parentAcademyId?: Maybe<Scalars['String']>;
  renewalDate?: Maybe<Scalars['String']>;
  renewalStatus?: Maybe<WorkspaceRenewalStatusEnum>;
  renewalType?: Maybe<WorkspaceRenewalTypeEnum>;
  serviceContactUser?: Maybe<UserType>;
  serviceContactUserId?: Maybe<Scalars['String']>;
};

export enum WorkspaceAdministrationRuleStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
}

export enum WorkspaceAdministrationRuleTypeEnum {
  LowSeatCount = 'LOW_SEAT_COUNT',
  UpcomingAutomaticRenewal = 'UPCOMING_AUTOMATIC_RENEWAL',
  UpcomingClosedRenewal = 'UPCOMING_CLOSED_RENEWAL',
  UpcomingDeactivation = 'UPCOMING_DEACTIVATION',
  UpcomingRenewalOpportunity = 'UPCOMING_RENEWAL_OPPORTUNITY',
}

export type WorkspaceAdministrationRulesInput = {
  lowSeatCount?: InputMaybe<LowSeatCountRuleInput>;
  upcomingAutomaticRenewal?: InputMaybe<UpcomingAutomaticRenewalRuleInput>;
  upcomingClosedRenewal?: InputMaybe<UpcomingClosedRenewalRuleInput>;
  upcomingDeactivation?: InputMaybe<UpcomingDeactivationRuleInput>;
  upcomingRenewalOpportunity?: InputMaybe<UpcomingRenewalOpportunityRuleInput>;
};

export type WorkspaceAdministrationRulesType = {
  __typename?: 'WorkspaceAdministrationRulesType';
  lowSeatCount?: Maybe<LowSeatCountRuleType>;
  upcomingAutomaticRenewal?: Maybe<UpcomingAutomaticRenewalRuleType>;
  upcomingClosedRenewal?: Maybe<UpcomingClosedRenewalRuleType>;
  upcomingDeactivation?: Maybe<UpcomingDeactivationRuleType>;
  upcomingRenewalOpportunity?: Maybe<UpcomingRenewalOpportunityRuleType>;
};

export type WorkspaceConfigType = {
  __typename?: 'WorkspaceConfigType';
  adminApprovedWhiteListedInviteDomains?: Maybe<Array<Scalars['String']>>;
  administration?: Maybe<WorkspaceAdministrationConfigType>;
  authConfig: AuthConfig;
  checkAssignmentContentVisibility?: Maybe<Scalars['Boolean']>;
  checkInviteUserTags?: Maybe<Scalars['Boolean']>;
  filterStaffRoleIds?: Maybe<Array<Scalars['String']>>;
  pages?: Maybe<Array<PageType__Next>>;
  site?: Maybe<Scalars['JSON']>;
  theme?: Maybe<Scalars['JSON']>;
  whitelistedSignUpDomains?: Maybe<Array<Scalars['String']>>;
};

/** User Worskapce Permissions */
export type WorkspaceDetails = {
  __typename?: 'WorkspaceDetails';
  icon?: Maybe<Scalars['String']>;
  uri: Scalars['String'];
  workspaceId: Scalars['String'];
  workspaceLogo?: Maybe<Scalars['String']>;
  workspaceName: Scalars['String'];
};

/** details of the source/target events for the conference */
export type WorkspaceEventDetails = {
  __typename?: 'WorkspaceEventDetails';
  eventId: Scalars['String'];
  workspaceId: Scalars['String'];
};

/** details of the source/target events for the conference */
export type WorkspaceEventDetailsInput = {
  eventId: Scalars['String'];
  workspaceId: Scalars['String'];
};

/** Workspace Integrations */
export type WorkspaceIntegrationType = {
  __typename?: 'WorkspaceIntegrationType';
  appId?: Maybe<Scalars['String']>;
  appName?: Maybe<Scalars['String']>;
  appUrl?: Maybe<Scalars['String']>;
  loginConfig?: Maybe<LoginConfig>;
};

export type WorkspaceLicenseLimit = {
  __typename?: 'WorkspaceLicenseLimit';
  licenseId: Scalars['String'];
  maxLearnersCount: Scalars['Float'];
};

export type WorkspaceLicenseLimitInput = {
  licenseId: Scalars['String'];
  maxLearnersCount: Scalars['Float'];
};

export enum WorkspaceRenewalStatusEnum {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Renewed = 'RENEWED',
}

export enum WorkspaceRenewalTypeEnum {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export enum WorkspaceRoleTypeEnum {
  Admin = 'ADMIN',
  Assigner = 'ASSIGNER',
  Author = 'AUTHOR',
  Learner = 'LEARNER',
}

export enum WorkspaceSubjects {
  Academy = 'ACADEMY',
  Assignment = 'ASSIGNMENT',
  Attendance = 'ATTENDANCE',
  Authentication = 'AUTHENTICATION',
  Billing = 'BILLING',
  Category = 'CATEGORY',
  Certificate = 'CERTIFICATE',
  Channel = 'CHANNEL',
  Community = 'COMMUNITY',
  CompanyObjective = 'COMPANY_OBJECTIVE',
  ContentCollection = 'CONTENT_COLLECTION',
  ContentSubworkspace = 'CONTENT_SUBWORKSPACE',
  Department = 'DEPARTMENT',
  DepartmentObjective = 'DEPARTMENT_OBJECTIVE',
  EmailDigest = 'EMAIL_DIGEST',
  Event = 'EVENT',
  EventSubworkspace = 'EVENT_SUBWORKSPACE',
  File = 'FILE',
  Group = 'GROUP',
  Homepage = 'HOMEPAGE',
  IndividualObjective = 'INDIVIDUAL_OBJECTIVE',
  Integration = 'INTEGRATION',
  Learner = 'LEARNER',
  Members = 'MEMBERS',
  Okr = 'OKR',
  OkrCycle = 'OKR_CYCLE',
  Post = 'POST',
  Report = 'REPORT',
  Resource = 'RESOURCE',
  ReviewCycle = 'REVIEW_CYCLE',
  Role = 'ROLE',
  Scorecard = 'SCORECARD',
  SharedConnection = 'SHARED_CONNECTION',
  Skill = 'SKILL',
  SkillMatrix = 'SKILL_MATRIX',
  Space = 'SPACE',
  Staff = 'STAFF',
  Subaccount = 'SUBACCOUNT',
  Tag = 'TAG',
  TagSubworkspace = 'TAG_SUBWORKSPACE',
  TeamObjective = 'TEAM_OBJECTIVE',
  User = 'USER',
  UserCollection = 'USER_COLLECTION',
  Workspace = 'WORKSPACE',
}

/** The Workspace Model */
export type WorkspaceType = {
  __typename?: 'WorkspaceType';
  academySetupDepartmentTemplates: Array<DefaultTemplateType>;
  acsUrl: Scalars['String'];
  adminId?: Maybe<Scalars['String']>;
  allowedEntityPermissionsMap?: Maybe<Array<EntityPermissionsMap>>;
  configs?: Maybe<WorkspaceConfigType>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdById: Scalars['String'];
  enabledApps?: Maybe<Array<Scalars['String']>>;
  hostName: Scalars['String'];
  id: Scalars['String'];
  integrations?: Maybe<Array<WorkspaceIntegrationType>>;
  learnersCount: Scalars['Float'];
  name: Scalars['String'];
  onboardingInfo?: Maybe<OnboardingInfoType>;
  /** @deprecated sharedSpace is not needed anymore, and will be removed soon */
  sharedSpace?: Maybe<Array<EntitySharedSpace>>;
  siteSettings: SiteSettingsType;
  spEntityId: Scalars['String'];
  /** Resolves roles available in workspace. Use with caution; all non learner roles will be returned */
  staffRoles: Array<RoleType>;
  status?: Maybe<Scalars['String']>;
  subDomain: Scalars['String'];
  supportEmail?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<UserType>;
  updatedById?: Maybe<Scalars['String']>;
  /** returns a list of user and their role based on entities permissions */
  userRoles: Array<UserRolePayload>;
  versionNumber?: Maybe<Scalars['Float']>;
};

/** details of users with their corresponding workspace */
export type WorkspaceUserDetails = {
  __typename?: 'WorkspaceUserDetails';
  userId: Scalars['String'];
  workspaceId: Scalars['String'];
};

/** details of users with their corresponding workspace */
export type WorkspaceUserDetailsInput = {
  userId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type WorspacesInput = {
  set: Array<Scalars['String']>;
};

export type ZoomEventAttendanceImportInputPropsInput = {
  eventId: Scalars['String'];
};

export type ZoomEventAttendanceImportInputPropsType = {
  __typename?: 'ZoomEventAttendanceImportInputPropsType';
  eventId: Scalars['String'];
};

export type ZoomEventAttendanceImportMetadataType = {
  __typename?: 'ZoomEventAttendanceImportMetadataType';
  success: Scalars['Boolean'];
};

export type ZoomIntegrationConnectionInfoInput = {
  accountId: Scalars['String'];
  userEmailId: Scalars['String'];
  userName: Scalars['String'];
};

export type ZoomIntegrationConnectionInfoType = {
  __typename?: 'ZoomIntegrationConnectionInfoType';
  accountId: Scalars['String'];
  userEmailId: Scalars['String'];
  userName: Scalars['String'];
};

export type ZoomIntegrationSettingsInput = {
  connectionInfo: ZoomIntegrationConnectionInfoInput;
};

export type ZoomIntegrationSettingsType = {
  __typename?: 'ZoomIntegrationSettingsType';
  connectionInfo: ZoomIntegrationConnectionInfoType;
};

export type ZoomRecordingsImportInputPropsInput = {
  eventId: Scalars['String'];
};

export type ZoomRecordingsImportInputPropsType = {
  __typename?: 'ZoomRecordingsImportInputPropsType';
  eventId: Scalars['String'];
};
