const config = {
  env: process.env.NODE_ENV,

  /* system_user_token [instance wise] for fetching data from backend */
  system_user_token: process.env.SYSTEM_USER_TOKEN!,

  public_backend_url: process.env.NEXT_PUBLIC_BACKEND_URL,
  public_stripe_publishable_key: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  public_bugsnag_api_key: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,

  default_build_hosts: [process.env.DEFAULT_BUILD_HOST],

  /* Provided by Vercel */
  public_vercel_env: process.env.NEXT_PUBLIC_VERCEL_ENV,
};

export default config;
