import { logout } from 'modules/auth/utils';
import { setLoginCookies } from 'modules/auth/utils/setLoginCookies';

import cookie from '@lyearn/core/utils/cookie';

import user from '../currentUser';
import request from '../request';

let refetchTokensPromise: any = null;

const fetchTokens = async (refreshToken: string) => {
  const accessToken = user.accessToken();

  try {
    const response = await request(
      '/api/auth/refreshTokens',
      {
        method: 'POST',
        body: JSON.stringify({
          refreshToken,
          entityId: cookie.get('storeId'),
          entityType: 'store',
        }),
        headers: {
          'content-type': 'application/json; charset=utf-8',
        },
      },
      {
        sendAuthHeader: false,
        retryCount: 2,
      },
    );

    const tokens = await response.json();

    if (tokens.success) {
      await setLoginCookies(tokens?.loginToken);
    } else {
      if (accessToken) {
        /* TODO: show snackbar to inform user he's been logged out */
      }
      /* logout call will also fetch new publicToken */
      await logout();
    }
  } catch (e) {
    console.error('Error in fetching token', e);
  } finally {
    refetchTokensPromise = null;
  }
};

export const fetchAndSaveTokens = async () => {
  const refreshToken = user.refreshToken();
  if (refetchTokensPromise) {
    console.log('refetch tokens promise already exists');
    return refetchTokensPromise;
  }
  if (refreshToken) {
    refetchTokensPromise = fetchTokens(refreshToken);
  } else {
    // TODO(MS): call handleLogout
    console.log("Couldn't find a refreshToken to refrech with");
  }
  return;
};

const useFetchAndSaveTokens = async () => {
  const refreshToken = user.refreshToken();

  if (refetchTokensPromise) {
    console.log('refetch tokens promise already exists');
    return refetchTokensPromise;
  }

  if (refreshToken) {
    refetchTokensPromise = fetchTokens(refreshToken);
  } else {
    logout();
    console.log("Couldn't find a refreshToken to refetch with");
  }
  return refetchTokensPromise;
};

export default useFetchAndSaveTokens;
