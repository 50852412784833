import { useCallback, useEffect, useState } from 'react';
import { useLayoutEffect } from 'react';
import currentUser from 'helpers/currentUser';
import { noop } from 'lodash';
import { UserStatusEnum } from 'types/schema';
import { useClient } from 'urql';

import { UserDocument } from '../grpahql/queries/generated/user';
import { User } from '../types';
import transformUser from '../utils/transformUser';

export const defaultUser = {
  id: '',
  emailId: '',
  name: '',
  alias: '',
  role: '',
  organisation: '',
  country: '',
  profilePicture: '',
  status: UserStatusEnum.Active,
  fetching: true,
  corporateUserId: '',
  refreshUser: noop,
  meta: '{}',
  experience: '',
  onBoardingCompleted: false,
  loggedIn: null,
};

const useUserInit = () => {
  // const [accessToken, updateAccessToken] = useState(currentUser.accessToken());
  const [user, setUser] = useState<User>(transformUser(defaultUser, noop));
  const client = useClient();

  const userId = currentUser.userId();

  const fetchUser = useCallback(async () => {
    const _userId = currentUser.userId();
    setUser(transformUser({ ...defaultUser, fetching: true, loggedIn: null }, fetchUser));

    const response = await client
      .query(
        UserDocument,
        {
          filters: { userId: _userId },
        },
        {
          requestPolicy: 'network-only',
        },
      )
      .toPromise();

    if (response.data?.user) {
      setUser(
        transformUser({ ...response.data?.user, fetching: false, loggedIn: true }, fetchUser),
      );
      return;
    }
    setUser(transformUser({ ...defaultUser, fetching: false, loggedIn: false }, fetchUser));
  }, [client]);

  useEffect(() => {
    if (userId) {
      fetchUser();
      return;
    }
    setUser(transformUser({ ...defaultUser, fetching: false, loggedIn: false }, fetchUser));
  }, [fetchUser, userId]);

  return { user };
};

export default useUserInit;
