import gql from 'graphql-tag';
import * as Types from 'types/schema';
import * as Urql from 'utils/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserQueryVariables = Types.Exact<{
  filters: Types.UserFilters;
}>;

export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserType';
    id: string;
    emailId?: string | null;
    name?: string | null;
    alias?: string | null;
    profilePicture?: string | null;
    status: Types.UserStatusEnum;
    meta?: string | null;
    affiliations?: Array<{
      __typename?: 'UserAffiliations';
      type: Types.AffiliationsTypeEnum;
      userId?: string | null;
      customerId?: string | null;
    }> | null;
  } | null;
};

export const UserDocument = gql`
  query User($filters: UserFilters!) {
    user(filters: $filters) {
      id
      emailId
      name
      alias
      profilePicture
      status
      meta
      affiliations {
        type
        userId
        customerId
      }
    }
  }
`;

export function useUserQuery(options: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'>) {
  return Urql.useQuery<UserQuery, UserQueryVariables>({ query: UserDocument, ...options });
}
