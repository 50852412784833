import { useState } from 'react';
import { FetchingStatus } from 'types/enums/FetchType';

import request from '../request';

function useRest(
  endpoint: string,
  fetchOptions?: object,
  extraOptions?: object,
  responseType: string = 'json',
): [FetchingStatus, object | null, any, Function] {
  const [status, setStatus] = useState(FetchingStatus.NotStarted);
  const [data, setData] = useState(null);
  const [error, setError] = useState<any>(null);

  const execute = async ({ requestBody = '', url = '' }: any = {}) => {
    setStatus(FetchingStatus.Fetching);
    let dataResponse;
    try {
      const response = await request(
        endpoint || url,
        requestBody ? { ...fetchOptions, body: requestBody } : fetchOptions,
        extraOptions,
      );
      if (responseType === 'json') {
        dataResponse = await response.json();
      } else {
        dataResponse = await response.text();
      }

      if (dataResponse.error) {
        setError(dataResponse.error);
      }
      setData(dataResponse);
      setStatus(FetchingStatus.Done);
      return dataResponse;
    } catch (e) {
      setError(e);
      setStatus(FetchingStatus.Done);
      console.error('Error in useRest:', e);
      return null;
    }
  };
  return [status, error, data, execute];
}

export default useRest;
