import { META_ONBOARDING_COMPLETED } from 'components/AboutYourself/components/AboutYourselfForm/hooks/useAboutYourselfForm';
import { OperationContext } from 'urql';

import { UserQuery } from '../grpahql/queries/generated/user';
import { User } from '../types';

const transformUser = (
  user: NonNullable<UserQuery['user'] & { fetching?: boolean; loggedIn: boolean | null }>,
  refetchUser: (opts?: Partial<OperationContext> | undefined) => void,
): User => {
  let metaData;
  try {
    metaData = JSON.parse(user?.meta || '{}');
  } catch (e) {
    console.log('user: ', user);
    console.error('Error in user meta parsing', e);
  }

  return {
    id: user.id,
    emailId: user.emailId ?? '',
    profilePicture: user.profilePicture!,
    status: user.status,
    name: user.name!,
    alias: user.alias!,
    meta: metaData,
    organisation: metaData.organisation,
    country: metaData.country,
    corporateUserId:
      metaData.corporateUserId ||
      user.affiliations?.find?.((entry) => entry?.type === 'LYEARN')?.userId,
    role: metaData.role,
    fetching: user.fetching ?? false,
    refreshUser: refetchUser,
    experience: metaData.experience,
    loggedIn: user.loggedIn,
    [META_ONBOARDING_COMPLETED]: metaData[META_ONBOARDING_COMPLETED],
  };
};

export default transformUser;
