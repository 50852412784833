import { getTrackingScript } from './utils';

const LinkedInScript = ({ LINKEDIN_PARTNER_ID }: { LINKEDIN_PARTNER_ID: string }) => {
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: getTrackingScript(LINKEDIN_PARTNER_ID),
        }}
        type="text/javascript"
      />
      <noscript>
        <img
          alt=""
          className="hidden"
          height="1"
          src={`https://px.ads.linkedin.com/collect/?pid=${LINKEDIN_PARTNER_ID}&fmt=gif`}
          width="1"
        />
      </noscript>
    </>
  );
};

export default LinkedInScript;
