import sanitizeURL from 'utils/sanitizeUrl';

import Cookie from '@lyearn/core/utils/cookie';

import publicAuthQuery from './publicAuthQuery';

export const logout = async (refreshUser?: Function, redirectTo?: string) => {
  Cookie.remove?.('accessToken');
  Cookie.remove?.('refreshToken');
  Cookie.remove?.('lyearn_token');
  Cookie.remove?.('user_id');
  Cookie.remove?.('_uId');

  await publicAuthQuery({ setCookies: true });

  refreshUser && (await refreshUser());

  if (redirectTo) {
    window.location.href = sanitizeURL(redirectTo);
  }
};
