export enum FetchingStatus {
  NotStarted = 'NOT_STARTED',
  Fetching = 'FETCHING',
  Done = 'DONE',
  Error = 'ERROR',
}

export enum FetchPolicy {
  CACHE_FIRST = 'cache-first',
  CACHE_AND_NETWORK = 'cache-and-network',
  NETWORK_ONLY = 'network-only',
  CACHE_ONLY = 'cache-only',
}
