import React from 'react';
import { useEffect } from 'react';
import { useAsync } from 'react-use';
import currentUser from 'helpers/currentUser';
import { getQueryParams } from 'helpers/getQueryParams';
import { logout } from 'modules/auth/utils';
import getUserVariables from 'modules/auth/utils/getUserVariables';
import isOldWorkspaceId from 'modules/auth/utils/isOldWorkspaceId';
import publicAuthQuery from 'modules/auth/utils/publicAuthQuery';
import { setLoginCookies } from 'modules/auth/utils/setLoginCookies';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { AuthErrors } from 'types/enums/AuthErrors';

import useToggle from '@lyearn/core/hooks/useToggle';
import { useSnackbar } from '@lyearn/ui';

/* NS: todo Make this as a route /login-redirect and not a component in _app stack */
const LoginRedirect: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { showSnackbar } = useSnackbar();
  const { asPath } = router;
  const params = getQueryParams(
    ['accessToken', 'refreshToken', 'success', 'errorCode'],
    asPath as string,
  );

  const [accessToken, refreshToken, success, errorCode] = Array.from(params as string[]);

  const [init, { toggle: toggleInit }] = useToggle(
    accessToken && success === 'true' ? false : true,
  );

  if (success && !errorCode) {
    setLoginCookies({
      accessToken: accessToken as string,
      refreshToken: refreshToken as string,
    });

    if (process.browser) {
      const { url, query } = queryString.parseUrl(window.location.href);
      delete query.accessToken;
      delete query.refreshToken;
      delete query.success;
      delete query.errorCode;

      router.replace(queryString.stringifyUrl({ url, query })).then(() => router.reload());
      // refreshUser && refreshUser();
    }
  }

  useAsync(
    () =>
      process.browser && !currentUser.userId() && !getUserVariables(accessToken)
        ? publicAuthQuery({ setCookies: true })
        : Promise.resolve(),
    [],
  );

  useEffect(() => {
    if (!init) {
      toggleInit();
    }
  }, [init, toggleInit]);

  /* Logout users with old university.sprinklr.com workspaceId 5f2262589e9a66c70ce5735c */
  const userCurrentAccessTokenFromCookie = currentUser.accessToken();

  useEffect(() => {
    if (!userCurrentAccessTokenFromCookie || isOldWorkspaceId(userCurrentAccessTokenFromCookie)) {
      logout();
    }
  }, [userCurrentAccessTokenFromCookie]);

  let message = '';
  if (errorCode) {
    if (process.browser) {
      setTimeout(() => {
        router.push('/', '/', { shallow: true });
      }, 5000);
    }
    if (errorCode === AuthErrors.USER_EXISTS) {
      message = 'User already exists, try logging in.';
    } else if (errorCode === AuthErrors.INCORRECT_USERNAME) {
      message = 'User account does not exists.';
    }
  }

  if (errorCode) {
    showSnackbar({
      message: message,
      iconStatus: 'error',
      classes: { root: 'rounded-8 border' },
    });
  }

  if (!init) {
    return null;
  }

  return <>{children}</>;
};

export default LoginRedirect;
