import parseJwt from 'helpers/parseJWT';
import _ from 'lodash';

const getUserVariables = _.memoize((accessToken) => {
  let userIdFromToken = '';
  try {
    if (accessToken) {
      userIdFromToken = parseJwt(accessToken as string).userId;
    }
  } catch (e) {
    console.error('Error parsing userId from token', e);
  }
  return userIdFromToken;
});

export default getUserVariables;
