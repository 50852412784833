import { useContext } from 'react';
import SiteConfigType from 'types/interfaces/SiteConfig';

import { SiteConfig } from '../components/SiteConfigProvider';

const useInstanceConfig = () => {
  const config = useContext(SiteConfig);
  if (!config) {
    throw new Error('Site config not initialied.');
  }
  return config as SiteConfigType;
};

export default useInstanceConfig;
