import Cookie from '@lyearn/core/utils/cookie';

const noopString = () => '';

let user = {
  accessToken: noopString,
  refreshToken: noopString,
  userId: noopString,
};

if (process.browser) {
  user = {
    accessToken: () => Cookie.get('accessToken') as string,
    refreshToken: () => Cookie.get('refreshToken') as string,
    userId: () => Cookie.get('_uId') as string,
  };
}

export default user;
