/*
  Inspired from https://github.com/vercel/next.js/blob/canary/examples/with-facebook-pixel/components/FacebookPixel.js
* */

import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { pageview } from './utils';

const handleRouteChange = () => {
  pageview();
};

const FacebookAnalyticsTriggers = ({ FB_PIXEL_ID }: { FB_PIXEL_ID: string }) => {
  const router = useRouter();

  useEffect(() => {
    // This pageview only trigger first time (it is important for Pixel to have real information)
    pageview();

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, FB_PIXEL_ID]);

  return null;
};

export default FacebookAnalyticsTriggers;
