import { nanoid } from 'nanoid';

import user from './currentUser';
import refetchTokens from './fetchTokens';
import url from './url';
import validateJWT from './validateJWT';

const fetchRetry = (backendURL: URL, options: any, n: number): any => {
  return fetch(backendURL as any, options).catch((error: any) => {
    if (n === 1) throw error;
    return fetchRetry(backendURL, options, n - 1);
  });
};

export default async function request(
  endpoint: string,
  fetchOptions: any = {},
  extraOptions: any = {
    sendAuthHeader: true,
  },
) {
  /* Set request url */
  let urlString = endpoint;
  if (!endpoint.startsWith('http')) {
    const baseURL = url;
    const slashedEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;
    urlString = `${baseURL}${slashedEndpoint}`;
  }
  const backendURL = new URL(urlString);

  /* Set request headers */
  const headers = fetchOptions.headers || {};

  const accessToken = user.accessToken();
  if (extraOptions.sendAuthHeader && accessToken) {
    const isValid = validateJWT(accessToken as string);
    if (!isValid) {
      await refetchTokens();
    }
    headers.accessToken = user.accessToken();
  }

  headers['x-request-id'] = nanoid();
  // Take last string after '/' and before any '?' or '#' as the operation name
  headers['x-operation-name'] = endpoint.split('/').pop();

  /* Set query parameters */
  const queryParams = extraOptions.queryParams || {};

  Object.keys(queryParams).forEach((key) => backendURL.searchParams.append(key, queryParams[key]));

  /* use request timeout if provided */
  let signal = null;
  const timeout = extraOptions.timeout;
  if (timeout) {
    const controller = new AbortController();
    signal = controller.signal;

    setTimeout(controller.abort, timeout);
  }

  const retryCount = extraOptions.retryCount || 0;

  if (retryCount > 0) {
    const tryCount = retryCount + 1; // total number of `fetch`s
    return fetchRetry(
      backendURL,
      {
        signal,
        ...fetchOptions,
        headers,
      },
      tryCount,
    );
  }

  const response = await fetch(backendURL as any, {
    signal,
    ...fetchOptions,
    headers,
    credentials: 'same-origin',
  });
  if (response.status === 403 && !extraOptions.doNotLogoutOn403) {
    console.log(
      'store_token is',
      user.accessToken(),
      'sendAuthHeaderFlag is',
      extraOptions.sendAuthHeader,
      'currentTime is',
      Date.now(),
    );
    throw new Error('FORCE_LOGOUT');
  }
  if (response.status === 401 && !extraOptions.doNotLogoutOn401) {
    console.log(
      'store_token is',
      user.accessToken(),
      'sendAuthHeaderFlag is',
      extraOptions.sendAuthHeader,
      'currentTime is',
      Date.now(),
    );
    throw new Error('TOKEN_INVALID');
  }
  return response;
}
