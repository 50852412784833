import config from 'config';
import domainTokenMapping from 'config/domainTokens';
import find from 'lodash/find';

const getSystemUserAccessToken = (host?: string | string[]) => {
  const hostUrl = config.public_vercel_env !== 'production' ? 'dev' : host;

  return find(domainTokenMapping, (tm) => tm.domains.includes(hostUrl as string))?.token!;
};

export default getSystemUserAccessToken;
