// @ts-nocheck
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useUser } from 'modules/context/Contexts/User';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';

const INTERCOM_APP_ID = 'rmvdg6n1';

const _runIntercomScript = () => {
  (function () {
    const w: any = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', (window as any).intercomSettings);
    } else {
      const d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      function l() {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APP_ID;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (document.readyState === 'complete') {
        l();
      }
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

const IntercomInit = () => {
  const user = useUser();
  const { site } = useInstanceConfig();

  if (process.browser) {
    _runIntercomScript();
  }

  const initializeIntercom = useCallback(() => {
    if ((user as any).id) {
      if ((window as any).top === (window as any).self && user) {
        setTimeout(() => {
          (window as any).Intercom?.('update', {
            app_id: INTERCOM_APP_ID,
            name: user.name,
            user_id: user.id,
            email: user.emailId,
            companies: {
              company_id: site.corpCompanyId,
              name: site.displayCompanyName,
            },
            created_at: new Date().getTime(),
          });
        }, 800);
      }
    } else if ((window as any).top === (window as any).self) {
      setTimeout(() => {
        (window as any).Intercom?.('shutdown');
        setTimeout(() => {
          (window as any).Intercom?.('boot', {
            app_id: INTERCOM_APP_ID,
          });
        }, 800);
      }, 800);
    }
  }, [site.corpCompanyId, site.displayCompanyName, user]);

  useEffect(() => {
    initializeIntercom(user);
  }, [initializeIntercom, user]);

  return null;
};

export default IntercomInit;
