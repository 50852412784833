import { getTrackingScript } from './utils';

const FacebookPixelScript = ({ FB_PIXEL_ID }: { FB_PIXEL_ID: string }) => {
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: getTrackingScript(FB_PIXEL_ID),
        }}
      />
      <noscript>
        <img
          alt="fb pixel"
          className="hidden"
          height="1"
          src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
          width="1"
        />
      </noscript>
    </>
  );
};

export default FacebookPixelScript;
