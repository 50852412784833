const getQueryParam = (params: string | string[], url: string) => {
  const reg = new RegExp('[?&#]' + params + '=([^&#]*)', 'i');
  const queryString = reg.exec(url);
  return queryString ? queryString[1] : null;
};

export const getQueryParams = (params: string | string[], url: string) => {
  if (typeof params === 'string') {
    return getQueryParam(params, url);
  } else {
    return params.map((param) => {
      return getQueryParam(param, url);
    });
  }
};
