import clearAllCookies from 'helpers/clearAllCookies';
import getDataFromAccessToken from 'helpers/getDataFromAccessToken';
import request from 'helpers/request';

import Cookie from '@lyearn/core/utils/cookie';

interface Params {
  setCookies?: boolean;
  setAccessToken?: boolean;
  host?: string;
}

interface CorpPublicTokenResponse {
  publicToken: string;
  storeId: string;
}

/* Fetches and sets public access token to cookies depending on the Args */
const publicAuthQuery = async (args: Params = {}): Promise<CorpPublicTokenResponse> => {
  const { setCookies: _setCookies = false, host } = args;

  const response = await request(
    '/api/auth/publicAuthentication',
    {
      method: 'POST',
      body: JSON.stringify({ host: host, entityType: 'store' }),
      headers: { 'content-type': 'application/json' },
    },
    {
      retryCount: 2,
    },
  );

  const responseData = await response.json();
  if (!responseData || !responseData.success) {
    /* TODO: show user something is not right, click here to refresh
    before refresh clear all cookies and other data, then refresh */
    clearAllCookies();
    console.log('Error in public authentication');
  }

  const { storeId } = getDataFromAccessToken(responseData.publicToken);

  if (_setCookies) {
    Cookie.set('accessToken', responseData.publicToken, { expires: 1, path: '/' });
    Cookie.set('storeId', storeId, { expires: 1, path: '/' });
  }
  return responseData;
};

export default publicAuthQuery;
