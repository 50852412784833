import React from 'react';
import { createContext } from 'helpers/createContext';
import useUserInit from 'modules/auth/hooks/useUserInit';

const USER = 'User';

type UserContextValue = ReturnType<typeof useUserInit>['user'];

type UserContextProps = {};

const [UserContextProvider, useUserContext] = createContext<UserContextValue>(USER);

const _UserContextProvider: React.FC<React.PropsWithChildren<UserContextProps>> = ({
  children,
}) => {
  const { user } = useUserInit();

  return <UserContextProvider {...user}>{children}</UserContextProvider>;
};

export { _UserContextProvider as UserContextProvider, useUserContext as useUser };
export default _UserContextProvider;
