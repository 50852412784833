import parseJWT from './parseJWT';

const invalidateBufferDuration = 5 * 60 * 1000;

export default function validateJWT(jwtToken: string) {
  if (!jwtToken) {
    return false;
  }
  const parsedToken = parseJWT(jwtToken);
  if (!parsedToken) {
    return false;
  }
  const exp = parsedToken.exp;
  const expirationTime = exp * 1000;
  if (Date.now() > expirationTime - invalidateBufferDuration) {
    return false;
  }

  return true;
}
