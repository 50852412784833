/**
 * Returns LinkedIn tracking script to be injected in head
 * @param LINKEDIN_PARTNER_ID
 */
export const getTrackingScript = (LINKEDIN_PARTNER_ID: string) => `
_linkedin_partner_id = ${LINKEDIN_PARTNER_ID};
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);
</script><script type="text/javascript">
(function(){var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})();
`;

export const pageview = () => {
  (window as any).fbq?.('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: any, options = {}) => {
  (window as any).fbq?.('track', name, options);
};
