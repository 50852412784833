export enum AuthErrors {
  PASSWORD_NOT_SET = 'PASSWORD_NOT_SET',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  INVALID_DATA = 'INVALID_DATA',
  USER_EXISTS = 'USER_EXISTS',
  INCORRECT_USERNAME = 'INCORRECT_USERNAME',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
}

export default AuthErrors;
