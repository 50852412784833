import gql from 'graphql-tag';
import * as Types from 'types/schema';
import * as Urql from 'utils/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateUserMutationVariables = Types.Exact<{
  userDetails: Types.UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'UserType'; id: string; meta?: string | null };
};

export const UpdateUserDocument = gql`
  mutation updateUser($userDetails: UpdateUserInput!) {
    updateUser(data: $userDetails) {
      id
      meta
    }
  }
`;

export function useUpdateUserMutation() {
  return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument);
}
