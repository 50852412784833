import { devtoolsExchange } from '@urql/devtools';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { retryExchange } from '@urql/exchange-retry';
import url from 'helpers/url';
import { authExchange } from 'helpers/urqlClient/authExchange';
import {
  cacheExchange,
  createClient as createUrqlClient,
  dedupExchange,
  fetchExchange,
} from 'urql';

let clients: any = null;

export const getClients = () => {
  const urqlClient = createUrqlClient({
    url: `${url}/api/graphql`,
    exchanges: [
      authExchange,
      devtoolsExchange,
      dedupExchange,
      cacheExchange,
      requestPolicyExchange({}),
      retryExchange({
        retryIf: (err) => err.message === '[Network] No Content' || !!err.networkError,
      }) as any,
      fetchExchange,
    ],
  });

  return {
    urqlClient: urqlClient,
  };
};

const useInit = () => {
  if (!clients) {
    clients = getClients();
  }

  return {
    ready: true,
    urqlClient: clients?.urqlClient,
  };
};

export default useInit;
