import React from 'react';
import SiteConfig from 'types/interfaces/SiteConfig';

const Favicons = ({ site }: { site: SiteConfig['site'] }) => {
  const {
    appleTouchIcon,
    favicon32,
    favicon16,
    msapplicationTileColor,
    themeColor,
    safariPinnedTab,
    webManifest,
  } = site.favicons || {};

  return (
    <>
      <link href={appleTouchIcon} rel="apple-touch-icon" sizes="180x180" />
      <link href={favicon32} rel="icon" sizes="32x32" type="image/png" />
      <link href={favicon16} rel="icon" sizes="16x16" type="image/png" />
      <link href={webManifest} rel="manifest" />
      <link color={safariPinnedTab?.color} href={safariPinnedTab?.icon} rel="mask-icon" />
      <meta content={msapplicationTileColor} name="msapplication-TileColor" />
      <meta content={themeColor} name="theme-color" />
    </>
  );
};

export default Favicons;
