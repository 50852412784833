import getDataFromAccessToken from 'helpers/getDataFromAccessToken';

import Cookie from '@lyearn/core/utils/cookie';

import { LoginToken } from '../types';
import getUserVariables from './getUserVariables';

export const setLoginCookies = (loginToken: LoginToken) => {
  Cookie.set('accessToken', loginToken?.accessToken, { expires: 1, path: '/' });
  Cookie.set('refreshToken', loginToken?.refreshToken, { expires: 30, path: '/' });
  Cookie.set('storeId', getDataFromAccessToken(loginToken?.accessToken).storeId, {
    expires: 30,
    path: '/',
  });
  Cookie.set('_uId', getUserVariables(loginToken.accessToken), { expires: 30, path: '/' });

  return Promise.resolve();
};
