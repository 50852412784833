const checkOnBoardingCompletion = (requiredUserFields: string[], userData: any) => {
  let onBoardingComplete = true;
  requiredUserFields.forEach((key) => {
    if (!userData[key]) {
      onBoardingComplete = false;
    }
  });

  return onBoardingComplete;
};

export default checkOnBoardingCompletion;
