import isURL from 'validator/lib/isURL';

const checkIfValidPath = (url: string) => isURL(url, { require_host: false, require_tld: false });
const checkIfContainsHost = (url: string) => isURL(url, { require_host: true, require_tld: true });

const defaultUrl = '/';

const sanitizeURL = (url = '') => {
  if (checkIfValidPath(url) && !checkIfContainsHost(url)) {
    return url;
  }

  return defaultUrl;
};

export default sanitizeURL;
