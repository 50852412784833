import Cookies from 'js-cookie';

import type { CookiesModuleType, Options } from './types';

let baseUrl = '';

const cookies: CookiesModuleType = {
  get: (key) => {
    return Cookies.get()?.[key];
  },
  getAll: () => {
    return Promise.resolve(Cookies.get());
  },
  set(name: string, value: string, options?: Options): Promise<boolean> {
    Cookies.set(name, value, {
      domain: options?.domain,
      expires: options?.expires,
      path: options?.path,
    });
    return Promise.resolve(false);
  },

  // for mobile support.
  clearAll(): Promise<boolean> {
    throw new Error('Not implement in web');
  },
  setBaseUrl(_url: string) {
    baseUrl = _url;
  },
  setInitialCacheUrls(_urls: string[]) {
    // throw new Error('Not implement in web');
  },
  remove(name: string, options?: Cookies.CookieAttributes) {
    Cookies.remove(name, options);
  },
};

export default cookies;
