import * as React from 'react';
import config from 'config';
import { FacebookPixelScripts } from 'lib/facebook';
// import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { GoogleAnalyticsScripts } from 'lib/google';
import { LinkedInScripts } from 'lib/linkedin';

const AnalyticsScripts = ({ site }: { site: any }) => {
  // const { site } = useInstanceConfig();

  if (config.env !== 'production') {
    return null;
  }

  const { facebook, google, linkedin } = (site?.integrations || {}) as TSFixMe;

  return (
    <>
      {facebook?.FB_PIXEL_ID ? <FacebookPixelScripts FB_PIXEL_ID={facebook.FB_PIXEL_ID} /> : null}
      {google?.GA_TRACKING_ID ? (
        <GoogleAnalyticsScripts GA_TRACKING_ID={google.GA_TRACKING_ID} />
      ) : null}
      {linkedin?.LINKEDIN_PARTNER_ID ? (
        <LinkedInScripts LINKEDIN_PARTNER_ID={linkedin.LINKEDIN_PARTNER_ID} />
      ) : null}
    </>
  );
};

export default AnalyticsScripts;
