import './tailwind.css';
/* TODO: handle fonts css */
import 'theme/css/fonts.css';

import React from 'react';
import { QueryClient, QueryClientProvider as ReactQueryProvider } from 'react-query';
import Favicons from 'components/Favicons';
import IntercomInit from 'components/Intercom';
import LoginRedirect from 'components/LoginRedirect';
import UserOnBoardingFlow from 'components/UserOnBoardingFlow';
import useInit from 'hooks/useInit';
import BugsnagWithErrorBoundary from 'lib/Bugsnag/components/BugsnagWithErrorBoundary';
import { isEmpty } from 'lodash';
import { configure } from 'mobx';
import { UserContextProvider } from 'modules/context/Contexts/User';
import SiteConfigProvider from 'modules/siteConfig/components/SiteConfigProvider';
import fetchSiteConfig from 'modules/siteConfig/query/fetchSiteConfig';
import { NextPageContext } from 'next';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import MaterialThemeProvider from 'theme/MaterialThemeProvider';
import SiteConfig from 'types/interfaces/SiteConfig';
import { Provider as UrqlProvider } from 'utils/urql';

import { Box, generateGlobalCSS, StylesProvider } from '@lyearn/ui';
import { Snackbar as SnackbarProvider } from '@lyearn/ui';

import { AnalyticsScripts, AnalyticsTriggers } from '../components/Analytics';

configure({ enforceActions: 'observed' });

const PageProgressBar = dynamic(() => import('components/PageProgressBar'));

const queryClient = new QueryClient();

interface CustomAppProps extends AppProps {
  siteConfig: SiteConfig;
}

function App({ Component, pageProps, siteConfig }: CustomAppProps) {
  const { ready, urqlClient } = useInit();

  if (!ready || !siteConfig || isEmpty(siteConfig)) {
    return null;
  }

  const cssString = generateGlobalCSS(siteConfig.theme);

  return (
    <ReactQueryProvider contextSharing client={queryClient}>
      <UrqlProvider value={urqlClient!}>
        <SnackbarProvider>
          <LoginRedirect>
            <UserContextProvider>
              <BugsnagWithErrorBoundary>
                <StylesProvider injectFirst>
                  <MaterialThemeProvider>
                    <SiteConfigProvider value={siteConfig}>
                      {siteConfig.site.intercomEnabled ? <IntercomInit /> : null}
                      <AnalyticsScripts site={siteConfig.site} />
                      <AnalyticsTriggers />
                      <UserOnBoardingFlow>
                        <Head>
                          <meta
                            content="width=device-width, height=device-height, initial-scale=1"
                            name="viewport"
                          />
                          <style data-lyearn-css="lyearn-global-css">{cssString}</style>
                          <Favicons site={siteConfig.site} />
                        </Head>
                        <Box className="bg-bg-primary">
                          <Component {...pageProps} />
                        </Box>
                      </UserOnBoardingFlow>
                      <PageProgressBar />
                    </SiteConfigProvider>
                  </MaterialThemeProvider>
                </StylesProvider>
              </BugsnagWithErrorBoundary>
            </UserContextProvider>
          </LoginRedirect>
        </SnackbarProvider>
      </UrqlProvider>
    </ReactQueryProvider>
  );
}

(App as any).getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  let siteConfig;

  if (!ctx.req?.headers.host) {
    siteConfig = {};
  } else {
    siteConfig = await fetchSiteConfig({ host: ctx.req?.headers.host });
  }

  return {
    siteConfig,
  };
};

export default App;
