import React from 'react';
import SiteConfigType from 'types/interfaces/SiteConfig';

export const SiteConfig = React.createContext<SiteConfigType | null>(null);

const SiteConfigContext: React.FC<React.PropsWithChildren<{ value: SiteConfigType }>> = ({
  children,
  value,
}) => {
  return <SiteConfig.Provider value={value}>{children}</SiteConfig.Provider>;
};

export default SiteConfigContext;
