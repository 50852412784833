import React from 'react';

import { getGAScript, getTrackingScript } from './utils';

const GoogleAnalyticsScript = ({ GA_TRACKING_ID }: { GA_TRACKING_ID: string }) => {
  return (
    <>
      <script async src={getGAScript(GA_TRACKING_ID)} />
      <script
        dangerouslySetInnerHTML={{
          __html: getTrackingScript(GA_TRACKING_ID),
        }}
      />
    </>
  );
};

export default GoogleAnalyticsScript;
