import useAuthentication from 'modules/auth/hooks/useAuthentication';
import { useUser } from 'modules/context/Contexts/User';

export const META_ONBOARDING_COMPLETED = 'onBoardingCompleted';

const useAboutYourselfForm = ({ redirectPath }: TSFixMe) => {
  const { handleUpdateUser } = useAuthentication();
  const user = useUser();

  const userDetails = {
    name: user.name,
    email: user.emailId,
    organisation: user.organisation,
    role: user.role,
    experience: user.experience,
    country: user.country,
  };

  const onSubmit = async (values: TSFixMe) => {
    const { name, email, organisation, role, country, experience } = values;
    await (handleUpdateUser as any)(
      {
        name,
        email,
        meta: JSON.stringify({
          ...user.meta,
          [META_ONBOARDING_COMPLETED]: true,
          organisation,
          role,
          country,
          experience,
        }),
      },
      redirectPath,
    );
  };
  return { onFormSubmit: onSubmit, initialValues: userDetails };
};

export default useAboutYourselfForm;
