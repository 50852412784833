import * as React from 'react';
import config from 'config';
import { FacebookAnalyticsTriggers } from 'lib/facebook';
import { GoogleAnalyticsTriggers } from 'lib/google';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';

const AnalyticsTriggers = () => {
  const { site } = useInstanceConfig();

  if (config.env === 'development') {
    return null;
  }
  const { facebook, google } = site?.integrations || {};

  return (
    <>
      {facebook?.FB_PIXEL_ID ? (
        <FacebookAnalyticsTriggers FB_PIXEL_ID={facebook?.FB_PIXEL_ID} />
      ) : null}
      {google?.GA_TRACKING_ID ? (
        <GoogleAnalyticsTriggers GA_TRACKING_ID={google?.GA_TRACKING_ID} />
      ) : null}
    </>
  );
};

export default AnalyticsTriggers;
