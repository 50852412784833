/**
 * Returns Facebook Pixel tracking script to be injected in head
 * @param FB_PIXEL_ID
 */
export const getTrackingScript = (FB_PIXEL_ID: string) => `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', ${FB_PIXEL_ID});
`;

export const pageview = () => {
  (window as any).fbq?.('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  (window as any).fbq?.('track', name, options);
};
